import styled from 'styled-components';
import { useState, useEffect } from 'react';

import SearchIcon from 'assets/icon_search_glass.svg';
import RightArrow from 'ui-kit/icons/svg/right-long-arrow.svg';

export default function EditJobSelectScorecard({
  customScorecards,
  customScorecardsSelected,
  onSave,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [availableScorecards, setAvailableScorecards] = useState([]);
  const [selectedScorecards, setSelectedScorecards] = useState([]);

  useEffect(() => {
    let selectedIds = [];
    if (customScorecardsSelected) {
      selectedIds = customScorecardsSelected.map((scorecard) => scorecard.id);

      setSelectedScorecards([...customScorecardsSelected]);
    }

    setAvailableScorecards(
      customScorecards.map((scorecard) => {
        scorecard.checked = selectedIds.includes(scorecard.id);
        return scorecard;
      })
    );
  }, [customScorecards, setAvailableScorecards, customScorecardsSelected, setSelectedScorecards]);

  function handleAvalableScorecardClick(event, scorecard) {
    scorecard.checked = event.target.checked;

    if (scorecard.checked) {
      setSelectedScorecards([...selectedScorecards, scorecard]);
      onSave([...selectedScorecards, scorecard]);
    } else {
      setSelectedScorecards(
        selectedScorecards.filter((selectedScorecard) => {
          return selectedScorecard.id !== parseInt(event.target.value, 10);
        })
      );
      onSave(
        selectedScorecards.filter((selectedScorecard) => {
          return selectedScorecard.id !== parseInt(event.target.value, 10);
        })
      );
    }
  }

  function handleSelectedScorecardClick(event, scorecard) {
    setSelectedScorecards(
      selectedScorecards.filter((scorecard) => {
        return scorecard.id !== parseInt(event.target.value, 10);
      })
    );
    onSave(
      selectedScorecards.filter((scorecard) => {
        return scorecard.id !== parseInt(event.target.value, 10);
      })
    );

    setAvailableScorecards(
      availableScorecards.map((availableScorecard) => {
        if (availableScorecard.id === scorecard.id) {
          availableScorecard.checked = false;
          return availableScorecard;
        }
        return availableScorecard;
      })
    );
  }

  return (
    <>
      <Header>Select Scorecards</Header>
      <SubHeader>
        Select the scorecards your interviewers can fill out for this job’s candidates
      </SubHeader>

      <ScorecardsHeaderContainer>
        <ScorecardsHeader>
          <span>Available Scorecards</span>
          <ScorecardsCount>{availableScorecards.length}</ScorecardsCount>
        </ScorecardsHeader>

        <ScorecardsHeader>
          <span>Assigned Scorecards</span>
          {selectedScorecards.length && (
            <ScorecardsCount>{selectedScorecards.length}</ScorecardsCount>
          )}
        </ScorecardsHeader>
      </ScorecardsHeaderContainer>

      <ScorecardListContainer>
        <ListContainer>
          <SearchLabel htmlFor="search-available-scorecards">
            <img src={SearchIcon} alt="magnifying glass icon" />
            <SearchInput
              id="search-available-scorecards"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              placeholder="Start typing to search scorecards"
            />
          </SearchLabel>

          <div>
            <LinkButton
              onClick={() => {
                setSearchQuery('');
                setSelectedScorecards(availableScorecards);
                onSave(availableScorecards);
                setAvailableScorecards(
                  availableScorecards.map((scorecard) => {
                    scorecard.checked = true;
                    return scorecard;
                  })
                );
              }}
            >
              Select All
            </LinkButton>
            <LinkButton
              onClick={() => {
                setSearchQuery('');
                setSelectedScorecards([]);
                onSave([]);
                setAvailableScorecards(
                  availableScorecards.map((scorecard) => {
                    scorecard.checked = false;
                    return scorecard;
                  })
                );
              }}
            >
              Clear All
            </LinkButton>
          </div>

          <ScorecardList>
            {availableScorecards
              .filter((scorecard) => {
                if (searchQuery) {
                  const title = scorecard.title.toLowerCase();
                  const isMatching = title.includes(searchQuery.toLowerCase());

                  if (isMatching) {
                    const startMatch = title.indexOf(searchQuery.toLowerCase());
                    const prefix = scorecard.title.substring(0, startMatch);
                    const match = scorecard.title.substring(
                      startMatch,
                      startMatch + searchQuery.length
                    );
                    const suffix = scorecard.title.substring(startMatch + searchQuery.length);

                    scorecard.titleWithMatch = (
                      <span>
                        {prefix}
                        <span>{match}</span>
                        {suffix}
                      </span>
                    );
                  }
                  return isMatching;
                }
                delete scorecard.titleWithMatch;
                return true;
              })
              .map((scorecard) => {
                return (
                  <ScorecardListItem key={scorecard.id} selected={scorecard.checked}>
                    <label>
                      <StyledCheckboxInput
                        type="checkbox"
                        value={scorecard.id}
                        checked={scorecard.checked}
                        onChange={(e) => {
                          handleAvalableScorecardClick(e, scorecard);
                        }}
                      />
                      {scorecard.titleWithMatch ? (
                        scorecard.titleWithMatch
                      ) : (
                        <span>{scorecard.title}</span>
                      )}
                      {scorecard.view_permissions !== 'everyone' && (
                        <span>({scorecard.view_permissions.replace('_', ' ')})</span>
                      )}
                    </label>
                  </ScorecardListItem>
                );
              })}
          </ScorecardList>
        </ListContainer>
        <CenteredArrowContainer>
          <img src={RightArrow} alt="Arrow pointing right" />
        </CenteredArrowContainer>
        <ListContainer>
          {selectedScorecards.length ? (
            <ScorecardList selected={true}>
              {selectedScorecards.map((scorecard) => {
                return (
                  <ScorecardListItem key={scorecard.id}>
                    <label>
                      <StyledCheckboxInput
                        type="checkbox"
                        value={scorecard.id}
                        defaultChecked={true}
                        onChange={(e) => {
                          handleSelectedScorecardClick(e, scorecard);
                        }}
                      />
                      <span>{scorecard.title}</span>
                      {scorecard.view_permissions !== 'everyone' && (
                        <span>({scorecard.view_permissions.replace('_', ' ')})</span>
                      )}
                    </label>
                  </ScorecardListItem>
                );
              })}
            </ScorecardList>
          ) : (
            <NoScorecardsMessageContainer>
              <div>
                <h5>No Scorecards Assigned</h5>
                <div>
                  Select available scorecards from the left to assign scorecards to this job posting
                </div>
              </div>
            </NoScorecardsMessageContainer>
          )}
        </ListContainer>
      </ScorecardListContainer>
    </>
  );
}

const Header = styled.div`
  margin: 3rem 0 5px;
  font-size: 16px;
  font-weight: 600;
  color: #444;
`;

const SubHeader = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #444;
  margin-bottom: 25px;
`;

const ScorecardsHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 75px;
  margin-bottom: 10px;
`;

const ScorecardsHeader = styled.div`
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 50px;
`;

const ScorecardsCount = styled.span`
  background: ${(props) => props.theme.uiColors.greens.full};
  border-radius: 999em;
  color: ${(props) => props.theme.uiColors.white};
  display: inline-block;
  font-weight: normal;
  text-align: center;
  width: 40px;
`;

const ScorecardListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 75px 1fr;
`;

const ListContainer = styled.div`
  background: ${(props) => props.theme.uiColors.white};
  box-shadow: 0 2px 4px ${(props) => props.theme.uiColors.notquitewhite};
  padding: 9px;
  max-height: 490px;
  min-height: 490px;
`;

const NoScorecardsMessageContainer = styled.div`
  display: grid;
  height: 100%;

  > div {
    margin: auto;
    width: 70%;
    color: #444;
    text-align: center;

    > h5 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    > div {
      font-size: 14px;
      font-style: italic;
    }
  }
`;

const SearchLabel = styled.label`
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.uiColors.notquitewhite};
  color: ${(props) => props.theme.uiColors.gray};
  display: grid;
  grid-template-columns: 40px 1fr;
  height: 40px;
  margin-bottom: 40px;
  font-weight: normal;

  > img {
    padding: 12px;
  }
`;

const SearchInput = styled.input`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
`;

const LinkButton = styled.button`
  background: none;
  border: 0;
  display: inline-block;
  margin: 0 30px 5px 0;
  padding: 0;
`;

const ScorecardList = styled.ul`
  border: 1px solid ${(props) => props.theme.uiColors.notquitewhite};
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  max-height: ${(props) => (props.selected ? '100%' : '368px')};
`;

const ScorecardListItem = styled.li`
  border-bottom: 1px solid ${(props) => props.theme.uiColors.notquitewhite};
  background: ${(props) => (props.selected ? 'rgb(240 240 240)' : props.theme.uiColors.white)};

  &:last-child {
    border-bottom: none;
  }

  > label {
    padding: 10px;
    font-weight: normal;
    margin-bottom: 0;
    width: 100%;

    > span {
      display: inline-block;
      margin-left: 10px;

      > span {
        background-color: ${(props) => props.theme.uiColors.greens.light};
      }
    }

    > span:nth-child(3) {
      font-style: italic;
      font-size: 12px;
    }
  }
`;

// A new checkmark (another one..) but this one is black :shrug:
// It doesn't have fancy functionality for indeterminate, and it's scoped to an input.
// Use as a normal input[type=checkbox]
// After is the checkmark
// before is the box around.
const StyledCheckboxInput = styled.input`
  position: relative;

  &:after,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
  }

  &:checked {
    &:after {
      content: '';
      border: solid ${(props) => props.theme.uiColors.black};
      border-width: 0 1.5px 1.5px 0;
      height: 9px;
      width: 5px;
      left: 4px;
      top: 1px;
      transform: scale(1) rotate(45deg);
    }
  }

  &:before {
    content: '';
    border: 1px solid ${(props) => props.theme.uiColors.black};
    background: ${(props) => props.theme.uiColors.white};
  }
`;

const CenteredArrowContainer = styled.div`
  margin: auto;
`;
