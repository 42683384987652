import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { color, typography } from 'styled-system';

import ApplicantSourceModal from './ApplicantSourceModal';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';
import { ViewButton } from 'sharedComponents/Buttons';
import { SettingsRow } from 'components/SettingsRow';

export default function ApplicantSourceList({ district, sources }) {
  // State:
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editSource, setEditSource] = useState({ value: '', label: '' });
  const [error, setError] = useState('');

  // sort by alpha (watch out for unicode:)
  // sortedRows will either come from our default list, or we will use the district
  // listed sources.
  const [sourcesList, setSourcesList] = useState([]);
  useEffect(() => {
    const sourceList = sources || [];
    setSourcesList(sourceList.sort((a, b) => a.label.localeCompare(b.label)));
  }, [sources]);

  // Add modal controls
  function openModal(s) {
    setEditSource(s);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function onModalSave(source) {
    let method = '';
    let url = '';

    if (source.id) {
      method = 'put';
      url = `/api/candidate-source/${source.id}/`;
    } else {
      method = 'post';
      url = `/api/candidate-source/`;
    }

    axios[method](url, {
      district: district,
      ...source,
    })
      .then((r) => {
        // Remove the version before the edit, and then add the new source.
        // Sort it all by alpha on the label key.
        setSourcesList(
          [...sourcesList.filter((s) => s.id !== source.id), r.data].sort((a, b) =>
            a.label.localeCompare(b.label)
          )
        );

        closeModal();
      })
      .catch(() => {
        // The only reason this would error is because someone is trying to duplicate
        // the candidate facing label.
        setEditSource(source);
        setModalIsOpen(true);
        setError('This candidate-facing source already exists.');
      });
  }

  function onModalDelete(source) {
    axios.delete(`/api/candidate-source/${source.id}`).then((r) => {
      // Remove the source from the list, and sort by alpha on the label key.
      setSourcesList(
        [...sourcesList.filter((s) => s.id !== source.id)].sort((a, b) =>
          a.label.localeCompare(b.label)
        )
      );
      setModalIsOpen(false);
    });
  }

  return (
    <Section id="applicant-source">
      <AnchorTagHeader
        onClick={() => openModal({ value: '', label: '' })}
        buttonText="+ New Source"
        title="Applicant Source"
        id="applicantSource"
      />
      <ListRow>
        <ListHeading>Source (internal name)</ListHeading>
        <ListHeading>Candidate-facing name</ListHeading>
      </ListRow>
      {sourcesList.map((s) => {
        if (s.value === 'none') return null;
        return (
          <SettingsRow
            cta={<ViewButton />}
            key={s.id || s.value || s.label}
            onClick={() => openModal(s)}
          >
            <ListRow>
              <ListText color="#444" fontSize="15px">
                {s.value}
              </ListText>
              <ListText color="#909090" fontSize="14px">
                {s.label || s.value}
              </ListText>
            </ListRow>
          </SettingsRow>
        );
      })}
      <ApplicantSourceModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        onSave={onModalSave}
        onDelete={onModalDelete}
        editSource={editSource}
        error={error}
        sources={sourcesList}
      />
    </Section>
  );
}

const Section = styled.section`
  margin-top: 41px;
`;

const ListRow = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 270px minmax(250px, auto) 50px;
  margin-bottom: 3px;
  background-color: #fff;
`;

const ListHeading = styled.div`
  font-size: 12px;
  height: 30px;
  color: #999;
  padding: 7px 16px 6px;
`;

const ListText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${color}
  ${typography}
`;
