import styled from 'styled-components';

interface TalentMarketplaceHeaderProps {
  currentTab: number;
  sortBackend: (value: string) => void;
}

export const TalentMarketplaceHeader: React.FC<TalentMarketplaceHeaderProps> = ({
  currentTab,
  sortBackend,
}) => {
  return (
    <HeaderRow style={{ borderTop: '1px solid #bdbdbd' }}>
      <div
        className={`candidate-name ''} pointer`}
        style={{ flex: '25', padding: '0' }}
        onClick={() => sortBackend('last_name')}
      >
        Name
        <span
          style={{ marginLeft: '6px', zIndex: 1 }}
          className="glyphicon glyphicon-menu-down sort"
        />
      </div>

      <div style={{ flex: '8' }}>Experience</div>

      <div style={{ flex: '20' }}>Job Categories</div>

      <div
        className="candidate-action pointer"
        style={{ flex: '14' }}
        onClick={() => sortBackend('profile.updated')}
      >
        Last Login
        <span className="glyphicon glyphicon-menu-down sort" style={{ zIndex: 1 }} />
      </div>

      <div className="candidate-action pointer" style={{ flex: '17' }}>
        {currentTab === 3 ? '' : 'Actions'}
      </div>

      <div className="candidate-action pointer" style={{ flex: '21' }}></div>
    </HeaderRow>
  );
};

const HeaderRow = styled.div`
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  align-items: center;
  border-bottom: 1px solid #ddd;
  color: rgba(57, 60, 73, 0.8);
  display: grid;
  font-size: 16px;
  font-weight: 600;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 48px;
  line-height: 24px;
  min-width: 500px;
  padding: 0;
  padding-left: 8px;
  width: 98.75%;

  & > div {
    padding-right: 8px !important;
  }
`;
