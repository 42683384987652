import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { addDocument } from '../../utils/fileUpload.js';
import greenCheckmark from '../../assets/icon_check_green.svg';
import paperAirplane from '../../assets/icon-paper-airplane-green.svg';
import ErrorText from '../errortext';

export default class UploadProspectDocumentModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    submitAdditionalDocument: PropTypes.func.isRequired,
    postSubmitCallback: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitDisabled: false,
      attachmentName: null,
      documentExists: false,
      uploadErrorMessages: {
        document: '',
      },
      document: {},
      submitErrorMessage: '',
      postSubmission: false,
    };
    this.initialState = Object.assign({}, this.state);

    this.addDocument = addDocument.bind(this);
  }

  handleChange = (e) => {
    this.setState({ attachmentName: e.target.value });
  };

  onSubmit = () => {
    if (this.hasNoErrors()) {
      this.setState({ submitDisabled: true });
      let newState = this.initialState;
      this.props
        .submitAdditionalDocument(this.state.document, this.state.attachmentName)
        .then(() => {
          this.setState({ newState });
          this.setState({ postSubmission: true });
          this.props.postSubmitCallback && this.props.postSubmitCallback();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  hasNoErrors = () => {
    if (!this.state.attachmentName) {
      this.setState({ submitErrorMessage: 'Please name this attachment.' });
      return false;
    } else if (!this.state.documentExists) {
      this.setState({ submitErrorMessage: 'Please select a file.' });
      return false;
    }
    return true;
  };

  clickDocumentInput = () => {
    const uploadErrorMessages = this.state.uploadErrorMessages;
    uploadErrorMessages.resume = '';
    this.setState({ uploadErrorMessages });
    this.documentInput.click();
  };

  changeDocument = (event) => {
    const file = this.addDocument(event);
    // If there's an error
    if (!file) return;
    this.setState({
      document: file.fileData,
      uploadErrorMessages: file.uploadErrorMessages,
      documentExists: true,
    });
  };

  document = () => {
    if (this.state.documentExists) {
      const nameShowing = this.getName();
      return this.showUploadedFile(nameShowing);
    } else {
      return this.updateOrSelectButton();
    }
  };

  getName = () => {
    return this.state.attachmentName || this.state.document.name;
  };

  showUploadedFile = (nameShowing) => (
    <div className="file-upload-container">
      <div className="upload-flex-container">
        <img alt="icon-check" src={greenCheckmark} />
        <div className="file-upload-col-2 ml2">
          <div>
            <div>{nameShowing}</div>
          </div>
        </div>
      </div>
    </div>
  );

  updateOrSelectButton = () => (
    <div>
      <button
        type="button"
        className="upload bg-green select-button"
        onClick={this.clickDocumentInput}
      >
        {this.state.documentExists ? 'Update File' : 'Select File'}
      </button>
    </div>
  );

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} bsSize="large" dialogClassName="additional-document-modal">
        <Modal.Header className="flex flex-1">
          <span className="modal-title flex-1">
            <h3>Upload New Attachment</h3>
          </span>
          <span
            className="copy-app-modal-close"
            onClick={() => {
              this.setState(this.initialState);
              this.props.onHide();
            }}
          >
            (X) CLOSE
          </span>
        </Modal.Header>
        <Modal.Body>
          {!this.state.postSubmission ? (
            <div className="modal-section">
              <div className="mt1">
                <h5 className="bold mb1">Attachment name</h5>
                <input
                  name="attachmentName"
                  className="form-field attachmentName mb2"
                  ref="attachmentName"
                  type="text"
                  placeholder="e.g. cover letter"
                  pattern="..*"
                  value={this.state.attachmentName || ''}
                  required
                  onChange={this.handleChange}
                />
                <input
                  name="document"
                  ref={(input) => (this.documentInput = input)}
                  type="file"
                  style={{ height: '0px', overflow: 'hidden', marginTop: '-0.4rem' }}
                  onChange={this.changeDocument}
                />

                {this.document()}

                <ErrorText message={this.state.submitErrorMessage} />
                <ErrorText message={this.state.uploadErrorMessages.document} />
              </div>
            </div>
          ) : (
            <div className="post-submission">
              <img src={paperAirplane} alt="paper-airplane" />
              <div>Your file has been submitted!</div>
            </div>
          )}
          <div className="modal-footer">
            {!this.state.postSubmission ? (
              <div>
                <button
                  className="mr2 wide"
                  onClick={() => {
                    this.setState(this.initialState);
                    this.props.onHide();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="mr3 wide"
                  onClick={this.onSubmit}
                  disabled={this.state.submitDisabled}
                >
                  Submit
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="mr2 wide"
                  onClick={() => {
                    this.setState(this.initialState);
                    this.props.onHide();
                  }}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
