import { useState, useCallback } from 'react';

/**
 * Can be used for form state management, input validation,
 * and even rendering form inputs.
 *
 * @param {Object} initialValues - object representation of form inputs
 * @param {Function} callback - a function that will be called on form submission
 */
const useForm = (initialValues, callback) => {
  const [values, setValues] = useState(initialValues || {});

  const renderFormInputs = () => {
    return Object.values(values).map((inputObj) => {
      const { value, label, errorMessage, valid, renderInput } = inputObj;
      return renderInput(handleChange, value, valid, errorMessage, label);
    });
  };

  /**
   * Returns boolean value indicating whether overall form
   * is valid.
   *
   * @param {Object} formObj - object representation of form
   */
  const isFormValid = useCallback(() => {
    let isValid = true;
    const arr = Object.values(values);

    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].valid) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }, [values]);

  /**
   * Accepts object representing an input element in form.
   *
   * Function checks each of the input's validation rules
   * for errors. If a rule returns false, an error message
   * is set and the function returns false.
   *
   * If all validation rules pass, then the function returns
   * true and the input is considered valid.
   */
  const isInputFieldValid = useCallback(
    (inputField) => {
      for (const rule of inputField.validationRules) {
        if (!rule.validate(inputField.value, values)) {
          inputField.errorMessage = rule.message;
          return false;
        }
      }
      return true;
    },
    [values]
  );

  // useCallback is used to avoid creating a function each time
  // state has updated.
  const handleChange = useCallback(
    (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      // copy input obj whose value has changed
      const inputObj = { ...values[name] };
      // update value
      inputObj.value = value;

      // update input field's validity
      const isValidInput = isInputFieldValid(inputObj);
      // if input is valid and it was previously invalid
      // set its valid status to true
      if (isValidInput && !inputObj.valid) {
        inputObj.valid = true;
      } else if (!isValidInput && inputObj.valid) {
        // if input is not valid and it was previously valid
        // set its valid status to false
        inputObj.valid = false;
      }

      // mark input field as touched
      inputObj.touched = true;
      setValues({ ...values, [name]: inputObj });
    },
    [values, isInputFieldValid]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    callback();
  };

  return {
    renderFormInputs,
    values,
    isFormValid,
    handleChange,
    handleSubmit,
  };
};

export default useForm;
