import { Component } from 'react';

import PropTypes from 'prop-types';
import { salutation } from '../../../utils/enums';

const salutationReadable = salutation().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default class AdditionalReferences extends Component {
  static propTypes = {
    additionalReferences: PropTypes.arrayOf(Object).isRequired,
    simpleCard: PropTypes.bool,
  };

  static defaultProps = {
    simpleCard: false,
  };

  render() {
    return (
      <div className="simple-card-container">
        {this.props.additionalReferences.map((row, i) =>
          this.props.simpleCard ? (
            <div className="simple-card mt2 ml2 mb1" key={i}>
              <div>
                <p>
                  {`${
                    salutationReadable[row['salutation']]
                      ? salutationReadable[row['salutation']] + ' '
                      : ''
                  }${row['first_name']} ${row['last_name']}`}
                  {row['title'] ? ', ' + row['title'] : ''}
                </p>
              </div>
              <div>{row['years_known'] ? 'Years known: ' + row['years_known'] : ''}</div>
              <div>{row['email'] ? row['email'] : ''}</div>
              <div>{row['phone'] ? row['phone'] : ''}</div>
            </div>
          ) : (
            <div className="added-row" key={i}>
              <span className="flex-2">
                <h5>
                  {row['first_name']} {row['last_name']}
                </h5>
                <p>{row['title']}</p>
              </span>
              <span className="flex-2">
                <p>{row['email']}</p>
                <p>{row['phone']}</p>
              </span>
            </div>
          )
        )}
      </div>
    );
  }
}
