import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { Box, CircularProgress } from '@mui/material';
import CandidateDashboardApplicationsRow from './CandidateDashboardApplicationsRow';

export default function CandidateDashboardApplicationsList({
  user,
  applications,
  totalApplicationsCount,
  page,
  isLoading,
  isFetchingNextPage,
  setIsFetchingNextPage,
  dispatch,
  hasMore,
  editModeApplicationID,
  openUploadDocumentModal,
  withdrawApplication,
  getApplicationAttachmentsForModal,
}) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasMore && !isFetchingNextPage) {
      setIsFetchingNextPage(true);
      dispatch({ type: 'SET_PAGE', payload: page + 1 });
    }
  }, [dispatch, hasMore, inView, isFetchingNextPage, page, setIsFetchingNextPage]);

  function getHeaderText() {
    if (totalApplicationsCount === 0) {
      return 'No Active Applications';
    }

    if (totalApplicationsCount > 0 && applications.length > 0) {
      return `Showing ${applications.length} of ${totalApplicationsCount} Active Application${
        totalApplicationsCount !== 1 ? 's' : ''
      }`;
    }

    return `${totalApplicationsCount} Active Application${totalApplicationsCount !== 1 ? 's' : ''}`;
  }

  return (
    <ApplicationsListContainer width={'100%'}>
      <HeaderText>{getHeaderText()}</HeaderText>
      {applications.map((application) => (
        <CandidateDashboardApplicationsRow
          key={application.id}
          user={user}
          application={application}
          editModeApplicationID={editModeApplicationID}
          openUploadDocumentModal={openUploadDocumentModal}
          withdrawApplication={withdrawApplication}
          getApplicationAttachmentsForModal={getApplicationAttachmentsForModal}
        />
      ))}
      <div id="load-more-results-trigger" ref={ref} />
      {(isLoading || isFetchingNextPage) && (
        <div style={{ textAlign: 'center', paddingTop: 24 }}>
          <CircularProgress />
        </div>
      )}
    </ApplicationsListContainer>
  );
}

CandidateDashboardApplicationsList.propTypes = {
  user: PropTypes.object.isRequired,
  applications: PropTypes.array.isRequired,
  totalApplicationsCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  setIsFetchingNextPage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  editModeApplicationID: PropTypes.string,
  openUploadDocumentModal: PropTypes.func.isRequired,
  withdrawApplication: PropTypes.func.isRequired,
  getApplicationAttachmentsForModal: PropTypes.func.isRequired,
};

const ApplicationsListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 1,
  width: '100%',
  justifyItems: 'center',
  justifyContent: 'space-evenly',
}));

const HeaderText = styled.p`
  font-size: 19px;
  color: #444444;
  margin-top: 4rem;
  margin-bottom: 12px;
`;
