import { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DateTime from 'react-datetime';

import { Dropdown, Input, Textarea } from 'ui-kit';
import { DownCaretIcon } from 'ui-kit/icons';
import { workExperienceType } from 'utils/enums';

import JobEditGrades from '../../JobEdit/jobeditgrades';
import ErrorText from 'components/errortext';
import { isValidEmail } from 'utils/emailutils';
import { ATSCandidateExperienceDataTestIds } from '../../../data-testids/ATS';

const TYPES = workExperienceType().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export default function ExpandedInput({ row, index, handleAddRow, onCancel }) {
  const [state, setState] = useState({ ...row });
  const [errorMessage, setErrorMessage] = useState({});
  const [emailIsValid, setEmailIsValid] = useState(true);

  const updateField = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    if (e.target.name === 'reference_email') {
      validateEmail(value);
    }

    setState((prevState) => {
      const newState = { ...prevState, [name]: value };
      // clear end date if selecting "presently employed" checkbox
      if (name === 'presently_employed') {
        newState.end_date = '';
        if (value === true) {
          newState.reason_for_leaving = 'I am presently employed here';
        } else if (value === false) {
          newState.reason_for_leaving = '';
        }
      }
      return newState;
    });
    setErrorMessage({});
  };

  const updateMultiSelect = (values, fieldName) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: values.map((value) => value.value),
    }));
    setErrorMessage({});
  };

  const updateFilterDrop = (fieldName, fieldValue, active) => {
    /** this.state.addExperience[fieldName] should always be an array. If active is true,
     * the filter option that was clicked is active and should be turned off, which means we
     * have to remove fieldValue from the array instead of adding it.
     * @param {string} fieldName: either experience_type or org_description
     * @param {number} fieldValue: number corresponding to enum
     * @param {boolean} active: whether or not the value is selected
     */
    if (active) {
      // value is selected and the user wants to deselect. Find the element and remove.
      setState((prevState) => ({
        ...prevState,
        [fieldName]: prevState[fieldName].filter((e) => e !== fieldValue),
      }));
    } else {
      // value is not selected and the user wants to select. Add it to the array.
      setState((prevState) => {
        const newState = { ...prevState };
        newState[fieldName].push(fieldValue);
        return newState;
      });
    }

    setErrorMessage({});
  };

  const updateDate = (field, date) => {
    if (date.toDate || date === '') {
      setState((prevState) => ({
        ...prevState,
        [field]: date ? date.toDate() : null,
      }));
    } else {
      // TODO: display validation error
      return;
    }
  };

  const toggleAdd = () => {
    try {
      checkRequiredAndValidFields();
    } catch (e) {
      if (e.message === 'Missing required fields') {
        // If the entry is missing a required field, the error is handled within
        // the function, but we still want to prevent the add so return here
        return;
      }

      // unexpected error
      throw new Error(e);
    }

    setErrorMessage({});
    handleAddRow({ ...state, hasError: false }, index);
  };

  const checkRequiredAndValidFields = () => {
    const errorMessage = {};
    let hasError = false;

    if (!state.reason_for_leaving && !state.presently_employed) {
      errorMessage.reason_for_leaving = 'Please add a reason for leaving';
      hasError = true;
    }
    if (!state.organization) {
      errorMessage.organization = 'Please add an employer name';
      hasError = true;
    }
    if (!state.title) {
      errorMessage.title = 'Please add a job title';
      hasError = true;
    }
    if (!state.description) {
      errorMessage.description = 'Please add a job description';
      hasError = true;
    }
    if (!state.start_date) {
      errorMessage.start_date = 'Please add a start date';
      hasError = true;
    }
    if (state.end_date && state.start_date > state.end_date) {
      errorMessage.end_date = 'Please enter an end date that is after your start date';
      hasError = true;
    }
    if (!state.presently_employed && !state.end_date) {
      errorMessage.end_date =
        'Please add an end date or select the applicable checkbox if this if your current role';
      hasError = true;
    }
    if (!state.start_date) {
      errorMessage.end_date = 'Start date is required';
      hasError = true;
    }
    if (!state.presently_employed && !state.end_date) {
      errorMessage.end_date = 'End date is required';
      hasError = true;
    }

    const today = new Date();
    if (state.start_date > today) {
      errorMessage.start_date = "Please add a start date prior to today's date";
      hasError = true;
    }
    if (state.end_date > today) {
      errorMessage.end_date =
        "Please add an end date prior to today's date or select the applicable checkbox if this if your current role";
      hasError = true;
    }

    if (!emailIsValid) {
      errorMessage.reference_email = 'Please enter a valid email for your employer reference';
      hasError = true;
    }

    if (hasError) {
      setErrorMessage(errorMessage);
      throw new Error('Missing required fields');
    }
  };

  const isSelected = (item) => {
    return state.experience_types.includes(item.value);
  };

  const validateEmail = (email) => {
    if (email === '') {
      setEmailIsValid(true);
    } else if (isValidEmail(email)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
      setErrorMessage();
    }
  };

  return (
    <>
      <div className="mt2">
        <GridContainerThirds>
          <RequiredInputContainer>
            <Input
              id="employer-input"
              data-testid={ATSCandidateExperienceDataTestIds.EMPLOYER_INPUT}
              width={1}
              name="organization"
              type="text"
              placeholder="Employer"
              value={state.organization}
              onChange={updateField}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateField(e);
                  toggleAdd();
                }
              }}
              hasError={errorMessage.organization}
            />
          </RequiredInputContainer>
          <RequiredInputContainer>
            <Input
              id="job-title-input"
              width={1}
              name="title"
              type="text"
              placeholder="Job title"
              data-testid={ATSCandidateExperienceDataTestIds.JOB_TITLE_INPUT}
              value={state.title}
              onChange={updateField}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateField(e);
                  toggleAdd();
                }
              }}
              hasError={errorMessage.title}
            />
          </RequiredInputContainer>
          <Dropdown.Dropdown
            label="Experience type"
            inputProps={{
              paddingLeft: '1.5em',
              paddingRight: '1.5em',
              boxShadow: 'none',
              'data-testid': ATSCandidateExperienceDataTestIds.EXPERIENCE_TYPE_DROPDOWN,
            }}
          >
            {workExperienceType().map((item) => {
              const selected = isSelected(item);
              return (
                <ListItem
                  isSelected={selected}
                  onClick={() => updateFilterDrop('experience_types', item.value, selected)}
                  key={item.key}
                >
                  {item.label}
                </ListItem>
              );
            })}
          </Dropdown.Dropdown>
        </GridContainerThirds>

        {(state.experience_types.indexOf(TYPES.teacher) > -1 ||
          state.experience_types.indexOf(TYPES.assistant_teacher) > -1) && (
          <div className="work-experience-grades mb2">
            <p className="mb1">What grades did you teach?</p>
            <JobEditGrades
              grades={state.grades}
              onChange={(values) => updateMultiSelect(values, 'grades')}
            />
          </div>
        )}

        <GridContainerThirds>
          <Input
            width={1}
            name="reference_name"
            type="text"
            placeholder="Supervisor's Name"
            value={state.reference_name}
            onChange={updateField}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                updateField(e);
                toggleAdd();
              }
            }}
          />
          <Input
            width={1}
            name="reference_phone"
            type="tel"
            size="15"
            placeholder="Supervisor's Phone Number"
            value={state.reference_phone}
            onChange={updateField}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                updateField(e);
                toggleAdd();
              }
            }}
          />
          <Input
            width={1}
            name="reference_email"
            type="email"
            placeholder="Supervisor's Email"
            value={state.reference_email}
            onChange={updateField}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                updateField(e);
                toggleAdd();
              }
            }}
            hasError={errorMessage.reference_email}
          />
        </GridContainerThirds>

        <GridContainerFourths>
          <DateTimeContainer hasError={errorMessage.start_date}>
            <DateTime
              className="datetime-picker-container"
              inputProps={{
                placeholder: 'Start Month',
                className: 'datetime-picker jobedit ml0',
                id: 'work-experience-start-date-month-input',
                'data-testid': ATSCandidateExperienceDataTestIds.START_MONTH_DATETIME,
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="MMM"
              closeOnSelect={true}
              value={state.start_date ? moment(state.start_date) : ''}
              onChange={(date) => updateDate('start_date', date)}
            />
            <StyledDownCaret />
          </DateTimeContainer>
          <DateTimeContainer hasError={errorMessage.start_date}>
            <DateTime
              className="datetime-picker-container"
              inputProps={{
                placeholder: 'Start Year',
                className: 'datetime-picker jobedit ml0',
                id: 'work-experience-start-date-year-input',
                'data-testid': ATSCandidateExperienceDataTestIds.START_YEAR_DATETIME,
                readOnly: true,
              }}
              timeFormat={false}
              dateFormat="YYYY"
              closeOnSelect={true}
              value={state.start_date ? moment(state.start_date) : ''}
              onChange={(date) => updateDate('start_date', date)}
            />
            <StyledDownCaret />
          </DateTimeContainer>
          <DateTimeContainer
            notRequired={state.presently_employed}
            hasError={errorMessage.end_date}
          >
            <DateTime
              className="datetime-picker-container"
              inputProps={{
                placeholder: 'End Month',
                className: `datetime-picker jobedit ml0
                  ${state.presently_employed ? 'disable-dropdown' : ''}`,
                'data-testid': ATSCandidateExperienceDataTestIds.END_MONTH_DATETIME,
                readOnly: true,
                disabled: state.presently_employed,
              }}
              timeFormat={false}
              dateFormat="MMM"
              closeOnSelect={true}
              value={state.end_date ? moment(state.end_date) : ''}
              onChange={(date) => updateDate('end_date', date)}
            />
            <StyledDownCaret />
          </DateTimeContainer>
          <DateTimeContainer
            notRequired={state.presently_employed}
            hasError={errorMessage.end_date}
          >
            <DateTime
              className="datetime-picker-container"
              inputProps={{
                placeholder: 'End Year',
                className: `datetime-picker jobedit ml0
                  ${state.presently_employed ? 'disable-dropdown' : ''}`,
                'data-testid': ATSCandidateExperienceDataTestIds.END_YEAR_DATETIME,
                readOnly: true,
                disabled: state.presently_employed,
              }}
              timeFormat={false}
              dateFormat="YYYY"
              closeOnSelect={true}
              value={state.end_date ? moment(state.end_date) : ''}
              onChange={(date) => updateDate('end_date', date)}
            />
            <StyledDownCaret />
          </DateTimeContainer>
        </GridContainerFourths>

        <RequiredInputContainerWithMargin>
          <Textarea
            id="job-description-input"
            width={1}
            height="5rem"
            name="description"
            type="text"
            maxLength={2048}
            placeholder="Job description"
            data-testid={ATSCandidateExperienceDataTestIds.JOB_DESCRIPTION_INPUT}
            value={state.description}
            onChange={updateField}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                updateField(e);
                toggleAdd();
              }
            }}
            hasError={errorMessage.description}
          />
        </RequiredInputContainerWithMargin>

        <RequiredInputContainerWithMargin notRequired={state.presently_employed}>
          <Textarea
            id="reason-for-leaving-input"
            width={1}
            height="5rem"
            name="reason_for_leaving"
            type="text"
            maxLength={2048}
            placeholder="Reason for leaving"
            data-testid={ATSCandidateExperienceDataTestIds.REASON_FOR_LEAVING_INPUT}
            value={state.reason_for_leaving}
            onChange={updateField}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                updateField(e);
                toggleAdd();
              }
            }}
            hasError={errorMessage.reason_for_leaving}
            disabled={state.presently_employed}
          />
        </RequiredInputContainerWithMargin>
        <PresentlyEmployed>
          <input
            type="checkbox"
            className="flex-1"
            id="presently-employed-checkbox"
            name="presently_employed"
            value={state.presently_employed}
            checked={state.presently_employed}
            onChange={updateField}
          />
          &nbsp;&nbsp;I'm presently employed here
        </PresentlyEmployed>
        <DoNotContact>
          <input
            type="checkbox"
            className="flex-1"
            name="do_not_contact"
            value={state.do_not_contact}
            checked={state.do_not_contact}
            onChange={updateField}
          />
          &nbsp;&nbsp;Please do not contact
        </DoNotContact>
      </div>
      <div className="flex add-card-container">
        <div className="add-btn cancel" onClick={() => onCancel(index)}>
          Cancel
        </div>
        <div
          className="add-btn save"
          id="save-entry-button"
          data-testid={ATSCandidateExperienceDataTestIds.SAVE_ENTRY_BUTTON}
          onClick={toggleAdd}
        >
          Save Entry
        </div>
      </div>
      {/* display error messages */}
      {Object.keys(errorMessage).length > 0 && (
        <div className="mt1">
          {Object.keys(errorMessage).map((key) => (
            <ErrorText key={key} message={errorMessage[key]} color="#EF5675" />
          ))}
        </div>
      )}
    </>
  );
}

const GridContainerThirds = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const RequiredInputContainer = styled.div`
  position: relative;

  ${({ notRequired }) => notRequired && `&::after { display: none }`}

  &::after {
    content: '*';
    position: absolute;
    right: 8px;
    top: 5px;
    color: #e64c66;
  }
`;

const RequiredInputContainerWithMargin = styled(RequiredInputContainer)`
  margin-bottom: 1.7rem;
`;

const GridContainerFourths = styled(GridContainerThirds)`
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const DateTimeContainer = styled(RequiredInputContainer)`
  position: relative;
  max-width: 250px;

  ${({ notRequired }) => notRequired && `&::after { display: none }`}
  ${({ hasError }) =>
    hasError &&
    `
      .datetime-picker-container > input {
        border: 1px solid #cc0033 !important;
        background-color: #fce4e4;
      }
    `}
`;

const PresentlyEmployed = styled.label`
  margin-bottom: 15px;
  display: block;
  max-width: 100%;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    display: inline-block;
  }
`;

const DoNotContact = styled(PresentlyEmployed)`
  @media screen and (min-width: 768px) {
    margin-left: 3rem;
  }
`;

const StyledDownCaret = styled(DownCaretIcon)`
  position: absolute;
  height: 0.5em;
  fill: #c1c1c1;
  right: 20px;
  top: 22px;
`;

const DropdownListItem = ({ isSelected, children, ...rest }) => {
  return (
    <Dropdown.ListItem {...rest}>
      <span>{children}</span>
      <input type="checkbox" checked={isSelected} readOnly />
    </Dropdown.ListItem>
  );
};

const ListItem = styled(DropdownListItem)`
  padding: 0.5em 1.5em;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
