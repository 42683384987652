import axios from 'axios';
import styled from 'styled-components';

export function LinkedinAuthContainer() {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  // Send code and redirect_uri to backend so that we can get an access token.
  axios
    .post('/api/socialmedia/callback/linkedin/', {
      code: code,
      redirect: window.location.href.replace(window.location.search, ''),
    })
    .then((r) => {
      window.localStorage.setItem('linkedin_email', r.data);
      window.close();
    })
    .catch(() => {
      window.close();
    });

  return (
    <Message>
      <p>Finishing... This window will close by itself.</p>
    </Message>
  );
}

export function TwitterAuthContainer() {
  const params = new URLSearchParams(window.location.search);
  const verifier = params.get('oauth_verifier');
  const token = params.get('oauth_token');

  axios
    .post('/api/socialmedia/callback/twitter/', {
      verifier: verifier,
      token: token,
    })
    .then((r) => {
      window.localStorage.setItem('twitter_email', r.data);
      window.close();
    })
    .catch((e) => {
      window.close();
    });

  return (
    <Message>
      <p>Finishing... This window will close by itself.</p>
    </Message>
  );
}

const Message = styled.div`
  height: 600px;
  display: grid;

  > p {
    margin: auto;
  }
`;
