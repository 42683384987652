import useSWR, { mutate } from 'swr';

import schoolGroupsAPI from 'api/schoolGroupsAPI';

const KEY = '/api/school-groups';

/**
 * Data management wrapper for fetching and managing SchoolGroups.
 *
 * Usage: const { data, isLoading, error, actions } = useSchoolGroups();
 */
export const useSchoolGroups = () => {
  const { data, error } = useSWR(KEY, () => schoolGroupsAPI.getSchoolGroups());

  const isLoading = data === undefined;

  const actions = {
    createSchoolGroup,
    updateSchoolGroup,
    deleteSchoolGroup,
  };

  return { data: data ?? [], isLoading, error, actions };
};

/**
 * Create a school group.
 * @param {*} groupName
 */
const createSchoolGroup = async (name) => {
  const data = { name };
  const newSchoolGroup = await schoolGroupsAPI.createSchoolGroup(data);
  mutate(KEY, async (schoolGroups) => [...schoolGroups, newSchoolGroup]);
};

/**
 * Update a school group by id.
 * @param {*} id
 * @param {*} groupName
 */
const updateSchoolGroup = async (id, data) => {
  // Optimisitic update
  mutate(
    KEY,
    async (schoolGroups) =>
      schoolGroups.map((originalSchoolGroup) =>
        originalSchoolGroup.id === id ? { ...originalSchoolGroup, ...data } : originalSchoolGroup
      ),
    false
  );

  const updatedSchoolGroup = await schoolGroupsAPI.updateSchoolGroup(id, data);

  // Finalize update
  mutate(
    KEY,
    async (schoolGroups) =>
      schoolGroups.map((originalSchoolGroup) =>
        originalSchoolGroup.id === id ? updatedSchoolGroup : originalSchoolGroup
      ),
    false
  );
};

/**
 * Delete a school group by id.
 * @param {*} id
 */
const deleteSchoolGroup = async (id) => {
  // Optimisitic update
  mutate(
    KEY,
    async (schoolGroups) => schoolGroups.filter((schoolGroup) => schoolGroup.id !== id),
    false
  );

  await schoolGroupsAPI.deleteSchoolGroup(id);

  // Revalidate
  mutate(KEY);
};
