import styled from 'styled-components';
import { grid, layout } from 'styled-system';

import { AltModal } from 'ui-kit';

export default function ConfirmationModalFooter({ save, onHide, editPreviousDraft }) {
  return (
    <GridContainer gridTemplateColumns={['1fr', '1fr', '186px 1fr 186px']}>
      <EditButton onClick={editPreviousDraft} width={[1, 1, '184px']}>
        Back to Edit
      </EditButton>
      <CancelButton onClick={onHide} width={[1, 1, '184px']}>
        Cancel all form(s)
      </CancelButton>
      <SendButton onClick={save} width={[1, 1, '184px']}>
        Send Forms
      </SendButton>
    </GridContainer>
  );
}

const GridContainer = styled(AltModal.Actions)`
  ${grid}

  display: grid;
  align-items: center;
`;

const Button = styled.button`
  ${layout}

  height: 50px;

  font-weight: 600;
  font-size: 15px;

  border-radius: 3px;
`;

const EditButton = styled(Button)`
  color: #a6a7ad;
  border: 1px solid #a6a7ad;
  background-color: #fff;
`;

const CancelButton = styled(Button)`
  color: #a6a7ad;
  border: none;
  background-color: transparent;
`;

const SendButton = styled(Button)`
  color: #fff;
  background-color: #00b88d;
  border: 1px solid #00b88d;

  justify-self: end;
`;
