import { useState } from 'react';
import styled from 'styled-components';

import UsersAPI from 'api/usersAPI';
import auth from 'utils/auth';
import { queryParams } from 'utils/util';
import { GreenCheckIcon } from 'ui-kit/icons';
import { useRouteMatch } from 'react-router-dom';

const allowedQueryParams = new Set([...Object.values(queryParams)]);

const getSearchParamFieldName = () => {
  if (window.location.pathname.indexOf('jobslist') > -1) {
    return 'internal_jobs_default_search_params';
  } else if (window.location.pathname.indexOf('requisition-view') > -1) {
    return 'view_by_posting_default_search_params';
  } else {
    return 'view_all_default_search_params';
  }
};

const getDefaultSearchParams = () => {
  const searchParamFieldName = getSearchParamFieldName();
  let searchParams = auth.getUser().profile[searchParamFieldName];

  // window.location.search prepends a '?' to the string when
  // search params exist, so in order to check equality, add a '?'
  // searchParams exists.
  if (searchParams) {
    searchParams = '?' + searchParams;
  }

  return searchParams;
};

const paramsCurrentlyMatch = (templatesMatch) => {
  const defaultSearchParams = getDefaultSearchParams();

  if (defaultSearchParams === window.location.search) {
    // if strings match, return true. no need to check object equality.
    return true;
  } else if (templatesMatch) {
    // if we are on the templates list page, disable the button
    return true;
  } else {
    // if string lengths are same but strings don't === each other, we have to
    // check the param values because they could be equal but out of order.
    // For our purposes, those are still considered equal.
    const defaultSearchParamObject = new URLSearchParams(defaultSearchParams);
    const currentSearchParamObject = new URLSearchParams(window.location.search);

    return isEqual(defaultSearchParamObject, currentSearchParamObject);
  }
};

const isEqual = (defaultParams, currentParams) => {
  // Check that the values of defaultParams and currentParams are equal.
  // The values within each key will be arrays.
  // To check equality, iterate over the keys of the defaultParams and
  // check that each key:
  // 1) Is in currentParams
  // 2) Has value of length equal to that in currentParams
  // 3) Has same values as the value for currentParams[key]. Order does not matter.

  // remove special search params: "query" and "dr" which don't get counted
  // as part of the default filters.
  defaultParams.delete('query');
  currentParams.delete('query');
  defaultParams.delete('dr');
  currentParams.delete('dr');

  const defaultParamEntries = Array.from(defaultParams.entries());
  const currentParamEntries = Array.from(currentParams.entries());

  if (defaultParamEntries.length !== currentParamEntries.length) {
    return false;
  }

  for (const [filterParam, valuesString] of defaultParamEntries) {
    // ESLint recommends calling prototype methods from the prototype
    if (!currentParams.has(filterParam)) {
      return false;
    } else if (valuesString.length !== currentParams.get(filterParam).length) {
      return false;
    } else {
      // lengths match, so iterate over array and check if each value is in
      // the other object's array
      const defaultFilterValues = valuesString.split(',');
      const currentFilterValues = currentParams.get(filterParam).split(',');
      for (const filterValue of defaultFilterValues) {
        if (!currentFilterValues.includes(filterValue)) {
          return false;
        }
      }
    }
  }

  // object values are equal, so return true
  return true;
};

export default function SetAsDefaultButton() {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const templatesMatch = useRouteMatch('/district/templates');

  const setDefaultSearchParams = () => {
    setIsLoading(true);

    const searchParams = new URLSearchParams(window.location.search);
    // remove any query params that we don't want to save
    for (const param of searchParams.keys()) {
      if (!allowedQueryParams.has(param)) {
        searchParams.delete(param);
      }
    }

    // Default search params are stored in the auth user context.
    // Change them there and then save to the db.
    const user = auth.getUser();
    const searchParamFieldName = getSearchParamFieldName();
    user.profile[searchParamFieldName] = searchParams.toString();
    auth.setUser(user);

    const payload = {
      [searchParamFieldName]: searchParams.toString(),
    };
    UsersAPI.setDefaultSearchParams(user.id, payload)
      .then(() => {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 5000);
      })
      .finally(() => setIsLoading(false));
  };

  const renderButton = () => {
    if (isLoading) {
      return <GreenButton disabled>Set as my Default</GreenButton>;
    } else if (showConfirmation) {
      return (
        <GreenButton disabled>
          <GreenCheckIcon style={{ marginBottom: '-1px' }} /> Default Set
        </GreenButton>
      );
    } else if (paramsCurrentlyMatch(templatesMatch)) {
      return <GreyedOutButton disabled>Set as my Default</GreyedOutButton>;
    } else {
      return <GreenButton onClick={setDefaultSearchParams}>Set as my Default</GreenButton>;
    }
  };

  return <Container>{renderButton()}</Container>;
}

const Container = styled.div`
  padding: 30px 0;
  border-top: 1px solid #dfdfdf;

  text-align: center;
`;

const GreenButton = styled.button`
  width: 186px;
  height: 30px;

  background-color: #ffffff;
  border: 2px solid #00b88d;
  color: #00b88d;

  border-radius: 3px;
`;

const GreyedOutButton = styled(GreenButton)`
  border: 2px solid #eeeff2;
  color: #eeeff2;
`;
