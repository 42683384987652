import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DropdownWithInputFilter } from 'ui-kit';
import StatusDropdown from './StatusDropdown';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Tooltip } from '../../../../assets/tooltip.svg';

import { colorMapping } from 'utils/enums';

function RoleAndStatusSelection({
  addNewApplicationToList,
  applicationList,
  availableRoles,
  handleRoleChange,
  handleStatusChange,
  removeApplication,
}) {
  function roleOptions() {
    return availableRoles.map((role) => ({
      label: role.title,
      value: role.id,
    }));
  }

  const showRemoveButton = applicationList.length > 1;

  ReactTooltip.rebuild();

  return (
    <>
      <GridRow showRemoveButton={showRemoveButton}>
        <SectionHeader>Job Title</SectionHeader>
        <StatusHeader>
          <SectionHeader>Candidate Status</SectionHeader>
          <Tooltip data-tip data-for="status-header" data-place="bottom" />
          <ReactTooltip id="status-header" effect="solid" place="bottoms">
            <TooltipTextContainer>
              List of all statuses available for the job selected. Candidates cannot be added as
              hired for more than one job.
            </TooltipTextContainer>
          </ReactTooltip>
        </StatusHeader>
      </GridRow>
      {applicationList.map((application, index) => (
        <GridRowBody key={index} showRemoveButton={showRemoveButton}>
          <RoleDropdown>
            <DropdownWithInputFilter
              options={roleOptions()}
              value={application.role?.id || ''}
              handleChange={(value) => handleRoleChange(value, index)}
              placeholder="Type or select job title"
              boxShadow={false}
              onClear={() => {
                handleRoleChange({}, index);
                handleStatusChange({}, index);
              }}
            />
          </RoleDropdown>
          <StatusDropdownContainer>
            <StatusColor color={colorMapping[application.status.color]} />
            <StatusDropdown
              application={application}
              handleStatusChange={(status) => handleStatusChange(status, index)}
              showColor={!!application.status.color}
            />
          </StatusDropdownContainer>
          {applicationList.length > 1 && (
            <RemoveApplicationButton onClick={() => removeApplication(index)}>
              x
            </RemoveApplicationButton>
          )}
        </GridRowBody>
      ))}
      <AddRoleButton onClick={addNewApplicationToList}>+ Add Role</AddRoleButton>
    </>
  );
}

RoleAndStatusSelection.propTypes = {
  addNewApplicationToList: PropTypes.func.isRequired,
  applicationList: PropTypes.array.isRequired,
  availableRoles: PropTypes.array.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  removeApplication: PropTypes.func.isRequired,
};

export default RoleAndStatusSelection;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.showRemoveButton ? '2fr 10px 1fr 10px 50px' : '2fr 10px 1fr'};
`;

const GridRowBody = styled(GridRow)`
  margin-top: 8px;
`;

const SectionHeader = styled.p`
  color: #777777;
  font-size: 14px;
  margin-right: 1rem;
`;

const RoleDropdown = styled.div`
  color: #777777;
`;

const StatusHeader = styled.div`
  grid-column-start: 3;
  display: flex;
`;

const StatusDropdownContainer = styled.div`
  grid-column-start: 3;
  display: flex;
  align-items: center;
  position: relative;

  height: 50px;
  color: #777777;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
`;

const StatusColor = styled.span`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  min-width: 12px;
  margin-right: 8px;
  // prettier-ignore
  background-color: var(--${(props) => props.color?.toLowerCase()});
  position: absolute;
  left: 14px;
`;

const AddRoleButton = styled.p`
  margin-top: 14px;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  cursor: pointer;
  width: fit-content;
`;

const RemoveApplicationButton = styled.button`
  grid-column-start: 5;
  color: red;
  padding: 0 19px;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
`;

const TooltipTextContainer = styled.div`
  max-width: 190px;
`;
