import ApplicantDetails from './ApplicantDetails';
import { LowerProfileDetailsProps } from './types';
import StatusAndSource from './StatusAndSource';
import Subheader from './Subheader';

const LowerProfileDetails: React.FC<LowerProfileDetailsProps> = ({
  fullUser,
  fetchProspect,
  hasTalentMarketplaceSource,
  isDistrictAdmin,
  openProspectConversionModal,
  sources,
  updated,
  updatedBy,
  updateFullUser,
  updateProspectStatus,
}) => {
  return (
    <div>
      <Subheader updated={updated} updatedBy={updatedBy} createdAt={fullUser.created_at} />
      <ApplicantDetails
        email={fullUser.email}
        partialUser={fullUser}
        fullUser={fullUser}
        fetchProspect={fetchProspect}
        hasTalentMarketplaceSource={hasTalentMarketplaceSource}
        openProspectConversionModal={openProspectConversionModal}
      />
      <StatusAndSource
        updateProspectStatus={updateProspectStatus}
        isDistrictAdmin={isDistrictAdmin}
        fullUser={fullUser}
        updateFullUser={updateFullUser}
        sources={sources}
      />
    </div>
  );
};

export default LowerProfileDetails;
