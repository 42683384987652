import { AxiosResponse } from 'axios';

import { BaseNimbleAPI } from './baseAPI';
import { CandidateDistrictInformation, MarketplaceFitStatus, SearchTalentData } from 'types/types';

export interface SearchTalentMarketplaceCandidatesParams {
  query: string;
  district_id: string;
  marketplace_fit_status: MarketplaceFitStatus;
  experience_start: string;
  experience_end: string;
  credential_subjects: number[];
  job_subject_list;
  multilingual_school: number[];
  title_1_school: number[];
  turnaround_school: number[];
  grades: number[];
  preference_categories: number[];
  page?: number;
  page_size?: number;
  orderBy?: 'years_experience' | 'last_name';
  orderDir?: 'asc' | 'desc';
}

export interface SearchTalentMarketplaceCandidatesResponse {
  count: number;
  page: number;
  num_pages: number;
  next: string | null;
  previous: string | null;
  more: boolean;
  results: SearchTalentData[];
}

interface UpdateCandidateDistrictInformationParams {
  user: number;
  marketplace_fit_status: string;
}

class TalentMarketplaceAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/' };
  }

  async searchTalentMarketplaceCandidates(
    params: SearchTalentMarketplaceCandidatesParams
  ): Promise<SearchTalentMarketplaceCandidatesResponse> {
    const url = 'api/search/talent/';
    const response: AxiosResponse = await this.get(url, { params });
    return response.data;
  }

  async updateCandidateDistrictInformation(
    params: UpdateCandidateDistrictInformationParams
  ): Promise<CandidateDistrictInformation> {
    const updateUrl = 'api/candidate-district-info/update-cdi/';
    const response = await this.put(updateUrl, params);
    return response.data;
  }
}

export default new TalentMarketplaceAPI();
