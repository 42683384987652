import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';

import SchoolProfile from '../components/Profile/SchoolProfile';
import auth from '../utils/auth.js';
import './profile.css';
import { getQueryStringValue } from 'utils/util';
import { updateCandidateTask } from '../utils/statusviewutils';
import { refStatus, refContactType } from '../utils/enums';

import usersAPI from 'api/usersAPI';
import { withRouter } from 'react-router-dom';
import { deleteNoteFromState } from 'components/Profile/InternalCandidateNotes/utils';

/* Initial state objects
=================================================================== */
// Current candidate being viewed
const user = {
  // Current application being viewed
  schoolapplication: {
    district_application: {
      schoolapplications: [],
    },
  },
  answers: [],
  applications: [],
  credentials: [],
  custom_fields: [],
  district_references: [],
  education: [],
  experiences: [],
  id: '',
  internal_candidate_notes: [],
  languages: [],
  preference: {},
  profile: {},
  schoolapplications: [],
  tasks: [],
  requests: [],
};

const context = {
  adminUsers: [],
  sources: [],
  emailTemplatesList: [],
  newApplicationStatuses: [],
  referencesError: '',
  scorecardSeen: false,
};

class SchoolProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.getUser = auth.getUser();
    this.updateCandidateTask = updateCandidateTask.bind(this);
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const references = searchParams.get('references');
    const scorecard = searchParams.get('scorecard');
    const print = searchParams.get('print');
    this.references = references;
    this.query = searchParams;
    this.scorecard = scorecard;
    this.print = print;
  }

  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  state = {
    user,
    events: [],
    references: [],
    ...context,
  };

  /* Lifecycle methods
  ====================================================================== */
  componentDidMount() {
    document.body.classList.add('full-profile-page');
    this.fetchUser();
    this.fetchEvents();
    this.fetchContextData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchUser();
      this.fetchEvents();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('full-profile-page');
    this.ignoreLastFetch = true;
  }

  scrollToReferences = () => {
    const element = document.getElementById('references-section');
    if (element) {
      element.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
    }
  };

  fetchUser = () => {
    /* TODO: redirecting from this or the profile (src/containers/profile.js) route
     * is breaking the print modal toggle. When its redirected to a new profile
     * the print param is being dropped from the query. */
    const url = `/api/full-profile/user/${this.props.match.params.id}/schoolapplication/`;
    const params = {
      s_pk: getQueryStringValue('s_pk'),
    };
    axios.get(url, { params }).then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState(
          {
            user: r.data,
            references: r.data.district_references,
          },
          () => {
            if (this.props.query && this.props.references === 'true') {
              this.scrollToReferences();
            }
          }
        );
      }
    });
  };

  fetchEvents = () => {
    axios.get(`/api/events/${this.props.match.params.id}/`).then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({
          events: r.data,
        });
      }
    });
  };

  fetchContextData = () => {
    axios.get('/api/full-profile/context/').then(({ data }) => this.setState({ ...data }));
  };

  fetchReferences = () => {
    axios.get(`/api/user/${this.props.match.params.id}/get_references/`).then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ references: r.data });
      }
    });
  };

  applicationSuccessMessage = () => {
    return (
      <div className="isa_success">
        <i className="fa fa-check" />
        Congratulations! Your application is submitted and visible in the "Applications" panel
        below.
      </div>
    );
  };

  removeLocationQuery = (query) => {
    /** Get rid of the given query param, and turn this.state.scorecardSeen to true.
     * This method is invoked in ScorecardModal, and afterward, the clicked scorecard
     * will not automatically open.
     */
    const newQuery = { [query]: '' };

    const nextSearch = new URLSearchParams(this.props.location.search);
    Object.entries(newQuery).forEach(({ key, value }) => {
      nextSearch.set(key, value);
    });

    const nextLocation = {
      ...this.props.location,
      search: nextSearch.toString(),
    };

    this.props.history.push(location);

    if (query === 'scorecard') {
      this.setState({ scorecardSeen: true });
    }
  };

  incrementScorecardCount = () => {
    const updateUser = this.state.user;
    updateUser.scorecard_count++;
    this.setState({ user: updateUser });
  };

  updateReference = (row, fieldName, value, by_id = false) => {
    let references = this.state.references;
    if (row !== null) {
      if (by_id === true) {
        // if by_id is true, the id comes in the row field instead of the row.
        // find the row using findIndex.
        row = references.findIndex((r) => r.id === row);
      }
      references[row][fieldName] = value;
      if (
        ['name', 'title', 'email', 'phone', 'years_known', 'relationship', 'organization'].includes(
          fieldName
        ) &&
        references[row].id
      ) {
        // also update the updated_by fields in these cases
        references[row][fieldName + '_updated_by'] = {
          id: this.getUser.id,
          name: this.getUser.name,
        };
        references[row][fieldName + '_updated_when'] = new Date();
      }
      // reference has to have a name and either a phone or email in order to save
      if (references[row].name && (references[row].phone || references[row].email)) {
        this.debouncedReferenceUpdate(references[row], row);
      }
      this.setState({ referencesError: '' });
    } else {
      // if id is null, user is adding a new reference
      references.push(this.freshReference());
    }
    this.setState({ references });
  };

  removeReference = (id) => {
    let references = this.state.references;
    if (id) {
      axios.delete(`/api/references/${id}/`);
      let index = references.findIndex((r) => r.id === id);
      if (index !== -1) {
        references.splice(index, 1);
      }
    } else {
      references.splice(references.length - 1, 1);
    }
    this.setState({ references });
  };

  debouncedReferenceUpdate = _.debounce((reference, row) => {
    let url;
    let method;
    if (reference.id) {
      url = `/api/references/${reference.id}/`;
      method = 'put';
    } else {
      url = '/api/references/';
      method = 'post';
    }

    let referenceCopy = Object.assign({}, reference);
    // backend expects id instead of object, so the below changes the reference object accordingly.
    // Example:
    //
    // 'name_updated_by': { id: 1, name: 'Bob' }
    //
    // becomes:
    //
    // 'name_updated_by': 1
    //
    // Do this for the 6 fields below.
    ['name', 'title', 'email', 'phone', 'years_known', 'relationship', 'organization'].forEach(
      (field) => {
        if (referenceCopy[field + '_updated_by']) {
          referenceCopy[field + '_updated_by'] = referenceCopy[field + '_updated_by'].id;
        }
      }
    );

    referenceCopy.candidate = this.state.user.id;
    referenceCopy.district = this.getUser.profile.district.id;
    referenceCopy.candidate_updated_last = false;
    referenceCopy.candidate_updated_when = null;

    axios[method](url, referenceCopy).then((r) => {
      if (method === 'post') {
        // if user just created a new reference, take the id that was created on the backend
        // and returned from this call. add that id to the reference.
        let id = r.data.id;
        let references = this.state.references;
        references[row].id = id;
        this.setState({ references });
      }
    });
  }, 750);

  freshReference = () => {
    return {
      name: '',
      title: '',
      phone: '',
      email: '',
      years_known: '',
      relationship: '',
      organization: '',
      status: refStatus.not_started,
      contact_type: refContactType.none,
    };
  };

  setReferencesError = (error) => {
    this.setState({ referencesError: error });
  };

  updateCandidateInState = (fieldName, value) => {
    const user = { ...this.state.user };
    user[fieldName] = value;
    this.setState({ user });
  };

  replaceCandidateInState = (updatedCandidate) => {
    this.setState((prevState) => {
      return {
        user: { ...prevState.user, ...updatedCandidate },
      };
    });
  };

  onBirthdayChange = (birthday) => {
    const userId = this.props.match.params.id;

    let previousValue;

    this.setState((prevState) => {
      previousValue = prevState.user.profile.birthday;

      return {
        user: {
          ...prevState.user,
          profile: { ...prevState.user.profile, birthday },
        },
      };
    });

    return usersAPI
      .updateUser(userId, { birthday })
      .then((data) => {
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            ...data,
          },
        }));
      })
      .catch((error) => {
        console.error(error);
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            profile: { ...prevState.user.profile, birthday: previousValue },
          },
        }));
      });
  };

  updateInternalCandidateNote = async (newNote, recipientList, message) => {
    const user = { ...this.state.user };
    user.internal_candidate_notes.unshift(newNote); // add new note to beginning of notes array
    this.setState({ user });

    await axios.patch(`/api/user/${this.state.user.id}/`, { internalCandidateNote: newNote });

    this.emailTaggedUsers(recipientList, message, 'note');
  };

  deleteNote = (id) =>
    deleteNoteFromState(id, this.state.user, (updatedUser) => this.setState({ user: updatedUser }));

  emailTaggedUsers = (recipientList, message, type, id) => {
    const tagObjects = [];
    recipientList.forEach((recipient) => {
      const url = this.getUrl(recipient, type, id);
      const tagObj = {
        user_id: recipient.id,
        location: `in a ${type}`,
        note_url: url,
        note_copy: message,
        candidate_name: this.state.user.name,
      };
      tagObjects.push(tagObj);
    });

    this.sendTagNotifications(tagObjects);
  };

  getUrl = (recipient, type, id) => {
    const host = window.location.hostname;
    const colon = host.indexOf('localhost') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;
    const urlPrefix = `${recipient.profile.user_type >= 30 ? 'district' : 'school'}`;
    const urlApplication = this.getApplicationForUrl();
    // If a param is present, either the scorecard modal or the reference modal will open
    // when the user visits the page. The reference or scorecard that matches the param id
    // will be the one that is opened.
    let extraParam = '';
    if (type === 'scorecard' || type === 'reference') {
      extraParam = `&${type}=${id}`;
    }

    return `${location}/${urlPrefix}/school-profile/${this.state.user.id}/${urlApplication}${extraParam}`;
  };

  getApplicationForUrl = () => {
    return `?s_pk=${this.state.user.schoolapplication.id ?? ''}`;
  };

  sendTagNotifications = (tagObjects) => {
    return axios.post('/api/send_tag_notifications/', tagObjects);
  };

  /*
   *  Updates an individual schoolapplication (post SHP admin, principals)
   */
  updateSchoolApp = (statusObject) => {
    const {
      candidate_id,
      application_id,
      target_status,
      archiveOtherApps = true,
      multipleSchoolapplicationUpdate,
    } = statusObject;
    axios
      .put(`/api/schoolapplications/${application_id}/update_status/`, {
        target_status,
        archive_other_apps_when_hired: archiveOtherApps,
        job_status_list: this.state.job_status_list,
        multipleSchoolapplicationUpdate,
        candidate_id,
      })
      .then(() => {
        this.fetchUser();
        // If on full profile, update the events feed and update the user object directly
        this.fetchEvents();
      })
      .catch((error) => this.setState({ errors: error.response }));
  };

  render() {
    return (
      <SchoolProfile
        user={this.state.user}
        updateSchoolApp={this.updateSchoolApp}
        adminUsers={this.state.adminUsers}
        sources={this.state.sources}
        events={this.state.events}
        scorecardParam={
          this.props.scorecard !== undefined && this.props.scorecard !== ''
            ? Number(this.props.scorecard)
            : null
        }
        referenceParam={
          this.props.reference !== undefined && this.props.reference !== ''
            ? Number(this.props.reference)
            : null
        }
        removeLocationQuery={this.removeLocationQuery}
        scorecardSeen={this.state.scorecardSeen}
        emailTemplatesList={this.state.emailTemplatesList}
        printBool={this.props.print === 'true' ? true : false}
        incrementScorecardCount={this.incrementScorecardCount}
        newApplicationStatuses={this.state.newApplicationStatuses}
        updateReference={this.updateReference}
        replaceCandidateInState={this.replaceCandidateInState}
        updateCandidateInState={this.updateCandidateInState}
        fetchEvents={this.fetchEvents}
        updateCandidateTask={this.updateCandidateTask}
        fetchUser={this.fetchUser}
        fetchReferences={this.fetchReferences}
        references={this.state.references}
        removeReference={this.removeReference}
        setReferencesError={this.setReferencesError}
        referencesError={this.state.referencesError}
        onBirthdayChange={this.onBirthdayChange}
        updateInternalCandidateNote={this.updateInternalCandidateNote}
        emailTaggedUsers={this.emailTaggedUsers}
        deleteNote={this.deleteNote}
      />
    );
  }
}

export default withRouter(SchoolProfileContainer);
