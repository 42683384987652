import React from 'react';
import Stack from '@mui/material/Stack';
import { Chip } from 'sharedComponents/Chip/Chip';
import { VacanciesSectionProps } from '../types';
import { Vacancy } from 'types/connectTypes';

export const JobDetailsHighlightRow: React.FC<VacanciesSectionProps> = React.memo(({ vacancy }) => {
  const formatSalary = (salary: string | number): string => {
    if (!salary || salary === '0') return null;

    const stringifiedSalary = salary.toString();
    const numericSalary = parseInt(stringifiedSalary.replace(',', ''));
    if (isNaN(numericSalary)) return null;

    if (numericSalary >= 1000) {
      const formattedSalary = (numericSalary / 1000).toFixed(1) + 'k';
      return formattedSalary.replace('.0k', 'k');
    } else {
      return stringifiedSalary;
    }
  };

  const getSalaryString = (vacancy: Vacancy): string | null => {
    const { salary_min, salary_max } = vacancy;
    const minSalary = salary_min ? formatSalary(salary_min) : '';
    const maxSalary = salary_max ? formatSalary(salary_max) : '';

    if (minSalary && maxSalary) {
      return `$${minSalary}-$${maxSalary}`;
    } else if (minSalary && !maxSalary) {
      return `$${minSalary}`;
    } else if (!minSalary && maxSalary) {
      return `$${maxSalary}`;
    } else {
      return null;
    }
  };

  const formatEmploymentType = (vacancy: Vacancy): string | null => {
    if (vacancy.employment_type === 'full_time') {
      return 'Full-time';
    } else if (vacancy.employment_type === 'part_time') {
      return 'Part-time';
    } else {
      return null;
    }
  };

  const formatDatePosted = (vacancy: Vacancy): string | null => {
    if (!vacancy.date_posted) {
      return null;
    }

    const dateObj: Date = new Date(vacancy.date_posted);

    const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj
      .getDate()
      .toString()
      .padStart(2, '0')}/${dateObj.getFullYear()}`;

    return formattedDate;
  };

  const salary = getSalaryString(vacancy);
  const employmentType = formatEmploymentType(vacancy);
  const datePosted = formatDatePosted(vacancy);

  return salary || employmentType || datePosted ? (
    <Stack direction={'row'} spacing={1}>
      {salary && <Chip label={salary} />}
      {employmentType && <Chip label={employmentType} />}
      {datePosted && <Chip label={`Posted: ${datePosted}`} />}
    </Stack>
  ) : null;
});

JobDetailsHighlightRow.displayName = 'JobDetailsHighlightRow';
