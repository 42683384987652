import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default class DeactivateModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    saveButtonText: PropTypes.string.isRequired,
    backButtonText: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="account-modal deactivate"
      >
        <Modal.Header>
          <img src={this.props.icon} alt="" />
          <h3>{this.props.header}</h3>
        </Modal.Header>
        <Modal.Body>
          <h3>{this.props.mainText}</h3>
          <h4 className="deactivate-header">{this.props.subText}</h4>
          <div className="action-buttons-div">
            <button className="cancel" onClick={this.props.onCancel}>
              {this.props.backButtonText}
            </button>
            <button className="save" onClick={this.props.onSave}>
              {this.props.saveButtonText}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
