import { ResponsiveContainer, Cell, PieChart, Pie, Tooltip } from 'recharts';
import { CHART_COLOR_PALETTES_V2 } from 'features/StateDataDashboards/constants';
import styled from 'styled-components';
let colors = CHART_COLOR_PALETTES_V2;

export const PieChartV2Component = ({ dataset, total, useVacancyAsTotal }) => {
  return (
    <ResponsiveContainer height={375} width={400}>
      <PieChart margin={{ left: 20, bottom: 30 }}>
        <Pie
          data={dataset}
          innerRadius={'75%'}
          outerRadius={'95%'}
          paddingAngle={0}
          startAngle={225}
          endAngle={225 - 360}
          dataKey={useVacancyAsTotal ? 'vacancy_count' : 'value'}
        >
          {dataset.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip total={total} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = ({ payload, total }) => {
  for (const bar of payload) {
    return (
      <ToolTipContainer>
        {bar.name}
        <br />
        <StyledPercentage>
          {bar.payload.percentageLabel} ({bar.value})
        </StyledPercentage>
      </ToolTipContainer>
    );
  }
  return null;
};

const ToolTipContainer = styled.div`
  background-color: #ffffff;
  min-width: 73px;
  height: 58px;
  padding: 8px 18px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
`;

const StyledPercentage = styled.div`
  color: #00b88d;
`;
