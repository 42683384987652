export const CHART_COLOR_PALETTES_V2 = [
  '#304B7D',
  '#2F80ED',
  '#EF5675',
  '#FFA601',
  '#FF0000',
  '#00B88D',
  '#9747FF',
  '#63779F',
  '#56CCF2',
  '#6FCF97',
  '#F2C94C',
  '#F57F97',
];
