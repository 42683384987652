import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import EditItemEditorModal from './EditItemEditorModal';
import EditItemModal from './EditItemModal';
import { ReactComponent as EditIcon } from '../../assets/icons/AccountManagementIcons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/icon_trash.svg';
import { questionType } from 'utils/enums';
import { questionSetItemMapping } from './enums';
import schoolsAPI from 'api/schoolsAPI';

export default function ItemRow({ index, item, onSave, onDelete, questionBank, setEditItemPane }) {
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  // For Direction Text modal because ReactQuill not compatible w/ ui-kits
  // TODO switch all questions over to this modal because they will need WYSIWYG functionality
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    schoolsAPI.fetchActiveSchools().then((schoolOptions) => setSchoolOptions(schoolOptions));
  }, []);

  const getQuestionDetails = () => {
    let str = '';
    if (item.is_required) {
      str += 'required, ';
    }
    if (str) {
      str = str.slice(0, str.length - 2);
      return ` (${str})`;
    }
  };

  const getText = () => {
    if (item.question_type === questionType.schoolPreferences) {
      if (item.is_automatic_list) {
        return 'Automatic list';
      } else {
        return `Manual list - ${schoolOptions
          .filter((o) => item.school_choices.includes(o.id))
          .map((s) => s.name)
          .join(', ')}`;
      }
    } else if (item.question_type === questionType.nimble) {
      const q = questionBank.find((question) => question.id === item.nimblequestion_id);
      return q?.question ?? 'Nimble question not found';
    } else if (
      item.question_type === questionType.videoLink ||
      item.question_type === questionType.attachment
    ) {
      return item.title;
    } else if (item.question_type === questionType.direction_text) {
      const paragraphTag = document.createElement('p');
      paragraphTag.innerHTML = item.prompt;
      return paragraphTag.innerText;
    } else {
      return item.prompt;
    }
  };
  const closeModal = () => {
    setShowQuestionModal(false);
  };

  const Icon = questionSetItemMapping[item.question_type].icon;
  return (
    <>
      {!showQuestionModal && !showEditQuestionModal && (
        <QuestionContainer>
          <QuestionType>
            <QuestionTypeIconContainer>
              <Icon />
            </QuestionTypeIconContainer>
            <p>
              {questionSetItemMapping[item.question_type].label}
              {getQuestionDetails()}
            </p>
          </QuestionType>
          <DeleteButton onClick={() => onDelete(index)} />

          <QuestionText>{getText()}</QuestionText>

          <EditButton
            onClick={() => {
              if (setEditItemPane) return setEditItemPane(item);

              if (item.question_type === 'question_type/direction_text') {
                setShowEditQuestionModal(true);
              } else {
                setShowQuestionModal(true);
              }
            }}
          />
        </QuestionContainer>
      )}

      {showEditQuestionModal && (
        //For direction text only
        <EditItemEditorModal
          show={showEditQuestionModal}
          onHide={() => setShowEditQuestionModal(false)}
          saveQuestion={(updatedItem) => {
            setShowEditQuestionModal(false);
            onSave(updatedItem);
          }}
          question={item}
        />
      )}
      {showQuestionModal && (
        <EditItemModal
          isOpen={showQuestionModal}
          onClose={closeModal}
          item={item}
          onSave={(updatedItem) => {
            closeModal();
            onSave(updatedItem);
          }}
          onDelete={() => {
            closeModal();
            onDelete(index);
          }}
          questionBank={questionBank}
        />
      )}
    </>
  );
}

ItemRow.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  questionBank: PropTypes.array.isRequired,
};

const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-areas:
    'question-type   delete-button'
    'question-text   edit-button';
  grid-column-gap: 8px;
  grid-row-gap: 12px;

  border-radius: 3px;
  box-shadow: var(--box-shadow);
  background-color: white;
  padding: 21px;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 768px) {
    @supports (display: grid) {
      grid-column-gap: 15px;
    }
  }
`;

const QuestionType = styled.div`
  grid-area: question-type;
  display: flex;
  align-items: center;

  color: #999999;
  font-size: 15px;
`;

const QuestionTypeIconContainer = styled.div`
  width: 19px;
  height: 19px;
  margin-right: 10px;
`;

const DeleteButton = styled(DeleteIcon)`
  grid-area: delete-button;
  justify-self: end;

  margin-right: 3px;
  margin-top: 2px;
  cursor: pointer;
`;

const EditButton = styled(EditIcon)`
  grid-area: edit-button;
  justify-self: end;

  margin-top: 3px;
  cursor: pointer;
`;

const QuestionText = styled.p`
  grid-area: question-text;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (min-width: 768px) {
    @supports (display: grid) {
      font-size: 18px;
    }
  }
`;
