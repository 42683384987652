import { Component, ReactNode } from 'react';

import { Modal } from 'react-bootstrap';
import { makeAllUrlsValid } from '../../../utils/util';
import { CandidateEmailedModalProps } from './types';

export default class CandidateEmailedModal extends Component<CandidateEmailedModalProps> {
  createMarkup: () => { __html: string } = () => {
    return {
      __html: makeAllUrlsValid(this.props.viewedEmail.message),
    };
  };

  render(): ReactNode {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="candidate-emailed-modal white"
      >
        <Modal.Header>
          <div>
            <h4 className="inline-block">{this.props.viewedEmail.subject}</h4>
            <span style={{ float: 'right', cursor: 'pointer' }} onClick={this.props.onHide}>
              X
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="ql-editor" dangerouslySetInnerHTML={this.createMarkup()} />
        </Modal.Body>
      </Modal>
    );
  }
}
