import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)({
  alignItems: 'center',
  backgroundColor: 'white',
  border: '1px solid #eee',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '14px',
  justifyContent: 'space-between',
  padding: '20px 16px',
  position: 'relative',
  width: '100%',
});

export const Left = styled(Box)({
  display: 'flex',
});

export const Right = styled(Box)({
  display: 'flex',
});

export const CTA = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'end',
});
