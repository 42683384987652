import styled from 'styled-components';

import { Input, SingleClickDropdown } from 'ui-kit';
import { colorMapping } from 'utils/enums';

import { ReactComponent as DeleteIcon } from 'assets/icons/icon_trash.svg';

const onDemo = window.location.hostname === 'demo.hirenimble.com';

function MultipleChoiceAnswerSection({
  addAnswer,
  addAnswerDemo,
  mc_options,
  demo_mc_options,
  multi_select,
  toggleMultiSelect,
  toggleKnockoutQuestion,
  is_knockout_question,
  // Use job.statuses_available if we limit statuses to a role's workflow
  // when this is actually implemented.
  applicationStatuses,
  handleMultipleChoiceTextChange,
  handleMultipleChoiceStatusChange,
  handleChange,
  removeOption,
  // similar to removeOption, but for non-demo purposes
  removeMCOption,
}) {
  const multipleChoiceAnswerRowDemo = (option, index) => {
    const showRemoveButton = demo_mc_options.length > 2;
    if (is_knockout_question) {
      return (
        <MultipleChoiceKnockoutDiv key={index} showRemoveButton={showRemoveButton}>
          <>
            <Input
              type="text"
              value={option.optionText}
              placeholder={`option ${index + 1}`}
              onChange={(e) => handleMultipleChoiceTextChange(e, index)}
            />
            <PaddedTextContainer>
              <i>goes to</i>
            </PaddedTextContainer>
            <StatusDropdownContainer>
              <StatusColor color={colorMapping[option.knockoutStatus.color]} />
              <SingleClickDropdown.Dropdown
                label={option.knockoutStatus.label || 'Select a status'}
                border={true}
                boxShadow={false}
                showColor={true}
                paddingLeft={option.knockoutStatus.color ? '42px' : undefined}
              >
                {applicationStatuses?.map((status) => (
                  <StyledListItem
                    key={status.id}
                    onClick={() => handleMultipleChoiceStatusChange(status, index)}
                  >
                    {status.label}
                  </StyledListItem>
                ))}
              </SingleClickDropdown.Dropdown>
            </StatusDropdownContainer>
            {showRemoveButton && <RemoveAnswerIcon onClick={() => removeOption(index)} />}
          </>
        </MultipleChoiceKnockoutDiv>
      );
    } else {
      return (
        <MultipleChoiceAnswerDiv key={index} showRemoveButton={showRemoveButton}>
          <>
            <Input
              type="text"
              value={option.optionText}
              placeholder={`option ${index + 1}`}
              onChange={(e) => handleMultipleChoiceTextChange(e, index)}
            />
            {showRemoveButton && <RemoveAnswerIcon onClick={() => removeOption(index)} />}
          </>
        </MultipleChoiceAnswerDiv>
      );
    }
  };

  const multipleChoiceAnswerRow = (option, index) => {
    const showRemoveButton = mc_options.length > 2;
    return (
      <MultipleChoiceAnswerDiv key={index} showRemoveButton={showRemoveButton}>
        <Input
          name="mc_options"
          type="text"
          value={option}
          placeholder={`option ${index + 1}`}
          onChange={(e) => handleChange(e, index)}
        />
        {showRemoveButton && <RemoveAnswerIcon onClick={() => removeMCOption(index)} />}
      </MultipleChoiceAnswerDiv>
    );
  };

  return (
    <div>
      <SwitchAndTextContainer>
        <SwitchContainer on={multi_select ? 'true' : undefined} onClick={toggleMultiSelect}>
          <SwitchCircle on={multi_select ? 'true' : undefined} />
        </SwitchContainer>
        <BodyText>Allow multiple selections</BodyText>
      </SwitchAndTextContainer>
      {onDemo && (
        <SwitchAndTextContainer>
          <SwitchContainer
            on={is_knockout_question ? 'true' : undefined}
            onClick={toggleKnockoutQuestion}
          >
            <SwitchCircle on={is_knockout_question ? 'true' : undefined} />
          </SwitchContainer>
          <BodyText>Knockout question</BodyText>
        </SwitchAndTextContainer>
      )}
      <HeaderContainer>
        <Header>Answer options:</Header>
        <GreenClickableSpan onClick={onDemo ? addAnswerDemo : addAnswer}>
          + Add another answer
        </GreenClickableSpan>
      </HeaderContainer>
      <MultipleChoiceAnswerContainer>
        {onDemo
          ? demo_mc_options.map(multipleChoiceAnswerRowDemo)
          : mc_options.map(multipleChoiceAnswerRow)}
      </MultipleChoiceAnswerContainer>
    </div>
  );
}

export default MultipleChoiceAnswerSection;

const SwitchAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  & > p {
    color: #444444;
  }
`;

const SwitchContainer = styled.div`
  width: 50px;
  min-width: 50px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid;
  border-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  margin-right: 10px;
  position: relative;
  cursor: pointer;
`;

const SwitchCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  left: ${(props) => (props.on ? '23px' : '3px')};
  top: 3px;
  transition: left 300ms;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 1rem;
`;

const Header = styled.h5`
  font-weight: bold;
`;

const MultipleChoiceAnswerContainer = styled.div`
  margin-bottom: 2rem;
`;

const MultipleChoiceAnswerDiv = styled.div`
  display: grid;
  // one column for smaller modal
  // add an extra column if the remove button is showing
  grid-template-columns: 1fr ${({ showRemoveButton }) => (showRemoveButton ? '16px' : '')};
  grid-gap: 12px;
  align-items: center;
  margin-bottom: 16px;
`;

const MultipleChoiceKnockoutDiv = styled.div`
  display: grid;
  // one column for smaller modal
  // add an extra column if the remove button is showing
  grid-template-columns: 1fr ${({ showRemoveButton }) => (showRemoveButton ? '16px' : '')};
  grid-gap: 12px;
  align-items: center;
  margin-bottom: 16px;

  // breakpoint for large modals
  @media screen and (min-width: 992px) {
    // break into three columns if large modal and knockout question
    grid-template-columns: 1fr auto 1fr ${({ showRemoveButton }) =>
        showRemoveButton ? '16px' : ''};
  }
`;

const RemoveAnswerIcon = styled(DeleteIcon)`
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
`;

const PaddedTextContainer = styled.div`
  width: 75px;
  padding: 8px 0;
  text-align: center;
`;

const StatusDropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StatusColor = styled.span`
  border-radius: 50%;

  height: 12px;
  width: 12px;
  // prettier-ignore
  background-color: var(--${(props) => props.color?.toLowerCase()});

  position: absolute;
  left: 20px;
  align-self: center;
`;

const StyledListItem = styled(SingleClickDropdown.ListItem)`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;

const GreenClickableSpan = styled.span`
  font-size: 15px;
  cursor: pointer;
  color: #00b88d;
`;

const BodyText = styled.p`
  font-size: 15px;
  color: rgb(68, 68, 68);
`;
