import styled from 'styled-components';

import { AltModal, Button } from 'ui-kit';

export default function ConfirmationDialog({
  resultHandler,
  title,
  text,
  backLabel,
  confirmLabel,
  isOpen,
}) {
  const onClose = () => {
    resultHandler(false);
  };

  return (
    <AltModal.AltModal isOpen={isOpen} onClose={onClose}>
      <StyledAltModalTitle>{title ? title : 'Delete'}</StyledAltModalTitle>
      <StyledAltModalBody>{text ? text : 'Are you sure you want to delete?'}</StyledAltModalBody>
      <StyledActions>
        <Button variant="secondary" onClick={() => resultHandler(false)}>
          {backLabel ? backLabel : 'Back'}
        </Button>
        <Button variant="primary" onClick={() => resultHandler(true)}>
          {confirmLabel ? confirmLabel : 'Delete'}
        </Button>
      </StyledActions>
    </AltModal.AltModal>
  );
}

const StyledAltModalTitle = styled(AltModal.Title)`
  font-size: 20px;
`;

const StyledAltModalBody = styled(AltModal.Body)`
  font-size: 19px;
`;

const StyledActions = styled(AltModal.Actions)`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 100%;
  padding: 30px;

  & button {
    max-height: 50px;
  }

  & button:first-child {
    grid-column: 1 / 1;
  }

  & button:last-child {
    grid-column: 3 / 3;
  }
`;
