import { useState, useEffect } from 'react';
import auth from '../../../../utils/auth';
import { JobQuestionModal } from '../../../../components/JobEdit/JobQuestionModal';
import AddIndividualItems from '../../../../components/JobEdit/AddIndividualItems';
import EditJobQuestionModal from '../../../../components/JobEdit/EditJobQuestionModal';
import AddJobQuestionModal from '../../../../components/JobEdit/AddJobQuestionModal';
import QuestionSetModal from '../../../ApplicationQuestionSets/QuestionSetModal';
import AddQuestionSet from '../../../../components/JobEdit/AddQuestionSet';
import QuestionsForCandidate from '../../../../components/JobEdit/QuestionsForCandidate';
import { questionSource } from '../../../../utils/enums';
import { Container } from './TabsShareComponents';
import { generateId } from '../../../../utils/util';
import { QuestionSet, Question, QuestionType } from 'types/types';
import { QuestionsTabProps } from '../../types/types';
import { questionDefault } from '../../defaults';
import questionSetsAPI from '../../../../api/questionSetsAPI';

export default function QuestionsTab({
  job,
  questionBank,
  schoolsSelected,
  schoolOptions,
  applicationStatuses,
  jobQuestionsAndAttachmentsAndSets,
  setJobQuestionsAttachmentsAndSets,
}: QuestionsTabProps): React.ReactElement {
  const [questionSets, setQuestionSets] = useState<QuestionSet[]>([]);
  const [questionSelectionType, setQuestionSelectionType] = useState<QuestionType | null>(null);
  const [questionModalOpen, setQuestionModalOpen] = useState<boolean>(false);
  const [editQuestionModalOpen, setEditQuestionModalOpen] = useState<boolean>(false);
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState<boolean>(false);
  const [questionSetModalOpen, setQuestionSetModalOpen] = useState<boolean>(false);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState<QuestionSet | undefined>(
    undefined
  );
  const [questionIdBeingEdited, setQuestionIdBeingEdited] = useState<string | null>(null);
  const [isCreatingRoleQuestionSet, setIsCreatingRoleQuestionSet] = useState<boolean>(false);
  const [questionSetUUIDToEdit, setQuestionSetUUIDToEdit] = useState<string | null>(null);
  const [shouldShowTagModal, setShouldShowTagModal] = useState<boolean>(false);

  useEffect(() => {
    questionSetsAPI.list().then((qs) => setQuestionSets(qs));
  }, []);

  // closes QuestionSetModal and resets relevant state
  const closeQuestionSetModal = () => {
    setQuestionSetModalOpen(false);
    setQuestionSetUUIDToEdit(null);
    setQuestionModalOpen(false);
    setIsCreatingRoleQuestionSet(false);
  };
  const buildQuestion = (obj: Question, type: QuestionType) => {
    const finalObj: Question = { ...questionDefault };
    finalObj.is_required_external = obj.is_required_external;
    finalObj.is_required_internal = obj.is_required_internal;
    finalObj.is_visible_external = obj.is_visible_external;
    finalObj.is_visible_internal = obj.is_visible_internal;

    if (obj.id) {
      finalObj.id = obj.id;
    }
    if (obj.localId) {
      finalObj.localId = obj.localId;
    } else {
      finalObj.localId = generateId();
    }

    switch (type) {
      case QuestionType.direction_text:
        finalObj.prompt = obj.prompt !== '' ? obj.prompt : obj.text;
        finalObj.question_type = QuestionType.direction_text;
        break;
      case QuestionType.nimble:
        finalObj.nimblequestion_id = obj.nimblequestion_id;
        finalObj.question = obj.text;
        finalObj.question_type = QuestionType.nimble;
        finalObj.source = questionSource.nimble;
        break;
      case QuestionType.text_question_model:
        // these questions will look exactly the same as regular 'text' questions to the user,
        // but they're different models so we have to deal with that here.
        finalObj.question = obj.text;
        finalObj.question_type = QuestionType.nimble;
        break;
      case QuestionType.open_response:
        finalObj.prompt = obj.text;
        finalObj.question_type = QuestionType.open_response;
        finalObj.district = auth.getUser().profile.district.id;
        finalObj.is_legal = false;
        finalObj.is_district_default = false;
        finalObj.hint = '';
        break;
      case QuestionType.yes_no:
        finalObj.prompt = obj.text;
        finalObj.question_type = QuestionType.yes_no;
        finalObj.district = auth.getUser().profile.district.id;
        finalObj.is_legal = false;
        finalObj.is_district_default = false;
        finalObj.hint = '';
        finalObj.autotags = obj.autotags;
        break;
      case QuestionType.multiple_choice:
        finalObj.prompt = obj.text;
        finalObj.question_type = QuestionType.multiple_choice;
        finalObj.district = auth.getUser().profile.district.id;
        finalObj.is_legal = false;
        finalObj.is_district_default = false;
        finalObj.hint = '';
        finalObj.mc_options = obj.mc_options;
        finalObj.multi_select = obj.multi_select;
        break;
      case QuestionType.attachment:
        finalObj.question_type = QuestionType.attachment;
        finalObj.title = obj.text;
        finalObj.description = obj.description;
        break;
      case QuestionType.videoLink:
        finalObj.question_type = QuestionType.videoLink;
        finalObj.title = obj.text;
        finalObj.description = obj.description;
        break;
      case QuestionType.schoolPreferences:
        finalObj.question_type = QuestionType.schoolPreferences;
        finalObj.is_automatic_list = obj.is_automatic_list;
        finalObj.school_choices = obj.school_choices;
        break;
      case QuestionType.statementCheckbox:
        finalObj.question_type = QuestionType.statementCheckbox;
        finalObj.prompt = obj.text;
        finalObj.autotags = obj.autotags;
        break;
      default:
        break; // should never get here
    }

    return finalObj;
  };

  const updateQuestionSets = (q: QuestionSet[]) => {
    setQuestionSets(q);
    return q;
  };

  const saveQuestion = (obj: Question, type: QuestionType) => {
    /** take the provided object and type and save it to the job in state */
    const finalObj = buildQuestion(obj, type);
    const questionsAndSets = [...jobQuestionsAndAttachmentsAndSets];
    const index = questionsAndSets.findIndex((question) => question.localId === finalObj.localId);
    if (index !== -1) {
      questionsAndSets[index] = finalObj;
    } else {
      questionsAndSets.push(finalObj);
    }
    setJobQuestionsAttachmentsAndSets(questionsAndSets);
    setQuestionModalOpen(false);
    setEditQuestionModalOpen(false);
    setAddQuestionModalOpen(false);
  };

  const openQuestionModal = (type: QuestionType, obj: Question) => {
    if (obj && type === 'question_type/direction_text') {
      setQuestionSelectionType(type);
      setQuestionIdBeingEdited(obj?.localId);
      setQuestionModalOpen(false);
      setEditQuestionModalOpen(true);
    } else if (type === 'question_type/direction_text') {
      setQuestionSelectionType(type);
      setQuestionIdBeingEdited(obj?.localId);
      setQuestionModalOpen(false);
      setEditQuestionModalOpen(false);
      setAddQuestionModalOpen(true);
    } else {
      setQuestionSelectionType(type);
      setQuestionIdBeingEdited(obj && obj.localId);
      setQuestionModalOpen(true);
    }
  };
  const addQuestionSet = () => {
    /**
     * 1) for each item in question set, make a matching item for the role
     * 2) add each one to end of current jobQuestionsAndAttachmentsAndSets
     * 3) don't add item if:
     *    - it's a school preferences question and one is already on the role
     *    - it's a nimble question that is already on the role
     */
    if (!selectedQuestionSet?.items) {
      return;
    }
    const questionsAndSets = [...jobQuestionsAndAttachmentsAndSets];
    questionsAndSets.push({
      ...selectedQuestionSet,
      is_role_qs: false, //default is global qs
      localId: generateId(),
      is_qs: true,
    });
    setJobQuestionsAttachmentsAndSets(questionsAndSets);
    setSelectedQuestionSet(undefined);
  };

  const handleQuestionSetSelection = (questionSetUUID: string) => {
    const questionSet = questionSets.find((qs) => qs.uuid === questionSetUUID);
    setSelectedQuestionSet(questionSet);
  };

  const deleteQuestionRow = (localId: string) => {
    const questionsAndSets = [...jobQuestionsAndAttachmentsAndSets];
    const index = questionsAndSets.findIndex(
      (question) => question.localId === localId || question.id === Number(localId)
    );
    if (index >= 0) {
      questionsAndSets.splice(index, 1);
      setJobQuestionsAttachmentsAndSets(questionsAndSets);
    }
  };

  const openQuestionSetModal = (questionSetUUID: string | null, createRoleQuestionSet = false) => {
    if (questionSetUUID && createRoleQuestionSet) {
      // notify modal that the user intends to create a RoleQuestionSet
      setQuestionSetUUIDToEdit(questionSetUUID);
      setQuestionSetModalOpen(true);
      setIsCreatingRoleQuestionSet(true);
    } else {
      setQuestionSetUUIDToEdit(questionSetUUID);
      setQuestionSetModalOpen(true);
    }
  };

  const handleQuestionSetEditOptions = (questionSetUUID: string | null) => {
    const newList = jobQuestionsAndAttachmentsAndSets.map((q: Question | QuestionSet) => {
      if (q.uuid === questionSetUUID) {
        // toggle edit options list visibility
        return { ...q, isShowingEditOptions: !q.isShowingEditOptions };
      }
      // ensure other option lists are closed
      return { ...q, isShowingEditOptions: false };
    });

    // update list
    setJobQuestionsAttachmentsAndSets(newList);
  };

  const handleUpdateQuestionSetData = (updatedQuestionSet: QuestionSet, isDeleted = false) => {
    let updatedList: (QuestionSet | Question)[] = [];

    if (isDeleted) {
      // if a QuestionSet was deleted we remove it from the list
      updatedList = jobQuestionsAndAttachmentsAndSets.filter(
        (qs) => qs.uuid !== updatedQuestionSet.uuid
      );
    } else if (isCreatingRoleQuestionSet) {
      // convert global question set to local one
      updatedList = jobQuestionsAndAttachmentsAndSets.map((qs) =>
        qs.uuid === updatedQuestionSet.uuid
          ? { ...updatedQuestionSet, isShowingEditOptions: false }
          : qs
      );
    } else {
      // update global QuestionSet
      updatedList = jobQuestionsAndAttachmentsAndSets.map((qs) =>
        qs.uuid === updatedQuestionSet.uuid
          ? {
              ...updatedQuestionSet,
              localId: qs.localId,
              isShowingEditOptions: false,
            }
          : qs
      );
    }
    setJobQuestionsAttachmentsAndSets(updatedList);
  };

  const closeQuestionModal = () => {
    setQuestionSetModalOpen(false);
    setQuestionSetUUIDToEdit(null);
    setQuestionModalOpen(false);
    setQuestionIdBeingEdited(null);
    setIsCreatingRoleQuestionSet(false);
  };

  const unchosenNimbleQuestions = questionBank.filter(
    (question) =>
      !jobQuestionsAndAttachmentsAndSets
        .filter((chosenQuestion) => chosenQuestion.question_type === QuestionType.nimble)
        .map((chosenQuestion) => chosenQuestion.nimblequestion_id)
        .includes(question.id)
  );

  const findQuestion = () => {
    const question = jobQuestionsAndAttachmentsAndSets.filter(
      (q) => q.localId === questionIdBeingEdited
    )[0];

    return question as Question | null;
  };

  const handleTagModalOpen = () => {
    setShouldShowTagModal(true);
  };

  const handleTagModalClose = () => {
    setShouldShowTagModal(false);
  };

  return (
    <Container>
      <>
        <AddQuestionSet
          selectedQuestionSet={selectedQuestionSet}
          questionSets={questionSets}
          handleQuestionSetSelection={handleQuestionSetSelection}
          addQuestionSet={addQuestionSet}
          jobQuestionsAndAttachmentsAndSets={jobQuestionsAndAttachmentsAndSets}
        />
        <QuestionsForCandidate
          job={job}
          jobQuestionsAndAttachmentsAndSets={jobQuestionsAndAttachmentsAndSets}
          deleteQuestionRow={deleteQuestionRow}
          openQuestionModal={openQuestionModal}
          openQuestionSetModal={openQuestionSetModal}
          handleQuestionSetEditOptions={handleQuestionSetEditOptions}
          setItemOrder={(s: (Question | QuestionSet)[]) => setJobQuestionsAttachmentsAndSets(s)}
        />
        <AddIndividualItems
          unchosenNimbleQuestions={unchosenNimbleQuestions}
          openQuestionModal={openQuestionModal}
          jobQuestionsAndAttachmentsAndSets={jobQuestionsAndAttachmentsAndSets}
        />
        {addQuestionModalOpen && (
          <AddJobQuestionModal
            show={addQuestionModalOpen}
            onHide={() => {
              setQuestionSelectionType(null);
              setQuestionIdBeingEdited(null);
              setAddQuestionModalOpen(false);
            }}
            type={questionSelectionType}
            addQuestion={saveQuestion}
            internal_requirements_specified={job.internal_requirements_specified}
          />
        )}
        {editQuestionModalOpen && questionIdBeingEdited && (
          <EditJobQuestionModal
            show={editQuestionModalOpen}
            onHide={() => {
              setQuestionSelectionType(null);
              setQuestionIdBeingEdited(null);
              setEditQuestionModalOpen(false);
            }}
            saveQuestion={saveQuestion}
            question={findQuestion()}
            internal_requirements_specified={job.internal_requirements_specified}
          />
        )}
        {questionModalOpen && (
          <JobQuestionModal
            show={questionModalOpen}
            onHide={closeQuestionModal}
            type={questionSelectionType}
            questionData={findQuestion()}
            questionBank={unchosenNimbleQuestions}
            onSaveQuestion={saveQuestion}
            internal_requirements_specified={job.internal_requirements_specified}
            schools={schoolsSelected}
            schoolOptions={schoolOptions}
            applicationStatuses={applicationStatuses}
            handleTagModalOpen={handleTagModalOpen}
            handleTagModalClose={handleTagModalClose}
            shouldShowTagModal={shouldShowTagModal}
          />
        )}

        {questionSetModalOpen && (
          <QuestionSetModal
            questionSets={questionSets}
            setQuestionSets={updateQuestionSets}
            isOpen={questionSetModalOpen}
            onClose={closeQuestionSetModal}
            internalRequirementsSpecified={job.internal_requirements_specified}
            questionSetToEdit={
              questionSetUUIDToEdit &&
              jobQuestionsAndAttachmentsAndSets.find((q) => q.uuid === questionSetUUIDToEdit)
            }
            updateQuestionSetData={handleUpdateQuestionSetData}
            // values below are used to specify RoleQuestionSet related changes
            createLocalQuestionSet={isCreatingRoleQuestionSet}
            roleTitle={job.title}
          />
        )}
      </>
    </Container>
  );
}
