import styled from 'styled-components';

import { TabsProps } from './Tabs.types';

export interface TabButtonProps {
  isActive?: boolean;
}

const Tab = ({ handleTabClick, id, isActive, title }) => {
  return (
    <TabButton id={id} onClick={handleTabClick} isActive={isActive}>
      {title}
    </TabButton>
  );
};

const Tabs: React.FC<TabsProps> = ({ currentTab, handleTabClick, tabsData }) => {
  return (
    <TabsContainer>
      {tabsData.map((tab, index) => (
        <Tab
          handleTabClick={handleTabClick}
          id={index}
          isActive={currentTab === index}
          key={index}
          title={tab.title}
        />
      ))}
    </TabsContainer>
  );
};

export default Tabs;

const TabsContainer = styled('div')({
  display: 'flex',
  paddingBottom: '12px',
  marginTop: '12px',
  padding: '0 48px',
});

const TabButton = styled.button<TabButtonProps>`
  background-color: transparent;
  border: none;
  border-bottom: ${(props) => (props.isActive ? '4px solid #00A37D' : 'none')};
  button-style: none;
  color: ${(props) => (props.isActive ? '#00A37D' : '#4F4F4F')};
  font-size: 15px;
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  line-height: 20px;
  margin-right: 40px;
  padding: 12px;
`;
