import auth from 'utils/auth';

export const calculateDerivedStatusProperties = (status, isSchoolUser, activeStatus) => {
  switch (status) {
    case 0:
      return {
        statusColor: 'draft-grey',
        statusLabel: 'Draft',
        actionURL: 'jobedit',
      };
    case 10:
      return isSchoolUser || auth.isDistrictUserOnly()
        ? {
            statusColor: 'needs-approval-blue',
            statusLabel: 'Needs Approval',
            actionURL: 'jobview',
          }
        : {
            statusColor: 'needs-approval-blue',
            statusLabel: 'Needs Approval',
            actionURL: 'jobedit',
          };
    case 20:
      return activeStatus === 1
        ? {
            statusColor: 'green-2',
            statusLabel: 'Active, Open',
            actionURL: 'jobview',
          }
        : {
            statusColor: 'active-closed-yellow',
            statusLabel: 'Active, Closed',
            actionURL: 'jobview',
          };
    default:
      return {
        statusColor: 'archived-red',
        statusLabel: 'Archived',
        actionURL: 'jobview',
        archived: true,
      };
  }
};
