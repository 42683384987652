import { useState } from 'react';
import styled, { css } from 'styled-components';

import LoadingSpinner from 'components/loadingSpinner';
import { useSchoolGroups } from 'hooks/data/useSchoolGroups';
import { useActiveSchools } from 'hooks/data/useActiveSchools';
import { AddSchoolsToSchoolGroupModal } from './AddSchoolsToSchoolGroupModal';
import { AddEditSchoolGroupModal } from './AddEditSchoolGroupModal';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { PrimaryButton, ViewButton } from 'sharedComponents/Buttons';
import { SettingsRow } from 'components/SettingsRow';

const DEFAULT_MODAL_STATE = {
  isAddingSchoolGroup: false,
  isEditingSchoolGroup: false,
  isAddingSchoolsToSchoolGroup: false,
  schoolGroupBeingEditedId: null,
};

/**
 * School Groups section of the settings page
 * Spec: https://docs.google.com/document/d/1aKcWbwX8-DmuQ75G7nCKLwZeT6MEJCTfM0y9RdJfdpY/edit
 */
const SchoolGroups = ({
  canCreateSchoolGroups,
  canEditSchoolGroups,
  canDeleteSchoolGroups,
  canAddSchoolsToSchoolGroup,
}) => {
  const activeSchools = useActiveSchools();
  const schoolGroups = useSchoolGroups();
  const [expandedSchoolGroupId, setExpandedSchoolGroupId] = useState(null);
  const [modalState, setModalState] = useState({ ...DEFAULT_MODAL_STATE });

  const isLoading = schoolGroups.isLoading || activeSchools.isLoading;

  // Expands a school group list item
  const toggleExpandedSchoolGroupId = (schoolGroupId) => {
    if (expandedSchoolGroupId === schoolGroupId) {
      setExpandedSchoolGroupId(null);
    } else {
      setExpandedSchoolGroupId(schoolGroupId);
    }
  };

  // Closes all open modals
  const onCloseModalClick = () => {
    setModalState({ ...DEFAULT_MODAL_STATE });
  };

  // Opens the modal for adding a new school group
  const onNewGroupClick = () => {
    setModalState({
      ...DEFAULT_MODAL_STATE,
      isAddingSchoolGroup: true,
    });
  };

  // Opens the modal for editing a school group
  const onEditSchoolGroupClick = (schoolGroupId) => {
    setModalState({
      ...DEFAULT_MODAL_STATE,
      isEditingSchoolGroup: true,
      schoolGroupBeingEditedId: schoolGroupId,
    });
  };

  // Opens the modal for adding schools to a school group
  const onAddSchoolsToSchoolGroupClick = (schoolGroupId) => {
    setModalState({
      ...DEFAULT_MODAL_STATE,
      isAddingSchoolsToSchoolGroup: true,
      schoolGroupBeingEditedId: schoolGroupId,
    });
  };

  // Handles saving when adding/editing a school group
  const onSchoolGroupSave = (schoolGroupId, name) => {
    if (schoolGroupId) {
      schoolGroups.actions.updateSchoolGroup(schoolGroupId, { name });
    } else {
      schoolGroups.actions.createSchoolGroup(name);
    }
    onCloseModalClick();
  };

  // Handles saving when modifying the schools of a school group
  const onAddSchoolsToSchoolGroupSave = (schoolGroupId, schoolIds) => {
    schoolGroups.actions.updateSchoolGroup(schoolGroupId, { schools: schoolIds });
    onCloseModalClick();
  };

  // Handle the removal a school from a school group
  const onRemoveSchoolClick = (schoolGroup, schoolIdToRemove) => {
    const newSchoolIds = schoolGroup.schools.filter((id) => id !== schoolIdToRemove);
    schoolGroups.actions.updateSchoolGroup(schoolGroup.id, { schools: newSchoolIds });
  };

  return (
    <>
      <div className="mb2">
        <div className="mt2">
          <AnchorTagHeader
            id="schoolGroups"
            title="School Groups"
            buttonText="+ New Group"
            onClick={onNewGroupClick}
          />
          {isLoading ? (
            <LoadingSpinner margin={8} />
          ) : (
            <>
              <Section>
                <HeaderRow>
                  <div>Group Name</div>
                  <div>Schools</div>
                  <div>{/* For spacing */}</div>
                </HeaderRow>
              </Section>
              {schoolGroups.data.length === 0 && (
                <EmptySectionPlaceholder>
                  Click "+ New School Group" button above to create new group
                </EmptySectionPlaceholder>
              )}
              {schoolGroups.data.map((schoolGroup) => {
                const isExpanded = schoolGroup.id === expandedSchoolGroupId;
                const toggleRowExpand = () => toggleExpandedSchoolGroupId(schoolGroup.id);

                const schools = activeSchools.data.filter((school) =>
                  schoolGroup.schools.includes(school.id)
                );

                return (
                  <Section key={schoolGroup.id}>
                    <SettingsRow
                      onClick={toggleRowExpand}
                      cta={
                        <ListItemActions>
                          {(canEditSchoolGroups && (
                            <EditButton
                              onClick={(event) => {
                                event.stopPropagation();
                                onEditSchoolGroupClick(schoolGroup.id);
                              }}
                            />
                          )) || <div>{/* For spacing */}</div>}
                          {(canDeleteSchoolGroups && (
                            <DeleteButton
                              onClick={(event) => {
                                event.stopPropagation();
                                schoolGroups.actions.deleteSchoolGroup(schoolGroup.id);
                              }}
                            />
                          )) || <div>{/* For spacing */}</div>}
                          <CaretIconButton isActive={isExpanded}>
                            <ViewButton />
                          </CaretIconButton>
                        </ListItemActions>
                      }
                    >
                      <Text>
                        <Name>{schoolGroup.name}</Name>
                        <SchoolsCount>{`${schools.length} schools`}</SchoolsCount>
                      </Text>
                    </SettingsRow>
                    <ListItemExpandedSection isActive={isExpanded}>
                      {schools.length > 0 && (
                        <SchoolsList>
                          {schools.map((school) => {
                            return (
                              <SchoolsListItem key={school.id}>
                                <div>{school.name ?? 'Unknown'}</div>
                                {canAddSchoolsToSchoolGroup && (
                                  <DeleteButton
                                    onClick={() => onRemoveSchoolClick(schoolGroup, school.id)}
                                  />
                                )}
                              </SchoolsListItem>
                            );
                          })}
                        </SchoolsList>
                      )}
                      {canAddSchoolsToSchoolGroup && (
                        <PrimaryButton
                          sx={{ height: '40px', width: '160px' }}
                          onClick={() => onAddSchoolsToSchoolGroupClick(schoolGroup.id)}
                        >
                          + Add Schools
                        </PrimaryButton>
                      )}
                    </ListItemExpandedSection>
                  </Section>
                );
              })}
            </>
          )}
        </div>
      </div>
      <AddEditSchoolGroupModal
        isOpen={modalState.isAddingSchoolGroup || modalState.isEditingSchoolGroup}
        onCloseModalClick={onCloseModalClick}
        onSaveModalClick={(schoolGroupName) =>
          onSchoolGroupSave(modalState.schoolGroupBeingEditedId, schoolGroupName)
        }
        initialValue={
          (modalState.isEditingSchoolGroup &&
            schoolGroups.data.find((group) => group.id === modalState.schoolGroupBeingEditedId)
              ?.name) ||
          null
        }
      />
      {modalState.isAddingSchoolsToSchoolGroup && (
        <AddSchoolsToSchoolGroupModal
          isOpen={modalState.isAddingSchoolsToSchoolGroup}
          onCloseModalClick={onCloseModalClick}
          onSaveModalClick={(schoolIds) =>
            onAddSchoolsToSchoolGroupSave(modalState.schoolGroupBeingEditedId, schoolIds)
          }
          schoolGroup={schoolGroups.data.find(
            (group) => group.id === modalState.schoolGroupBeingEditedId
          )}
        />
      )}
    </>
  );
};

export default SchoolGroups;

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

const EmptySectionPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 208px;

  border: 1px dashed #949494;
  border-top: none;
  border-radius: 0 0 3px 3px;

  font-size: 17px;
  color: #bdbdbd;
`;

/**
 * Three column row. Looks kinda like a table...
 */
const Row = styled.div`
  display: flex;
  align-items: center;

  background-color: #fff;
  border-radius: 3px;

  & > div:first-child {
    flex: 1;
  }

  & > div:nth-child(2) {
    flex: 0 0 150px;
  }

  & > div:nth-child(3) {
    flex: 0 0 125px;
  }

  padding: 0 16px;
  & > div:not(:last-child) {
    padding-right: 1rem;
  }
`;

const HeaderRow = styled(Row)`
  height: 30px;

  font-size: 12px;
  color: #999999;
`;

const ListItemRow = styled(Row)`
  height: 60px;

  font-size: 15px;
  color: #444;
  color: var(--dark-text);

  cursor: pointer;
`;

/**
 * Row of action icons for a list item.
 */
const ListItemActions = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

/**
 * Wraps the icons to add some spacing, color, and effects.
 */

export const CaretIconButton = styled('div')`
  svg {
    transform: rotate(0deg);
    transition: transform 250ms ease-in-out;

    ${(props) =>
      props.isActive &&
      css`
        fill: #008060;
        transform: rotate(90deg);
      `}
  }
`;

const ListItemExpandedSection = styled.div`
  padding: 1em 1.5em;

  ${(props) =>
    !props.isActive &&
    css`
      visibility: hidden;
      height: 0;
      min-height: 0;
      padding-top: 0;
      padding-bottom: 0;

      * {
        visibility: hidden;
        height: 0;
        min-height: 0;
      }
    `}

  transition: all 250ms ease-in-out;

  background-color: ${(props) => props.theme.colors.pureWhite};
  border-top: 1px solid #eee;
  border-left: 5px solid #008060;
`;

const SchoolsList = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 400px;
  margin-bottom: 1em;
`;

const SchoolsListItem = styled.div`
  display: flex;
  align-items: Center;

  height: 40px;
  padding: 1.5em 0;

  font-size: 14px;

  :not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  & > div:first-child {
    flex: 1;
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Name = styled.div`
  flex: 1 1 0%;
`;

const SchoolsCount = styled.div`
  flex: 0 0 165px;
`;
