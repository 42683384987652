import { Answer as AnswerType } from 'components/ReferenceModal/types';
import {
  Answer,
  BoldText,
  RubricList,
  RubricListItem,
  FlexColumnBox,
  RubricTitle,
  StyledCheckIcon,
} from './styles';

export interface RubricPrintViewProps {
  rubric: AnswerType;
}

export const RubricPrintView: React.FC<RubricPrintViewProps> = ({ rubric }) => {
  return (
    <>
      <RubricTitle>{rubric.question_text}</RubricTitle>
      <RubricList>
        {rubric.rubric_columns.map((column) => {
          const isSelected = rubric.answer_rubric === column.number;

          return (
            <RubricListItem isSelected={isSelected} key={column.number}>
              {isSelected && <StyledCheckIcon />}
              <FlexColumnBox>
                <BoldText>{column.label}</BoldText>
                <Answer>{column.description}</Answer>
              </FlexColumnBox>
            </RubricListItem>
          );
        })}
      </RubricList>
    </>
  );
};
