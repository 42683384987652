import { Redirect } from 'react-router-dom';

import useCurrentUserPermissions from '../../hooks/useCurrentUserPermissions';

import { getInvalidUserTypeRedirectPathname, UserType } from '../../utils/userType';

interface UserAuthorized {
  children: React.ReactNode;
  disallow?: readonly UserType[];
  allow?: readonly UserType[];
}

// requiresUserTypes
const UserAuthorized: React.FC<UserAuthorized> = ({ children, allow, disallow }) => {
  const { hasApplications, userType } = useCurrentUserPermissions();

  if (disallow?.includes(userType) || !allow.includes(userType)) {
    const to = getInvalidUserTypeRedirectPathname(userType, hasApplications);
    return <Redirect to={to} />;
  }

  return <>{children}</>;
};

export default UserAuthorized;
