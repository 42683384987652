/**
 * Similar to mentionStyles but with different values for suggestions.list.top
 * and suggestions.list.left. This style renders the suggestion list to the right
 * of the @ character and goes down instead of up.
 */
export default {
  control: {
    backgroundColor: '#fff',
    marginBottom: '15px',
    border: '1px solid #D7D7D7',
    borderRadius: '3px',
  },

  highlighter: {
    overflow: 'hidden',
  },

  '&multiLine': {
    input: {
      padding: 9,
      minHeight: 80,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      width: 225,
      maxHeight: 223,
      overflow: 'auto',
      position: 'absolute',
      top: '-22px',
      left: '3px',
      boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)',
    },

    item: {
      padding: '8px',
      '&focused': {
        backgroundColor: '#f5f5f5',
      },
    },
  },
};
