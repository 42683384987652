import styled from 'styled-components';

export const FormControlContainer = styled.div`
  margin-top: 20px;

  .quill {
    background-color: #fff;
    border: 1px solid #dcdcdc;
    height: 100%;
    margin-bottom: 10px;
  }

  & .ql-container {
    height: calc(100% - 41px);
    padding-bottom: 20px;
  }
`;

export const ContentAndVariablesGrid = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 700px) {
    display: grid;
    grid-template-columns: 75% 25%;
  }
`;

export const VariablesContainer = styled.div`
  margin-top: 20px;
  padding-left: 0;

  @media (max-width: 700px) {
    #emailTemplateVariables {
      padding-left: 0;
    }
  }
`;

export const ErrorText = styled.p`
  font-size: 0.75rem;
  color: #d32f2f;
`;
