import { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import CandidatesList from '../components/CandidatesList';
import CandidatesFilters from '../components/CandidatesList/CandidatesFilters';
import ProspectFilters from '../components/CandidatesList/ProspectFilters';
import auth from '../utils/auth';
import { USStates, appStatusType } from '../utils/enums';
import CandidatesListHeader from '../components/CandidatesList/CandidatesListHeader';
import {
  fetchCandidates,
  fetchProspects,
  setSortFieldAndDirection,
  fetchCandidatesDebounced,
} from '../utils/candidatesSearchUtil';

import {
  fetchEmailTemplates,
  getInitialFilters,
  updateExperienceState,
  selectAll,
  clearAll,
  resetFilters,
} from '../utils/statusviewutils';

import categoriesAPI from 'api/categoriesAPI';
import customTagAPI from '../api/customTagAPI';
import districtAPI from '../api/districtAPI';
import prospectDetailAPI from '../api/prospectDetailAPI';
import schoolsAPI from 'api/schoolsAPI';
import ApplicationStatusesAPI from 'api/applicationStatusesAPI';

import { fetchDistrictTasks } from '../utils/universalTasksUtil';
import { prospectStatus } from 'utils/prospectenums';
import {
  deleteQueryStringValue,
  getQueryStringValue,
  setQueryStringValue,
  setQueryStringWithoutPageReload,
} from 'utils/util';
import { validateTagUserType } from '../components/CustomTag/util';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import TalentFiltersSidebar from 'features/TalentMarketplace/TalentFiltersSidebar';
import Tabs from 'components/Tabs';

import { preferenceCategories } from 'utils/enums';
import { pushQueryLocation } from 'utils/routing';
import candidateAPI from 'api/candidateAPI';
import { DEBOUNCE_TYPING_TIMEOUT } from '../utils/constants';

// TODO: remove prospect stuff from this
const tabsData = [
  { title: 'Not Reviewed', content: 'Not reviewed content' },
  { title: 'Potential Fit', content: 'Potential fit content' },
  { title: 'Not a Fit', content: 'Not a fit content' },
  { title: 'Converted to Prospect', content: 'Converted to prospect' },
];

class CandidatesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.isSchoolUser = auth.isSchoolUser();
    this.districtId = auth.getUser().profile.district.id;
    this.district = auth.getUser().profile.district;
    this.fetchEmailTemplates = fetchEmailTemplates.bind(this);
    this.setSortFieldAndDirection = setSortFieldAndDirection.bind(this);
    this.fetchCandidates = fetchCandidates.bind(this);
    this.fetchProspects = fetchProspects.bind(this);
    this.fetchCandidatesDebounced = fetchCandidatesDebounced.bind(this);
    this.getInitialFilters = getInitialFilters.bind(this);
    this.updateExperienceState = updateExperienceState.bind(this);
    this.selectAll = selectAll.bind(this);
    this.clearAll = clearAll.bind(this);
    this.resetFilters = resetFilters.bind(this);
    this.fetchDistrictTasks = fetchDistrictTasks.bind(this);
  }

  getInitialState = () => {
    return {
      candidates: [],
      categories: [],
      credentialOptions: [],
      query: '',
      errors: {},
      page: 0,
      more: false,
      orderBy: 'nimble_score',
      orderDir: 'desc',
      statusOptions: [],
      showSpinner: false,
      fetchCandidatesCompleted: false, // use for loading message below CandidatesList
      fetchCandidatesRunning: false, // use to avoid calling before previous one finishes
      totalCandidateCount: 0,
      experienceStart: 0,
      experienceEnd: 30,
      status_list: [],
      job_status_list: [],
      internal_list: [],
      cred_list: [],
      job_subject_list: [],
      task_list: [],
      tag_ids: [],
      hrbp_assignees: [],
      hrbpAssigneeOptions: [],
      hellosign_templates: [],
      language_list: [],
      employment_type_and_hours: [],
      /** new custom statuses */
      newApplicationStatuses: [],
      job_subject_length: 0,
      tag_ids_length: 0,
      hrbp_assignees_length: 0,
      universal_tasks: [],
      visible_templates: [],
      // When the user clicks an application to open the QuickView, the application will
      // be set here. This is still the minimal candidateslist version of the application
      // so it won't have all the necessary information. The application is passed into
      // QuickView which uses the ID to fetch the full application.
      partialApplication: {},
      application_type: 'application',
      partialUser: null,
      schoolAdminViewingDistrictApp: false,
      // candidate preference filters begin //
      multilingual_school: [],
      title_1_school: [],
      turnaround_school: [],
      grades: [],
      preference_categories: [],
      // candidate preference filters end //
      activeDistrictSchools: [],
      schoolPreferenceFilters: {
        isOpenToOtherOptions: true,
        selectedSchoolIds: [],
      },
      onboarding_locations: [],
      elasticSearchError: false,
      prospect_status_list: [],
      candidate_source_list: [],
      candidate_source_list_length: 0,
      candidateSourceOptions: [],
      availableApplicationStatuses: [],
    };
  };

  componentDidMount() {
    this.mountingCode();
  }

  componentWillReceiveProps(nextProps) {
    /** this component is used for both prospectlist and candidateslist, so switching between
     * the two doesn't automatically remount the component. So we have to check for a change
     * in the URL and remount manually.
     */
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.unmountingCode();
      this.setState(this.getInitialState(), this.mountingCode);
    }
  }

  componentWillUnmount() {
    this.unmountingCode();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query && this.state.query.length !== 1) {
      this.search();
    }
  }

  mountingCode = async () => {
    this.ignoreLastFetch = false;
    document.body.classList.add('candidates-list-page');
    document.body.classList.add('candidates');
    document.body.classList.add('new-candidate-line-items');
    const onProspectList = window.location.href.indexOf('prospectlist') !== -1;
    if (onProspectList) {
      document.body.classList.add('prospects');
      this.setProspectFiltersThenSearch();
    } else {
      this.setDefaultSearchParams();
      this.setCandidateFiltersThenSearch();
      this.fetchCandidatesSources();
      categoriesAPI
        .fetchCategories({ districtId: this.districtId })
        .then(this.setInitialCategories);
      this.fetchApplicationStatuses();
      this.fetchCredentialOptions();
      this.fetchDistrictTasks(true);
      this.fetchHellosignTemplates();
      schoolsAPI.fetchActiveSchools(this.districtId).then((activeDistrictSchools) => {
        this.setState({ activeDistrictSchools });
        this.setInitialSchoolPreferences(activeDistrictSchools);
        this.setInitialOnboardingLocations(activeDistrictSchools);
      });
      customTagAPI.fetchCustomTags().then((tags) => {
        const filteredTags = this.filteredDistrictTags(tags);
        this.setInitialTags(filteredTags);
      });
      // spec: "hrbp assignees" filter should include all district super/district admins
      if (auth.isDistrictAdmin(auth.getUser())) {
        districtAPI.getDistrictAndSuperAdmins(this.districtId).then(this.setInitialHrbpAssignees);
      }
      ApplicationStatusesAPI.getAll({
        districtId: this.districtId,
        statusTypes: [
          appStatusType.pre_hiring_pool,
          appStatusType.hiring_pool,
          appStatusType.archived,
          appStatusType.onboarding,
        ],
      }).then((data) => {
        this.setState({ availableApplicationStatuses: data });
      });
    }
  };

  unmountingCode = () => {
    this.ignoreLastFetch = true;
    document.body.classList.remove('candidates-list-page');
    document.body.classList.remove('candidates');
    document.body.classList.remove('prospects');
  };

  setDefaultSearchParams = () => {
    const authUser = auth.getUser();
    if (!window.location.search && authUser.profile.view_all_default_search_params) {
      setQueryStringWithoutPageReload('?' + authUser.profile.view_all_default_search_params);
    }
  };

  setProspectFiltersThenSearch = async () => {
    // fetch district tags and store in state
    const tags = await customTagAPI.fetchCustomTags();
    const filteredTags = this.filteredDistrictTags(tags, true);
    this.setInitialTags(filteredTags);

    await axios
      .get(`/api/candidate-source/`, {
        params: { district_id: this.districtId, all_sources: 'true' },
      })
      .then((r) => {
        r.data.unshift({ value: 'none', label: 'N/A' });
        const authUser = auth.getUser();

        const userHasTalentMarketplace = auth.hasTalentMarketplace(authUser);
        const userHasProspectsModule = auth.hasProspectsModule(authUser);

        if (userHasProspectsModule && !userHasTalentMarketplace) {
          r.data.push({ value: 'Talent Marketplace', label: 'Talent Marketplace' });
        }

        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const query = params.get('query');

        // Search for all status excluding "Archived"
        const prospect_status_list = prospectStatus()
          .filter((s) => s.key !== 'archived')
          .map((s) => s.value);
        const candidate_source_list = Object.assign([], r.data).map((source) => source.value);

        this.setState(
          {
            query: query || '',
            prospect_status_list,
            candidate_source_list,
            tag_ids: this.state.tag_ids,
            sources: r.data,
          },
          this.initialSearch
        );
      });
  };

  setCandidateFiltersThenSearch = () => {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const query = searchParams.get('query');
    const daysAgo = searchParams.get('days_ago');

    const {
      status_list,
      internal_list,
      cred_list,
      job_status_list,
      multilingual_school,
      title_1_school,
      turnaround_school,
      language_list,
      grades_list,
      preference_categories,
      employment_type_and_hours,
      experienceStart,
      experienceEnd,
      isOpenToOtherOptions,
      selectedSchoolIds,
      task_list,
      hellosign_templates,
      job_subject_list,
      tag_ids,
      hrbp_assignees,
      onboarding_locations,
      candidate_source_list,
    } = this.getInitialFilters(this.districtId);

    this.setState(
      {
        days_ago: daysAgo || '',
        query: query || '',
        status_list,
        internal_list,
        cred_list,
        job_status_list,
        multilingual_school,
        title_1_school,
        turnaround_school,
        language_list,
        grades: grades_list,
        preference_categories,
        employment_type_and_hours,
        experienceStart,
        experienceEnd,
        schoolPreferenceFilters: {
          selectedSchoolIds,
          isOpenToOtherOptions,
        },
        task_list,
        hellosign_templates,
        job_subject_list,
        onboarding_locations,
        tag_ids,
        hrbp_assignees,
        candidate_source_list,
      },
      this.initialSearch
    );
  };

  clearProspectFiltersThenSearch = () => {
    const prospect_status_list = prospectStatus().map((status) => status.value);
    const candidate_source_list = this.state.sources.map((source) => source.value);
    const tag_ids = this.state.tag_ids.map((tag) => tag.value);

    this.setState(
      {
        query: '',
        prospect_status_list,
        candidate_source_list,
        tag_ids,
      },
      this.search
    );
  };

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  fetchApplicationStatuses = () => {
    axios.get(`/api/application_statuses/`).then((r) => {
      this.setState({ newApplicationStatuses: r.data }, this.setStatusOptions);
    });
  };

  fetchCandidatesSources = async () => {
    const params = { district_id: this.districtId };
    candidateAPI.getCandidateSources(params).then((data) => {
      if (data) {
        data.unshift({ value: 'none', label: 'N/A' });

        const candidate_source_list = data.map((source) => source.value);

        this.setState(
          {
            candidateSourceOptions: candidate_source_list,
            sources: data,
          },
          this.setCandidateSourceOptions
        );
      }
    });
  };

  fetchCredentialOptions = () => {
    // eventually district state should be a foreign key to state model
    let stateObj = USStates().filter((state) => state.label === this.district.state)[0];
    axios.get(`/api/state/${stateObj.id}/`).then((r) => {
      let credentialOptionsFormatted = r.data.credential_subjects.map((item) => {
        item['key'] = item.id;
        item['label'] = item.value;
        item['value'] = item.id;
        return item;
      });
      this.setState({ credentialOptions: credentialOptionsFormatted }, this.setCredentialOptions);
    });
  };

  setCredentialOptions = () => {
    let credentialOptions = [...this.state.credentialOptions];
    // if there is no default candidate status filter, start with them all selected
    const f_C = getQueryStringValue('f_C');
    if (!f_C) this.setState({ cred_list: credentialOptions.map((s) => s.id) });

    this.setState({ credentialOptions });
  };

  setStatusOptions = () => {
    let statusOptions = [...this.state.newApplicationStatuses];
    if (this.isSchoolUser) {
      statusOptions = statusOptions.filter((s) => s.school_admin_view_access === true);
    }

    // if there is no default candidate status filter, start with them all selected
    const f_CS = getQueryStringValue('f_CS');
    if (!f_CS) this.setState({ status_list: statusOptions.map((s) => s.id) });

    this.setState({ statusOptions });
  };

  setCandidateSourceOptions = () => {
    let candidateSourceOptions = [...this.state.sources];
    const f_CSL = getQueryStringValue('f_CSL');
    if (!f_CSL)
      this.setState({
        candidate_source_list: candidateSourceOptions.map((source) => source.value),
        candidate_source_list_length: candidateSourceOptions.length,
      });

    this.setState({ candidateSourceOptions });
  };

  setInitialSchoolPreferences = (activeDistrictSchools) => {
    // if f_SS and f_O are both in the query string on mount
    // they have defaults set so don't overwrite them.
    const f_SS = getQueryStringValue('f_SS');
    const f_O = getQueryStringValue('f_O');

    // f_SS has to be compared to null because it can be an empty string,
    // in which case we don't want to overwrite it because the selectedSchoolIds
    // field should be empty.
    if (f_SS !== null && f_O) {
      return;
    }

    const activeSchoolIds = activeDistrictSchools.map((school) => school.id);

    this.setState((prevState) => {
      const newFilters = { ...prevState.schoolPreferenceFilters };
      // only update initial filter if a default is not set
      if (f_SS === null) newFilters.selectedSchoolIds = activeSchoolIds;
      if (!f_O) newFilters.isOpenToOtherOptions = true;
      return { schoolPreferenceFilters: newFilters };
    });
  };

  setInitialOnboardingLocations = (activeDistrictSchools) => {
    // if f_OL is in the query string on mount it has a
    // default set so don't overwrite.
    const f_OL = getQueryStringValue('f_OL');
    if (!f_OL) {
      const activeSchoolIds = activeDistrictSchools.map((school) => school.id);
      this.setState({ onboarding_locations: activeSchoolIds });
    }
  };

  setCurrentQuery = (newQuery, search) => {
    /** @param {boolean} search: true or undefined (not provided). If true, explicitly run this.search
     * because the query string may not have been updated. This currently happens when switching between
     * searching by Candidate and by Keyword on the front end.
     */
    this.setState(newQuery);

    const {
      history,
      location: { pathname, search: previousSearch },
    } = this.props;

    pushQueryLocation(newQuery, search, history, pathname, previousSearch, this.search);
  };

  search = _.debounce((paginate = true) => {
    let onProspectList = window.location.href.indexOf('prospectlist') !== -1;
    let fetchType = onProspectList ? 'fetchProspects' : 'fetchCandidates';
    this.setState(
      {
        candidates: [],
        page: 0,
        more: false,
      },
      () => this[fetchType](true, paginate, this.districtId)
    );
  }, DEBOUNCE_TYPING_TIMEOUT);

  initialSearch = (paginate = true) => {
    let onProspectList = window.location.href.indexOf('prospectlist') !== -1;
    let fetchType = onProspectList ? 'fetchProspects' : 'fetchCandidates';
    this[fetchType](true, paginate, this.districtId);
  };

  setApplicationCountPerCandidate = (candidates) => {
    candidates.forEach((candidate) => {
      candidate['app_count'] = candidate.applications.length;
    });
    return candidates;
  };

  updateMultiSelectFilter = (fieldName, fieldValue, active, optionsLength) => {
    /** this.state[fieldName] should always be an array. If active is true,
     * the filter option that was clicked is active and should be turned off, which means we
     * have to remove fieldValue from the array instead of adding it.
     */
    let updatedField;

    if (active) {
      // filter is active and the user wants to turn it off. Find the element and remove.
      updatedField = this.state[fieldName].filter((e) => e !== fieldValue);
    } else {
      updatedField = this.state[fieldName];
      updatedField.push(fieldValue);
    }

    if (updatedField.length === 0 || updatedField.length === optionsLength) {
      deleteQueryStringValue(fieldName);
    } else {
      setQueryStringValue(fieldName, updatedField.join());
    }
    this.setState({ [fieldName]: updatedField }, this.search);
  };

  updateMultiSelectFilterWithMultipleValues = (fieldName, values, optionsLength) => {
    /** Similar to this.updateMultiSelectFilter, but set multiple
     * values using an array instead of updating a single value.
     * The array can be empty.
     */

    if (values.length === 0 || values.length === optionsLength) {
      deleteQueryStringValue(fieldName);
    } else {
      setQueryStringValue(fieldName, values.join());
    }
    this.setState({ [fieldName]: values }, this.search);
  };

  setApplicationForQuickView = (partialApplication, partialUser) => {
    this.setState({ partialUser });
    let onProspectList = window.location.href.indexOf('prospectlist') !== -1;
    if (!onProspectList) {
      let application_type =
        partialApplication.role && partialApplication.role.district_role
          ? 'schoolapplication'
          : 'application';
      this.setState({
        partialApplication,
        application_type,
      });
    }
  };

  updateProspectStatus = (selectedStatus, prospect_id) => {
    const updateObject = { status: selectedStatus.value };
    this.updateProspectInState(updateObject, prospect_id);
  };

  updateProspectInState = (updateObject, prospect_id) => {
    const candidates = this.state.candidates;
    const candidate = candidates.find((candidate) => candidate.prospect_id === prospect_id);
    if (candidate) {
      for (const property in updateObject) {
        candidate[property] = updateObject[property];
      }
      this.setState({ candidates });
    }
  };

  retrieveProspect = (prospect_id) => {
    prospectDetailAPI.getProspect(prospect_id).then((prospect) => {
      this.setApplicationForQuickView(null, prospect);
      this.replaceProspectInState(prospect);
    });
  };

  /** Similar to updateProspectInState, but this replaces the entire object with
   * a new prospect instead of updating only certain fields.
   */
  replaceProspectInState = (prospect) => {
    const candidates = this.state.candidates;
    const index = candidates.findIndex((candidate) => candidate.id === prospect.id);
    if (index > -1) {
      candidates[index] = prospect;
      this.setState({ candidates });
    }
  };

  updateSchoolApp = (updatedCandidate) => {
    this.updateCandidateInState(updatedCandidate);
  };

  updateCandidateInState = (updatedCandidate) => {
    this.setState((prevState) => {
      return {
        candidates: prevState.candidates.map((candidate) =>
          candidate.id === updatedCandidate.id ? { ...candidate, ...updatedCandidate } : candidate
        ),
      };
    });
  };

  refreshCandidateInList = (candidateId) => {
    const params = {
      job_status_list: this.state.job_status_list,
      role_id: null,
    };
    axios
      .get(`/api/user/${candidateId}/candidates_list_data/`, { params })
      .then(({ data }) => this.updateCandidateInState(data));
  };

  selectAllSchoolPreferencesFilters = () => {
    // selecting all deactivates the filter, so remove search params
    deleteQueryStringValue('selectedSchoolIds');
    deleteQueryStringValue('isOpenToOtherOptions');

    this.setState(
      {
        schoolPreferenceFilters: {
          isOpenToOtherOptions: true,
          selectedSchoolIds: this.state.activeDistrictSchools.map((school) => school.id),
        },
      },
      this.search
    );
  };

  clearAllSchoolPreferencesFilters = () => {
    deleteQueryStringValue('selectedSchoolIds');
    deleteQueryStringValue('isOpenToOtherOptions');

    this.setState(
      {
        schoolPreferenceFilters: {
          isOpenToOtherOptions: false,
          selectedSchoolIds: [],
        },
      },
      this.search
    );
  };

  updateSelectedSchoolIds = (schoolId) => {
    this.setState((prevState) => {
      let selectedSchoolIds = prevState.schoolPreferenceFilters.selectedSchoolIds;
      if (selectedSchoolIds.includes(schoolId)) {
        selectedSchoolIds = selectedSchoolIds.filter((id) => id !== schoolId);
      } else {
        selectedSchoolIds = [...selectedSchoolIds, schoolId];
      }

      this.handleSchoolPreferencesQueryString(
        selectedSchoolIds,
        prevState.schoolPreferenceFilters.isOpenToOtherOptions
      );

      return {
        schoolPreferenceFilters: {
          ...prevState.schoolPreferenceFilters,
          selectedSchoolIds,
        },
      };
    }, this.search);
  };

  updateIsOpenToOtherOptions = () => {
    this.setState((prevState) => {
      // this is what we're actually toggling, but we need the currentSchools value
      // in order to determine whether the filter is active or not
      const isOpenToOtherOptions = !prevState.schoolPreferenceFilters.isOpenToOtherOptions;

      this.handleSchoolPreferencesQueryString(
        prevState.schoolPreferenceFilters.selectedSchoolIds,
        isOpenToOtherOptions
      );

      return {
        schoolPreferenceFilters: {
          ...prevState.schoolPreferenceFilters,
          isOpenToOtherOptions,
        },
      };
    }, this.search);
  };

  handleSchoolPreferencesQueryString = (selectedSchoolIds, isOpenToOtherOptions) => {
    const noneSelected = selectedSchoolIds.length === 0 && !isOpenToOtherOptions;
    const allSelected =
      selectedSchoolIds.length === this.state.activeDistrictSchools.length && isOpenToOtherOptions;

    if (noneSelected || allSelected) {
      deleteQueryStringValue('isOpenToOtherOptions');
      deleteQueryStringValue('selectedSchoolIds');
    } else {
      setQueryStringValue('isOpenToOtherOptions', isOpenToOtherOptions.toString());
      setQueryStringValue('selectedSchoolIds', selectedSchoolIds.join(','));
    }
  };

  fetchHellosignTemplates = () => {
    axios.get('/api/hellosign/visible_templates/').then(({ data }) => {
      const visible_templates = data;
      this.setState({ visible_templates });

      const f_HT = getQueryStringValue('f_HT');

      if (!f_HT) {
        let hellosign_templates = [];

        visible_templates.forEach(({ template_id }) => {
          hellosign_templates.push(template_id);
          hellosign_templates.push(`-${template_id}`);
        });

        this.setState({ hellosign_templates });
      }
    });
  };

  setInitialCategories = (categories) => {
    let job_subject_list = [];
    categories.forEach((c) => {
      c.subcategories.forEach((s) => {
        job_subject_list.push(Number(s.id));
      });
    });

    this.setState({
      categories,
      job_subject_length: job_subject_list.length,
    });

    // only set job subjects if there is no default filter set
    const f_JSU = getQueryStringValue('f_JSU');
    if (!f_JSU) {
      this.setState({ job_subject_list });
    }
  };

  setInitialTags = (tags) => {
    const tag_ids = tags.map((tag) => Number(tag.id));

    // add full tag data to state
    this.setState({
      tags,
      tag_ids_length: tag_ids.length,
    });

    // only set tags if there is no default filter set
    const f_TG = getQueryStringValue('f_TG');
    if (!f_TG) {
      this.setState({
        tag_ids,
      });
    }
  };

  setInitialHrbpAssignees = (hrbpAssigneesData) => {
    const hrbp_assignees = hrbpAssigneesData.map((assignee) => assignee.id);

    // add full admin user data to state
    this.setState({
      hrbpAssigneeOptions: hrbpAssigneesData.map((assignee) => ({
        value: assignee.id,
        label: assignee.name,
      })),
      hrbp_assignees_length: hrbpAssigneesData.length,
    });

    // only set users if there is no default filter set
    const f_HA = getQueryStringValue('f_HA');
    if (!f_HA) {
      this.setState({
        hrbp_assignees,
      });
    }
  };

  // user should only see tags related to their user_type
  filteredDistrictTags = (tag_ids, isProspectlist = false) => {
    const filteredTags = tag_ids.filter((tag) => {
      // exclude prospect only tag types (but include hybrid tags that apply to both)
      if (!isProspectlist && !tag.candidate_type && tag.prospect_type) return false;
      // exclude candidate only tag types if on prospectlist (and include hybrid tags)
      else if (isProspectlist && tag.candidate_type && !tag.prospect_type) return false;

      // tag is not meant for current user
      if (!validateTagUserType(auth.getUser(), tag)) return false;

      // tag is valid
      return tag;
    });
    return filteredTags;
  };

  updateCandidatesInContainerState = (updatedCandidates) => {
    this.setState((prevState) => {
      const candidates = prevState.candidates.map((candidate) => {
        let updatedCandidate = updatedCandidates.find((c) => c.id === candidate.id);
        return updatedCandidate ? { ...candidate, ...updatedCandidate } : candidate;
      });
      return { candidates };
    });
  };

  isSchoolPreferenceFilterActive = () => {
    const { isOpenToOtherOptions, selectedSchoolIds } = this.state.schoolPreferenceFilters;
    const allOptionsSelected =
      selectedSchoolIds.length === this.state.activeDistrictSchools.length && isOpenToOtherOptions;
    const noOptionsSelected = selectedSchoolIds.length === 0 && !isOpenToOtherOptions;
    const isActive = !(allOptionsSelected || noOptionsSelected);
    return isActive;
  };

  sideBarToRender = (onProspectList) => {
    if (onProspectList) {
      return (
        <ProspectFilters
          updateMultiSelectFilter={this.updateMultiSelectFilter}
          prospect_status_list={this.state.prospect_status_list}
          candidate_source_list={this.state.candidate_source_list}
          tag_ids={this.state.tag_ids}
          tags={this.state.tags}
          all_sources={this.state.sources}
          selectAll={this.selectAll}
          clearAll={this.clearAll}
        />
      );
    } else {
      return (
        <CandidatesFilters
          statusOptions={this.state.statusOptions}
          experienceStart={this.state.experienceStart}
          experienceEnd={this.state.experienceEnd}
          updateMultiSelectFilter={this.updateMultiSelectFilter}
          isSchoolPreferenceFilterActive={this.isSchoolPreferenceFilterActive}
          updateMultiSelectFilterWithMultipleValues={this.updateMultiSelectFilterWithMultipleValues}
          status_list={this.state.status_list}
          job_status_list={this.state.job_status_list}
          internal_list={this.state.internal_list}
          cred_list={this.state.cred_list}
          job_subject_list={this.state.job_subject_list}
          tag_ids={this.state.tag_ids}
          tags={this.state.tags}
          hrbp_assignees={this.state.hrbp_assignees}
          hrbpAssigneeOptions={this.state.hrbpAssigneeOptions}
          task_list={this.state.task_list}
          hellosign_templates={this.state.hellosign_templates}
          language_list={this.state.language_list}
          employment_type_and_hours={this.state.employment_type_and_hours}
          categories={this.state.categories}
          updateExperienceState={this.updateExperienceState}
          selectAll={this.selectAll}
          clearAll={this.clearAll}
          universal_tasks={this.state.universal_tasks}
          visible_templates={this.state.visible_templates}
          multilingual_school={this.state.multilingual_school}
          title_1_school={this.state.title_1_school}
          turnaround_school={this.state.turnaround_school}
          grades={this.state.grades}
          preference_categories={this.state.preference_categories}
          onboarding_locations={this.state.onboarding_locations}
          activeDistrictSchools={this.state.activeDistrictSchools}
          schoolPreferenceFilters={this.state.schoolPreferenceFilters}
          selectAllSchoolPreferencesFilters={this.selectAllSchoolPreferencesFilters}
          clearAllSchoolPreferencesFilters={this.clearAllSchoolPreferencesFilters}
          updateSelectedSchoolIds={this.updateSelectedSchoolIds}
          updateIsOpenToOtherOptions={this.updateIsOpenToOtherOptions}
          credentialOptions={this.state.credentialOptions}
          candidateSourceOptions={this.state.candidateSourceOptions}
          candidate_source_list={this.state.candidate_source_list}
          all_sources={this.state.sources}
        />
      );
    }
  };

  render() {
    const currentLocation = this.props.location.pathname;
    const onProspectList = currentLocation === '/prospectlist';
    const onTalentMarketplace = currentLocation === '/talent-marketplace';
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const daysAgo = searchParams.get('days_ago');

    return (
      <div className="candidateslist-and-filter-container">
        {this.sideBarToRender(onProspectList)}
        <div className={`candidateslist-container ${onProspectList ? 'prospectlist' : ''}`}>
          <CandidatesListHeader
            query={this.state.query}
            daysAgo={daysAgo}
            setCurrentQuery={this.setCurrentQuery}
            totalCandidateCount={this.state.totalCandidateCount}
            onProspectList={onProspectList}
            onTalentMarketplace={onTalentMarketplace}
            onViewByPosting={false}
            resetFilters={onProspectList ? this.clearProspectFiltersThenSearch : this.resetFilters}
            elasticSearchError={this.state.elasticSearchError}
            defaultSort="nimble_score"
            defaultOrder="desc"
          />
          {onTalentMarketplace && <Tabs tabsData={tabsData} />}
          <CandidatesList
            candidates={this.state.candidates}
            hasMore={this.state.more}
            fetchCandidates={this.fetchCandidates}
            fetchProspects={this.fetchProspects}
            fetchCandidatesDebounced={this.fetchCandidatesDebounced}
            search={this.search}
            isSchoolUser={this.isSchoolUser}
            emailTemplatesList={this.state.emailTemplatesList}
            totalCandidateCount={this.state.totalCandidateCount}
            orderBy={this.state.orderBy}
            orderDir={this.state.orderDir}
            setSortFieldAndDirection={this.setSortFieldAndDirection}
            jobTitle={''}
            newApplicationStatuses={this.state.newApplicationStatuses}
            updateCandidateInternalExternal={this.updateCandidateInternalExternal}
            onProspectList={onProspectList}
            onViewByPosting={false}
            retrieveProspect={this.retrieveProspect}
            partialApplication={this.state.partialApplication}
            application_type={this.state.application_type}
            setApplicationForQuickView={this.setApplicationForQuickView}
            partialUser={this.state.partialUser}
            job_status_list={this.state.job_status_list}
            fetchCandidatesRunning={this.state.fetchCandidatesRunning}
            updateProspectStatus={this.updateProspectStatus}
            refreshCandidateInList={this.refreshCandidateInList}
            showSpinner={this.state.showSpinner}
            availableApplicationStatuses={this.state.availableApplicationStatuses}
            updateCandidatesInContainerState={this.updateCandidatesInContainerState}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CandidatesListContainer);
