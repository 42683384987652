import styled from 'styled-components';

import { AltModal, Button } from 'ui-kit';

export default function ScorecardEditConfirmation({ onClose, onSave, onDiscard, isOpen }) {
  return (
    <AltModal.AltModal isOpen={isOpen} onClose={onClose}>
      <StyledAltModalTitle>Unsaved changes</StyledAltModalTitle>
      <StyledAltModalBody>
        Looks like you have unsaved changes to this scorecard form. Would you like to save?
      </StyledAltModalBody>
      <StyledActions>
        <Button variant="secondary" onClick={() => onClose()}>
          Back
        </Button>
        <Button variant="secondary" onClick={() => onDiscard()}>
          Exit without saving
        </Button>
        <Button variant="primary" onClick={() => onSave()}>
          Save & exit
        </Button>
      </StyledActions>
    </AltModal.AltModal>
  );
}

const StyledAltModalTitle = styled(AltModal.Title)`
  font-size: 20px;
`;

const StyledAltModalBody = styled(AltModal.Body)`
  font-size: 19px;
`;

const StyledActions = styled(AltModal.Actions)`
  display: grid;
  grid-template-columns: 1fr 200px 200px;
  grid-template-rows: 100%;
  grid-gap: 50px;
  padding: 30px;

  & button {
    max-height: 50px;
    width: 200px;
  }
`;
