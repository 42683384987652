import { useState, useRef, useCallback, useEffect } from 'react';

// hooks
import { useStateFilterOptions } from 'hooks/data/useStateFilterOptions';
import { useStateWideJobs } from 'hooks/data/useStateWideJobs';

// components
import PageNotFound from 'components/PageNotFound';
import { StateJobBoardHeader } from './StateJobBoardHeader';
import StateJobBoardFilterDropDowns from './StateJobBoardFilterDropDowns';
import StateJobsList from './StateJobsList';
import JobAlerts from './JobAlerts/index';

import { grades as gradesEnum, USStates } from 'utils/enums';
import { useRouteMatch } from 'react-router-dom';
import auth from '../../utils/auth';
import { getOrSetPreferenceFlag } from 'utils/preferenceConfig';
import StateTalentModal from './StateTalentModal';
import { StateJobBoardFilterContainer } from './StateJobBoardFilterContainer';
import { Box, Stack, styled } from '@mui/material';

export default function StateJobBoard(props) {
  const match = useRouteMatch('/jobs/state/:id');
  const rawStateCode = match?.params.id;
  const stateCode = rawStateCode?.toUpperCase();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [grades, setGrades] = useState(gradesEnum().map((grade) => grade.value));
  const [fulltime, setFulltime] = useState([0, 1]);
  const [schoolIds, setSchoolIds] = useState([]);
  const [subcategoryStateIds, setSubcategoryStateIds] = useState([]);
  const [districtIds, setDistrictIds] = useState([]);
  const [startDate, setStartDate] = useState(['immediate', 'next_school_year']);
  const [startDateBefore, setStartDateBefore] = useState(null);
  const [startDateAfter, setStartDateAfter] = useState(null);
  const [locationInput, setLocationInput] = useState(null); // TODO: use user's preferred location?
  const [distanceRadiusMiles, setDistance] = useState(null);
  const [locationLongitude, setLocationLongitude] = useState('');
  const [locationLatitude, setLocationLatitude] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [useDebounce, setUseDebounce] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    grades: false,
    fulltime: false,
    schoolIds: false,
    subcategoryStateIds: false,
    districtIds: false,
    startDate: false,
    distanceRadiusMiles: false,
    locationLongitude: false,
    locationLatitude: false,
  });

  const filterValues = {
    stateCode,
    page,
    query,
    grades,
    fulltime,
    schoolIds,
    subcategoryStateIds,
    districtIds,
    startDate,
    startDateBefore,
    startDateAfter,
    locationLongitude,
    locationLatitude,
    distanceRadiusMiles,
  };

  const { results, count, hasMore, error, fetchingNextPage, setFetchingNextPage } =
    useStateWideJobs({ ...filterValues }, activeFilters, setIsLoading, useDebounce);

  // Attach a ref to an element in the job list, and when
  // that element comes into view, start fetching the next
  // page. I'm not 100% sure how this works but Can't
  // Argue with Success (CAWS)
  const observer = useRef();
  const observedElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setFetchingNextPage(true);
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, setFetchingNextPage]
  );

  const filterOptions = useStateFilterOptions(stateCode);

  const [loggedIn, setLoggedIn] = useState(false);
  const [dropdownProps, setDropdownProps] = useState({
    logout: () => {},
    getUser: () => {
      return { id: 0, first_name: '', is_hijacked: false };
    },
    releaseHijack: () => {},
    showChecklist: () => {},
    inPostingOnlyDistrict: false,
    isDistrictAdmin: false,
    isDistrictUser: false,
    showDropdown: false,
    jobBoardStyling: false,
    combinedPreferenceAndProfile: false,
  });

  const logout = () => {
    auth.logout().then(() => window.location.assign('/login'));
  };

  const releaseHijack = () => {
    auth.releaseHijack().then(() => window.location.assign('/admin/core/user/'));
  };

  function showChecklist() {
    return (
      auth.getUser().checklist !== null &&
      !auth.inPostingOnlyDistrict() &&
      (auth.getUser().is_legacy_user || auth.getUser().checklist.completed)
    );
  }

  useEffect(() => {
    async function fetchConfig() {
      const combinedPreferenceAndProfileFlag = await getOrSetPreferenceFlag();

      auth.checkAuthenticated().then((r) => {
        if (!r || r === undefined) return;

        if (Object.keys(r).length === 0) {
          setLoggedIn(false);
        } else {
          setLoggedIn(true);
          setDropdownProps({
            ...dropdownProps,
            logout: logout,
            getUser: auth.getUser,
            releaseHijack: releaseHijack,
            showChecklist: showChecklist,
            inPostingOnlyDistrict: auth.inPostingOnlyDistrict(),
            isDistrictAdmin: auth.isDistrictAdmin(),
            isDistrictUser: auth.isDistrictUser(),
            showDropdown: false,
            jobBoardStyling: true,
            combinedPreferenceAndProfile: combinedPreferenceAndProfileFlag,
          });
        }
      });
    }

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (filterOptions.subcategoryStateOptions) {
      const values = [];
      filterOptions.subcategoryStateOptions.forEach((option) => {
        option.subOptions.forEach((subOption) => values.push(subOption.value));
      });
      setSubcategoryStateIds(values);
    }
  }, [filterOptions.subcategoryStateOptions]);

  const resetResults = () => {
    setPage(1);
    setFetchingNextPage(false);
    setIsLoading(true);
  };

  const actions = {
    setQuery: (query) => {
      setQuery(query);
      setUseDebounce(false);
      resetResults();
    },
    setGrades: (grades) => {
      setGrades(grades);
      setUseDebounce(true);
      resetResults();
    },
    setFulltime: (fulltime) => {
      setFulltime(fulltime);
      setUseDebounce(false);
      resetResults();
    },
    setSchoolIds: (ids) => {
      setSchoolIds(ids);
      setUseDebounce(true);
      resetResults();
    },
    setSubcategoryStateIds: (ids) => {
      setSubcategoryStateIds(ids);
      setUseDebounce(true);
      resetResults();
    },
    setDistrictIds: (ids) => {
      setDistrictIds(ids);
      setUseDebounce(true);
      resetResults();
    },
    setStartDate: (startDate) => {
      setStartDate(startDate);
      setUseDebounce(false);
      resetResults();
    },
    setStartDateBefore: (startDateBefore) => {
      setStartDateBefore(startDateBefore);
      setUseDebounce(false);
      resetResults();
    },
    setStartDateAfter: (startDateAfter) => {
      setStartDateAfter(startDateAfter);
      setUseDebounce(false);
      resetResults();
    },
    setSelectedLocation: (location) => {
      setLocationLatitude(location['locationLatitude']);
      setLocationLongitude(location['locationLongitude']);
      setDistance(location['distanceRadiusMiles']);
      setUseDebounce(true);
      resetResults();
    },
    setActiveFilters: (activeFilters) => {
      setActiveFilters(activeFilters);
      resetResults();
    },
    setDefaultSubcategoryStateIds: () => {
      const values = [];
      filterOptions.subcategoryStateOptions.forEach((o) => {
        o.subOptions.forEach((s) => values.push(s.value));
      });
      setSubcategoryStateIds(values);
      setActiveFilters((prev) => ({ ...prev, subcategoryStateIds: false }));
      resetResults();
    },
    setDefaultDistrictIds: () => {
      setDistrictIds([]);
      setActiveFilters((prev) => ({ ...prev, districtIds: false }));
      resetResults();
    },
    setDefaultGrades: () => {
      setGrades(filterOptions.gradeOptions.map((o) => o.value));
      setActiveFilters((prev) => ({ ...prev, grades: false }));
      resetResults();
    },
    setDefaultEmploymentTypes: () => {
      setFulltime(filterOptions.employmentTypeOptions.map((o) => o.value));
      setActiveFilters((prev) => ({ ...prev, fulltime: false }));
      resetResults();
    },
    removeStartDateFilterValue: (fieldName) => {
      let isActive = true;
      setStartDate((prev) => {
        const updatedValues = prev.filter((i) => i !== fieldName);
        if (updatedValues.includes('immediate') && updatedValues.includes('next_school_year')) {
          isActive = false;
        }
        return updatedValues;
      });
      if (fieldName === 'before') {
        setStartDateBefore(null);
      }
      if (fieldName === 'after') {
        setStartDateAfter(null);
      }
      setActiveFilters((prev) => ({ ...prev, startDate: isActive }));
      resetResults();
    },
    setDefaultSchoolIds: () => {
      setSchoolIds([]);
      setActiveFilters((prev) => ({ ...prev, schoolIds: false }));
      resetResults();
    },
  };

  const onNoThanksClose = () => {
    saveToLocalStorage();
    setShowModal(false);
  };

  const onMaybeClose = () => {
    setShowModal(false);
  };

  const saveToLocalStorage = () => {
    window.localStorage.setItem('hasSeenModal', 'true');
  };

  const checkLocalStorage = () => {
    if (window.localStorage.getItem('hasSeenModal')) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    checkLocalStorage();
  }, []);

  return (
    <JobBoardContainer>
      <JobAlerts
        filterOptions={filterOptions}
        jobboardName={
          USStates().find((s) => s.label === stateCode)?.title + ' Department of Education'
        }
        stateCode={stateCode}
        editPreferences={false}
      />
      <JobBoardStack>
        <JobBoardBox>
          <StateJobBoardHeader
            actions={actions}
            filterValues={filterValues}
            activeFilters={activeFilters}
            error={error}
            isloggedIn={loggedIn}
            dropdownProps={dropdownProps}
            count={count}
          />

          {showModal && (
            <StateTalentModal onNoThanksClose={onNoThanksClose} onMaybeClose={onMaybeClose} />
          )}
          <StateJobBoardFilterContainer
            filterValues={filterValues}
            actions={actions}
            filterOptions={filterOptions}
            activeFilters={activeFilters}
            setIsLoading={setIsLoading}
            setUseDebounce={setUseDebounce}
            error={error}
            isLoading={isLoading}
          />
          <StateJobsList
            isLoading={isLoading}
            results={results}
            count={count}
            hasMore={hasMore}
            setPage={setPage}
            ref={observedElementRef}
            fetchingNextPage={fetchingNextPage}
          />
        </JobBoardBox>
      </JobBoardStack>
    </JobBoardContainer>
  );
}

const JobBoardContainer = styled(Stack)({
  background: 'white',
});

const JobBoardStack = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  background: 'white',
  maxWidth: '100%',
  alignItems: 'center',
});

const JobBoardBox = styled(Box)({
  maxWidth: '1350px',
  width: '100%',
  margin: '0 auto',
});
