import { roleSections } from 'utils/enums';

export const roleSectionsArr = roleSections().map((item) => {
  const rs = {
    section: item.value,
    visible: true,
    required: item.value === 7 ? true : false,
    visible_internal: true,
    required_internal: item.value === 7 ? true : false,
  };
  return rs;
});
