import { BaseNimbleAPI } from './baseAPI';

class ProspectDetailAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/prospect' };
  }

  async getProspect(prospectId) {
    const response = await this.get(`/${prospectId}/`);
    return response.data;
  }

  async updateProspectPartial(prospectId, updateObject) {
    const response = await this.patch(`/${prospectId}/`, updateObject);
    return response.data;
  }

  async convertToCandidate(prospectId, updateObject) {
    const response = await this.post(`/${prospectId}/convert_to_candidate/`, updateObject);
    return response.data;
  }
}

export default new ProspectDetailAPI();
