import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { CircularProgress, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

import { Alert } from 'sharedComponents/Alert';
import { PrimaryButton } from 'sharedComponents/Buttons';
import RadioCardToggle from '../SharedComponents/RadioCardToggle';

import { CertificationsFormProps } from './types';
import { empty_certification_input } from './constants';
import { CertificationsFormInput } from './CertificationsFormInput';
import { QuestionLabel } from './ResumeAndCertificationsForm';
import { Grid } from 'sharedComponents/Grid';
import { STATE_DROPDOWN_OPTIONS } from 'features/Connect/utils/connectConstants';

export const CertificationsForm: React.FC<CertificationsFormProps> = ({
  isUserDataLoading,
  handleFormChange,
  userFormData,
  setUserFormData,
  certificationsFormHasErrors,
  getConnectCertifications,
  isFormSubmitting,
  pageDisplayInformation,
  defaultStateID,
}) => {
  const theme = useTheme();

  const { certifications } = userFormData;

  const addCertificationButtonHandler = () => {
    const newCertifications = [...certifications];
    if (getConnectCertifications().length === 0 || !certificationsFormHasErrors()) {
      newCertifications.push(empty_certification_input(defaultStateID));
    }
    setUserFormData({ ...userFormData, certifications: newCertifications });
  };

  return (
    <>
      <QuestionLabel required={pageDisplayInformation.requiredFields.includes('certifications')}>
        Do you have a current or expected certification?
      </QuestionLabel>
      <RadioGroup
        aria-labelledby="current-or-expected-certification-radio-buttons-group"
        name="hasCertification"
        value={userFormData.hasCertification}
        onChange={handleFormChange}
      >
        <Stack direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%' }}>
          <RadioCardToggle
            text="Yes"
            radioValue={true}
            selectedValue={userFormData.hasCertification}
            width="50%"
            height="54px"
            disabled={isFormSubmitting}
          />
          <RadioCardToggle
            text="No"
            radioValue={false}
            selectedValue={userFormData.hasCertification}
            width="50%"
            height="54px"
            disabled={isFormSubmitting}
          />
        </Stack>
      </RadioGroup>
      <Grid>
        {isUserDataLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          userFormData.hasCertification &&
          certifications?.length > 0 &&
          certifications.map((certification) => {
            if (!certification.isHidden) {
              return (
                <CertificationsFormInput
                  key={certification.client_id}
                  certification={certification}
                  userFormData={userFormData}
                  setUserFormData={setUserFormData}
                  isFormSubmitting={isFormSubmitting}
                  getConnectCertifications={getConnectCertifications}
                  stateDropdownOptions={STATE_DROPDOWN_OPTIONS}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </Grid>
      {!isUserDataLoading && (
        <div style={{ marginTop: theme.spacing(3) }}>
          {userFormData.hasCertification ? (
            <PrimaryButton
              sx={{
                bgcolor: theme.palette.info.main,
                width: '148px',
                height: '36px',
                fontSize: '15px',
                fontWeight: 600,
                borderRadius: '8px',
                padding: '6px 16px',
                '&:hover': {
                  bgcolor: theme.palette.info.dark,
                },
              }}
              onClick={addCertificationButtonHandler}
            >
              <Add sx={{ marginRight: (theme) => theme.spacing(0.5) }} />
              Add another
            </PrimaryButton>
          ) : (
            <Alert>
              {
                "Don't worry, keep going! You may still be a qualified candidate if you are eligible for emergency certifications."
              }
            </Alert>
          )}
        </div>
      )}
    </>
  );
};
