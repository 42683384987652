export const getDisplayNameText = (valuesSelected, fieldPlural, options, subOptions = false) => {
  if (valuesSelected.length > 0) {
    if (valuesSelected.length === 1) {
      if (subOptions) {
        const subOption = options
          .find((o) => o.subOptions.map((s) => s.value).includes(valuesSelected[0]))
          .subOptions.find((s) => s.value === valuesSelected[0]);

        return subOption.label;
      } else {
        return options.find((o) => o.value === valuesSelected[0]).label;
      }
    } else {
      return `${valuesSelected.length} ${fieldPlural} selected`;
    }
  } else {
    return 'Select all that apply';
  }
};
