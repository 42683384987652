import { Component } from 'react';

import JobEdit from '../components/JobEdit';
import RoleForm from '../features/RoleForm/index';
import LoadingSpinner from '../components/loadingSpinner';
import axios from 'axios';
import {
  currentHiringSeason,
  getJobStatusColor,
  roleSections,
  startDateType,
  questionType,
  appStatusType,
} from '../utils/enums';
import auth from '../utils/auth';
import districtAPI from '../api/districtAPI';
import schoolsAPI from '../api/schoolsAPI';
import schoolGroupsAPI from '../api/schoolGroupsAPI';
import applicationStatusesAPI from '../api/applicationStatusesAPI';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';
import CustomScorecardListAPI from 'api/customScorecardListAPI';
import RoleTemplatesAPI from '../api/roleTemplatesAPI';
import { showTotalFailureAdmin } from 'utils/message';
import { scrollToElementById, ensureHttpPrefixIfValueIsNotEmail } from 'utils/util';
import { withRouter } from 'react-router-dom';
import { showWarning } from 'utils/message';
import FeatureContext from 'context/featureContext';
import { roleSectionsArr } from './jobData';

const initialErrorState = {
  link_to_ats: '',
  duplicate_title: '',
};

class JobCreateContainer extends Component {
  static contextType = FeatureContext;
  constructor(props) {
    super(props);
    this.state = {
      statusColor: '',
      questionBank: [],
      job: {
        title: '',
        grades: [],
        questions: [],
        custom_questions: [],
        question_sets: [],
        role_question_sets: [],
        requiredapplicationattachment_set: [],
        schoolroles: [],
        hiring_season: currentHiringSeason(),
        district_users: [],
        owners: [],
        // Hardcoded for now; if some districts opt out later we'll have to move to backend
        indeed_role: true,
        // Hardcoded for now; if some districts opt out later we'll have to move to backend
        ziprecruiter_role: true,
        // Tracks which sections are visible/required for that role
        role_sections: roleSectionsArr,
        jobboards: [],
        ftes_total: 1,
        internal_requirements_specified: false,
        start_date_type: startDateType['Not specified'],
        statuses_available: [],
        link_to_ats: '',
        hrbp_assignments: [],
        is_template: props.isTemplate,
        salary_type: 4,
        question_sets_order: [],
        salary_min: null,
        salary_max: null,
        internal_only: false,
        external_date: null,
      },
      schools: [],
      schoolOptions: [],
      activelySubmitting: false,
      districtUsers: [],
      districtAndSuperAdmins: [],
      jobBoardOptions: [],
      newApplicationStatuses: [],
      apiResponseErrors: { ...initialErrorState },
      customScorecardsAvailable: [],
      customScorecardsSelected: [],
    };
  }

  componentDidMount() {
    // This has to be done again for React reasons that escape me.
    this.resetRoleSections();
    nimbleQuestionsAPI.getAll().then((questionBank) => {
      if (!this.ignoreLastFetch) {
        this.setState({ questionBank });
      }
    });

    schoolsAPI.fetchActiveSchools().then((schools) => {
      schoolGroupsAPI.getSchoolGroups().then((schoolGroups) => {
        let schoolOptions = [];

        schoolGroups.forEach((g) => {
          schoolOptions.push({ ...g, isGroupHeader: true, group: g.id });

          schools
            .filter((s) => s.school_groups.includes(g.id))
            .forEach((s) => schoolOptions.push({ ...s, group: g.id }));
        });

        schoolOptions = [...schoolOptions, ...schools.filter((s) => s.school_groups?.length === 0)];
        if (!this.ignoreLastFetch) {
          this.setState({ schoolOptions });
        }
      });
    });

    CustomScorecardListAPI.fetchCustomScorecards().then((data) => {
      this.setState({
        customScorecardsAvailable: data
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
          })
          .filter((scorecard) => scorecard.view_permissions !== 'me_only'),
      });
    });

    if (auth.isDistrictAdmin()) {
      applicationStatusesAPI
        .getAll({
          districtId: auth.getUser().profile.district.id,
        })
        .then((newApplicationStatuses) => {
          this.setState((prevState) => {
            // Set all statuses selected by default except draft
            const statusesAvailable = newApplicationStatuses.filter(
              (s) => s.status_type !== appStatusType.draft
            );

            return {
              newApplicationStatuses,
              job: { ...prevState.job, statuses_available: statusesAvailable },
            };
          });
        });
      axios
        .get('/api/user/get_district_users/')
        .then((r) => {
          if (!this.ignoreLastFetch) {
            this.setState({ districtUsers: r.data });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      axios
        .get('/api/jobboards/')
        .then((r) => {
          if (!this.ignoreLastFetch) {
            this.setState({ jobBoardOptions: r.data });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      this.fetchDistrictAdmins();
    }
  }

  resetRoleSections = () => {
    const roleSectionsArr = roleSections().map((item) => {
      const rs = {
        section: item.value,
        visible: true,
        required: item.value === 7 ? true : false,
        visible_internal: true,
        required_internal: item.value === 7 ? true : false,
      };
      return rs;
    });
    const updateRole = this.state.job;
    updateRole.role_sections = roleSectionsArr;
    this.setState({ job: updateRole });
  };

  fetchDistrictAdmins = () => {
    let district_id = auth.getUser().profile.district.id;
    districtAPI.getDistrictAndSuperAdmins(district_id).then((admins) => {
      this.setState({ districtAndSuperAdmins: admins });
    });
  };

  setStatusColor = () => {
    this.setState({ statusColor: getJobStatusColor(this.state.job.status) });
  };

  onSave = async (childRole, questionsAndAttachmentsAndSets) => {
    await this.updateRole(childRole, questionsAndAttachmentsAndSets);
    this.props.history.push('/district/jobslist?f_JS=draft');
  };

  onSaveRoleForm = async (childRole, questionsAndAttachmentsAndSets, schoolsSelected) => {
    this.setState({ schools: [...schoolsSelected] }, async () => {
      const response = await this.updateRole(childRole, questionsAndAttachmentsAndSets);
      this.props.history.push(`/district/jobedit/${response.data.id}`);
    });
  };

  onSubmitRoleForm = async (childRole, questionsAndAttachmentsAndSets, schoolsSelected) => {
    this.setState({ schools: [...schoolsSelected] }, async () => {
      const response = await this.updateRole(childRole, questionsAndAttachmentsAndSets);
      this.props.history.push(`/district/jobpreview/${response.data.id}/?created=1`);
    });
  };

  onSaveRoleTemplate = async (childRole, questionsAndAttachmentsAndSets, schoolsSelected) => {
    this.setState({ schools: [...schoolsSelected] }, async () => {
      const response = await this.updateRole(childRole, questionsAndAttachmentsAndSets);
      if (!response?.data.id) return;

      this.props.history.push(`/district/template-edit/${response.data.id}`);
    });
  };

  onSubmit = async (childRole, questionsAndAttachmentsAndSets) => {
    const newRole = await this.updateRole(childRole, questionsAndAttachmentsAndSets);
    this.props.history.push(`/district/jobpreview/${newRole.data.id}/?created=1`);
  };

  onSubmitRoleTemplate = async (childRole, questionsAndAttachmentsAndSets, schoolsSelected) => {
    this.setState({ schools: [...schoolsSelected] }, async () => {
      const response = await this.updateRole(childRole, questionsAndAttachmentsAndSets);
      this.props.history.push(`/district/template-preview/${response.data.id}/?created=1`);
    });
  };

  sendTagNotifications = (tagObjects) => {
    return axios.post('/api/send_tag_notifications/', tagObjects);
  };

  getUrl = (job) => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;
    return `${location}/district/jobpreview/${job.id}#internal_notes`;
  };

  emailTaggedUsers = (notesWithNewTags, job) => {
    const tagObjects = [];
    notesWithNewTags.forEach((note) => {
      let newlyTaggedUsers = [...new Set(note.newly_tagged_users)];
      for (let taggedUser of newlyTaggedUsers) {
        const url = this.getUrl(job);
        const tagObj = {
          user_id: +taggedUser,
          location: 'in a job posting',
          note_url: url,
          note_copy: note.text,
          role_title: job.title,
        };
        tagObjects.push(tagObj);
      }
    });
    this.sendTagNotifications(tagObjects);
  };

  updateRole = async (childRole, questionsAndAttachmentsAndSets) => {
    if (!this.state.activelySubmitting) {
      this.setState({ activelySubmitting: true });

      const putJob = childRole;
      putJob.questions = [];
      putJob.custom_questions = [];
      putJob.requiredapplicationattachment_set = [];
      putJob.question_sets = [];
      putJob.role_question_sets = [];
      putJob.question_sets_order = [];
      putJob.school_preferences_question = null;

      questionsAndAttachmentsAndSets.forEach((item, index) => {
        item.order = index;

        if (item.is_qs) {
          switch (item.is_role_qs) {
            case true:
              putJob.role_question_sets.push(item);
              putJob.question_sets_order.push({
                uuid: item.uuid,
                order: item.order,
                is_role_qs: true,
              });
              break;
            default:
              putJob.question_sets.push(item);
              putJob.question_sets_order.push({
                uuid: item.uuid,
                order: item.order,
                is_role_qs: false,
              });
              break;
          }
        }
        switch (item.question_type) {
          case questionType.nimble:
            putJob.questions.push(item);
            break;
          case questionType.direction_text:
          case questionType.open_response:
          case questionType.yes_no:
          case questionType.multiple_choice:
          case questionType.statementCheckbox:
            putJob.custom_questions.push(item);
            break;
          case questionType.attachment:
          case questionType.videoLink:
            putJob.requiredapplicationattachment_set.push(item);
            break;
          case questionType.schoolPreferences:
            putJob.school_preferences_question = item;
            break;
          default:
            console.warn('Unknown question type', item.question_type);
        }
      });

      // If the job has a link to ATS, but it doesn't start with http or https,
      // add it here. If it's an email we do nothing.
      putJob.link_to_ats = ensureHttpPrefixIfValueIsNotEmail(putJob.link_to_ats);

      const customScorecardsSelected = this.state.customScorecardsSelected;

      try {
        const newRole = await axios.post('/api/role/', putJob);

        showWarning(`${this.state.job.is_template ? 'Template' : 'Draft'} saved`, 1000);
        this.setState({ job: newRole.data });

        // notify new users of their tag
        if (putJob?.internal_role_notes) {
          let newlyTaggedUsers = putJob?.internal_role_notes?.filter((note) =>
            note.hasOwnProperty('newly_tagged_users')
          );
          let roleObj = newRole.data;
          this.emailTaggedUsers(newlyTaggedUsers, roleObj);
        }
        await this.createSchoolRoles(newRole.data.id, childRole);
        await this.updateCustomScorecards(newRole.data.id, customScorecardsSelected);
        this.setState({
          activelySubmitting: false,
        });
        return newRole;
      } catch (err) {
        if (err.response?.data?.link_to_ats) {
          this.setState(
            {
              apiResponseErrors: {
                link_to_ats: err.response?.data?.link_to_ats,
              },
              activelySubmitting: false,
            },
            () => scrollToElementById('jobedit_link_to_ats')
          );
        } else if (err.response?.data?.title) {
          this.setState({
            apiResponseErrors: {
              duplicate_title: err.response?.data?.title,
            },
            activelySubmitting: false,
          });
        } else {
          // Any other error is unexpected so blow it all up
          showTotalFailureAdmin(
            `Oops, we were unable to save the ${
              this.state.job.is_template ? 'template' : 'job'
            }. Contact support@hirenimble.com for assistance.`
          );
          this.setState({ activelySubmitting: false });
          throw new Error(err);
        }
      }
    }
  };

  createSchoolRoles = async (roleID, childRole) => {
    var schoolRoles = [];
    this.state.schools.forEach((school) => {
      let role = Object.assign({}, childRole);
      role.school = school;
      role.ftes_total = school.ftes_total;
      role.district_role = roleID;
      schoolRoles.push(role);
    });
    if (schoolRoles.length) {
      return axios.post('/api/schoolrole/', {
        schoolRoles: schoolRoles,
        districtRoleID: roleID,
      });
    } else {
      return roleID;
    }
  };

  async updateCustomScorecards(roleId, scorecards) {
    const scorecardIds = scorecards.map((s) => s.id);
    CustomScorecardListAPI.updateScorecardRoles(roleId, scorecardIds);
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  clearAPIResponseErrors = () => {
    this.setState({ apiResponseErrors: { ...initialErrorState } });
  };

  render() {
    // Wait for the jobboards to auto check the default one on JobEdit/index
    if (
      auth.isDistrictAdmin() &&
      (this.state.jobBoardOptions.length === 0 || this.state.job.statuses_available?.length === 0)
    ) {
      return <LoadingSpinner />;
    }

    return (
      this.state.job && (
        <>
          {auth.isDistrictAdmin() && this.state.job.is_template ? (
            <RoleForm
              isEditing={false}
              jobData={this.state.job}
              job={this.state.job} //Needed for fallback component, JobEdit
              statusColor={this.state.statusColor}
              questionBank={this.state.questionBank}
              onSave={this.onSave} //Needed for fallback component, JobEdit
              onSubmit={this.onSubmit} //Needed for fallback component, JobEdit
              onSaveRoleForm={this.onSaveRoleForm}
              onSaveRoleTemplateForm={this.onSaveRoleTemplate} // save template
              onSubmitRoleForm={this.onSubmitRoleForm}
              onSubmitRoleTemplateForm={this.onSubmitRoleTemplate} // save & publish template
              schools={this.state.schools}
              schoolOptions={this.state.schoolOptions}
              onSubmitLabel={'Save & Preview'} //Needed for fallback component, JobEdit
              pageTitle="Create New Job" //Needed for fallback component, JobEdit
              districtUsers={this.state.districtUsers}
              districtAndSuperAdmins={this.state.districtAndSuperAdmins}
              jobBoardOptions={this.state.jobBoardOptions}
              applicationStatuses={this.state.newApplicationStatuses.filter(
                (a) => a.status_type !== appStatusType.draft
              )}
              apiResponseErrors={this.state.apiResponseErrors}
              clearAPIResponseErrors={this.clearAPIResponseErrors}
              activelySubmitting={this.state.activelySubmitting}
              customScorecards={this.state.customScorecardsAvailable}
              customScorecardsSelected={this.state.customScorecardsSelected}
              setCustomScorecardsSelected={(s) => this.setState({ customScorecardsSelected: s })}
            />
          ) : (
            <JobEdit
              job={this.state.job}
              statusColor={this.state.statusColor}
              questionBank={this.state.questionBank}
              onSave={this.onSave}
              onSubmit={this.onSubmit}
              schools={this.state.schools}
              schoolOptions={this.state.schoolOptions}
              onSubmitLabel="Save & Preview"
              pageTitle="Create New Job"
              districtUsers={this.state.districtUsers}
              districtAndSuperAdmins={this.state.districtAndSuperAdmins}
              jobBoardOptions={this.state.jobBoardOptions}
              applicationStatuses={this.state.newApplicationStatuses.filter(
                (a) => a.status_type !== appStatusType.draft
              )}
              apiResponseErrors={this.state.apiResponseErrors}
              clearAPIResponseErrors={this.clearAPIResponseErrors}
              activelySubmitting={this.state.activelySubmitting}
              customScorecards={this.state.customScorecardsAvailable}
              customScorecardsSelected={this.state.customScorecardsSelected}
              setCustomScorecardsSelected={(s) => this.setState({ customScorecardsSelected: s })}
            />
          )}
        </>
      )
    );
  }
}

export default withRouter(JobCreateContainer);
