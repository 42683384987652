import { Component } from 'react';

import BulkEmailPreviewRow from './BulkEmailPreviewRow';
import PropTypes from 'prop-types';
import auth from '../../utils/auth';

// first and last name unnecessary here because they're always included
const VARIABLE_MAP = {
  'Candidate phone': 1,
  'Phone number': 2,
  'Job title': 3,
  'Sender first name': 4,
  'Sender last name': 5,
  'School name': 6,
  'District name': 7,
  'Interview date & time': 8,
};

export default class BulkEmailPreview extends Component {
  static propTypes = {
    candidates: PropTypes.array.isRequired,
    removeRecipient: PropTypes.func.isRequired,
    emailTemplate: PropTypes.object.isRequired,
    updateRecipientField: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      variableSet: new Set(),
    };
    this.sender = auth.getUser();
    this.isSchoolUser = auth.isSchoolUser();
  }

  componentDidMount() {
    this.processVariables();
  }

  processVariables = () => {
    /** get all variables from the subject and content. Use these to determine which
     * variables to show on the preview page.
     */
    let emailTemplate = this.props.emailTemplate;
    let { content, subject } = emailTemplate;
    let variables = [];

    let contentMatches = content.match(/\{.*?\}/g) || [];
    let subjectMatches = subject.match(/\{.*?\}/g) || [];
    // eg. ['{First name}', '{Sender last name}']
    let allMatches = contentMatches.concat(subjectMatches);

    allMatches.forEach((m) => {
      // remove curly brackets
      let variableName = m.substring(1, m.length - 1);
      // map to a number
      let variableKey = VARIABLE_MAP[variableName];
      if (variableKey) {
        variables.push(variableKey);
      }
    });

    this.setState({ variableSet: new Set(variables) });
  };

  render() {
    let { variableSet } = this.state;
    return (
      <div className="bulk-email-preview">
        <h3 className="mb1">Check recipient list</h3>
        <div className="preview-container">
          <div className="header">
            <div className="row-item trash" />
            <div className="row-item candidate-email">Email</div>
            <div className="row-item">First name</div>
            <div className="row-item">Last name</div>
            {/* only render the below variables if they're included in subject or content */}
            {variableSet.has(VARIABLE_MAP['Interview date & time']) && (
              <div className="row-item interview-datetime">Interview date & time</div>
            )}
            {(variableSet.has(VARIABLE_MAP['Phone number']) ||
              variableSet.has(VARIABLE_MAP['Candidate phone'])) && (
              <div className="row-item">Phone number</div>
            )}
            {variableSet.has(VARIABLE_MAP['Job title']) && (
              <div className="row-item">Job title</div>
            )}
            {variableSet.has(VARIABLE_MAP['Sender first name']) && (
              <div className="row-item">Sender first name</div>
            )}
            {variableSet.has(VARIABLE_MAP['Sender last name']) && (
              <div className="row-item">Sender last name</div>
            )}
            {variableSet.has(VARIABLE_MAP['District name']) && (
              <div className="row-item">District name</div>
            )}
            {variableSet.has(VARIABLE_MAP['School name']) && (
              <div className="row-item">School name</div>
            )}
          </div>
          <div>
            {this.props.candidates.map((candidate, index) => (
              <BulkEmailPreviewRow
                key={index}
                sender={this.sender}
                candidate={candidate}
                isSchoolUser={this.isSchoolUser}
                removeRecipient={this.props.removeRecipient}
                updateRecipientField={this.props.updateRecipientField}
                VARIABLE_MAP={VARIABLE_MAP}
                variableSet={variableSet}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
