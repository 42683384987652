import { useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * Hold a copy of the argument and compares equality. If there are changes,
 * the new value is saved.
 *
 * Useful if you want to use an array in the depenency list of useEffect.
 *
 * Usage:
 *
 * const myDependency = useArrayDependecy([1,2,3,4])
 *
 * useEffect(() => print('hello'), [myDependency])
 */
export const useArrayDependency = (value) => {
  const originalRef = useRef(value);

  if (!isEqual(originalRef.current, value)) {
    originalRef.current = value;
  }

  return originalRef.current;
};
