import { BlankModal } from './BlankModal';
import {
  CancelButton,
  CTAContainer,
  DeleteButton,
  DeletionModalTitle,
  TextContainer,
} from './styles';
import { DeletionModalProps } from './types';
import { SharedComponentsDeletionModalTestIds } from 'data-testids/SharedComponents';
import { useTheme } from '@mui/material';
import React from 'react';

export const DeletionModal = React.forwardRef<HTMLDivElement, DeletionModalProps>(
  ({ open, onClose, onDelete, text, title }, ref) => {
    const theme = useTheme();

    return (
      <BlankModal
        open={open}
        sx={{ borderRadius: theme.shape.borderRadius * 2, padding: theme.spacing(6) }}
        onClose={onClose}
        ref={ref}
        header={
          <DeletionModalTitle variant="h5" data-testid={SharedComponentsDeletionModalTestIds.TITLE}>
            {title}
          </DeletionModalTitle>
        }
        body={
          <>
            <TextContainer variant="body1" data-testid={SharedComponentsDeletionModalTestIds.TEXT}>
              {text}
            </TextContainer>
            <CTAContainer>
              <CancelButton
                color="inherit"
                data-testid={SharedComponentsDeletionModalTestIds.CANCEL_BUTTON}
                onClick={onClose}
                size="extraLarge"
                variant="outlined"
              >
                Cancel
              </CancelButton>
              <DeleteButton
                color="error"
                data-testid={SharedComponentsDeletionModalTestIds.DELETE_BUTTON}
                onClick={() => onDelete()}
                size="extraLarge"
                variant="contained"
              >
                Delete
              </DeleteButton>
            </CTAContainer>
          </>
        }
      />
    );
  }
);

DeletionModal.displayName = 'DeletionModal';
