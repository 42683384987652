import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import auth from '../../../utils/auth';
import axios from 'axios';
import { BaseJobAlertsBanner, MarketplaceJobAlertsBanner } from './JobAlertBanner';
import { JobAlertsProps, JobAlert, EmailState, OpenModalType, SuccessAlert } from './types';
import { JobAlertsSubscribeModal } from './JobAlertsModal';
import statejobboardsAPI from 'api/statejobboardsAPI';

const JobAlerts: React.FC<JobAlertsProps> = ({ jobboardName, stateCode, filterOptions }) => {
  // Token is provided after an initial alert POST to perform crud actions or is provided in an alert email.
  // Token is required to perform all Read/Update/Delete functionality. Token is optional for POST.
  const [jobAlerts, setJobAlerts] = useState<JobAlert[]>([]);
  // errors and input data for email field
  const [emailState, setEmailState] = useState<EmailState>({
    email: '',
    error: undefined,
  });
  // errors for selection fields
  const [selectionError, setSelectionError] = useState(null);
  const [successAlert, setSuccessAlert] = useState<SuccessAlert>({
    show: false,
    message: '',
    submessage_html: null,
  });
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const history = useHistory();
  const [hasMarketplaceFF, setHasMarketplaceFF] = useState(false);
  const { search: queryString } = useLocation();
  const isLoggedIn = auth.loggedIn();
  const [token, setToken] = useState<string | null>(null);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);
  const emailToUse = hasMarketplaceFF && isLoggedIn ? auth.getUser().email : emailState.email;
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false);

  const openModalType = useMemo<OpenModalType>(() => {
    const searchParams = new URLSearchParams(queryString);
    const rawOpenModalType = searchParams.get('openModalType');

    if (rawOpenModalType === 'add') {
      return 'add';
    } else if (rawOpenModalType === 'edit') {
      return 'edit';
    } else if (rawOpenModalType === 'manage') {
      return 'manage';
    } else {
      return 'none';
    }
  }, [queryString]);

  useEffect(() => {
    axios.get('/api/config/').then((r) => {
      if (r.data?.data?.CANDIDATE_MARKETPLACE_FLAG || r.data?.CANDIDATE_MARKETPLACE_FLAG === '1') {
        setHasMarketplaceFF(true);
        setEmailDisabled(true);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParams = urlParams.get('token');
        const confirmationParams = urlParams.get('action');
        if (tokenParams) {
          setToken(tokenParams);
          setEmailDisabled(true);
        }
        if (confirmationParams && confirmationParams.toLowerCase() === 'confirm') {
          setEmailConfirmed(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!token || !emailConfirmed) return;

    statejobboardsAPI.confirm(token);
  }, [token, emailConfirmed]);

  useEffect(() => {
    if ((!token && !hasMarketplaceFF) || (hasMarketplaceFF && !isLoggedIn)) return;

    if (hasMarketplaceFF && isLoggedIn) {
      setEmailState({ email: emailToUse, error: '' });
    }
    statejobboardsAPI.subscriptions(token).then(({ email = '', subscriptions = [] }) => {
      setJobAlerts(subscriptions);
      setEmailState((previousState) => {
        if (previousState.email) {
          return previousState;
        }
        return { email: email, error: undefined };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, hasMarketplaceFF, isLoggedIn]);

  const generateParameters = (modalType: OpenModalType) => {
    const parameters = new URLSearchParams(queryString);
    parameters.set('openModalType', modalType);
    return parameters.toString();
  };

  const handleJobAlertsSubscribeModalClose = () => {
    history.push(`/jobs/state/${stateCode}`);
  };

  const handleBannerClick = () => {
    if (hasMarketplaceFF) {
      if (isLoggedIn) {
        if (jobAlerts.length > 0) {
          const parameters = generateParameters('manage');
          history.push({ search: parameters });
        } else {
          const parameters = generateParameters('add');
          history.push({ search: parameters });
        }
      } else {
        history.push('/login');
      }
    } else {
      if (token) {
        const parameters = generateParameters('manage');
        history.push({ search: parameters });
      } else {
        const parameters = generateParameters('add');
        history.push({ search: parameters });
      }
    }
  };

  const shouldShowModal = (hasMarketplaceFF && isLoggedIn) || !hasMarketplaceFF;

  const handleTokenChange = (token) => {
    setToken(token);
    setEmailDisabled(true);
  };

  const hasAlerts = jobAlerts.length > 0;

  return (
    <>
      {hasMarketplaceFF ? (
        <MarketplaceJobAlertsBanner
          text={
            hasAlerts ? (
              <ManageAlertsText>
                You are subscribed to job alerts. &nbsp;<u>Manage Alerts </u> →
              </ManageAlertsText>
            ) : (
              <div>{`Subscribe to job alerts for ${
                stateCode === 'IN' ? 'the Indiana Educator Job Board' : jobboardName
              }\xa0\u2192`}</div>
            )
          }
          onClick={handleBannerClick}
          hasAlerts={hasAlerts}
        />
      ) : (
        <BaseJobAlertsBanner
          text={
            token ? (
              <ManageAlertsText>
                You are subscribed to job alerts. &nbsp;<u>Manage Alerts</u>→
              </ManageAlertsText>
            ) : (
              <div>{`Subscribe to job alerts for ${
                stateCode === 'IN' ? 'the Indiana Educator Job Board' : jobboardName
              }\xa0\u2192`}</div>
            )
          }
          onClick={handleBannerClick}
        />
      )}
      {shouldShowModal && (
        <JobAlertsSubscribeModal
          categories={filterOptions.subcategoryStateOptions}
          emailState={emailState}
          emailToUse={emailToUse}
          jobAlerts={jobAlerts}
          onClose={handleJobAlertsSubscribeModalClose}
          onDistrictSelection={setSelectedDistricts}
          onEmailStateChange={setEmailState}
          onSelectionErrorChange={setSelectionError}
          onSubcategorySelection={setSelectedSubcategories}
          openModalType={openModalType}
          organizations={filterOptions.districtOptions}
          selectedDistricts={selectedDistricts}
          selectedSubcategories={selectedSubcategories}
          selectionError={selectionError}
          stateCode={stateCode}
          onSuccessAlertChange={setSuccessAlert}
          successAlert={successAlert}
          token={token}
          onModifyingJobAlerts={setJobAlerts}
          onTokenChange={handleTokenChange}
          hasMarketplaceFF={hasMarketplaceFF}
          emailDisabled={emailDisabled}
        />
      )}
    </>
  );
};

export default JobAlerts;

const ManageAlertsText = styled('div')({
  display: 'flex',
});
