import { useState } from 'react';
import styled from 'styled-components';
import ErrorText from 'components/errortext';

import { ReactComponent as AttachmentIcon } from 'assets/icons/icon_green_paper_clip.svg';

import { fileNameShortener } from 'utils/util';
import { AttachmentAnswerProps } from './types';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';

const AttachmentAnswer: React.FC<AttachmentAnswerProps> = ({
  answer,
  uploadAttachment,
  removeAttachment,
  index,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleDocumentUpload = (event) => {
    const fileData = event.target.files[0];

    try {
      checkForUploadErrors(fileData);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }

      return;
    }

    uploadAttachment(fileData, index);
  };

  const checkForUploadErrors = (fileData: { size: number; name: string }) => {
    /* ensure no empty files are uploaded */
    if (typeof fileData === 'undefined' || fileData.size === 0) {
      throw new Error('Upload is empty, please try again');
    }

    /* no reasonable file upload should be greater than 5MB in size */
    if (fileData.size > 5242880) {
      throw new Error('Attachment must be smaller than 5MB');
    }

    /* only proper file types can be uploaded */
    if (
      !['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(
        fileData.name.split('.').pop().toLowerCase()
      )
    ) {
      throw new Error(
        'Please ensure your resume ends in one of the following: .pdf, .doc, .docx, .jpg, .png'
      );
    }

    /**
     * ~ 40 characters are prepended to file name (/reference_attachments/None/(timestamp)),
     * and max_length is 255, so ensure input file name is no more than 200 characters, to be safe.
     */
    if (fileData.name.length > 200) {
      throw new Error("Please ensure your resume's file name is fewer than 200 characters");
    }
    /* if this point is reached, resume is valid, so remove error messages if they exist */
    setErrorMessage('');
  };

  return (
    <>
      {answer.attachment_directions && <Directions>{answer.attachment_directions}</Directions>}
      <GridContainer>
        <AttachmentButtonContainer>
          <AttachmentButtonLabel>
            <AttachmentInput name="document" type="file" onChange={handleDocumentUpload} />
            <AttachmentIcon />
            <AttachmentButtonText>Attach File</AttachmentButtonText>
          </AttachmentButtonLabel>
        </AttachmentButtonContainer>
        {answer.attachment && (
          <span className="ml1">
            {/* remove the timestamp at front (14 characters long) */}
            {answer.attachment.name ?? fileNameShortener(answer.attachment).substring(14)}
            <RemoveButton onClick={(e) => removeAttachment(e, index)}>(remove)</RemoveButton>
          </span>
        )}
        {errorMessage && <ErrorText message={errorMessage} />}
      </GridContainer>
      <UploadFileHelperMessage />
    </>
  );
};

export default AttachmentAnswer;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 135px 1fr;
  align-items: center;

  margin-top: 13px;
`;

const Directions = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #444444;
`;

const AttachmentButtonLabel = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 35.75px;

  margin-bottom: 0;
`;

const AttachmentInput = styled.input`
  display: none !important;
`;

const AttachmentButtonContainer = styled.div`
  width: 133px;
  height: 35.75px;

  background: #ffffff;
  border: 1px solid #00b88d;
  border-radius: 2px;
  color: #00b88d;
  cursor: pointer;
`;

const AttachmentButtonText = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  border: none;
  background-color: #f8f8f8;

  &:hover {
    text-decoration: underline;
  }
`;
