import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Range } from 'rc-slider';

import iconUpCaretGreen from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';

export default class ExperienceFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSlider: false,
    };
  }
  static propTypes = {
    experienceStart: PropTypes.number,
    experienceEnd: PropTypes.number,
    updateExperienceState: PropTypes.func.isRequired,
  };

  toggleSlider = () => {
    this.setState((state, props) => ({
      showSlider: !state.showSlider,
    }));
  };

  render() {
    let experienceActive = this.props.experienceStart !== 0 || this.props.experienceEnd !== 30;

    const railStyle = { backgroundColor: '#c8c8c8' };
    const trackStyle = [{ backgroundColor: experienceActive ? '#00B88D' : '#929292' }];
    const handleStyle = {
      boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)',
      border: 'none',
      width: '12px',
      height: '12px',
      marginTop: '-4px',
    };

    return (
      <div className={`${experienceActive ? 'cl-active' : ''} new-cl-filter slider`}>
        <div className="cl-filter-header" onClick={this.toggleSlider}>
          <p className={experienceActive ? 'cl-active' : ''}>Experience</p>
          <img
            src={
              this.state.showSlider && experienceActive
                ? iconUpCaretGreen
                : !this.state.showSlider && experienceActive
                  ? iconDownCaretGreen
                  : this.state.showSlider
                    ? iconUpCaret
                    : iconDownCaret
            }
            alt=""
          />
        </div>
        <p
          className={`years ${experienceActive ? 'cl-active' : ''} ${
            this.state.showSlider ? 'show' : ''
          }`}
        >
          {this.state.showSlider &&
            `${this.props.experienceStart} - ${this.props.experienceEnd} years`}
        </p>
        <div
          className={`cl-slider ${this.state.showSlider ? 'expanded' : ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Range
            min={0}
            max={30}
            value={[this.props.experienceStart, this.props.experienceEnd]}
            railStyle={railStyle}
            trackStyle={trackStyle}
            handleStyle={[handleStyle, handleStyle]}
            onChange={(query) => this.props.updateExperienceState(query[0], query[1])}
          />
        </div>
      </div>
    );
  }
}
