import { useState } from 'react';
import styled from 'styled-components';
import { DataDashboardContainerV2 } from '../components/V2Components/DataDashboardContainerV2';
import { DashboardBaseChartV2 } from '../components/V2Components/DashboardBaseChartV2';
import {
  searchBarPlaceholders,
  stateDashboardSegmentTypes,
  stateDashboardChartTypes,
} from 'utils/enums';

/** Entrypoint into the SupplyAndDemandDashboard dashboard.**/
export default function SupplyAndDemandDashboard() {
  const stateCode = 'IN';
  const [districtsSelected, setDistrictsSelected] = useState([]);
  const [hiringSeasonsSelected, setHiringSeasonsSelected] = useState([]);
  const [jobSubcategoriesSelected, setJobSubcategoriesSelected] = useState([]);

  return (
    <>
      <DataDashboardContainerV2
        title={'Supply and Demand'}
        stateCode={stateCode}
        setDistrictsSelected={setDistrictsSelected}
        setHiringSeasonsSelected={setHiringSeasonsSelected}
        setJobSubcategoriesSelected={setJobSubcategoriesSelected}
        showJobSubcategoriesDropdown={false}
      />
      <DashboardContainer>
        <DashboardBaseChartV2
          title="Hire Rate by Applicant Group"
          chartType={stateDashboardChartTypes.bar}
          XAxisLabel={''}
          showFilter={false}
          showDropdown={true}
          alwaysShowStatus={true}
          defaultGroupBySelected={stateDashboardSegmentTypes.credential.value}
          groupByOptions={[
            stateDashboardSegmentTypes.credential,
            stateDashboardSegmentTypes.gender,
            stateDashboardSegmentTypes.raceEthnicity,
          ]}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          stateCode={stateCode}
          excludePostingOnly={true}
          graphId={0}
          searchBarPlaceholders={searchBarPlaceholders}
          legendOnLeft={false}
          filterZerosFromGraph={true}
          titleTooltip={true}
          titleTooltipText={`These charts display the rate at which <br/>
          certain applicant groups are being hired <br/>
          out of the total number of applicants.`}
        />
      </DashboardContainer>
      <DashboardContainer>
        <DashboardBaseChartV2
          title="Vacancy Fill Rate by Posting Type"
          vacancyFillRate={true}
          showFilter={true}
          chartType={stateDashboardChartTypes.bar}
          XAxisLabel={''}
          defaultGroupBySelected={stateDashboardSegmentTypes.subcategory.value}
          groupByOptions={[
            stateDashboardSegmentTypes.subcategory,
            stateDashboardSegmentTypes.employmentType,
          ]}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          stateCode={stateCode}
          graphId={1}
          searchBarPlaceholders={searchBarPlaceholders}
          legendOnLeft={false}
          isSingleColumned={true}
          showGroupedToggleInHeader={false}
          titleTooltip={true}
          titleTooltipText={`These charts display the percentage of <br/>
          positions being filled out of the total <br/>
          number of vacancies.`}
        />
      </DashboardContainer>
    </>
  );
}

const DashboardContainer = styled.div`
  display: flex;
  gap: 15px;
  max-width: 1160px;
  margin: 10px auto 10px;
  padding: 0 30px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;
