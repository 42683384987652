import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './stylesheets/react_dates_overrides.css';

import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';

import { DownCaretIcon } from './icons';

/** Airbnb's date picker component with some Nimble styles. Docs can be found
 * at https://github.com/airbnb/react-dates#singledatepicker.
 *
 * Here is the minimum REQUIRED setup you need to get the SingleDatePicker working:

  <SingleDatePicker
    date={this.state.date} // momentPropTypes.momentObj or null
    onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
    focused={this.state.focused} // PropTypes.bool
    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
    id="your_unique_id" // PropTypes.string.isRequired,
  />
 * */
export default function DatePicker(props) {
  return (
    <SingleDatePicker
      {...props}
      customInputIcon={<DownCaretIcon width="12px" height="12px" fill="#c1c1c1" />}
      inputIconPosition={ICON_AFTER_POSITION}
      // Block sizing causes the input to fill its container width. If you want to change
      // the width, wrap in a div with desired width.
      block
    />
  );
}
