import { useState } from 'react';

import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipButtonClickTriggerProps } from './types';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const TooltipButtonClickTrigger: React.FC<TooltipButtonClickTriggerProps> = ({
  children,
  dataTestId,
  variant,
  startIcon,
  endIcon,
  buttonSx,
  tooltipText,
  onClick,
}) => {
  const [hasClickedButton, setHasClickedButton] = useState(false);

  const handleTooltipClose = () => {
    setHasClickedButton(false);
  };

  const handleClick = () => {
    onClick();
    setHasClickedButton(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={hasClickedButton}
          PopperProps={{
            disablePortal: true,
          }}
          title={tooltipText}
        >
          <Button
            variant={variant}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={handleClick}
            sx={buttonSx}
            data-testid={dataTestId}
          >
            {children}
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
