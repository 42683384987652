import axios from 'axios';
import { Component } from 'react';

import { fetchApplicationStatuses, fetchAdmins } from '../utils/statusviewutils';
import { SchoolGroups } from 'features/Settings';
import { SettingsAnchorTagHeader } from 'components/SettingsAnchorTags/SettingsAnchorTags';
import ApplicationQuestionSets from 'features/ApplicationQuestionSets/Settings';
import ApplicationStatusList from '../components/ApplicationStatus/ApplicationStatusList';
import auth from '../utils/auth';
import CandidateForms from '../components/CandidateForms';
import CategoriesList from '../components/Categories/CategoriesList';
import CustomProfileFieldList from '../components/CustomProfileFields';
import CustomReferenceList from '../components/CustomReferences/CustomReferenceList';
import CustomScorecardList from '../components/CustomScorecards/CustomScorecardList';
import customScorecardListAPI from 'api/customScorecardListAPI';
import CustomTagList from '../components/CustomTag/CustomTagList';
import EmailTemplateList from '../components/EmailTemplates/EmailTemplateList';
import Notifications from '../components/Notifications';
import ReferenceTemplateListAPI from 'api/referenceTemplateListAPI';
import UniversalTasksList from '../components/UniversalTasks/UniversalTasksList';
import ApplicantSourceList from '../components/ApplicantSource/ApplicantSourceSettingsList';
import usersAPI from '../api/usersAPI';

import './usersettings.css';

export default class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scorecardlist: [],
      referenceList: [],
      emailTemplateList: [],
      newApplicationStatuses: [],
      adminUsers: [],
      defaultScorecardId: null,
      defaultReferenceId: null,
    };
    this.user = auth.getUser();
    this.hasOnboardingModule = auth.hasOnboardingModule();
    this.isSchoolUser = auth.isSchoolUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.isSuperAdminUser = auth.isSuperAdminUser();
    this.fetchApplicationStatuses = fetchApplicationStatuses.bind(this);
    this.fetchAdmins = fetchAdmins.bind(this);
    this.hasApplicationConfirmationFF = auth.hasApplicationConfirmationTemplates(this.user);
  }

  componentDidMount() {
    document.body.classList.add('user-settings-page');
    this.fetchDefaultScorecardId();
    this.fetchDefaultReferenceId();
    this.fetchScorecards();
    this.fetchReferences();
    this.fetchTemplates();
    this.fetchAdmins();
    this.fetchApplicationStatuses();

    this.fetchCandidateSource();
  }

  componentWillUnmount() {
    document.body.classList.remove('user-settings-page');
  }

  fetchCandidateSource = () => {
    // Get a list of sources stored in the server, if they exist.
    // And if the user has permissions.
    if (this.isSuperAdminUser) {
      axios.get('/api/candidate-source/').then((r) => this.setState({ sources: r.data }));
    }
  };

  fetchTemplates = () => {
    // GET templates for editing. A user can only edit his/her own, so send personal: true to
    // limit results to personal templates.
    // edit 2/26/19 super admins can view/edit all non-personal email templates.
    const personal = this.isSuperAdminUser ? false : true;
    axios
      .get('/api/emailtemplates/', {
        params: { personal, isTemplatesList: this.hasApplicationConfirmationFF ? true : false },
      })
      .then((r) => this.setState({ emailTemplateList: r.data }))
      .catch((err) => console.log(err));
  };

  fetchDefaultScorecardId = async () => {
    const defaultScorecardId = await usersAPI.getDefaultScorecardId(this.user.id);
    this.setState({ defaultScorecardId });
  };

  fetchDefaultReferenceId = async () => {
    const defaultReferenceId = await usersAPI.getDefaultReferenceTemplateId(this.user.id);
    this.setState({ defaultReferenceId });
  };

  /**
   * Update the defaultScorecardId optimistically.
   * Rollback to previous value on error.
   */
  setDefaultScorecardId = (scorecardId) => {
    let previousDefaultScorecardId;

    this.setState((prevState) => {
      previousDefaultScorecardId = prevState.defaultScorecardId;
      return { defaultScorecardId: scorecardId };
    });

    return usersAPI
      .updateDefaultScorecardId(this.user.id, scorecardId)
      .catch(() => this.setState({ defaultScorecardId: previousDefaultScorecardId }));
  };

  clearDefaultScorecardId = () => {
    this.setDefaultScorecardId(null);
  };

  setDefaultReferenceId = (templateId) => {
    let previousDefaultReferenceId;

    this.setState((prevState) => {
      previousDefaultReferenceId = prevState.defaultReferenceId;
      return { defaultReferenceId: templateId };
    });

    return usersAPI
      .updateDefaultReferenceTemplateId(this.user.id, templateId)
      .catch(() => this.setState({ defaultReferenceId: previousDefaultReferenceId }));
  };

  clearDefaultReferenceId = () => {
    this.setDefaultReferenceId(null);
  };

  fetchScorecards = () => {
    customScorecardListAPI
      .fetchEditableCustomScorecards()
      .then((scorecardlist) => this.setState({ scorecardlist }));
  };

  fetchReferences = () => {
    ReferenceTemplateListAPI.fetchEditableReferences().then((referenceList) =>
      this.setState({ referenceList })
    );
  };

  saveEmailTemplate = () => {
    this.fetchTemplates();
  };

  deleteEmailTemplate = (id) => {
    axios.delete(`/api/emailtemplate/${id}/`).then(() => {
      this.setState({ emailTemplateList: this.state.emailTemplateList.filter((o) => o.id !== id) });
    });
  };

  sendScorecardUpdate = (fieldName, value, urlString, customScorecardId = null) => {
    const data = { [fieldName]: value };
    if (customScorecardId !== null) {
      // if updating the custom scorecard type, a non-null id will be sent in. Use
      // that on the backend to get the appropriate scorecard model.
      data['id'] = customScorecardId;
    }
    axios.patch(urlString, data);
  };

  render() {
    const showSchoolGroups = this.isDistrictAdmin;

    return (
      <div className="user-settings-container">
        <SettingsAnchorTagHeader
          isSuperAdminUser={this.isSuperAdminUser}
          showSchoolGroups={showSchoolGroups}
          hasAdminUsers={this.state.adminUsers.length > 0}
          hasOnboardingModule={this.hasOnboardingModule}
        />
        <Notifications newApplicationStatuses={this.state.newApplicationStatuses} />
        <CustomScorecardList
          scorecardlist={this.state.scorecardlist}
          defaultScorecardId={this.state.defaultScorecardId}
          setDefaultScorecardId={this.setDefaultScorecardId}
          clearDefaultScorecardId={this.clearDefaultScorecardId}
        />
        {this.isSuperAdminUser && (
          <CustomReferenceList
            referenceList={this.state.referenceList}
            defaultReferenceId={this.state.defaultReferenceId}
            setDefaultReferenceId={this.setDefaultReferenceId}
            clearDefaultReferenceId={this.clearDefaultReferenceId}
          />
        )}
        <EmailTemplateList
          emailTemplateList={this.state.emailTemplateList}
          saveEmailTemplate={this.saveEmailTemplate}
          deleteEmailTemplate={this.deleteEmailTemplate}
          isDistrictAdmin={this.isDistrictAdmin}
          isSuperAdmin={this.isSuperAdminUser}
          hasApplicationConfirmationFF={this.hasApplicationConfirmationFF}
        />
        {showSchoolGroups && (
          <SchoolGroups
            canCreateSchoolGroups={this.isSuperAdminUser}
            canEditSchoolGroups={this.isSuperAdminUser}
            canDeleteSchoolGroups={this.isSuperAdminUser}
            canAddSchoolsToSchoolGroup={this.isDistrictAdmin}
          />
        )}
        {this.isSuperAdminUser && <CategoriesList />}
        {this.isSuperAdminUser && <ApplicationQuestionSets />}
        {this.isSuperAdminUser && (
          <ApplicantSourceList
            district={this.user?.profile?.district?.id}
            sources={this.state.sources}
          />
        )}
        {this.isSuperAdminUser && (
          <ApplicationStatusList emailTemplateList={this.state.emailTemplateList} />
        )}
        {this.isSuperAdminUser && <CustomTagList />}
        {this.hasOnboardingModule && this.isSuperAdminUser && (
          <div>
            <UniversalTasksList
              newApplicationStatuses={this.state.newApplicationStatuses}
              adminUsers={this.state.adminUsers}
            />

            {this.state.adminUsers.length > 0 && (
              <CandidateForms adminUsers={this.state.adminUsers} />
            )}
          </div>
        )}

        {this.hasOnboardingModule && this.isSuperAdminUser && (
          <CustomProfileFieldList adminUsers={this.state.adminUsers} />
        )}
      </div>
    );
  }
}
