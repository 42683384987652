import './buttoncheckbox.css';

import { Component } from 'react';

import PropTypes from 'prop-types';

export default class ButtonCheckBox extends Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    dataTestId: PropTypes.string,
  };

  checkBox = () => this.input.click();

  render() {
    return (
      <div className="check-r">
        <input
          ref={(input) => (this.input = input)}
          type="checkbox"
          className="button-checkbox"
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        <button
          className={`button-checkbox ${this.props.checked ? 'active' : ''}`}
          type="button"
          onClick={this.checkBox}
          data-testid={this.props.dataTestId}
        >
          {this.props.label}
        </button>
      </div>
    );
  }
}
