import {
  createFormFieldConfig,
  emailMatchRule,
  isValidEmailRule,
  minLengthRule,
  noEmptySpaceRule,
  passwordMatchRule,
  requiredRule,
  uppercaseOrSpecialCharacterRule,
} from '../../../../utils/formConfig';

/**
 * Object representation of signup form
 *
 * FORMAT: Input Label, Input name, Input type
 */
const signupForm = {
  fname: {
    ...createFormFieldConfig('First name', 'fname', 'text'),
    validationRules: [requiredRule('name'), minLengthRule('name', 2)],
  },
  lname: {
    ...createFormFieldConfig('Last name', 'lname', 'text'),
    validationRules: [requiredRule('name'), minLengthRule('name', 2)],
  },
  email: {
    ...createFormFieldConfig('Email', 'email', 'email'),
    validationRules: [requiredRule('email'), isValidEmailRule()],
  },
  emailConfirm: {
    ...createFormFieldConfig('Confirm email address', 'confirmEmail', 'email'),
    validationRules: [emailMatchRule()],
  },
  password: {
    ...createFormFieldConfig('Password', 'password', 'password'),
    validationRules: [
      requiredRule('Password'),
      minLengthRule('Password', 10),
      uppercaseOrSpecialCharacterRule('Password'),
      noEmptySpaceRule('Password'),
    ],
  },
  passwordConfirm: {
    ...createFormFieldConfig('Confirm Password', 'confirmPassword', 'password'),
    validationRules: [passwordMatchRule()],
  },
};

export default signupForm;
