import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as TextIcon } from 'assets/icon-pen-tip.svg';
import { ReactComponent as YesNoIcon } from 'assets/icon-check-with-circle.svg';
import { ReactComponent as NimbleLogoBlack } from 'assets/nimble-logo-black.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icon-multiple-choice.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icon-paperclip-black.svg';
import { ReactComponent as VideoIcon } from 'assets/icon-video.svg';
import { CheckmarkBlackBox } from 'ui-kit/icons';
import { DirectionTextIcon } from 'ui-kit/icons';
import { SchoolPreferencesQuestionIcon } from 'ui-kit/icons';
import { ReactComponent as EditIcon } from 'assets/icons/AccountManagementIcons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon_trash.svg';

import { questionType } from 'utils/enums';

let mapping = {
  [questionType.yes_no]: {
    icon: YesNoIcon,
    header: 'Yes/No Question',
    getText: (obj) => obj.prompt,
  },
  [questionType.open_response]: {
    icon: TextIcon,
    header: 'Text Question',
    getText: (obj) => obj.prompt,
  },

  [questionType.direction_text]: {
    icon: DirectionTextIcon,
    header: 'Direction Text',
    getText: (obj) => obj.prompt,
  },
  [questionType.text_question_model]: {
    icon: TextIcon,
    header: 'Text Question',
    getText: (obj) => obj.question,
  },
  [questionType.nimble]: {
    icon: NimbleLogoBlack,
    header: 'Nimble Question',
    getText: (obj) => obj.question,
  },
  [questionType.multiple_choice]: {
    icon: MultipleChoiceIcon,
    header: 'Multiple Choice Question',
    getText: (obj) => obj.prompt,
  },
  [questionType.attachment]: {
    icon: AttachmentIcon,
    header: 'Attachment',
    getText: (obj) => obj.title,
  },
  [questionType.videoLink]: {
    icon: VideoIcon,
    header: 'Video Link',
    getText: (obj) => obj.title,
  },
  [questionType.schoolPreferences]: {
    icon: SchoolPreferencesQuestionIcon,
    header: 'School Preferences',
    getText: (obj) => {
      if (obj.is_automatic_list) {
        return 'Automatic list';
      } else {
        return `Manual list - ${obj.school_choices.length} school${
          obj.school_choices.length !== 1 ? 's' : ''
        } currently selected`;
      }
    },
  },
  [questionType.statementCheckbox]: {
    icon: CheckmarkBlackBox,
    header: 'Statement Checkbox',
    getText: (obj) => obj.prompt,
  },
};

const getQuestionDetails = (item, internal_requirements_specified) => {
  const { is_required_external, is_required_internal, is_visible_external, is_visible_internal } =
    item;

  if (!internal_requirements_specified) {
    if (is_required_external) {
      return ' (required)';
    }
  } else {
    if (
      // Visible to everyone, required for no one
      is_visible_external &&
      is_visible_internal &&
      !is_required_external &&
      !is_required_internal
    ) {
      return '';
    } else if (
      // Visible to everyone, required for everyone
      is_visible_external &&
      is_visible_internal &&
      is_required_external &&
      is_required_internal
    ) {
      return ' (required)';
    } else if (
      // Visible to everyone, required for only one group
      is_visible_external &&
      is_visible_internal &&
      is_required_external
    ) {
      return ' (required for external candidates)';
    } else if (
      // Visible to everyone, required for only one group
      is_visible_external &&
      is_visible_internal &&
      is_required_internal
    ) {
      return ' (required for internal candidates)';
    } else if (
      // Visible to only one, required for that one group
      is_visible_external &&
      is_required_external
    ) {
      return ' (external candidates only, required)';
    } else if (
      // Visible to only one, required for that one group
      is_visible_internal &&
      is_required_internal
    ) {
      return ' (internal candidates only, required)';
    } else if (
      // Visible to only one, not required for that one
      is_visible_external &&
      !is_required_external
    ) {
      return ' (external candidates only)';
    } else if (
      // Visible to only one, not required for that one
      is_visible_internal &&
      !is_required_internal
    ) {
      return ' (internal candidates only)';
    }
  }
};

export default function JobQuestionRow({
  obj,
  type,
  deleteQuestionRow,
  editQuestionRow,
  internal_requirements_specified,
}) {
  let item = mapping[type];
  const Icon = item.icon;

  const getAndFormatItemText = (obj) => {
    let text = item.getText(obj) || obj?.question_text || '';
    if (obj.question_type === 'question_type/direction_text') {
      text = text?.replace(/<[^>]*>?/gm, '');
    }
    const textFormatted = text.length > 56 ? `${text.slice(0, 56)}...` : text;
    return textFormatted;
  };
  return (
    <QuestionContainer>
      <QuestionType>
        <QuestionTypeIconContainer>
          <Icon />
        </QuestionTypeIconContainer>
        <p>
          {item.header}
          {getQuestionDetails(obj, internal_requirements_specified)}
        </p>
      </QuestionType>
      {deleteQuestionRow && <DeleteButton onClick={deleteQuestionRow} />}
      <QuestionText>{getAndFormatItemText(obj)}</QuestionText>
      {editQuestionRow && <EditButton onClick={editQuestionRow} />}
    </QuestionContainer>
  );
}

JobQuestionRow.propTypes = {
  obj: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.values(questionType)).isRequired,
  deleteQuestionRow: PropTypes.func,
  editQuestionRow: PropTypes.func,
  internal_requirements_specified: PropTypes.bool.isRequired,
};

const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-areas:
    'question-type   delete-button'
    'question-text   edit-button';
  grid-column-gap: 8px;
  grid-row-gap: 12px;

  border-radius: 3px;
  box-shadow: var(--box-shadow);
  background-color: white;
  padding: 21px;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 768px) {
    @supports (display: grid) {
      grid-column-gap: 15px;
    }
  }
`;

const QuestionType = styled.div`
  grid-area: question-type;
  display: flex;
  align-items: center;

  color: #999999;
  font-size: 15px;
`;

const QuestionTypeIconContainer = styled.div`
  width: 19px;
  height: 19px;
  margin-right: 10px;
`;

const DeleteButton = styled(DeleteIcon)`
  grid-area: delete-button;
  justify-self: end;

  margin-right: 3px;
  margin-top: 2px;
  cursor: pointer;
`;

const EditButton = styled(EditIcon)`
  grid-area: edit-button;
  justify-self: end;

  margin-top: 3px;
  cursor: pointer;
`;

const QuestionText = styled.p`
  grid-area: question-text;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (min-width: 768px) {
    @supports (display: grid) {
      font-size: 18px;
    }
  }
`;
