import { useState, useEffect } from 'react';
import styled from 'styled-components';

import ModalFooterNew from '../../common/Modal/ModalFooterNew';
import DropdownSingle from '../../common/DropdownSingle';
import { AltModal, MultiSelect } from 'ui-kit';

import {
  notificationTypeValue,
  notificationJobOptions,
  notificationJobsValue,
  notificationFrequency,
  notificationSchools,
  notificationSchoolsPreference,
  notifyMeForOptions,
  notifyMeForMapping,
  notifyMeWhenOptions,
  notifyMeWhenMapping,
} from '../../../utils/NotificationEnums';
import {
  formatCategoryOptions,
  formatSchoolOptions,
  frequencyDropdownDisabled,
  getInitialCategories,
  getInitialSchools,
  getNotificationTypeOptions,
  getStatusOptions,
  showJobsDropdown,
  showSchoolsDropdown,
  noStatusSelected,
} from './NotificationModalUtils';

import SchoolsAPI from '../../../api/schoolsAPI';
import SubcategoriesAPI from '../../../api/subcategoriesAPI';

import auth from '../../../utils/auth';

const NotificationModal = ({
  notification,
  show,
  closeModal,
  newApplicationStatuses,
  handleSubmit,
  handleDelete,
  hasReferenceCompletedNotification,
}) => {
  /** State relating to the notification object
   * ======================================================================================= */
  const [type, setType] = useState(notification.type);
  const [status_id, setStatusId] = useState(notification.status?.id ?? null);
  const [frequency, setFrequency] = useState(notification.frequency);
  const [jobs_preference, setJobsPreference] = useState(notification.jobs_preference);
  const [job_categories, setJobCategories] = useState(getInitialCategories(notification));
  const [schools_preference, setSchoolsPreference] = useState(notification.schools_preference);
  const [schools, setSchools] = useState(getInitialSchools(notification));
  const [notify_me_for, setNotifyMeFor] = useState(notification.notify_me_for);
  const [notify_me_when, setNotifyMeWhen] = useState(notification.notify_me_when);

  /** Other state
   * ======================================================================================= */
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    SubcategoriesAPI.fetch().then((results) => setCategoryOptions(results));
    SchoolsAPI.fetchActiveSchools().then((schools) => setSchoolOptions(schools));
  }, []);

  const EDIT_MODE = notification.id !== undefined;
  const certainCategoriesSelected = jobs_preference === notificationJobsValue.certain_categories;
  const schoolsDropdownVisible = showSchoolsDropdown(type);
  const jobDropdownVisible = showJobsDropdown(type);

  function saveNotification() {
    try {
      checkForErrors();
      handleSubmit({
        id: notification.id,
        type,
        status_id,
        frequency,
        jobs_preference,
        job_categories,
        schools,
        schools_preference,
        notify_me_for,
        notify_me_when,
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  function checkForErrors() {
    if (type === notificationTypeValue.status_change && noStatusSelected(status_id)) {
      throw new Error('Please select status to continue');
    }
    if (schools_preference === notificationSchools.only_certain_schools && schools.length === 0) {
      throw new Error('Please select a school to continue');
    }
    if (
      jobs_preference === notificationJobsValue.certain_categories &&
      job_categories.length === 0
    ) {
      throw new Error('Please select a category to continue');
    }
  }

  function handleTypeChange(selectedObject) {
    const { value } = selectedObject;
    handleStatusChangeSpecifics(value);
    handleJobDropdownSpecifics(value);
    handleReferenceCompletedDefaults(value);

    setType(value);
    setErrorMessage('');
  }

  function handleStatusChangeSpecifics(value) {
    if (value !== notificationTypeValue.status_change) {
      clearSchoolsAndStatus();
    } else if (schools_preference === null) {
      setSchoolsPreference(notificationSchools.all_schools);
    }
  }

  function clearSchoolsAndStatus() {
    setSchools([]);
    setSchoolsPreference(null);
    setStatusId(null);
  }

  function handleJobDropdownSpecifics(value) {
    if (!showJobsDropdown(value)) {
      clearJobSelections();
    } else {
      if (jobs_preference === null) {
        setJobsPreference(notificationJobsValue.all_jobs);
      }
    }
  }

  function clearJobSelections() {
    setJobsPreference(null);
    setJobCategories([]);
  }

  function handleReferenceCompletedDefaults(value) {
    if (value === notificationTypeValue.reference_completed) {
      // If reference_completed was selected, set the defaults
      setNotifyMeFor(notifyMeForMapping.sent_request);
      setNotifyMeWhen(notifyMeWhenMapping.any);
    } else {
      setNotifyMeFor(null);
      setNotifyMeWhen(null);
    }
  }

  function handleJobsPreferenceChange(selectedObject) {
    const preference = selectedObject.value;
    setJobsPreference(preference);
    if (preference !== notificationJobsValue.certain_categories) {
      setJobCategories([]);
    }
  }

  function handleSchoolPreferenceChange(selectedObject) {
    const preference = selectedObject.value;
    setSchoolsPreference(preference);
    if (preference !== notificationSchools.only_certain_schools) {
      setSchools([]);
    }
  }

  function deleteNotification() {
    handleDelete(notification);
  }

  const noBottomSection =
    !jobDropdownVisible &&
    !schoolsDropdownVisible &&
    type !== notificationTypeValue.reference_completed;

  const filteredTypeOptions = getNotificationTypeOptions(
    // Use notification.type instead of type here because we want the initial type
    notification.type,
    hasReferenceCompletedNotification
  );

  return (
    <AltModal.AltModal isOpen={show} onClose={closeModal}>
      <AltModal.Title>{EDIT_MODE ? 'Edit Notification' : 'New Notification'}</AltModal.Title>
      <AltModal.Body>
        <ModalSection>
          <DropdownContainerHalf>
            <DropdownSingle
              label={'Notification type'}
              handleChange={handleTypeChange}
              options={filteredTypeOptions}
              value={filteredTypeOptions.find((o) => o.value === type)}
            />
          </DropdownContainerHalf>
          {type === notificationTypeValue.reference_completed && (
            <DropdownContainerHalf>
              {auth.isDistrictAdmin() && (
                <DropdownSingle
                  label="Notify me for..."
                  handleChange={({ value }) => {
                    setNotifyMeFor(value);
                    setErrorMessage('');
                  }}
                  value={notifyMeForOptions.find((o) => o.value === notify_me_for)}
                  options={notifyMeForOptions}
                />
              )}
            </DropdownContainerHalf>
          )}
          {type === notificationTypeValue.status_change && (
            <DropdownContainerHalf>
              <DropdownSingle
                label="Status selection"
                handleChange={({ value }) => {
                  setStatusId(value);
                  setErrorMessage('');
                }}
                placeholder="N/A"
                options={getStatusOptions(newApplicationStatuses)}
                isSearchable={true}
                value={getStatusOptions(newApplicationStatuses).find((s) => s.id === status_id)}
              />
            </DropdownContainerHalf>
          )}
        </ModalSection>
        <ModalSection noMarginBottom={noBottomSection}>
          <DropdownSingle
            label={'Frequency'}
            value={notificationFrequency().find((object) => object.value === frequency)}
            handleChange={(selectedObject) => setFrequency(selectedObject.value)}
            options={notificationFrequency()}
            disabled={frequencyDropdownDisabled(type)}
          />
        </ModalSection>
        {type === notificationTypeValue.reference_completed ? (
          <ModalSection noMarginBottom>
            <DropdownSingle
              label="Notify me when..."
              handleChange={({ value }) => {
                setNotifyMeWhen(value);
                setErrorMessage('');
              }}
              value={notifyMeWhenOptions.find((o) => o.value === notify_me_when)}
              options={notifyMeWhenOptions}
              onChange={({ value }) => setNotifyMeWhen(value)}
            />
          </ModalSection>
        ) : (
          <>
            {jobDropdownVisible && (
              <>
                <ModalSection noMarginBottom={certainCategoriesSelected || !schoolsDropdownVisible}>
                  <DropdownSingle
                    label={'Jobs'}
                    value={notificationJobOptions().find((o) => o.value === jobs_preference)}
                    handleChange={handleJobsPreferenceChange}
                    options={notificationJobOptions()}
                    placeholder="N/A"
                  />
                </ModalSection>
                {certainCategoriesSelected && (
                  <ModalSection noMarginBottom={!schoolsDropdownVisible}>
                    <StyledMultiSelect
                      options={formatCategoryOptions(categoryOptions)}
                      value={job_categories}
                      placeholder="Select categories"
                      onChange={(values) => setJobCategories(values ?? [])}
                    />
                  </ModalSection>
                )}
              </>
            )}
            {schoolsDropdownVisible && (
              <>
                <ModalSection noMarginBottom>
                  <DropdownSingle
                    label={'Schools'}
                    value={notificationSchoolsPreference().find(
                      (object) => object.value === schools_preference
                    )}
                    handleChange={handleSchoolPreferenceChange}
                    options={notificationSchoolsPreference()}
                    placeholder="N/A"
                  />
                </ModalSection>
                {schools_preference === notificationSchools.only_certain_schools && (
                  <ModalSection noMarginBottom>
                    <StyledMultiSelect
                      options={formatSchoolOptions(schoolOptions)}
                      value={schools}
                      placeholder="Select school"
                      onChange={(values) => setSchools(values ?? [])}
                    />
                  </ModalSection>
                )}
              </>
            )}
          </>
        )}
      </AltModal.Body>
      <AltModal.Actions>
        <ModalFooterNew
          cancel={closeModal}
          deleteFunction={EDIT_MODE ? deleteNotification : null}
          deleteButtonLabel={'Delete Notification'}
          save={saveNotification}
          errorMessage={errorMessage}
        />
      </AltModal.Actions>
    </AltModal.AltModal>
  );
};

export default NotificationModal;

const ModalSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '3rem')};
  flex-direction: ${(props) => (props.noMarginBottom ? 'column' : 'row')};
`;

const DropdownContainerHalf = styled.div`
  width: 48.5%;
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 100%;
  margin-top: 8px;
`;
