import { Link, useRouteMatch } from 'react-router-dom';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import UserDropdown from '../../components/NavBar/userDropdown';
import IDOElogo from 'assets/state_logos/idoe_new_logo.png';
import OECOSLlogo from 'assets/state_logos/OECOSL_reducedsize.png';
import { StateJobBoardHeaderProps } from './types';
import auth from 'utils/auth';
import { ATSStateJobBoardHeaderDataTestIds } from 'data-testids/ATS';

export const StateJobBoardHeader: React.FC<StateJobBoardHeaderProps> = ({
  dropdownProps,
  count,
}) => {
  const match = useRouteMatch<{ id: string }>('/jobs/state/:id');
  const rawStateCode = match?.params.id;
  const stateCode = rawStateCode?.toUpperCase();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = auth.getUser();

  const pathToReturnTo = `${location.pathname}`;

  return (
    <div>
      <TopContainer>
        <Stack direction={'row'}>
          <LogoContainer>
            <LogoItem>
              <StyledLogo1 src={IDOElogo} alt="IDOE logo" />
            </LogoItem>
            <LogoItem>
              <StyledLogo2 src={OECOSLlogo} alt="OECOSL logo" />
            </LogoItem>
          </LogoContainer>
        </Stack>
        {!user ? (
          <CandidateLoginButton
            to={{
              pathname: '/login',
              state: {
                previousLocation: pathToReturnTo,
              },
            }}
          >
            {isMobile ? 'Login' : 'Candidate Login'}
          </CandidateLoginButton>
        ) : (
          <UserDropdown {...dropdownProps} />
        )}
      </TopContainer>
      <Container container>
        <Grid item xs={12} md={12}>
          {stateCode === 'IN' ? (
            <Title variant="h1">Indiana Educator Job Board</Title>
          ) : (
            <Title>School Personnel Job Search</Title>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <JobsSubtitle data-testid={ATSStateJobBoardHeaderDataTestIds.JOB_COUNT}>
            {count} JOBS
          </JobsSubtitle>
        </Grid>
      </Container>
    </div>
  );
};

const CandidateLoginButton = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.gray.darker}`,
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.white.main,
  color: theme.palette.text.secondary,
  display: 'flex',
  fontSize: '15px !important',
  fontWeight: theme.typography.fontWeightMediumBold,
  height: '48px',
  lineHeight: '24px',
  padding: theme.spacing(1, 4),
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px !important',
    padding: theme.spacing(1, 2),
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3.75, 2, 2.5, 2),
  backgroundColor: theme.palette.white.main,
  maxWidth: '1350px',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3.75, 3, 2.5, 2),
  },
}));

const Container = styled(Grid)(({ theme }) => ({
  display: 'block',
  borderTop: `1px solid ${theme.palette.gray.darker}`,
  gridTemplateRows: '102px minmax(48px, auto) 74px minmax(35px, 1fr)',
  gridColumnGap: theme.spacing(2.5),
  alignItems: 'center',
  backgroundColor: theme.palette.white.main,
  padding: theme.spacing(0.5, 2.5),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5, 2.5),
  },
}));

const LogoContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
}));

const LogoItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledLogo1 = styled('img')(({ theme }) => ({
  height: '80px',
  minWidth: '226px',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '226px',
  [theme.breakpoints.down('md')]: {
    height: '60px',
    minWidth: '180px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    minWidth: '120px',
  },
}));

const StyledLogo2 = styled('img')(({ theme }) => ({
  height: '65px',
  minWidth: '210px',
  margin: theme.spacing(1.25, 0, 0, 5),
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '210px',
  [theme.breakpoints.down('md')]: {
    height: '60px',
    minWidth: '180px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    minWidth: '120px',
    margin: theme.spacing(0.375, 0, 0, 1.5),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '24px',
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingLeft: theme.spacing(0),
  minWidth: '100%',
  whiteSpace: 'nowrap',
}));

const JobsSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightLight,
  padding: 0,
  paddingBottom: theme.spacing(1),
}));
