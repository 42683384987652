import { useState, useRef, useEffect } from 'react';

import { AddOrEditAlertModalProps, ManageAlertModalProps } from './types';
import { AltModal as Modal, Input, Label } from 'ui-kit';
import BellIcon from 'assets/icons/icon_bell.svg';
import CompleteIcon from 'assets/icons/TaskIcons/green-circle-checkmark.svg';
import { getDisplayNameText } from './utils';
import { isValidEmail } from 'utils/emailutils';
import { JobAlertsContainer } from './JobAlertsContainer';
import { StateJobBoardDropDown } from '../Filters/StateJobBoardDropDown';
import { StateJobBoardDropDownWithSubOptions } from '../Filters/StateJobBoardDropDownWithSubOptions';
import statejobboardsAPI from 'api/statejobboardsAPI';

import {
  Actions,
  Button,
  CenteredPrimaryButton,
  ConfirmationContainer,
  FormInputError,
  GreenAddAlert,
  InputContainer,
  NoAlertContainer,
  NotificationIcon,
  PaddedText,
  PaddedTextBottomOnly,
  PrimaryButton,
  SubMessage,
  SubscribeForm,
  SuccessAlertDiv,
  SuccessIcon,
} from './JobAlertsModal.styles';
import { ATSStateJobBoardAlertsModalDataTestIds } from 'data-testids/ATS';

export const AddOrEditAlertModal: React.FC<AddOrEditAlertModalProps> = ({
  categories,
  editingId,
  emailState,
  emailToUse,
  hasMarketplaceFF,
  jobAlerts,
  onAddSuccess,
  onClose,
  onDistrictSelection,
  onEmailStateChange,
  onSelectionErrorChange,
  onSubcategorySelection,
  onUpdateSuccess,
  openModalType,
  organizations,
  selectedDistricts,
  selectedSubcategories,
  selectionError,
  stateCode,
  token,
  emailDisabled,
}) => {
  const firstInputRef = useRef(null);
  const isAddModal = openModalType === 'add';
  const hasSelectionError = Boolean(selectionError);

  const subscribeToJobAlerts = () => {
    statejobboardsAPI
      .subscribe(stateCode, emailState.email, selectedSubcategories, selectedDistricts, token)
      .then((response) => {
        onAddSuccess(response.token, response.email);
      })
      .catch((error) =>
        onEmailStateChange((prevState) => {
          return { ...prevState, error: error.message };
        })
      );
  };

  const updateJobAlerts = () => {
    statejobboardsAPI
      .update(editingId, stateCode, emailToUse, selectedSubcategories, selectedDistricts, token)
      .then((response) => {
        const alertsCopy = [...jobAlerts];
        const alertIndex = jobAlerts.findIndex((jobAlert) => jobAlert.id === editingId);
        const editedAlert = response;
        alertsCopy[alertIndex] = editedAlert;
        onUpdateSuccess(alertsCopy);
      })
      .catch((error) => {
        onEmailStateChange((prevState) => {
          return { ...prevState, error: error.message };
        });
      });
  };

  const validateForm = () => {
    if (!emailState.email) {
      onEmailStateChange((prevState) => ({ ...prevState, error: 'Email is required.' }));
      return false;
    }
    if (!isValidEmail(emailState.email)) {
      onEmailStateChange((prevState) => ({
        ...prevState,
        error: 'Please make sure your email address is correct.',
      }));
      return false;
    }
    if (selectedDistricts.length === 0 && selectedSubcategories.length === 0) {
      onSelectionErrorChange('Please select at least one organization or job category.');
      return false;
    }
    onSelectionErrorChange(null);
    return true;
  };

  const handleSubmit = () => {
    const isValid = validateForm();

    if (!isValid) return;

    if (isAddModal) {
      subscribeToJobAlerts();
    } else {
      updateJobAlerts();
    }
  };

  return (
    <Modal.AltModal isOpen={true} onClose={onClose} data-testid="modal">
      <Modal.Title>{isAddModal ? 'Add alert' : 'Edit job alert'}</Modal.Title>
      <Modal.Body style={{ paddingTop: '20px' }}>
        <SubscribeForm>
          <InputContainer>
            <Label htmlFor="job-alerts-email">
              <PaddedTextBottomOnly>
                {isAddModal ? 'Enter your Email' : 'Update your email address'}
              </PaddedTextBottomOnly>
              <Input
                id="job-alerts-email"
                type="email"
                name="email"
                placeholder="me@schooldistrict.edu"
                required
                ref={firstInputRef}
                value={hasMarketplaceFF ? emailToUse : emailState.email}
                hasError={Boolean(emailState.error)}
                onChange={(event) => onEmailStateChange({ email: event.target.value })}
                disabled={emailDisabled}
              />
              {emailState.error && <FormInputError>{emailState.error}</FormInputError>}
            </Label>
          </InputContainer>
          <InputContainer>
            <Label htmlFor="job-alerts-categories" data-testid="job-categories-label">
              <PaddedText>Job categories of interest</PaddedText>
              <StateJobBoardDropDownWithSubOptions
                values={selectedSubcategories || []}
                setValues={onSubcategorySelection}
                options={categories}
                dataTestId={ATSStateJobBoardAlertsModalDataTestIds.CATEGORY_DROPDOWN}
                displayName={getDisplayNameText(
                  selectedSubcategories,
                  'categories',
                  categories,
                  true
                )}
                isActive={hasSelectionError}
                setIsActive={() => {}}
                mainColor="#151E47"
                highlightColor="#cc0033"
                showSearchInput
              />
            </Label>
            {selectionError && <FormInputError>{selectionError}</FormInputError>}
          </InputContainer>
          <InputContainer>
            <Label htmlFor="job-alerts-organizations">
              <PaddedText>Organization</PaddedText>
              <StateJobBoardDropDown
                values={selectedDistricts || []}
                setValues={onDistrictSelection}
                options={organizations}
                dataTestId={ATSStateJobBoardAlertsModalDataTestIds.ORGANIZATION_DROPDOWN}
                displayName="Select all that apply"
                isActive={hasSelectionError}
                setIsActive={() => {}}
                mainColor="#151E47"
                highlightColor="#cc0033"
                showSearchInput
                showSelectAllButtons
                displayNameIsDynamic
                optionNamePlural="organizations"
              />
              {selectionError && <FormInputError>{selectionError}</FormInputError>}
            </Label>
          </InputContainer>
        </SubscribeForm>
      </Modal.Body>
      <Modal.Actions>
        <Actions>
          <Button onClick={onClose}>Cancel</Button>
          <PrimaryButton type="submit" onClick={handleSubmit} disabled={Boolean(emailState.error)}>
            Confirm
          </PrimaryButton>
        </Actions>
      </Modal.Actions>
    </Modal.AltModal>
  );
};

export const ManageAlertModal: React.FC<ManageAlertModalProps> = ({
  editingId,
  emailState,
  emailToUse,
  handleSetIsSubmessageVisible,
  handleSetIsSuccessAlertVisible,
  hasMarketplaceFF,
  isSuccessAlertVisible,
  isSuccessSubmessageVisible,
  jobAlerts,
  onAdd,
  onClose,
  onDistrictSelection,
  onEditingIdChange,
  onModifyingJobAlerts,
  onSubcategorySelection,
  onSuccessAlertChange,
  resetInputState,
  stateCode,
  successAlert,
  token,
}) => {
  const [confirmDelete, setConfirmDelete] = useState<number[]>([]);
  const [isDeleteAlertVisible, setIsDeleteAlertVisible] = useState(false);

  const onUnsubscribeToJobAlerts = (alertId) => {
    statejobboardsAPI.unsubscribe(alertId, stateCode, token).then(() => {
      if (jobAlerts.length === 1) {
        resetInputState();
      }
      onSuccessAlertChange((previousState) => ({
        ...previousState,
        message: 'Alert deleted successfully',
      }));

      const alertsCopy = [...jobAlerts].filter((a) => a.id !== parseInt(alertId));

      onModifyingJobAlerts(alertsCopy);
      setIsDeleteAlertVisible(true);
    });
  };

  useEffect(() => {
    if (isDeleteAlertVisible) {
      const timerId = setTimeout(() => {
        setIsDeleteAlertVisible(false);
      }, 7000);

      return () => {
        clearTimeout(timerId);
      };
    }

    return undefined;
  }, [isDeleteAlertVisible]);

  const onUnsubscribe = (alertId) => {
    onUnsubscribeToJobAlerts(alertId);
    setConfirmDelete([alertId]);
  };

  const alertTextToRender = () => {
    if (isSuccessAlertVisible && !editingId) {
      return 'Alert added successfully';
    } else if (isSuccessAlertVisible && editingId) {
      return 'Alert updated successfully';
    } else if (isDeleteAlertVisible) {
      return 'Alert deleted successfully';
    } else {
      return null;
    }
  };

  return (
    <Modal.AltModal isOpen={true} onClose={onClose} data-testid="modal">
      <Modal.Title data-testid="manage-alerts-title">Your alerts</Modal.Title>
      <Modal.Body style={{ paddingTop: '20px' }}>
        <div>
          {jobAlerts.length > 0 ? (
            <ConfirmationContainer>
              {isSuccessAlertVisible && (
                <SuccessAlertDiv>
                  <SuccessIcon alt="success-icon" src={CompleteIcon} />
                  {alertTextToRender()}
                  {isSuccessSubmessageVisible ? (
                    <div>
                      You will receive a confirmation email at{' '}
                      {hasMarketplaceFF ? emailToUse : emailState.email}. Please confirm your
                      address via email to start receiving alerts. If you do not receive an email,
                      ensure that the above email address is correct. If this email address is
                      incorrect, click{' '}
                      <a
                        href={`${window.location.origin}/jobs/state/${stateCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        here{' '}
                      </a>{' '}
                      to set up an alert with the correct address.
                    </div>
                  ) : null}
                </SuccessAlertDiv>
              )}
              <JobAlertsContainer
                jobAlerts={jobAlerts}
                onEditingIdChange={onEditingIdChange}
                onDistrictSelection={onDistrictSelection}
                onSubcategorySelection={onSubcategorySelection}
                onUnsubscribe={onUnsubscribe}
                onConfirmDeleteChange={setConfirmDelete}
                confirmDelete={confirmDelete}
                hasMarketplaceFF={hasMarketplaceFF}
                emailToUse={emailToUse}
                handleSetIsSubmessageVisible={handleSetIsSubmessageVisible}
                handleSetIsSuccessAlertVisible={handleSetIsSuccessAlertVisible}
              />
              <GreenAddAlert onClick={onAdd}>+ Add new alert</GreenAddAlert>
            </ConfirmationContainer>
          ) : (
            <NoAlertContainer>
              {successAlert && (
                <SuccessAlertDiv>
                  <SuccessIcon alt="success-icon" src={CompleteIcon} />
                  {successAlert.message}
                  {successAlert?.submessage_html && (
                    <SubMessage>{successAlert.submessage_html}</SubMessage>
                  )}
                </SuccessAlertDiv>
              )}
              <NotificationIcon src={BellIcon} />
              <div>You don&apos;t have any alerts</div>
              <div style={{ justifyContent: 'center' }}>
                <CenteredPrimaryButton onClick={onAdd}>Add New Alert</CenteredPrimaryButton>
              </div>
            </NoAlertContainer>
          )}
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Actions>
          <Button onClick={onClose}>Cancel</Button>
          <PrimaryButton type="submit" onClick={onClose}>
            Done
          </PrimaryButton>
        </Actions>
      </Modal.Actions>
    </Modal.AltModal>
  );
};
