import classNames from 'classnames';

import CompleteIcon from 'assets/icons/TaskIcons/green-circle-checkmark.svg';
import IncompleteIcon from 'assets/icons/TaskIcons/incomplete-circle.svg';
import OverdueIcon from 'assets/icons/TaskIcons/overdue-red-circle.svg';

export default function StatusIcon({ isComplete, isOverdue, onClick }) {
  return (
    <img
      className={classNames('mr1', { pointer: onClick })}
      src={isComplete ? CompleteIcon : isOverdue ? OverdueIcon : IncompleteIcon}
      alt=""
      onClick={onClick}
    />
  );
}
