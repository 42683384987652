import { Redirect, Route, RouteProps } from 'react-router-dom';

import useCurrentUserPermissions from '../hooks/useCurrentUserPermissions';

const ATSRoute: React.FC<RouteProps> = (props) => {
  const { isPostingOnly } = useCurrentUserPermissions();

  // requireATS logic
  if (isPostingOnly) {
    return <Redirect to="/district/jobslist" />;
  }

  return <Route {...props} />;
};

export default ATSRoute;
