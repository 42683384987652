import styled from 'styled-components';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Fragment } from 'react';

import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import * as DashboardWidget from './components/DashboardWidget';
import * as Toggle from './components/Toggle';
import { CHART_COLOR_PALETTES } from './constants';
import { useApplicantDiversityMetrics } from './useApplicantDiversityMetrics';
import { appStatusType } from 'utils/enums';

const ApplicantDiversityWidget = ({
  hiringSeasons,
  schools,
  subcategories,
  applicationStatuses,
}) => {
  const toggleState = Toggle.useToggleState();

  const statusChoices = applicationStatuses.map((status) => ({
    id: status.id,
    value: status.id,
    label: status.label,
  }));

  const statusDropdownState = useChoiceDropdown({
    choices: statusChoices,
    initialSelectedChoiceIds: statusChoices.map((choice) => choice.id),
  });

  // Filter by selected statuses
  let statusesToFilter = applicationStatuses
    .filter((status) => statusDropdownState.selectedChoiceIds.includes(status.id))
    .map((status) => status.id);
  // If hired is selected, filter by onboarding too.
  const hiredStatuses = applicationStatuses.filter(
    (status) => status.status_type === appStatusType.hired
  );
  if (hiredStatuses.some((status) => statusDropdownState.selectedChoiceIds.includes(status.id))) {
    statusesToFilter = [
      ...statusesToFilter,
      ...applicationStatuses
        .filter((status) => status.status_type === appStatusType.onboarding)
        .map((status) => status.id),
    ];
  }

  const { data, isLoading } = useApplicantDiversityMetrics({
    hiringSeasons,
    schools,
    subcategories,
    statuses: statusesToFilter,
  });

  const dataset = toggleState.modeOneIsActive
    ? data.applicants_by_gender.filter((entry) => entry.value > 0)
    : data.applicants_by_ethnicity.filter((entry) => entry.value > 0);

  const totalItems = dataset.reduce((total, currentItem) => {
    return (total += currentItem.value);
  }, 0);

  const notEnoughData = totalItems <= 1;

  let colors = CHART_COLOR_PALETTES[8];
  if (dataset.length <= 4) {
    colors = CHART_COLOR_PALETTES[4];
  } else if (dataset.length <= 6) {
    colors = CHART_COLOR_PALETTES[6];
  }

  let message;
  if (notEnoughData) {
    message = 'Not enough data. Check your filters and try again.';
  }

  return (
    <DashboardWidget.Container>
      <DashboardWidget.Heading>Applicant diversity</DashboardWidget.Heading>
      <DashboardWidget.Body>
        <DashboardWidget.BodyActions>
          <ChoiceDropdown label="Status" choices={statusChoices} {...statusDropdownState} />
          <DashboardWidget.BodyActionPulledRight>
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={toggleState.modeOneIsActive}
                onClick={toggleState.setModeOneIsActive}
              >
                Gender
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={toggleState.modeTwoIsActive}
                onClick={toggleState.setModeTwoIsActive}
              >
                Race/Eth
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          </DashboardWidget.BodyActionPulledRight>
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message ? (
            <Placeholder>{message}</Placeholder>
          ) : (
            <>
              <ResponsiveContainer width="50%" height="100%">
                <PieChart>
                  <Pie
                    data={dataset}
                    innerRadius={'60%'}
                    outerRadius={'80%'}
                    paddingAngle={1}
                    startAngle={225}
                    endAngle={225 - 360}
                    dataKey="value"
                  >
                    {dataset.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <ScrollableWrapper>
                <Legend>
                  {dataset.map((entry, index) => (
                    <Fragment key={entry.name}>
                      <LegendCircle color={colors[index % colors.length]} />
                      <LegendPercent>
                        {(entry.value / data.total).toLocaleString('en-US', {
                          style: 'percent',
                          minimumFractionDigits: 1,
                        })}
                      </LegendPercent>
                      <LegendName>{entry.name}</LegendName>
                      <div /> {/* spacer */}
                      <div /> {/* spacer */}
                      <LegendSubtext>{entry.value}</LegendSubtext>
                    </Fragment>
                  ))}
                </Legend>
              </ScrollableWrapper>
            </>
          )}
        </DashboardWidget.BodyContent>
      </DashboardWidget.Body>
    </DashboardWidget.Container>
  );
};

export default ApplicantDiversityWidget;

const ScrollableWrapper = styled.div`
  max-height: 225px;
  width: 50%;
  overflow-y: scroll;
`;

const Legend = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 1rem;
  align-items: center;

  margin-left: 0.5em;

  color: #777;
  font-size: 16px;
`;

const LegendCircle = styled.div`
  background: ${(props) => props.color};
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const LegendPercent = styled.div`
  text-align: right;

  font-weight: 600;
  color: #444;

  margin-right: 5px;
`;

const LegendName = styled.div`
  color: #000;
`;

const LegendSubtext = styled.div`
  font-size: 12px;

  margin-bottom: 10px;
`;

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
