import PropTypes from 'prop-types';
import { numberIsBetweenZeroAndOne, numberLengthIsLessThanThree } from '../../../../utils/util';

function EditFTES(props) {
  const handleChange = (event) => {
    if (valueIsAcceptable(event.target.value)) {
      props.handleChange(event);
    }
  };

  const valueIsAcceptable = (value) => {
    // Because the max FTEs value is 1.0, we can exclude anything more than 3 characters.
    // For example, 1.33, 222, 0.555, etc would be invalid.
    // The value should also be between 0 and 1.
    return numberLengthIsLessThanThree(value) && numberIsBetweenZeroAndOne(value);
  };

  const ftesGreaterThanZero = Number(props.fteValue) >= 0.1;
  const ftesLessThanOne = Number(props.fteValue) <= 0.9;
  return (
    <div className="flex vacancy-modal-section edit-ftes">
      <h4 className="vacancy-modal-section-header mr1"># FTEs:</h4>
      <div className="add-ftes-container">
        <div
          className={`add-ftes ${ftesGreaterThanZero ? '' : 'button-disabled'}`}
          onClick={props.decrementFTES}
        >
          -
        </div>
        <div className="add-ftes middle">
          <input
            type="number"
            name="vacancyFTEs"
            value={props.fteValue}
            onChange={handleChange}
            min="0"
            max="1"
          />
        </div>
        <div
          className={`add-ftes ${ftesLessThanOne ? '' : 'button-disabled'}`}
          onClick={props.incrementFTES}
        >
          +
        </div>
      </div>
      <p className="ml1">Default: 1.0 - Can be anything less than 1.0</p>
    </div>
  );
}

EditFTES.propTypes = {
  decrementFTES: PropTypes.func.isRequired,
  fteValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  incrementFTES: PropTypes.func.isRequired,
};

export default EditFTES;
