import { BaseNimbleAPI } from './baseAPI';
import { questionTypeModelMap } from 'utils/enums';

class QuestionSetsAPI extends BaseNimbleAPI {
  // Note: data manipulation of qs items => question types done on frontend
  // A cleaner implementation (TODO) would be a nested serializer on BE that accepts items.
  get config() {
    return { baseURL: '/api/question_set' };
  }

  async create(questionSet) {
    const items = questionSet.items.map((i, index) => (i = { ...i, order: index }));
    let questionSetCopy = { ...questionSet, items: items };
    for (let key of Object.keys(questionTypeModelMap)) {
      questionSetCopy[key] = questionSetCopy.items.filter((q) =>
        questionTypeModelMap[key].includes(q.question_type)
      );
    }
    const response = await this.post('/', questionSetCopy);
    return response.data;
  }

  async retrieve(questionSetUUID) {
    const response = await this.get(`/${questionSetUUID}/`);
    return response.data;
  }

  async update(questionSet) {
    const items = questionSet.items.map((i, index) => (i = { ...i, order: index }));
    let questionSetCopy = { ...questionSet, items: items };
    for (let key of Object.keys(questionTypeModelMap)) {
      questionSetCopy[key] = questionSetCopy.items.filter((q) =>
        questionTypeModelMap[key].includes(q.question_type)
      );
    }
    const response = await this.put(`/${questionSet.uuid}/`, questionSetCopy);
    return response.data;
  }

  async destroy(questionSetUUID) {
    const response = await this.delete(`/${questionSetUUID}/`);
    return response.data;
  }

  async list() {
    const response = await this.get('/');
    return response.data;
  }
}

export default new QuestionSetsAPI();
