import { Component } from 'react';
import PropTypes from 'prop-types';

export default class QPDrowdownButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="quickview-dropdown-btn" onClick={this.props.onClick}>
        {this.props.label}
      </div>
    );
  }
}
