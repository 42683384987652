import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Title, Body, Actions } from 'ui-kit/Modal';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import { Input } from 'ui-kit';
import deleteIcon from 'assets/icon-delete.svg';
import { ATSDataFFTDataTestIds } from 'data-testids/ATS';

export default function FlatFileTransferDataMappingModal({
  isOpen,
  closeModal,
  mappingJson,
  onMappingUpdate,
  saveMapping,
}: {
  isOpen: boolean;
  closeModal: () => void;
  mappingJson: Array<any>;
  onMappingUpdate: (newMapping: any) => void;
  saveMapping: () => void;
}) {
  const [modalMapping, setModalMapping] = useState<Array<any>>([]);

  const handleMappingUpdate = (index: number, key: string, value: string) => {
    const updatedMapping = [...mappingJson];
    updatedMapping[index][key] = value;
    onMappingUpdate(updatedMapping);
  };

  const deleteMapping = (index: number) => {
    const currentMapping = [...mappingJson];
    currentMapping.splice(index, 1);
    onMappingUpdate(currentMapping);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setModalMapping(mappingJson);
  });

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Title>Data Mapping</Title>
      <Body>
        <ModalSection>
          <ColumnHeader>Initial Value</ColumnHeader>
          <ColumnHeader>Mapped Value</ColumnHeader>
        </ModalSection>
        {modalMapping.map((input, index) => (
          <ModalSection key={index}>
            <InputContainer>
              <Input
                name={`initialValue-${index}`}
                type="text"
                placeholder="Eg: Female"
                value={input['initialValue']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleMappingUpdate(index, 'initialValue', e.target.value)
                }
              />
            </InputContainer>
            <InputContainer>
              <Input
                name={`mappedValue-${index}`}
                type="text"
                placeholder="Eg: F"
                value={input['mappedValue']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleMappingUpdate(index, 'mappedValue', e.target.value)
                }
              />
            </InputContainer>
            <DeleteButtonContainer id="button-container">
              <DeleteIcon src={deleteIcon} onClick={() => deleteMapping(index)} />
            </DeleteButtonContainer>
          </ModalSection>
        ))}
        <ButtonContainer>
          <Button
            onClick={() => onMappingUpdate([...mappingJson, { initialValue: '', mappedValue: '' }])}
          >
            + Add Rule
          </Button>
        </ButtonContainer>
      </Body>
      <Actions>
        <ModalFooterNew
          cancel={closeModal}
          cancelButtonLabel="Cancel"
          errorMessage={''}
          save={() => saveMapping()}
          saveButtonLabel="Done"
          saveButtonTestId={ATSDataFFTDataTestIds.DATA_MAPPING_SAVE}
        />
      </Actions>
    </Modal>
  );
}

const ModalSection = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: '3rem';
  flex-direction: 'row';

  &:first-of-type {
    margin-right: 30px;
  }
`;

const ColumnHeader = styled.div`
  font-weight: bold;
  flex-basis: 50%;
  font-size: 14px;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;

  padding: 8px 24px;
  gap: 8px;

  width: 125px;
  height: 48px;

  border: 1px solid #bdbdbd;
  border-radius: 2px;

  color: #bdbdbd;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 15px 0 0;
  width: 100%;

  & > input {
    flex: 1;
  }
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: flex-end;
  margin-top: 10px;
`;

const DeleteIcon = styled.img`
  width: 60px;
  height: 60px;
  cursor: pointer;
`;
