import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GreenCheckIcon } from 'ui-kit/icons';
import IconUpCaret from 'assets/icon-caret-up-large.svg';
import IconDownCaret from 'assets/icon-caret-down-large.svg';
import auth from '../../../utils/auth';

export default function HRBPView({ hrbpAssignments, schoolApplications }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSchoolRole, setIsSchoolRole] = useState(false);
  const [schools, setSchools] = useState([]);

  // If the HRBPs are returned with a populated schools array, the role is a school role.
  // If no school applications exists, this means the user has not passed the hiring pool stage yet.
  // Admin displayed are those whose are in HRBP assignments where schools overlap school app schools
  useEffect(() => {
    if (hrbpAssignments?.length > 0 && hrbpAssignments[0].schools.length > 0) {
      setIsSchoolRole(true);
    } else {
      setIsSchoolRole(false);
    }
  }, [hrbpAssignments]);

  useEffect(() => {
    if (schoolApplications && schoolApplications.length > 0) {
      let schoolsPHP = schoolApplications.map((a) => a.school.id);
      setSchools(schoolsPHP);
    } else {
      setIsExpanded(false);
      setSchools([]);
    }
  }, [schoolApplications]);

  const getHRBPsSchoolRoles = (schools) => {
    let assignmentsSchoolRole = [];
    for (let assignment of hrbpAssignments) {
      let releventAssignment = assignment.schools.some((s) => schools.includes(s));
      if (releventAssignment) {
        assignmentsSchoolRole = assignmentsSchoolRole.concat(assignment.admin_users);
      }
    }
    return [...new Set(assignmentsSchoolRole)];
  };

  let hrbpAdmins = isSchoolRole
    ? getHRBPsSchoolRoles(schools)
    : hrbpAssignments?.length > 0
      ? hrbpAssignments[0].admin_users
      : [];

  return (
    <>
      {hrbpAdmins && hrbpAdmins.length > 0 && (
        <HRBPContainer>
          HR/Onboarding Partner(s):{' '}
          <span onClick={() => setIsExpanded(!isExpanded)}>
            <GreenText>
              {hrbpAdmins[0].name}{' '}
              {hrbpAdmins.length > 1 &&
                `and ${hrbpAdmins.length - 1} other${hrbpAdmins.length > 2 ? 's' : ''}`}
            </GreenText>
            {isExpanded ? (
              <GreenCaretIcon src={IconUpCaret} />
            ) : (
              <GreenCaretIcon src={IconDownCaret} />
            )}
          </span>
          {isExpanded && (
            <UserListContainer>
              {hrbpAdmins
                .sort(
                  (a, b) => Number(b.id === auth.getUser().id) - Number(a.id === auth.getUser().id)
                )
                .map((user, idx) => {
                  return (
                    <UserLineItem key={idx}>
                      <GreenCheckIcon
                        style={{ width: '12px', marginBottom: '-4px', marginRight: '12px' }}
                      />
                      {user.name} {auth.getUser().id === user.id && <UserBadge>YOU</UserBadge>}
                    </UserLineItem>
                  );
                })}
            </UserListContainer>
          )}
        </HRBPContainer>
      )}
    </>
  );
}

const UserBadge = styled.span`
  background-color: #7b5196;
  color: white;
  padding: 2px 5px;
  width: 70px;
  font-weight: bold;
  border-radius: 40px;
  font-size: 12px;
  margin-left: 5px;
  vertical-align: center;
`;

const GreenText = styled.span`
  color: var(--green);
  text-decoration: underline;
`;

const GreenCaretIcon = styled.img`
  filter: invert(68%) sepia(51%) saturate(6956%) hue-rotate(134deg) brightness(97%) contrast(101%);
  width: 9px;
  margin-leftf: 2px;
`;

const HRBPContainer = styled.div``;

const UserListContainer = styled.div`
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  background-color: #f4f5f7;
`;

const UserLineItem = styled.div`
  padding-bottom: 2px;
`;
