import { scorecardQuestionMapping } from 'utils/enums';

/** Utitily functions for scorecards */

export function updateScorecardSelection(e, component) {
  /**
   * This corresponds to the dropdown at the top of ScorecardEditor when a user is filling out
   * a new scorecard. The user is able to choose which scorecard template to use. When they
   * click a new item, search the customScorecards array in the component's state, and match by id.
   * @param {object} e: event containing information about the selection
   * @param {object} component: react component whose state to modify (usually 'this')
   */
  const customScorecard = component.state.customScorecards.find(
    (cs) => cs.id === Number(e.target.value)
  );
  // add empty answer field to each question
  customScorecard.questions.forEach((q) => {
    if (q.answer === undefined) {
      q.answer = '';
    }
  });
  if (!component.ignoreLastFetch) {
    component.setState({ customScorecard });
  }
}

export function getQuestionCounts(questions) {
  /**
   * Count the number of each question type within a scorecard or reference template.
   * @param {object[]} questions: list of questions, each having a type
   * @returns {string}
   */
  if (questions.length === 0) {
    return 'No questions selected';
  }
  const reducer = (accumulator, currentQuestion) => {
    if (accumulator[currentQuestion.question_type]) {
      accumulator[currentQuestion.question_type] += 1;
    } else {
      accumulator[currentQuestion.question_type] = 1;
    }
    return accumulator;
  };

  const questionCounts = questions.reduce(reducer, {});

  let str = '';

  for (const questionType in questionCounts) {
    str += `${questionCounts[questionType]} ${scorecardQuestionMapping[questionType].label}, `;
  }

  return str.substring(0, str.length - 2);
}

export function getScorecardCount(application) {
  let application_scorecards_count = application.scorecard_count || 0;
  if (application.district_application?.scorecard_count) {
    application_scorecards_count = application.district_application?.scorecard_count;
  }
  return application_scorecards_count;
}
