import { ButtonProps } from './types';
import { StyledSecondaryButton } from './styles';
import { CircularProgress, Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles'; // Import from the same package

export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  isLoading,
  onClick,
  size,
  startIcon,
  endIcon,
  sx,
  dataTestId,
  iconColor = 'inherit',
  iconStyle,
}: ButtonProps) => {
  return (
    <StyledSecondaryButton
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant="outlined"
      startIcon={
        !isLoading &&
        startIcon && (
          <Box
            component="span"
            sx={{
              ...iconStyles(iconColor, iconStyle),
            }}
          >
            {startIcon}
          </Box>
        )
      }
      endIcon={
        !isLoading &&
        endIcon && (
          <Box
            component="span"
            sx={{
              ...iconStyles(iconColor, iconStyle),
            }}
          >
            {endIcon}
          </Box>
        )
      }
      sx={sx}
      href={href}
      data-testid={dataTestId ? dataTestId : 'secondary-button'}
    >
      {isLoading ? <CircularProgress /> : children}
    </StyledSecondaryButton>
  );
};

export const iconStyles = (iconColor: string, iconStyle: SxProps<Theme>): SxProps<Theme> => ({
  color: iconColor,
  display: 'flex',
  alignItems: 'center',
  ...iconStyle,
});
