import { useState, useEffect, useRef } from 'react';

import { useOnKeyEvent } from 'hooks';
import { useActiveSchools } from 'hooks/data/useActiveSchools';
import { Button, Label, Modal, MultiSelect } from 'ui-kit';
import { ModalActions } from './components/ModalActions';
import { FormInputError } from './components/FormInputError';

export const AddSchoolsToSchoolGroupModal = ({
  isOpen,
  onCloseModalClick,
  onSaveModalClick,
  schoolGroup,
}) => {
  const activeSchools = useActiveSchools();
  const [formState, setFormState] = useState({
    selectedSchoolIds: schoolGroup.schools,
    error: null,
  });
  const inputRef = useRef(null);

  // Keep form data in sync if the parent changes.
  useEffect(() => {
    setFormState({ selectedSchoolIds: schoolGroup.schools });
  }, [schoolGroup]);

  const onSchoolsChange = (schoolIds) => {
    setFormState({ selectedSchoolIds: schoolIds });
  };

  const onSave = () => {
    onSaveModalClick(formState.selectedSchoolIds);
  };

  const onClose = () => {
    onCloseModalClick();
  };

  // Submit form on enter key
  useOnKeyEvent('Enter', 'keyup', onSave, isOpen);

  const schoolChoices = activeSchools.data.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Modal.Modal
      isOpen={isOpen}
      onClose={onCloseModalClick}
      focusOnOpenRef={inputRef}
      // BUG: Clicking options inside of React-Select is somehow also trigging this
      // event for our modal, and closing it. Resolution is unclear at this point, so it's disabled.
    >
      <Modal.Title>{`Add schools: ${schoolGroup.name}`}</Modal.Title>
      <Modal.Body>
        <Label htmlFor="school-group-schools">
          School Names{' '}
          <MultiSelect
            id="school-group-schools"
            name="schools"
            ref={inputRef}
            multi={true}
            options={schoolChoices}
            value={schoolChoices.filter((school) =>
              formState.selectedSchoolIds?.includes(school.value)
            )}
            onChange={(selectedOptions) => {
              selectedOptions = selectedOptions ?? [];
              onSchoolsChange(selectedOptions.map(({ value }) => value));
            }}
            placeholder="Start typing to add schools to this group"
          />
          <FormInputError>{formState.error}</FormInputError>
        </Label>
      </Modal.Body>
      <Modal.Actions>
        <ModalActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save & Close
          </Button>
        </ModalActions>
      </Modal.Actions>
    </Modal.Modal>
  );
};
