import styled from 'styled-components';

import { range } from 'utils/util';

export default function RatingQuestion({ questionText, setQuestionText, scale, setScale }) {
  return (
    <>
      <Header>Type rating name here:</Header>
      <RatingNameInput
        type="text"
        placeholder="e.g. Content knowledge"
        value={questionText}
        onChange={(e) => setQuestionText(e.target.value)}
      />
      <ScaleSelectContainer>
        <Header>Out of:</Header>
        <DropdownContainer>
          <ScaleSelect value={scale || 100} onChange={(e) => setScale(e.target.value)}>
            {range(1, 100).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </ScaleSelect>
        </DropdownContainer>
      </ScaleSelectContainer>
    </>
  );
}

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const RatingNameInput = styled.input`
  background-color: #fff;
  padding: 15px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 50px;
  width: 100%;
  margin: 0 !important;

  font-size: 16px;
  color: #777777;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;

const ScaleSelectContainer = styled.div`
  margin-top: 16px;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 150px;

  &::after {
    content: '\f078';
    font: normal normal normal 14px/1 FontAwesome;
    color: var(--lightgray);
    left: 120px;
    top: 18px;
    position: absolute;
    pointer-events: none;
  }
`;

const ScaleSelect = styled.select`
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: var(--box-shadow);
  padding: 0px 18px;
  border: none;
  background-color: white;
  border-radius: 3px;
  height: 50px;
  width: 100%;
`;
