import './footer.css';
import { useRouteMatch } from 'react-router';

const Footer: React.FC = () => {
  const connectMatch = useRouteMatch('/connect');

  return (
    <div className="footer">
      <div>
        <div className="footer-content">
          <div className="contact-support" style={{ textAlign: 'center' }}>
            Questions? We&apos;re here to help. Contact{' '}
            {connectMatch ? (
              <a href="mailto:connectsupport@hirenimble.com">connectsupport@hirenimble.com</a>
            ) : (
              <a href="mailto:support@hirenimble.com">support@hirenimble.com</a>
            )}
            .
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
