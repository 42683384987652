import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from 'assets/icons/icon_trash.svg';

function ScorecardMultipleChoiceSection({
  questionText,
  setQuestionText,
  addAnswer,
  mc_options,
  multi_select,
  setMultiSelect,
  handleMultipleChoiceTextChange,
  removeOption,
}) {
  const multipleChoiceAnswerRow = (option, index) => {
    const showRemoveButton = mc_options.length > 2;
    return (
      <MultipleChoiceAnswerDiv key={index} showRemoveButton={showRemoveButton}>
        <MultipleChoiceAnswerInput
          name="mc_options"
          type="text"
          value={option}
          placeholder={`option ${index + 1}`}
          onChange={(e) => handleMultipleChoiceTextChange(e, index)}
        />
        {showRemoveButton && <RemoveAnswerIcon onClick={() => removeOption(index)} />}
      </MultipleChoiceAnswerDiv>
    );
  };

  return (
    <>
      <Header>Question:</Header>
      <Input
        className="form-field full-width mb3 mt16px box-style"
        type="text"
        placeholder="e.g. What is your pedagogical approach?"
        value={questionText}
        onChange={(e) => setQuestionText(e.target.value)}
      />
      <HeaderContainer>
        <Header>Answer options:</Header>
        <span className="job-question-edit" onClick={addAnswer}>
          + Add another answer
        </span>
      </HeaderContainer>
      <MultipleChoiceAnswerContainer>
        {mc_options.map(multipleChoiceAnswerRow)}
      </MultipleChoiceAnswerContainer>
      <SwitchAndTextContainer>
        <SwitchContainer
          on={multi_select ? 'true' : undefined}
          onClick={() => setMultiSelect((prevValue) => !prevValue)}
        >
          <SwitchCircle on={multi_select ? 'true' : undefined} />
        </SwitchContainer>
        <p>Allow multiple selections</p>
      </SwitchAndTextContainer>
    </>
  );
}

export default ScorecardMultipleChoiceSection;

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
`;

const Input = styled.input`
  background-color: #fff;
  padding: 15px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 50px;
  width: 100%;
  margin: 0 !important;
  margin-top: 6px;

  font-size: 16px;
  color: #777777;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;

const SwitchAndTextContainer = styled.div`
  display: flex;
  align-items: center;

  & > p {
    color: #444444;
  }
`;

const SwitchContainer = styled.div`
  width: 50px;
  min-width: 50px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid;
  border-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  margin-right: 10px;
  position: relative;
  cursor: pointer;
`;

const SwitchCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  left: ${(props) => (props.on ? '23px' : '3px')};
  top: 3px;
  transition: left 300ms;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 22px;
  margin-bottom: 13px;
`;

const MultipleChoiceAnswerContainer = styled.div`
  margin-bottom: 34px;
`;

const MultipleChoiceAnswerDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 15px;
  grid-column-gap: 8px;
  align-items: center;

  margin-bottom: 16px;
`;

const MultipleChoiceAnswerInput = styled.input`
  background-color: #fff;
  padding: 20px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 50px;
  width: 100%;

  &::placeholder {
    color: #999999;
    opacity: 0.6;
  }
`;

const RemoveAnswerIcon = styled(DeleteIcon)`
  grid-column-start: 2;
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
`;
