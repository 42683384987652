import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';

import { TemplateRow } from './TemplateRow';
import { ScrollWindow } from './styles';
import { RowsProps } from './types';
import { JobRow } from './JobRow';
import { FetchedJob } from 'components/CreateJobOrTemplateModal/types';
import { RoleTemplate } from 'types/types';

export const Rows: React.FC<RowsProps> = ({
  archiveRole,
  closeRole,
  fetchItemsDebounced,
  handleOpenModalClick,
  isItemLoaded,
  itemCount,
  items,
  numberOfItems,
  openMergeRoleModal,
  openReturnSubmittedRoleModal,
  openShareModal,
  setShouldShowDeleteModal,
  setTemplateToDelete,
  smallerLayout,
  toggleFeaturedRole,
  handleSetNetworkError,
  variant,
}) => {
  const Row = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return <div style={style}>{'Loading...'}</div>;
    }

    const currentItem: RoleTemplate | FetchedJob = items[index];

    const handleOpenDeleteModal = (event) => {
      event.stopPropagation();
      setTemplateToDelete(currentItem);
      setShouldShowDeleteModal(true);
    };

    const handleOpenCreateModal = (event, template, shouldShow) => {
      event.stopPropagation();
      handleOpenModalClick(template, shouldShow);
    };

    return (
      <div style={style}>
        {variant === 'templates' && (
          <TemplateRow
            template={currentItem}
            key={currentItem.id}
            smallerLayout={smallerLayout}
            handleDelete={handleOpenDeleteModal}
            handleOpenModalClick={handleOpenCreateModal}
          />
        )}
        {variant === 'jobs' && (
          <JobRow
            job={currentItem}
            archiveRole={archiveRole}
            closeRole={closeRole}
            smallerLayout={smallerLayout}
            toggleFeaturedRole={toggleFeaturedRole}
            handleOpenModalClick={handleOpenModalClick}
            openMergeRoleModal={() => openMergeRoleModal(currentItem)}
            openReturnSubmittedRoleModal={openReturnSubmittedRoleModal}
            openShareModal={openShareModal}
            handleSetNetworkError={handleSetNetworkError}
          />
        )}
      </div>
    );
  };

  return (
    <ScrollWindow>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={fetchItemsDebounced}
        minimumBatchSize={15}
        threshold={3}
      >
        {({ onItemsRendered }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                onItemsRendered={onItemsRendered}
                height={height}
                itemCount={numberOfItems}
                itemSize={() => 50}
                width={width}
                // number of candidates to render off the bottom of the page.
                // results in smoother scrolling
                overscanCount={25}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </ScrollWindow>
  );
};
