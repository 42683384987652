import { Component } from 'react';
import PropTypes from 'prop-types';
import { handleOutsideClickOnValidForm } from '../../utils/util';
import { appStatusColors } from '../../utils/enums';

const COLORS = appStatusColors().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export default class ColorDropdown extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  outsideClickListener = (ev) => handleOutsideClickOnValidForm(this.node, ev, this.toggleOptions);

  toggleOptions = () => {
    if (!this.state.showOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    this.setState({ showOptions: !this.state.showOptions });
  };

  render() {
    return (
      <div
        className="cl-filter-dropdown-div"
        onClick={this.toggleOptions}
        ref={(node) => (this.node = node)}
      >
        <div className={`app-status-color ${COLORS[this.props.status.color]}`} />
        <div className={`cl-filter-selection-list ${this.state.showOptions ? 'show' : ''}`}>
          {appStatusColors().map((color, i) => (
            <div
              key={i}
              className="color-dropdown-list-item"
              onClick={() =>
                this.props.handleChange({ target: { name: 'color', value: color.key } })
              }
            >
              <div className={`app-status-color ${color.value}`} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
