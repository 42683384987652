import { Link, withRouter } from 'react-router-dom';
import { Component } from 'react';

import PropTypes from 'prop-types';
import auth from '../../utils/auth';
import { handleOutsideClickOnValidForm } from '../../utils/util';
import nimbleLogo from 'assets/logo.svg';
import nimbleLogoDark from 'assets/nimble-logo-dark-circle.svg';
import UserDropdown from './userDropdown';
import { Feature } from 'hoc/Feature';
import FeatureContext, { featureTags } from 'context/featureContext';
import { stateItems } from 'constants/navbar';
import { getHomeRoute } from 'utils/userType';
import { getOrSetPreferenceFlag } from 'utils/preferenceConfig';
import { ATSNavbarDataTestIds } from '../../data-testids/ATS';

class Navbar extends Component {
  static contextType = FeatureContext;
  constructor(props) {
    super(props);
    let combinedPreferenceAndProfile = false;
    this.state = {
      combinedPreferenceAndProfile,
      showDropdown: false,
      showDropdownCandidates: false,
      showDropdownData: false,
      showDropdownProspects: false,
      showDropdownStateDashboards: false,
      showDropdownJobs: false,
    };
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onProspectList: PropTypes.bool.isRequired,
    onProspectEntry: PropTypes.bool.isRequired,
  };

  async componentDidMount() {
    const combinedPreferenceAndProfile = await getOrSetPreferenceFlag();
    this.setState({ combinedPreferenceAndProfile });
  }

  logout() {
    auth.logout().then(() => window.location.assign('/login'));
  }

  outsideClickListener = (ev) =>
    handleOutsideClickOnValidForm(this.candidatesDropdownNode, ev, this.toggleDropdown);

  outsideClickListenerCandidates = (ev) =>
    handleOutsideClickOnValidForm(this.candidatesDropdownNode, ev, this.toggleDropdownCandidates);

  outsideClickListenerData = (ev) =>
    handleOutsideClickOnValidForm(this.dataDropdownNode, ev, this.toggleDropdownData);

  outsideClickListenerStateDashboards = (ev) =>
    handleOutsideClickOnValidForm(
      this.stateDashboardsDropdownNode,
      ev,
      this.toggleDropdownStateDashboards
    );

  outsideClickListenerProspects = (ev) =>
    handleOutsideClickOnValidForm(this.prospectsDropdownNode, ev, this.toggleDropdownProspects);

  outsideClickListenerJobs = (ev) =>
    handleOutsideClickOnValidForm(this.jobsDropdownNode, ev, this.toggleDropdownJobs);

  toggleDropdown = () => {
    if (!this.state.showDropdown) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    let showDropdown = !this.state.showDropdown;
    this.setState({ showDropdown });
  };

  toggleDropdownCandidates = () => {
    if (!this.state.showDropdownCandidates) {
      document.addEventListener('click', this.outsideClickListenerCandidates, {
        capture: true,
      });
    } else {
      document.removeEventListener('click', this.outsideClickListenerCandidates, { capture: true });
    }
    let showDropdownCandidates = !this.state.showDropdownCandidates;
    this.setState({ showDropdownCandidates });
  };

  toggleDropdownData = () => {
    if (!this.state.showDropdownData) {
      document.addEventListener('click', this.outsideClickListenerData, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListenerData, { capture: true });
    }
    this.setState((prevState) => ({ showDropdownData: !prevState.showDropdownData }));
  };

  toggleDropdownProspects = () => {
    if (!this.state.showDropdownProspects) {
      document.addEventListener('click', this.outsideClickListenerProspects, {
        capture: true,
      });
    } else {
      document.removeEventListener('click', this.outsideClickListenerProspects, { capture: true });
    }
    this.setState((prevState) => ({ showDropdownProspects: !prevState.showDropdownProspects }));
  };

  toggleDropdownJobs = () => {
    if (!this.state.showDropdownJobs) {
      document.addEventListener('click', this.outsideClickListenerJobs, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListenerJobs, { capture: true });
    }
    this.setState((prevState) => ({ showDropdownJobs: !prevState.showDropdownJobs }));
  };

  isTalentMarketplaceEnabled = () => {
    if (this.context.features) {
      return this.context.features.includes('has_talent_marketplace');
    }

    return false;
  };

  isJobTemplatesEnabled = () => {
    if (this.context.features) {
      return this.context.features.includes('job_templates');
    }

    return false;
  };

  toggleDropdownStateDashboards = () => {
    if (!this.state.showDropdownStateDashboards) {
      document.addEventListener('click', this.outsideClickListenerStateDashboards, {
        capture: true,
      });
    } else {
      document.removeEventListener('click', this.outsideClickListenerStateDashboards, {
        capture: true,
      });
    }
    this.setState((prevState) => ({
      showDropdownStateDashboards: !prevState.showDropdownStateDashboards,
    }));
  };

  stateDataDashboardsMenuItem = () => (
    <div
      data-testid={ATSNavbarDataTestIds.DATA_BUTTON}
      className="nav-links-dropdown candidates"
      style={{
        marginLeft: auth.inPostingOnlyDistrict() ? '75px' : 'none',
      }}
    >
      <div className="dropdown">
        <button className="dropbtn" onClick={this.toggleDropdownStateDashboards}>
          {stateItems.stateDashboardsMain} &nbsp;
          <i className="fa fa-caret-down" />
        </button>
        <div
          className={`dropdown-content ${this.state.showDropdownStateDashboards ? 'show' : ''}`}
          ref={(node) => (this.stateDashboardsDropdownNode = node)}
        >
          <div
            className="navlink-div"
            onClick={() => {
              this.toggleDropdownStateDashboards();
              this.props.history.push('/dashboards/state/applicantcharacteristics/in');
            }}
          >
            <span>{stateItems.stateDashboardsApplicant}</span>
          </div>
          <div
            className="navlink-div"
            onClick={() => {
              this.toggleDropdownStateDashboards();
              this.props.history.push('/dashboards/state/vacancycharacteristics/in');
            }}
          >
            <span>{stateItems.stateDashboardsVacancy}</span>
          </div>
          <div
            className="navlink-div"
            onClick={() => {
              this.toggleDropdownStateDashboards();
              this.props.history.push('/dashboards/state/supplyanddemand/in');
            }}
          >
            <span>{stateItems.stateDashboardsSupply}</span>
          </div>
        </div>
      </div>
    </div>
  );

  jobsMenuItem = () => (
    <div className="nav-links-dropdown candidates">
      <div className="dropdown">
        <button
          className="dropbtn"
          data-testid={ATSNavbarDataTestIds.JOBS_DROPDOWN}
          onClick={this.toggleDropdownJobs}
        >
          Jobs &nbsp;
          <i className="fa fa-caret-down" />
        </button>
        <div
          className={`dropdown-content ${this.state.showDropdownJobs ? 'show' : ''}`}
          ref={(node) => (this.jobsDropdownNode = node)}
        >
          <div
            className="navlink-div"
            data-testid={ATSNavbarDataTestIds.JOBS_LINK}
            onClick={() => {
              this.toggleDropdownJobs();
              this.props.history.push('/district/jobslist');
            }}
          >
            <span>View Jobs</span>
          </div>
          <div
            className="navlink-div"
            data-testid={ATSNavbarDataTestIds.TEMPLATES_LINK}
            onClick={() => {
              this.toggleDropdownJobs();
              this.props.history.push('/district/templates');
            }}
          >
            <span>View Templates</span>
          </div>
        </div>
      </div>
    </div>
  );

  districtUserMenuItems = () => (
    <div
      className={`nav-links ${auth.isDistrictUserOnly() ? 'district-user' : 'district'}`}
      style={
        auth.isStateLevelAdminUser() && {
          marginLeft: '75px',
          width: '525px',
        }
      }
    >
      <Link to="/district/home" role="link">
        Home
      </Link>

      <div className="nav-links-dropdown candidates">
        <div className="dropdown">
          <button
            className="dropbtn"
            onClick={this.toggleDropdownCandidates}
            data-testid={ATSNavbarDataTestIds.CANDIDATES_BUTTON}
          >
            Candidates &nbsp;
            <i className="fa fa-caret-down" />
          </button>
          <div
            className={`dropdown-content ${this.state.showDropdownCandidates ? 'show' : ''}`}
            ref={(node) => (this.candidatesDropdownNode = node)}
          >
            <div
              className="navlink-div"
              data-testid={ATSNavbarDataTestIds.CANDIDATES_BUTTON_VIEW_ALL}
              onClick={() => {
                this.toggleDropdownCandidates();
                window.location.href.indexOf('/candidates') !== -1
                  ? window.location.reload()
                  : this.props.history.push('/district/candidates');
              }}
            >
              <span>View all</span>
            </div>
            <div
              className="navlink-div"
              data-testid={ATSNavbarDataTestIds.CANDIDATES_BUTTON_BY_POSTING}
              onClick={() => {
                this.toggleDropdownCandidates();
                this.props.history.push('/district/requisition-roles');
              }}
            >
              <span>By posting</span>
            </div>
          </div>
        </div>
      </div>
      {auth.isDistrictUserOnly() ? (
        <Link to="/data/vacancy" data-testid={ATSNavbarDataTestIds.DATA_BUTTON}>
          Data
        </Link>
      ) : (
        <div className="nav-links-dropdown candidates">
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={this.toggleDropdownData}
              data-testid={ATSNavbarDataTestIds.DATA_BUTTON}
            >
              Data &nbsp;
              <i className="fa fa-caret-down" />
            </button>
            <div
              className={`dropdown-content ${this.state.showDropdownData ? 'show' : ''}`}
              ref={(node) => (this.dataDropdownNode = node)}
            >
              <div
                className="navlink-div"
                onClick={() => {
                  this.toggleDropdownData();
                  this.props.history.push('/data/vacancy');
                }}
              >
                <span>Vacancy metrics</span>
              </div>
              <div
                className="navlink-div"
                onClick={() => {
                  this.toggleDropdownData();
                  this.props.history.push('/data/pipeline');
                }}
              >
                <span>Pipeline metrics</span>
              </div>
              <Feature featureName={featureTags.FLAT_FILE_TRANSFERS}>
                <div
                  className="navlink-div"
                  onClick={() => {
                    this.toggleDropdownData();
                    this.props.history.push('/data/datatransfer');
                  }}
                >
                  <span>Data Transfer</span>
                </div>
              </Feature>
            </div>
          </div>
        </div>
      )}
      {auth.isStateLevelAdminUser() && this.stateDataDashboardsMenuItem()}

      {auth.isDistrictAdmin() && this.isJobTemplatesEnabled() ? (
        this.jobsMenuItem()
      ) : (
        <Link to="/district/jobslist" data-testid={ATSNavbarDataTestIds.JOBS_LINK} role="link">
          Jobs
        </Link>
      )}

      {auth.isDistrictAdmin() &&
        auth.hasProspectsModule() &&
        !this.isTalentMarketplaceEnabled() && (
          <Link to="/prospectlist" role="link" data-testid={ATSNavbarDataTestIds.PROSPECTS_BUTTON}>
            Prospects
          </Link>
        )}
      {auth.isDistrictAdmin() && this.isTalentMarketplaceEnabled() && (
        <div className="nav-links-dropdown candidates">
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={this.toggleDropdownProspects}
              data-testid={ATSNavbarDataTestIds.PROSPECTS_BUTTON}
            >
              Prospects &nbsp;
              <i className="fa fa-caret-down" />
            </button>
            <div
              className={`dropdown-content ${this.state.showDropdownProspects ? 'show' : ''}`}
              ref={(node) => (this.prospectsDropdownNode = node)}
            >
              {(auth.hasProspectsModule() || this.isTalentMarketplaceEnabled()) && (
                <div
                  className="navlink-div"
                  onClick={() => {
                    this.toggleDropdownProspects();
                    this.props.history.push('/prospectlist');
                  }}
                >
                  <span>My Prospects</span>
                </div>
              )}

              {this.isTalentMarketplaceEnabled() && (
                <div
                  className="navlink-div"
                  onClick={() => {
                    this.toggleDropdownProspects();
                    this.props.history.push('/talent-marketplace');
                  }}
                >
                  <span>Talent Marketplace</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Link to="/district/vacancies" className="hidden">
        Vacancies
      </Link>
    </div>
  );

  schoolUserMenuItems = () => (
    <div className="nav-links district">
      <Link to="/school/home">Home</Link>

      <div className="nav-links-dropdown candidates">
        <div className="dropdown">
          <button className="dropbtn" onClick={this.toggleDropdownCandidates}>
            Candidates &nbsp;
            <i className="fa fa-caret-down" />
          </button>
          <div
            className={`dropdown-content ${this.state.showDropdownCandidates ? 'show' : ''}`}
            ref={(node) => (this.candidatesDropdownNode = node)}
          >
            <div
              className="navlink-div"
              onClick={() => {
                this.toggleDropdownCandidates();
                window.location.href.indexOf('/candidates') !== -1
                  ? window.location.reload()
                  : this.props.history.push('/school/candidates');
              }}
            >
              <span>View all</span>
            </div>
            <div
              className="navlink-div"
              onClick={() => {
                this.toggleDropdownCandidates();
                this.props.history.push('/school/requisition-roles');
              }}
            >
              <span>By posting</span>
            </div>
          </div>
        </div>
      </div>
      <Link to="/data/vacancy" data-testid={ATSNavbarDataTestIds.DATA_BUTTON}>
        Data
      </Link>
      <Link to="/school/jobslist">Jobs</Link>
    </div>
  );

  showChecklist = () =>
    // checklist tab in the dropdown is only displayed if the checklist exists,
    // the admin is not in a posting-only district, and at least one of the
    // following: the admin is a legacy user OR the admin has completed the
    // checklist.
    auth.getUser().checklist !== null &&
    auth.getUser().checklist !== undefined &&
    !auth.inPostingOnlyDistrict() &&
    (auth.getUser().is_legacy_user || auth.getUser().checklist.completed);

  getNavigation = () => {
    if (this.props.onProspectEntry) {
      return (
        <div className="nav-container prospectentry">
          <div onClick={this.logout}>
            <span className="pointer mr2">Log out</span>
          </div>
        </div>
      );
    } else if (auth.isDistrictUser()) {
      return (
        <div className="nav-container">
          {!auth.inPostingOnlyDistrict() && this.districtUserMenuItems()}
          {auth.isStateLevelAdminUser() &&
            auth.inPostingOnlyDistrict() &&
            auth.isIndianaDistrict() &&
            this.stateDataDashboardsMenuItem()}
          <div className="nav-links-dropdown district">
            {/* Show tab if user is not legacy_user and they haven't completed checklist */}
            {!auth.getUser().is_legacy_user &&
              auth.isValidChecklistUser() &&
              auth.getUser().checklist !== null &&
              !auth.getUser().checklist.completed && (
                <Link
                  data-testid="navtab-getting-started"
                  to="/getting-started"
                  className="getting-started"
                  activeClassName="active"
                >
                  Getting Started
                </Link>
              )}
            <UserDropdown
              {...{
                logout: this.logout,
                getUser: auth.getUser,
                releaseHijack: () => auth.handleReleaseHijack(),
                showChecklist: this.showChecklist,
                inPostingOnlyDistrict: auth.inPostingOnlyDistrict(),
                isDistrictAdmin: auth.isDistrictAdmin(),
                isDistrictUser: auth.isDistrictUser(),
                isSchoolUser: false,
                showDropdown: this.state.showDropdown,
                jobBoardStyling: false,
                combinedPreferenceAndProfile: this.state.combinedPreferenceAndProfile,
              }}
            />
          </div>
        </div>
      );
    } else if (auth.isSchoolUser()) {
      return (
        <div className="nav-container">
          {!auth.inPostingOnlyDistrict() && this.schoolUserMenuItems()}
          <div className="nav-links-dropdown school">
            {/* Show tab if user is not legacy_user and they haven't completed checklist */}
            {!auth.getUser().is_legacy_user &&
              auth.isValidChecklistUser() &&
              auth.getUser().checklist !== null &&
              !auth.getUser().checklist.completed && (
                <Link
                  data-testid="navtab-getting-started"
                  to="/getting-started"
                  className="getting-started"
                  activeClassName="active"
                >
                  Getting Started
                </Link>
              )}
            <UserDropdown
              {...{
                logout: this.logout,
                getUser: auth.getUser,
                releaseHijack: () => auth.handleReleaseHijack(),
                showChecklist: this.showChecklist,
                inPostingOnlyDistrict: auth.inPostingOnlyDistrict(),
                isDistrictAdmin: auth.isDistrictAdmin(),
                isDistrictUser: auth.isDistrictUser(),
                isSchoolUser: true,
                showDropdown: this.state.showDropdown,
                jobBoardStyling: false,
                combinedPreferenceAndProfile: this.state.combinedPreferenceAndProfile,
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="nav-links-dropdown nav-links candidate">
          <UserDropdown
            {...{
              logout: this.logout,
              getUser: auth.getUser,
              releaseHijack: () => auth.handleReleaseHijack(),
              showChecklist: this.showChecklist,
              inPostingOnlyDistrict: auth.inPostingOnlyDistrict(),
              isDistrictAdmin: auth.isDistrictAdmin(),
              isDistrictUser: auth.isDistrictUser(),
              isSchoolUser: false,
              showDropdown: this.state.showDropdown,
              jobBoardStyling: false,
              combinedPreferenceAndProfile: this.state.combinedPreferenceAndProfile,
            }}
          />
        </div>
      );
    }
  };

  getLogo = () => {
    const { userType, hasApplications } = this.props;
    const homeRouteToGoTo = getHomeRoute({ userType, hasApplications });

    return (
      <div className="nav-logo flex-center">
        <Link to={homeRouteToGoTo}>
          <img src={auth.isCandidateUser() ? nimbleLogoDark : nimbleLogo} alt="Nimble logo" />
        </Link>
      </div>
    );
  };

  render() {
    if (this.props.show) {
      return (
        <div>
          <div className="navbar">
            {!this.props.onProspectEntry && this.getLogo()}
            {this.getNavigation()}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default withRouter(Navbar);
