import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { preferenceMapping } from 'utils/util';
import { nimbleTheme } from 'theme';

export default class QuickProfilePreferenceSection extends Component {
  static propTypes = {
    toggleCardsSection: PropTypes.func.isRequired,
    expanded: PropTypes.object.isRequired,
    preference: PropTypes.object.isRequired,
    schoolPreferencesAnswer: PropTypes.object,
    isTalentMarketplace: PropTypes.boolean,
  };

  render() {
    let preferenceObj = preferenceMapping(this.props.preference);

    let preferredSchoolsSummary = '';
    if (this.props.schoolPreferencesAnswer) {
      if (this.props.schoolPreferencesAnswer.is_interested_in_all) {
        preferredSchoolsSummary = 'open to any location';
      } else {
        const selectedSchoolNames = [...this.props.schoolPreferencesAnswer.selected_schools];
        if (this.props.schoolPreferencesAnswer.is_open_to_other_options) {
          selectedSchoolNames.push('open to other options');
        }
        preferredSchoolsSummary = selectedSchoolNames.join('; ');
      }
    }

    return (
      <div className="cards-section">
        <div
          className="cards-expander-title preferences"
          onClick={() => this.props.toggleCardsSection('preference')}
          style={{ marginBottom: '16px' }}
        >
          <span className="plus-sign">+</span>{' '}
          <CandidatePreferencesTitle className="darker-text">
            {this.props.isTalentMarketplace ? 'Preferences' : 'Candidate Preferences'}
          </CandidatePreferencesTitle>
        </div>
        <Section
          className={
            this.props.expanded.preference
              ? 'application-confirmation-cards-expander preferences expanded'
              : 'application-confirmation-cards-expander preferences'
          }
        >
          <div className="application-sectionapplication-confirmation-questions-section">
            <div>
              {this.props.schoolPreferencesAnswer && (
                <div className="mb1">
                  <span>
                    <strong>{'School preference question: '}</strong>
                    {`${preferredSchoolsSummary ?? 'No response'}.`}
                  </span>
                </div>
              )}
              {Object.keys(this.props.preference).map(
                (item, index) =>
                  preferenceObj[item] && (
                    <ListItem className="mb1" key={index}>
                      <span>
                        <strong>{preferenceObj[item].label + ': '}</strong>
                        {/* There's weirdness with truthy/falsy values here. For example, grades uses an empty array
                        when no preference was selected, and empty arrays are truthy in Javascript. The miles_within
                        field returns "Within  miles of " when empty, so that's also truthy. Use both props.preference
                        and preferenceObj to determine whether to show response. */}
                        {this.props.preference[item] && preferenceObj[item].value
                          ? preferenceObj[item].value
                          : 'No response.'}
                      </span>
                    </ListItem>
                  )
              )}
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

const Section = styled.div`
  @media print {
    max-height: max-content;
    overflow: auto;
  }
`;

const ListItem = styled.li`
  display: block;
  @media print {
    display: list-item;
  }
`;

const CandidatePreferencesTitle = styled.span`
  @media print {
    font-weight: ${nimbleTheme.typography.fontWeightBold};
  }
`;
