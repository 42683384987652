import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * UI component for what is essentially a couple of styled radio buttons. The user
 * can select one or the other and the style will reflect which one is currently
 * selected. Selecting the first button will emit a value of 0 and the second one
 * a value of 1.
 */
export default function TwoButtonSelect({
  firstButtonLabel,
  firstButtonActive,
  secondButtonLabel,
  secondButtonActive,
  handleChange,
}) {
  const selectFirstButton = () => {
    handleChange(0);
  };
  const selectSecondButton = () => {
    handleChange(1);
  };
  return (
    <div>
      <Button onClick={selectFirstButton} isActive={firstButtonActive}>
        {firstButtonLabel}
      </Button>
      <Button onClick={selectSecondButton} isActive={secondButtonActive}>
        {secondButtonLabel}
      </Button>
    </div>
  );
}

TwoButtonSelect.propTypes = {
  firstButtonLabel: PropTypes.string.isRequired,
  firstButtonActive: PropTypes.bool.isRequired,
  secondButtonLabel: PropTypes.string.isRequired,
  secondButtonActive: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const determineColor = ({ isActive }) => `
  background-color: ${isActive ? '#00b88d' : '#FFFFFF'} !important;
  color: ${isActive ? '#FFFFFF' : '#A6A7AD'} !important;
`;

const Button = styled.button`
  ${determineColor}

  font-weight: 400;
  font-size: 15px;

  width: 139px !important;
  height: 50px !important;

  border: none;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0954484);
`;
