import auth, {
  USER_TYPE,
  DISTRICT_ADMIN_USER_TYPES,
  DISTRICT_USER_TYPES,
  CANDIDATE_USER_TYPES,
  SUPER_ADMIN_USER_TYPES,
  CHECKLIST_USER_TYPES,
  SCHOOL_ADMIN_USER_TYPES,
} from './auth';
import { User } from '../containers/CurrentUserPermissionsProvider';

/**
 * Middleware to redirect users that are not logged in
 */
export const requiresLogIn = (wrappedFunction) => {
  return (nextState, replace) => {
    // User is not logged in
    if (!auth.loggedIn()) {
      return replace({
        pathname: '/login',
        query: {
          return_to:
            nextState.location.pathname + nextState.location.hash + nextState.location.search,
        },
      });
    }

    // Delegate
    if (wrappedFunction) {
      return wrappedFunction(nextState, replace);
    }
  };
};

/**
 * Middleware to redirect users that do not use our ATS
 * (district.posting_only=True)
 */
export const requiresATS = (wrappedFunction) => {
  return (nextState, replace) => {
    if (auth.inPostingOnlyDistrict()) {
      // new spec: redirect users to jobslist page instead of /district/home
      return replace({ ...nextState.location, pathname: '/district/jobslist' });
    }

    // Delegate
    if (wrappedFunction) {
      return wrappedFunction(nextState, replace);
    }
  };
};

/**
 * Middleware to redirect users based on role
 * /district -> /school or /school -> /district
 */
export const redirectScopedUrls = (wrappedFunction) => {
  return (nextState, replace) => {
    const currentPath = nextState.location.pathname;

    // Redirect school users from district-scoped urls
    if (currentPath.startsWith('/district') && auth.isSchoolUser()) {
      return replace({
        ...nextState.location,
        pathname: currentPath.replace('/district', '/school'),
      });
    }

    // Redirect district users from school-scoped urls
    if (currentPath.startsWith('/school') && auth.isDistrictUser()) {
      return replace({
        ...nextState.location,
        pathname: currentPath.replace('/school', '/district'),
      });
    }

    // Delegate
    if (wrappedFunction) {
      return wrappedFunction(nextState, replace);
    }
  };
};

/**
 * Redirects users home if they're not the required userType
 * @param  {...any} userTypes
 */
export const requiresUserTypes = (userTypes) => {
  return (nextState, replace) => {
    const user = auth.getUser();

    let pathname;
    if (!userTypes.includes(user.profile.user_type)) {
      switch (user.profile.user_type) {
        case USER_TYPE.candidate:
          if (user.has_applications) {
            pathname = '/candidate-dashboard';
          } else {
            pathname = '/preferences';
          }
          break;
        case USER_TYPE.school_user:
        case USER_TYPE.school_admin:
          pathname = '/school/home';
          break;
        case USER_TYPE.district_user:
        case USER_TYPE.district_admin:
          pathname = '/district/home';
          break;
        case USER_TYPE.super_admin:
          pathname = '/district/home';
          break;
        default:
          pathname = '/login';
          break;
      }
      return replace({ pathname });
    }
  };
};

/**
 * Middleware that redirects users who don't have access to the
 * prospects module.
 *
 * If a user does have access, it will proceed to check their userType.
 */
export const requireProspectsAuth = () => {
  return (nextState, replace) => {
    if (!auth.hasProspectsModule()) {
      replace({ ...nextState.location, pathname: '/not-found' });
    } else {
      return redirectScopedUrls(requiresUserTypes(DISTRICT_ADMIN_USER_TYPES));
    }
  };
};

export const requireSuperAdminAuth = requiresLogIn(
  redirectScopedUrls(requiresUserTypes(SUPER_ADMIN_USER_TYPES))
);

export const requireDistrictAdminAuth = requiresLogIn(
  redirectScopedUrls(requiresUserTypes(DISTRICT_ADMIN_USER_TYPES))
);

export const requireDistrictAuth = requiresLogIn(
  redirectScopedUrls(requiresUserTypes(DISTRICT_USER_TYPES))
);

export const requireCandidateAuth = requiresLogIn(
  redirectScopedUrls(requiresUserTypes(CANDIDATE_USER_TYPES))
);

export const requireSchoolAuth = requiresLogIn(
  redirectScopedUrls(requiresUserTypes(SCHOOL_ADMIN_USER_TYPES))
);

export const requireSchoolOrDistrictAuth = requiresLogIn(
  redirectScopedUrls(requiresUserTypes([...DISTRICT_USER_TYPES, ...SCHOOL_ADMIN_USER_TYPES]))
);

export const requireChecklistAuth = requiresLogIn(requiresUserTypes(CHECKLIST_USER_TYPES));

export const pushQueryLocation = (
  newQuery,
  inputSearch,
  history,
  pathname,
  previousSearch,
  performSearch
) => {
  const previousSearchParams = new URLSearchParams(previousSearch);

  Object.entries(newQuery).forEach(({ key, value }) => {
    previousSearchParams.set(key, value);
  });

  const search = previousSearchParams.toString();

  history.push({ pathname, search });

  if (inputSearch) {
    performSearch();
  }
};
