import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line } from 'recharts';
import { HIRING_SEASONS } from 'utils/enums';
import { CHART_COLOR_PALETTES } from '../../PipelineMetricsDashboard/constants';

let colors = CHART_COLOR_PALETTES[8];

export const LineChartComponent = ({ dataset }) => {
  let hiringSeasons = Object.entries(HIRING_SEASONS);
  return (
    <ResponsiveContainer width="99%" height="100%">
      <LineChart
        data={dataset.map((item, index) => (item['month_abbr'] = index))}
        margin={{ right: 25, top: 10 }}
      >
        <CartesianGrid vertical={false} strokeWidth="0.5" />
        <XAxis dataKey="month_abbr" interval={0} tickLine={false} />
        <YAxis tickLine={false} />
        {hiringSeasons.map((season, i) => {
          return (
            <Line
              key={season}
              dataKey={HIRING_SEASONS[season]}
              name={HIRING_SEASONS[season]}
              stroke={colors[i % hiringSeasons.length]}
              strokeWidth={2}
              dot={false}
              type="monotone"
              activeDot={{ strokeWidth: 3, r: 6 }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
