import styled from 'styled-components';

export const TitleContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '28px',
  paddingTop: '20px',
});

export const HeaderText = styled.p({
  alignItems: 'center',
  display: 'flex',
  fontSize: '18px',
  fontWeight: '600',
});
