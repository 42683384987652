import { Input } from '../ui-kit/Input';
import { isValidEmail } from './emailutils';

/**
 * Example signup form obj
 *
 * Object representation of signup form
 *
 * FORMAT: Input Label, Input name, Input type
 */

// const signupForm = {
//   fname: {
//     ...createFormFieldConfig('First name', 'fname', 'text'),
//     validationRules: [requiredRule('name'), minLengthRule('name', 3)]
//   },
//   lname: {
//     ...createFormFieldConfig('Last name', 'lname', 'text'),
//     validationRules: [requiredRule('name'), minLengthRule('name', 3)]
//   },
//   email: {
//     ...createFormFieldConfig('Email', 'email', 'email'),
//     validationRules: [requiredRule('email'), minLengthRule('email', 10)]
//   },
//   emailConfirm: {
//     ...createFormFieldConfig('Confirm email address', 'confirmEmail', 'email'),
//     validationRules: [emailMatchRule()]
//   },
//   password: {
//     ...createFormFieldConfig('Password', 'password', 'password'),
//     validationRules: [requiredRule('password'), minLengthRule('password', 6)]
//   },
//   passwordConfirm: {
//     ...createFormFieldConfig('Confirm Password', 'confirmPassword', 'password'),
//     validationRules: [passwordMatchRule()]
//   }
// };

/**
 * Creates and returns object representation of form field
 *
 * @param {string} label - label to show with the form input
 * @param {string} name - input name
 * @param {string} type - input type
 * @param {string} defaultValue - default value for the input
 */
export function createFormFieldConfig(label, name, type, defaultValue = '') {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Input
          key={key}
          name={name}
          type={type}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: '',
    touched: false,
  };
}

/**
 *  Create and return a validation rule object that
 * 	is used by useForm() hook to validate a form's inputs.
 *
 * @param {string} ruleName - name of validation rule
 * @param {string} errorMessage - message to display
 * @param {function} validateFunc - validation function that returns a boolean value
 */
export function createValidationRule(ruleName, errorMessage, validateFunc) {
  return {
    name: ruleName,
    message: errorMessage,
    validate: validateFunc,
  };
}

/***  VALIDATION RULES  ***/

export function requiredRule(inputName) {
  return createValidationRule(
    'required',
    `${inputName} is a required field`,
    (inputValue, formObj) => inputValue.length !== 0
  );
}

export function noEmptySpaceRule(inputName) {
  return createValidationRule(
    'emptySpaceRule',
    `${inputName} must not contain spaces`,
    (inputValue, formObj) => !inputValue.includes(' ')
  );
}

export function uppercaseOrSpecialCharacterRule(inputName) {
  return createValidationRule(
    'uppercaseOrSpecialCharacter',
    `${inputName} must contain at least 1 uppercase letter or 1 special character`,
    (inputValue, formObj) => {
      const hasUppercase = /[A-Z]/.test(inputValue);
      // source: https://owasp.org/www-community/password-special-characters except for blank spaces
      const hasSpecialCharacter = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(inputValue);
      return hasUppercase || hasSpecialCharacter;
    }
  );
}

export function minLengthRule(inputName, minCharacters) {
  return createValidationRule(
    'minLength',
    `${inputName} must be ${minCharacters} or more characters`,
    (inputValue, formObj) => inputValue.length >= minCharacters
  );
}

export function maxLengthRule(inputName, maxCharacters) {
  return createValidationRule(
    'minLength',
    `${inputName} cannot contain more than ${maxCharacters} characters`,
    (inputValue, formObj) => inputValue.length <= maxCharacters
  );
}

export function passwordMatchRule() {
  return createValidationRule(
    'passwordMatch',
    `Passwords do not match`,
    (inputValue, formObj) => inputValue === formObj.password.value
  );
}

export function emailMatchRule() {
  return createValidationRule(
    'emailMatch',
    `Email addresses do not match`,
    (inputValue, formObj) => inputValue === formObj.email.value
  );
}

export function isValidEmailRule() {
  return createValidationRule(
    'isValidEmail',
    'Please provide a valid email address',
    (inputValue, formObj) => isValidEmail(inputValue)
  );
}
