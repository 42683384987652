import { BaseNimbleAPI } from './baseAPI';

class SchoolGroupsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/school-groups' };
  }

  /**
   * Fetch a district's school groups
   */
  async getSchoolGroups() {
    const response = await this.get(`/`);
    return response.data;
  }

  /**
   * Create a school group
   */
  async createSchoolGroup(data) {
    const response = await this.post(`/`, data);
    return response.data;
  }

  /**
   * Create a school group
   */
  async updateSchoolGroup(id, data) {
    const response = await this.patch(`/${id}/`, data);
    return response.data;
  }

  /**
   * Delete a school group
   */
  async deleteSchoolGroup(id) {
    return await this.delete(`/${id}/`);
  }
}

export default new SchoolGroupsAPI();
