import styled from 'styled-components';
import { RubricAnswerProps } from './types';

const RubricAnswer: React.FC<RubricAnswerProps> = ({ answer, updateRubricSelection, index }) => {
  return (
    <RubricGridContainer numberOfColumns={answer.rubric_columns.length}>
      <TextCenterDiv>
        <HeaderText>
          {answer.question_text}
          {answer.is_required && <sup>*</sup>}
        </HeaderText>
        <Subtext>(click to select)</Subtext>
      </TextCenterDiv>
      {answer.rubric_columns.map((item) => (
        <RubricItemContainer key={item.number}>
          <div>
            <HeaderText>{item.number}</HeaderText>
            <HeaderText>{item.label}</HeaderText>
          </div>
          <DescriptionBox
            selected={answer.answer_rubric === item.number}
            onClick={() => updateRubricSelection(item.number, index)}
            value={item.description}
            readOnly
          />
        </RubricItemContainer>
      ))}
    </RubricGridContainer>
  );
};

export default RubricAnswer;

const TextCenterDiv = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Subtext = styled.i`
  font-size: 14px;
  color: #777777;
  line-height: 19px;
`;

const RubricItemContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderText = styled.p`
  font-size: 14px;
  color: #444444;
  font-weight: 800;
  line-height: 19px;
`;

export interface DescriptionBoxProps {
  selected?: boolean;
}

const DescriptionBox = styled.textarea<DescriptionBoxProps>`
  background-color: #ffffff;
  border: 2px solid ${(props) => (props.selected ? '#00B88D' : 'transparent')};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0778136);
  border-radius: 3px;
  padding: 11px 15px;
  color: #8e8d8d;
  font-size: 11px;
  line-height: 15px;
  margin-top: 11px;
  cursor: pointer;
  height: 118px;
  overflow-y: auto;
  text-align: initial;
  resize: none;

  &:hover {
    border: 2px solid #00b88d;
  }
`;

export interface RubricGridContainerProps {
  numberOfColumns?: number;
}

const RubricGridContainer = styled.div<RubricGridContainerProps>`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 991px) {
    grid-column-gap: 11px;
    grid-template-columns: 100px ${(props) => `repeat(${props.numberOfColumns}, minmax(0, 1fr))`};
  }
`;
