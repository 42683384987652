import useSWR from 'swr';

import categoriesApi from 'api/categoriesAPI';

const KEY = '/api/categories';

/**
 * Data management wrapper for fetching a district's Categories.
 *
 * Usage: const { data, isLoading, error } = useCategories({ districtId });
 */
export const useCategories = ({ districtId }) => {
  const { data, error } = useSWR([KEY, districtId], (key, districtId) =>
    categoriesApi.fetchCategories({ districtId })
  );

  const isLoading = data === undefined;

  return { data: data ?? [], isLoading, error };
};
