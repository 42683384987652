import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { AltModal } from 'ui-kit';
import CandidateHellosignModalBody from './CandidateHellosignModalBody';
import ConfirmationPage from './ConfirmationPage';
import HelloSignIFrameBodyAndFooter from 'features/HellosignEmbeddedSigning/HelloSignIFrameBodyAndFooter';

import { makeHSClient, openHSiFrame } from 'utils/helloSignUtil';

const client = makeHSClient();

export default function TaskModal({ show, onHide, task, setHellosignTaskStatus }) {
  const [isLoading, setIsLoading] = useState(false);
  const [iFrameOpen, setIFrameOpen] = useState(false);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);

  const backToPrevious = () => {
    setIFrameOpen(false);
  };

  const continueToSign = () => {
    setIsLoading(true);
    let retryCount = 0;

    axios
      .get('/api/hellosign/signature_url/', {
        params: { signature_id: task.signature_id },
      })
      .then(({ data }) => {
        setIFrameOpen(true);
        setIsLoading(false);
        openHSiFrame(client, data.sign_url, null, onSuccess);
      })
      .catch(() => {
        // Retry the request with exponential backoff
        retryCount++;
        if (retryCount <= 3) setTimeout(1000 * retryCount, continueToSign);
      });
  };

  const onSuccess = () => {
    setHellosignTaskStatus(task.signature_id, 'signed');
    setShowConfirmationPage(true);
    setIFrameOpen(false);
  };

  const closeIFrameAndModal = () => {
    client.close();
    onHide();
  };

  return (
    <AltModal.AltModal isOpen={show} onClose={closeIFrameAndModal} large={iFrameOpen}>
      <AltModal.Title>Task: Sign {task.title}</AltModal.Title>
      {iFrameOpen ? (
        <HelloSignIFrameBodyAndFooter onBack={backToPrevious} onHide={closeIFrameAndModal} />
      ) : (
        <>
          {showConfirmationPage ? (
            <ConfirmationPage onHide={closeIFrameAndModal} />
          ) : (
            <CandidateHellosignModalBody
              isLoading={isLoading}
              task={task}
              continueToSign={continueToSign}
              onHide={closeIFrameAndModal}
            />
          )}
        </>
      )}
    </AltModal.AltModal>
  );
}

TaskModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  setHellosignTaskStatus: PropTypes.func.isRequired,
};
