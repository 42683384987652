import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal, DropdownWithInputFilter } from 'ui-kit';
import ErrorText from 'components/errortext';

import { useReferenceTemplates } from 'hooks/data/useReferenceTemplates';

export default function CopyReferenceModal({ isOpen, onClose, copyReference }) {
  const referenceTemplates = useReferenceTemplates();
  const [templateId, setTemplateId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = () => {
    if (!templateId) {
      setErrorMessage('Please select a reference template');
      return;
    }
    const reference = referenceTemplates.data.find((reference) => reference.id === templateId);
    copyReference(reference);
  };

  return (
    <Modal.Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Copy Reference Form</Modal.Title>
      <Modal.Body>
        <DropdownWithInputFilter
          options={referenceTemplates.data.map((s) => ({ value: s.id, label: s.title }))}
          value={templateId || ''}
          handleChange={(value) => {
            setTemplateId(value);
            setErrorMessage('');
          }}
          placeholder="Start typing to select reference form"
          onClear={() => setTemplateId(null)}
        />
      </Modal.Body>
      <Modal.Actions>
        <FooterButtonsGridContainer>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <FlexDiv>
            <ErrorText message={errorMessage} />
            <SubmitButton onClick={onSubmit} disabled={errorMessage !== ''}>
              Use this form
            </SubmitButton>
          </FlexDiv>
        </FooterButtonsGridContainer>
      </Modal.Actions>
    </Modal.Modal>
  );
}

CopyReferenceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const FlexDiv = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const FooterButton = styled.button`
  width: 100%;
  height: 50px;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  @media screen and (min-width: 768px) {
    width: 151px;
  }
`;

const CancelButton = styled(FooterButton)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: 0;

  background: transparent;
  color: #c2c2c2;

  margin-top: 8px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 50px;

  background: #00b88d;
  border-radius: 2px;
  border: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;

  @media screen and (min-width: 768px) {
    width: 190px;
    margin-left: 8px;
  }
`;

const FooterButtonsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;

  padding-top: 0;
  padding-bottom: 12px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr minmax(190px, auto);
    grid-column-gap: 16px;

    padding-top: 20px;
    padding-bottom: 0;
  }
`;
