import { useState } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-system';

import PropTypes from 'prop-types';
import TaskModal from 'components/CandidateDashboardApplicationsList/Hellosign/TaskModal';
import moment from 'moment';

import CompleteIcon from 'assets/icons/TaskIcons/green-circle-checkmark.svg';
import IncompleteIcon from 'assets/icon-check-with-circle.svg';

export default function CandidateDashboardHellosignTaskRow({ task, setHellosignTaskStatus }) {
  const [hellosignTaskModalOpen, setHellosignTaskModalOpen] = useState(false);

  const isComplete = task.status_code === 'signed';
  const isOverdue = task.due_date && moment(Date.now()).isAfter(task.due_date);

  return (
    <TaskRow
      gridTemplateColumns={[
        '19px 1fr 90px',
        '19px 1fr 90px',
        '19px 1fr minmax(90px, 0.5fr) repeat(4, 1fr)',
      ]}
    >
      <Icon
        style={{
          height: isComplete ? '18px' : '16px',
          width: isComplete ? '18px' : '16px',
        }}
        src={isComplete ? CompleteIcon : IncompleteIcon}
        alt=""
      />
      <Title isComplete={isComplete}>{task.title} </Title>
      <StartTaskButton isComplete={isComplete} onClick={() => setHellosignTaskModalOpen(true)}>
        Start task
      </StartTaskButton>
      <GridItem
        gridColumn={['1 / span 3', '1 / span 3', '4']}
        gridRow={['2', '2', '1']}
        isComplete={isComplete}
      >
        {task.job_title}
      </GridItem>
      <GridItem
        gridColumn={['1 / span 3', '1 / span 3', '5']}
        gridRow={['3', '3', '1']}
        isComplete={isComplete}
      >
        {task.district_name}
      </GridItem>
      {task.due_date && (
        <GridItem
          gridColumn={['1 / span 3', '1 / span 3', '6']}
          gridRow={['4', '4', '1']}
          isComplete={isComplete}
        >
          {`Due ${moment(task.due_date).format('MMMM D, YYYY')}`}
        </GridItem>
      )}
      {task.signed_at && (
        <DueDateFromNow
          gridColumn={['1 / span 3', '1 / span 3', '7']}
          gridRow={['5', '5', '1']}
          isComplete
        >
          {`Completed ${moment(task.signed_at * 1000).format('M/D/YYYY')}`}
        </DueDateFromNow>
      )}
      {!task.signed_at && task.due_date && (
        <DueDateFromNow
          gridColumn={['1 / span 3', '1 / span 3', '7']}
          gridRow={['5', '5', '1']}
          isComplete={false}
          isOverdue={isOverdue}
        >
          {moment(task.due_date).fromNow()}
        </DueDateFromNow>
      )}
      {hellosignTaskModalOpen && (
        <TaskModal
          show={hellosignTaskModalOpen}
          onHide={() => setHellosignTaskModalOpen(false)}
          task={task}
          setHellosignTaskStatus={setHellosignTaskStatus}
        />
      )}
    </TaskRow>
  );
}

const TaskRow = styled.div`
  ${grid}

  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 4px;
  align-items: center;

  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.img`
  grid-column-start: 1;
`;

const GridItem = styled.div`
  ${grid}

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ isComplete }) => (isComplete ? '#A6A7AD' : '#393C49')};
`;

const Title = styled(GridItem)`
  grid-column-start: 2;
`;

const StartTaskButton = styled.button`
  grid-column-start: 3;

  border: none;
  background: #fff;
  color: #00b88d;
  text-decoration: underline;

  ${({ isComplete }) => (isComplete ? 'display: none;' : '')}
`;

const DueDateFromNow = styled(GridItem)`
  color: ${({ isComplete, isOverdue }) => {
    if (isComplete) return '#A6A7AD';
    if (isOverdue) return '#EF5675';
    return '#393C49';
  }};
`;

CandidateDashboardHellosignTaskRow.propTypes = {
  task: PropTypes.object.isRequired,
  setHellosignTaskStatus: PropTypes.func.isRequired,
};
