import { BaseNimbleAPI } from './baseAPI';

class JobboardsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/jobboards' };
  }

  /**
   * Subscribe to a jobboard
   */
  async subscribe(slug, email, subcategoryIds) {
    const subcategories = subcategoryIds.map((id) => ({ id }));

    const url = `/${slug}/subscribe/`;
    const response = await this.post(url, {
      email,
      subcategories,
    });
    return response.data;
  }

  /**
   * Unsubscribe from a jobboard
   */
  async unsubscribe(slug, token, unSubscribeFromAll) {
    const params = { token };

    if (unSubscribeFromAll) {
      params.unsubscribe_from_all = true;
    }

    const url = `/${slug}/unsubscribe/`;
    const response = await this.post(url, null, { params });
    return response.data;
  }

  /**
   * Get subscription status to jobboard from a token
   */
  async subscriptions(slug, token) {
    const params = { token };
    const url = `/${slug}/subscriptions/`;
    const response = await this.get(url, { params });
    return response.data;
  }

  /**
   * Get jobboard information for the external jobs page.
   */
  async getExternalJobboard(slug) {
    const url = `/${slug}/`;
    const response = await this.get(url);
    return response.data;
  }
}

export default new JobboardsAPI();
