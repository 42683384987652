import statejobboardsAPI from 'api/statejobboardsAPI';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AddOrEditAlertModal, ManageAlertModal } from './JobAlertModals';
import { JobAlert, JobAlertsSubscribeModalProps } from './types';

export const JobAlertsSubscribeModal: React.FC<JobAlertsSubscribeModalProps> = ({
  categories,
  emailState,
  emailToUse,
  hasMarketplaceFF,
  jobAlerts,
  onClose,
  onDistrictSelection,
  onEmailStateChange,
  onModifyingJobAlerts,
  onSelectionErrorChange,
  onSubcategorySelection,
  onSuccessAlertChange,
  onTokenChange,
  openModalType,
  organizations,
  selectedDistricts,
  selectionError,
  selectedSubcategories,
  stateCode,
  successAlert,
  token,
  emailDisabled,
}) => {
  const [editingId, setEditingId] = useState<number | false>(false);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);
  const [isSuccessSubmessageVisible, setIsSuccessSubmessageVisible] = useState(false);
  const history = useHistory();
  const { search: queryString } = useLocation();

  const resetInputState = () => {
    onSubcategorySelection([]);
    onDistrictSelection([]);
    setEditingId(false);
  };

  useEffect(() => {
    if (isSuccessAlertVisible) {
      const timerId = setTimeout(() => {
        setIsSuccessAlertVisible(false);
      }, 14000);

      return () => {
        clearTimeout(timerId);
      };
    }

    return undefined;
  }, [isSuccessAlertVisible]);

  useEffect(() => {
    if (isSuccessSubmessageVisible) {
      const timerId = setTimeout(() => {
        setIsSuccessSubmessageVisible(false);
      }, 14000);

      return () => {
        clearTimeout(timerId);
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAlertVisible]);

  const onAdd = () => {
    const parameters = new URLSearchParams(queryString);
    parameters.set('openModalType', 'add');
    history.push({ search: parameters.toString() });
    resetInputState();
  };

  const fetchJobAlerts = () => {
    return statejobboardsAPI.subscriptions(token).then((r) => {
      onModifyingJobAlerts(r.subscriptions);
    });
  };

  const handleAddSuccess = (responseToken: string) => {
    const parameters = new URLSearchParams(queryString);
    parameters.set('openModalType', 'manage');
    onTokenChange(responseToken);

    onEmailStateChange({ email: emailToUse, error: undefined });
    setIsSuccessAlertVisible(true);

    fetchJobAlerts();
    history.push({ search: parameters.toString() });
  };

  const handleUpdateSuccess = (alerts: JobAlert[]) => {
    const parameters = new URLSearchParams(queryString);
    parameters.set('openModalType', 'manage');
    onModifyingJobAlerts(alerts);
    history.push({ search: parameters.toString() });
  };

  const handleSetIsSubmessageVisible = () => {
    setIsSuccessSubmessageVisible((previousState) => !previousState);
  };

  const handleSetIsSuccessAlertVisible = () => {
    setIsSuccessAlertVisible(true);
  };

  switch (openModalType) {
    case 'add':
    case 'edit':
      return (
        <AddOrEditAlertModal
          categories={categories}
          emailState={emailState}
          emailToUse={emailToUse}
          hasMarketplaceFF={hasMarketplaceFF}
          jobAlerts={jobAlerts}
          onClose={onClose}
          onEmailStateChange={onEmailStateChange}
          onDistrictSelection={onDistrictSelection}
          onSubcategorySelection={onSubcategorySelection}
          onSuccessAlertChange={onSuccessAlertChange}
          onSelectionErrorChange={onSelectionErrorChange}
          openModalType={openModalType}
          organizations={organizations}
          selectedDistricts={selectedDistricts}
          selectedSubcategories={selectedSubcategories}
          selectionError={selectionError}
          stateCode={stateCode}
          token={token}
          editingId={editingId}
          resetInputState={resetInputState}
          onAddSuccess={handleAddSuccess}
          onUpdateSuccess={handleUpdateSuccess}
          emailDisabled={emailDisabled}
        />
      );
    case 'manage':
      return (
        <ManageAlertModal
          editingId={editingId}
          emailState={emailState}
          emailToUse={emailToUse}
          jobAlerts={jobAlerts}
          onAdd={onAdd}
          onClose={onClose}
          onDistrictSelection={onDistrictSelection}
          onEditingIdChange={setEditingId}
          onSubcategorySelection={onSubcategorySelection}
          stateCode={stateCode}
          successAlert={successAlert}
          token={token}
          onModifyingJobAlerts={onModifyingJobAlerts}
          onSuccessAlertChange={onSuccessAlertChange}
          resetInputState={resetInputState}
          isSuccessAlertVisible={isSuccessAlertVisible}
          isSuccessSubmessageVisible={isSuccessSubmessageVisible}
          hasMarketplaceFF={hasMarketplaceFF}
          handleSetIsSubmessageVisible={handleSetIsSubmessageVisible}
          handleSetIsSuccessAlertVisible={handleSetIsSuccessAlertVisible}
        />
      );

    default:
      return <></>;
  }
};
