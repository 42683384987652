import { useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import moment, { Moment } from 'moment';

import ErrorText from 'components/errortext';
import { DatePicker, Input, SingleClickDropdown } from 'ui-kit';

import {
  salarytype,
  employmentTypeValues,
  hiringSeasons,
  startDateLabels,
  startDateType,
} from '../../utils/enums';
import auth from 'utils/auth';
import { EmploymentTypeValue, JobDetailsProps, SalaryTypeValue } from './types';
import { EMPLOYMENT_TYPE, HIRING_SEASON, SALARY_TYPE } from './enums';
import { JobClosureNotifications } from 'features/JobClosureNotifications/JobClosureNotifications';
import { useQueryGetJobClosureNotifications } from 'features/JobClosureNotifications/queries';
import { ATSJobClosureNotificationTestIds } from 'data-testids/ATS';
import { Skeleton, useTheme } from '@mui/material';

const formatDateAsMoment = (date: string | null): Moment | null => {
  /**
   * Input is a date as a string in the form 'YYYY-MM-DD'
   * and output is a moment object unless the date is null.
   */
  if (date) {
    return moment(date);
  } else {
    return null;
  }
};

export const JobDetails: React.FC<JobDetailsProps> = ({
  districtAndSuperAdmins,
  isDistrictAdmin,
  job,
  updateJob,
  updateField,
  updateJobDate,
  resetDate,
  errors,
  isTemplate = false,
}) => {
  const [startDatePickerFocused, setStartDatePickerFocused] = useState<boolean>(false);
  const [deadlinePickerFocused, setDeadlinePickerFocused] = useState<boolean>(false);

  const isValidUserTypeForJobClosureNotifications =
    auth.isDistrictAdmin() || auth.isSuperAdminUser();

  const { data, isLoading } = useQueryGetJobClosureNotifications(
    job.id,
    isValidUserTypeForJobClosureNotifications
  );
  const theme = useTheme();

  const handleSalaryTypeChange = (value: SalaryTypeValue): void => {
    updateField({ target: { name: 'salary_type', value } });
  };
  const handleEmploymentTypeChange = (value: EmploymentTypeValue): void => {
    updateField({ target: { name: 'fulltime', value } });
  };
  const handleHiringSeasonChange = (value: number): void => {
    updateField({ target: { name: 'hiring_season', value } });
  };
  const handleStartDateTypeChange = (value: keyof typeof startDateType): void => {
    updateField({ target: { name: 'start_date_type', value: startDateType[value] } });
  };
  const handleDateChange = (field: 'deadline' | 'start_date', value: string): void => {
    if (value) {
      updateJobDate(field, value);
    } else {
      resetDate(field);
    }
  };

  const shouldShowJobClosureNotificationsSection =
    !isTemplate && auth.hasJobClosureNotifications() && !auth.inPostingOnlyDistrict();

  return (
    <JobDetailsContainer isSchoolAdmin={!isDistrictAdmin}>
      {isDistrictAdmin && (
        <>
          <FirstColumnSection data-testid="salary-section">
            <Heading>Salary</Heading>
            <StyledNumberFormat
              data-testid="salary-minimum-input"
              value={job.salary_min || ''}
              placeholder="Example: $40,000"
              thousandSeparator={true}
              prefix={'$'}
              maxLength={11}
              decimalScale={2}
              allowNegative={false}
              onValueChange={(values) => {
                const { value } = values;
                // value ie, 2223. Displayed value would be $2,223
                const updatedJob = { ...job, salary_min: Number(value) };
                updateJob(updatedJob);
              }}
            />
            <HelpText>If left blank, posting will say &quot;Up to $xx,xxx.&quot;</HelpText>
          </FirstColumnSection>
          <RelativeContainer>
            <SalaryTo>To</SalaryTo>
          </RelativeContainer>
          <MiddleColumnSection>
            <StyledNumberFormat
              data-testid="salary-maximum-input"
              value={job.salary_max || ''}
              placeholder="Example: $50,000"
              thousandSeparator={true}
              prefix={'$'}
              maxLength={11}
              decimalScale={2}
              allowNegative={false}
              onValueChange={(values) => {
                const { value } = values;
                // value ie, 2223. Displayed value would be $2,223
                const updatedJob = { ...job, salary_max: Number(value) };
                updateJob(updatedJob);
              }}
            />
          </MiddleColumnSection>
          <EndColumnSection>
            <SalaryTypeDropdown
              label={SALARY_TYPE[job.salary_type] || 'per year'}
              border={false}
              boxShadow={true}
              className="salary-type-dropdown"
            >
              {salarytype()
                .reverse()
                .map((option) => (
                  <StyledListItem
                    key={option.value}
                    onClick={() => handleSalaryTypeChange(option.value as SalaryTypeValue)}
                  >
                    {option.label}
                  </StyledListItem>
                ))}
            </SalaryTypeDropdown>
          </EndColumnSection>
          <ErrorText message={errors.salary_min || errors.salary_max} />
        </>
      )}
      <FirstColumnSection>
        <Heading>Employment Type</Heading>
        <SingleClickDropdown.Dropdown
          label={EMPLOYMENT_TYPE[job.fulltime] || 'Full-Time'}
          border={false}
          boxShadow={true}
          className="employment-type-dropdown"
        >
          {employmentTypeValues().map((option) => (
            <StyledListItem
              key={option.value}
              onClick={() => handleEmploymentTypeChange(option.value as EmploymentTypeValue)}
            >
              {option.label}
            </StyledListItem>
          ))}
        </SingleClickDropdown.Dropdown>
      </FirstColumnSection>
      {isDistrictAdmin && (
        <>
          <MiddleColumnSection data-testid="deadline-section">
            <Heading data-testid="deadline-heading">Deadline</Heading>
            <DeadlineContainer>
              <DatePicker
                date={formatDateAsMoment(job.deadline)}
                onDateChange={(date: string) => handleDateChange('deadline', date)}
                focused={deadlinePickerFocused}
                onFocusChange={({ focused }) => setDeadlinePickerFocused(focused)}
                id="jobedit-deadline-date-picker"
                placeholder="MM/DD/YYYY"
              />
            </DeadlineContainer>
            <HelpText data-testid="deadline-help-text">
              Job closes at 8PM Pacific Time on date selected. Blank shows “Until Filled.”
            </HelpText>
          </MiddleColumnSection>

          {shouldShowJobClosureNotificationsSection && (
            <EndColumnSection data-testid={ATSJobClosureNotificationTestIds.JOB_CLOSURE_SECTION}>
              {isLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="100%"
                    sx={{ fontSize: theme.typography.htmlFontSize }}
                  />
                  <Skeleton variant="rectangular" width="100%" height={80} />
                </>
              ) : (
                <JobClosureNotifications
                  applicationStatuses={job.statuses_available}
                  data={data[0]}
                  districtAndSuperAdmins={districtAndSuperAdmins}
                  isLoading={isLoading}
                  jobId={job.id}
                />
              )}
            </EndColumnSection>
          )}
        </>
      )}

      {/**
       * In posting only districts we show the hiring season selection on the row above next to deadline.
       * For normal ATS districts, the question is on the next row, and the endspot of this row is meant
       * to be blank.
       */}

      {auth.inPostingOnlyDistrict() && (
        <EndColumnSection data-testid="po-hiring-season-section">
          <Heading data-testid="po-hiring-season-heading">Hiring Season</Heading>
          <SingleClickDropdown.Dropdown
            label={HIRING_SEASON[job.hiring_season]}
            border={false}
            boxShadow={true}
            className="po-hiring-season-dropdown"
          >
            {hiringSeasons(true, true, 10).map((option) => (
              <StyledListItem
                key={option.value}
                onClick={() => handleHiringSeasonChange(option.value)}
              >
                {option.label}
              </StyledListItem>
            ))}
          </SingleClickDropdown.Dropdown>
        </EndColumnSection>
      )}

      <EndColumnSection />
      {!auth.inPostingOnlyDistrict() && isDistrictAdmin && (
        <FirstColumnSection data-testid="standard-hiring-season-section">
          <Heading data-testid="standard-hiring-season-heading">Hiring Season</Heading>
          <SingleClickDropdown.Dropdown
            label={HIRING_SEASON[job.hiring_season]}
            border={false}
            boxShadow={true}
            className="standard-hiring-season-dropdown"
          >
            {hiringSeasons(true, true, 10).map((option) => (
              <StyledListItem
                key={option.value}
                onClick={() => handleHiringSeasonChange(option.value)}
              >
                {option.label}
              </StyledListItem>
            ))}
          </SingleClickDropdown.Dropdown>
        </FirstColumnSection>
      )}

      {!auth.inPostingOnlyDistrict() && !isTemplate && (
        <MiddleColumnSection
          isSchoolAdmin={!isDistrictAdmin}
          data-testid="requisition-number-section"
        >
          <Heading>Requisition Number</Heading>
          <Input
            data-testid="requisition-number-input"
            name="requisition_number"
            type="text"
            placeholder="If applicable"
            value={
              job.requisition_number
                ? job.requisition_number
                : job.district_role
                  ? job.district_role.requisition_number
                  : ''
            }
            onChange={updateField}
          />
          <HelpText>For internal use only.</HelpText>
          <ErrorText message={errors.fulltime || errors.deadline} />
        </MiddleColumnSection>
      )}

      {isDistrictAdmin && (
        <>
          <EndColumnSection />
          <FirstColumnSection data-testid="contact-section">
            <Heading>Contact for Questions</Heading>
            <Input
              data-testid="contact-input"
              name="contact"
              type="text"
              placeholder="Ex: talent@greatschools.org"
              value={job.contact || ''}
              onChange={updateField}
              maxLength={100}
            />
            <HelpText data-testid="contact-help-text">
              Will not appear to candidate if left blank.
            </HelpText>
          </FirstColumnSection>
          <MiddleColumnSection>
            <Heading>Start Date</Heading>
            <SingleClickDropdown.Dropdown
              label={startDateLabels[job.start_date_type]}
              border={false}
              className="start-date-dropdown"
            >
              {Object.keys(startDateType).map((option) => (
                <StyledListItem
                  key={option}
                  onClick={() => handleStartDateTypeChange(option as keyof typeof startDateType)}
                >
                  {option}
                </StyledListItem>
              ))}
            </SingleClickDropdown.Dropdown>
          </MiddleColumnSection>
          {Number(job.start_date_type) === startDateType['Specific date'] && (
            <EndColumnSection>
              <RowTwo>
                <DatePicker
                  date={formatDateAsMoment(job.start_date)}
                  onDateChange={(date: string) => handleDateChange('start_date', date)}
                  focused={startDatePickerFocused}
                  onFocusChange={({ focused }) => setStartDatePickerFocused(focused)}
                  id="jobedit-start-date-picker"
                  placeholder="MM/DD/YYYY"
                />
              </RowTwo>
            </EndColumnSection>
          )}
          <ErrorText message={errors.start_date} />
        </>
      )}
    </JobDetailsContainer>
  );
};

const JobDetailsContainer = styled.div<{ isSchoolAdmin: boolean }>`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  @media screen and (min-width: 990px) {
    // 3 column layout for large screens
    // school admins only need one column because most items are not present
    grid-template-columns: ${({ isSchoolAdmin }) =>
      isSchoolAdmin ? '1fr' : '1fr 3rem 1fr 3rem 1fr'};

    // grid column gaps are built into the columns above because we have the word "To"
    // for salary in one of the "gaps"
    grid-column-gap: 0;

    grid-row-gap: 1rem;
  }
`;

const Section = styled.div`
  display: grid;
  grid-auto-rows: 50px 50px 0.8rem 20px;
`;

const FirstColumnSection = styled(Section)`
  @media screen and (min-width: 990px) {
    grid-column-start: 1;
  }
`;

const MiddleColumnSection = styled(Section)<{ isSchoolAdmin?: boolean }>`
  @media screen and (min-width: 990px) {
    grid-column-start: ${({ isSchoolAdmin }) => (isSchoolAdmin ? '1' : '3')};
  }
`;

export const EndColumnSection = styled(Section)<{ isSchoolAdmin?: boolean }>`
  @media screen and (min-width: 990px) {
    grid-column-start: ${({ isSchoolAdmin }) => (isSchoolAdmin ? '1' : '5')};
  }
`;

export const Heading = styled.p`
  color: #444444;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;

  align-self: end;
`;

const StyledNumberFormat = styled(NumberFormat)`
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  height: 50px;
  margin: 0;

  @media screen and (min-width: 990px) {
    grid-row-start: 2;
  }

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }
`;

const StyledListItem = styled(SingleClickDropdown.ListItem)`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;

const HelpText = styled.p`
  font-size: 12px;
  color: #909090;

  grid-row-start: 4;
`;

const SalaryTypeDropdown = styled(SingleClickDropdown.Dropdown)`
  @media screen and (min-width: 990px) {
    grid-row-start: 2;
  }
`;

const DeadlineContainer = styled.div`
  position: relative;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const SalaryTo = styled.div`
  @media screen and (min-width: 990px) {
    position: absolute;
    top: 51%;
    left: 13px;
  }
`;

const RowTwo = styled.div`
  grid-row-start: 2;
`;
