import './jobrow.css';

import { Link } from 'react-router-dom';
import { Component } from 'react';
import rightCaret from '../../../assets/icon_right_caret_grey.svg';

import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { startDateLabels, startDateType } from '../../../utils/enums';
import blueStarIcon from '../../../assets/icons/icon-star-filled-blue.svg';
import { formatSalary, getDeadline } from 'utils/roleutils';
import { ATSDistrictJobListDataTestIds } from 'data-testids/ATS';

export default class JobRow extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    isInternalCandidate: PropTypes.bool.isRequired,
  };

  renderDateColumn(date, isDeadline) {
    if (isDeadline) {
      if (!date) {
        return 'Until filled';
      } else {
        const deadline = getDeadline(date);
        return (
          <div>
            <div>{Moment(deadline).format('l')}</div>
            <div className="job-row-subtext">{Moment(deadline).fromNow()}</div>
          </div>
        );
      }
    } else {
      if (this.props.job.start_date_type !== startDateType['Specific date']) {
        return startDateLabels[this.props.job.start_date_type];
      } else if (!date) {
        return 'Not specified';
      }
    }
    const dateShown = Moment(date).format('MMM YYYY');
    return (
      <div>
        <div>{dateShown}</div>
        <div className="job-row-subtext">{Moment(date).fromNow()}</div>
      </div>
    );
  }

  renderSchoolColumn = (job) => {
    if (!job.schools || !job.schools.length) {
      return <span className="job-row-school pointer">Not Specified</span>;
    }

    const schoolsWithUnfilledVacancy = job.schools.filter(
      (schoolrole) => schoolrole.ftes_total > 0
    );

    if (schoolsWithUnfilledVacancy.length > 0) {
      let school;
      if (schoolsWithUnfilledVacancy.length > 1) {
        school = 'Multiple';

        const sortedSchoolNames = schoolsWithUnfilledVacancy
          .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
          .map((school) => school.name)
          .join(', ');

        return (
          <div>
            <TextEllipsisContainer
              data-tip={sortedSchoolNames}
              data-for="district-jobboard-schools"
              data-delay-show="100"
            >
              {school}
            </TextEllipsisContainer>
            <ReactTooltip id="district-jobboard-schools" effect="solid" html />
          </div>
        );
      } else {
        school = schoolsWithUnfilledVacancy[0].name;
        return <span className="job-row-school pointer">{school}</span>;
      }
    } else {
      return <span className="job-row-school pointer">Not Specified</span>;
    }
  };

  renderCategoryLabel = (categoriesList) => {
    let categoryLabel;
    if (categoriesList.length > 1) {
      categoryLabel = 'Multiple';
    } else if (categoriesList.length === 0) {
      categoryLabel = '';
    } else {
      for (let i = 0; i < this.props.categories.length; i++) {
        let category = this.props.categories[i];
        for (let j = 0; j < category.subcategories.length; j++) {
          let subcategory = category.subcategories[j];
          if (subcategory.id.toString() === categoriesList[0].toString()) {
            categoryLabel = subcategory.label;
            if (categoryLabel.length > 18) {
              categoryLabel = categoryLabel.substring(0, 18) + '...';
            }
            return <span className="job-row-subject">{categoryLabel}</span>;
          }
        }
      }
      // if id not found in subcategories list, it might have been deleted. So return empty str.
      categoryLabel = '';
    }
    return <span className="job-row-subject">{categoryLabel}</span>;
  };

  render() {
    const { job } = this.props;
    // base64 encode the word "true" for EXTRA SECURITY
    let encodedData = window.btoa('true');
    let url = this.props.isInternalCandidate ? `/?internal=${encodedData}` : '';
    const isFeaturedJob = job.is_featured;
    const onDemo = window.location.host === 'demo.hirenimble.com';

    return (
      <Link
        className="list-row list-item job-row"
        rel="noopener"
        target="_blank"
        to={`/jobview/${job.id}${url}`}
        data-testid={ATSDistrictJobListDataTestIds.JOB_ROW}
      >
        <div className="job-row-featured-icon" data-tip="Featured job">
          {isFeaturedJob ? <img src={blueStarIcon} alt="featured job" /> : <span />}
        </div>
        <div className={`candidate-select-box ex-jobs-select-box ${onDemo ? ' ' : 'hidden'}`}>
          <input type="checkbox" name="selected" onClick={(e) => e.stopPropagation()} />
        </div>
        <div className="job-row-title" data-testid={ATSDistrictJobListDataTestIds.JOB_TITLE}>
          {job.title}
        </div>
        <div className="job-row-school">{this.renderSchoolColumn(job || [])}</div>

        <div className="job-row-subjects">{this.renderCategoryLabel(job.subjects || [])}</div>
        <div className="job-row-salary">
          {formatSalary(job.salary_min, job.salary_max, job.salary_type)}
        </div>
        <div className="job-row-date">{this.renderDateColumn(job.start_date, false)}</div>
        <div className={`job-row-date deadline ${onDemo ? 'demo' : ''}`}>
          {this.renderDateColumn(job.deadline, true)}
        </div>
        <div className="job-row-apply" style={{ marginLeft: onDemo ? '-2%' : '0' }}>
          <img src={rightCaret} className="job-row-apply-caret" alt="right-caret" />
        </div>
      </Link>
    );
  }
}

const overflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

const TextEllipsisContainer = styled.div`
  ${overflowEllipsis}
`;
