import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { captureException } from '@sentry/browser';

import { ResumeForm } from './ResumeForm';
import { CertificationsForm } from './CertificationsForm';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import { QualificationsFormData } from 'types/connectTypes';
import { ConnectProfileFlowButtonGroup } from '../SharedComponents/ConnectProfileFlowButtonGroup';

import { getGatedActionDisplayInfo, updateConnectFirstProfileSetupDateTime } from '../utils';
import auth from 'utils/auth';
import { Aeonik } from '../../JobDiscovery/utils';

import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import connectSubscriptionsAPI from 'api/connectSubscriptionsAPI';
import expressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { empty_certification_input } from './constants';
import { useStateParam } from '../../ConnectStateCodeContextProvider';
import { getStateIDFromStateCode } from 'features/Connect/utils/connectUtils';

export const ResumeAndCertificationsForm = (): React.ReactElement => {
  const user = auth.getUser();
  const stateCode = useStateParam();

  const defaultStateID = useMemo(() => {
    return getStateIDFromStateCode(stateCode);
  }, [stateCode]);

  const [userDataIsLoading, setUserDataIsLoading] = useState<boolean>(true);
  const [userFormData, setUserFormData] = useState<QualificationsFormData>({
    hasCertification: true,
    certifications: [empty_certification_input(defaultStateID)],
    resume: '',
  });

  const [resumeIsUploading, setResumeIsUploading] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [resumeUploadErrors, setResumeUploadErrors] = useState<string[]>([]);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatedAction = searchParams.get('action')?.toLowerCase();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const getConnectCertifications = () => {
    return userFormData.certifications.filter((certification) => certification.isHidden === false);
  };

  const removeIncompleteCertifications = () => {
    return userFormData.certifications.filter((certification) => {
      const isHiddenCredential = certification.isHidden;
      const hasValidStateAndSubject = !!certification.state && !!certification.subject?.id;
      return isHiddenCredential || hasValidStateAndSubject;
    });
  };

  const certificationsFormHasErrors = () => {
    let hasErrors = false;
    const requiresCertificationValidation = userFormData.hasCertification;

    if (!requiresCertificationValidation) {
      hasErrors = false;
    } else {
      getConnectCertifications().forEach((certification) => {
        const formCertificationIndex = userFormData.certifications.findIndex(
          (formCertification) => formCertification.client_id === certification.client_id
        );

        const hasSubjectError = !certification?.subject?.id;
        const hasStateError = !certification?.state;

        userFormData.certifications[formCertificationIndex].errors = {
          hasSubjectError,
          hasStateError,
        };

        if (hasSubjectError || hasStateError) {
          hasErrors = true;
        }
      });
      setUserFormData({ ...userFormData, certifications: [...userFormData.certifications] });
    }
    return hasErrors;
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'hasCertification') {
      if (value === 'true' && getConnectCertifications().length === 0) {
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [
            ...userFormData.certifications,
            empty_certification_input(defaultStateID),
          ],
        });
        return;
      } else if (value === 'false') {
        const updatedCertifications = getConnectCertifications().map((certification) => {
          const errors = {
            hasSubjectError: false,
            hasStateError: false,
          };
          certification.errors = errors;
          return certification;
        });
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [...updatedCertifications],
        });
      }

      setUserFormData({ ...userFormData, hasCertification: value === 'true' });
      return;
    }

    setUserFormData({ ...userFormData, [name]: value });
  };

  const isFormValid = () => {
    let isFormValid = true;
    const { resume } = userFormData;

    if (!resume && pageDisplayInformation.requiredFields.includes('resume')) {
      setResumeUploadErrors(['Please upload a resume.']);
      isFormValid = false;
    }
    if (certificationsFormHasErrors()) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const fetchUserData = async () => {
    try {
      setUserDataIsLoading(true);
      const { resume, credentials } = await expressInterestAPI.fetchUserProfileAndCredentials(
        user.id
      );
      if (resume && credentials.length > 0) {
        setUserFormData({
          ...userFormData,
          certifications: credentials,
          resume,
        });
      } else if (resume) {
        setUserFormData({ ...userFormData, resume });
      } else if (credentials.length > 0) {
        setUserFormData({ ...userFormData, certifications: credentials });
      }
    } catch (error) {
      captureException(error);
    } finally {
      setUserDataIsLoading(false);
    }
  };

  const handleSaveAndContinue = async () => {
    if (isFormValid()) {
      try {
        setIsFormSubmitting(true);
        let certificationsToSave = userFormData.certifications;
        if (!userFormData.hasCertification) {
          certificationsToSave = userFormData.certifications.filter(
            (certification) => certification.isHidden
          );
        } else {
          certificationsToSave = removeIncompleteCertifications();
        }
        await expressInterestAPI.updateUserCredentials(certificationsToSave, user.id);
        if (gatedAction === ConnectGatedActions.ALERT) {
          await connectSubscriptionsAPI.createSubscription();
        }
        history.push(pageDisplayInformation.continueToUrl);
      } catch (error) {
        captureException(error);
      } finally {
        setIsFormSubmitting(false);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userFormData.hasCertification && getConnectCertifications().length === 0) {
      setUserFormData({
        ...userFormData,
        certifications: [...userFormData.certifications, empty_certification_input(defaultStateID)],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData.certifications, userFormData.hasCertification]);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  return (
    <Box
      sx={{
        width: 635,
        maxWidth: '100vw',
        margin: '22px auto',
      }}
    >
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <Paper
        elevation={4}
        sx={{
          borderRadius: '12px',
          margin: '0px 15px',
        }}
      >
        <Box
          component="form"
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            paddingTop: '40px',
            paddingBottom: '16px',
            marginTop: '24px',
            borderRadius: '12px',
          }}
        >
          <Typography
            variant="h1"
            color="#101828"
            fontSize={28}
            fontWeight={400}
            fontFamily={Aeonik}
            textAlign="center"
          >
            {pageDisplayInformation.headerText}
          </Typography>
          <Stack spacing={3} px={{ xs: 2, sm: 8 }} py={{ xs: 2, sm: 3 }}>
            <ResumeForm
              user={user}
              userFormData={userFormData}
              setUserFormData={setUserFormData}
              userDataIsLoading={userDataIsLoading}
              resumeIsUploading={resumeIsUploading}
              setResumeIsUploading={setResumeIsUploading}
              resumeUploadErrors={resumeUploadErrors}
              setResumeUploadErrors={setResumeUploadErrors}
              pageDisplayInformation={pageDisplayInformation}
            />
            <CertificationsForm
              isUserDataLoading={userDataIsLoading}
              handleFormChange={handleFormChange}
              userFormData={userFormData}
              setUserFormData={setUserFormData}
              certificationsFormHasErrors={certificationsFormHasErrors}
              getConnectCertifications={getConnectCertifications}
              isFormSubmitting={isFormSubmitting}
              pageDisplayInformation={pageDisplayInformation}
              defaultStateID={defaultStateID}
            />
          </Stack>
        </Box>
      </Paper>
      <ConnectProfileFlowButtonGroup
        dataTestId={''}
        primaryButton={{
          dataTestId: '',
          primaryButtonLabel: pageDisplayInformation?.primaryButtonLabel,
          primaryAction: handleSaveAndContinue,
          disabled: false,
          isLoading: isFormSubmitting,
        }}
        secondaryButton={{
          dataTestId: '',
          secondaryButtonLabel: 'Back',
          secondaryAction: () => history.push(pageDisplayInformation.backUrl),
        }}
        hasError={false}
      />
    </Box>
  );
};

export const QuestionLabel = styled(InputLabel)(({ theme }) => ({
  textWrap: 'wrap',
  marginBottom: theme.spacing(1),
}));
