import { Component } from 'react';

import PropTypes from 'prop-types';
import { examMapping, praxisSubjects } from '../../../../utils/enums';

export default class ExamInputFields extends Component {
  static propTypes = {
    exam: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  render() {
    let { exam, handleChange } = this.props;

    return (
      <div className={`input-div ${examMapping[exam.type]['className']}`}>
        {/* exam type 2 is Praxis Subject Assessment, which has an extra select element */}
        {exam.type === 2 && (
          <div className="basic-select dropdown">
            <select
              name="praxis_subject"
              value={this.props.exam.praxis_subject || ''}
              onChange={handleChange}
            >
              <option value="">Please select subject</option>
              {praxisSubjects().map((e, i) => (
                <option key={i} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {examMapping[exam.type] &&
          examMapping[exam.type]['fields'].map((row, i) => {
            const rowMinMax = row.min ? `${row.min} - ${row.max}` : '';
            return (
              <input
                key={`${i} ${row.displayName}`}
                name={row.fieldName}
                className="form-field"
                type={row.fieldType === 'text' ? 'text' : 'number'}
                min={row.min}
                max={row.max}
                placeholder={`${row.displayName} ${rowMinMax}`}
                value={this.props.exam[row.fieldName] || ''}
                onChange={handleChange}
              />
            );
          })}
      </div>
    );
  }
}
