import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal, Input, Label } from 'ui-kit';

const EditCategoryModal = (props) => {
  const [formState, setFormState] = useState({
    label: props.label ?? '',
    error: null,
  });
  const inputRef = useRef(null);

  const onSave = () => {
    if (!formState.label) {
      setFormState((prevState) => ({ ...prevState, error: 'Please enter a valid label.' }));
    } else {
      props.onSave(formState.label);
      props.onHide();
    }
  };

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete();
      props.onHide();
    }
  };

  const isNew = props.onDelete ? false : true;

  return (
    <Modal.Modal isOpen={props.show} onClose={props.onHide} focusOnOpenRef={inputRef}>
      <Modal.Title>{`${isNew ? 'New' : 'Edit'} ${props.entityName}`}</Modal.Title>
      <Modal.Body>
        <Label htmlFor="category-name">
          {`${props.entityName} Name`}
          <Input
            id="category-name"
            type="text"
            name="label"
            required
            ref={inputRef}
            value={formState.label}
            hasError={formState.error}
            onChange={(event) => setFormState({ label: event.target.value })}
            marginTop="1em"
          />
          <FormInputError>{formState.error}</FormInputError>
        </Label>
      </Modal.Body>
      <Modal.Actions>
        <ModalActions>
          <SecondaryButton onClick={props.onHide}>Cancel</SecondaryButton>
          {props.onDelete && (
            <SecondaryButton onClick={onDelete}>{`Delete ${props.entityName}`}</SecondaryButton>
          )}
          <PrimaryButton onClick={onSave}>Save & Close</PrimaryButton>
        </ModalActions>
      </Modal.Actions>
    </Modal.Modal>
  );
};

EditCategoryModal.propTypes = {
  label: PropTypes.string,
  entityName: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onHide: PropTypes.func.isRequired,
};

EditCategoryModal.defaultProps = {
  label: '',
  entityName: 'Category',
};

export default EditCategoryModal;

const ModalActions = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  & > button:not(:first-child) {
    margin-left: 1rem;
  }

  & > button:last-child {
    margin-left: auto;
  }

  // Collapse action buttons into a column for small screens
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    flex-flow: column-reverse;

    & > button:last-child {
      margin-left: unset;
    }
  }
`;

const Button = styled.button`
  padding: 0.85em 2.5em;
  border-radius: 2px;

  font-size: 14px;
`;

const SecondaryButton = styled(Button)`
  background-color: #ffffff;
  background-color: var(--white);
  color: #c2c2c2;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const PrimaryButton = styled(Button)`
  background-color: #00b88d;
  background-color: var(--green);
  color: #ffffff;
  color: var(--white);

  border-radius: 3px;
  border-color: #fff;
`;

const FormInputError = styled.div`
  margin-top: 0.25em;
  font-size: 13px;
  color: #cc0033;

  height: 18px;
`;
