import styled from 'styled-components';

import { useActiveSchools } from 'hooks/data/useActiveSchools';
import { useSchoolGroups } from 'hooks/data/useSchoolGroups';
import { useCategories } from 'hooks/data/useCategories';
import { useApplicationStatuses } from 'hooks/data/useApplicationStatuses';
import auth from 'utils/auth';
import { appStatusType } from 'utils/enums';

import PipelineMetricsDashboard from './PipelineMetricsDashboard';

/**
 * Entrypoint into the dashboard.
 * Loading schools and categories here to prevent mutiple network calls of each
 * widget as these load in separately.
 */
export default () => {
  const districtId = auth.getUser().profile.district.id;
  const activeSchools = useActiveSchools(districtId);
  const schoolGroups = useSchoolGroups();
  const categories = useCategories({ districtId });
  const applicationStatuses = useApplicationStatuses({
    districtId,
    statusTypes: Object.values(appStatusType).filter((value) => value !== appStatusType.draft),
  });

  if (activeSchools.isLoading || categories.isLoading || applicationStatuses.isLoading) {
    return (
      <LoadingSpinner>
        <i className="fa fa-circle-o-notch fast-spin fa-3x fa-fw" />
      </LoadingSpinner>
    );
  }

  let activeSchoolGroups = [];

  schoolGroups.data.forEach((g) => {
    activeSchoolGroups.push({
      id: 'g' + g.id,
      label: g.name,
      isGroupHeader: true,
      group: g.id,
    });

    activeSchoolGroups = [
      ...activeSchoolGroups,
      ...activeSchools.data
        .filter((a) => {
          return a.school_groups.includes(g.id);
        })
        .map((a) => {
          return { id: a.id, label: a.name, group: g.id, originalId: a.id };
        }),
    ];
  });

  activeSchoolGroups = [
    ...activeSchoolGroups,
    {
      id: 'g0',
      label: 'No group',
      isGroupHeader: true,
      group: 0,
    },
    ...activeSchools.data
      .filter((a) => {
        return a.school_groups.length === 0;
      })
      .map((a) => {
        return { id: a.id, label: a.name, group: 0, originalId: a.id };
      }),
  ];

  return (
    <PipelineMetricsDashboard
      activeSchools={activeSchoolGroups}
      categories={categories.data}
      applicationStatuses={applicationStatuses.data}
    />
  );
};

const LoadingSpinner = styled.div`
  width: 100%;
  margin-top: 4em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
