import { BaseNimbleAPI } from './baseAPI';

class RolesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/role' };
  }

  async featureRole(roleId) {
    const url = `/${roleId}/feature/`;
    const response = await this.post(url);
    return response.data;
  }

  async unfeatureRole(roleId) {
    const url = `/${roleId}/feature/`;
    const response = await this.delete(url);
    return response.data;
  }

  async mergeRole(roleBeingMerged, roleMergingInto) {
    const url = `/${roleMergingInto.id}/merge/`;
    const data = { role_being_merged: roleBeingMerged.id };
    const response = await this.post(url, data);
    return response.data;
  }

  async returnSubmittedRole(roleId, data) {
    const url = `/${roleId}/return_submitted_role/`;
    const response = await this.post(url, data);
    return response.data;
  }

  async fetchRole(roleId) {
    const url = `/${roleId}/`;
    const response = await this.get(url);
    return response.data;
  }

  async fetchProspectConversionRoles() {
    const url = '/get_prospect_conversion_roles/';
    const response = await this.get(url);
    return response.data;
  }

  async fetchFilterStatuses(roleId) {
    const url = `/${roleId}/filter_statuses/`;
    const response = await this.get(url);
    return response.data;
  }

  async update(roleId, data) {
    const url = `/${roleId}/`;
    const response = await this.put(url, data);
    return response.data;
  }

  async createTemplateFromRole(roleId, title) {
    const url = `/template_from_role/`;
    const newData = { title: title, role_id: roleId };
    const response = await this.post(url, newData);
    return response.data;
  }

  async createRoleFromTemplate(roleId, title) {
    const url = `/role_from_template/`;
    const newData = { title: title, template_id: roleId };
    const response = await this.post(url, newData);
    return response.data;
  }

  async createRoleFromRequest(title, roleRequestId, templateId) {
    const url = `/convert_request_to_role/`;
    const newData = { title: title, role_request_id: roleRequestId, template_id: templateId };
    const response = await this.post(url, newData);
    return response.data;
  }
}

export default new RolesAPI();
