import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ReactComponent as SearchIcon } from 'assets/icon_search_glass.svg';

export default function ListFilterDropdownWithInput({
  updateMultiSelectFilter,
  displayName,
  fieldName,
  options,
  values,
  selectAll,
  clearAll,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  let filteredOptions;
  if (options === undefined) {
    filteredOptions = [];
  } else if (!searchValue) {
    filteredOptions = options;
  } else {
    filteredOptions = options.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const toggleOptions = () => setShowOptions((prev) => !prev);

  const getCaret = () => {
    if (isActive) {
      if (showOptions) {
        return iconUpCaretGreen;
      } else {
        return iconDownCaretGreen;
      }
    } else {
      if (showOptions) {
        return iconUpCaret;
      } else {
        return iconDownCaret;
      }
    }
  };

  // default is to have all filters selected, so we only need to show
  // the filter as active if they change something, which would make both allSelected
  // and noneSelected false.
  let allSelected = values.length === options.length;
  let noneSelected = values.length === 0;
  let isActive = !(allSelected || noneSelected);

  return (
    <div className={`new-cl-filter ${fieldName} ${isActive ? 'cl-active' : ''}`}>
      <div className="cl-filter-header" onClick={toggleOptions}>
        <p className={isActive ? 'cl-active' : ''}>{displayName}</p>
        <img src={getCaret()} alt="" />
      </div>
      <div className={`relative selection-list ${showOptions ? 'show' : ''}`}>
        <div className="option select-all">
          <span
            className="drop-select-all"
            onClick={(e) => {
              e.stopPropagation();
              selectAll(fieldName, options);
            }}
          >
            Select All
          </span>
          <span
            className="drop-select-all"
            onClick={(e) => {
              e.stopPropagation();
              clearAll(fieldName);
            }}
          >
            Clear All
          </span>
        </div>
        <InputFilterContainer>
          <InputFilter
            type="text"
            placeholder=""
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {searchValue ? (
            <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
          ) : (
            <PositionedSearchIcon />
          )}
        </InputFilterContainer>
        {filteredOptions.map((option) => {
          const optionIsSelected = values.includes(option.value);
          return (
            <div key={option.value} className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={optionIsSelected}
                  onChange={() => {
                    updateMultiSelectFilter(
                      fieldName,
                      option.value,
                      optionIsSelected,
                      options.length
                    );
                  }}
                />
                <span className="checkmark" />
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

ListFilterDropdownWithInput.propTypes = {
  updateMultiSelectFilter: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  selectAll: PropTypes.func,
  clearAll: PropTypes.func,
  isSubFilter: PropTypes.bool,
};

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 0.5rem;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 15px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;
