import { Typography, Link, styled } from '@mui/material';
import { ATSSignUpDataTestIds } from '../../../../data-testids/ATS';
import { Alert } from 'sharedComponents/Alert/Alert';
import { AccountErrors } from 'sharedComponents/Error/constants';

export const renderErrorMessage = (message: string): (string | JSX.Element)[] => {
  const messageParts: string[] = message.split(/(sign in|support)/g);

  return messageParts.map((part, index) => {
    switch (part) {
      case 'sign in':
        return (
          <StyledMessageLink key={index} href="/login">
            sign in
          </StyledMessageLink>
        );
      case 'support':
        return (
          <StyledMessageLink key={index} href="mailto:support@hirenimble.com" target="_blank">
            support
          </StyledMessageLink>
        );
      default:
        return part;
    }
  });
};

interface SignUpErrorAlertProps {
  errorMessage: string;
  title?: string;
}

export const SignUpErrorAlert: React.FC<SignUpErrorAlertProps> = ({
  errorMessage,
  title = "Hmm, something isn't right",
}) => {
  const isAccountAlreadyExists = errorMessage === AccountErrors.ACCOUNT_ALREADY_EXISTS;

  return (
    <Alert type="error" variant="standard" dataTestId={ATSSignUpDataTestIds.ERROR_TEXT}>
      <>
        <StyledTitleText variant="subtitle1">{title}</StyledTitleText>
        {isAccountAlreadyExists ? (
          <StyledSubtitleText variant="body2">
            {renderErrorMessage(AccountErrors.ACCOUNT_ALREADY_EXISTS)}
          </StyledSubtitleText>
        ) : (
          <StyledSubtitleText variant="body2">{errorMessage}</StyledSubtitleText>
        )}
      </>
    </Alert>
  );
};

const StyledTitleText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(0.5),
  color: theme.palette.error.dark,
}));

const StyledSubtitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}));

const StyledMessageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.error.dark,
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.error.dark,
    textDecoration: 'underline',
  },
  '&:focus': {
    color: theme.palette.error.dark,
  },
  '&:active': {
    color: theme.palette.error.dark,
  },
}));
