import { useEffect, useLayoutEffect } from 'react';

import PageNotFoundIcon from '../assets/pageNotFound.png';
import { useSetNotFound } from 'Layout';

const PageNotFound: React.FC = () => {
  const setNotFound = useSetNotFound();

  useLayoutEffect(() => {
    const unset = setNotFound();

    return () => {
      unset();
    };
  }, [setNotFound]);

  useEffect(() => {
    document.body.classList.add('page-not-found');

    return () => {
      document.body.classList.remove('page-not-found');
    };
  }, []);

  return (
    <div className="page-not-found-div">
      <img src={PageNotFoundIcon} alt="page not found" />
    </div>
  );
};

export default PageNotFound;
