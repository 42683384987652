import styled from 'styled-components';

export const JobAlertsBanner = styled.div`
  align-items: center;
  background-color: #060810;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  min-height: 50px;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }

  svg {
    fill: #fff;
    flex-shrink: 0;
    height: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    svg {
      height: 14px;
    }
  }
`;

export const JobAlertsBannerText = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0.5em 0.5em 0.5em 1.5em;
`;

export const ManageAlertText = styled.div`
  margin-left: 5px;
`;

export const NotificationIcon = styled.img`
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(265deg) brightness(103%) contrast(101%);
  height: 22px;
  padding-right: 10px;
`;
