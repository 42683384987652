import styled from 'styled-components';

import { ApplicantDetailsProps } from './types';
import Button from '../../common/Button';
import emailIcon from 'assets/icon-email.svg';
import PhoneSection from './PhoneSection';

const ApplicantDetails: React.FC<ApplicantDetailsProps> = ({
  email,
  fetchProspect,
  fullUser,
  hasTalentMarketplaceSource,
  openProspectConversionModal,
  partialUser,
}) => {
  return (
    <div
      className="QuickProfile__applicant-details"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div className="QuickProfile__applicant-details-contact contact">
        {email && (
          <EmailSection>
            <img src={emailIcon} alt="" />
            &nbsp;&nbsp;
            {email}
          </EmailSection>
        )}
        <PhoneSection
          onProspectList={true}
          partialUser={partialUser}
          fullUser={fullUser}
          fetchProspect={fetchProspect}
        />
      </div>
      {!hasTalentMarketplaceSource && (
        <ConvertButton data-convert-button onClick={openProspectConversionModal}>
          Convert to Candidate
        </ConvertButton>
      )}
    </div>
  );
};

export default ApplicantDetails;

const ConvertButton = styled(Button)`
  height: 40px;
  width: 170px;
  margin-top: 3px;

  font-weight: bold;

  @media screen and (min-width: 1200px) {
    justify-self: end;
  }
`;

const EmailSection = styled.div`
  margin-bottom: 10px;
`;
