import { Component } from 'react';

import PropTypes from 'prop-types';

export default class CustomFieldModalLineItem extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    permission: PropTypes.oneOf(['View only', 'View & edit']).isRequired,
    removeLineItem: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="edit-schools-modal-school-row">
        <div className="edit-schools-school-name">{this.props.label}</div>
        <span>{this.props.permission}</span>
        <div
          className="jobedit-question-delete remove-line-item"
          onClick={() => this.props.removeLineItem(this.props.id)}
        >
          <span className="Select-clear">×</span>
        </div>
      </div>
    );
  }
}
