import { Input } from 'sharedComponents/Input';
import { InputAdornment, Theme, useTheme, useMediaQuery } from '@mui/material';
import { InputStyles, StyledSearchIcon } from 'features/StateJobBoard/StateJobBoardFilterContainer';
import { MobileFilterInterface } from './types';
import { BodyContainer } from './utils';
import { searchLabelText } from './constants';
import { getSearchPlaceholderText } from 'features/StateJobBoard/utils';

export const MobileKeywordSearchFilterBody: React.FC<MobileFilterInterface> = ({
  searchTerm,
  handleKeywordChange,
  dataTestId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <BodyContainer>
      <Input
        label={searchLabelText}
        variant="outlined"
        size="medium"
        placeholder={getSearchPlaceholderText(isMobile)}
        value={searchTerm}
        onChange={handleKeywordChange}
        dataTestId={dataTestId}
        sx={InputStyles(theme)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </BodyContainer>
  );
};
