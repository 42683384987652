import { AltModal } from 'ui-kit';
import { ReferenceViewerActivityFeed } from './ReferenceViewerActivityFeed';
import { ReferenceModalActivityFeedProps } from './types.js';

export const ReferenceModalActivityFeed: React.FC<ReferenceModalActivityFeedProps> = ({
  isOpen,
  onClose,
  candidate,
  selectedReference,
}) => {
  return (
    <AltModal.AltModal isOpen={isOpen} onClose={onClose}>
      <AltModal.Title>
        <div style={{ display: 'inline-block' }}>
          <h3>
            Reference for {candidate.first_name} {candidate.last_name}
          </h3>
        </div>
      </AltModal.Title>
      <ReferenceViewerActivityFeed onBack={onClose} referenceCheck={selectedReference} />
    </AltModal.AltModal>
  );
};
