import styled from 'styled-components';
import { roleStatusByValue } from '../../../utils/enums';
import DeleteModal from '../../../components/JobEdit/DeleteModal';
import { FooterProps } from '../types/types';
import { Link } from 'react-router-dom';
import { GreyActionButton, SubmitButton } from 'components/JobEdit';

export default function Footer({
  job,
  activelySubmitting,
  setDeleteRoleModalOpen,
  deleteRoleModalOpen,
  onSaveDeletion,
  saveDraft,
  publishJob,
}: FooterProps): React.ReactElement {
  const isTemplate = job.is_template;

  return (
    <div className="card mt2 jobedit-header-card bottom">
      <ActionButtonsContainer>
        {job.status === roleStatusByValue.draft ? (
          <DeleteButton onClick={() => setDeleteRoleModalOpen(true)}>
            {isTemplate ? 'Delete Template' : 'Delete Draft'}
          </DeleteButton>
        ) : (
          <div />
        )}
        {deleteRoleModalOpen && (
          <DeleteModal
            show={deleteRoleModalOpen}
            onHide={() => setDeleteRoleModalOpen(false)}
            onSave={onSaveDeletion}
            title={'Confirm'}
            message={`Are you sure you want to delete this ${isTemplate ? 'template' : 'posting'}?`}
            cancelLabel={'Cancel'}
            submitLabel={'Delete'}
          />
        )}
        <RightButtons>
          <Link to="/district/jobslist">
            <CancelButton disabled={activelySubmitting}>Cancel</CancelButton>
          </Link>
          {!isTemplate && (
            <>
              <GreyActionButton
                disabled={activelySubmitting}
                onClick={saveDraft}
                style={{ marginRight: '16px' }}
              >
                Save as Draft
              </GreyActionButton>
              <SubmitButton onClick={publishJob} disabled={activelySubmitting}>
                Save & Preview
              </SubmitButton>
            </>
          )}
          {isTemplate && (
            <SubmitButton onClick={saveDraft} disabled={activelySubmitting}>
              Save
            </SubmitButton>
          )}
        </RightButtons>
      </ActionButtonsContainer>
    </div>
  );
}

const DeleteButton = styled(GreyActionButton)`
  margin-right: 0;
  background-color: #ef5675;
`;

const CancelButton = styled(GreyActionButton)`
  background-color: #ffffff;
  color: #777777;
  padding: 0;
  margin-right: 16px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  margin: auto;
  flex-direction: reverse-row;
  justify-content: space-between;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
