import { Typography } from '@mui/material';
import { ModalContainer } from '../Modal/styles';
import React from 'react';
import { ServerErrorModalDataTestIds } from './constants';

export const ServerErrorModal = (): React.JSX.Element => {
  return (
    <ModalContainer data-testid={ServerErrorModalDataTestIds.ModalContainer}>
      <Typography variant="h6">{'There was a problem communicating with the server.'}</Typography>
      <br />
      <Typography variant="body1">
        {'Please try again in a few minutes or contact support@hirenimble.com for assistance.'}
      </Typography>
    </ModalContainer>
  );
};
