import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import ApplicationsNote from './ApplicationsNote';
import SchoolapplicationsNote from './SchoolapplicationsNote';
import SourceSection from 'components/CandidatesList/QuickProfile/SourceSection';
import ExternalInternalToggle from './ExternalInternalToggle';
import Languages from './Basics/Languages';

import { appStatusType } from 'utils/enums';
import { BulletPoint } from 'ui-kit/icons';
import { formatPhoneNumber } from 'utils/util';
import auth from 'utils/auth';

import draftApplicationPic from 'assets/draft-application.png';
import { nimbleTheme } from 'theme';
import { HeaderLabel } from './styles';
import { DefaultContainer, PrintContainer } from 'features/PrintView/styles';

export default function OverviewSection({
  isDistrictAdmin,
  isDistrictUser,
  user,
  application,
  schoolapplications,
  districtRole,
  newApplicationStatuses,
  updateFullUser,
  replaceCandidateInState,
  fetchEvents,
  isDraft,
  candidateSources,
}) {
  let primary_phone_number = null;
  let secondary_phone_number = null;

  if (user.profile?.phone_cell) {
    primary_phone_number = user.profile.phone_cell;
    secondary_phone_number = user.profile.phone_work || user.district_phone;
  } else if (user.profile?.phone_work) {
    primary_phone_number = user.profile.phone_work;
    secondary_phone_number = user.district_phone;
  } else if (user.district_phone) {
    primary_phone_number = user.district_phone;
  }

  if (primary_phone_number) {
    primary_phone_number = formatPhoneNumber(primary_phone_number);
  }

  if (secondary_phone_number) {
    secondary_phone_number = formatPhoneNumber(secondary_phone_number);
  } else {
    secondary_phone_number = 'Not provided';
  }

  const getContactInfo = () => {
    if (primary_phone_number) {
      return [primary_phone_number, user.email].join(', ');
    } else {
      return user.email;
    }
  };

  const address = `
    ${user.profile.address_street}, ${user.profile.address_city},${' '}
    ${user.profile.address_state} ${user.profile.address_zip}
  `;

  return (
    <div style={{ paddingTop: 0 }}>
      <HeaderLabel>Overview</HeaderLabel>

      {user.applications.length > 0 && (
        <ApplicationsNote userFirstName={user.first_name} applications={user.applications} />
      )}

      {[
        appStatusType.hiring_pool,
        appStatusType.post_hiring_pool,
        appStatusType.hired,
        appStatusType.onboarding,
      ].includes(application.new_status.status_type) && (
        <SchoolapplicationsNote
          user={user}
          role={districtRole}
          replaceCandidateInState={replaceCandidateInState}
          fetchEvents={fetchEvents}
          schoolapplications={schoolapplications}
          newApplicationStatuses={newApplicationStatuses}
          isEditable={isDistrictUser}
        />
      )}
      <GridContainer>
        <LineItem>
          <BulletPoint />
          <p>
            <b>{user.profile.years_experience} years</b> of experience
          </p>
        </LineItem>
        <LineItem>
          <BulletPoint />
          <p>
            <b>Address: </b>
            {address}
          </p>
        </LineItem>
        <LineItem>
          <BulletPoint />
          <div>
            <b style={{ marginRight: '4px' }}>Contact:</b>
            <DefaultContainer>{getContactInfo()}</DefaultContainer>

            <PrintContainer>
              {primary_phone_number && <li>{primary_phone_number}</li>}
              <li>{user.email}</li>
            </PrintContainer>
          </div>
        </LineItem>
        <LineItem>
          <BulletPoint />
          <p>
            <b>Secondary phone: </b>
            {secondary_phone_number}
          </p>
        </LineItem>
        <LineItem>
          <BulletPoint />
          <SourceContainer className="flex">
            {isDistrictAdmin ? (
              <SourceSection
                fullUser={user}
                onProspectList={false}
                updateFullUser={updateFullUser}
                sources={candidateSources}
              />
            ) : (
              <>
                <b style={{ marginRight: '4px' }}>Source:</b>
                <p>
                  {user.district_source && user.district_source !== 'none'
                    ? user.district_source.label
                    : 'None'}
                </p>
              </>
            )}
          </SourceContainer>
        </LineItem>
        <LineItem>
          <BulletPoint />
          <p>
            <b>Languages: </b>
            {user.languages.length > 0 ? <Languages languages={user.languages} /> : ' None'}
          </p>
        </LineItem>
        <LineItem>
          <BulletPoint />
          <ExternalInternalToggle
            user={user}
            district={auth.getUser().profile.district}
            replaceCandidateInState={replaceCandidateInState}
            fetchEvents={fetchEvents}
          />
        </LineItem>
        <LineItem>
          <BulletPoint />
          <p>
            <b>Current or former employee: </b>
            {application.prior_district_employee ? 'Yes' : 'No'}
          </p>
        </LineItem>
        {user.next_interview && (
          <LineItem>
            <BulletPoint />
            <div>
              <span>
                Signed up for{' '}
                <span className="underline" data-tip data-for="interview-time">
                  interview
                </span>{' '}
                via {user.next_interview.integration_label}
              </span>
              <ReactTooltip id="interview-time" effect="solid">
                {moment(user.next_interview.when).format('dddd, MMM D [at] h:mm a')}
              </ReactTooltip>
            </div>
          </LineItem>
        )}
        <LineItem>
          <BulletPoint />
          <p>
            <b>Candidate-reported source:</b> {application.source?.label ?? 'None'}
          </p>
        </LineItem>
      </GridContainer>

      {isDraft && (
        <div className="draft-application-div">
          <img src={draftApplicationPic} alt="draft application" />
          <p>Looks like this candidate is still working on their application.</p>
        </div>
      )}
    </div>
  );
}

const GridContainer = styled.div`
  margin-top: 18px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 10px;
  @media print {
    font-size: ${nimbleTheme.typography.print.fontSize};
    grid-column-gap: 0;
    grid-row-gap: 0;
    line-height: ${nimbleTheme.spacing(2.5)};
  }
`;

const LineItem = styled.div`
  color: #777777 !important;
  font-weight: 400;
  min-height: 22px;

  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 12px;
  align-items: baseline;
`;

const SourceContainer = styled.div`
  @media print {
    font-size: ${nimbleTheme.typography.print.fontSize};
  }
`;
