import './copyappmodal.css';

import { Component } from 'react';
import { StyledSelect } from 'ui-kit';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import styled from 'styled-components';

import { appStatusType, colorMapping } from '../../../utils/enums';
import ErrorText from '../../errortext';
import Tooltip from '../../../assets/tooltip.svg';
import LoadingSpinner from '../../loadingSpinner';

export default class CopyAppModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      districtRoles: [],
      applicationsToCreate: [
        {
          target_status: '',
          color: '',
          target_role_id: '',
          statuses_available: [],
        },
      ],
      error: '',
      keepApp: true,
      draftApplications: [],
      submitting: false,
    };
  }

  static propTypes = {
    application: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    updateCandidatePostSave: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const url = `/api/copy-application/available-roles/?candidate_id=${this.props.user.id}`;
    axios.get(url).then(({ data }) => this.setState({ districtRoles: data }));
  }

  getNewApplication = () => {
    return {
      target_status: this.props.application.new_status.id,
      color: this.props.application.new_status.color,
      target_role_id: '',
    };
  };

  handleChange = (selectedItem, index, name) => {
    const applicationsToCreate = this.state.applicationsToCreate;
    applicationsToCreate[index][name] = selectedItem?.value.toString() ?? '';
    if (selectedItem) {
      axios.get(`/api/role/${selectedItem.value}`).then(({ data }) => {
        applicationsToCreate[index]['statuses_available'] = data.statuses_available;
        applicationsToCreate[index]['target_status'] = data.statuses_available[0]?.id || '';
        applicationsToCreate[index]['color'] = data.statuses_available[0]?.color || '';
        this.setState({
          applicationsToCreate,
          error: '',
        });
      });
    } else {
      this.setState({
        applicationsToCreate,
        error: '',
      });
    }
  };

  handleStatusChange = (e, index) => {
    let applicationsToCreate = this.state.applicationsToCreate;
    const statusObj = this.state.applicationsToCreate[index].statuses_available.find(
      (s) => s.id === Number(e.target.value)
    );
    applicationsToCreate[index].target_status = statusObj.id;
    applicationsToCreate[index].color = statusObj.color;
    this.setState({
      applicationsToCreate,
      error: '',
    });
  };

  removeLineItem = (index) => {
    const applicationsToCreate = this.state.applicationsToCreate;
    applicationsToCreate.splice(index, 1);
    this.setState({ applicationsToCreate });
  };

  addNewApplication = () => {
    const applicationsToCreate = this.state.applicationsToCreate;
    applicationsToCreate.push(this.getNewApplication());
    this.setState({ applicationsToCreate });
  };

  onSave = () => {
    try {
      this.checkForErrors();
    } catch (error) {
      this.setState({
        error: error.message,
      });
      return;
    }

    const drafts = this.getExistingDrafts();
    if (drafts.length > 0) {
      this.setState({
        draftApplications: drafts,
      });
      return;
    }

    this.submitApplications();
  };

  checkForErrors = () => {
    const applicationsToCreate = [...this.state.applicationsToCreate];
    const uniqueSet = new Set();

    applicationsToCreate.forEach((application) => {
      if (application.target_role_id === '') {
        throw new Error('Please select a target role for each line item.');
      }
      if (uniqueSet.has(application.target_role_id)) {
        throw new Error('Please ensure the target roles are unique.');
      }
      uniqueSet.add(application.target_role_id);
    });
  };

  getExistingDrafts = () => {
    const drafts = [];

    // Loop over applications to create and check if the candidate already
    // has a draft application for any of the prospective roles. If they
    // do, push in the role's title so we can show a confirmation message
    // that they are going to overwrite that role.
    this.state.applicationsToCreate.forEach((appToCreate) => {
      this.state.districtRoles
        .filter((role) => role.draft_application_exists)
        .forEach((r) => {
          if (r.id === Number(appToCreate.target_role_id)) {
            drafts.push(r.title);
          }
        });
    });

    return drafts;
  };

  submitApplications = () => {
    this.setState({ submitting: true });
    const applicationsToCreate = [...this.state.applicationsToCreate];
    const applicationsPosts = [];

    for (let i = 0; i < applicationsToCreate.length; i++) {
      applicationsToCreate[i].keep_app = this.state.keepApp;
      applicationsPosts.push(
        axios.post(
          `/api/applications/${this.props.application.id}/duplicate_application/`,
          applicationsToCreate[i]
        )
      );
    }

    axios
      .all(applicationsPosts)
      .then(() => {
        this.props.updateCandidatePostSave();
        this.props.onHide();
      })
      .catch((err) => {
        if (err.response?.data?.detail) {
          this.setState({
            error: err.response.data.detail,
            submitting: false,
          });
        } else {
          this.setState({
            error: 'There was an error creating the application(s)',
            submitting: false,
          });
        }
        throw new Error(err);
      });
  };

  toggleKeepApp = () => {
    const keepApp = !this.state.keepApp;
    this.setState({ keepApp });
  };

  districtRolesOptions = () => {
    return this.state.districtRoles.map((role) => {
      return {
        label: role.title,
        value: role.id,
      };
    });
  };

  overwriteDraftConfirmationSection = () => (
    <BodyContainer>
      <DraftConfirmationText>
        Candidate already has a draft for the following role(s):
      </DraftConfirmationText>
      <DraftList>
        {this.state.draftApplications.map((title, index) => (
          <li key={index}>{title}</li>
        ))}
      </DraftList>
      <DraftConfirmationUnderlined>
        This will override their existing draft(s).
      </DraftConfirmationUnderlined>
      <FooterContainer>
        <ModalButton onClick={this.onBack}>Back</ModalButton>
        <TextCenterFlexDiv>
          {this.state.submitting && <LoadingSpinner margin={0} fontSize={3} />}
          {this.state.error && (
            <ErrorTextContainer>
              <ErrorText message={this.state.error} />
            </ErrorTextContainer>
          )}
          <ModalSaveButton onClick={this.submitApplications} disabled={this.state.submitting}>
            Continue
          </ModalSaveButton>
        </TextCenterFlexDiv>
      </FooterContainer>
    </BodyContainer>
  );

  onBack = () => {
    this.setState({ draftApplications: [] });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="copy-app-modal"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="copy-app-modal-title">
            <h3>Copy Application</h3>
          </div>
          <span className="copy-app-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
          {this.state.draftApplications.length > 0 ? (
            this.overwriteDraftConfirmationSection()
          ) : (
            <div className="copy-app-modal-section">
              {this.state.applicationsToCreate.map((item, index) => (
                <div className="flex mt1 mb1 ml1 mr1" key={index}>
                  <div className="select-form-field copy-app-select-role mt1px">
                    <StyledSelect
                      boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                      className="flex-1"
                      options={this.districtRolesOptions()}
                      name="target_role_id"
                      placeholder="Type or select job title"
                      onChange={(value) => this.handleChange(value, index, 'target_role_id')}
                      isClearable
                    />
                  </div>
                  <div
                    className={`select-form-field jobedit-select-div copy-app-select-status ${
                      item.target_role_id === '' ? '#eeeff2' : colorMapping[item.color]
                    }`}
                  >
                    <span
                      className={`status-circle ${
                        item.target_role_id === '' ? '#eeeff2' : colorMapping[item.color]
                      } status-color`}
                      style={{ marginBottom: '-1px' }}
                    />
                    <select
                      type="text"
                      name="target_status"
                      value={item.target_status}
                      disabled={item.target_role_id === ''}
                      onChange={(e) => this.handleStatusChange(e, index)}
                    >
                      {item.target_role_id === '' ? (
                        <option key={0} value={false}>
                          Select status
                        </option>
                      ) : (
                        <>
                          {item.statuses_available.map(
                            (statusObj, i) =>
                              statusObj.status_type !== appStatusType.draft && (
                                <option key={i} value={statusObj.id}>
                                  {statusObj.label}
                                </option>
                              )
                          )}
                        </>
                      )}
                    </select>
                  </div>
                  {this.state.applicationsToCreate.length > 1 && (
                    <RemoveButton onClick={() => this.removeLineItem(index)}>×</RemoveButton>
                  )}
                </div>
              ))}
              <div className="copy-app-flex-div mb1 mr1">
                <button className="copy-app-add-btn" onClick={this.addNewApplication}>
                  + Add role
                </button>
              </div>
              <div className="ml1">
                {this.state.error && <ErrorText message={this.state.error} />}
              </div>
              <button
                className="copy-app-modal-button copy-app-modal-save"
                onClick={this.onSave}
                disabled={this.state.submitting}
              >
                {this.state.submitting ? (
                  <LoadingSpinner margin={0} fontSize={1} />
                ) : (
                  'Copy Application'
                )}
              </button>
              <div className="copy-app-keep-div">
                <label className="copy-app-keep-label">
                  <input
                    type="checkbox"
                    style={{ margin: '1em 0.5em 1em 1em' }}
                    name="keepApp"
                    value={this.state.keepApp}
                    checked={this.state.keepApp}
                    onChange={this.toggleKeepApp}
                  />
                  keep current application
                </label>
                <img
                  className="copy-app-tooltip"
                  src={Tooltip}
                  alt="tooltip"
                  data-tip
                  data-for="copy-app-keep"
                />
                <ReactTooltip id="copy-app-keep" effect="solid">
                  <span>
                    If you uncheck this box, the original application will be archived and replaced
                    with the application(s) identified above.
                  </span>
                </ReactTooltip>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const BodyContainer = styled.div`
  background-color: #f8f8f8;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 25px;
  padding: 30px 34px;
`;

const DraftConfirmationText = styled.p`
  font-size: 15px;
  font-weight: bold;
`;

const DraftList = styled.ul`
  margin: 10px 20px 24px;

  li {
    padding: 4px 0;
  }
`;

const DraftConfirmationUnderlined = styled(DraftConfirmationText)`
  text-decoration: underline;
`;

const FooterContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ModalButton = styled.button`
  height: 50px;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0px 42px;
  color: #c2c2c2;
  font-size: 16px;
`;

const ModalSaveButton = styled(ModalButton)`
  background-color: #00b88d;
  color: white;
  border: none;
`;

const TextCenterFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorTextContainer = styled.div`
  margin-right: 1rem;
`;

const RemoveButton = styled.button`
  height: 51px;
  margin-left: 8px;
  background-color: white;
  color: var(--lightgray);
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 8%);
  border-radius: 3px;
  padding: 15px 17px;
  border: 1px solid;
  font-size: 22px;
  line-height: 19px;
`;
