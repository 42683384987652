import { StyledTextButton } from './styles';
import { ButtonProps } from './types';

export const TextButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  sx,
  size,
  endIcon,
  startIcon,
  dataTestId,
}) => {
  return (
    <StyledTextButton
      disabled={disabled}
      href={href}
      variant="text"
      onClick={onClick}
      size={size}
      sx={sx}
      data-testid={dataTestId || 'text-button'}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {children}
    </StyledTextButton>
  );
};
