import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingSpinner from '../loadingSpinner';

import closeIcon from '../../assets/icon-close-x-dark.svg';
import axios from 'axios';

export default class BulkExport extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHideExport: PropTypes.func.isRequired,
    selections: PropTypes.any.isRequired,
    deselectAll: PropTypes.func.isRequired,
    fetchAllCandidates: PropTypes.func.isRequired,
    fetchAllProspects: PropTypes.func.isRequired,
    onProspectList: PropTypes.bool.isRequired,
    getUser: PropTypes.object.isRequired,
    filterApplicationsByRole: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingSpinner: false,
      format: 'xls',
      exportOptions: {
        name: false,
        email: false,
        phone: false,
        basic_info: false,
        source: false,
        status: false,
        work_experience: false,
        education: false,
        credentials: false,
        languages: false,
        exams: false,
        certificates: false,
        references: false,
        questions: false,
        preference: false,
        all: false,
      },
      success: false,
    };
  }

  // Deprecated, user_ids are now provided in props
  fetchAllCandidates = async (callback) => {};

  onCancel = () => {
    this.props.onHideExport();
  };

  onExport = () => {
    const user_ids_param = Array.from(this.props.selections);
    const selected_fields = Object.keys(this.state.exportOptions);

    axios
      .post('/api/user/get_users_for_bulk_export/', {
        user_ids: user_ids_param,
        prospect_export: this.props.onProspectList,
        selected_fields: selected_fields,
      })
      .then((r) => {
        this.setState({ success: true });
      });
  };

  onSuccess = () => {
    this.props.onHideExport();
  };

  /* Change the data that will be exported as the user changes desired fields */
  changeExportSelection = (e) => {
    const newState = this.state.exportOptions;

    if (e === 'all') {
      if (this.props.onProspectList) {
        // for prospect list export, 'all' means name and email.
        ['name', 'email', 'all'].forEach((key) => {
          newState[key] = newState.all ? false : true;
        });
      } else {
        Object.keys(newState).forEach((key) => {
          newState[key] = newState.all ? false : true;
        });
      }
      this.setState({ exportOptions: newState });
    } else {
      const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      newState[name] = value;
      this.setState({ exportOptions: newState });
    }
  };

  render() {
    // let candidateData = [];

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHideExport}
        bsSize="large"
        dialogClassName="bulk-email-modal bulk-export"
      >
        <Modal.Header />
        {this.state.loadingSpinner || this.state.success ? (
          <Modal.Body>
            {this.state.loadingSpinner && <LoadingSpinner />}
            {this.state.success && (
              <div className="bulk-email-success-div">
                <h2>Your export was successfully requested.</h2>
                <p>You will receive an email shortly...</p>
                <button
                  className="bulk-email-modal-button bulk-email-modal-cancel"
                  onClick={this.onSuccess}
                >
                  Close
                </button>
              </div>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="bulk-email-modal-title">
              <h4>
                {`Export Data for ${this.props.selections.size} ${
                  this.props.onProspectList ? 'Prospect' : 'Candidate'
                }${this.props.selections.size === 1 ? '' : 's'}`}
              </h4>
              <span className="pointer" onClick={this.props.onHideExport}>
                <img src={closeIcon} alt="close button" />
              </span>
            </div>

            <div className="export-modal-body">
              <h4 className="inline-block">Export Options</h4>
              <div
                className="inline-block pointer"
                onClick={() => this.changeExportSelection('all')}
              >
                Select All
              </div>
              <div className="export-options-container flex">
                <div className="flex-column-container left mr1">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="name"
                        checked={this.state.exportOptions.name}
                        onChange={this.changeExportSelection}
                      />
                      Name
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="email"
                        checked={this.state.exportOptions.email}
                        onChange={this.changeExportSelection}
                      />
                      Email
                    </label>
                  </div>
                  {!this.props.onProspectList && (
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="phone"
                          checked={this.state.exportOptions.phone}
                          onChange={this.changeExportSelection}
                        />
                        Phone
                      </label>
                    </div>
                  )}
                  {!this.props.onProspectList && (
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="basic_info"
                          checked={this.state.exportOptions.basic_info}
                          onChange={this.changeExportSelection}
                        />
                        Basic info
                      </label>
                    </div>
                  )}
                </div>

                {!this.props.onProspectList && (
                  <div className="flex-column-container mr1">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="work_experience"
                          checked={this.state.exportOptions.work_experience}
                          onChange={this.changeExportSelection}
                        />
                        Work experience
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="education"
                          checked={this.state.exportOptions.education}
                          onChange={this.changeExportSelection}
                        />
                        Education
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="credentials"
                          checked={this.state.exportOptions.credentials}
                          onChange={this.changeExportSelection}
                        />
                        Credentials
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="languages"
                          checked={this.state.exportOptions.languages}
                          onChange={this.changeExportSelection}
                        />
                        Languages
                      </label>
                    </div>
                  </div>
                )}
                {!this.props.onProspectList && (
                  <div className="flex-column-container">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="exams"
                          checked={this.state.exportOptions.exams}
                          onChange={this.changeExportSelection}
                        />
                        Exams
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="certificates"
                          checked={this.state.exportOptions.certificates}
                          onChange={this.changeExportSelection}
                        />
                        Certificates
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="references"
                          checked={this.state.exportOptions.references}
                          onChange={this.changeExportSelection}
                        />
                        References
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="questions"
                          checked={this.state.exportOptions.questions}
                          onChange={this.changeExportSelection}
                        />
                        Application Questions
                      </label>
                    </div>
                  </div>
                )}
                {!this.props.onProspectList && (
                  <div className="flex-column-container last">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="preference"
                          checked={this.state.exportOptions.preference}
                          onChange={this.changeExportSelection}
                        />
                        Candidate preferences
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="bulk-email-buttons-div">
              <button
                className="bulk-email-modal-button bulk-email-modal-cancel"
                onClick={this.onCancel}
              >
                Cancel
              </button>

              <button className="bulk-email-modal-button" onClick={this.onExport}>
                Request export
              </button>

              {/* <DownloadXls
                dataSet={candidateData}
                exportOptions={this.state.exportOptions}
                fileExtensionType="xlsx"
                fetchAllCandidates={this.fetchAllCandidates}
                onProspectList={this.props.onProspectList}
                filterApplicationsByRole={this.props.filterApplicationsByRole}
              /> */}
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}
