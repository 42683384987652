import { BaseNimbleAPI } from './baseAPI';
import { AxiosResponse } from 'axios';

interface CandidateSourceParams {
  district_id: string;
}

class CandidateAPI extends BaseNimbleAPI {
  async getCandidateSources(params: CandidateSourceParams) {
    const url = `/api/candidate-source/`;
    const response: AxiosResponse = await this.get(url, params);
    return response.data;
  }

  async getCandidateSource(sourceId: string, queryParams: CandidateSourceParams) {
    const response = await this.get(`/api/candidate-source/${sourceId}/`, {
      params: { ...queryParams },
    });
    return response.data;
  }
}

export default new CandidateAPI();
