import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { USStates } from '../../utils/enums';

import PropTypes from 'prop-types';
import ErrorText from '../errortext';

import IconCaretDown from '../../assets/icon-down-caret-grey.svg';

export default class DistrictAccountModal extends Component {
  static propTypes = {
    district: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };
  static defaultProps = { district: {} };
  state = { district: {} };

  componentDidMount() {
    this.setState({
      district: this.props.district,
    });
  }

  handleSubmit = () => {
    this.props.onSave(this.state.district);
    return false;
  };

  setDistrictState = (k, v) => {
    let newDistrict = Object.assign({}, this.state.district);
    newDistrict[k] = v;
    this.setState({ district: newDistrict });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Modal show={this.props.displayModal} bsSize="large" dialogClassName="account-modal">
          <Modal.Header>
            <h4>District Account</h4>
          </Modal.Header>
          <Modal.Body>
            <ErrorText message={this.props.errors.non_field_errors} />
            <h4 className="section-title">District Name</h4>
            <div className="account-modal-dropshadow">
              <input
                className="account-modal-input"
                name="name"
                type="text"
                placeholder="District Name"
                required
                value={this.state.district.name}
                onChange={(event) => this.setDistrictState('name', event.target.value)}
              />
              <ErrorText message={this.props.errors.name} />
            </div>

            <h4 className="section-title">City</h4>
            <div className="account-modal-dropshadow">
              <input
                className="account-modal-input"
                name="city"
                type="text"
                placeholder="City"
                value={this.state.district.city}
                onChange={(event) => this.setDistrictState('city', event.target.value)}
              />
              <ErrorText message={this.props.errors.location} />
            </div>

            <h4 className="section-title">State</h4>
            <div className="account-modal-dropshadow">
              <select
                name="state"
                value={this.state.district.state}
                onChange={(event) => this.setDistrictState('state', event.target.value)}
                required
              >
                <option value="">State</option>
                {USStates().map((s) => (
                  <option key={s.value} value={s.label}>
                    {s.label}
                  </option>
                ))}
              </select>
              <img src={IconCaretDown} alt="" />
            </div>
            <div className="action-buttons-div">
              <button className="cancel" onClick={this.props.onHide}>
                Cancel
              </button>
              <button className="save" onClick={this.handleSubmit}>
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    );
  }
}
