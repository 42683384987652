import styled from 'styled-components';

import { ReactComponent as GrayArrow } from '../assets/icon_right_caret_grey.svg';
import { useHistory } from 'react-router-dom';

export default function BackButton({ buttonText }) {
  const history = useHistory();

  return (
    <BackButtonContainer onClick={() => history.goBack}>
      <BackButtonIcon />
      {buttonText}
    </BackButtonContainer>
  );
}

const BackButtonContainer = styled.div`
  color: var(--darkgray) !important;
  font-size: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const BackButtonIcon = styled(GrayArrow)`
  transform: rotate(180deg);
  margin-right: 7px;
`;
