import { BaseNimbleAPI } from './baseAPI';

class UsersAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/user' };
  }

  // @TODO: This endpoint seems to not be in use anymore.
  async getAllApplications(user_id) {
    const response = await this.get(`/${user_id}/get_all_applications/`);
    return response.data;
  }

  async getApplicationsForScorecards(user_id) {
    const response = await this.get(`/${user_id}/get_all_applications_for_scorecards/`);
    return response.data;
  }

  async getDefaultScorecardId(userId) {
    const url = `/${userId}/get_scorecard_default/`;
    const response = await this.get(url);
    return response.data.scorecard_default_id;
  }

  async updateDefaultScorecardId(userId, defaultScorecardId = null) {
    const url = `/${userId}/`;
    const data = { scorecard_default_id: defaultScorecardId };
    return this.patch(url, data);
  }

  async getDefaultReferenceTemplateId(userId) {
    const url = `/${userId}/get_reference_default/`;
    const response = await this.get(url);
    return response.data.reference_template_default_id;
  }

  async updateDefaultReferenceTemplateId(userId, defaultReferenceTemplateId = null) {
    const url = `/${userId}/`;
    const data = { reference_template_default_id: defaultReferenceTemplateId };
    return this.patch(url, data);
  }

  async fetchUser(userId, params = {}) {
    const url = `/${userId}/`;
    const response = await this.get(url, {
      params: { ...params },
    });
    return response.data;
  }

  async updateUser(userId, data) {
    const url = `/${userId}/`;
    const response = await this.patch(url, data);
    return response.data;
  }

  async updateUserDistrictSource(userId, internalSource) {
    const url = `/${userId}/update_district_source/`;
    const data = { internal_source: internalSource };
    const response = await this.patch(url, data);
    return response.data;
  }

  async getCandidateInfo(userId) {
    const url = `/${userId}/get_candidate_info/`;
    const response = await this.get(url);
    return response.data;
  }

  async fetchReferenceChecks(userId) {
    const url = `/${userId}/get_reference_checks/`;
    const response = await this.get(url);
    return response.data;
  }

  async fetchScorecards(userId) {
    const url = `/${userId}/get_scorecards/`;
    const response = await this.get(url);
    return response.data;
  }

  async setInternalExternal(candidate) {
    const url = `/${candidate.id}/internal_external/`;
    const district_id = candidate.profile.district_id;
    const response = await this.patch(url, { district_id });
    return response.data;
  }

  async setDefaultSearchParams(userId, searchParams) {
    const url = `/${userId}/set_default_search_params/`;
    const response = await this.post(url, searchParams);
    return response.data;
  }
}

export default new UsersAPI();
