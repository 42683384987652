import styled from 'styled-components';
import { layout } from 'styled-system';

const QuestionSetContainer = styled.div`
  padding: 0 48px 180px;
  max-width: 1200px;
  margin: auto;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  margin-bottom: 8px;
  margin-top: 28px;
`;

const GreyText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const ActionButtonsContainer = styled.div`
  display: grid;
  grid-row-gap: 12px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr minmax(258px, 1fr);
    grid-column-gap: 12px;
  }
`;

const ActionButton = styled.button`
  ${layout}

  height: 50px !important;
  box-shadow: none !important;
  font-size: 16px;
  border-radius: 3px;
`;

const DeleteButton = styled(ActionButton)`
  color: #ef5675 !important;
  background-color: #ffffff !important;
  border: 1px solid #ef5675 !important;
`;

const SaveButton = styled(ActionButton)`
  color: white !important;
  background-color: #00b88d !important;
  border: none !important;
`;

const SubmitButtonContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-self: end;
    grid-column-start: 2;
  }
`;

const ErrorTextStyled = styled.p`
  color: #ff7700;
  min-height: 1.5rem;
  margin-right: 1rem;
`;

const ModalBodySectionHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #444444;

  margin-bottom: 6px;
`;

const QuestionSetActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr 160px;

  & > button:last-child {
    grid-column-start: 3;
  }
`;

export {
  HeaderText,
  GreyText,
  ActionButtonsContainer,
  DeleteButton,
  SaveButton,
  SubmitButtonContainer,
  ErrorTextStyled,
  QuestionSetContainer,
  ModalBodySectionHeader,
  QuestionSetActionsContainer,
};
