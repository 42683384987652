import moment from 'moment';
import _ from 'lodash';

import checkSM from 'assets/icons/ActivityFeedIcons/checkmark.svg';
import calendar from 'assets/icons/ActivityFeedIcons/calendar.svg';
import graph from 'assets/icons/ActivityFeedIcons/graph.svg';
import note from 'assets/icons/ActivityFeedIcons/notes2.svg';
import pencil from 'assets/icons/ActivityFeedIcons/pencil.svg';
import duplicateIcon from 'assets/icons/icon_duplicate.svg';
import templateIcon from 'assets/icons/template_icon.svg';
import email from 'assets/icons/ActivityFeedIcons/email.svg';
import applicationUpdate from 'assets/icons/ActivityFeedIcons/application_update.svg';
import reference from 'assets/icons/ActivityFeedIcons/reference.svg';
import profilePencil from 'assets/icons/ActivityFeedIcons/profile_edit.svg';
import uploadIcon from 'assets/icons/ActivityFeedIcons/upload.svg';

import {
  employmentTypePreferences,
  gradeMapping,
  grades,
  preferenceCategories,
  preferenceOptions,
} from './enums';
import schoolsAPI from 'api/schoolsAPI';

const preferenceMap = preferenceOptions().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

const preferenceCategoryMap = preferenceCategories().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

const employmentTypeMap = employmentTypePreferences().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

let gradesWithGroups = grades().slice();
gradesWithGroups.push({ value: 14, label: 'Elementary School' });
gradesWithGroups.push({ value: 15, label: 'Middle School' });
gradesWithGroups.push({ value: 16, label: 'High School' });

const gradeMap = gradesWithGroups.reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

/**
 * Parses a moment if it can, otherwise returns fallback. All arguments are strings.
 */
export function momentOr(content, format, fallback) {
  let m = moment(content);
  if (isNaN(m.valueOf())) return fallback;
  return m.format(format);
}

/**
 * Function to handle a click outside of a react component.
 *
 * @param node The toplevel node of the rendered react component, probably bound with something like
 * https://www.robinwieruch.de/react-ref-attribute-dom-node/. If there are any invalid input
 * elements inside this form, the given callback is not called.
 * @param clickEvent The click event generated by the browser
 * @param callback Something to call if the given click is indeed outside of the react node.
 */
export function handleOutsideClickOnValidForm(node, clickEvent, callback) {
  let incompleteInput = node && node.querySelector ? node.querySelector('input:invalid') : null;
  let incompleteSelect = node && node.querySelector ? node.querySelector('select:invalid') : null;
  // This could be more elegant
  if (
    incompleteInput !== null ||
    incompleteSelect !== null ||
    (node && node.contains && node.contains(clickEvent.target)) ||
    clickEvent.target.classList.contains('rdtMonth') ||
    clickEvent.target.classList.contains('rdtYear')
  ) {
    return;
  }
  callback();
}

/**
 * Update an item in a react component's state.
 *
 * The existense of this function is a code smell to me...but, the way the forms are currently
 * written, there is often an outer dict that keeps track of an in-progress form element, that
 * upon completion gets collapsed in to a card. This function actually eliminates a fair bit
 * of boilerplate.
 *
 * @param component The react component whose state to update.
 * @param field the object inside component.state to modify.
 * @param subfield the object inside field to modify.
 * @param value what to set subfield to.
 */
export function updateFormObject(component, field, subfield, value) {
  let obj = Object.assign({}, component.state[field]);
  obj[subfield] = value;
  let stateUpdateDict = {};
  stateUpdateDict[field] = obj;
  component.setState(stateUpdateDict);
}

export function updateDate(component, field, subfield, date) {
  if (date.toDate || date === '') {
    const updateObject = Object.assign({}, component.state[field]);
    if (subfield === 'external_date') {
      updateObject[subfield] = date ? moment(date).format('YYYY-MM-DD') : null;
    } else {
      updateObject[subfield] = date ? date.toDate() : null;
    }
    component.setState({ [field]: updateObject });
  } else {
    // TODO: display validation error
    return;
  }
}

/* Converts bytes to kilobytes (for resume and other field upload) */
export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/* Shorten file urls to a more readable format of just the last part of the path string */
export function fileNameShortener(url) {
  let resumeToShow = url.split('?')[0];
  resumeToShow = resumeToShow.split('/');
  return (resumeToShow = resumeToShow[resumeToShow.length - 1]);
}
/* Detect if user is using IE and judge them harshly :-)~ */
export function getInternetExplorerVersion() {
  var rv2 = -1;
  if (navigator.appName === 'Microsoft Internet Explorer') {
    let ua2 = navigator.userAgent;
    let re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
    if (re.exec(ua2) != null) rv2 = parseFloat(RegExp.$1);
  } else if (navigator.appName === 'Netscape') {
    let ua2 = navigator.userAgent;
    let re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
    if (re.exec(ua2) != null) rv2 = parseFloat(RegExp.$1);
  }
  return rv2;
}

export function isChrome() {
  return navigator.userAgent.indexOf('Chrome') !== -1 || !!window.chrome;
}

/** handy function to create a range (similar to Python's range() function) */
export function range(start, end) {
  return [...Array(1 + end - start).keys()].map((v) => start + v);
}

/* Get query string value using key */
export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
/* find furthest application, which can be either a school app or district app.
 * return the application, which is an object.
 */
export function furthestApplication(applications) {
  return _.maxBy(applications, 'status');
}

export function getAllSchoolsInDistrict() {
  return schoolsAPI
    .fetchActiveSchools()
    .then((allSchoolsInDistrict) => this.setState({ allSchoolsInDistrict }));
}

/*
 * Format a phone number in the way our users like
 */
export function formatPhoneNumber(number) {
  let finalString = '';
  if (number.length !== 10) return number;
  else {
    let i = number.length;
    while (i--) {
      finalString = number[i] + finalString;
      if (i === 3 || i === 6) {
        finalString = '-' + finalString;
      }
    }
  }
  return finalString;
}

export function checkInternal(user, district_id, set_state = true) {
  let internal_user = false;

  if (user && user.profile.district_id === district_id) {
    internal_user = true;
  } else if (user?.profile?.district?.id === district_id) {
    internal_user = true;
  } else if (this.props.location) {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const query = searchParams.get('internal');

    if (query && window.atob(query) === 'true') {
      internal_user = true;
    }
  }

  // internal_user = true;
  if (set_state) {
    this.setState({ isInternalCandidate: internal_user });
  }
  return internal_user;
}

/*
 ** Check if any of the card's fields is filled out; if so allow the user to submit
 */
export function isCardBlank(card) {
  let result = true;
  const keys = Object.keys(card);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let value = card[key];
    // If it's not references, which has a valid falsy 0 value
    if (!card.hasOwnProperty('years_known')) {
      value = value === 0 ? '0' : value;
    }
    if (value && key !== 'id' && Number(value) !== -1) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          result = false;
          break;
        }
      } else {
        result = false;
        break;
      }
    }
  }
  return result;
}

/*
 ** Check if it's the production environment
 */
export function isProductionEnvironment() {
  if (window.location.href.indexOf('app.hirenimble') !== -1) {
    return true;
  }
  return false;
}

export function isStagingEnvironment() {
  if (window.location.href.indexOf('dev.hirenimble') !== -1) {
    return true;
  }
  return false;
}

export function isPreviewEnvironment() {
  if (window.location.href.indexOf('demo.hirenimble') !== -1) {
    return true;
  }
  return false;
}

export function gradesArray(grades) {
  /** goal is to say "Middle School" if all middle school grades are selected, and same
   * for elementary and high school. First, add these grouped entries to the enum in this file,
   * then check to see if all are present in grades. If they are, remove the grades and add
   * the group. Example: [6,7,8] => [15] (15 is "Middle School"). Now it will render "Middle
   * School" instead of "Sixth Grade, Seventh Grade, Eighth Grade".
   */
  let jobGradesArray = grades.slice();
  let elementarySchool = gradeMapping().elementary.every((e) => jobGradesArray.includes(e));
  let middleSchool = gradeMapping().middle_school.every((e) => jobGradesArray.includes(e));
  let highSchool = gradeMapping().high_school.every((e) => jobGradesArray.includes(e));

  // remove individual elements and add group, if applicable.
  if (elementarySchool) {
    gradeMapping().elementary.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(14);
  }
  if (middleSchool) {
    gradeMapping().middle_school.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(15);
  }
  if (highSchool) {
    gradeMapping().high_school.forEach((g) => {
      jobGradesArray.splice(jobGradesArray.indexOf(g), 1);
    });
    jobGradesArray.push(16);
  }
  return jobGradesArray;
}

export function preferenceMapping(obj) {
  let jobGradesArray = obj.grades ? gradesArray(obj.grades) : [];

  let gradeStr = '';
  if (jobGradesArray.length > 0) {
    gradeStr = '';
    jobGradesArray.forEach((g) => {
      gradeStr = gradeStr + gradeMap[g] + ', ';
    });
    gradeStr = gradeStr.slice(0, gradeStr.length - 2);
  }

  let categoriesArray = obj.categories || [];
  let categoryStr = '';
  if (categoriesArray.length > 0) {
    categoryStr = '';
    categoriesArray.forEach((c) => {
      categoryStr = categoryStr + preferenceCategoryMap[c] + ', ';
    });
    categoryStr = categoryStr.slice(0, categoryStr.length - 2);
  }

  return {
    miles_within: {
      label: 'Geography',
      value: `Within ${obj.miles_within} miles of ${obj.location}`,
    },
    multilingual_school: {
      label: 'Prefer to work in a multilingual school',
      value: preferenceMap[obj.multilingual_school],
    },
    title_1_school: {
      label: 'Prefer to work in a title 1 school',
      value: preferenceMap[obj.title_1_school],
    },
    turnaround_school: {
      label: 'Prefer to work in a turnaround school',
      value: preferenceMap[obj.turnaround_school],
    },
    employment_type: {
      label: 'Schedule preference',
      value: employmentTypeMap[obj.employment_type],
    },
    hours_per_week: {
      label: 'Hours per week',
      value: obj.hours_per_week,
    },
    grades: {
      label: 'Preferred grade levels',
      value: gradeStr,
    },
    categories: {
      label: 'Preferred categories',
      value: categoryStr,
    },
  };
}

export function numberIsBetweenZeroAndOne(value) {
  return Number(value) >= 0 && Number(value) <= 1;
}

export function numberLengthIsLessThanThree(value) {
  return value.length <= 3;
}

/**
 * Stolen from https://stackoverflow.com/a/43467144/4661800
 *
 * Has some peculiarties, but they all do... eg/
 *  www.google.com is not valid URL (missing scheme)
 *  javascript:void(0) is valid URL, although not an HTTP one
 *  http://.. is valid URL, with the host being ..; whether it resolves depends on your DNS
 *  https://google..com is valid URL, same as above
 */
export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}
/**
 Makes sure all urls in an HTMLString are formatted correctly.
 */
export function makeAllUrlsValid(string) {
  let re = /<a\s[^>]*href\s*=\s*"((?!https?:\/\/)[^"]*)"[^>]*>/g;
  string = string.replace(re, '<a href="http://$1" rel="noopener noreferrer" target="_blank">');
  return string;
}

/**
 *
 * This is a pretty basic check, but it should be good enough for our purposes.
 */
export function isValidEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

/**
 * Generate a pseudo-random identifier.
 * Not as robust as a UUID, but it doesn't need external dependencies.
 */
export function generateId() {
  const array = new Uint8Array(16);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

/**
 * Takes a date string of type `formats` and parses a moment object.
 * Protip: always check if moment.isValid()
 */
export function parseMomentFromString(dateString, strict = true, formats = null) {
  const defaultFormats = ['MM/DD/YYYY', moment.ISO_8601];
  return moment(dateString, formats ?? defaultFormats, strict);
}

export const queryParams = {
  /**
   * I wanted to use filter names that are short and hard to decipher
   * and thus hard to change by users when looking at a URL.
   * We need this mapping because we don't always know which filter
   * the user is changing, but we do know the fieldname. So the
   * set and delete methods below translate the fieldname using this
   * mapping.
   */
  // candidates View All and View By Posting filter names
  // (except 'job_status_list' and 'grades' which are also
  // used on jobslist).
  status_list: 'f_CS',
  job_status_list: 'f_JS',
  job_subject_list: 'f_JSU',
  internal_list: 'f_IE',
  cred_list: 'f_C',
  task_list: 'f_T',
  hellosign_templates: 'f_HT',
  multilingual_school: 'f_MS',
  title_1_school: 'f_TI',
  turnaround_school: 'f_TS',
  language_list: 'f_L',
  grades: 'f_G',
  preference_categories: 'f_PC',
  employment_type_and_hours: 'f_ET',
  experienceStart: 'f_ES',
  experienceEnd: 'f_EE',
  selectedSchoolIds: 'f_SS',
  isOpenToOtherOptions: 'f_O',
  onboarding_locations: 'f_OL',
  tag_ids: 'f_TG',
  // jobslist filter names
  categories: 'f_CA',
  hiring_seasons: 'f_HS',
  internal_external: 'f_IX',
  job_owners: 'f_JO',
  hrbp_assignees: 'f_HA',
  district_users: 'f_DU',
  schools: 'f_S',
  districts: 'f_D',
  subcategories: 'f_SC',
  vacancies: 'f_VS',
  candidate_source_list: 'f_CSL',
};

const _formatQueryString = (params) => {
  let formattedParams = params.toString();
  // if formattedParams is falsy, no need to prepend a '?'
  if (formattedParams) {
    formattedParams = '?' + formattedParams;
  }

  return formattedParams;
};

export const setQueryStringValue = (key, value) => {
  const params = new URLSearchParams(window.location.search);
  // translate key into proper form
  const param = queryParams[key];
  params.set(param, value);

  const formattedParams = _formatQueryString(params);
  setQueryStringWithoutPageReload(formattedParams);
};

export const deleteQueryStringValue = (key) => {
  const params = new URLSearchParams(window.location.search);
  // translate key into proper form
  const param = queryParams[key];
  params.delete(param);

  const formattedParams = _formatQueryString(params);
  setQueryStringWithoutPageReload(formattedParams);
};

export const setQueryStringWithoutPageReload = (queryString) => {
  const newUrl =
    window.location.protocol + '//' + window.location.host + window.location.pathname + queryString;
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const getQueryStringValue = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const parseEvent = (event, user, events) => {
  let label, subtext, icon, smallerIcon;

  switch (event.type) {
    case 0: // profile_created
      label = 'Profile created';
      icon = graph;
      break;
    case 1: // application_created
      label = `Application submitted | ${event.model}`;
      if (label.length > 70) {
        label = label.substring(0, 70) + '...';
      }
      icon = graph;
      subtext = `Candidate - ${moment(event.created_at).format('L, LT')}`;
      break;
    case 2: // application edited by candidate
      label = `Candidate updated ${event.model} application`;
      icon = pencil;
      subtext = `${moment(event.created_at).format('L, LT')}`;
      smallerIcon = 2.2;
      break;
    case 3: // status_update
      icon = applicationUpdate;
      label = `${event.model} application status changed to ${event.new_value}`;
      if (event.new_value === 'Draft') {
        label = `${event.created_by.name} started a draft | ${event.model}`;
      }
      // Don't show the updated by if it was (erroneously) done by the candidate
      let updatedBy = '';
      if (event.created_by.id === user.id && event.new_value === 'Draft') {
        updatedBy = 'Candidate - ';
      } else if (event.created_by.id !== user.id) {
        updatedBy = `${event.created_by.name} - `;
      }
      updatedBy += `${moment(event.created_at).format('L, LT')}`;
      subtext = `${updatedBy}`;
      break;
    case 6: // candidate_emailed
      label = `Email sent: ${event.subject}`;
      if (label.length > 70) {
        label = label.substring(0, 70) + '...';
      }

      const createdBy = event.created_by ? `${event.created_by.name} - ` : '';
      subtext = `${createdBy}${moment(event.created_at).format('L, LT')}`;

      icon = email;
      break;
    case 7: // internal-external change
      label = `Candidate has been changed to ${event.value}`;
      if (label.length > 70) {
        label = label.substring(0, 70) + '...';
      }
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = pencil;
      smallerIcon = 2.2;
      break;
    case 8: // Completed task
      label = `${event.task.universal_task.title} completed`;
      if (label.length > 70) {
        label = label.substring(0, 70) + '...';
      }
      subtext = `${
        event.created_by.name === user.name ? 'Candidate' : event.created_by.name
      } - ${moment(event.created_at).format('L, LT')}`;
      icon = checkSM;
      smallerIcon = -0.1;
      break;
    case 9: // zapier integration
      label = `Application status changed to ${event.status_label} via ${event.integration_label} integration`;
      if (label.length > 75) {
        label = label.substring(0, 75) + '...';
      }
      icon = graph;
      break;
    case 10: // added as prospect
      label = 'Added as prospect';
      if (label.length > 75) {
        label = label.substring(0, 75) + '...';
      }
      icon = graph;
      break;
    case 11: // application attachment added
      const name = event.added_by.id === user.id ? 'Candidate' : event.added_by.name;
      const title = event.model.required_application_attachment?.title || event.model.title;
      label = `${name} uploaded file "${title}" to ${event.role_title} application`;
      icon = uploadIcon;
      subtext = `${event.added_by.name} - ${moment(event.created_at).format('L, LT')}`;
      smallerIcon = 2.4;
      break;
    case 12: // internal note created
      label = `${event.created_by_name} added a note for ${user.name}`;
      icon = note;
      subtext = `${moment(event.created_at).format('L, LT')}`;
      break;
    case 13: // Task assigned
      label = `${event.task.universal_task.title} was created`;
      icon = checkSM;
      subtext = `${moment(event.created_at).format('L, LT')}`;
      smallerIcon = -0.1;
      break;
    case 14: // application copied added
      if (event.keep_app) {
        label = `${event.original_model} application was copied to ${event.model} in ${event.new_application_status} status`;
      } else {
        label = `${event.original_model} was moved to ${event.model} in ${event.new_application_status} status`;
      }
      icon = duplicateIcon;
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      break;
    case 15: // Profile updated by candidate
      // Resume upload event
      if (event.resume_update) {
        const file = event.resume_path;
        const fileSlug = file.substring(file.lastIndexOf('_') + 1, file.length);
        label = `Candidate uploaded resume file "${fileSlug}"`;
        subtext = `Candidate - ${moment(event.created_at).format('L, LT')}`;
        icon = uploadIcon;
        smallerIcon = 2.4;
      } else {
        label = 'Candidate updated their profile';
        subtext = `${moment(event.created_at).format('L, LT')}`;
        icon = profilePencil;
      }
      break;
    case 16: // Hellosign form sent
      label = `${event.model} was sent`;
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = templateIcon;
      smallerIcon = 2;
      break;
    case 17: // Schoolapplication status change
      const schoolName = event.number_schools_updated ? 'multiple schools' : event.school;
      label = `${event.model} was moved to ${event.new_value} for ${schoolName}`;
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = applicationUpdate;
      break;
    case 18: // Reference (aka scorecard) requested event
      label = `Reference request email sent to ${event.recipient}`;
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = reference;
      smallerIcon = 2.2;
      break;
    case 19: // Reference (aka scorecard) requested completed
      label = `Reference completed by ${event.recipient}`;
      subtext = `${moment(event.created_at).format('L, LT')}`;
      icon = reference;
      smallerIcon = 2.2;
      break;
    case 20: // School visibility changed
      label = `${event.number_schools_added} school${
        event.number_schools_added === 1 ? ' was' : 's were'
      } added to ${event.role_title} application`;
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = pencil;
      smallerIcon = 2.2;
      break;
    case 22: // Prospect status changed
      label = `Prospect status was changed to ${event.new_status}`;
      subtext = `${event.created_by_name} - ${moment(event.created_at).format('L, LT')}`;
      icon = applicationUpdate;
      break;
    case 23: // NEW Reference requested event
      label = `Reference request email sent to ${event.recipient}`;
      subtext = `${event.created_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = reference;
      smallerIcon = 2.2;
      break;
    case 24: // NEW Reference requested completed
      label = `Reference completed by ${event.recipient}`;
      subtext = `${moment(event.created_at).format('L, LT')}`;
      icon = reference;
      smallerIcon = 2.2;
      break;
    case 25: // Source updated
      let sourceEvents = events.filter((e) => e.type === 25);
      let idx;
      sourceEvents.forEach((e, i) => {
        if (e.pk === event.pk) {
          idx = i + 1;
        }
      });
      let previousSource = sourceEvents[idx];
      let from = '';
      if (previousSource) {
        from = ` from ${previousSource.model.label}`;
      }
      label = `Candidate source was changed${from} to ${event.model.label}`;
      if (label.length > 70) {
        label = label.substring(0, 70) + '...';
      }
      subtext = `${
        event.created_by.name === user.name ? 'Candidate' : event.created_by.name
      } - ${moment(event.created_at).format('L, LT')}`;
      icon = pencil;
      smallerIcon = 2.2;
      break;
    case 26: // Candidate removed optional attachment
      label = `Document '${event.previous_attachment_title}' was deleted`;
      subtext = `${event.added_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = profilePencil;
      break;
    case 27: // Candidate replaced required attachment
      label = `Document '${event.previous_attachment_title}' was replaced.`;
      subtext = `${event.added_by.name} - ${moment(event.created_at).format('L, LT')}`;
      icon = applicationUpdate;
      break;
    default:
      label = 'Event';
      icon = calendar;
  }
  return { label, icon, subtext, smallerIcon };
};

export const expandAllSections = async () => {
  const appExpandBtn = document.querySelector('.expand-all');
  if (appExpandBtn.innerText.toLowerCase() === 'expand all') {
    // found waiting is needed to ensure DOM has time to expand attachment section
    await appExpandBtn.click();
  }
};

export const openPrintDialogue = async () => {
  // Open all the cards and sections
  await expandAllSections();

  // get hot jar analytics button
  const hotJarButton = findHotJarButton();

  // Hide hotJarButton if exists
  if (hotJarButton !== null) {
    hotJarButton.style.display = 'none';
  }

  window.print();
};

/*TODO:
    This function will need to hit prod. before I can verify
    the right element is selected (can't see it on development/staging).
    */
const findHotJarButton = () =>
  document.querySelector(`
    ._hj-3ZiaL__MinimizedWidgetBottom__container ._hj-4a_14__MinimizedWidgetBottom__left
`);

/**
 * Function accepts an integer and converts it into
 * hours and minutes.
 *
 * Function will also returns several different
 * return string values.
 *
 * @param {*} num - Integer to be converted
 * @param {*} hours - for a string with no hours, set to false
 * @param {*} mins - for a string with no mins, set to false
 */
export function convertIntegerToTime(num, hours = true, mins = true) {
  const h = Math.floor(num / 60);
  const m = num % 60;

  if (hours && mins) {
    // hours & minutes
    return `${h} ${h === 1 ? 'hour' : 'hours'} ${m} ${m === 1 ? 'min' : 'mins'}`;
  } else if (!hours && mins) {
    // only minutes
    return `${m} ${m === 1 ? 'min' : 'mins'}`;
  } else if (hours && !mins) {
    // only hours
    return `${h} ${h === 1 ? 'hour' : 'hours'}`;
  }
}

export function scrollToElementById(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
  }
}

export const formatDropdownOptionLabel = (value) =>
  value
    .split('_')
    .join(' ')
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

export const ensureHttpPrefixIfValueIsNotEmail = (value) => {
  if (!value) {
    return '';
  }

  if (!isValidEmail(value) && !value.startsWith('http://') && !value.startsWith('https://')) {
    return `http://${value}`;
  }

  return value;
};

export const simpleBrowserUrlFormatter = (link) => {
  const simpleValidDomainPattern =
    /^(http:\/\/|https:\/\/)?([\w-]+\.)+[\w]{2,}(?::\d+)?(\/[^\s]*)?$/;

  if (simpleValidDomainPattern.test(link)) {
    if (!/^(http:\/\/|https:\/\/)/.test(link)) {
      link = `https://${link}`;
    }

    return link;
  } else {
    throw new Error('Invalid URL');
  }
};

export function buildUrl(baseUrl, params = {}) {
  if (typeof baseUrl !== 'string') {
    throw new TypeError('Expected baseUrl to be a string');
  }

  if (!baseUrl) {
    throw new Error('Base URL cannot be empty');
  }

  if (typeof params !== 'object' || Array.isArray(params) || params === null) {
    throw new TypeError('Expected params to be a plain object');
  }

  const queryString = (() => {
    const queryParts = [];

    for (const [key, value] of Object.entries(params)) {
      if (value != null) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value.toString());
        queryParts.push(`${encodedKey}=${encodedValue}`);
      }
    }

    return queryParts.join('&');
  })();

  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}
