import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorText from '../errortext';
import SchoolIcon from '../../assets/icons/AccountManagementIcons/school_icon.svg';
import auth from '../../utils/auth';
import { useSchoolGroups } from 'hooks/data/useSchoolGroups';
import { StyledErrorText } from 'components/Login/LoginForm';
import { MultiSelect } from 'ui-kit';

const SchoolAccountModal = (props) => {
  const schoolGroups = useSchoolGroups();
  const [schoolState, setSchoolState] = useState({ ...props.school });

  const handleSubmit = () => {
    props.onSave(schoolState);
    return false;
  };

  const setSchoolName = (name) => {
    setSchoolState((prevState) => ({ ...prevState, name }));
  };

  const setSchoolLocation = (location) => {
    setSchoolState((prevState) => ({ ...prevState, location }));
  };

  const setSchoolGroups = (schoolGroupIds) => {
    setSchoolState((prevState) => ({ ...prevState, school_groups: schoolGroupIds }));
  };

  const schoolGroupChoices = schoolGroups.data.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const EDITMODE = !!props.school.id;
  return (
    <form onSubmit={handleSubmit}>
      <Modal show={props.displayModal} bsSize="large" dialogClassName="account-modal">
        <Modal.Header>
          <img src={SchoolIcon} alt="" />
          <h3>{EDITMODE ? 'Edit' : 'New'} School</h3>
        </Modal.Header>
        <Modal.Body>
          <ErrorText message={props.errors.non_field_errors} />

          <h4 className="section-title">School Name</h4>
          <div className="account-modal-dropshadow">
            <input
              className="account-modal-input"
              name="name"
              type="text"
              placeholder="School Name"
              required
              value={schoolState.name}
              onChange={(event) => setSchoolName(event.target.value)}
            />
            <ErrorText message={props.errors.name} />
          </div>

          <h4 className="section-title">School Location</h4>
          <div
            className="account-modal-dropshadow"
            style={{
              marginBottom: !schoolState.geocoded_location && '0px',
              backgroundColor: !schoolState.geocoded_location && '#FDEDEC',
              borderColor: !schoolState.geocoded_location && 'red',
            }}
          >
            <input
              className="account-modal-input"
              style={{
                backgroundColor: !schoolState.geocoded_location && '#FDEDEC',
                borderColor: !schoolState.geocoded_location && 'red',
              }}
              name="location"
              type="text"
              placeholder="School Location"
              value={schoolState.location}
              onChange={(event) => setSchoolLocation(event.target.value)}
            />
            <ErrorText message={props.errors.location} />
          </div>
          {!schoolState.geocoded_location && (
            <StyledErrorText style={{ marginTop: '0px', marginBottom: '2rem' }}>
              Please enter a valid location and ensure your district location information is correct
            </StyledErrorText>
          )}
          {/* Only district/super admins can tag schools */}
          {auth.isDistrictAdmin() && !auth.inPostingOnlyDistrict() && (
            <>
              <h4 className="section-title">Groups</h4>
              <div>
                <StyledMultiSelect
                  border="0.5px solid #d7d7d7"
                  boxShadow="0 3px 3px 0 rgba(0, 0, 0, 0.08)"
                  name="school-groups"
                  multi={true}
                  options={schoolGroupChoices}
                  defaultValue={schoolGroupChoices.filter((school) =>
                    schoolState.school_groups?.includes(school.value)
                  )}
                  onChange={(selectedOptions) => {
                    selectedOptions = selectedOptions ?? [];
                    setSchoolGroups(selectedOptions.map(({ value }) => value));
                  }}
                  placeholder="Start typing to add this school to one or more groups"
                />
                <ErrorText message={props.errors.schoolGroups} />
              </div>
            </>
          )}
          {EDITMODE && auth.isSuperAdminUser() && (
            <div className="deactivate" onClick={props.openArchiveModal}>
              {/* use this.props instead of this.state here in case they edit the name */}
              Archive {props.school.name}
            </div>
          )}
          <div className="action-buttons-div">
            <button className="cancel" onClick={props.onHide}>
              Cancel
            </button>
            <button className="save" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

SchoolAccountModal.propTypes = {
  school: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  openArchiveModal: PropTypes.func,
};

export default SchoolAccountModal;

const StyledMultiSelect = styled(MultiSelect)`
  margin: 1rem 0 2rem;
`;
