import { useState, useEffect } from 'react';
import styled from 'styled-components';

import useForm from '../../../../hooks/useForm';
import signinForm from './SigninFormValidationRules';
import { ATSSignInDataTestIds } from '../../../../data-testids/ATS';

function SigninForm({ onLogin }) {
  const [globalError, setGlobalError] = useState(null);
  const urlParams = useState(window.location.search)[0];

  const onSubmit = async () => {
    if (email && password) {
      try {
        await onLogin(email.value, password.value);
      } catch (error) {
        setGlobalError(error);
      }
    }
  };

  const { isFormValid, values, handleChange, handleSubmit } = useForm(signinForm, onSubmit);
  const { email, password } = values;

  const fireActiveLogin = (activeElement) => {
    if (email?.value && password?.value) {
      // if we have email and password values and press enter, login
      try {
        onSubmit(email, password);
      } catch (error) {
        setGlobalError(error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const activeElement = document.activeElement;
      fireActiveLogin(activeElement);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(urlParams);
    const idoeError = queryParams.get('idoe_error');

    if (idoeError) {
      setGlobalError(
        "You do not currently have access. Please reach out to your organization's HR admin for assistance."
      );
    }
  }, [urlParams, setGlobalError]);

  return (
    <div
      className="flex form-name-container"
      style={{ flexWrap: 'wrap' }}
      onKeyDown={handleKeyDown}
    >
      <div className="form-entry">
        <label>
          <input
            name="email"
            type="email"
            data-testid={ATSSignInDataTestIds.EMAIL_INPUT}
            placeholder="Email address"
            value={email.value || ''}
            required
            onChange={handleChange}
            className="email-address"
          />
          <StyledErrorText>
            {email.errorMessage && !email.valid && email.errorMessage}
          </StyledErrorText>
        </label>
      </div>
      <div className="form-entry">
        <label>
          <input
            name="password"
            type="password"
            data-testid={ATSSignInDataTestIds.PASSWORD_INPUT}
            placeholder="Password"
            value={password.value || ''}
            pattern=".{6,}"
            required
            onChange={handleChange}
            className="password"
          />
          <StyledErrorText>
            {password.errorMessage && !password.valid && password.errorMessage}
          </StyledErrorText>
        </label>
      </div>
      <StyledInput
        className={`btn ${isFormValid() ? 'btn-success' : 'btn-disabled'}`}
        data-testid={ATSSignInDataTestIds.SUBMIT_BUTTON}
        type="submit"
        onClick={handleSubmit}
        value="Sign in"
        disabled={!isFormValid()}
      />
      {globalError && <GlobalError>{globalError}</GlobalError>}
    </div>
  );
}

export default SigninForm;

const StyledErrorText = styled.p`
  margin-left: 8px;
  font-size: 12px;
  height: 1.5em;
  font-weight: normal;
  color: #cc0000;
`;

const StyledInput = styled.input`
  margin-bottom: 1em;
`;

const GlobalError = styled.p`
  margin: -18px 8px 0px 8px;
  font-size: 12px;
  margin-bottom: 1em;
  font-weight: normal;
  color: #cc0000;
`;
