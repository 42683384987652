import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;

export const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  color: #c2c2c2;
  font-size: 16px;
  padding: 0.85em 2.5em;
`;

export const PrimaryButton = styled(Button)`
  background-color: #00b88d;
  color: #ffffff;
`;

export const CenteredPrimaryButton = styled(PrimaryButton)`
  margin: 10px 0px;
  width: 250px;
`;

export const ConfirmationContainer = styled.div`
  color: black;
  padding: 10px;
`;

export const FormInputError = styled.div`
  color: #cc0033;
  font-size: 13px;
  margin-top: 0.25em;
`;

export const GreenAddAlert = styled.div`
  color: #00b88d;
  font-size: 12px;
  margin: 10px;
`;

export const InputContainer = styled.div`
  color: black;
`;

export const NoAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const NotificationIcon = styled.img`
  filter: invert(100%) sepia(0%) saturate(3815%) hue-rotate(195deg) brightness(83%) contrast(90%);
  height: 50px;
  margin-botton: 10px;
`;

export const PaddedTextBottomOnly = styled.div`
  margin-bottom: 0.7em;
`;

export const PaddedText = styled.div`
  margin-top: 0.7em;
  margin-bottom: 0.7em;
`;

export const SuccessAlertDiv = styled.div`
  padding: 10px;
  color: #074436;
  background-color: #dbf0eb;
  border-radius: 5px;
`;

export const SubMessage = styled.div`
  padding-top: 10px;
  font-size: 12px;
`;

export const SubscribeForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`;

export const SuccessIcon = styled.img`
  margin-right: 10px;
`;
