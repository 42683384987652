import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

export const StyledFileUploadContainer = styled(Container)(({ theme }) => ({
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: 12,
  fontSize: 14,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 24px',
  minHeight: 94,

  '&:hover': {
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const FileDownloadCompleteIcon = styled(FileDownloadDoneIcon)(({ theme }) => ({
  fontSize: '3rem',
  color: theme.palette.success.main,
  marginLeft: 'auto !important',
  marginRight: 'auto !important',
}));
