import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextButton } from './TextButton';

export interface BackButtonProps {
  href: string;
  children: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ href, children }) => {
  return (
    <TextButton href={href} size="large" sx={{ fontWeight: 600, fontSize: '15px' }}>
      <ArrowBackIcon fontSize="small" />
      {children}
    </TextButton>
  );
};
