import { useState } from 'react';
import styled from 'styled-components';
import { MentionsInput, Mention } from 'react-mentions';

import mentionStylesScorecard from '../ScorecardModal/mentionStylesScorecard';
import { TextAnswerProps } from './types';

const TextAnswer: React.FC<TextAnswerProps> = ({
  adminUsers,
  answer,
  updateField,
  index,
  mentionsInputDataTestId,
}) => {
  const renderUserSuggestion = (_suggestion, _search, highlightedDisplay) => {
    return <div>{highlightedDisplay}</div>;
  };

  const [charCount, setCharCount] = useState(0);

  const onTextAreaChangeEvent = (e, index) => {
    updateField(e, index);
    setCharCount(e.target.value.length);
  };

  if (adminUsers) {
    // for tagging users
    return (
      <MentionsInput
        value={answer.answer_text || ''}
        onChange={(e) => updateField(e as unknown as Event, index)}
        placeholder="Mention people using '@'"
        style={mentionStylesScorecard}
        id="placeholder-mention"
        markup="@[__display__]"
        displayTransform={(_id, display) => `@${display}`}
        data-testid={mentionsInputDataTestId}
      >
        <Mention
          trigger="@"
          style={{ backgroundColor: 'var(--gray)' }}
          data={adminUsers}
          renderSuggestion={renderUserSuggestion}
        />
      </MentionsInput>
    );
  }

  return (
    <>
      <StyledTextarea
        value={answer.answer_text || ''}
        onChange={(e) => onTextAreaChangeEvent(e, index)}
        maxLength={32000}
      />
      <p>{charCount} / 32000</p>
    </>
  );
};

export default TextAnswer;

const StyledTextarea = styled.textarea`
  border: 0.5px solid #d7d7d7;
  padding: 10px 15px;
  width: 100%;
  min-height: 120px;
  resize: vertical;
`;
