import { useContext, useEffect, useState } from 'react';
import Context from 'context/featureContext';

export const withFeatures = (data) => (Component) => (props) => {
  const featureContext = useContext(Context);
  const [enabled, setEnabled] = useState(featureContext.features?.includes(data.featureName));

  useEffect(() => {
    if (featureContext.features?.includes(data.featureName)) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [featureContext]);

  const FallbackComponent = data.fallback;

  if (enabled) {
    return <Component {...props} />;
  } else {
    return <FallbackComponent {...props} />;
  }
};

export const useFeatures = () => {
  const featureContext = useContext(Context);
  const enabledFeatures = featureContext.features || [];

  const hasFeature = (featureName) => {
    return featureContext.features?.includes(featureName);
  };

  return [enabledFeatures, hasFeature];
};

export const isFeatureEnabled = (featureName) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const featureContext = useContext(Context);
  const enabledFeatures = featureContext.features || [];
  return enabledFeatures.includes(featureName);
};

export const Feature = ({ featureName, fallback, children }) => {
  const [enabledFeatures, hasFeature] = useFeatures();
  if (hasFeature(featureName)) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return <></>;
};
