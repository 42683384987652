import './browsermodal.css';

import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default class BrowserModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="browser-modal"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="browser-modal-title">
            <h3>Heads up...</h3>
          </div>
          <span className="browser-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
          <div className="browser-modal-section">
            <div className="browser-modal-div">
              We suggest you use the Nimble application in Chrome. If you don't already have Chrome,
              you can download it <a href="https://www.google.com/chrome/">here</a>. If you'd like
              to stick with your current browser and you run into any issues, feel free to contact
              us at support@hirenimble.com and we'll be happy to help!
            </div>
            <button className="browser-modal-button browser-modal-save" onClick={this.props.onHide}>
              Got it!
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
