import { FlatFileTransferInterface, ReportColumn } from 'types/types';
import { Dispatch, SetStateAction } from 'react';

export type ColumnState = {
  columns: FlatFileTransferInterface['report_columns'];
};

export enum ColumnActionType {
  SET_TITLE = 'SET_TITLE',
  SET_TYPE = 'SET_TYPE',
  SET_VALUE = 'SET_VALUE',
  SET_MAPPING = 'SET_MAPPING',
  SET_PROFILE_FIELD = 'SET_PROFILE_FIELD',
  ADD_COLUMN = 'ADD_COLUMN',
  DELETE_COLUMN = 'DELETE_COLUMN',
  SET_FLAT_FILE = 'SET_FLAT_FILE',
  MOVE = 'MOVE',
}

export type ColumnActionValueType =
  | string
  | number
  | Map<string, string>
  | FlatFileTransferInterface['report_columns'];

export type ColumnAction = {
  type: ColumnActionType;
  value?: ColumnActionValueType;
  index?: number;
  from?: any;
  to?: any;
  fromIndex?: number;
  toIndex?: number;
  fieldName?: string;
  id?: string;
};

export interface FlatFileTransferPropsInterface {
  title?: string;
  isLoading?: boolean;
  isSaving?: boolean;
  isDownloading?: boolean;
  isSaveDisabled?: boolean;
  isSaveModalOpen?: boolean;
  areColumnsIncomplete?: boolean;
  saveFlatFileTransfer?: () => void;
  handleTestFileDownload?: () => Promise<void>;
  setAreColumnsIncomplete?: Dispatch<SetStateAction<boolean>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setIsSaving?: Dispatch<SetStateAction<boolean>>;
  setIsDownloading?: Dispatch<SetStateAction<boolean>>;
  dispatch?: Dispatch<{
    type: ColumnActionType;
    value?: ColumnActionValueType;
    index?: number;
    fieldName?: string;
    id?: string;
    hellosign_template_id?: string;
  }>;
}

export interface FlatFileTransferCustomSelectPropsInterface {
  value: FlatFileTransferOptionsInterface;
  onChange: (option: FlatFileTransferOptionsInterface) => void;
  options: FlatFileTransferOptionsInterface[];
  isDisabled?: boolean;
  isClearable?: boolean;
  isMissingInput?: boolean;
}

export interface FlatFileTransferInputPropsInterface {
  index: number;
  columnState: ReportColumn;
  customProfileFields?: FlatFileTransferOptionsInterface[];
  applicationStatusOptions?: FlatFileTransferOptionsInterface[];
  helloSignTemplateOptions?: FlatFileTransferOptionsInterface[];
  setShowFormatsColumn?: Dispatch<SetStateAction<boolean>>;
  setIsMissingInput?: Dispatch<SetStateAction<boolean>>;
  isMissingInput?: boolean;
  isBackendOnlyField?: boolean;
  dispatch: Dispatch<{
    type: ColumnActionType;
    value?: ColumnActionValueType;
    index?: number;
    fieldName?: string;
    id?: string;
  }>;
}
export interface FlatFileTransferOptionsInterface {
  label: string;
  value: string | number;
  hellosign_template_id?: string;
}
