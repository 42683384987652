import { Fragment, useState, useEffect } from 'react';
import {
  USStates,
  credentialNewJerseyTeachingCertificate,
  examTypesMissouri,
} from '../../../utils/enums';
import axios from 'axios';
import stateAPI from '../../../api/stateAPI';
import iconMinus from '../../../assets/icon-minus.svg';
import iconPlus from '../../../assets/icon-plus.svg';
import moment from 'moment';
import DateTime from 'react-datetime';
import ErrorText from '../../../components/errortext';
import { MultiSelect, Input, StyledSelect, Dropdown, theme } from 'ui-kit';
import styled from 'styled-components';
import { DownCaretIcon } from 'ui-kit/icons';
import SpnInput from './components/SpnInput';
import { ATSCandidateCredentialsDataTestIds } from 'data-testids/ATS';

const credentialTypeList = [
  { value: 1, label: 'State Credential' },
  { value: 2, label: 'Other Credential' },
];
const DEFAULT_CREDENTIAL_LABEL = 'Select Credential Type';

export default function CredentialInputs({
  spn,
  handleSpnChange,
  hasSpnError,
  credentials,
  autosave,
  setCredentials,
  setCredentialNone,
}) {
  // TODO: so many enum improvements .. and using ".find()" instead of ".filter()" (sigh)
  const [stateInfo, setStateInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [inputCredential, setInputCredential] = useState({});
  const [editPosition, setEditPosition] = useState(false);
  const [credentialType, setCredentialType] = useState({ value: 0 });
  const [transformedCredentials, setTransformedCredentials] = useState([]);

  let explanationPlaceholderText = {
    Initial: 'Is license current or expired?',
    Provisional: 'Explain certification situation and date of anticipated certification',
    'Temporary Authorization':
      'Explain certification situation and date of anticipated certification',
    'In Progress / Pending':
      'Have you passed licensure exam ? How many credit hours do you have remaining for certification coursework?',
    'Substitute Certification': 'Further explanation, if needed',
    Career: 'Further explanation, if needed',
    Lifetime: 'Further explanation, if needed',
  };

  const getStateObj = (stateId) => {
    return USStates().filter((state) => state.id === stateId)[0];
  };

  const hasIndianaCredentials = () => {
    const indianaId = USStates().find((s) => s.title === 'Indiana').id;
    const visibleCredentials = transformedCredentials.filter(
      (credential) => credential.isHidden == false
    );
    const indianaCredentials = visibleCredentials.filter((c) => c.state && +c.state === indianaId);
    return indianaCredentials.length > 0;
  };

  const isSelected = (item) => {
    const selected = credentialTypeList.find((type) => type.value === item.value);
    return selected.value === credentialType.value;
  };

  const updateCredentialTypeDropdown = (selectedType, isFilterCurrentlyActive) => {
    if (isFilterCurrentlyActive) {
      setCredentialType({ value: 0 });
    } else {
      setCredentialType(selectedType);
    }

    setInputCredential({});
    setStateInfo(false);
    setErrorMessage('');
  };

  const transformCredentials = () => {
    const updatedCredentials = credentials.map((credential) => {
      if ('isHidden' in credential) {
        return credential;
      } else {
        let isHidden = false;
        isHidden = credential?.subject?.show_in_ats == false;
        return { ...credential, isHidden: isHidden };
      }
    });
    setTransformedCredentials(updatedCredentials);
  };

  useEffect(() => {
    // add flag to hide Nimble Connect credentials
    transformCredentials(credentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const renderCredentialsSection = () => {
    return (
      <div>
        <div className="mt1">
          <GridContainerHalves theme={{ main: '2rem' }}>
            <Dropdown.Dropdown
              dataTestId={ATSCandidateCredentialsDataTestIds.CREDENTIAL_TYPE_SELECT}
              label={credentialType.value ? credentialType.label : DEFAULT_CREDENTIAL_LABEL}
              isRequired={credentialType.value === 0}
              isMultiSelect={false}
              inputProps={{
                id: 'credential-type-select',
                paddingLeft: '1.5em',
                paddingRight: '1.5em',
                boxShadow: 'none',
                isError: !!errorMessage.credential_type,
              }}
            >
              {credentialTypeList.map((type) => {
                const selectedCredentialType = isSelected(type);
                return (
                  <ListItem
                    data-testid={`${ATSCandidateCredentialsDataTestIds.CREDENTIAL_TYPE_SELECT_DROPDOWN_OPTION}-${type.value}`}
                    isSelected={selectedCredentialType}
                    onClick={() => updateCredentialTypeDropdown(type, selectedCredentialType)}
                    key={type.value}
                  >
                    {type.label}
                  </ListItem>
                );
              })}
            </Dropdown.Dropdown>
            {/* state credential field */}
            {credentialType?.value === 1 && (
              <StyledSelect
                options={USStates()}
                id="credential-state-select"
                name="state"
                placeholder="State"
                onChange={(evt) => fetchStateData(evt?.id, true)}
                value={getStateObj(inputCredential?.state)}
                hasErrors={errorMessage.state}
              />
            )}
            {/* other credential field */}
            {credentialType?.value === 2 && (
              <Input
                width={1}
                name="credential_explanation"
                type="text"
                placeholder="Describe Credential (optional)"
                value={inputCredential?.credential_explanation}
                onChange={(e) => handleChange('credential_explanation', e.target.value)}
                hasError={false}
              />
            )}
          </GridContainerHalves>
          {/* state credential field */}
          {credentialType?.value === 1 && (
            <div>
              <GridContainerHalves theme={{ main: '2rem' }}>
                <StyledSelect
                  id="credential-category-select"
                  options={stateInfo?.credential_subjects || []}
                  name="subject"
                  placeholder={stateInfo?.credential_subjects ? 'Category' : 'Select state first'}
                  onChange={(e) => handleChange('subject', e)}
                  value={inputCredential?.subject}
                  hasErrors={errorMessage.status_subject_grade}
                  isClearable
                />
                {/* Only state of NJ (30) will show certificate question */}
                {stateInfo?.certificate_question_required ? (
                  <StyledSelect
                    options={credentialNewJerseyTeachingCertificate() || []}
                    name="certificate"
                    placeholder={stateInfo?.certificate || 'Do you hold a NJ certificate?*'}
                    onChange={(e) => handleChange('certificate', e.value)}
                    value={
                      inputCredential?.certificate
                        ? credentialNewJerseyTeachingCertificate().filter(
                            (item) => item.value === Number(inputCredential?.certificate)
                          )[0]
                        : false
                    }
                    hasErrors={errorMessage.certificate}
                    isClearable
                  />
                ) : (
                  <StyledSelect
                    id="credential-grade-select"
                    options={stateInfo?.credential_grades || []}
                    name="grade"
                    placeholder={stateInfo?.credential_subjects ? 'Grade' : 'Select state first'}
                    onChange={(e) => handleChange('grade', e)}
                    value={inputCredential?.grade}
                    hasErrors={errorMessage.status_subject_grade}
                    isClearable
                  />
                )}
              </GridContainerHalves>
              <GridContainerHalves theme={{ main: '2rem' }}>
                <div style={{ display: 'flex', columnGap: '10px' }}>
                  <DateTimeContainer hasError={errorMessage.issue_date}>
                    <StyledDateTime
                      inputProps={{
                        placeholder: 'Issue Month',
                        className: 'datetime-picker jobedit',
                        readOnly: true,
                      }}
                      timeFormat={false}
                      dateFormat="MMM"
                      closeOnSelect={true}
                      value={
                        inputCredential?.issue_date ? new Date(inputCredential?.issue_date) : ''
                      }
                      onChange={(date) => updateDate(date, 'issue_date', 'month')}
                    />
                    <StyledDownCaret />
                  </DateTimeContainer>
                  <DateTimeContainer hasError={true}>
                    <StyledDateTime
                      inputProps={{
                        placeholder: 'Issue Year',
                        className: 'datetime-picker jobedit',
                        readOnly: true,
                      }}
                      timeFormat={false}
                      dateFormat="YYYY"
                      closeOnSelect={true}
                      value={
                        inputCredential?.issue_date ? new Date(inputCredential?.issue_date) : ''
                      }
                      onChange={(date) => updateDate(date, 'issue_date', 'year')}
                    />
                    <StyledDownCaret />
                  </DateTimeContainer>
                </div>
                <div style={{ display: 'flex', columnGap: '10px' }}>
                  <DateTimeContainer hasError={errorMessage.start_date}>
                    <StyledDateTime
                      inputProps={{
                        placeholder: 'Expiration Month',
                        className: 'datetime-picker jobedit',
                        readOnly: true,
                      }}
                      timeFormat={false}
                      dateFormat="MMM"
                      closeOnSelect={true}
                      value={
                        inputCredential?.expiration_date
                          ? new Date(inputCredential?.expiration_date)
                          : ''
                      }
                      onChange={(date) => updateDate(date, 'expiration_date', 'month')}
                    />
                    <StyledDownCaret />
                  </DateTimeContainer>
                  <DateTimeContainer hasError={errorMessage.start_date}>
                    <StyledDateTime
                      inputProps={{
                        placeholder: 'Expiration Year',
                        className: 'datetime-picker jobedit',
                        readOnly: true,
                      }}
                      timeFormat={false}
                      dateFormat="YYYY"
                      closeOnSelect={true}
                      value={
                        inputCredential?.expiration_date
                          ? new Date(inputCredential?.expiration_date)
                          : ''
                      }
                      onChange={(date) => updateDate(date, 'expiration_date', 'year')}
                    />
                    <StyledDownCaret />
                  </DateTimeContainer>
                </div>
              </GridContainerHalves>
              <GridContainerHalves theme={{ main: '0rem' }}>
                <StyledSelect
                  options={stateInfo?.credential_statuses}
                  name="status"
                  placeholder={
                    stateInfo?.credential_statuses ? 'Status/Type' : 'Select state first'
                  }
                  onChange={(e) => handleChange('status', e)}
                  value={inputCredential?.status}
                  hasErrors={errorMessage.status_subject_grade}
                  isClearable
                />
                <Input
                  width={1}
                  name="license_number"
                  type="text"
                  placeholder="License Number"
                  value={inputCredential?.license_number || ''}
                  onChange={(e) => handleChange('license_number', e.target.value)}
                  hasError={false}
                />
              </GridContainerHalves>
            </div>
          )}
          {/* Only MO (25) show exam multi-select */}
          {credentialType?.value === 1 && stateInfo?.exams_question_required && (
            <div style={{ marginTop: '20px' }}>
              <MultiSelect
                className="flex-1"
                options={examTypesMissouri()}
                value={examTypesMissouri().filter((exam) =>
                  inputCredential?.credential_exams_passed?.includes(exam.value)
                )}
                placeholder="Credential exams passed"
                onChange={(values) =>
                  handleMultiSelectChange(values ?? [], 'credential_exams_passed')
                }
              />
            </div>
          )}
          {/* Only NJ (30) shows explanation_required */}
          {credentialType?.value === 1 && stateInfo?.explanation_required && (
            <div style={{ marginTop: '20px' }}>
              <Input
                width={1}
                name="credential_explanation"
                type="text"
                placeholder={
                  inputCredential?.status?.label
                    ? explanationPlaceholderText[inputCredential?.status?.label]
                    : 'Further explanation, if needed'
                }
                value={inputCredential?.credential_explanation}
                onChange={(e) => handleChange('credential_explanation', e.target.value)}
                hasError={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const fetchStateData = async (stateId, setInput) => {
    let response = await stateAPI.fetchState(stateId);
    // we need to reset status, grade, and subject if state is changed
    if (setInput) {
      let copy = {
        state: stateId,
        subject: null,
        status: null,
        grade: null,
        certificate: null,
        credential_explanation: null,
        credential_exams_passed: null,
      };
      setInputCredential(copy);
    }
    setStateInfo(response);
  };

  const updateDate = (date, name, type) => {
    let updateObject = { ...inputCredential };
    if (date.toDate) {
      if (type === 'month') {
        if (updateObject?.name) {
          let existingDate = new Date(updateObject[name]);
          let newMonth = date.toDate().getMonth();
          existingDate.setMonth(newMonth);
          updateObject[name] = existingDate;
        } else {
          updateObject[name] = date.toDate();
        }
      } else {
        if (updateObject?.name) {
          let existingDate = new Date(updateObject[name]);
          let newYear = date.toDate().getFullYear();
          existingDate.setFullYear(newYear);
          updateObject[name] = existingDate;
        } else {
          updateObject[name] = date.toDate();
        }
      }
      setInputCredential(updateObject);
    }
  };

  const handleMultiSelectChange = (values, fieldName) => {
    // values parameter can be an array of objects, or one object (not in an array)
    if (Array.isArray(values)) {
      values = values.map((value) => value.value);
    } else if (values) {
      values = values.value;
    } else {
      values = '';
    }
    let updateObject = { ...inputCredential };
    updateObject[fieldName] = values;
    setInputCredential(updateObject);
  };

  const toggleAdd = () => {
    setIsAdding(!isAdding);
  };

  const toggleEditRow = (index, row) => {
    let stateId = row.state;
    if (stateId) {
      fetchStateData(stateId, false);
    }
    setIsAdding(false);
    // Editing credential will either be a State or Other Credential depending on state value.
    let editingCredentialType = row?.state
      ? { value: 1, label: 'State Credential' }
      : { value: 2, label: 'Other Credential' };
    setCredentialType(editingCredentialType);
    setInputCredential(row);
    setEditPosition(index);
  };
  const handleAddRow = () => {
    let errorsExists = checkForErrors();
    if (!errorsExists) {
      credentials.push(inputCredential);
      setCredentials([...credentials]);
      setIsAdding(false);
      setInputCredential({});
      autosave();
    }
  };

  const handleEditRow = () => {
    let errorsExists = checkForErrors();
    if (!errorsExists) {
      credentials[editPosition] = inputCredential;
      setCredentials([...credentials]);
      setEditPosition(false);
      setInputCredential({});
      autosave();
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    let updateObject = { ...inputCredential };
    updateObject[fieldName] = fieldValue;
    setInputCredential(updateObject);
  };

  const handleRemoveRow = (i, e) => {
    credentials.splice(i, 1);
    setCredentials([...credentials]);
    setIsAdding(false);
  };

  const checkForErrors = () => {
    const errorMessage = {};
    let hasError = false;

    if (credentialType.value === 0) {
      errorMessage.credential_type = 'Please select a credential type.';
      hasError = true;
    } else if (credentialType.value === 1) {
      if (!inputCredential.certificate && stateInfo?.certificate_question_required) {
        errorMessage.certificate = 'You must complete certificate question.';
        hasError = true;
      }
      if (!inputCredential?.status && !inputCredential?.grade && !inputCredential?.subject) {
        errorMessage.status_subject_grade =
          'Please complete at least one of these fields: category, status, or grade.';
        hasError = true;
      }
      if (inputCredential?.issue_date > inputCredential?.expiration_date) {
        errorMessage.expiration_date =
          'Please enter an expiration date that is after your issue date';
        hasError = true;
      }
      const today = new Date();
      if (inputCredential?.issue_date > today) {
        errorMessage.issue_date = "Please add an issue date prior to today's date";
        hasError = true;
      }
    }

    hasError ? setErrorMessage(errorMessage) : setErrorMessage('');
    return hasError;
  };

  const resetState = () => {
    setInputCredential({});
    setIsAdding(false);
    setEditPosition(false);
    setErrorMessage('');
  };

  return (
    <>
      {transformedCredentials &&
        transformedCredentials.map((row, i) => {
          if (!row?.isHidden) {
            return (
              <Fragment key={i}>
                {i !== editPosition && (
                  <div className="added-row" key={i}>
                    {row?.state ? (
                      <span className="block flex-1 bold">
                        <h4>
                          {row?.grade?.value || ''}
                          {row?.grade?.value && row?.subject?.value ? `, ` : ''}
                          {row?.subject?.value || ''}
                        </h4>
                        <p>{row?.status?.value || ''}</p>
                      </span>
                    ) : (
                      <span className="block flex-1 bold">
                        <h4>Other Credential</h4>
                        <p>{row?.credential_explanation}</p>
                      </span>
                    )}
                    <span className="flex-1">
                      {`${
                        row?.issue_date && moment(row?.issue_date).isValid()
                          ? moment(row?.issue_date).format('MM/D/YYYY')
                          : ''
                      }
                    ${
                      row?.expiration_date && moment(row?.expiration_date).isValid()
                        ? `- ${moment(row?.expiration_date).format('MM/D/YYYY')}`
                        : ''
                    }`}
                    </span>
                    <div className="pointer">
                      <span className="mr1" onClick={(e) => toggleEditRow(i, row)}>
                        Edit
                      </span>
                      <span onClick={() => handleRemoveRow(i)}>Delete</span>
                    </div>
                  </div>
                )}
                {i === editPosition && (
                  <div>
                    {renderCredentialsSection()}
                    <div className="flex add-card-container">
                      <div className="add-btn cancel" onClick={resetState}>
                        Cancel
                      </div>
                      <div
                        className="add-btn save"
                        id="credential-save-button"
                        onClick={handleEditRow}
                      >
                        Save Entry
                      </div>
                    </div>
                    {/* display error messages for editing */}
                    {errorMessage && Object.keys(errorMessage).length > 0 && (
                      <div className="mt1">
                        {Object.keys(errorMessage).map((key) => (
                          <ErrorText key={key} message={errorMessage[key]} color="#EF5675" />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            );
          }
        })}
      {isAdding && renderCredentialsSection()}
      {isAdding && credentialType?.value !== 3 && (
        <div className="flex add-card-container">
          <div className="add-btn cancel" onClick={resetState}>
            Cancel
          </div>
          <div
            className="add-btn save"
            id="credential-save-button"
            data-testid={ATSCandidateCredentialsDataTestIds.SAVE_ENTRY_BUTTON}
            onClick={handleAddRow}
          >
            Save Entry
          </div>
        </div>
      )}
      {!isAdding && (
        <div
          className="add-btn"
          id="credentials-add-button"
          data-testid={ATSCandidateCredentialsDataTestIds.ADD_CREDENTIALS_BUTTON}
          onClick={() => {
            toggleAdd();
            setEditPosition(false);
            setCredentialType({ value: 0 });
            setCredentialNone(false);
            setStateInfo(false);
            setIsAdding(true);
          }}
        >
          <img src={isAdding ? iconMinus : iconPlus} alt="Icon" />
          Add Entry
        </div>
      )}
      {/* display error messages for adding */}
      {isAdding && errorMessage && Object.keys(errorMessage).length > 0 && (
        <div className="mt1">
          {Object.keys(errorMessage).map((key) => (
            <ErrorText key={key} message={errorMessage[key]} color="#EF5675" />
          ))}
        </div>
      )}
      {hasIndianaCredentials() && (
        <SpnInput spn={spn} handleSpnChange={handleSpnChange} hasSpnError={hasSpnError} />
      )}
    </>
  );
}

const GridContainerHalves = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: ${(props) => props.theme.main};

  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid #cc0033 !important;
    background-color: #fce4e4;
    `}
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const DateTimeContainer = styled.div`
  position: relative;
  width: 100%;
  ${({ hasError }) =>
    hasError &&
    `
      .datetime-picker-container > input {
        border: 1px solid #cc0033 !important;
        background-color: #fce4e4;
      }
    `}
`;

const StyledDownCaret = styled(DownCaretIcon)`
  position: absolute;
  height: 0.5em;
  fill: #c1c1c1;
  right: 20px;
  top: 22px;
`;

const StyledDateTime = styled(DateTime)`
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #dcdcdc;
  box-shadow: 0 0 $(outer.shadow.spread) rgba(0, 0, 0, 0.2);
`;

const DropdownListItem = ({ isSelected, closeDropdown, onClick, children, ...rest }) => {
  const handleClick = () => {
    if (onClick) onClick();
    closeDropdown();
  };

  return (
    <Dropdown.ListItem {...rest} onClick={handleClick}>
      <span>{children}</span>
    </Dropdown.ListItem>
  );
};

const ListItem = styled(DropdownListItem)`
  padding: 0.5em 1.5em;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.isSelected ? theme.uiColors.white : 'default')};
  background-color: ${(props) => (props.isSelected ? theme.uiColors.greens.full : 'transparent')};

  :hover {
    color: ${theme.uiColors.white};
    background-color: ${theme.uiColors.greens.medium};
  }
`;
