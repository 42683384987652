import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { StyledToggleButton } from './styles';
import { ToggleButtonsProps } from './types';

export const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  currentlySelected,
  onToggle,
  options,
}) => {
  const [alignment, setAlignment] = useState(
    currentlySelected ? currentlySelected : options[0].title
  );
  const history = useHistory();

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Button group"
    >
      {options.map((option) => (
        <StyledToggleButton
          key={option.title}
          value={option.title}
          selected={null || currentlySelected === option.title}
          onClick={() => {
            if (currentlySelected === option.title) return;

            onToggle(option);
            if (option.href) {
              history.push(option.href);
            }
          }}
        >
          {option.title}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
