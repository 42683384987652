import useSWR from 'swr';

import questionSetsAPI from 'api/questionSetsAPI';

const KEY = '/api/question_set/';

export default function useApplicationQuestionSets() {
  const { data } = useSWR(KEY, () => questionSetsAPI.list());

  const isLoading = data === undefined;

  return { data, isLoading };
}
