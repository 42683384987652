import styled from 'styled-components';

import ProspectStatusSwitcher from '../../Prospects/ProspectStatusSwitcher';
import SourceSection from './SourceSection';
import { StatusAndSourceProps } from './types';

const StatusAndSource: React.FC<StatusAndSourceProps> = ({
  fullUser,
  isDistrictAdmin,
  sources,
  updateFullUser,
  updateProspectStatus,
}) => {
  return (
    <StatusAndSourceContainer>
      <ProspectStatusSwitcher candidate={fullUser} updateProspectStatus={updateProspectStatus} />
      {isDistrictAdmin && (
        <SourceSection
          fullUser={fullUser}
          onProspectList={true}
          updateFullUser={updateFullUser}
          sources={sources}
        />
      )}
    </StatusAndSourceContainer>
  );
};

export default StatusAndSource;

const StatusAndSourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
`;
