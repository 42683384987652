import styled from 'styled-components';

import CandidatesListFilterDrop from 'ui-kit/ListFilterDropdown';
import CandidatePreferencesFilter from 'components/CandidatesList/CandidatesFilters/CandidatePreferencesFilter';
import ExperienceFilter from 'components/CandidatesList/CandidatesFilters/ExperienceFilter';
import { TalentFiltersSidebarProps } from './TalentFiltersSidebar.types';

const TalentFiltersSidebar: React.FC<TalentFiltersSidebarProps> = ({
  activeDistrictSchools,
  clearAll,
  credentialOptions,
  credList,
  experienceEnd,
  experienceStart,
  filterOptions,
  filterValues,
  grades,
  multilingualSchool,
  selectAll,
  title1School,
  turnaroundSchool,
  updateExperienceState,
  updateMultiSelectFilter,
  updateMultiSelectFilterWithMultipleValues,
}) => {
  return (
    <Container className="candidateslist-filters">
      <CandidatesListFilterDrop
        displayName="Credential Subject"
        updateMultiSelectFilter={updateMultiSelectFilter}
        fieldName="cred_list"
        values={credList}
        options={credentialOptions}
        selectAll={selectAll}
        clearAll={clearAll}
      />
      <ExperienceFilter
        experienceStart={experienceStart}
        experienceEnd={experienceEnd}
        updateExperienceState={updateExperienceState}
      />
      <CandidatePreferencesFilter
        activeDistrictSchools={activeDistrictSchools}
        clearAll={clearAll}
        filterOptions={filterOptions}
        filterValues={filterValues}
        grades={grades}
        multilingualSchool={multilingualSchool}
        selectAll={selectAll}
        title1School={title1School}
        turnaroundSchool={turnaroundSchool}
        updateMultiSelectFilter={updateMultiSelectFilter}
        updateMultiSelectFilterWithMultipleValues={updateMultiSelectFilterWithMultipleValues}
      />
    </Container>
  );
};

export default TalentFiltersSidebar;

const Container = styled('div')({
  backgroundColor: 'white',
  borderBottom: '1px solid #dfdfdf',
  borderRight: '1px solid #dfdfdf',
  height: '100%',
  overflow: 'auto',
  position: 'fixed',
  width: '250px',
  '@media (max-width: 768px)': {
    marginTop: 0,
    position: 'relative',
    width: '100%',
  },
});
