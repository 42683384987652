import { useState, useEffect } from 'react';
import CustomTagAPI from '../../api/customTagAPI';
import auth from '../../utils/auth';

import styled from 'styled-components';

import CustomTagRow from './CustomTagRow';
import CustomTagModal from './CustomTagModal';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';

function CustomTagList() {
  // STATE:
  const district = auth.getUser().profile.district;
  const [tags, setTags] = useState([]);
  const [error] = useState('');
  const [editTag, setEditTag] = useState({ value: '', label: '' });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  /**
   * Accepts a tag as a parameter and opens a modal for it
   */
  function openModal(tag) {
    setEditTag(tag);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const fetchCustomTags = async () => {
    try {
      const data = await CustomTagAPI.fetchCustomTags();
      setTags(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Helper function that generates new tag fields
   * when user is creating a new tag.
   */
  const freshCustomTag = () => {
    return {
      id: '',
      district: district.id,
      title: '',

      // spec: super_admin should always be selected
      super_admin_visibility: true,
      district_admin_visibility: false,
      district_user_visibility: false,
      school_admin_visibility: false,
      candidate_type: true,
      prospect_type: false,
      // defaults to green
      label_color: 0,
    };
  };

  const onModalSave = async (tag) => {
    // if tag exists we are updating, else we're creating new one
    tag.id ? await CustomTagAPI.updateCustomTag(tag) : await CustomTagAPI.createCustomTag(tag);
    await fetchCustomTags();
    closeModal();
  };

  /**
   * Handles deletion of tags.
   */
  const onModalDelete = async (tag) => {
    await CustomTagAPI.deleteCustomTag(tag.id);
    await fetchCustomTags();
    closeModal();
  };

  useEffect(() => {
    fetchCustomTags();
  }, []);

  return (
    <CustomTagContainer>
      <div className="mt2">
        <AnchorTagHeader
          title="Candidate Tags"
          buttonText="+ New Tag"
          onClick={() => openModal(freshCustomTag)}
          id="candidateTags"
        />
        <CustomTagHeader>
          <CustomTagColumn>Tag Name</CustomTagColumn>
          <CustomTagColumn>Visibility</CustomTagColumn>
          <CustomTagColumn>Type</CustomTagColumn>
        </CustomTagHeader>
        {tags.length > 0 ? (
          tags.map((tag) => <CustomTagRow key={tag.id} tag={tag} openModal={openModal} />)
        ) : (
          <EmptyTagContainer>
            <EmptyTagPlaceholderText>
              Click "+ New tag" button above to create candidate tags
            </EmptyTagPlaceholderText>
          </EmptyTagContainer>
        )}
      </div>
      {modalIsOpen && (
        <CustomTagModal
          isOpen={modalIsOpen}
          onSave={onModalSave}
          editTag={editTag}
          onDelete={onModalDelete}
          onClose={closeModal}
          error={error}
        />
      )}
    </CustomTagContainer>
  );
}

export default CustomTagList;

const CustomTagContainer = styled.div`
  justify-content: space-around;
  margin-bottom: 2rem;
`;

const CustomTagHeader = styled.div`
  color: #999999;
  background-color: #ffffff;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  padding: 7px 16px;
  border: 1px solid #eee;
`;

const CustomTagColumn = styled.span`
  flex: 0 0 25%;
  padding-right: 16px;
`;

const EmptyTagContainer = styled.div`
  height: 208px;
  border: 1px dashed #949494;
  border-top: none;
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
`;

const EmptyTagPlaceholderText = styled.p`
  font-family: Open Sans;
  font-size: 17px;
  color: #bdbdbd;
  margin: 0 auto;
`;
