import {
  ResponsiveContainer,
  Cell,
  CartesianGrid,
  BarChart,
  Bar,
  Text,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { CHART_COLOR_PALETTES } from '../../PipelineMetricsDashboard/constants';

let colors = CHART_COLOR_PALETTES[8];

export const BarChartComponent = ({
  dataset,
  XAxisLabel,
  vacancyFillRate,
  alwaysShowStatus,
  total,
}) => {
  let datasetCopy = dataset;
  if (alwaysShowStatus) {
    datasetCopy.map((i) => (i.value = i.value / i.segment_total));
  }

  const showYAxisLabels = dataset.length < 8;

  return (
    <ResponsiveContainer width={400} height={350}>
      <BarChart data={datasetCopy} layout="vertical">
        <CartesianGrid horizontal={false} />
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          tickLine={false}
          tick={<TickLabel showYAxisLabels={showYAxisLabels} />}
        />
        <XAxis
          type="number"
          domain={[0, 'dataMax']}
          tickLine={true}
          height={50}
          tickFormatter={(value) =>
            vacancyFillRate || alwaysShowStatus
              ? `${Math.ceil(value * 1000) / 10}%`
              : `${Math.ceil((value / total) * 1000) / 10}%`
          }
          label={{
            value: `${XAxisLabel}`,
            fontSize: 11,
            position: 'insideBottom',
            dy: 0,
            fill: '#777777',
          }}
        />
        <Bar dataKey={vacancyFillRate ? 'vacancy_fill_rate' : 'value'} fill="#00B88D" name="">
          {datasetCopy.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              tooltipItemColor={colors[index % colors.length]}
            />
          ))}
        </Bar>
        <Tooltip
          contentStyle={{ minWidth: '73px', height: '58px', alignContent: 'center' }}
          separator={''}
          formatter={(value) => [
            vacancyFillRate || alwaysShowStatus
              ? `${Math.ceil(value * 1000) / 10}%`
              : `${Math.ceil((value / total) * 1000) / 10}%`,
            '',
          ]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const TickLabel = ({ payload, showYAxisLabels, ...passthroughProps }) => {
  return (
    <Text {...passthroughProps} style={{ fontSize: '11' }}>
      {showYAxisLabels ? payload.value : ''}
    </Text>
  );
};
