import { Redirect, Route, Switch } from 'react-router-dom';

import {
  LinkedinAuthContainer,
  TwitterAuthContainer,
} from 'components/ShareToSocialMedia/authcontainer';
import UnauthenticatedRoute from 'containers/UnauthenticatedRoute';
import UserUpdatePasswordContainer from 'containers/userupdatepassword';
import { NetworkRequestStatusProvider } from 'context/networkRequestStatusContext';
import { ConnectSignUpLogin } from 'features/Connect/features/ConnectSignUpLogin/ConnectSignUpLogin';
import { JobDiscovery } from 'features/Connect/features/JobDiscovery/JobDiscovery';
import SubmitReferenceContainer from 'features/SubmitReference';
import SubmitScorecardContainer from 'features/SubmitScorecard/submitscorecard';
import JobsListContainer from '../../containers/jobslist';
import JobviewContainer from 'containers/JobviewContainer';
import LoginContainer from '../../containers/login';
import HellosignEmbeddedSigning from '../../features/HellosignEmbeddedSigning';
import StateJobBoard from '../../features/StateJobBoard';
import PublicRoute from '../PublicRoute';
import SocialMediaTrackingContainer from '../ShareToSocialMedia/trackingcontainer';

interface PublicRoutesProps {
  fallback: React.ReactNode;
}

const PublicRoutes: React.FC<PublicRoutesProps> = ({ fallback }) => {
  return (
    <Switch>
      <PublicRoute exact path={['/connect/signup', '/connect/:id/signup']}>
        <NetworkRequestStatusProvider>
          <ConnectSignUpLogin />
        </NetworkRequestStatusProvider>
      </PublicRoute>
      <PublicRoute exact path={['/connect/login', '/connect/:id/login']}>
        <NetworkRequestStatusProvider>
          <ConnectSignUpLogin />
        </NetworkRequestStatusProvider>
      </PublicRoute>
      <Route exact path="/connect">
        <Redirect to="/connect/missouri" />
      </Route>
      <PublicRoute exact path="/connect/:id">
        <NetworkRequestStatusProvider>
          <JobDiscovery />
        </NetworkRequestStatusProvider>
      </PublicRoute>
      <PublicRoute path="/jobs/state/:id">
        <StateJobBoard />
      </PublicRoute>
      <PublicRoute path="/jobs/:id">
        <NetworkRequestStatusProvider>
          <JobsListContainer />
        </NetworkRequestStatusProvider>
      </PublicRoute>
      <PublicRoute
        path="/hellosign-embedded-sign/:signatureid"
        component={HellosignEmbeddedSigning}
      />
      <PublicRoute path="/jobview/:id" component={JobviewContainer} />
      {/* Broken on prod too? */}
      <Route path="/j/:code">
        <SocialMediaTrackingContainer />
      </Route>
      <PublicRoute path="/scorecard">
        <SubmitScorecardContainer />
      </PublicRoute>
      <Route path="/reference">
        <SubmitReferenceContainer />
      </Route>
      <PublicRoute path="/socialmedia/auth/linkedin">
        <LinkedinAuthContainer />
      </PublicRoute>
      <PublicRoute path="/socialmedia/auth/twitter">
        <TwitterAuthContainer />
      </PublicRoute>
      <PublicRoute path="/password_reset">
        <UserUpdatePasswordContainer />
      </PublicRoute>
      {/* ================== */}
      <UnauthenticatedRoute path="/login">
        <LoginContainer />
      </UnauthenticatedRoute>
      <Route path="*">{fallback}</Route>
    </Switch>
  );
};

export default PublicRoutes;
