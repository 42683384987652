import styled from 'styled-components';
import { RatingAnswerProps } from './types';

const RatingAnswer: React.FC<RatingAnswerProps> = ({
  answer,
  index,
  updateRating,
  dataTestId,
  scaleDataTestId,
}) => {
  return (
    <Container>
      <StyledInput
        type="number"
        min="0"
        max={answer.scale}
        value={answer.answer_rating ?? ''}
        onChange={(e) => updateRating(e, index)}
        placeholder="Enter score"
        data-testid={dataTestId}
      />
      <i data-testid={scaleDataTestId}>out of {answer.scale}</i>
    </Container>
  );
};

export default RatingAnswer;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  background-color: #fff;
  padding: 13px 10px;
  border: 0.5px solid #d7d7d7;
  border-radius: 3px;
  height: 48px;
  width: 118px;
  margin-right: 8px;
  margin-top: 6px;
  margin-bottom: 0;

  &::placeholder {
    color: #999999;

    mix-blend-mode: normal;
    opacity: 0.4;
  }
`;
