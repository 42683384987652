import { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import customTagAPI from 'api/customTagAPI';

import Attachments from '../Attachments';
import CandidateHeaderSection from '../CandidateHeaderSection';
import Certification from './Certification';
import CopyAppModal from 'components/CandidatesList/QuickProfileCandidate/CopyAppModal';
import EmailModal from '../../EmailModal';
import HelloSignTemplateModal from 'features/HellosignEmbeddedSigning/helloSignTemplateModal';
import OverviewSection from '../OverviewSection';
import PropTypes from 'prop-types';
import ScorecardModal from '../../ScorecardModal';
import StatusModal from '../../StatusModal';
import CustomTagSection from '../../CustomTag/CustomTagProfile/CustomTagSection';

// end application confirmation page
import auth from 'utils/auth';
import { scorecardType } from 'utils/enums';
import { openUploadDocumentModal, submitAdditionalDocument } from 'utils/profileUtil';
import UploadDocumentModal from 'components/CandidateDashboardApplicationsList/uploadDocumentModal';
import { openPrintDialogue } from '../.../../../../utils/util';
import { validateTagUserType } from '../../CustomTag/util';
import { getATSCredentials } from 'utils/candidateUtils';
import { nimbleTheme } from 'theme';
import { PrintContainer } from 'features/PrintView/styles';
import { PrintDivider } from 'features/PrintView';
import { Print } from '@mui/icons-material';

export default class Basics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailModalOpen: false,
      copyAppModalOpen: false,
      desiredStatus: {},
      statusModalOpen: false,
      districtTags: [],
      // candidate tags
      tags: [this.props.user.tags],
      // if scorecard is passed in query params, open the profile page with the modal open
      scorecardModalOpen: props.scorecardParam !== null ? true : false,
      emailTemplate: {},
      subject: '',
      message: '',
      recipientList: [this.props.user],
      application_to_update: null,
      statusObj: {},
      archiveOtherApps: null,
      schoolappsToModify: null,
      helloSignTemplateModalOpen: false,
      /**** end status update section ****/
    };
    this.getUser = auth.getUser();
    this.isDistrictUser = auth.isDistrictUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.isSchoolUser = auth.isSchoolUser();
    this.openUploadDocumentModal = openUploadDocumentModal.bind(this);
    this.submitAdditionalDocument = submitAdditionalDocument.bind(this);
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    updateUserStatus: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    sources: PropTypes.arrayOf(PropTypes.object).isRequired,
    emailTaggedUsers: PropTypes.func.isRequired,
    updateSchoolApp: PropTypes.func.isRequired,
    scorecardParam: PropTypes.number,
    editLocationQuery: PropTypes.func,
    scorecardSeen: PropTypes.bool,
    emailTemplatesList: PropTypes.array.isRequired,
    printBool: PropTypes.bool.isRequired,
    // not required if on prospect profile
    isDraft: PropTypes.bool,
    incrementScorecardCount: PropTypes.func.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    replaceCandidateInState: PropTypes.func.isRequired,
    updateCandidateInState: PropTypes.func.isRequired,
    fetchEvents: PropTypes.func,
    events: PropTypes.array.isRequired,
    fetchUser: PropTypes.func,
  };

  componentDidMount() {
    if (this.props.printBool) {
      this.props.removeLocationQuery('print');
      openPrintDialogue();
    }

    // check for new tags
    customTagAPI.fetchCustomTags().then((tags) => this.setState({ districtTags: tags }));
  }

  onSend = () => {
    if (this.state.schoolappsToModify) {
      this.handleSchoolAppUpdate();
    }
    return this.onHide();
  };

  handleSchoolAppUpdate = () => {
    // there are more school apps left to be updated, so update the school apps iteratively.
    // If we have multiple schoolapplications updated we have a different event logged on the
    // backend so we have to designate this for the server
    let multipleSchoolapplicationUpdate = false;
    if (this.state.schoolappsToModify.length > 1) {
      multipleSchoolapplicationUpdate = true;
    }
    this.state.schoolappsToModify.forEach((a, index) => {
      if (multipleSchoolapplicationUpdate && index === this.state.schoolappsToModify.length - 1) {
        multipleSchoolapplicationUpdate = this.state.schoolappsToModify.length;
      }
      this.props.updateSchoolApp({
        candidate_id: this.props.user.id,
        application_id: a.id,
        target_status: this.state.statusObj.id,
        archiveOtherApps: this.state.archiveOtherApps,
        multipleSchoolapplicationUpdate,
      });
    });
  };

  onHide = () => {
    this.setState({
      emailTemplate: {},
      subject: '',
      message: '',
      emailModalOpen: false,
      recipientList: [this.props.user],
      application_to_update: null,
      statusObj: {},
      archiveOtherApps: null,
      schoolappsToModify: null,
    });
  };

  viewScorecard = () => {
    this.setState({ scorecardModalOpen: true });
  };

  viewStatusModal = (statusObj) => {
    this.setState({
      statusModalOpen: true,
      desiredStatus: statusObj,
    });
  };

  updateEmailTemplateSelection = (e) => {
    /** selected template will send up id as e.target.value. find in list */
    const emailTemplateProp = this.props.emailTemplatesList.find(
      (template) => template.id === Number(e.target.value)
    );

    if (emailTemplateProp) {
      let emailTemplate = Object.assign({}, emailTemplateProp);
      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }
      this.setState({
        emailTemplate,
        subject: emailTemplate.subject,
        message: emailTemplate.content,
      });
    }
    if (e.target.value === null) {
      this.setState({
        emailTemplate: {},
        subject: '',
        message: '',
      });
    }
  };

  /**
   * Assigns selected tag to the specified candidate.
   */
  assignTag = async (tagId) => {
    const canidate = { ...this.props.user };
    // add tag to user
    await axios.post(`/api/candidate-tags/`, {
      tag: tagId,
      candidate: canidate.id,
    });
    this.props.fetchUser();
  };

  /**
   * Unassigns tag from the candidate/prospect
   */
  removeTag = async (tagId) => {
    await axios.delete(`/api/candidate-tags/${tagId}`);
    this.props.fetchUser();
  };

  render() {
    const { user } = this.props;
    const skipEmailButton = (
      <button className="email-modal-button" onClick={this.onSend}>
        Skip Email
      </button>
    );

    const showDownloadButton =
      (user.profile.resume || user.application.applicationattachment_set.length > 0) &&
      user.application.permissions.download_documents;

    const districtAppSchoolAdmin = this.isSchoolUser;

    const atsCredentials = getATSCredentials(user.credentials);

    return (
      <div className="flex-1 basics" id="basics-container">
        <HeaderSection>
          <CandidateHeaderSection
            user={this.props.user}
            application={this.props.user.application}
            schoolapplications={this.props.user.application.schoolapplications}
            isDraft={this.props.isDraft}
            openEmailModal={() => this.setState({ emailModalOpen: true })}
            openScorecardModal={() => this.setState({ scorecardModalOpen: true })}
            openCopyApplicationModal={() => this.setState({ copyAppModalOpen: true })}
            events={this.props.events}
            updateUserStatus={this.props.updateUserStatus}
            updateSchoolApp={this.props.updateSchoolApp}
            viewStatusModal={this.viewStatusModal}
            emailTemplatesList={this.props.emailTemplatesList}
            adminUsers={this.props.adminUsers}
            newApplicationStatuses={this.props.newApplicationStatuses}
            districtApplicationBeingViewedBySchoolAdmin={districtAppSchoolAdmin}
            openPrintDialogue={openPrintDialogue}
          />
        </HeaderSection>

        <PrintContainer sx={{ padding: '0 !important' }}>
          <PrintDivider />
        </PrintContainer>

        <Section style={{ paddingTop: nimbleTheme.spacing(3) }}>
          <OverviewSection
            isDistrictAdmin={this.isDistrictAdmin}
            isDistrictUser={this.isDistrictUser}
            user={user}
            application={user.application}
            schoolapplications={user.application.schoolapplications}
            districtRole={user.application.role}
            newApplicationStatuses={this.props.newApplicationStatuses}
            updateFullUser={this.props.updateCandidateInState}
            replaceCandidateInState={this.props.replaceCandidateInState}
            fetchEvents={this.props.fetchEvents}
            isDraft={this.props.isDraft}
            candidateSources={this.props.sources}
          />
        </Section>

        {this.state.copyAppModalOpen && (
          <CopyAppModal
            application={user.application}
            user={user}
            show={this.state.copyAppModalOpen}
            onHide={() => this.setState({ copyAppModalOpen: false })}
            updateCandidatePostSave={() => {
              this.props.fetchUser();
            }}
            newApplicationStatuses={this.props.newApplicationStatuses}
          />
        )}

        {this.state.emailModalOpen && (
          <EmailModal
            recipientList={this.state.recipientList}
            show={this.state.emailModalOpen}
            subject={this.state.subject}
            message={this.state.message}
            onHide={this.onHide}
            onSend={this.onSend}
            emailTemplatesList={this.props.emailTemplatesList}
            emailTemplate={this.state.emailTemplate}
            updateEmailTemplateSelection={this.updateEmailTemplateSelection}
            candidate={user}
            application={this.props.applicationBeingQuickviewed}
            showTemplates={true}
            footer={this.state.sendNotification ? skipEmailButton : null}
          />
        )}

        {this.state.scorecardModalOpen && (
          <ScorecardModal
            user={user}
            application={user.application}
            show={this.state.scorecardModalOpen}
            onHide={() => this.setState({ scorecardModalOpen: false })}
            emailTaggedUsers={this.props.emailTaggedUsers}
            roleTitle={user.application.role.title}
            scorecardParam={this.props.scorecardParam}
            removeLocationQuery={this.props.removeLocationQuery}
            scorecardSeen={this.props.scorecardSeen}
            incrementScorecardCount={this.props.incrementScorecardCount}
            scorecard_type={scorecardType.scorecard}
            adminUsers={this.props.adminUsers}
          />
        )}

        {this.state.statusModalOpen && (
          <StatusModal
            user={user}
            show={this.state.statusModalOpen}
            onHide={() => this.setState({ statusModalOpen: false })}
            schoolapplications={user.application.schoolapplications}
            updateSchoolApp={this.props.updateSchoolApp}
            desiredStatus={this.state.desiredStatus}
            canUpdateStatus={this.isDistrictUser}
            updateUserStatus={this.props.updateUserStatus}
            applicationBeingQuickviewed={user.application}
          />
        )}

        {this.state.helloSignTemplateModalOpen && (
          <HelloSignTemplateModal
            show={this.state.helloSignTemplateModalOpen}
            onHide={(cb) =>
              this.setState({ helloSignTemplateModalOpen: false }, () => {
                if (typeof cb === 'function') cb();
              })
            }
            onSend={this.onSend}
            candidate={user}
            application={user.application}
            adminUsers={this.props.adminUsers}
          />
        )}

        {this.state.uploadDocumentModalOpen && (
          <UploadDocumentModal
            show={this.state.uploadDocumentModalOpen ? true : false}
            onHide={() => this.setState({ uploadDocumentModalOpen: false })}
            submitAdditionalDocument={this.submitAdditionalDocument}
            hideInstructions={true}
            postSubmitCallback={() => {
              this.props.fetchUser();
              this.props.fetchEvents();
            }}
          />
        )}

        {/*
          Spec: Hide section if district hasn't created any candidate tags.

          Spec: If there are no tags present in the district that the current
          user can view, the section should be hidden.
        */}

        {this.state.districtTags.length > 0 &&
          // check if current user_type can view any tags in district
          this.state.districtTags.some((tag) => validateTagUserType(auth.getUser(), tag)) && (
            <Section style={{ marginBottom: '0 !important' }}>
              <CustomTagSection
                user={auth.getUser()}
                fullUser={this.props.user}
                assignTag={this.assignTag}
                removeTag={this.removeTag}
                districtTags={this.state.districtTags}
                isFullProfile={true}
              />
            </Section>
          )}

        <PrintContainer sx={{ padding: '0 !important' }}>
          <PrintDivider />
        </PrintContainer>

        <Section>
          <TwoColumnGridContainer>
            <div>
              <Attachments
                partialUser={user}
                fullUser={user}
                districtApplicationBeingQuickviewed={user.application}
                openDocumentUploadModal={this.openUploadDocumentModal}
                openHellosignTemplateModal={() =>
                  this.setState({ helloSignTemplateModalOpen: true })
                }
                // the component expects a function so just return the boolean
                // from that function
                helloSignTemplateModalOpen={this.state.helloSignTemplateModalOpen}
                showDownloadIcon={() => showDownloadButton}
                handleDelete={() => this.props.fetchUser()}
                isCandidateList
              />
            </div>

            <PrintContainer>
              <PrintDivider />
            </PrintContainer>
            <div>
              <Certification
                subjects={atsCredentials}
                hasNoCredentials={user.profile.has_no_credentials || user.credentials?.length === 0}
                isCandidateList
              />
            </div>
          </TwoColumnGridContainer>
        </Section>
      </div>
    );
  }
}

const Section = styled.div`
  padding: 26px;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.095);

  @media print {
    border: none;
    margin-bottom: ${nimbleTheme.spacing(2)};
    padding: 0 !important;
  }
`;

const HeaderSection = styled(Section)`
  padding-bottom: 12px;
  @media print {
    padding: 0;
  }
`;

const TwoColumnGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 54px;

  @media print {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;
