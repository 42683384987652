import { BaseNimbleAPI } from './baseAPI';

class ApplicationStatusAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/application_status' };
  }

  async partial_update(statusId, payload) {
    const response = await this.patch(`/${statusId}/`, payload);
    return response.data;
  }
}

export default new ApplicationStatusAPI();
