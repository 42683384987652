import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { fileNameShortener } from 'utils/util';

import { ReactComponent as DownloadIcon } from 'ui-kit/icons/svg/download-black.svg';

export default function AttachmentAnswer({ answer, urlBase }) {
  const downloadAttachment = () => {
    window.open(`/api/${urlBase}/${answer.id}/download_attachment/`, '_blank');
  };

  const attachmentNameSection = () => {
    if (answer.attachment) {
      // remove the timestamp at front (14 characters long)
      const attachmentName = fileNameShortener(answer.attachment).substring(14);

      return (
        <>
          <DownloadContainer
            onClick={downloadAttachment}
            data-tip
            data-for={`download-${attachmentName}`}
            data-place="bottom"
            data-delay-show="100"
          >
            <DownloadIcon />
            <AttachmentDownloadText>{attachmentName}</AttachmentDownloadText>
          </DownloadContainer>
          <ReactTooltip id={`download-${attachmentName}`} effect="solid">
            <span style={{ color: 'white' }}>Download attachment</span>
          </ReactTooltip>
        </>
      );
    } else {
      return <i>No attachment</i>;
    }
  };

  return (
    <>
      {answer.attachment_directions && <Directions>{answer.attachment_directions}</Directions>}
      {attachmentNameSection()}
    </>
  );
}

const Directions = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #444444;

  margin-bottom: 10px;
`;

const DownloadContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  width: fit-content;
`;

const AttachmentDownloadText = styled.span`
  line-height: 20px;
  margin-left: 2px;

  text-decoration-line: underline;

  color: #444444;
`;
