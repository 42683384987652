import Select from 'react-select';
import { FlatFileTransferCustomSelectPropsInterface } from '../types/types';

export const CustomSelect = (
  props: FlatFileTransferCustomSelectPropsInterface
): React.ReactElement => {
  const getBorderColor = (props) => {
    if (props.isMissingInput) {
      return '#CC0000';
    } else {
      return '#D7D7D7';
    }
  };

  const customSelectStyles = (props) => ({
    singleValue: (styles) => ({
      ...styles,
      color: props.isDisabled ? '#5c5e69' : '#4F4F4F',
      fontWeight: 400,
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: '12px',
      paddingRight: '12px',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: props.isDisabled ? '#efefef' : '#ffffff',
      border: `1px solid ${getBorderColor(props)}`,
      borderRadius: '3px',
      minHeight: '48px',
      margin: '8px 0px',
      color: '#4F4F4F',
      fontWeight: 400,
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused
        ? '#DAE1EE'
        : state.isSelected
          ? '#DAE1EE'
          : styles.backgroundColor,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: '0 0 7px 7px',
      marginBottom: '40px',
      position: 'relative',
    }),
  });

  return (
    <Select
      styles={customSelectStyles(props)}
      placeholder={'Search or Select...'}
      isDisabled={props.isDisabled}
      {...props}
    />
  );
};
