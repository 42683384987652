export const notificationTypes = () => [
  { value: 0, key: 'status_change', label: 'Status change' }, // default
  { value: 1, key: 'new_applicant', label: 'New candidate' },
  { value: 2, key: 'job_request', label: 'Job request' },
  { value: 3, key: 'new_job_posted', label: 'New job posted' },
  { value: 4, key: 'task_assigned', label: 'Task assigned to me' },
  { value: 5, key: 'tagged', label: 'Tagged in note/scorecard' },
  { value: 6, key: 'new_vacancy', label: 'New vacancy added' },
  { value: 7, key: 'new_top_candidate', label: 'New top candidate' },
  { value: 8, key: 'reference_completed', label: 'Reference completed' },
];

export const notificationTypeLabel = notificationTypes().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export const notificationTypeValue = notificationTypes().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export const notificationFrequency = () => [
  { value: 0, key: 'immediate', label: 'Immediate' }, // default
  { value: 1, key: 'daily_digest', label: 'Daily digest' },
  { value: 2, key: 'weekly_digest', label: 'Weekly digest' },
];

export const notificationFrequencyLabel = notificationFrequency().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export const notificationJobOptions = () => [
  { value: 0, key: 'all_jobs', label: 'All jobs' },
  { value: 1, key: 'owned_jobs', label: 'Only jobs where I’m an owner' },
  { value: 2, key: 'certain_categories', label: 'Only certain job categories' },
];

export const notificationJobsLabel = notificationJobOptions().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export const notificationJobsValue = notificationJobOptions().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export const notificationSchoolsPreference = () => [
  { value: 0, key: 'all_schools', label: 'All schools' },
  { value: 1, key: 'only_certain_schools', label: 'Only certain schools' },
];

export const notificationSchools = notificationSchoolsPreference().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export const notifyMeForOptions = [
  { value: 'all_candidates', label: 'All candidates' },
  { value: 'sent_request', label: 'Candidates where I sent a request' },
];

export const notifyMeForMapping = notifyMeForOptions.reduce((obj, item) => {
  obj[item.value] = item.value;
  return obj;
}, {});

export const notifyMeWhenOptions = [
  { value: 'any', label: 'Any reference is completed' },
  { value: 'first', label: 'The first reference is completed for a candidate' },
  { value: 'second', label: 'The second reference is completed for a candidate' },
  {
    value: 'first_and_second',
    label: 'First and second references are completed for a candidate ',
  },
  { value: 'third', label: 'The third reference is completed for a candidate' },
  {
    value: 'first_second_third',
    label: 'First, second and third references are completed for a candidate',
  },
];

export const notifyMeWhenMapping = notifyMeWhenOptions.reduce((obj, item) => {
  obj[item.value] = item.value;
  return obj;
}, {});
