import styled from 'styled-components';

import { GreyText } from '../styledComponents';

import CheckboxContainer from './CheckboxContainer';

export default function HeaderInformation({
  Icon,
  title,
  subtext,
  internalRequirementsSpecified,
  itemMatrix,
  handleItemMatrixChange,
}) {
  return (
    <GridContainer>
      <Icon style={{ alignSelf: 'center', width: '36px', height: '36px' }} />
      <VerticalFlexContainer>
        <Header>{title}</Header>
        <GreyText>{subtext}</GreyText>
      </VerticalFlexContainer>
      <EndItem>
        {internalRequirementsSpecified ? (
          <CheckboxContainer
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
          />
        ) : (
          <RequiredLabel>
            <input
              type="checkbox"
              name="isRequiredExternal"
              style={{ margin: '0 0.5em 0 0' }}
              checked={itemMatrix.isRequiredExternal}
              value={itemMatrix.isRequiredExternal}
              onChange={handleItemMatrixChange}
            />
            Required
          </RequiredLabel>
        )}
      </EndItem>
    </GridContainer>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 54px 1fr 100px;

  margin-bottom: 2rem;
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 2px;
`;

const RequiredLabel = styled.label`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  color: #777777;

  margin: 0;
`;

const EndItem = styled.div`
  justify-self: end;
`;
