import { uniqueId } from 'lodash';
import { CertificationInput } from 'types/connectTypes';

export const CERTIFICATION_FORM_INPUT_TYPE = {
  STATE: 'state',
  SUBJECT: 'subject',
};

export const empty_certification_input = (stateID = null): CertificationInput => {
  return {
    // client_id is used to uniquely identify each certification input during CRUD operations
    client_id: uniqueId(),
    state: stateID,
    isHidden: false,
    errors: { hasSubjectError: false, hasStateError: false },
    subject: null,
  };
};
