import styled from 'styled-components';

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;

  // Collapse action buttons into a column for small screens
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    flex-flow: column-reverse;
  }
`;
