import { useRef, useEffect } from 'react';
import styled from 'styled-components';

export default function CheckButton({
  label,
  id,
  name,
  value,
  initialChecked,
  isConfused,
  onClick,
}) {
  const doesNotComeWithLabel = Boolean(label);

  if (!label) {
    label = <span style={{ opacity: 0 }}>label</span>;
  }

  const checkboxEl = useRef(null);

  useEffect(() => {
    if (checkboxEl.current) checkboxEl.current.indeterminate = isConfused;
  });

  return (
    <Container>
      <StyledCheckButton
        ref={checkboxEl}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={initialChecked}
        data-testid={doesNotComeWithLabel ? 'no-label-checkbox' : `checkbox-for-${name}`}
        onChange={() => {
          onClick(name, checkboxEl.current.checked, value);
        }}
      />
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </Container>
  );
}

const Container = styled.span`
  display: inline-block;
`;

// the width is so that the ellipsis apply
const StyledLabel = styled.label`
  font-weight: normal;
  font-size: 15px;
  display: inline-block;
  margin-right: 20px;
  user-select: none;
  max-width: 335px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledCheckButton = styled.input`
  box-sizing: border-box;

  &:checked,
  &:not(:checked),
  &:indeterminate {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label,
  &:indeterminate + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 18px;
    border-radius: 2px;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:not(checked) + label:before {
    border: 1px solid #aaa;
    background: transparent;
  }

  &:checked + label:before {
    border: 0;
    background: #00b88d;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1) rotate(45deg);
  }

  &:indeterminate + label:before {
    border: 1px solid #00b88d;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:indeterminate + label:after {
    opacity: 1;
    content: '';
    position: absolute;
    top: 8px;
    left: 6px;
    width: 4px;
    height: 0;
    border: none;
    border-bottom: 2px solid #00b88d;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: scale(1);
  }
`;
