import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { BulletPoint } from '../../../ui-kit/icons';
import pencilIcon from '../../../assets/icon-edit-pencil.svg';
import trashIcon from '../../../assets/icons/icon_trash.svg';

import { makeAllUrlsValid } from '../../../utils/util';
import auth from '../../../utils/auth';

import { InternalRoleNote } from '../../../types/types';
import { isNoteAuthor } from './util';

export interface InternalRoleNoteList {
  internalRoleNotes: InternalRoleNote[];
  handleEditClick: (note: InternalRoleNote, index: number) => void;
  handleDeleteClick: (note: InternalRoleNote, index: number) => void;
}

function NotesList({
  internalRoleNotes,
  handleEditClick,
  handleDeleteClick,
}: InternalRoleNoteList): React.ReactElement {
  const sortedinternalRoleNotes = internalRoleNotes.sort((a, b) =>
    a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0
  );

  return (
    <NotesAnimatedContainer extended={false} className="ql-editor">
      {sortedinternalRoleNotes.map((note, index) => (
        <LineItem key={index}>
          <BulletPoint />
          <div>
            <div
              style={{
                lineHeight: '24px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <NoteText
                dangerouslySetInnerHTML={{
                  __html: makeAllUrlsValid(note.text),
                }}
              />
              <NoteIconsContainer>
                {isNoteAuthor(note, auth.getUser()) && (
                  <NotesIcon
                    className="mbhalf"
                    src={pencilIcon}
                    alt="edit-tooltip"
                    data-tip={`Edit`}
                    data-for="edit"
                    onClick={() => handleEditClick(note, index)}
                  />
                )}
                <ReactTooltip id="edit" effect="solid" multiline />
                {(isNoteAuthor(note, auth.getUser()) || auth.isSuperAdminUser()) && (
                  <NotesIcon
                    className="mbhalf"
                    src={trashIcon}
                    alt="delete-tooltip"
                    data-tip={`Delete`}
                    data-for="delete"
                    onClick={() => handleDeleteClick(note, index)}
                  />
                )}
                <ReactTooltip id="delete" effect="solid" multiline />
              </NoteIconsContainer>
            </div>
            <Signature>
              {`${note.created_by?.name || 'Unspecified user'} ${
                note?.created_at ? moment(note.created_at).format('- MM/DD/YYYY [at] h:mm a') : ''
              }`}
              {note.is_edited && ' (edited)'}
            </Signature>
          </div>
        </LineItem>
      ))}
    </NotesAnimatedContainer>
  );
}

export default NotesList;

interface NotesAnimatedContainerProps {
  extended: boolean;
}

const Signature = styled.div`
  font-size: 12px;
  color: var(--darkgray);
`;

const NotesAnimatedContainer = styled.div<NotesAnimatedContainerProps>`
  max-height: 140px;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  margin-bottom: 10px;
  overflow: auto;
  -webkit-transition: max-height 1s; /* Safari */
  transition: max-height 1s;
  transition-timing-function: ease;

  ${({ extended }) =>
    extended
      ? `
    max-height: 500px;
    -webkit-transition: max-height 1s; /* Safari */
    transition: max-height 1s;
    transition-timing-function: ease;
  `
      : ''}
`;

const LineItem = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 12px;
  align-items: baseline;
  margin-bottom: 10px;

  &:hover {
    background-color: #b1ecdb;
  }
`;

const NoteText = styled.div`
  .mention {
    font-weight: bold;
  }
  li {
    margin-left: 20px;
  }
  a {
    color: #0066cc;
    text-decoration: underline;
  }
`;

const NotesIcon = styled.img`
  height: 15px;
  padding: 0px 5px;
  &:hover {
    cursor: pointer;
    filter: invert(48%) sepia(99%) saturate(822%) hue-rotate(126deg) brightness(89%) contrast(101%);
  }
`;

const NoteIconsContainer = styled.div``;
