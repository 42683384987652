import PropTypes from 'prop-types';

ThreeButtonSelect.propTypes = {
  object: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  enumObject: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  firstButton: PropTypes.object.isRequired,
  secondButton: PropTypes.object.isRequired,
  thirdButton: PropTypes.object.isRequired,
};

export default function ThreeButtonSelect({
  object,
  fieldName,
  enumObject,
  handleChange,
  firstButton,
  secondButton,
  thirdButton,
}) {
  return (
    <div className="three-button-select-container">
      <div
        className={`three-button-item first ${
          object[fieldName] === enumObject[firstButton.enumField] ? 'selected' : ''
        }`}
        onClick={() =>
          handleChange({
            target: {
              name: fieldName,
              value: enumObject[firstButton.enumField],
            },
          })
        }
      >
        {firstButton.label}
      </div>
      <div
        className={`three-button-item second ${
          object[fieldName] === enumObject[secondButton.enumField] ? 'selected' : ''
        }`}
        onClick={() =>
          handleChange({
            target: {
              name: fieldName,
              value: enumObject[secondButton.enumField],
            },
          })
        }
      >
        {secondButton.label}
      </div>
      <div
        className={`three-button-item third ${
          object[fieldName] === enumObject[thirdButton.enumField] ? 'selected' : ''
        }`}
        onClick={() =>
          handleChange({
            target: {
              name: fieldName,
              value: enumObject[thirdButton.enumField],
            },
          })
        }
      >
        {thirdButton.label}
      </div>
    </div>
  );
}
