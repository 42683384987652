import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { Form } from './styles';
import { LandingForm } from './components/LandingForm';
import { useQueryFetchCandidateForConnectATSIntegration } from './queries';
import { FormData, FormDataMockResponse } from './types';
import { SuccessPage } from './components/SuccessPage';

export const AdminLandingForm = React.forwardRef(() => {
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const [mockResponse, setMockResponse] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const candidateId = Number(queryParams.get('candidate_id'));
  const { data: candidateInfo } = useQueryFetchCandidateForConnectATSIntegration(candidateId);

  const methods = useForm<FormData>({
    defaultValues: {
      role: null,
      internalNote: '',
    },
  });

  const onSubmit = (data: FormData) => {
    const roleId = data.role?.id;
    const schools = data.role?.schoolroles;
    const schoolsToReturn: string =
      schools.length === 0 ? 'District' : schools?.map(({ school }) => school.name).join(', ');

    const dataToReturn: FormDataMockResponse = {
      roleId,
      candidateId: candidateInfo.id,
      internalNote: data.internalNote,
      jobLocation: schoolsToReturn,
    };

    setHasSubmittedForm(true);
    setMockResponse(dataToReturn);
  };

  return (
    <FormProvider {...methods}>
      {!hasSubmittedForm && (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <LandingForm candidateEmail={candidateInfo?.email} candidateName={candidateInfo?.name} />
        </Form>
      )}
      {hasSubmittedForm && (
        <SuccessPage
          candidateEmail={candidateInfo?.email}
          candidateName={candidateInfo?.name}
          mockResponse={mockResponse}
        />
      )}
    </FormProvider>
  );
});

AdminLandingForm.displayName = 'AdminLandingForm';
