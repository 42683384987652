import { useState, useCallback } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from 'hooks';
import { ReactComponent as DownCaret } from 'assets/icon-down-caret.svg';
import { nimbleTheme } from 'theme';

export const getSourceLabel = (district_source, onProspectList) => {
  const sourceLabel = district_source?.label ?? 'None';

  if (onProspectList) {
    return `Prospect Source: ${sourceLabel}`;
  } else {
    return (
      <>
        <b style={{ marginRight: '4px' }}>Source:</b>
        {sourceLabel}
      </>
    );
  }
};

const SourceDropdown = ({ district_source, handleSourceChange, onProspectList, sources = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdown, isOpen);
  const sourceOptions = sources;

  sources.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Container ref={closeNodeOnClickOutside}>
      <DropdownWrapper>
        <DropdownInput onClick={toggleDropdown}>
          <DropdownLabel>
            {getSourceLabel(district_source, onProspectList, sourceOptions)}
          </DropdownLabel>
          <DropdownCaretSized />
        </DropdownInput>
        {isOpen && (
          <DropdownList onClick={closeDropdown}>
            {sourceOptions.map((source) => (
              <StyledListItem key={source.id} onClick={() => handleSourceChange(source)}>
                {source.label}
              </StyledListItem>
            ))}
          </DropdownList>
        )}
      </DropdownWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  height: 21px;
  background-color: white;
`;

const DropdownWrapper = styled.div`
  position: relative;
  user-select: none;
`;

const DropdownInput = styled.div`
  height: 21px;
  font-size: 15px;

  border: none;
  box-shadow: none;
  border-radius: 3px;

  padding-left: 0;
  padding-right: 0;

  display: flex;
  align-items: center;

  max-width: 264px;
  text-overflow: ellipsis;
`;

const DropdownLabel = styled.div`
  flex: 1;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  margin-right: 8px;

  @media print {
    font-size: ${nimbleTheme.typography.print.fontSize};
  }
`;

const DropdownCaretSized = styled(DownCaret)`
  width: 12px !important;
  height: 12px !important;

  @media print {
    display: none;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 250px;

  max-height: 315px;
  @media screen and (max-width: 768px) {
    max-height: 222px;
  }

  font-size: 15px;

  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 1px 2px rgba(204, 204, 204, 0.5);

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  list-style-type: none;
  z-index: 10;
`;

const StyledListItem = styled.li`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 6px;
  }

  &:last-child {
    padding-bottom: 6px;
  }
`;

export default SourceDropdown;
