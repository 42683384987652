import { Component } from 'react';
import { notify } from 'react-notify-toast';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

import auth from '../../utils/auth';
import LoadingSpinner from '../loadingSpinner';
import { StyledErrorText } from 'components/Login/LoginForm';
import { customFieldPermission } from '../../utils/enums';
import { myColor, myColorWarning } from 'utils/message';
import { formatCurrency } from 'utils/currencyFormat';
import { DatePicker, StyledSelect } from 'ui-kit';
import 'react-dates/initialize';
import { HeaderLabel } from 'components/Profile/styles';
import { nimbleTheme } from 'theme';
import { DefaultContainer, PrintContainer } from 'features/PrintView/styles';
import { ATSCustomCandidateFieldsDataTestIds } from 'data-testids/ATS';

const DatePickerContainer = styled.div`
  margin: 0.7em 0;
  .SingleDatePickerInput {
    margin: 1.3rem 0;
  }
  .SingleDatePickerInput__withBorder {
    height: 42px;
    margin: 0.3rem 0;
    border-radius: 3px;
    border-width: ${(props) => props.errorShow && '1'};
    border-color: ${(props) => props.errorShow && 'red'};
  }
  .DateInput_input {
    height: 40px;
    color: #777777;
    font-size: 15px;
    border-radius: 3px;
    background-color: ${(props) => props.errorShow && '#FDEDEC'};
    border-width: ${(props) => props.errorShow && '1'};
    border-color: ${(props) => props.errorShow && 'red'};
  }
  .SingleDatePickerInput_calendarIcon {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 3px;
  }
`;

const regexEmail =
  /^$|^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

function isNum(v) {
  return /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9]?)?$/.test(v);
  //return /\d/.test(v);
}

const fieldPermission = customFieldPermission().reduce((obj, item) => {
  obj[item.label] = item.value;
  return obj;
}, {});

export default class CustomCandidateFields extends Component {
  constructor(props) {
    super(props);
    this.show = notify.createShowQueue();
    this.state = {
      // copy in fields from parent. Editing and saving will take place in this component.
      custom_fields: [...props.user.custom_fields],
      showSSN: false,
      ssn_loading_spinner: false,
      ssn: undefined,
      focused: new Array(props.user.custom_fields.length).fill(false),
      focusedDob: false,
      showErrorMessage: new Array(props.user.custom_fields.length).fill(false),
      errorMessage: new Array(props.user.custom_fields.length).fill(''),
    };
    this.isSuperAdmin = auth.isSuperAdminUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.instantiateErrorMessages();
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  static propTypes = {
    ssn_required: PropTypes.bool.isRequired,
    birthday_required: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    onBirthdayChange: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        custom_fields: nextProps.user.custom_fields,
        focused: new Array(nextProps.user.custom_fields.length).fill(false),
        showErrorMessage: new Array(nextProps.user.custom_fields.length).fill(false),
        errorMessage: new Array(nextProps.user.custom_fields.length).fill(''),
      },
      () => {
        this.instantiateErrorMessages();
      }
    );
    //this.instantiateErrorMessages();
  }

  instantiateErrorMessages() {
    const showErrorMessages = this.state.showErrorMessage;
    let custom_fields = this.state.custom_fields;
    for (let i = 0; i < showErrorMessages.length; i++) {
      if (this.state.custom_fields[i].field_type === 0) {
        const val = this.state.custom_fields[i].value;
        if (!moment(val).isValid()) {
          if (
            val !== '' &&
            this.state.custom_fields[i].permission === fieldPermission['View & edit']
          ) {
            this.persistentErrorMessages('Invalid value, please select a valid date', i, true);
          }
        }
      } else if (this.state.custom_fields[i].field_type === 2) {
        if (
          !this.state.custom_fields[i].multichoice_options
            .concat('')
            .includes(this.state.custom_fields[i].value) &&
          this.state.custom_fields[i].permission === fieldPermission['View & edit']
        ) {
          this.persistentErrorMessages('Invalid option, please select one from the list', i, true);
        }
      } else if (this.state.custom_fields[i].field_type === 1) {
        if (
          isNum(this.state.custom_fields[i].value) === false &&
          this.state.custom_fields[i].value !== '' &&
          this.state.custom_fields[i].permission === fieldPermission['View & edit']
        ) {
          this.persistentErrorMessages('Invalid input, please enter a valid number', i, true);
        } else {
          let value_formatted = '';
          if (
            this.state.custom_fields[i].value.includes('.') &&
            this.state.custom_fields[i].value.slice(-1) !== '.'
          ) {
            value_formatted = formatCurrency(this.state.custom_fields[i].value, 'blur');
            custom_fields[i].value = value_formatted;
            this.setState({});
          } else {
            value_formatted = formatCurrency(this.state.custom_fields[i].value, '');
          }

          const last_char = typeof value_formatted === 'undefined' ? '' : value_formatted.slice(-1);

          if (
            (value_formatted === '$' || last_char === '.') &&
            this.state.custom_fields[i].value !== '' &&
            this.state.custom_fields[i].permission === fieldPermission['View & edit']
          ) {
            this.persistentErrorMessages('Invalid input, please enter a valid number', i, true);
          }
        }
      } else if (this.state.custom_fields[i].field_type === 3) {
        const testEmail = regexEmail.test(this.state.custom_fields[i].value);

        if (
          !testEmail &&
          this.state.custom_fields[i].permission === fieldPermission['View & edit']
        ) {
          this.persistentErrorMessages('Invalid input, please enter a valid url', i, true);
        }
      }
    }
    this.setState({
      custom_fields,
    });
  }

  handleChange = (e, i, id) => {
    let custom_fields = this.state.custom_fields;
    let custom_field = custom_fields.find((f) => f.id === id);

    custom_field.value = e.target.value;
    this.setState({ custom_fields });
    const testEmail = regexEmail.test(e.target.value);

    if (testEmail === true) {
      this.persistentErrorMessages('', i, false);
    } else {
      this.persistentErrorMessages('Enter a valid URL', i, true);
    }
  };

  handleChangeText = (e, id) => {
    let custom_fields = this.state.custom_fields;
    let custom_field = custom_fields.find((f) => f.id === id);
    custom_field.value = e.target.value;
    this.setState({ custom_fields });
  };

  handleChangeSelect = (obj, i, id) => {
    let custom_fields = this.state.custom_fields;
    let custom_field = custom_fields.find((f) => f.id === id);
    obj === null ? (custom_field.value = '') : (custom_field.value = obj.label);
    this.setState({ custom_fields: custom_fields });
    this.saveCustomField('Select from list', custom_field);
    this.persistentErrorMessages('', i, false);
  };

  handleChangeDate = (date, i, id) => {
    let custom_fields = this.state.custom_fields;
    let custom_field = custom_fields.find((f) => f.id === id);
    if (moment(date).isValid() === true) {
      this.persistentErrorMessages('', i, false);
      custom_field.value = moment(date).format('MM/DD/YYYY');
    } else {
      this.persistentErrorMessages('Enter date in format MM/DD/YYYY', i, true);
      // this.show('Invalid date format', 'custom', 800, myColorWarning);
      custom_field.value = null;
    }
    this.setState({ custom_fields });

    if (moment(date).isValid() === true) {
      this.saveCustomField('Date', custom_field);
    }
  };

  handleBlur = (e, i, id) => {
    let custom_field = this.state.custom_fields.find((f) => f.id === id);
    custom_field.value = e.target.value;
    if (e.target.value === '$' || e.target.value.slice(-1) === '.') {
      this.persistentErrorMessages('Enter a valid value', i, true);
    }
    this.saveCustomField('Currency', custom_field);
  };

  handleBlurText = (e, id) => {
    let custom_field = this.state.custom_fields.find((f) => f.id === id);
    custom_field.value = e.target.value;
    this.saveCustomField('Text', custom_field);
  };

  handleBlurDatePicker = (e) => {
    if (e.date === null) {
      this.show('Invalid date format', 'custom', 800, myColorWarning);
    }
  };

  persistentErrorMessages = (customError, id, status) => {
    let showErrorMessage = this.state.showErrorMessage;
    showErrorMessage[id] = status;
    this.setState({ showErrorMessage });
    let errorMessage = this.state.errorMessage;
    errorMessage[id] = customError;
    this.setState({ errorMessage });
  };

  saveCustomField = (fieldType, custom_field) => {
    axios
      .put(`/api/candidate_field/${custom_field.id}/`, custom_field)
      .then((r) => {
        if (r.status === 200) {
          this.show(fieldType.concat(' field saved'), 'custom', 1000, myColor);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.show('Unable to save custom field', 'custom', 2000, myColorWarning);
      });
  };

  getSSN = () => {
    let ssnToken = this.props.user.profile.ssn;
    if (ssnToken === undefined || ssnToken === '') {
      // The candidate does not have an SSN on her profile in this case.
      return '';
    } else {
      if (this.state.showSSN && this.state.ssn !== undefined) {
        return this.state.ssn;
      } else {
        return '***-**-****';
      }
    }
  };

  getSSNfromVGS = async () => {
    // Exchange ssn token for plaintext ssn. Super admins only.
    let response = await axios.get(`/api/user/${this.props.user.id}/get_ssn/`);
    return response;
  };

  revealSSN = async (e) => {
    e.preventDefault();
    // if plaintext ssn has already been retrieved, just show it. no
    // need to exchange the token.
    if (this.state.ssn) {
      this.setState({ showSSN: true });
    } else {
      // in this case, ssn is still a token, so exchange the token on the backend.
      this.setState({ ssn_loading_spinner: true });
      try {
        let response = await this.getSSNfromVGS();
        this.setState({
          ssn: response.data?.ssn,
          showSSN: true,
        });
      } catch (err) {
        this.show(
          `Oops, there was a problem retrieving this candidate's SSN.
           Please contact support@hirenimble.com for assistance.`,
          'custom',
          -1,
          myColorWarning
        );
      } finally {
        this.setState({
          ssn_loading_spinner: false,
        });
      }
    }
  };

  hideSSN = (e) => {
    e.preventDefault();
    this.setState({ showSSN: false });
  };

  renderSSNButtonOrLoader = (ssn) => {
    if (this.state.ssn_loading_spinner) {
      return (
        <LoadingSpinner
          margin={0}
          fontSize={1}
          additionalStyles={{
            position: 'absolute',
            top: '43px',
            right: '12px',
          }}
        />
      );
    } else if (ssn === '***-**-****') {
      return (
        <button
          className="view-ssn"
          onClick={this.revealSSN}
          data-testid={ATSCustomCandidateFieldsDataTestIds.VIEW_SSN_BUTTON}
        >
          (view)
        </button>
      );
    } else {
      return (
        <button
          className="view-ssn"
          onClick={this.hideSSN}
          data-testid={ATSCustomCandidateFieldsDataTestIds.HIDE_SSN_BUTTON}
        >
          (hide)
        </button>
      );
    }
  };

  renderSSNSection = () => {
    let ssn = this.getSSN();
    return (
      <div
        className="custom-field relative"
        data-testid={ATSCustomCandidateFieldsDataTestIds.SSN_SECTION}
      >
        <p
          className="custom-field-title"
          data-testid={ATSCustomCandidateFieldsDataTestIds.SSN_HEADER}
        >
          SSN
        </p>
        <input
          className="form-field"
          type="text"
          value={ssn}
          disabled
          data-testid={ATSCustomCandidateFieldsDataTestIds.SSN_INPUT}
        />
        {ssn && this.renderSSNButtonOrLoader(ssn)}
      </div>
    );
  };

  handleCurrency = (e, i, id) => {
    const amount = e.target.value.replace(/[^\d,$.]/, '');
    const check = /[^\d,$.]/.test(amount);
    let custom_fields = this.state.custom_fields;
    let custom_field = custom_fields.find((f) => f.id === id);
    let value_formatted = null;

    if (check === false && amount !== '') {
      value_formatted = formatCurrency(amount, '');
    } else {
      value_formatted = amount;
    }
    custom_field.value = value_formatted;

    this.setState({ custom_fields });
    const last_char = typeof value_formatted === 'undefined' ? '' : value_formatted.slice(-1);

    if ((value_formatted !== '$' && last_char !== '.' && check === false) || amount === '') {
      this.persistentErrorMessages('', i, false);
    } else {
      this.persistentErrorMessages('Enter a valid value', i, true);
    }
  };

  testAndSaveUrl = (e, id) => {
    const testEmail = regexEmail.test(e.target.value);

    if (testEmail === true) {
      let custom_field = this.state.custom_fields.find((f) => f.id === id);
      custom_field.value = e.target.value;
      this.saveCustomField('Link ', custom_field);
    } else {
      this.show('Unable to save link field', 'custom', 2000, myColorWarning);
    }
  };

  mapMultichoice = (multichoice_array) => {
    var map_multichoice = [];
    var i = 0;
    multichoice_array.forEach(function (element) {
      map_multichoice.push({ label: element, value: i });
      i++;
    });
    return map_multichoice;
  };

  loadSelect = (multichoice_array, value) => {
    const map_choice = this.mapMultichoice(multichoice_array);
    let custom_option = map_choice.find((f) => f.label === value);
    return custom_option;
  };

  loadCurrency = (value) => {
    let val = value;
    if (isNum(val) === false) {
      return val;
    }
    if (typeof value !== 'undefined') {
      val = formatCurrency(value, '');
    }
    return val;
  };

  onFocusChange = (id, focused) => {
    let focusedArr = this.state.focused;
    focusedArr[id] = focused;

    this.setState({ focused: focusedArr });
  };

  customFilter = (option, inputValue) => {
    const reg = new RegExp(`^${inputValue.toLowerCase()}`);
    if (reg.test(option.label) || reg.test(option.label.toLowerCase())) {
      return true;
    }
    return false;
  };

  getPlaceholder = (placeholder, i, id) => {
    let custom_fields = this.state.custom_fields;
    let custom_field = custom_fields.find((f) => f.id === id);
    if (custom_field.value === '') {
      return placeholder;
    } else {
      return custom_field.value;
    }
  };

  render() {
    const onFullProfilePage = window.location.href.indexOf('profile') !== -1;

    const canViewSSN = this.props.user.profile?.permissions?.view_ssn ?? false;
    const canViewBirthdate = this.props.user.profile?.permissions?.view_birthday ?? false;
    var birthdate = this.props.user.profile?.birthday;

    const showSSN = this.props.ssn_required && canViewSSN;
    const showDOB = this.props.birthday_required && canViewBirthdate;
    const showCustomFields = this.state.custom_fields.length > 0;

    // Don't render the section if there is nothing to show
    if (!showSSN && !showDOB && !showCustomFields) {
      return null;
    }

    const selectStyles = {
      control: (styles, state) => ({
        ...styles,
        minHeight: '40px',
        height: '42px',
        margin: '0.7rem 0',
        boxShadow: 'none',
        borderColor: '#d7d7d7',
        borderStyle: 'solid',
        borderWidth: 1,
        '&:hover': {
          boxShadow: 'none',
          borderColor: '#d7d7d7',
          borderStyle: 'solid',
          borderWidth: 1,
        },
        '&:focus': {
          borderColor: 'none',
          boxShadow: state.isFocused ? 'black' : 'none',
        },
      }),
    };

    return (
      <Container
        className={`custom-field-container ${onFullProfilePage ? 'fp' : 'qv'}`}
        style={{ border: '1px solid #e0e0e0', borderRadius: '4px', padding: '16px' }}
        data-testid={ATSCustomCandidateFieldsDataTestIds.CONTAINER}
      >
        <HeaderLabel data-testid={ATSCustomCandidateFieldsDataTestIds.HEADING}>
          Custom Profile Fields
        </HeaderLabel>
        <DefaultContainer
          className="custom-field-list"
          data-testid={ATSCustomCandidateFieldsDataTestIds.LIST_CONTAINER}
        >
          {showSSN && this.renderSSNSection()}
          {showDOB && (
            <div
              className="custom-field"
              data-testid={ATSCustomCandidateFieldsDataTestIds.DOB_SECTION}
            >
              <div>
                <p
                  className="custom-field-title"
                  data-testid={ATSCustomCandidateFieldsDataTestIds.DOB_TITLE}
                >
                  Date of Birth
                </p>
                <DatePickerContainer>
                  <DatePicker
                    date={moment(birthdate).isValid() === true ? moment(birthdate) : null}
                    onDateChange={(date) => (birthdate = moment(date).format('MM/DD/YYYY'))}
                    focused={this.state.focusedDob}
                    onFocusChange={({ focused }) => this.setState({ focusedDob: focused })}
                    placeholder="MM/DD/YYYY"
                    id="custom-field-type-date"
                    //onOutsideClick={true}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    displayFormat="MM/DD/YYYY"
                    disabled={true}
                  />
                </DatePickerContainer>
              </div>
            </div>
          )}
          {this.state.custom_fields.map((field, i) => (
            <div className="custom-field" key={i}>
              <p
                className="custom-field-title"
                data-testid={ATSCustomCandidateFieldsDataTestIds.FIELD_TITLE}
              >
                {field.customprofilefield}
              </p>
              {field.field_type === 0 && (
                <DatePickerContainer errorShow={this.state.showErrorMessage[i]}>
                  <DatePicker
                    date={moment(field.value).isValid() === true ? moment(field.value) : null}
                    onDateChange={(date) => this.handleChangeDate(date, i, field.id)}
                    focused={this.state.focused[i]}
                    onFocusChange={({ focused }) => this.onFocusChange(i, focused)}
                    placeholder={this.getPlaceholder('MM/DD/YYYY', i, field.id)} //"MM/DD/YYYY"
                    id={'custom-field-type-date'.concat(i)}
                    onClose={(e) => this.handleBlurDatePicker(e)}
                    //onOutsideClick={true}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    displayFormat="MM/DD/YYYY"
                    disabled={field.permission === fieldPermission['View only']}
                  />
                </DatePickerContainer>
              )}
              {field.field_type === 1 && (
                <input
                  type="text"
                  className="form-field"
                  style={{
                    resize: 'none',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    backgroundColor:
                      this.state.showErrorMessage[i] === true
                        ? '#FDEDEC'
                        : field.permission === fieldPermission['View only'] && '#f2f2f2',
                    borderColor: this.state.showErrorMessage[i] === true && 'red',
                  }}
                  id="amount"
                  min={0}
                  placeholder={this.getPlaceholder('e.g. $1,234.56', i, field.id)}
                  value={this.loadCurrency(field.value) || ''}
                  onChange={(e) => this.handleCurrency(e, i, field.id)}
                  onBlur={(e) => this.handleBlur(e, i, field.id)}
                  disabled={field.permission === fieldPermission['View only']}
                  data-testid={ATSCustomCandidateFieldsDataTestIds.CURRENCY_INPUT}
                />
              )}
              {field.field_type === 2 && (
                <StyledSelect
                  styles={selectStyles}
                  className={`flex-1 ${
                    this.state.showErrorMessage[i] === true && 'styled-select-warning'
                  }`} //"flex-1"
                  options={this.mapMultichoice(field.multichoice_options)}
                  placeholder={this.getPlaceholder('Choose one option', i, field.id)}
                  value={this.loadSelect(field.multichoice_options, field.value)}
                  onChange={(obj) => this.handleChangeSelect(obj, i, field.id)}
                  filterOption={this.customFilter}
                  autoBlur
                  isClearable
                  isDisabled={field.permission === fieldPermission['View only']}
                />
              )}
              {field.field_type === 3 && (
                <input
                  type="text"
                  className="form-field"
                  style={{
                    resize: 'none',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    backgroundColor:
                      this.state.showErrorMessage[i] === true
                        ? '#FDEDEC'
                        : field.permission === fieldPermission['View only'] && '#f2f2f2',
                    borderColor: this.state.showErrorMessage[i] === true && 'red',
                  }}
                  placeholder={this.getPlaceholder('e.g. https://...', i, field.id)}
                  value={field.value || ''}
                  onChange={(e) => this.handleChange(e, i, field.id)}
                  onBlur={(e) => this.testAndSaveUrl(e, field.id)}
                  disabled={field.permission === fieldPermission['View only']}
                  data-testid={ATSCustomCandidateFieldsDataTestIds.LINK_INPUT}
                />
              )}
              {field.field_type === 4 && (
                <input
                  type="text"
                  className="form-field"
                  style={{
                    backgroundColor: field.permission === fieldPermission['View only'] && '#f2f2f2',
                  }}
                  placeholder="enter text"
                  value={field.value}
                  disabled={field.permission === fieldPermission['View only']}
                  onChange={(e) => this.handleChangeText(e, field.id)}
                  onBlur={(e) => this.handleBlurText(e, field.id)}
                  data-testid={ATSCustomCandidateFieldsDataTestIds.TEXT_INPUT}
                />
              )}
              {this.state.showErrorMessage[i] === true && (
                <StyledErrorText>{this.state.errorMessage[i]}</StyledErrorText>
              )}
            </div>
          ))}
        </DefaultContainer>
        <PrintList>
          <CPFList numberOfCustomFields={this.state.custom_fields.length}>
            {this.state.custom_fields.map((field) => (
              <li key={field.id}>
                <b>{field.customprofilefield}</b>: <>{field?.value ? field.value : 'No answer'}</>
              </li>
            ))}
          </CPFList>
        </PrintList>
      </Container>
    );
  }
}

const CPFList = styled.div`
  @media print {
    column-count: ${({ numberOfCustomFields }) => (numberOfCustomFields < 6 ? 1 : 2)};};
}`;

const Container = styled.div`
  @media print {
    border: none !important;
    padding: 0 !important;
  }
`;

const PrintList = styled(PrintContainer)`
  @media print {
    display: flex;
    flex-direction: column;
    font-size: ${nimbleTheme.typography.print.fontSize};
  }
`;
