import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'ui-kit';
import RoleAndStatusSelection from './RoleAndStatusSelection';
import SchoolSelection from './SchoolSelection';
import ModalFooterNew from '../../../common/Modal/ModalFooterNew';

import prospectDetailAPI from 'api/prospectDetailAPI';
import RolesAPI from 'api/rolesAPI';
import { appStatusType } from 'utils/enums';
import { useHistory } from 'react-router-dom';

function ConvertToCandidateModal({ isOpen, onClose, fullUser }) {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [applicationList, setApplicationList] = useState([newApplication()]);
  const [errorMessage, setErrorMessage] = useState('');
  const [schoolSelection, setSchoolSelection] = useState(false);
  // If the prospect is being moved into "Hired", and there are multiple schoolroles
  // for that role, the user must choose a school to be hired into. Store the index of
  // the application and the ID of the selected schoolrole here.
  const [hiredApplicationIdx, setHiredApplicationIdx] = useState(null);
  const [schoolroleId, setSchoolroleId] = useState(null);
  const history = useHistory();

  useEffect(() => {
    RolesAPI.fetchProspectConversionRoles().then((results) => setAvailableRoles(results));
  }, []);

  function newApplication() {
    return {
      role: {},
      status: {},
    };
  }

  function addNewApplicationToList() {
    const newApplicationList = [...applicationList];
    newApplicationList.push(newApplication());
    setApplicationList(newApplicationList);
  }

  function handleRoleChange(value, index) {
    let role = availableRoles.find((role) => role.id === value);
    if (role === undefined) {
      // user is clearing the input dropdown
      role = {};
    }

    const newApplicationList = [...applicationList];
    newApplicationList[index]['role'] = role;
    setApplicationList(newApplicationList);
    setErrorMessage('');
  }

  function handleStatusChange(status, index) {
    const newApplicationList = [...applicationList];
    newApplicationList[index]['status'] = status;
    setApplicationList(newApplicationList);
    setErrorMessage('');
  }

  function handleSchoolChange(id) {
    setSchoolroleId(id);
    setErrorMessage('');
  }

  function removeApplication(index) {
    const newApplicationList = [...applicationList];
    newApplicationList.splice(index, 1);
    setApplicationList(newApplicationList);
    setErrorMessage('');
  }

  function convertToCandidate() {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    if (!schoolSelection && hiredAndMultipleSchoolRoles()) {
      setSchoolSelection(true);
    } else {
      submitConversion();
    }
  }

  function checkForErrors() {
    if (schoolSelection) {
      checkSchoolNotNull();
    } else {
      checkApplications();
      checkRolesAreUnique();
      checkHiredApplications();
    }
  }

  function checkSchoolNotNull() {
    if (schoolroleId === null) {
      throw new Error('Please select a school for the hired application');
    }
  }

  function checkApplications() {
    applicationList.forEach((application) => {
      if (!application.role.id || !application.status.id) {
        throw new Error('Please select a target role and status for each line item');
      }
    });
  }

  function checkRolesAreUnique() {
    const uniqueSet = new Set();
    applicationList.forEach((application) => {
      if (uniqueSet.has(application.role.id)) {
        throw new Error('Please ensure the target roles are unique');
      }
      uniqueSet.add(application.role.id);
    });
  }

  function checkHiredApplications() {
    // Make sure there is not more than one "Hired" application.
    let hiredApplicationExists = false;
    applicationList.forEach((application) => {
      if (application.status.status_type === appStatusType.hired) {
        if (hiredApplicationExists) {
          throw new Error('Cannot add a hired candidate for more than one job');
        } else {
          hiredApplicationExists = true;
        }
      }
    });
  }

  function hiredAndMultipleSchoolRoles() {
    /**
     * If multiple school roles are associated with the job the user is hired into,
     * bring up school selection modal after the first modal to select for which school
     * they are hired; all other school apps should be added as archived.
     */
    for (let i = 0; i < applicationList.length; i++) {
      if (applicationList[i].status.status_type === appStatusType.hired) {
        // target status is "Hired", now see if we need to open the school selection modal
        if (applicationList[i].role.schoolroles.length > 1) {
          setHiredApplicationIdx(i);
          return true;
        }
      }
    }
    return false;
  }

  function submitConversion() {
    const application_list = applicationList.map((application) => ({
      role_id: application.role.id,
      status_id: application.status.id,
    }));
    const updateObject = {
      application_list,
      schoolrole_id: schoolroleId,
    };

    prospectDetailAPI.convertToCandidate(fullUser.prospect_id, updateObject).then((data) => {
      history.push(`/district/profile/${fullUser.id}/?application=${data.application_id}`);
    });
  }

  return (
    <Modal.Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Convert Prospect to Candidate</Modal.Title>
      <Modal.Body>
        {schoolSelection ? (
          <SchoolSelection
            schoolroleId={schoolroleId}
            schoolroles={applicationList[hiredApplicationIdx].role.schoolroles}
            handleSchoolChange={handleSchoolChange}
          />
        ) : (
          <RoleAndStatusSelection
            addNewApplicationToList={addNewApplicationToList}
            applicationList={applicationList}
            availableRoles={availableRoles}
            handleRoleChange={handleRoleChange}
            handleStatusChange={handleStatusChange}
            removeApplication={removeApplication}
          />
        )}
      </Modal.Body>
      <Modal.Actions>
        <ModalFooterNew
          cancel={onClose}
          save={convertToCandidate}
          errorMessage={errorMessage}
          saveButtonLabel="Save"
        />
      </Modal.Actions>
    </Modal.Modal>
  );
}

ConvertToCandidateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConvertToCandidateModal;
