import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { ComboBox } from 'sharedComponents/ComboBox';
import { OptionTitle } from '../styles';
import { useQuerySearchActiveOpenRoles } from '../queries';
import { JobComboBoxProps } from '../types';

export const JobComboBox: React.FC<JobComboBoxProps> = ({ hasError, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);
  const { data: searchResults, isLoading: isDataLoading } = useQuerySearchActiveOpenRoles(
    debouncedInputValue,
    {
      enabled: debouncedInputValue.length > 0,
      keepPreviousData: true,
      staleTime: 60000,
      cacheTime: 120000,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const handleInputChange = (text: string) => {
    setInputValue(text);
  };

  const isLoading = isDataLoading && Boolean(inputValue);

  return (
    <ComboBox
      dataTestId={ATSConnectImportCandidateFormTestIds.JOB_COMBOBOX}
      getOptionLabel={(option) => option.title || ''}
      handleSetSearchInputValue={handleInputChange}
      hasError={hasError}
      inputValue={inputValue}
      loading={isLoading}
      onChange={onChange}
      options={searchResults ? searchResults : []}
      placeholder="Type or select job"
      renderOption={(props, option) => (
        <Box {...props} component="li" key={option.id}>
          <OptionTitle>{option.title}</OptionTitle>
        </Box>
      )}
    />
  );
};
