import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, styled, SxProps } from '@mui/material';
import { nimbleTheme } from 'theme';

export interface DeleteButtonProps {
  onClick: () => void;
  sx?: SxProps;
  dataTestId: string;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, sx, dataTestId }) => {
  return (
    <StyledIconButton onClick={onClick} sx={sx} data-testid={dataTestId}>
      <StyledDeleteIcon />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const StyledDeleteIcon = styled(DeleteOutlineOutlinedIcon)({
  '&:hover': {
    fill: nimbleTheme.palette.error.main,
    transition: 'fill 0.2s ease',
  },
});
