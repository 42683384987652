import { BaseNimbleAPI } from './baseAPI';

class ReferenceTemplateDetailAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/reference_template' };
  }

  async fetchReference(referenceId) {
    const response = await this.get(`/${referenceId}/`);
    return response.data;
  }

  async updateReference(reference) {
    const response = await this.put(`/${reference.id}/`, reference);
    return response.data;
  }

  async deleteReference(referenceId) {
    const response = await this.delete(`/${referenceId}/`);
    return response.data;
  }
}

export default new ReferenceTemplateDetailAPI();
