import auth from '../../../utils/auth';
import { notificationTypes, notificationTypeValue } from '../../../utils/NotificationEnums';
import { appStatusType } from '../../../utils/enums';

export function getNotificationTypeOptions(type, hasReferenceCompletedNotification) {
  let typeOptions = notificationTypes();
  if (auth.isDistrictUserOnly()) {
    typeOptions = typeOptions.filter(districtUserTypeOptions);
  }
  if (auth.isSchoolUser()) {
    typeOptions = typeOptions.filter(schoolAdminTypeOptions);
  }

  // According to spec the user may only have at most one reference completed notification.
  // If they already have one, don't show that notification type in the modal dropdown
  // unless they are currently editing that notification.
  if (type !== notificationTypeValue.reference_completed && hasReferenceCompletedNotification) {
    typeOptions = typeOptions.filter(nonReferenceCompleted);
  }
  return typeOptions;
}

function districtUserTypeOptions(option) {
  return (
    option.value !== notificationTypeValue.job_request &&
    option.value !== notificationTypeValue.new_vacancy
  );
}

function schoolAdminTypeOptions(option) {
  return (
    option.value !== notificationTypeValue.job_request &&
    option.value !== notificationTypeValue.new_applicant &&
    option.value !== notificationTypeValue.new_vacancy
  );
}

function nonReferenceCompleted(option) {
  return option.value !== notificationTypeValue.reference_completed;
}

export function formatCategoryOptions(categories) {
  return categories.map((category) => ({
    value: category.id,
    ...category,
  }));
}

export function formatSchoolOptions(schools) {
  return schools.map((school) => ({
    label: school.name,
    value: school.id,
    ...school,
  }));
}

export function statusVisibleToSchoolAdmin(status) {
  return status.school_admin_view_access === true;
}

export function statusIsNotDraft(status) {
  return status.status_type !== appStatusType.draft;
}

export function noStatusSelected(status_id) {
  return status_id === null;
}

export function filterStatusOptions(statuses) {
  statuses = statuses.filter(statusIsNotDraft);
  if (auth.isSchoolUser()) {
    statuses = statuses.filter(statusVisibleToSchoolAdmin);
  }
  return statuses;
}

export function getStatusOptions(statuses) {
  statuses = filterStatusOptions(statuses);

  return statuses.map((status) => ({
    value: status.id,
    label: status.label,
    ...status,
  }));
}

export function getInitialCategories(notification) {
  return notification.job_categories.map((category) => ({
    value: category.id,
    ...category,
  }));
}

export function getInitialSchools(notification) {
  return notification.schools.map((school) => ({
    label: school.name,
    value: school.id,
    ...school,
  }));
}

export const showJobsDropdown = (type) => {
  if (!auth.isDistrictAdmin()) return false;
  return [
    notificationTypeValue.status_change,
    notificationTypeValue.new_applicant,
    notificationTypeValue.new_vacancy,
  ].includes(type);
};

export const showSchoolsDropdown = (type) =>
  auth.isDistrictAdmin() && type === notificationTypeValue.status_change;

export function frequencyDropdownDisabled(value) {
  return value === notificationTypeValue.new_top_candidate;
}
