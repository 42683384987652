import { IconButton, styled } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const ViewButton: React.FC = () => {
  return (
    <StyledButton size="small">
      <ViewIcon />
    </StyledButton>
  );
};

const StyledButton = styled(IconButton)({
  padding: '8px',
});

const ViewIcon = styled(ArrowForwardIosIcon)({
  width: '20px',
  height: '20px',
});
