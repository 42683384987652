import { Component } from 'react';

import PropTypes from 'prop-types';

import { examType, examMapping } from '../../../utils/enums';

const EXAMS = examType().map((exam) => {
  return {
    value: exam.value,
    label: exam.label,
  };
});

export default class Exams extends Component {
  static propTypes = {
    exams: PropTypes.arrayOf(Object).isRequired,
    simpleList: PropTypes.bool,
  };

  getItem(list, value, examID) {
    value = parseInt(value, 10);
    const option = list.find((o) => o.value === value);
    if (!option) {
      return 'Exam details not provided.';
    }
    if (option.label === 'Other') {
      const exam = this.props.exams.find((e) => e.id === examID);
      return exam['other_exam_name'] || 'Test name not provided';
    }
    return option && option.label;
  }

  getItemSectionAndScore = (exam) => {
    let results = [];
    // 'Other' exams
    if (exam['other_exam_name']) {
      let displayName = 'Score';
      let score = exam['other_exam_score'] || 'N/A';
      if (exam['other_exam_possible_score']) {
        score += ` out of ${exam['other_exam_possible_score']}`;
      }
      results.push(`${displayName}: ${score}`);
    } else {
      Object.keys(exam).forEach((section) => {
        let displayName = '';
        let score = 'N/A';
        if (
          exam[section] &&
          !['id', 'updated', 'type', 'other_exam_name'].includes(section) &&
          exam.type > -1
        ) {
          displayName = examMapping[exam.type].fields.find((f) => f.fieldName === section);
          if (displayName) {
            displayName = displayName.displayName;
            score = exam[section];
            results.push(`${displayName}: ${score}`);
          }
        }
      });
    }
    return results;
  };

  render() {
    const exams = this.props.exams;

    if (this.props.simpleList || this.props.simpleCard) {
      return (
        <div className="simple-card-container">
          {exams.map((row, i) => (
            <div className="simple-card mb1" key={i}>
              <div className="bold">{this.getItem(EXAMS, row.type, row.id)}</div>
              <div>
                {this.getItemSectionAndScore(row).map((item) => (
                  <div key={item.substr(3)}>{item}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div>
        {exams.map((row, i) => (
          <div className="added-row" key={i}>
            <span className="block flex-1">
              <div className="bold">{this.getItem(EXAMS, row.type, row.id)}</div>
              <div>
                {this.getItemSectionAndScore(row).map((item) => (
                  <div key={item.substr(3)}>{item}</div>
                ))}
              </div>
            </span>
            <span className="flex-1" />
          </div>
        ))}
      </div>
    );
  }
}
