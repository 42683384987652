import './EmailModalOverrides.scss';

import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import dedent from 'dedent-js';
import ReactTooltip from 'react-tooltip';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { space } from 'styled-system';

import auth from '../../utils/auth';
import iconPaperclip from '../../assets/icon-paperclip.svg';
import iconClose from '../../assets/icon-close-x.svg';
import { showWarning } from '../../utils/message';
import { bytesToSize } from '../../utils/util';
import { DropdownWithInputFilter, AltModal, EmailSelect, Input, Label } from 'ui-kit';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import SearchAPI from 'api/searchAPI';

// AltModal breaks react-quill formatting, so keep react-bootstrap modal
// as the container until we figure out how to fix that
import { Modal } from 'react-bootstrap';
import { emailVariableKeywords } from 'utils/enums';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';
import { ATSCandidateListBulkEmail } from 'data-testids/ATS';

/**
 * A note about EmailModal:
 *
 * Various places in the system use this to pop a modal which knows how to:
 * 1) render a given template
 * 2) send the actual message, through the django backend.
 * 3) send attachments, through the django backend.
 *
 * As of https://goo.gl/WS4WLb, this functionality is required to be shared in more places, with
 * various template contents. The templates must be parameterized on real data about the sender
 * and recipient (e.g. name, district name, ...).
 *
 * To that end, for the subject and message properties, various dynamic substitutions will be
 * supported:
 *
 * * '%{SENDER}.{X}' will pull the field 'X' out of the corresponding sender or
 *   recipient user object. Dots will work as expected, .e.g. %SENDER.profile.middle_name% will
 *   work.
 *
 * * Because storing emails in source code is easy but ugly, we will make various beatifications:
 *   * A single newline will be replaced with a single space.
 *   * N>1 newlines will be replaces with N-1 newlines.
 *   * Text will be dedented.
 */

export default class EmailModal extends Component {
  static propTypes = {
    /**
     * The list of users receiving the email. Sender is understood to be the logged in user.
     * For a single recipient, recipientList should be an array of length 1.
     */
    recipientList: PropTypes.array.isRequired,
    /**
     * Relevant candidate. Not always the recipient (sometimes emails are sent to other admins),
     * but there is still an associated candidate. This is currently only passed down from
     * statusSwitcher, which is the only component that uses templates.
     */
    candidate: PropTypes.object,
    /**
     * associated application. Currently only passed down from statusSwitcher, used for templates.
     */
    application: PropTypes.object,
    /**
     * Subject of outgoing message.
     */
    subject: PropTypes.string.isRequired,
    /**
     * Contents of the email.
     */
    message: PropTypes.string.isRequired,
    /**
     * Footer of the _Modal_, not the message.
     */
    footer: PropTypes.element,
    onHide: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
    updateEmailTemplateSelection: PropTypes.func,
    emailTemplatesList: PropTypes.array,
    emailTemplate: PropTypes.object,
    showTemplates: PropTypes.bool,
    interviewTime: PropTypes.any,
    postSendCallback: PropTypes.func,
  };

  /**
   * Takes a template variable, as documented above, and returns the dynamic value.
   * @param input e.g. '{Sender first name}'
   * @return value e.g. 'Frank' or '()' if the lookup does not exist or '[]' if the given input
   * is not a valid substitution.
   */
  dynamicSub = (input) => {
    /**
     * mapping from human readable variable names to names that fit our data models.
     * Edit 4/23/19: change candidate fields to 'Candidate ...', but maintaining support
     * for old fields name, hence the duplicates.
     */
    const variableMapping = {
      'Candidate first name': 'candidate.first_name',
      'First name': 'candidate.first_name',
      'Candidate last name': 'candidate.last_name',
      'Last name': 'candidate.last_name',
      'Candidate phone': 'candidate.profile.phone_cell',
      'Phone number': 'candidate.profile.phone_cell',
      'Job title': 'application.role.title',
      'Sender first name': 'sender.first_name',
      'Sender last name': 'sender.last_name',
      'School name': 'sender.profile.school.name',
      'District name': 'sender.profile.district.name',
      'Interview date & time': 'application.interview.when',
    };
    const variableLabels = Object.keys(variableMapping);
    const variableValues = Object.values(variableMapping);

    if (!_.startsWith(input, '{') && !_.endsWith(input, '}')) {
      return '[]';
    }

    input = _.trim(input, '{}');

    if (input === 'Interview date &amp; time') {
      input = 'application.interview.when';
    } else {
      input = variableMapping[input];
    }
    let fields = _.split(input, '.'); // eg. ['candidate', 'first_name'] or ['sender', 'last_name']
    let obj;
    if (fields[0] === 'application') {
      obj = this.props.application;
    } else if (fields[0] === 'candidate') {
      obj = this.props.candidate;
    } else if (fields[0] === 'sender') {
      obj = this.sender;
    }
    fields = _.drop(fields, 1);
    for (let field of fields) {
      if (obj && field in obj) {
        // Special case where interview date is saved in the prior modal and passed down as props.
        // In this case, use props.interviewTime instead of application.interview.when.
        if (field === 'when' && this.props.interviewTime) {
          obj = this.props.interviewTime;
        } else {
          // In normal cases, move down nested object until you reach the desired field.
          obj = obj[field];
        }
      } else {
        const variable = `{${variableLabels[variableValues.indexOf(input)]}}`;
        return variable;
      }
    }
    // if the variable evaluates to a date, make it human readable.
    if (obj instanceof Date) {
      obj = moment.utc(obj).format('dddd, MMMM Do YYYY, h:mm a');
    }
    return obj;
  };

  /**
   * Calls dynamicSub on the parts of text that need updating
   * @param text e.g. Hello from {Sender district}"
   * @return text e.g. Hello from ABC District
   */
  processText = (text) => {
    text = dedent(text);
    text = text.replace(/\{.*?\}/g, (str) => this.dynamicSub(str));
    return text;
  };

  constructor(props) {
    super(props);

    this.authenticatedUser = auth.getUser();
    this.sender = auth.getUser();
    this.upload = {};
    this.state = {
      subject: this.processText(this.props.subject),
      message: this.processText(this.props.message),
      uploads: [],
      errors: [],
      uploadError: '',
      variableErrors: [],
      variableErrorMessage: '',
      // @TODO: `recipientList` is no longer a good name since we have multiple types of recipients.
      // We should change these asap to `to` `cc` and `bcc`
      to: props.recipientList.map((u) => ({
        label: u.name ? `${u.name} (${u.email})` : u.email,
        value: u.email,
        isValid: true,
        isFixed: true,
      })),
      cc: [],
      bcc: [
        {
          label: this.authenticatedUser.name
            ? `${this.authenticatedUser.name} (${this.authenticatedUser.email})`
            : this.authenticatedUser.email,
          value: this.authenticatedUser.email,
          isValid: true,
        },
      ],
      isBccVisible: true,
      isUploadingFilesOrSending: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      subject: this.processText(nextProps.subject),
      message: this.processText(nextProps.message),
      showTemplates: nextProps.showTemplates,
      to: nextProps.recipientList.map((u) => ({
        label: u.name ? `${u.name} (${u.email})` : u.email,
        value: u.email,
        isValid: true,
      })),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.uploadError !== this.state.uploadError ||
      prevState.variableErrorMessage !== this.state.variableErrorMessage
    ) {
      this.setState({ errors: [this.state.uploadError, this.state.variableErrorMessage] });
    }
  }

  onHide = () => {
    this.setState({ uploads: [] });
    this.props.onHide();
  };

  send = () => {
    if (this.state.isUploadingFilesOrSending) {
      return;
    }
    this.setState({ isUploadingFilesOrSending: true });

    /** Users can now attach files to an outgoing email. Use the sendemail endpoint for emails w/o
     * attached files, and use sendemail_with_attachments endpoint for emails with attached files.
     * I used a separate endpoint because I couldn't get a file sent to the backend
     * unless it was part of a FormData object. If you're reading this and you can combine into
     * one endpoint, feel free to do so.
     */
    let formData;
    let url;
    let count = 0;

    // Subject and message are required
    if (!this.state.subject || !this.state.message || this.state.message === '<p><br></p>') {
      return this.setState({
        uploadError: 'Please fill in a subject and body for your email, then try again.',
        isUploadingFilesOrSending: false,
      });
    }

    if ((this.state.subject && this.state.message) || this.state.message !== '<p><br></p>') {
      const variableErrors = this.checkForMissingVariables();

      // Check if all emails are well formed:
      const invalidOptions = [...this.state.to, ...this.state.cc, ...this.state.bcc].filter(
        (option) => {
          return !option.isValid;
        }
      );
      if (invalidOptions.length > 0) {
        return this.setState({
          uploadError:
            'One or more email addresses is not recognized. Please correct the red emails and try again.',
          isUploadingFilesOrSending: false,
        });
      }

      if (this.state.uploads.length > 0) {
        // if there are attachments, send the parameters as part of a formdata object
        url = 'sendemail_with_attachments';
        formData = new FormData();
        formData.append('to', JSON.stringify(this.state.to.map((u) => u.value)));
        formData.append('subject', this.state.subject);
        formData.append('message', this.state.message);
        if (this.state.cc) {
          formData.append('cc', JSON.stringify(this.state.cc.map((u) => u.value)));
        }
        if (this.state.bcc) {
          formData.append('bcc', JSON.stringify(this.state.bcc.map((u) => u.value)));
        }
        this.state.uploads.forEach((upload, i) => {
          formData.append(`attachment_${i}`, upload, upload.name);
          count += 1;
        });

        // count represents the number of attached files. use this on backend to get attachment names
        // eg. attachment_0, attachment_1, up to attachment_(n-1) for n attachments.
        formData.append('count', count);
      } else {
        url = 'sendemail';
        formData = {
          to: this.state.to.map((u) => u.value),
          subject: this.state.subject,
          message: this.state.message,
          cc: this.state.cc ? this.state.cc.map((u) => u.value) : undefined,
          bcc: this.state.bcc ? this.state.bcc.map((u) => u.value) : undefined,
        };
      }
      if (variableErrors.length > 0) {
        return this.setState({ isUploadingFilesOrSending: false });
      }
    }

    // Process the sending behind the scenes:
    this.setState({
      uploads: [],
      isUploadingFilesOrSending: false,
    });
    this.props.onSend();

    return axios
      .post(`/api/${url}/`, formData)
      .then(() => {
        this.props.postSendCallback && this.props.postSendCallback();
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
        showWarning('Unable to send Email.');
      });
  };

  /* ---- attachment functions ---- */
  clickUploadInput = () => {
    this.upload.click();
  };

  uploadAttachment = (event) => {
    this.setState({ isUploadingFilesOrSending: true });

    let attachment = event.target.files[0];
    let uploads = this.state.uploads;
    let uploadError = '';

    /* ensure no empty files are uploaded */
    if (attachment.size === 0) {
      uploadError = 'Upload is empty, please try again';
      this.setState({
        uploadError: uploadError,
        isUploadingFilesOrSending: false,
      });
      return;
    }

    /* no reasonable file upload should be greater than 5MB in size */
    if (attachment.size > 5242880) {
      uploadError = 'Attachments must be smaller than 5MB';
      this.setState({
        uploadError: uploadError,
        isUploadingFilesOrSending: false,
      });
      return;
    }

    /* only proper file types can be uploaded */
    if (
      !['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(
        attachment.name.split('.').pop().toLowerCase()
      )
    ) {
      uploadError = 'Please upload one of the following file types: .pdf, .doc, .docx, .jpg, .png';
      this.setState({
        uploadError: uploadError,
        isUploadingFilesOrSending: false,
      });
      return;
    }

    /**
     * ~ 30 characters are prepended to file name (/attachments/None/(timestamp)), and max_length
     * is 255, so ensure input file name is no more than 215 characters, to be safe.
     */

    if (attachment.name.length > 215) {
      uploadError = 'Please ensure all attachment file names are fewer than 215 characters';
      this.setState({
        uploadError: uploadError,
        isUploadingFilesOrSending: false,
      });
      return;
    }

    // if user clicks cancel, attachment will be undefined
    if (attachment) {
      uploads.push(attachment);
      this.setState({ uploads, uploadError: '', isUploadingFilesOrSending: false });
    }
  };

  removeAttachment = (index) => {
    let uploads = this.state.uploads;
    uploads.splice(index, 1);
    this.setState({ uploads, uploadError: '' });
  };
  /* ---- end attachment functions ---- */

  handleEmailTemplateChange = (value) => {
    // updateEmailTemplateSelection calls e.target.value,
    // so format this argument accordingly.
    this.props.updateEmailTemplateSelection({
      target: { value },
    });
  };

  clearEmailTemplate = () => {
    this.props.updateEmailTemplateSelection({
      target: { value: null },
    });
    this.setState({ variableErrors: [] });
  };

  setCcList = (recipients) => {
    const isLastItem = this.state.cc.length === 1 && recipients && recipients.length === 0;
    if (isLastItem) {
      this.setState({ isCcVisible: false });
    }

    if (!recipients || (recipients && recipients.length === 0)) {
      this.setState({
        cc: [],
      });
    } else {
      this.setState({ cc: recipients });
    }
  };

  setBccList = (recipients) => {
    const isLastItem = this.state.bcc.length === 1 && recipients && recipients.length === 0;
    if (isLastItem) {
      this.setState({ isBccVisible: false });
    }

    if (!recipients || (recipients && recipients.length === 0)) {
      this.setState({
        bcc: [],
      });
    } else {
      this.setState({ bcc: recipients });
    }
  };

  // We're using the callback version because the promises api doesn't agree well with debounce.
  // https://github.com/JedWatson/react-select/issues/614
  searchOptions = _.debounce((inputValue, callback) => {
    SearchAPI.searchDistrictUsers({ search: inputValue }).then((data) => {
      callback(
        data.results.map((u) => ({
          label: u.name ? `${u.name} (${u.email})` : u.email,
          value: u.email,
          isValid: true, // Assume valid since it comes from the server
        }))
      );
    });
  }, 300);

  generateVariableErrorMessage = (variableErrors) => {
    if (variableErrors) {
      if (variableErrors.includes('{Interview date &amp; time}')) {
        variableErrors[variableErrors.indexOf('{Interview date &amp; time}')] =
          '{Interview date & time}';
      }
      if (!variableErrors || variableErrors.length === 0) {
        this.setState({ variableErrorMessage: '' });
      } else if (variableErrors.length > 2) {
        this.setState({
          variableErrorMessage: `Manually complete or delete the missing ${variableErrors.length} fields to proceed`,
        });
      } else if (variableErrors.length > 1) {
        this.setState({
          variableErrorMessage: `Manually complete or delete the missing ${variableErrors[0]} and ${variableErrors[1]} fields to proceed`,
        });
      } else if (variableErrors.length > 0) {
        this.setState({
          variableErrorMessage: `Manually complete or delete the missing ${variableErrors[0]} field to proceed`,
        });
      }
    }
  };

  checkForMissingVariables = () => {
    let errorsArray = [];

    emailVariableKeywords.forEach((keyword) => {
      if (this.state.subject.includes(keyword)) {
        errorsArray.push(keyword);
      }
      if (this.state.message.includes(keyword)) {
        errorsArray.push(keyword);
      }
    });

    const dedupedErrorsArray = [...new Set(errorsArray)];
    this.generateVariableErrorMessage(dedupedErrorsArray);
    return dedupedErrorsArray;
  };

  render() {
    return (
      <Modal show={this.props.show} bsSize="large" className="deprecated-modal-container">
        <StyledAltModalTitle>Send Email</StyledAltModalTitle>
        <AltModal.Cancel onClick={this.onHide}>×</AltModal.Cancel>
        <AltModal.UpperRule />

        <StyledAltModalBody>
          {this.props.showTemplates ? (
            <Section>
              <Label>Select Template</Label>
              <DropdownWithInputFilter
                placeholder="Type or use drop down to select template"
                options={this.props.emailTemplatesList
                  .filter((e) => e.reference_check === false)
                  .map((template) => ({ value: template.id, label: template.title }))}
                value={this.props.emailTemplate?.id ?? ''}
                handleChange={this.handleEmailTemplateChange}
                onClear={this.clearEmailTemplate}
                boxShadow={false}
                dataTestId={ATSCandidateListBulkEmail.SELECT_TEMPLATE_SELECT}
              />
            </Section>
          ) : (
            ''
          )}

          <Section>
            <Label>Recipients</Label>

            <RecipientsControl>
              {!this.state.isCcVisible && (
                <button
                  onClick={() => {
                    this.setState({ isCcVisible: !this.state.isCcVisible });
                  }}
                >
                  + Add Cc
                </button>
              )}

              {!this.state.isBccVisible && (
                <button onClick={() => this.setState({ isBccVisible: !this.state.isBccVisible })}>
                  + Add Bcc
                </button>
              )}
            </RecipientsControl>

            <RecipientsContainer>
              <SelectLabel>To:</SelectLabel>
              <EmailSelect initialOptions={this.state.to} isDisabled={true} />

              {this.state.isCcVisible && (
                <>
                  <SelectLabel>Cc:</SelectLabel>
                  <EmailSelect
                    initialOptions={this.state.cc}
                    loadOptions={this.searchOptions}
                    onChange={this.setCcList}
                  />
                </>
              )}

              {this.state.isBccVisible && (
                <>
                  <SelectLabel>Bcc:</SelectLabel>
                  <EmailSelect
                    initialOptions={this.state.bcc}
                    loadOptions={this.searchOptions}
                    onChange={this.setBccList}
                  />
                </>
              )}
            </RecipientsContainer>
          </Section>

          <Section>
            <Label>Subject:</Label>
            <Input
              width="100%"
              name="subject"
              type="text"
              placeholder="Email subject"
              required
              value={this.state.subject}
              onChange={(event) => this.setState({ subject: event.target.value })}
            />
          </Section>

          <Section className="relative">
            <StyledReactQuill
              placeholder="Email message"
              value={this.state.message}
              onChange={(value) => this.setState({ message: value })}
            />
            <UploadButton>
              <input
                ref={(input) => (this.upload = input)}
                type="file"
                style={{ height: '0px', width: '0px', overflow: 'hidden' }}
                onChange={this.uploadAttachment}
                multiple
              />
              <img
                className="pointer mr1"
                src={iconPaperclip}
                alt="attach file"
                onClick={this.clickUploadInput}
                data-tip
                data-for="email-attachment"
              />

              <ReactTooltip id="email-attachment" effect="solid">
                <span>Attach files</span>
              </ReactTooltip>
            </UploadButton>
          </Section>
          <Section marginTop="0">
            {this.state.uploads.map((upload, i) => (
              <AttachmentRow key={i}>
                <div>
                  <AttachmentName>{upload.name}</AttachmentName>
                  &nbsp;&nbsp;
                  <span>({bytesToSize(upload.size)})</span>
                </div>
                <img
                  style={{ width: '10px', cursor: 'pointer' }}
                  src={iconClose}
                  alt="close"
                  onClick={() => this.removeAttachment(i)}
                />
              </AttachmentRow>
            ))}
          </Section>
          <UploadFileHelperMessage />
        </StyledAltModalBody>
        <AltModal.Actions>
          <ModalFooterNew
            cancel={this.onHide}
            save={this.send}
            saveButtonLabel="Send Email"
            saveDisabled={this.state.isUploadingFilesOrSending}
            deleteFunction={this.props.skipEmailFn}
            deleteButtonLabel="Skip email"
            errorMessages={this.state.errors}
          />
        </AltModal.Actions>
      </Modal>
    );
  }
}

const StyledAltModalTitle = styled(AltModal.Title)`
  font-size: 20px;
  font-weight: 400;
`;

const StyledAltModalBody = styled(AltModal.Body)`
  padding-bottom: 0;
`;

const Section = styled.section`
  &:first-child {
    margin-top: 0;
  }

  margin-top: 30px;

  ${space}
`;

const RecipientsContainer = styled.div`
  width: 91%;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 5px 5px 0;
`;

const RecipientsControl = styled.div`
  float: right;
  width: 65px;

  & > button {
    color: #00b88d;
    display: block;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }
`;

const SelectLabel = styled.span`
  display: inline-box;
  line-height: 24px;
  font-size: 14px;

  margin-left: 5px;
  width: 30px;
  float: left;
`;

const StyledReactQuill = styled(ReactQuill)`
  border: 1px solid #dcdcdc;
  background: #fff;
  padding-bottom: 25px;
  border-radius: 3px;

  &:hover {
    border-color: #dcdcdc !important;
  }

  .ql-editor.ql-blank::before {
    color: rgba(57, 60, 73, 0.3);
  }
`;

const UploadButton = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
`;

const AttachmentRow = styled.div`
  width: 400px;
  font-size: 13px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 7px 10px;
`;

const AttachmentName = styled.span`
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
`;
