import _ from 'lodash';
import { roleStatuses } from 'utils/enums';

export const statuses = _.keyBy(roleStatuses(), 'value');

export const HRBP_ASSIGNEE_FIELD_NAME = 'hrbp_assignees';

export const emptyFilterState = {
  query: '',
  sortField: '',
  sortDescending: false,
  job_status_list: [],
  grades: [],
  categories: [],
  hiring_seasons: [],
  internal_external: [],
  job_owners: [],
  hrbp_assignees: [],
  district_users: [],
  schools: [],
};
