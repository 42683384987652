import { AltModal } from 'ui-kit';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';

const ConfirmationPage = ({ onHide }) => {
  return (
    <>
      <AltModal.Body>
        You will receive a copy of the signed form via email once the signing process is complete!
      </AltModal.Body>
      <AltModal.Actions>
        <ModalFooterNew save={onHide} saveButtonLabel="OK" />
      </AltModal.Actions>
    </>
  );
};

export default ConfirmationPage;
