import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'ui-kit';

export default function SimpleCheckInput({ isSelected, isConfused }) {
  const checkboxElement = useRef(null);

  useEffect(() => {
    if (checkboxElement.current) {
      checkboxElement.current.checked = isSelected;
      checkboxElement.current.indeterminate = isConfused;
    }
  });

  return (
    <StyledCheckLabel>
      <StyledInput type="checkbox" readOnly ref={checkboxElement} checked={isSelected} />
      <Checkmark></Checkmark>
    </StyledCheckLabel>
  );
}

const StyledCheckLabel = styled.label`
  margin: 0;
  padding: 0;
  width: 13px;
  height: 13px;
  display: inline-block;
  position: relative;
  z-index: -1;
`;

const Checkmark = styled.span`
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid ${theme.uiColors.gray};
  display: inline-block;
  height: 13px;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  top: 0;
  width: 13px;
  z-index: -1;
`;

const StyledInput = styled.input`
  opacity: 0;
  margin: 0 !important;
  padding: 0 !important;
  z-index: -1;

  &:not(:checked) + ${Checkmark} {
  }

  &:checked + ${Checkmark} {
    background: ${theme.uiColors.greens.full};
    border-color: ${theme.uiColors.greens.full};
  }
  &:checked + ${Checkmark}:after {
    content: '';
    display: inline-block;
    height: 9px;
    left: 3px;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    top: 0;
    width: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.2s ease;
    transform: scale(1) rotate(45deg);
    z-index: -1;
  }

  &:indeterminate + ${Checkmark} {
    background: ${theme.uiColors.white};
    border-color: ${theme.uiColors.greens.full};
  }
  &:indeterminate + ${Checkmark}:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 3px;
    width: 5px;
    height: 0;
    border: none;
    border-bottom: 2px solid ${theme.uiColors.greens.full};
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: scale(1);
  }
`;
