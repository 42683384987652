import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function EmailTemplateVariable(props) {
  const { name, handleVariableClick } = props;
  return <Row onClick={() => handleVariableClick(name)}>{name}</Row>;
}

EmailTemplateVariable.propTypes = {
  name: PropTypes.string.isRequired,
  handleVariableClick: PropTypes.func.isRequired,
};

const Row = styled.div`
  cursor: pointer;
  color: #444;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 5px;

  &:hover {
    color: #008060;
    transition: 0.3s ease;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
