import styled from 'styled-components';

import { Body } from 'ui-kit/Modal';
import { MultipleChoiceIcon } from 'ui-kit/icons';
import { Input, Textarea } from 'ui-kit';
import { ModalBodySectionHeader } from '../styledComponents';

import HeaderInformation from './HeaderInformation';

import { ReactComponent as DeleteIcon } from 'assets/icons/icon_trash.svg';

export default function MultipleChoice({
  questionText,
  setQuestionText,
  addAnswer,
  mc_options,
  multi_select,
  setMultiSelect,
  handleMultipleChoiceTextChange,
  removeOption,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) {
  const multipleChoiceAnswerRow = (option, index) => {
    const showRemoveButton = mc_options.length > 2;
    return (
      <MultipleChoiceAnswerDiv key={index} showRemoveButton={showRemoveButton}>
        <Input
          name="mc_options"
          type="text"
          value={option}
          placeholder={`option ${index + 1}`}
          onChange={(e) => handleMultipleChoiceTextChange(e, index)}
          width={1}
        />
        {showRemoveButton && <RemoveAnswerIcon onClick={() => removeOption(index)} />}
      </MultipleChoiceAnswerDiv>
    );
  };

  return (
    <Body>
      <HeaderInformation
        Icon={MultipleChoiceIcon}
        title="Multiple Choice Question"
        subtext={
          multi_select ? 'Candidate can select multiple options' : 'Candidate can select one option'
        }
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <ModalBodySectionHeader>Type multiple choice question here:</ModalBodySectionHeader>
      <Textarea
        placeholder="e.g. What is your pedagogical approach?"
        value={questionText}
        onChange={(e) => setQuestionText(e.target.value)}
        width={1}
      />
      <HeaderContainer>
        <ModalBodySectionHeader>Answer options:</ModalBodySectionHeader>
        <GreenClickableSpan onClick={addAnswer}>+ Add another answer</GreenClickableSpan>
      </HeaderContainer>
      <MultipleChoiceAnswerContainer>
        {mc_options.map(multipleChoiceAnswerRow)}
      </MultipleChoiceAnswerContainer>
      <SwitchAndTextContainer>
        <SwitchContainer
          on={multi_select ? 'true' : undefined}
          onClick={() => setMultiSelect((prevValue) => !prevValue)}
        >
          <SwitchCircle on={multi_select ? 'true' : undefined} />
        </SwitchContainer>
        <BodyText>Allow multiple selections</BodyText>
      </SwitchAndTextContainer>
    </Body>
  );
}

const SwitchAndTextContainer = styled.div`
  display: flex;
  align-items: center;

  & > p {
    color: #444444;
  }
`;

const SwitchContainer = styled.div`
  width: 50px;
  min-width: 50px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid;
  border-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  margin-right: 10px;
  position: relative;
  cursor: pointer;
`;

const SwitchCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  left: ${(props) => (props.on ? '23px' : '3px')};
  top: 3px;
  transition: left 300ms;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 22px;
  margin-bottom: 13px;
`;

const MultipleChoiceAnswerContainer = styled.div`
  margin-bottom: 34px;
`;

const MultipleChoiceAnswerDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 15px;
  grid-column-gap: 8px;
  align-items: center;

  margin-bottom: 16px;
`;

const RemoveAnswerIcon = styled(DeleteIcon)`
  grid-column-start: 2;
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
`;

const GreenClickableSpan = styled.span`
  font-size: 14px;
  cursor: pointer;
  color: #00b88d;
`;

const BodyText = styled.p`
  font-size: 14px;
  color: rgb(68, 68, 68);
`;
