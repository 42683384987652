import { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import DateTime from 'react-datetime';
import moment from 'moment';

import { MultiSelect, StyledCreatableSelect, StyledSelect } from 'ui-kit';
import { gender, race_ethnicity, USStates } from 'utils/enums';
import { parseMomentFromString } from 'utils/util';
import LoadingSpinner from '../../loadingSpinner';
import Tooltip from 'assets/tooltip.svg';
import { ReactComponent as TooltipIcon } from 'assets/tooltip.svg';
import { ATSCandidateBasicInfoDataTestIds } from '../../../data-testids/ATS';

const STATE_NAMES = USStates().map((state) => {
  return {
    label: state.title,
    value: state.value,
  };
});

const STATE_MAPPING = USStates().map((state) => {
  // map long state names to short names, eg. California -> CA
  return {
    label: state.title,
    value: state.label,
  };
});

export default class BasicInputs extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleUserChange: PropTypes.func.isRequired,
    handleProfileChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    getSSN: PropTypes.func.isRequired,
    ssn_loading_spinner: PropTypes.bool.isRequired,
    determineIfSSNRequired: PropTypes.func.isRequired,
    isBirthdayRequired: PropTypes.bool.isRequired,
    handleBirthdayChange: PropTypes.func.isRequired,
    birthdayErrorOnSubmit: PropTypes.string,
    eeoErrorOnSubmit: PropTypes.string,
  };
  static defaultProps = {
    user: { profile: {} },
  };

  constructor(props) {
    super(props);
    this.state = {
      showSSN: true,
    };
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  getSSN = () => {
    // if the user has a tokenized SSN or showSSN is false, show asterisks instead
    // of showing the SSN/token.
    let ssn = this.props.user.profile.ssn || '';
    if (ssn && (!this.state.showSSN || ssn.indexOf('tok_') !== -1)) {
      ssn = '***-**-****';
    }
    return ssn;
  };

  revealSSN = (e) => {
    e.preventDefault();
    // if plaintext ssn has already been retrieved, just show it. no
    // need to exchange the token.
    if (this.props.user.profile.ssn.indexOf('tok_') === -1) {
      this.setState({ showSSN: true });
    } else {
      // in this case, ssn is still a token. exchange the token on the backend
      // via props.getSSN() and then it will be returned in plaintext. once that is
      // done, set showSSN to true.
      this.props.getSSN();
      this.setState({ showSSN: true });
    }
  };

  hideSSN = (e) => {
    e.preventDefault();
    this.setState({ showSSN: false });
  };

  handleGenderChange = (payload) => {
    let value;
    if (payload === null) {
      value = null;
    } else {
      value = payload.value;
    }
    this.props.handleProfileChange({ target: { value, name: 'gender' } });
  };

  handleEthnicityChange = (payload) => {
    let value;
    if (payload === null) {
      value = null;
    } else {
      value = payload.value;
    }
    this.props.handleProfileChange({ target: { value, name: 'race_ethnicity' } });
  };

  renderSSNButtonOrLoader = (ssn) => {
    if (this.props.ssn_loading_spinner) {
      return (
        <LoadingSpinner
          margin={0}
          fontSize={2}
          additionalStyles={{
            position: 'absolute',
            top: '16px',
            right: '8px',
          }}
        />
      );
    } else if (ssn === '***-**-****') {
      return (
        <button className="view-ssn" onClick={this.revealSSN}>
          (view)
        </button>
      );
    } else {
      return (
        <button className="view-ssn" onClick={this.hideSSN}>
          (hide)
        </button>
      );
    }
  };

  /**
   * show ssn section if:
   * 1) user is completing or editing an application and the district requires ssn.
   * 2) user is editing her profile and any of her existing applications have
   *    ssn required.
   *
   * if the ssn section is showing, it's required.
   * */
  renderSSNSection = () => {
    let ssnRequired = this.props.determineIfSSNRequired();
    if (!ssnRequired) return null;

    let ssn = this.getSSN();
    let style = {
      marginBottom: '0px',
      fontSize: ssn === '***-**-****' ? '16px' : '14px',
    };
    return (
      <InputWithTooltipContainer>
        <div className="required-input-container relative">
          <input
            name="ssn"
            className="form-field ssn-input"
            ref="ssn"
            type="text"
            placeholder="Social security number"
            value={ssn}
            onChange={this.props.handleProfileChange}
            pattern="\d{3}[\-]\d{2}[\-]\d{4}"
            maxLength={11}
            style={style}
            disabled={ssn === '***-**-****'}
            required
          />
          {ssn && this.renderSSNButtonOrLoader(ssn)}
        </div>
        <TooltipIcon
          alt="tooltip"
          data-tip={`Please note that Social Security Numbers are encrypted and stored
          securely.<br/><br/>
          This information is used to check credentials where applicable
          and as part of the onboarding process, so it’s important that you enter
          accurate information here.`}
        />
      </InputWithTooltipContainer>
    );
  };

  renderBirthdayInput = () => {
    const birthdayRequired = this.props.isBirthdayRequired;
    if (!birthdayRequired) return null;

    return (
      <BirthdayInput
        initialValue={this.props.user?.profile?.birthday}
        handleBirthdayChange={this.props.handleBirthdayChange}
        birthdayErrorOnSubmit={this.props.birthdayErrorOnSubmit}
      />
    );
  };

  renderBasicInputsLineTwo = () => {
    return (
      <div className="basic application-row border-below">
        <FormLayout>
          <div className="required-input-container">
            <input
              readOnly={true}
              name="username"
              className="form-field email-input"
              id="email-input"
              ref="email"
              type="email"
              placeholder="Email"
              value={this.props.user.username || ''}
              required
              onChange={this.props.handleUserChange}
            />
          </div>
          <div className="required-input-container">
            <input
              name="phone_cell"
              className="form-field"
              id="cell-phone-input"
              ref="phone_cell"
              type="text"
              data-testid={ATSCandidateBasicInfoDataTestIds.CELL_PHONE_INPUT}
              placeholder="Cell phone (or primary)"
              value={this.props.user.profile.phone_cell || ''}
              required
              onChange={this.props.handleProfileChange}
              minLength={10}
            />
          </div>
          <div className="input-container">
            <input
              name="phone_work"
              className="form-field"
              ref="phone_work"
              type="text"
              placeholder="Home phone"
              value={this.props.user.profile.phone_work || ''}
              onChange={this.props.handleProfileChange}
            />
          </div>
          {this.renderSSNSection()}
          {this.renderBirthdayInput()}
          <div className="input-container twitter-handle">
            @
            <input
              name="twitter_handle"
              type="text"
              placeholder="X / Twitter Handle"
              value={this.props.user.profile.twitter_handle || ''}
              onChange={this.props.handleProfileChange}
            />
          </div>
        </FormLayout>
      </div>
    );
  };

  render() {
    return (
      <>
        <ReactTooltip className="BasicInputs__tooltip" effect="solid" multiline={true} />
        <div>
          <div className="basic application-row">
            <div className="required-input-container">
              <input
                name="first_name"
                className="form-field"
                ref="first_name"
                type="text"
                placeholder="First Name"
                id="first-name-input"
                pattern="..*"
                value={this.props.user.first_name || ''}
                required
                onChange={this.props.handleUserChange}
              />
            </div>
            <div className="input-container">
              <input
                name="middle_name"
                className="form-field"
                id="middle-name-input"
                ref="middle_name"
                type="text"
                placeholder="Middle Name"
                value={this.props.user.profile.middle_name || ''}
                onChange={this.props.handleProfileChange}
              />
            </div>
            <div className="required-input-container">
              <input
                name="last_name"
                className="form-field"
                id="last-name-input"
                ref="last_name"
                type="text"
                placeholder="Last Name"
                value={this.props.user.last_name || ''}
                required
                onChange={this.props.handleUserChange}
              />
            </div>
          </div>
        </div>
        <div>
          {this.renderBasicInputsLineTwo()}
          <div className="basic application-row flex address-inputs address-inputs-one">
            <div className="required-input-container">
              <input
                name="address_street"
                className="flex-3 form-field"
                id="address-line-1-input"
                ref="address_street"
                type="text"
                data-testid={ATSCandidateBasicInfoDataTestIds.ADDRESS_LINE1_INPUT}
                placeholder="Address Line 1 (street address, PO box, company name, c/o)"
                value={this.props.user.profile.address_street || ''}
                required
                onChange={this.props.handleProfileChange}
              />
            </div>
            <div className="input-container">
              <input
                name="address_apt"
                className="flex-1 form-field address_apt"
                ref="address_apt"
                type="text"
                placeholder="Apartment or unit number"
                value={this.props.user.profile.address_apt || ''}
                onChange={this.props.handleProfileChange}
              />
            </div>
          </div>
          <div className="basic application-row flex address-inputs address-inputs-two">
            <div className="required-input-container">
              <input
                name="address_city"
                className="flex-2 form-field"
                id="address-city-input"
                ref="address_city"
                type="text"
                placeholder="City"
                data-testid={ATSCandidateBasicInfoDataTestIds.CITY_INPUT}
                value={this.props.user.profile.address_city || ''}
                onChange={this.props.handleProfileChange}
                required
              />
            </div>
            <div className="basic-select address_state flex-1">
              {this.props.user.profile.address_state ? (
                <div>
                  <input
                    name="address_state"
                    className="form-field"
                    value={this.props.user.profile.address_state || ''}
                    onChange={this.props.handleProfileChange}
                  />
                </div>
              ) : (
                <StyledCreatableSelect
                  boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                  options={STATE_MAPPING}
                  value={STATE_MAPPING.filter((state) =>
                    this.props.user.profile.address_state?.includes(state.value)
                  )}
                  placeholder="State / Province / Region"
                  onChange={(values) => this.props.handleMultiSelectChange(values, 'address_state')}
                />
              )}
            </div>
            <div className="required-input-container">
              <input
                name="address_zip"
                className="form-field address_zip"
                id="address-zipcode-input"
                ref="address_zip"
                type="text"
                placeholder="Zip code"
                pattern="[0-9-]+"
                required
                data-testid={ATSCandidateBasicInfoDataTestIds.ZIP_INPUT}
                value={this.props.user.profile.address_zip || ''}
                onChange={this.props.handleProfileChange}
              />
            </div>
            <div className="input-container">
              <input
                name="address_country"
                className="form-field"
                placeholder="Country"
                value={this.props.user.profile.address_country || ''}
                onChange={this.props.handleProfileChange}
              />
            </div>
          </div>
          <div className="basic application-row flex full-width">
            What other states have you lived in?
          </div>
          <div className="basic application-row flex full-width border-below">
            <MultiSelect
              id="other-states-lived-in-select"
              boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
              className="flex-1"
              options={STATE_NAMES}
              value={STATE_NAMES.filter((state) =>
                this.props.user.profile.states_lived_in?.includes(state.value)
              )}
              placeholder="Start typing..."
              onChange={(values) =>
                this.props.handleMultiSelectChange(values ?? [], 'states_lived_in')
              }
            />
          </div>
          <div className="basic application-row flex full-width">
            <input
              name="website"
              className="form-field"
              ref="website"
              type="text"
              placeholder="Websites (Personal website, LinkedIn, etc)"
              value={this.props.user.profile.website || ''}
              onChange={this.props.handleProfileChange}
            />
          </div>
        </div>
        <EEOContainer className="application-row" id="eeo-inputs">
          <GenderContainer data-testid={ATSCandidateBasicInfoDataTestIds.GENDER_SELECT}>
            <StyledSelect
              id="gender-select"
              boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
              // ** make sure 'Prefer Not to Say' is always an option
              options={gender()}
              name="gender"
              placeholder="Gender"
              onChange={this.handleGenderChange}
              value={gender().find((g) => g.value === this.props.user.profile.gender) ?? null}
              isClearable
            />
          </GenderContainer>
          <FlexContainer>
            <EthnicityContainer data-testid={ATSCandidateBasicInfoDataTestIds.ETHNICITY_SELECT}>
              <StyledSelect
                id="race-ethnicity-select"
                boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                // ** make sure 'Prefer Not to Say' is always an option
                options={race_ethnicity()}
                name="race_ethnicity"
                placeholder="Ethnicity"
                onChange={this.handleEthnicityChange}
                value={
                  race_ethnicity().find(
                    (r) => r.value === this.props.user.profile.race_ethnicity
                  ) ?? null
                }
                isClearable
              />
            </EthnicityContainer>
            <img
              src={Tooltip}
              alt="tooltip"
              data-tip="This data will not be visible on your individual application. Instead, it is
                utilized in aggregate to help the organization better understand and improve its
                recruitment and hiring practices.<br/><br/>
                Submission of this information is voluntary and refusal to provide it will not subject you to any adverse treatment."
            />
          </FlexContainer>
          {this.props.eeoErrorOnSubmit && (
            <EEOErrorMessage>{this.props.eeoErrorOnSubmit}</EEOErrorMessage>
          )}
        </EEOContainer>
      </>
    );
  }
}

/**
 * Built this to space the basic inputs in a more automatic fashion.
 * Would be ideal to extend it to the rest of the inputs
 */
const FormLayout = styled.div`
  display: flex;
  flex-direction: column;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.3em;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1em;
    }
  }

  width: 100%;
`;

const EEOContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const SelectRequiredContainer = styled.div`
  position: relative;

  &:after {
    content: '*';
    position: absolute;
    right: 14px;
    top: 4px;
    width: 0;
    color: #e64c66;
  }
`;

const GenderContainer = styled(SelectRequiredContainer)`
  flex: 1 1;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

const EthnicityContainer = styled(SelectRequiredContainer)`
  margin-right: 12px;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 2 1;
`;

const EEOErrorMessage = styled.p`
  color: #e64c66;
  position: absolute;
  bottom: -24px;
  left: 0;
`;

const BirthdayInput = ({ initialValue, handleBirthdayChange, birthdayErrorOnSubmit }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [error, setError] = useState(null);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (birthdayErrorOnSubmit) {
      setError(birthdayErrorOnSubmit);
    }
  }, [birthdayErrorOnSubmit]);

  let value = inputValue;
  if (value) {
    let parsedValue = parseMomentFromString(value);
    if (parsedValue.isValid()) {
      value = parsedValue.format('MM/DD/YYYY');
    }
  }

  return (
    <div>
      <InputWithTooltipContainer>
        <div className="required-input-container relative">
          <DateTime
            timeFormat={false}
            closeOnSelect
            dateFormat="MM/DD/YYYY"
            inputProps={{
              required: true,
              placeholder: 'Date of Birth (mm/dd/yyyy)',
              className: 'form-field birthday-input',
              id: 'birthday-input',
            }}
            value={value}
            onChange={(newValue) => {
              setInputValue(newValue);
              setError(null);
              if (moment.isMoment(newValue)) {
                newValue = newValue.toISOString();
                handleBirthdayChange(newValue);
              } else if (!newValue) {
                handleBirthdayChange(newValue);
              }
            }}
            onBlur={() => {
              if (inputValue) {
                if (!parseMomentFromString(inputValue).isValid()) {
                  setError('Please enter a valid date in mm/dd/yyyy format.');
                }
              } else {
                setError('Date is required.');
              }
            }}
          />
        </div>
        <TooltipIcon
          alt="tooltip"
          data-tip={`Date of birth is only made visible to limited parties after the point of hire,
          and is not visible to interviewers during the screening process.<br/><br/>
          This information is used to check credentials where applicable and as part of
          the onboarding process, so it’s important that you enter accurate information here.`}
        />
      </InputWithTooltipContainer>
      {error && <span className="BasicInput__field-error">{error}</span>}
    </div>
  );
};

const InputWithTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: auto;

  div {
    flex: 1;
  }

  input {
    width: 100%;
  }

  svg {
    margin-left: 0.5em;
    margin-right: 1.5em;
    fill: #999999;
  }
`;
