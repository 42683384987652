import { HIRING_SEASONS } from 'utils/enums';
import { theme } from 'ui-kit';

// export const CHART_COLOR_PALETTES = {
//   4: ['#003f5c', '#7a5195', '#ef5675', '#ffa600'],
//   6: ['#003f5c', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600'],
//   8: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600']
// };

export const CHART_COLOR_PALETTES = {
  8: [
    theme.uiColors.yellows.full,
    theme.uiColors.reds.full,
    theme.uiColors.purples.full,
    theme.uiColors.blues.full,
    theme.uiColors.yellows.medium,
    theme.uiColors.reds.medium,
    theme.uiColors.purples.medium,
    theme.uiColors.blues.medium,
  ],
  6: [
    theme.uiColors.yellows.full,
    theme.uiColors.reds.full,
    theme.uiColors.purples.full,
    theme.uiColors.blues.full,
    theme.uiColors.yellows.medium,
    theme.uiColors.reds.medium,
  ],
  4: [
    theme.uiColors.yellows.full,
    theme.uiColors.reds.full,
    theme.uiColors.purples.full,
    theme.uiColors.blues.full,
  ],
};

export const DIVERSITY_CHOICES = [
  { id: 'gender_header', label: 'Gender', isGroupHeader: true, group: 'gender' },
  { id: 2, value: 2, label: 'Male', group: 'gender' },
  { id: 1, value: 1, label: 'Female', group: 'gender' },
  { id: 3, value: 3, label: 'Other', group: 'gender' },
  { id: 4, value: 4, label: 'Non-binary', group: 'gender' },
  { id: 0, value: 0, label: 'Unknown', group: 'gender' },
  { id: 'ethnicity_header', label: 'Race/Ethnicity', isGroupHeader: true, group: 'enthnicity' },
  { id: 'ethnicity_1', value: 1, label: 'American Indian or Alaska Native', group: 'enthnicity' },
  { id: 'ethnicity_2', value: 2, label: 'Asian', group: 'enthnicity' },
  { id: 'ethnicity_3', value: 3, label: 'Black or African Descent', group: 'enthnicity' },
  {
    id: 'ethnicity_4',
    value: 4,
    label: 'Pacific Islander or Native Hawaiian',
    group: 'enthnicity',
  },
  { id: 'ethnicity_5', value: 5, label: 'White', group: 'enthnicity' },
  { id: 'ethnicity_6', value: 6, label: 'Hispanic or Latino', group: 'enthnicity' },
  { id: 'ethnicity_7', value: 7, label: 'Two or more races', group: 'enthnicity' },
  { id: 'ethnicity_0', value: 0, label: 'Unknown', group: 'enthnicity' },
];

export const HIRING_SEASON_CHOICES = Object.entries(HIRING_SEASONS).map(([key, label]) => ({
  id: parseInt(key),
  value: parseInt(key),
  label,
}));
