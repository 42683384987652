import { useState, useCallback } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from 'hooks';
import { ReactComponent as DownCaret } from 'assets/icon-down-caret.svg';

/** Similar to ui-kit/Dropdown, but single click instead of 'Select all that apply' */
export const Dropdown = ({
  label = '',
  children,
  border = true,
  boxShadow = true,
  showColor = false,
  height = '50px',
  width = '100%',
  paddingLeft = '1.5em',
  paddingRight = '1.5em',
  maxWidth = 'unset',
  dropdownListWidth = '100%',
  className,
  noLabelMargin = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdown, isOpen);

  return (
    <Container ref={closeNodeOnClickOutside} width={width} height={height} className={className}>
      <DropdownWrapper>
        <DropdownInput
          onClick={toggleDropdown}
          height={height}
          border={border}
          boxShadow={boxShadow}
          showColor={showColor}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          maxWidth={maxWidth}
        >
          <DropdownLabel noLabelMargin={noLabelMargin}>{label}</DropdownLabel>
          <DropdownCaretSized />
        </DropdownInput>
        {isOpen && (
          <DropdownList onClick={closeDropdown} width={dropdownListWidth}>
            {children}
          </DropdownList>
        )}
      </DropdownWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: white;
`;

const DropdownWrapper = styled.div`
  position: relative;
  user-select: none;
`;

const DropdownInput = styled.div`
  height: ${(props) => props.height};
  font-size: 15px;

  border: ${(props) => (props.border ? '1px solid #d7d7d7' : 'none')};
  box-shadow: ${(props) => (props.boxShadow ? '0px 2px 2px rgba(0, 0, 0, 0.0954484)' : 'none')};
  border-radius: 3px;

  // if showing color, like for status dropdowns, we need extra padding.
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};

  display: flex;
  align-items: center;

  max-width: ${(props) => props.maxWidth};
  text-overflow: ellipsis;
`;

const DropdownLabel = styled.div`
  flex: 1;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  // removes the margin when adding color and text items to label
  margin-right: ${(props) => (props.noLabelMargin ? 0 : '8px')};
`;

const DropdownCaretSized = styled(DownCaret)`
  width: 12px !important;
  height: 12px !important;
`;

const DropdownList = styled.ul`
  position: absolute;
  width: ${({ width }) => width};

  max-height: 315px;
  @media screen and (max-width: 768px) {
    max-height: 222px;
  }

  font-size: 15px;

  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 1px 2px rgba(204, 204, 204, 0.5);

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  list-style-type: none;
  z-index: 10;
`;

export const ListItem = ({ children, ...rest }) => {
  return <li {...rest}>{children}</li>;
};
