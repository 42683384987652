import { Box, styled, Typography } from '@mui/material';

export const CTAButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontSize: theme.typography.print.fontSize,
}));

export const Form = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

export const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.shape.borderRadius * 3,
  color: theme.palette.black.main,
  margin: theme.spacing(4),
  padding: theme.spacing(4),
  width: '1000px',
}));

export const HelpText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.print.fontSize,
  fontStyle: 'italic',
  marginTop: theme.spacing(0.5),
}));

export const Key = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
  marginBottom: theme.spacing(0.25),
}));

export const OptionTitle = styled(Box)({
  width: '50%',
});

export const Right = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

export const Row = styled(Box)({
  display: 'flex',
});

export const SubContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(4),
}));

export const SuccessSubContainer = styled(SubContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightMediumBold,
  marginBottom: theme.spacing(2),
  padding: 0,
}));
