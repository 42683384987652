import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SxProps } from '@mui/material';

export interface CloseButtonProps {
  onClick: () => void;
  sx?: SxProps;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick, sx }) => {
  return (
    <IconButton onClick={onClick} sx={sx}>
      <CloseIcon />
    </IconButton>
  );
};
