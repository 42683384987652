import { BaseNimbleAPI } from './baseAPI';

class InternalRoleNotesAPI extends BaseNimbleAPI {
  // Internal Role Notes are deleted through a patch to the role api endpoint.
  // Those that are sent back with an 'is_deleted' = True flag are considered deleted.
  get config() {
    return { baseURL: '/api/role/' };
  }

  async destroy(note, job_id, statuses_available) {
    const response = await this.patch(`/${job_id}/`, {
      internal_role_notes: [note],
      statuses_available: statuses_available,
    });
    return response.data;
  }
}

export default new InternalRoleNotesAPI();
