import styled from 'styled-components';

export default function Attachment({
  questionText,
  setQuestionText,
  attachmentDirections,
  setAttachmentDirections,
}) {
  return (
    <>
      <Header className="bold">Attachment name</Header>
      <AttachmentNameInput
        type="text"
        value={questionText}
        placeholder="e.g. Interview notes"
        onChange={(e) => setQuestionText(e.target.value)}
        maxLength="55"
      />
      <Header>Directions to the reviewer</Header>
      <DirectionsTextarea
        value={attachmentDirections}
        placeholder="e.g. Please upload your notes from this interview"
        onChange={(e) => setAttachmentDirections(e.target.value)}
      />
    </>
  );
}

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const AttachmentNameInput = styled.input`
  background-color: #fff;
  padding: 15px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 50px;
  width: 100%;
  margin: 0 !important;
  margin-bottom: 6px !important;

  font-size: 14px;
  color: #777777;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;

const DirectionsTextarea = styled.textarea`
  background-color: #fff;
  padding: 15px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  min-height: 150px;

  color: #8e8d8d;

  resize: vertical;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;
