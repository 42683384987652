import styled from 'styled-components';

import { finalRecommendation } from 'utils/enums';

export default function FinalRecommendationPreview({ parentObject }) {
  const options = parentObject.include_maybe_option
    ? finalRecommendation
    : finalRecommendation.filter((item) => item.value !== 'final_recommendation/maybe');

  return (
    <Container>
      <GreyText>Recommendation:</GreyText>
      <LetterItemContainer>
        {options.map((item) => (
          <LetterItem
            key={item.value}
            color={item.color}
            selected={parentObject.final_recommendation === item.value}
          >
            {item.label.substring(0, 1)}
          </LetterItem>
        ))}
      </LetterItemContainer>
    </Container>
  );
}

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '125px 110px',
  gridGap: '12px',
  alignItems: 'center',
  marginBottom: '8px',
});

const GreyText = styled.p({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  color: '#999999',
});

const LetterItemContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 32px)',
  gridGap: '7px',
});

const LetterItem = styled.div({
  height: '23px',
  width: '32px',
  backgroundColor: '#FFFFFF',
  border: '1px solid',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.095)',
  borderRadius: '3px',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '21px',
  textAlign: 'center',
  color: (props) => (props.selected ? props.color : '#979797'),
  borderColor: (props) => (props.selected ? props.color : '#979797'),
});
