import './categories.css';

import { Component } from 'react';

import CategoriesRow from './CategoriesRow';
import EditCategoryModal from './EditCategoryModal';
import { fetchCategories } from '../../utils/statusviewutils';
import axios from 'axios';
import auth from '../../utils/auth';
import { PrimaryButton } from 'sharedComponents/Buttons';

export default class CategoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      editModalOpen: false,
    };
    this.districtId = auth.getUser().profile.district.id;
    this.fetchCategories = fetchCategories.bind(this);
  }

  componentDidMount() {
    this.fetchCategories(this.districtId);
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  addCategory = (categoryId = null, label) => {
    if (categoryId === null) {
      this.sendUpdate('post', 'categories', '', { label });
    } else {
      this.sendUpdate('post', 'subcategories', '', { label, categoryId });
    }
  };

  editCategory = (categoryId, subcategoryId = null, label) => {
    if (subcategoryId === null) {
      this.sendUpdate('put', 'categories', `${categoryId}/`, { label, categoryId });
    } else {
      this.sendUpdate('put', 'subcategories', `${subcategoryId}/`, {
        label,
        categoryId,
        subcategoryId,
      });
    }
  };

  deleteCategory = (categoryId, subcategoryId = null) => {
    if (subcategoryId === null) {
      this.sendUpdate('delete', 'categories', `${categoryId}/`, {});
    } else {
      this.sendUpdate('delete', 'subcategories', `${subcategoryId}/`, {});
    }
  };

  sendUpdate = (method, url, lookup = '', data) => {
    /** send axios call with the given info. Because this isn't a form, we have to do
     * on the fly axios calls to update as the user goes.
     * @param {string} method: REST method
     * @param {string} url: url string
     * @param {string} lookup (optional): lookup field used to match against a single instance
     */
    axios[method](`/api/${url}/${lookup}`, data).then((r) => this.fetchCategories(this.districtId));
  };

  render() {
    return (
      <div className="mb2">
        <div className="mt2">
          <div id="jobCategories">
            <div className="scorecard-list-header-div">
              <h4 className="bold mt1 mr1 inline-block">Job Categories</h4>
              {/* Remove edit/delete if district belongs to Indiana (IN) */}
              {!auth.getUser().profile.district.indiana_district && (
                <PrimaryButton
                  className="scorecard-list-header-btn"
                  onClick={() => this.setState({ editModalOpen: true })}
                  sx={{ width: '200px' }}
                >
                  + New Category
                </PrimaryButton>
              )}
            </div>
          </div>
          {this.state.categories.map((e, index) => {
            return (
              <CategoriesRow
                key={index}
                category={e}
                categoryCount={this.state.categories.length}
                addCategory={this.addCategory}
                editCategory={this.editCategory}
                deleteCategory={this.deleteCategory}
              />
            );
          })}
        </div>
        {this.state.editModalOpen && (
          <EditCategoryModal
            entityName={'Category'}
            show={this.state.editModalOpen}
            onHide={() => this.setState({ editModalOpen: false })}
            onSave={(label) => this.addCategory(null, label)}
          />
        )}
      </div>
    );
  }
}
