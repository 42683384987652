import React from 'react';
import { Chip } from 'sharedComponents/Chip';
import { ConnectBadgeLabels } from 'features/Connect/utils/connectEnums';
import { SecondarySchoolTagsBadgeProps } from './types';
import { useTheme } from '@mui/material';

export const SecondarySchoolTagsBadge: React.FC<SecondarySchoolTagsBadgeProps> = ({ tagLabel }) => {
  const theme = useTheme();
  return (
    <Chip
      label={`● ${ConnectBadgeLabels[tagLabel]}`}
      variant="filled"
      sx={{
        color: theme.palette.blue.light,
        fontWeight: theme.typography.fontWeightMedium,
        marginX: theme.spacing(1),
        border: `1px solid ${theme.palette.blue.light}`,
        background: theme.palette.blue.fill,
      }}
    />
  );
};
