import { Component } from 'react';
import PropTypes from 'prop-types';

import { preferenceOptions } from '../../../utils/enums';

import iconUpCaretGreen from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ATSCandidatesListFilterTestIds } from 'data-testids/ATS';

export default class SchoolTypeFilter extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    multilingual_school: PropTypes.array.isRequired,
    title_1_school: PropTypes.array.isRequired,
    turnaround_school: PropTypes.array.isRequired,
    selectAll: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions = () => {
    this.setState((state, props) => ({ showOptions: !state.showOptions }));
  };

  selectAll = (e) => {
    e.stopPropagation();
    let options = preferenceOptions();
    this.props.selectAll('multilingual_school', options);
    this.props.selectAll('title_1_school', options);
    this.props.selectAll('turnaround_school', options);
  };

  clearAll = (e) => {
    e.stopPropagation();
    this.props.clearAll('multilingual_school');
    this.props.clearAll('title_1_school');
    this.props.clearAll('turnaround_school');
  };

  render() {
    /** use a set for constant time lookup. Loop through props.values, which is a list of currently
     * active filters. Add each value to the set, and down below in the map function, check
     * if each item is in the set (set.has(o.value). This is faster than looping through
     * the values array during each iteration of map.
     * Also, send set.has(o.value) when the item is clicked, so that the container
     * knows if you're clicking an already active filter (in which case it will turn it off).
     */
    let { multilingual_school, title_1_school, turnaround_school } = this.props;
    let multilingualSchoolSet = new Set();
    let title1SchoolSet = new Set();
    let turnaroundSchoolSet = new Set();

    multilingual_school.forEach((o) => {
      multilingualSchoolSet.add(o);
    });
    title_1_school.forEach((o) => {
      title1SchoolSet.add(o);
    });
    turnaround_school.forEach((o) => {
      turnaroundSchoolSet.add(o);
    });

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    let allSelected =
      multilingual_school.length + title_1_school.length + turnaround_school.length ===
      preferenceOptions().length * 3;
    let noneSelected =
      multilingual_school.length === 0 &&
      title_1_school.length === 0 &&
      turnaround_school.length === 0;
    let isActive = !(allSelected || noneSelected);

    const optionsLength = 3;

    return (
      <div className={`new-cl-filter ${isActive ? 'cl-active' : ''} sub-filter`}>
        <div className="cl-filter-header" onClick={this.toggleOptions}>
          <p className={isActive ? 'cl-active' : ''}>School Types</p>
          <img
            src={
              this.state.showOptions && isActive
                ? iconUpCaretGreen
                : !this.state.showOptions && isActive
                  ? iconDownCaretGreen
                  : this.state.showOptions
                    ? iconUpCaret
                    : iconDownCaret
            }
            alt=""
          />
        </div>
        <div className={`selection-list ${this.state.showOptions ? 'show' : ''}`}>
          <div className="option select-all">
            <span className="drop-select-all" onClick={this.selectAll}>
              Select All
            </span>
            <span
              className="drop-select-all"
              onClick={this.clearAll}
              data-testid={
                ATSCandidatesListFilterTestIds.CANDIDATE_PREFERENCES_SCHOOL_TYPES_CLEAR_ALL_BUTTON
              }
            >
              Clear All
            </span>
          </div>
          <p className="school-type-p">Prefers Multilingual School</p>
          {preferenceOptions().map((o, i) => (
            <div key={i} className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={multilingualSchoolSet.has(o.value)}
                  onChange={() =>
                    this.props.updateMultiSelectFilter(
                      'multilingual_school',
                      o.value,
                      multilingualSchoolSet.has(o.value),
                      optionsLength
                    )
                  }
                />
                <span className="checkmark"></span>
                {o.label}
              </label>
            </div>
          ))}
          <p className="school-type-p">Prefers Title 1 School</p>
          {preferenceOptions().map((o, i) => (
            <div key={i} className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={title1SchoolSet.has(o.value)}
                  onChange={() =>
                    this.props.updateMultiSelectFilter(
                      'title_1_school',
                      o.value,
                      title1SchoolSet.has(o.value),
                      optionsLength
                    )
                  }
                />
                <span className="checkmark"></span>
                {o.label}
              </label>
            </div>
          ))}
          <p className="school-type-p">Prefers Turnaround School</p>
          {preferenceOptions().map((o, i) => (
            <div key={i} className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={turnaroundSchoolSet.has(o.value)}
                  onChange={() =>
                    this.props.updateMultiSelectFilter(
                      'turnaround_school',
                      o.value,
                      turnaroundSchoolSet.has(o.value),
                      optionsLength
                    )
                  }
                />
                <span className="checkmark"></span>
                {o.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
