import './bulkemail.css';

import { Component } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AltModal } from 'ui-kit';
import auth from '../../utils/auth';
import BulkEmailCompose from './BulkEmailCompose';
import BulkEmailPreview from './BulkEmailPreview';
import BulkEmailSend from './BulkEmailSend';
import ErrorText from '../errortext';
import LoadingSpinner from '../loadingSpinner';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import paperAirplane from '../../assets/icon-paper-airplane-green.svg';
import SearchAPI from 'api/searchAPI';

const COMPOSE = 'compose';
const PREVIEW = 'preview';
const SEND = 'send';

export default class BulkEmail extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    emailTemplatesList: PropTypes.array.isRequired,
    selections: PropTypes.any.isRequired,
    deselectAll: PropTypes.func.isRequired,
    candidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchAllCandidates: PropTypes.func.isRequired,
    fetchAllProspects: PropTypes.func,
    jobTitle: PropTypes.string.isRequired,
    emailTemplateSelection: PropTypes.number,
    continueStatusUpdate: PropTypes.func,
    onProspectList: PropTypes.bool.isRequired,
    getUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.userEmail = auth.getUser().username;

    this.state = {
      mode: COMPOSE,
      emailTemplate: {
        subject: '',
        content: '',
      },
      downloadingData: false,
      loadingSpinner: false,
      success: false,
      errors: {},
      candidates: [],
      cc: [],
      bcc: [],
      uploads: [],
    };
    // we can tell if a bulk status update is taking place by whether the continueStatusUpdate function
    // is passed down.
    this.isUpdatingStatus = props.continueStatusUpdate ? true : false;
  }

  componentDidMount() {
    this.setCandidates();
    this.setEmailTemplate();
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  setEmailTemplate = () => {
    /** set relevant email template if an emailtemplate id is passed down in props. */
    if (this.props.emailTemplateSelection) {
      this.updateEmailTemplateSelection({
        target: {
          value: this.props.emailTemplateSelection,
        },
      });
    }
  };

  setCandidates = async () => {
    /** by default, use the candidates data passed down from CandidatesList. If the user selected
     * the Select All button, use the fetchAllCandidates to get the extra data.
     */
    let candidates = [];
    let allCandidates;

    this.setState({ downloadingData: true });
    if (this.props.onProspectList) {
      allCandidates = await this.props.fetchAllProspects(false, false);
    } else {
      allCandidates = await this.props.fetchAllCandidates(
        false,
        false,
        this.props.getUser ? this.props.getUser.profile.district.id : null,
        false
      );
    }

    this.setState({ downloadingData: false });

    // Get the data for the selected candidates by checking the Set
    allCandidates.forEach((candidate) => {
      if (this.props.selections.has(candidate.id)) {
        // if coming from viewByRequisition page, add job title so the variable can be used.
        if (this.props.jobTitle) {
          candidate.job_title = this.props.jobTitle;
        }
        candidates.push(candidate);
      }
    });
    this.setState({ candidates });
  };

  formatDate = (date) => {
    return moment.utc(date).format('dddd, MMMM Do YYYY, h:mm a');
  };

  handleCcChange = (selected) => {
    this.setState({ cc: selected });
  };

  handleBccChange = (selected) => {
    this.setState({ bcc: selected });
  };

  handleChange = (e) => {
    const emailTemplate = this.state.emailTemplate;
    // React quill passes the value instead of event
    if (typeof e === 'string') {
      emailTemplate['content'] = e;
    } else {
      emailTemplate[e.target.name] = e.target.value;
    }
    this.setState({ emailTemplate, errors: {} });
  };

  handleUploads = (uploads) => {
    this.setState({ uploads: uploads });
  };

  updateEmailTemplateSelection = (e) => {
    /** selected template will send id as e.target.value. find in list */
    let emailTemplateProp = this.props.emailTemplatesList.find(
      (template) => template.id === Number(e.target.value)
    );

    if (emailTemplateProp) {
      // copy over properties. Originally this set state with a reference to the same prop object,
      // but since you can edit the subject and message in this template, it was editing props
      // which is bad. It was also causing a bug: https://goo.gl/PX79A4
      let emailTemplate = Object.assign({}, emailTemplateProp);
      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }
      this.setState({ emailTemplate });
    }
  };

  removeRecipient = (id) => {
    /** invoked in the BulkEmailPreview flow */
    let { candidates } = this.state;
    if (candidates.length === 1) {
      // if they're trying to remove the last candidate, just cancel out of the modal.
      return this.onCancel();
    }
    let index = candidates.findIndex((c) => c.id === id);
    candidates.splice(index, 1);
    this.setState({ candidates, errors: {} });
  };

  updateRecipientField = (e, id) => {
    /** User can edit recipient values like first & last name, phone number, etc.
     * @param {object} e: event containing new value and field name.
     * @param {number} id: Id of candidate to update. Find it in this.state.candidates.
     */
    let { candidates } = this.state;
    let candidate = candidates.find((c) => c.id === id);
    if (candidate) {
      if (e.target.name === 'phone') {
        // phone field is nested inside candidate profile. Since the user wants to edit the phone
        // number, we have to edit the fallback numbers as well.
        candidate['profile']['phone_cell'] = e.target.value;
        candidate['profile']['phone_home'] = e.target.value;
        candidate['profile']['phone_work'] = e.target.value;
      } else {
        candidate[e.target.name] = e.target.value;
      }
    }
    this.setState({ candidates, errors: {} });
  };

  nextStep = () => {
    /** check that subject and content are filled out, then move to next step
     * (either PREVIEW or SEND).
     */
    if (this.state.emailTemplate.subject === '') {
      this.setState({ errors: { subject: true } });
      return;
    }
    if (
      this.state.emailTemplate.content === '' ||
      this.state.emailTemplate.content === '<p><br></p>'
    ) {
      this.setState({ errors: { content: true } });
      return;
    }
    // missing variables on the preview page will have a class of 'missing-value'.
    if (this.state.mode === PREVIEW && document.querySelector('.missing-value') !== null) {
      this.setState({ errors: { variables: true } });
      return;
    }
    let nextMode = this.state.mode === COMPOSE ? PREVIEW : SEND;
    this.setState({ mode: nextMode });
  };

  onSend = () => {
    this.setState({ loadingSpinner: false, success: true });
    if (!this.isUpdatingStatus) {
      // if not in the middle of a bulk status update, deselect candidates on send.
      this.props.deselectAll();
      // reset success message after 3 seconds
      setTimeout(() => {
        if (!this.ignoreLastFetch) {
          this.onCancel();
        }
      }, 3000);
    } else {
      // updating status, so continue in CandidatesList
      this.props.continueStatusUpdate();
    }
  };

  onBack = () => {
    // if mode is PREVIEW or SEND, it's 'back' functionality instead of cancel
    if (this.state.mode === PREVIEW) {
      this.setState({ mode: COMPOSE });
    } else if (this.state.mode === SEND) {
      this.setState({ mode: PREVIEW });
    } else {
      this.onCancel();
    }
  };

  onCancel = () => {
    this.props.onHide();
    this.setState({
      emailTemplate: { subject: '', content: '' },
      mode: COMPOSE,
      success: false,
    });
  };

  searchOptions = _.debounce((inputValue, callback) => {
    SearchAPI.searchDistrictUsers({ search: inputValue }).then((data) => {
      callback(
        data.results.map((u) => ({
          label: u.name ? `${u.name} (${u.email})` : u.email,
          value: u.email,
          isValid: true, // Assume valid since it comes from the server
        }))
      );
    });
  }, 300);

  render() {
    const modalTitle = `Send Email to ${this.props.selections.size} ${
      this.props.onProspectList ? 'Prospect' : 'Candidate'
    }${this.props.selections.size === 1 ? '' : 's'}`;

    return (
      <Modal show={this.props.show} bsSize="large" className="bulk-email-modal">
        <AltModal.Title>{this.state.success ? 'Emails sent' : modalTitle}</AltModal.Title>
        <AltModal.Cancel onClick={this.props.onHide}>×</AltModal.Cancel>
        <AltModal.UpperRule />

        {this.state.loadingSpinner || this.state.success ? (
          <AltModal.Body>
            {this.state.loadingSpinner && <LoadingSpinner />}
            {this.state.success && (
              <div className="bulk-email-success-div">
                <img className="bulk-email-success-img" src={paperAirplane} alt="" />
                <h2 className="center">Your email was successfully sent.</h2>
                <button
                  className="bulk-email-modal-button bulk-email-modal-cancel"
                  onClick={this.onCancel}
                >
                  Close
                </button>
              </div>
            )}
          </AltModal.Body>
        ) : (
          <>
            <AltModal.Body p="0">
              {this.state.mode === COMPOSE && (
                <BulkEmailCompose
                  emailTemplate={this.state.emailTemplate}
                  emailTemplatesList={this.props.emailTemplatesList}
                  updateEmailTemplateSelection={this.updateEmailTemplateSelection}
                  handleChange={this.handleChange}
                  handleUploads={this.handleUploads}
                  subjectError={this.state.errors.subject}
                  messageError={this.state.errors.content}
                  jobTitle={this.props.jobTitle}
                  onProspectList={this.props.onProspectList}
                  selectionSize={this.props.selections.size}
                  cc={this.state.cc}
                  handleCc={this.handleCcChange}
                  bcc={this.state.bcc}
                  handleBcc={this.handleBccChange}
                  searchOptions={this.searchOptions}
                />
              )}
              {this.state.mode === PREVIEW && (
                <BulkEmailPreview
                  candidates={this.state.candidates}
                  removeRecipient={this.removeRecipient}
                  emailTemplate={this.state.emailTemplate}
                  updateRecipientField={this.updateRecipientField}
                  errors={this.state.errors}
                />
              )}
              {this.state.mode === SEND && (
                <BulkEmailSend
                  candidates={this.state.candidates}
                  cc={this.state.cc}
                  bcc={this.state.bcc}
                  emailTemplate={this.state.emailTemplate}
                  uploads={this.state.uploads}
                  userEmail={this.userEmail}
                  startSpinner={() => this.setState({ loadingSpinner: true })}
                  onSend={this.onSend}
                  onProspectList={this.props.onProspectList}
                />
              )}

              {this.state.errors.variables && (
                <ErrorContainer>
                  <ErrorText message="We’ve highlighted some blank variables. Please update those before proceeding." />
                </ErrorContainer>
              )}
            </AltModal.Body>
            {!this.state.success && (
              <AltModal.Actions px="22px">
                <ModalFooterNew
                  cancel={this.onBack}
                  cancelButtonLabel={this.state.mode === COMPOSE ? 'Cancel' : 'Back'}
                  save={this.state.mode === SEND ? null : this.nextStep}
                  saveButtonLabel={this.state.mode === SEND ? '' : 'Next Step'}
                  saveDisabled={this.state.downloadingData}
                  deleteFunction={this.isUpdatingStatus ? this.props.continueStatusUpdate : null}
                  deleteButtonLabel="Skip email"
                  errorMessage={this.state.uploadError}
                />
              </AltModal.Actions>
            )}
          </>
        )}
      </Modal>
    );
  }
}

const ErrorContainer = styled.div`
  margin-left: 40px;
`;
