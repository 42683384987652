import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactTooltip from 'react-tooltip';
import PositionNotes from './PositionNotes';
import EditVacancyModal from '../AddVacancyModal/editVacancyModal';

import axios from 'axios';
import auth from '../../../../utils/auth';

import noteIcon from '../../../../assets/icons/icon_note.svg';
import noteIconActive from '../../../../assets/icons/icon_note_green.svg';

import { getParameterByName } from '../../../../utils/util';
import { vacancyTypeColor } from '../../../../utils/enums';
import { ATSVacancyDashboardDataTestIds } from '../../../../data-testids/ATS';

export default class VacancyRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingName: false,
      noteShowing: false,
      editVacancyModalShowing: false,
      dropdownValue: -1,
    };
  }

  static propTypes = {
    vacancy: PropTypes.object,
    unplacedCandidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeCandidateFromVacancy: PropTypes.func.isRequired,
    pairCandidateWithVacancy: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object),
    updateVacancyInContainerState: PropTypes.func.isRequired,
    roleOptions: PropTypes.array.isRequired,
    schoolOptions: PropTypes.array.isRequired,
    putVacancy: PropTypes.func.isRequired,
    deleteVacancy: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // open notes if url param is present
    if (
      [Number(getParameterByName('pid')), Number(getParameterByName('spid'))].includes(
        this.props.vacancy.id
      )
    ) {
      this.setState({ noteShowing: true });
    }
  }

  deleteVacancyThenCloseModal = (vacancy) => {
    this.props.deleteVacancy(vacancy).finally(this.closeEditVacancyModal);
  };

  putVacancyThenCloseModal = (updatedVacancy) => {
    this.props.putVacancy(updatedVacancy).finally(this.closeEditVacancyModal);
  };

  closeEditVacancyModal = () => {
    this.setState({ editVacancyModalShowing: false });
  };

  openEditVacancyModal = () => {
    if (this.isDistrictOrSuperAdmin()) {
      this.setState({ editVacancyModalShowing: true });
    }
  };

  isDistrictOrSuperAdmin = () => {
    return auth.isDistrictAdmin();
  };

  renderCategories = () => {
    let subcategories = this.props.vacancy.district_role.subcategories;
    if (!subcategories || subcategories.length === 0) {
      return null;
    } else {
      return subcategories.join(', ');
    }
  };

  onSubmitNote = async (message, recipientList) => {
    await axios
      .patch(`/api/position/${this.props.vacancy.id}/`, {
        id: this.props.vacancy.id,
        is_district_position: this.props.vacancy.school_role ? false : true,
        note: message,
      })
      .then((r) => {
        let vacancy = r.data;
        this.props.updateVacancyInContainerState(vacancy);
        return 201;
      })
      .catch((error) => console.log(error));
    // After success, notify the tagged users
    this.emailTaggedUsers(recipientList, message);
  };

  emailTaggedUsers = (recipientList, message) => {
    const { vacancy } = this.props;
    const tagObjects = [];

    recipientList.forEach((recipient) => {
      const url = this.getUrl();
      const tagObj = {
        user_id: recipient.id,
        location: 'on the vacancy dashboard',
        note_url: url,
        note_copy: message,
        role_title: vacancy.district_role.title,
        school_name: this.getSchoolName(),
        district_name: auth.getUser().profile.district.name,
      };
      tagObjects.push(tagObj);

      this.sendTagNotifications(tagObjects);
    });
  };

  getSchoolName = () => {
    if (this.props.vacancy.school_role) {
      return this.props.vacancy.school_role.school.name;
    } else {
      return null;
    }
  };

  getUrl = () => {
    const host = window.location.hostname;
    const colon = host.indexOf('localhost') !== -1 ? ':' : '';
    const port = window.location.port;

    const positionId = this.props.vacancy.id;
    let isSchoolVacancy = this.props.vacancy.school_role ? true : false;

    const url = `${window.location.protocol}//${host}${colon}${port}/dashboards/vacancy?${
      isSchoolVacancy ? 's' : ''
    }pid=${positionId}`;

    return url;
  };

  sendTagNotifications = (tagObjects) => {
    return axios.post('/api/send_tag_notifications/', tagObjects).catch((err) => console.log(err));
  };

  renderCandidateName = () => {
    if (this.props.vacancy.candidate) {
      return this.getCandidateJSX();
    } else {
      const hiredCandidates = this.getHiredCandidatesForThisVacancy();
      return this.getCandidatesDropdownJSX(hiredCandidates);
    }
  };

  getHiredCandidatesForThisVacancy = () => {
    const hiredCandidatesForThisVacancy = this.props.unplacedCandidates.filter(
      this.candidateHasRelatedApp
    );
    return hiredCandidatesForThisVacancy;
  };

  candidateHasRelatedApp = (candidate) => {
    const isSchoolVacancy = this.props.vacancy.hasOwnProperty('school_role');
    if (isSchoolVacancy) {
      if (
        this.candidateHasRelatedSchoolApplication(candidate) ||
        this.candidateHasRelatedDistrictApp(candidate)
      ) {
        return true;
      }
    } else {
      return this.candidateHasRelatedDistrictApp(candidate);
    }
    return false;
  };

  candidateHasRelatedSchoolApplication = (candidate) => {
    const relatedSchoolApp = candidate.schoolapplications.find(this.isRelatedToVacancySchoolRole);
    if (relatedSchoolApp) {
      return true;
    } else {
      return false;
    }
  };

  candidateHasRelatedDistrictApp = (candidate) => {
    const relatedApp = candidate.applications.find(this.isRelatedToVacancyRole);
    if (relatedApp) {
      return true;
    } else {
      return false;
    }
  };

  isRelatedToVacancyRole = (app) => {
    return app.role_id === this.props.vacancy.district_role.id;
  };

  isRelatedToVacancySchoolRole = (schoolapp) => {
    return schoolapp.role_id === this.props.vacancy.school_role.id;
  };

  getCandidateJSX = () => (
    <div className="inline-block">
      <span className="pointer">{this.props.vacancy.candidate.name}</span>
    </div>
  );

  getCandidatesDropdownJSX = (hiredCandidates) => {
    if (hiredCandidates.length === 0) {
      return null;
    }
    return (
      <div className="inline-block" onClick={(event) => event.stopPropagation()}>
        <AddCandidateSelect
          name="candidate"
          value={this.state.dropdownValue}
          onChange={this.selectCandidateForVacancy}
        >
          <option key={-1} value={-1}>
            + Add Candidate
          </option>
          {hiredCandidates.map((item, i) => (
            <option key={i} value={item.id}>
              {item.name}
            </option>
          ))}
        </AddCandidateSelect>
      </div>
    );
  };

  selectCandidateForVacancy = (event) => {
    const candidate_id = Number(event.target.value);
    this.props.pairCandidateWithVacancy(candidate_id, this.props.vacancy);
  };

  removeCandidate = (event) => {
    this.props.removeCandidateFromVacancy(this.props.vacancy, event);
  };

  openNotes = (event) => {
    event.stopPropagation();
    this.addClickListener();
    this.openNoteWindow();
  };

  addClickListener = () => {
    document.addEventListener('click', this.outsideClickListener, { capture: true });
  };

  openNoteWindow = () => {
    this.setState({ noteShowing: true });
  };

  outsideClickListener = (event) => {
    if (this.keepNotesOpen(event)) {
      return;
    } else {
      this.removeClickListener();
      this.closeNoteWindow();
    }
  };

  keepNotesOpen = (event) => {
    return this.clickedInsideBox(event) && this.didNotClickCancel(event);
  };

  clickedInsideBox = (event) => {
    return (
      event.target.className.indexOf('note-textarea') !== -1 ||
      event.target.className.indexOf('submit') !== -1
    );
  };

  didNotClickCancel = (event) => {
    return event.target.className.indexOf('cancel-button') === -1;
  };

  removeClickListener = () => {
    document.removeEventListener('click', this.outsideClickListener, false);
  };

  closeNoteWindow = () => {
    this.setState({ noteShowing: false });
  };

  render() {
    const { vacancy } = this.props;
    const isDistrictVacancy = vacancy.school_role ? false : true;
    let hasCandidate = vacancy.candidate && vacancy.candidate.id;

    return (
      <div className="relative">
        <div
          className={`vacancy-row list-item list-row ${vacancy.id} ${
            this.isDistrictOrSuperAdmin() ? 'pointer' : ''
          }`}
          onClick={this.openEditVacancyModal}
          data-testid={ATSVacancyDashboardDataTestIds.VACANCY_ROW}
        >
          <div className="vacancy-job-posting">
            <div className={`status-circle ${vacancyTypeColor[vacancy.vacancy_type]} mr1`} />
            <p
              className="overflow-ellipsis"
              data-testid={ATSVacancyDashboardDataTestIds.VACANCY_ROW_JOB_TITLE}
            >
              {vacancy.district_role.title}
            </p>
          </div>
          <div className="vacancy-school overflow-ellipsis">
            {isDistrictVacancy ? 'N/A' : vacancy.school_role.school.name}
          </div>
          <div className="vacancy-category overflow-ellipsis">{this.renderCategories()}</div>
          <div className="vacancy-fte">
            {parseFloat(Math.round(vacancy.ftes * 100) / 100).toFixed(2)}
          </div>
          <div
            className={'vacancy-candidate ' + (hasCandidate ? 'has-candidate-name' : '')}
            data-testid={ATSVacancyDashboardDataTestIds.VACANCY_ROW_CANDIDATE_TITLE}
          >
            <div>
              {this.renderCandidateName()}

              {hasCandidate && (
                <div className="inline-block pointer" onClick={this.removeCandidate}>
                  x
                </div>
              )}
            </div>
          </div>
          <div className="vacancy-note" onClick={this.openNotes}>
            <img
              src={vacancy.notes.length > 0 ? noteIconActive : noteIcon}
              alt="notes"
              className="no-propogate"
              data-tip
              data-for="notes"
              data-place="top"
              data-delay-show="100"
            />
            <ReactTooltip id="notes" effect="solid">
              <span>+ Note</span>
            </ReactTooltip>
          </div>
        </div>
        {this.state.noteShowing && (
          <PositionNotes
            closeNoteWindow={this.closeNoteWindow}
            position={vacancy}
            onSubmitNote={this.onSubmitNote}
            adminUsers={this.props.adminUsers}
          />
        )}
        {this.state.editVacancyModalShowing && (
          <EditVacancyModal
            vacancy={vacancy}
            show={this.state.editVacancyModalShowing}
            onHide={this.closeEditVacancyModal}
            roleOptions={this.props.roleOptions}
            schoolOptions={this.props.schoolOptions}
            putVacancyThenCloseModal={this.putVacancyThenCloseModal}
            deleteVacancyThenCloseModal={this.deleteVacancyThenCloseModal}
          />
        )}
      </div>
    );
  }
}

const AddCandidateSelect = styled.select`
  display: inline-block;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  background-color: transparent;
  color: #9e9e9e;

  & > option {
    color: #000;
  }
`;
