import { useState, useEffect } from 'react';
import './_index.scss';

import styled from 'styled-components';
import { grid } from 'styled-system';

import { Button, Icons } from 'ui-kit';
import Tooltip from '../../assets/tooltip.svg';
import ReactTooltip from 'react-tooltip';
import { withFeatures } from '../../hoc/Feature';
import { featureTags } from '../../context/featureContext';
import BasicLocationPreferences from './basicLocationPreferences';
import { LocationPreferencesProps } from '../../types/types';
import { StyledErrorText } from 'components/Login/LoginForm';
import defaultMap from '../../assets/default-map.png';

import { MOBILE_BREAKPOINT } from 'features/ProfilePreference/defaults';
import { ComponentsLocationPreferencesDataTestIds } from '../../data-testids/Components';
import { ATSCandidatePreferencesDataTestIds } from '../../data-testids/ATS';

const MAP_DISTANCE_OPTIONS = ['Any', '5', '10', '25', '50', '75', '100'];
const MOBILE_BREAKPOINT_STRING = `${MOBILE_BREAKPOINT}px`;

interface SearchDivProps {
  showError: boolean;
}

interface StyledBackgroundImageProps {
  src: string;
  alt: string;
}

interface RequiredAsteriskProps {
  hideAsterisk?: boolean;
}

interface showMapErrorSelect {
  showMapErrorSelect?: boolean;
}

function LocationPreferences(props: LocationPreferencesProps) {
  const [deviceWidth, setDeviceWidth] = useState<number>(0);

  useEffect(() => {
    const checkWidth = () => {
      setDeviceWidth(window.innerWidth);
    };

    checkWidth();
    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  return (
    <div>
      <Container>
        <TopDiv>
          <h4>Location</h4>
          <img
            style={{ marginLeft: '10px' }}
            src={Tooltip}
            alt="tooltip"
            data-tip={`
                        Update your address and click search<br/>
                        to adjust pin.<br/>
                        `}
            data-for="hrbp-assignments"
          />
        </TopDiv>
        <LeftColumn className="section">
          <LeftAlignedDiv className="preferences-container__location-section-content">
            <PreferenceContainer>
              <p style={{ whiteSpace: 'nowrap' }}>
                I prefer to work within{' '}
                <RequiredAsterisk hideAsterisk={deviceWidth > MOBILE_BREAKPOINT}>
                  *
                </RequiredAsterisk>
              </p>
              <div className="select-form-field">
                <select
                  className="pointer"
                  style={{
                    backgroundColor: props.showMapErrorSelect && '#FDEDEC',
                    border: props.showMapErrorSelect && '1px solid',
                    borderColor: props.showMapErrorSelect && 'red',
                    marginBottom: props.showMapErrorSelect && '0',
                  }}
                  name="miles_within"
                  value={props.milesWithin}
                  onChange={props.handleChangeSelect}
                  data-testid={ComponentsLocationPreferencesDataTestIds.MILES_WITHIN_SELECT}
                >
                  <option value="">Select</option>
                  {MAP_DISTANCE_OPTIONS.map((i, key) => (
                    <option value={i} key={key}>
                      {i}
                    </option>
                  ))}
                </select>
                {props.showMapErrorSelect === true && (
                  <LocationPrefsErrorText
                    style={{
                      marginLeft: '0px',
                      marginBottom: '15px',
                      marginTop: '8px',
                      display: 'inline-block',
                    }}
                  >
                    Please select an option
                  </LocationPrefsErrorText>
                )}
              </div>
              {deviceWidth > MOBILE_BREAKPOINT && (
                <MilesOfText>
                  miles of <RequiredAsterisk style={{ marginTop: '20px' }}>*</RequiredAsterisk>
                </MilesOfText>
              )}
            </PreferenceContainer>
          </LeftAlignedDiv>
          <LeftAlignedDiv>
            {deviceWidth <= MOBILE_BREAKPOINT && (
              <MilesOfText style={{ paddingLeft: 0 }}>miles of</MilesOfText>
            )}
            <SearchDiv showError={props.showMapErrorInput}>
              <input
                name="location"
                className="form-field input-location"
                style={{
                  backgroundColor: props.showMapErrorInput && '#FDEDEC',
                }}
                placeholder="Address or zip code"
                value={props.location || ''}
                onChange={props.handleChange}
                data-testid={ComponentsLocationPreferencesDataTestIds.MILES_OF_INPUT}
                onBlur={(e) => props.hadleSearchButton(e, props.location, props.milesWithin)}
                onKeyUp={(e) =>
                  props.handleAddressSearchOnEnter(e, props.location, props.milesWithin)
                }
              />
              <StyledButtonSearch
                onClick={(e) => props.hadleSearchButton(e, props.location, props.milesWithin)}
              >
                <Icons.SearchGlassIcon />
              </StyledButtonSearch>
            </SearchDiv>
            <RequiredAsterisk style={{ marginLeft: '5px' }}>*</RequiredAsterisk>
          </LeftAlignedDiv>
          {props.showMapErrorInput === true && (
            <StyledErrorText>Please enter a valid address or zipcode</StyledErrorText>
          )}
          <LeftAlignedDiv>
            <VisibleToRecruitersContainer>
              <StyledCheckbox
                checked={props.checked || false}
                onChange={props.handleCheckboxChange}
              />
              <VisibleToRecruitersText>
                Visible to recruiters outside of this radius{' '}
                <img
                  src={Tooltip}
                  alt="tooltip"
                  data-tip={`
                            This will help determine which recruiters <br/>
                            are able see your candidate profile.<br/>
                            `}
                  data-for="hrbp-assignments"
                />
              </VisibleToRecruitersText>
              <ReactTooltip id="hrbp-assignments" effect="solid" multiline />
            </VisibleToRecruitersContainer>
          </LeftAlignedDiv>
        </LeftColumn>
        <RightColumn>
          {props.showMap ? (
            <StyledBackgroundImage
              src={props.mapUrl}
              alt="locations"
              data-testid={ATSCandidatePreferencesDataTestIds.MAP_IMAGE}
            />
          ) : (
            <StyledBackgroundImage
              src={defaultMap}
              alt="default-location"
              data-testid={ATSCandidatePreferencesDataTestIds.MAP_IMAGE}
            />
          )}
        </RightColumn>
      </Container>
    </div>
  );
}

export default withFeatures({
  featureName: featureTags.LOCATION_PREFERENCES,
  fallback: BasicLocationPreferences,
})(LocationPreferences);

const Container = styled.div`
  ${grid}

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 10px;

  background-color: white;
  border: 1px solid #e6e6e6;
  border-bottom: 0px;
  padding: 20px 32px;

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

const TopDiv = styled.div`
  display: inline-flex;
  grid-column: 1 / 3;
`;

const LeftColumn = styled.div`
  grid-column: 1 / 2;
  max-width: 100%;
  border: none !important;
  background-color: white;
  padding: 20px 32px;
  border-bottom: none;
  padding: 0px !important;
`;

const RightColumn = styled.div`
  grid-column: 2 / 3;
  width: 460px;

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    width: 100%;
  }
`;

export const LeftAlignedDiv = styled.div<showMapErrorSelect>`
  display: flex;
  justify-content: flex-start;
  max-width: 85%;

  .preference-text {
    margin-top: 28px;
    margin-right: 5px;
  }

  .select-form-field {
    white-space: nowrap;
    overflow: visible;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
  }

  select {
    background-color: ${(props) => (props.showMapErrorSelect ? '#FDEDEC' : 'white')};
    border: 1px solid ${(props) => (props.showMapErrorSelect ? 'red' : '#ccc')};
    margin-bottom: ${(props) => (props.showMapErrorSelect ? '0' : '15px')};
  }

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    align-items: center;

    .preference-text,
    .select-form-field {
      margin-top: 0;
    }

    .select-form-field {
      width: auto;
      margin-left: 5px;
      padding-left: 4px;
    }

    select {
      width: 100px;
    }
  }
`;

const StyledButtonSearch = styled(Button)`
  background-color: var(--green);
  border-radius: 0px 2px 2px 0px;
  padding: 0;
  width: 60px;
  height: 60px;
  border: 1px solid var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchDiv = styled.div<SearchDivProps>`
  display: flex;
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 0;
  margin: 0;
  border-radius: 0px 2px 2px 0px;
  background-color: ${(props) => props.showError && '#FDEDEC'};
  border-color: ${(props) => props.showError === true && 'red'};

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    flex: 1 1 50%;
    margin: 32px 16px 0 16px;
  }
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  margin: 0 !important;

  &:checked {
    background-color: var(--green);
    border: 0;
    outline: none;
  }

  &:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    box-shadow: none !important;
  }

  &focus::before {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    box-shadow: none !important;
  }

  &:before {
    content: '✔';
    position: absolute;
    left: 3px;
    font-size: 12px;
    color: transparent;
    outline: none;
    border: none;
  }

  &:focus::before {
    outline: none;
    border: none;
  }

  &:checked:before {
    color: white;
    outline: none;
    border: none;
  }
`;

const LocationPrefsErrorText = styled(StyledErrorText)`
  margin-left: 0px;
  margin-bottom: 15px;
  margin-top: 8px;
  display: inline-block;
`;

const StyledBackgroundImage = styled.img<StyledBackgroundImageProps>`
  background:
    url(${(props) => props.src}),
    lightgray 50% / cover no-repeat;
  width: 460px;
  height: 360px;
  background-position: center;

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    width: 100%;
    height: 0;
    padding-top: 66.83%; // Maintain aspect ratio
  }
`;

const PreferenceContainer = styled.div`
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  flex-grow: 1;

  div {
    overflow: visible;
  }

  p {
    padding-right: 5px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    flex-direction: row;

    p {
      margin: 0;
      padding: 0;
      flex: 1 1 50%;
      flex-wrap: nowrap;
    }

    .select-form-field {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      overflow: visible;
      flex: 1 0 82px;
    }
  }
`;

const RequiredAsterisk = styled.span<RequiredAsteriskProps>`
  display: ${(props) => (props.hideAsterisk ? 'none' : 'inline')};

  color: #c00;
  margin: 20px 0 0 4px;
`;

const MilesOfText = styled.p`
  padding-left: 5px;

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    margin-top: 28px;
  }
`;

const VisibleToRecruitersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0px;

  @media (max-width: ${MOBILE_BREAKPOINT_STRING}) {
    padding: 30px 10px;
  }
`;

const VisibleToRecruitersText = styled.p`
  padding: 10px;
`;
