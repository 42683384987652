import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import roleSearchAPI from 'api/roleSearchAPI';
import usersAPI from 'api/usersAPI';
import { Job, User } from 'types/types';

export const fetchActiveOpenRoles = (inputValue: string): Promise<Job[]> => {
  return roleSearchAPI.searchActiveOpenDistrictRoles(inputValue).then((res) => res.results);
};

export const useQuerySearchActiveOpenRoles = (
  inputValue: string,
  options: Partial<UseQueryOptions<Job[]>> = {}
): UseQueryResult<Job[]> =>
  useQuery({
    ...options,
    queryKey: ['activeOpenSearchResults', inputValue],
    queryFn: () => fetchActiveOpenRoles(inputValue),
  });

export const fetchCandidate = (candidateId: number): Promise<User> => {
  return usersAPI.fetchUser(candidateId).then((res) => res);
};

export const useQueryFetchCandidateForConnectATSIntegration = (
  candidateId: number
): UseQueryResult<User> => {
  return useQuery({
    queryKey: ['candidate', candidateId],
    queryFn: () => fetchCandidate(candidateId),
  });
};
