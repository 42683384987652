import { BaseNimbleAPI } from './baseAPI';

class SchoolrolesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/schoolrole' };
  }

  async fetchSchoolRole(schoolroleId) {
    const url = `/${schoolroleId}/`;
    const response = await this.get(url);
    return response.data;
  }

  async createSchoolrole(districtRoleID, newSchoolRole) {
    const url = '/';
    const data = { districtRoleID, schoolRoles: [newSchoolRole] };
    const response = await this.post(url, data);
    return response.data;
  }

  async postSchoolrole(schoolrole) {
    const url = '/';
    const response = await this.post(url, schoolrole);
    return response.data;
  }

  async updateSchoolrole(schoolrole) {
    const url = `/${schoolrole.id}/`;
    const response = await this.put(url, schoolrole);
    return response.data;
  }

  async fetchFilterStatuses(roleId) {
    const url = `/${roleId}/filter_statuses/`;
    const response = await this.get(url);
    return response.data;
  }
}

export default new SchoolrolesAPI();
