import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material';
import { BasicSelect } from 'sharedComponents/Select';
import { Input } from 'sharedComponents/Input';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { JobDiscoveryFilterContainerProps } from 'types/connectTypes';
import { useEffect, useState } from 'react';
import GeolocationAPI from '../../../api/geolocationAPI';
import { ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../../ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export const JobDiscoveryLocationFilter: React.FC<JobDiscoveryFilterContainerProps> = ({
  filterValues,
  filterOptions,
  distanceDisplayName,
  locationDisplayName,
  locationChangeHandler,
  actions,
  setShowMap,
  setMapUrl,
  mapUrl,
}) => {
  const jobboardState = useStateParam();

  const [selectedDistance, setSelectedDistance] = useState<string>('');
  const [locationInput, setLocationInput] = useState<string>(
    CONNECT_JOBBOARD_STATES[jobboardState]?.defaultUserLocation
  );
  const [locationLongitude, setLocationLongitude] = useState<string>('');
  const [locationLatitude, setLocationLatitude] = useState<string>('');
  const [showMapInputError, setShowMapInputError] = useState<boolean>(false);
  const [showMapSelectError, setShowMapSelectError] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const setDistanceFromLocationHandler = (event) => {
    handleAddressSearch(event, locationInput, selectedDistance);
  };

  const fetchMapUrl = async (data, initial) => {
    const response = await GeolocationAPI.getMapUrl(data);
    if (response[0] === 200) {
      const showMapCopy = response[1]?.map_url ? true : false;
      setShowMapInputError(false);
      setShowMapSelectError(false);
      setShowMap(showMapCopy);
      setLocationLatitude(response[1].latitude);
      setLocationLongitude(response[1].longitude);
    } else {
      const showErrorInput =
        response[1].error === 'invalid address' || response[1].error === 'address is required'
          ? true
          : false;
      const showErrorSelect = data.radius === '' ? true : false;
      if (!initial) {
        setShowMapInputError(showErrorInput);
        setShowMapSelectError(showErrorSelect);
        setShowMap(false);
        setMapUrl('');
      }
    }
    return response[1].map_url;
  };

  const handleAddressSearch = async (event, location, distance) => {
    event.preventDefault();
    const data = {
      address: location,
      radius: distance,
      isMobile: isMobile,
    };
    const mapURL = await fetchMapUrl(data, false);
    setMapUrl(mapURL);
  };

  useEffect(() => {
    if (mapUrl && mapUrl.length > 0) {
      setShowMap(true);
    }

    const distanceRadiusMiles = selectedDistance !== 'Any' ? selectedDistance : '';
    const distanceIsActive = selectedDistance !== 'Any';

    locationChangeHandler({
      distance_radius_miles: distanceRadiusMiles,
      location_latitude: locationLatitude,
      location_longitude: locationLongitude,
    });

    actions.setFilterIsActive(ConnectFilterName.LOCATION, true);
    actions.setFilterIsActive(ConnectFilterName.DISTANCE_RADIUS_MILES, distanceIsActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapUrl]);

  return (
    <Grid container spacing={1} rowGap={0.5}>
      <Grid item xs={12} md={6} lg={6}>
        <Input
          label="Location"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.LOCATION_INPUT}
          placeholder={locationDisplayName}
          onChange={(e) => {
            setLocationInput(e.target.value);
          }}
          value={locationInput}
          size="small"
          sx={{
            width: '100%',
            radius: 8,
          }}
        />
        {showMapInputError && (
          <Typography sx={{ color: theme.palette.error.main }}>
            Please enter a valid address or zipcode
          </Typography>
        )}
      </Grid>
      <Grid container item direction={'row'} spacing={1} xs={12} md={6} lg={6}>
        <Grid item xs={9} lg={7}>
          <BasicSelect
            displayName={distanceDisplayName}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.DISTANCE_FILTER}
            handleChange={(distance) => {
              setSelectedDistance(distance);
            }}
            options={filterOptions}
            sx={{ width: '100%', minWidth: 110 }}
            size="small"
            values={filterValues.selectedDistance}
          />
          {showMapSelectError && (
            <Typography sx={{ color: theme.palette.error.main }} alignSelf={'center'}>
              Please select an option
            </Typography>
          )}
        </Grid>
        <Grid item xs={3} lg={5}>
          <PrimaryButton
            dataTestId={
              ConnectJobDiscoveryFiltersDataTestIds.UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON
            }
            size="large"
            onClick={(e) => {
              setDistanceFromLocationHandler(e);
            }}
            sx={{
              minWidth: '36px',
              width: '100%',
              boxShadow: 'none',
              backgroundColor: theme.palette.black.light,
              '&:hover': {
                backgroundColor: theme.palette.gray.medium,
              },
            }}
          >
            <Typography alignSelf={'center'}>Search</Typography>
          </PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
