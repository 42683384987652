import { useState } from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';
import PasswordReset from './PasswordReset';

export default function HelpSection() {
  const [mode, setMode] = useState({
    passwordReset: true,
    createAdmin: false,
    other: false,
  });

  const getCopy = () => {
    if (mode.passwordReset) {
      return (
        <>
          <MessageHeader>Password Reset</MessageHeader>
          <Message>
            Enter your account’s email address and we’ll send you a secure link to reset your
            password.
          </Message>
        </>
      );
    } else if (mode.createAdmin) {
      return (
        <>
          <MessageHeader>Admin Account</MessageHeader>
          <Message>
            For assistance creating an admin account, please reach out to your organization’s Human
            Resources team directly. Creating a new account on this page is only for candidates.
          </Message>
          <br />
          <Message>
            If you already have an admin account but have forgotten your password, select “I forgot
            my password” above for help with that!
          </Message>
        </>
      );
    } else if (mode.other) {
      return (
        <>
          <MessageHeader>Contact Support</MessageHeader>
          <Message>
            If you need further technical assistance, you can email us at{' '}
            <StyledLink href="mailto:support@hirenimble.com">support@hirenimble.com</StyledLink>.{' '}
            Remember, you don’t need to contact us to reset your password. Simply click “I forgot my
            password” above for help with that!
          </Message>
          <br />
          <Message>
            If you have questions about the application process or materials, please reach out
            directly to the organization’s HR team.
          </Message>
        </>
      );
    }
  };

  return (
    <OuterContainer>
      <GridContainer>
        <HeaderContainer>
          <HeaderText>Need help?</HeaderText>
        </HeaderContainer>
        <HelpContainer>
          <CheckboxContainer>
            <Checkbox
              text="I forgot my password"
              isActive={mode.passwordReset}
              onClick={() =>
                setMode({
                  passwordReset: true,
                  createAdmin: false,
                  other: false,
                })
              }
              marginBottom="10px"
            />
            <Checkbox
              text="I need to create an admin account"
              isActive={mode.createAdmin}
              onClick={() =>
                setMode({
                  passwordReset: false,
                  createAdmin: true,
                  other: false,
                })
              }
              marginBottom="10px"
            />
            <Checkbox
              text="I have a different issue"
              isActive={mode.other}
              onClick={() =>
                setMode({
                  passwordReset: false,
                  createAdmin: false,
                  other: true,
                })
              }
              marginBottom="10px"
            />
          </CheckboxContainer>
          <HorizontalRow />
          <MessageContainer>{getCopy()}</MessageContainer>
          {mode.passwordReset && <PasswordReset />}
        </HelpContainer>
        <CopyrightText>&copy; 2020 Nimble Hiring, PBC, All Rights Reserved</CopyrightText>
      </GridContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 1200px) {
    background-color: #e5e5e5;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 260px 1fr;
  grid-template-rows: 80px 1fr 48px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;

  margin: 0 auto 10px;
  margin-top: 36px;

  position: relative;

  background-color: #fff;
  padding: 0 32px;
  width: 100%;
  height: 537px;

  @media screen and (min-width: 1200px) {
    width: unset;
  }
`;

const HeaderContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  align-self: center;
  text-align: center;
`;

const HeaderText = styled.p`
  font-weight: bold;
  font-size: 18px;

  color: rgba(24, 26, 33, 0.7);
`;

const HelpContainer = styled.div`
  grid-column-start: 2;
  grid-row-start: 2;
`;

const HorizontalRow = styled.hr`
  border: none;
  border-bottom: 1px solid #c4c4c4;
  margin: 70px 16px 8px;
`;

const MessageContainer = styled.div`
  padding: 16px;
`;

const MessageHeader = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
`;

const Message = styled.p`
  font-size: 13px;
  line-height: 16px;
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-column: 1fr;
  grid-gap: 10px;
`;

const CopyrightText = styled.div`
  position: absolute;
  bottom: -28px;
  font-size: 11px;
  line-height: 23px;
  text-align: center;
  width: 100%;

  color: rgba(24, 26, 33, 0.5);

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const StyledLink = styled.a`
  color: #00b88d;
  font-weight: bold;

  &:focus,
  &:hover {
    color: #00b88d;
  }
`;
