import styled from 'styled-components';

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 44px;
`;

export const HomepageContentContainer = styled.div`
  display: grid;
  grid-template-rows: 26px 1fr;
  grid-gap: 21px;
`;

export const HeaderLabel = styled.p`
  color: #393c49;
  font-size: 19px;
  font-weight: 400;

  justify-self: start;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 2fr 2fr 1fr 90px;
  grid-column-gap: 23px;
  align-items: center;

  padding: 0 20px;
  font-size: 15px;
`;

export const HeaderRow = styled(Row)`
  height: 21px;
  font-weight: 600;
  margin-bottom: 6px;
`;

HeaderRow.displayName = 'HeaderRow';

export const ContentRow = styled(Row)`
  height: 50px;

  &:hover {
    background-color: ${({ isOverdue }) =>
      isOverdue ? 'rgba(239, 86, 117, 0.09)' : 'rgba(0, 184, 141, 0.09)'};
  }
`;

ContentRow.displayName = 'ContentRow';
