import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import auth from '../../../utils/auth';
import { colorMapping, roleStatusesForCandidatesList } from '../../../utils/enums';

import rightCaretGrey from '../../../assets/icon_right_caret_grey.svg';
import { StarIcon } from 'ui-kit/icons';
import { RefreshIcon } from 'ui-kit/icons';

import { GreenScorecardIcon } from 'ui-kit/icons';
import { ReactComponent as ScorecardIcon } from 'assets/icon-scorecard.svg';
import ScorecardModal from 'components/ScorecardModal';
import { ATSCandidateListTestIds } from '../../../data-testids/ATS';

export default class CandidateApplicationsList extends Component {
  static propTypes = {
    toggleQuickProfile: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired,
    isSchoolUser: PropTypes.bool,
    activeCandidatesOnly: PropTypes.bool.isRequired,
    district: PropTypes.object.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedApplications: PropTypes.object,
    toggleSelection: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.urlPrefix = auth.isSchoolUser() ? '/school/' : '/district/';
    this.state = {
      overrideStatus: false,
      showScorecardModal: false,
    };

    this.isSchoolUser = auth.isSchoolUser();
  }

  getRoleStatus = (application) => {
    let role = application.role;
    let statusString = roleStatusesForCandidatesList[role.status];
    if (statusString === 'Active') {
      // Check whether open or closed active role
      if (role.active_status === 1) {
        statusString += ', open';
      } else {
        statusString += ', closed';
      }
    }

    statusString += ' job';

    return (
      <span className={statusString.toLowerCase().replace(',', '').replace(' ', '-')}>
        {statusString}
      </span>
    );
  };

  toggleQuickProfile(event, application, candidate) {
    // Allow the user to toggle the checkbox state
    if (event.target.className.includes('select-app-input')) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleQuickProfile(application, candidate);
  }

  getScorecardCount(candidate, application) {
    const universal_scorecards_count = candidate.scorecard_count || 0;
    let application_scorecards_count = application.scorecard_count || 0;
    if (application.district_application?.scorecard_count) {
      application_scorecards_count = application.district_application?.scorecard_count;
    }
    return universal_scorecards_count + application_scorecards_count;
  }

  render() {
    const { candidate } = this.props;

    const applications = this.props.isSchoolUser
      ? candidate.allRelevantApplicationsForSchoolAdmin || []
      : candidate.applications;

    const isLinkedToAuthUser = (auth.getUser().linked_users || []).includes(candidate.id);

    return (
      <div
        className={
          'candidate-application-list-outer ' + (this.props.candidate.expanded ? 'expanded' : '')
        }
      >
        <div
          className={
            'candidate-application-list ' + (this.props.candidate.expanded ? 'expanded' : '')
          }
        >
          {applications.map((application) => {
            const scorecard_count = this.getScorecardCount(candidate, application);
            const isSelected = this.props.selectedApplications.has(application.id);

            return (
              <Row
                key={application.id}
                onClick={(event) => this.toggleQuickProfile(event, application, candidate)}
                showRoleStatus={!this.props.activeCandidatesOnly}
                data-testid={ATSCandidateListTestIds.CANDIDATE_APPLICATION}
              >
                <CheckboxInput
                  className="select-app-input"
                  type="checkbox"
                  checked={isSelected}
                  isDistrictUser={auth.isDistrictUser()}
                  onChange={(e) => {
                    this.props.toggleSelection(application.id, candidate.id);
                  }}
                />
                <span
                  className="candidate-app-list-row-title"
                  data-tip={application.role.title}
                  data-for="role-title"
                  data-place="bottom"
                  data-delay-show="100"
                  data-testid={ATSCandidateListTestIds.CANDIDATE_ROW_JOB_TITLE}
                >
                  {application.role.title}{' '}
                  {this.props.district?.has_subdistricts && application.role.jobboard_title && (
                    <span>{application.role.jobboard_title}</span>
                  )}
                </span>

                <InsightsGridContainer>
                  <FirstColumn>{application.top_candidate && <StarIcon />}</FirstColumn>
                  {/* only show high retention nudge for top candidates */}
                  <SecondColumn>
                    {application.top_candidate && application.high_retention && <RefreshIcon />}
                  </SecondColumn>
                </InsightsGridContainer>

                <span
                  data-testid={ATSCandidateListTestIds.CANDIDATE_APPLICATION_STATUS}
                  className="candidate-status candidate-app-list-status"
                >
                  <span
                    className={`status-circle ${
                      colorMapping[application.new_status.color]
                    } inline-block`}
                  />
                  {application.new_status.label}
                </span>

                {!this.props.activeCandidatesOnly && (
                  <span className="candidate-app-list-row-role-status">
                    {this.getRoleStatus(application)}
                  </span>
                )}

                {!isLinkedToAuthUser && (
                  <>
                    {scorecard_count > 0 ? (
                      <GreenScorecardIcon
                        data-tip={scorecard_count}
                        data-for="scorecard_count"
                        data-place="bottom"
                        data-testid={ATSCandidateListTestIds.CANDIDATE_ROW_SCORECARD_ICON}
                        data-delay-show="100"
                        style={{ width: '18px', height: '18px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ showScorecardModal: true, application: application });
                        }}
                      />
                    ) : (
                      <ScorecardIcon
                        data-testid={ATSCandidateListTestIds.CANDIDATE_ROW_SCORECARD_ICON}
                        style={{ width: '18px', height: '18px' }}
                        onClick={() => {}}
                      />
                    )}
                  </>
                )}

                <RightCaret src={rightCaretGrey} alt="right-caret" />
              </Row>
            );
          })}
        </div>
        {this.state.showScorecardModal && (
          <ScorecardModal
            user={this.props.candidate}
            application={this.state.application}
            show={this.state.showScorecardModal}
            onHide={() => this.setState({ showScorecardModal: false })}
            adminUsers={this.props.adminUsers}
            incrementScorecardCount={this.props.incrementScorecardCount}
          />
        )}
      </div>
    );
  }
}

const Row = styled.div`
  display: grid;
  grid-template-columns: 20px 2fr 1fr ${({ showRoleStatus }) => (showRoleStatus ? '1.5fr ' : '')}1fr 1fr;
  align-items: center;

  cursor: pointer;

  height: 48px;
  min-width: 500px;
  padding-left: 12px;
  font-size: 15px;
  background-color: #fff !important;
  position: relative;
  color: rgba(57, 60, 73, 0.8);
  border-bottom: 1px solid #ddd;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 20px 113px 76px ${({ showRoleStatus }) =>
        showRoleStatus ? '188px ' : ''}350px 1fr;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 20px 168px 98px ${({ showRoleStatus }) =>
        showRoleStatus ? '236px 115px' : '350px'} 1fr;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 20px 185px 105px ${({ showRoleStatus }) =>
        showRoleStatus ? '284px 125px 40px' : '410px'} 1fr;
  }
`;

const RightCaret = styled.img`
  position: absolute;
  right: 8px;
`;

const InsightsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 20px);
  grid-gap: 12px;
  height: 18px;
`;

const FirstColumn = styled.div`
  grid-column: 1 / span 1;
`;

const SecondColumn = styled.div`
  grid-column: 2 / span 1;
`;

// We need to overwrite the browser default margin:
const CheckboxInput = styled.input`
  opacity: ${({ isDistrictUser }) => (isDistrictUser ? '1' : '0')};

  &[type='checkbox'] {
    margin: 0;
  }
`;
