import styled from 'styled-components';
import { layout } from 'styled-system';

import { AltModal } from 'ui-kit';
import LoadingSpinner from 'components/loadingSpinner';

const HellosignTemplateIFrameModal = ({ show, onClose, editMode, isLoading }) => {
  return (
    <AltModal.AltModal isOpen={show} onClose={onClose} large={true}>
      <AltModal.Title>{editMode ? 'Edit form' : 'Add New HelloSign Template'}</AltModal.Title>
      {isLoading ? (
        <AltModal.Body>
          <LoadingSpinner />
        </AltModal.Body>
      ) : (
        <IFrameEntry id="hellosign-iframe-entry" />
      )}
      <Footer>
        <CancelButton onClick={onClose} width={[1, 1, '184px']}>
          Cancel
        </CancelButton>
      </Footer>
    </AltModal.AltModal>
  );
};

export default HellosignTemplateIFrameModal;

const IFrameEntry = styled.div`
  height: 650px;
  grid-column: 1 / span 4;
  grid-row: 3 / span 1;
  min-width: 0;
  margin: 0;
`;

const Footer = styled(AltModal.Actions)`
  display: flex;
  align-items: center;
`;

const CancelButton = styled.button`
  ${layout}

  height: 50px;

  font-weight: 600;
  font-size: 15px;

  border-radius: 3px;

  color: #a6a7ad;
  border: 1px solid #a6a7ad;
  background-color: #fff;
`;
