import { cloneElement, isValidElement, Children, useState, useCallback } from 'react';
import styled from 'styled-components';
import { color, layout, space, typography, shadow } from 'styled-system';

import { useOnClickOutside } from 'hooks';
import { DownCaretIcon } from 'ui-kit/icons';

/**
 * Ghetto dropdown component.
 * TODO: Should use a portal so it stops hiding behind things.
 */
export const Dropdown = ({
  label = 'Select all that apply',
  inputProps,
  labelProps,
  isActive,
  children,
  isRequired = false,
  isMultiSelect = true,
  listProps = {},
  dataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const closeDropdown = useCallback(
    (forceClose = false) => {
      if (forceClose || isMultiSelect) {
        setIsOpen(false);
      }
    },
    [isMultiSelect]
  );
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdown, isOpen);

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { closeDropdown, ...child.props });
    }
    return child;
  });

  return (
    <div ref={closeNodeOnClickOutside}>
      <DropdownWrapper>
        <DropdownInput
          data-testid={dataTestId}
          isOpen={isOpen}
          isActive={isActive}
          {...inputProps}
          onClick={toggleDropdown}
        >
          <DropdownLabel {...labelProps}>
            {label}
            {isRequired && <RequiredAsterisk>*</RequiredAsterisk>}
          </DropdownLabel>
          <StyledDownCaretIcon isActive={isActive} height="0.5em" />
        </DropdownInput>
        {isOpen && <DropdownList {...listProps}>{childrenWithProps}</DropdownList>}
      </DropdownWrapper>
    </div>
  );
};

const DropdownWrapper = styled.div`
  position: relative;
  user-select: none;
`;

// Unpacking props to remove warnings...
// Seems dumb that this would be manual. https://github.com/styled-components/styled-components/issues/439
const DropdownInput = styled(
  ({ isOpen, isActive, isError, minWidth, paddingLeft, paddingRight, boxShadow, ...rest }) => (
    <div {...rest} />
  )
)`
  ${layout}
  ${space}
  ${typography}
  ${color}

  background-color: #ffffff;

  border: 1px solid
    ${(props) =>
      props.isError
        ? 'red'
        : props.isActive
          ? props.theme.colors.primary.alternateLight
          : '#dcdcdc'};
  border-radius: 3px;

  ${(props) => props.isOpen && 'border-bottom-left-radius: 0; border-bottom-right-radius: 0;'}

  display: flex;
  align-items: center;

  ${shadow}
`;

DropdownInput.defaultProps = {
  isActive: false,
  isError: false,
  height: '50px',
  paddingLeft: '1em',
  paddingRight: '1em',
  fontSize: '14px',
};

// Unpacking props to remove warnings...
// Seems dumb that this would be manual. https://github.com/styled-components/styled-components/issues/439
const StyledDownCaretIcon = styled(({ isActive, ...rest }) => <DownCaretIcon {...rest} />)`
  fill: ${(props) => (props.isActive ? props.theme.colors.primary.alternateLight : '#C1C1C1')};
`;

StyledDownCaretIcon.defaultProps = {
  isActive: false,
};

const DropdownLabel = styled.div`
  ${color}

  flex: 1;
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 4px;
  font-size: 16px;
`;

const DropdownList = styled.ul`
  ${layout}

  position: absolute;

  font-size: 14px;

  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;

  border: 1px solid #dcdcdc;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0954484);

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  list-style-type: none;
  z-index: ${(props) => props.zIndex};
`;

DropdownList.defaultProps = {
  zIndex: 1,
  width: '100%',
  maxHeight: '315px',
};

export const ListItem = ({ children, ...rest }) => {
  return <li {...rest}>{children}</li>;
};
