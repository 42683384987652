import { BaseNimbleAPI } from './baseAPI';

class LocationPreferencesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/' };
  }

  async getMapUrl(data) {
    try {
      const response = await this.post('work-preferences-map/', data);
      return [response.status, response.data];
    } catch (error) {
      return [error.response.status, error.response.data];
    }
  }

  async updatePreferences(data, id) {
    try {
      const response = await this.patch('preferences/' + id + '/', data);
      return [response.status, response.data];
    } catch (error) {
      return [error.response.status, error.response.data];
    }
  }
}

export default new LocationPreferencesAPI();
