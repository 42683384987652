import { Component } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import greenCheckMark from 'assets/icons/ReferenceIcons/green_check.svg';
import remindIcon from 'assets/icons/icon_remind.svg';
import { ATSCandidateScorecardDataTestIds } from 'data-testids/ATS';

export default class ScorecardRequests extends Component {
  static propTypes = {
    onRequest: PropTypes.func.isRequired,
    requests: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  state = {
    email: '',
    warn: false,
  };

  render() {
    return (
      <Container>
        <h4
          data-testid={ATSCandidateScorecardDataTestIds.PENDING_REQUESTS_HEADER}
          className="mb1"
        >{`${this.props.requests.length} Pending Request${
          this.props.requests.length === 1 ? '' : 's'
        }`}</h4>
        <ul className="list-unstyled">
          {this.props.requests.map((request) => {
            return (
              <li
                data-testid={ATSCandidateScorecardDataTestIds.PENDING_REQUEST_ROW}
                className="mbhalf"
                key={request.id}
              >
                <img src={greenCheckMark} alt="" />
                &nbsp;&nbsp;Request for {request.email} created on{' '}
                {new Date(request.created).toLocaleString()}{' '}
                <span className="hover-icon-set">
                  <span
                    onClick={() =>
                      this.props.onRequest({
                        recipients: [{ email: request.email }],
                        custom_scorecard_id: request.custom_scorecard_id,
                        custom_message: null,
                        is_reminder: true,
                      })
                    }
                    data-tip
                    data-for="remind"
                    data-place="bottom"
                    data-delay-show="100"
                  >
                    <img src={remindIcon} alt="edit" />
                    <ReactTooltip id="remind" effect="solid">
                      <span>Remind</span>
                    </ReactTooltip>
                  </span>
                </span>
              </li>
            );
          })}
        </ul>
      </Container>
    );
  }
}

const Container = styled.div`
  margin-top: 2rem;
`;
