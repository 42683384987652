/*
 * Let's follow this strategy to name our enums:
 * 1. System name as prefix: ATS, Connect
 * 2. Next: Page name, e.g.: Home, SignIn, JobsList
 * 3. Next: DataTestIds
 *
 * For each attribute name:
 * 1. Next: Element Identifier, e.g.: submit-, email-, password-
 * 2. Next: Element type, e.g.: button, input, text, link
 *
 * For the value, we must follow this rule:
 * data-testIds should follow the pattern area-noun-component (ex. connect-express-interest-primary-button)
 * More context here: https://nimble-ed.slack.com/archives/C06DZ7FF3RQ/p1721922036641099
 */

export enum ATSCandidateBasicInfoDataTestIds {
  ADDRESS_LINE1_INPUT = 'ats-candidate-basic-info-address-line1-input',
  CELL_PHONE_INPUT = 'ats-candidate-basic-info-cell-phone-input',
  CITY_INPUT = 'ats-candidate-basic-info-city-input',
  COMPLETE_PROFILE_TITLE = 'ats-candidate-basic-info-complete-profile-title',
  ETHNICITY_SELECT = 'ats-candidate-basic-info-ethnicity-select',
  GENDER_SELECT = 'ats-candidate-basic-info-gender-select',
  SAVE_AND_NEXT_BUTTON = 'ats-candidate-basic-info-save-and-next-button',
  UPLOAD_RESUME_INPUT = 'ats-candidate-basic-info-upload-resume-input',
  ZIP_INPUT = 'ats-candidate-basic-info-zip-input',
}

export enum ATSCandidateCredentialsDataTestIds {
  ADD_CREDENTIALS_BUTTON = 'ats-candidate-credentials-add-credentials-button',
  CREDENTIAL_TYPE_SELECT = 'ats-candidate-credentials-credentials-type-select',
  CREDENTIAL_TYPE_SELECT_DROPDOWN_OPTION = 'ats-candidate-credentials-credentials-type-select-dropdown-option',
  CREDENTIAL_STATE_SELECT = 'ats-candidate-credentials-credential-state-select',
  CREDENTIAL_CATEGORY_SELECT = 'ats-candidate-credentials-credential-category-select',
  SAVE_ENTRY_BUTTON = 'ats-candidate-credentials-save-entry-button',
}

export enum ATSCandidateExamsDataTestIds {
  ADD_EXAMS_BUTTON = 'ats-candidate-exams-add-exam-button',
  EXAM_SELECT = 'ats-candidate-exams-exam-select',
  EXAM_SELECT_DROPDOWN_OPTION = 'ats-candidate-exams-exam-select-dropdown-option',
  SAVE_ENTRY_BUTTON = 'ats-candidate-exams-save-entry-button',
}

export enum ATSCandidateExperienceDataTestIds {
  ADD_WORK_EXPERIENCE_BUTTON = 'ats-candidate-experience-add-work-experience-button',
  ADVANCED_DEGREE_YES_INPUT = 'ats-candidate-experience-advanced-degree-yes-input',
  EMPLOYER_INPUT = 'ats-candidate-experience-employer-input',
  END_MONTH_DATETIME = 'ats-candidate-experience-end-month-datetime',
  END_YEAR_DATETIME = 'ats-candidate-experience-end-year-datetime',
  EXPERIENCE_TYPE_DROPDOWN = 'ats-candidate-experience-experience-type-dropdown',
  HEAR_ABOUT_US_SELECT = 'ats-candidate-experience-hear-about-us-select',
  JOB_DESCRIPTION_INPUT = 'ats-candidate-experience-job-description-input',
  JOB_TITLE_INPUT = 'ats-candidate-experience-job-title-input',
  REASON_FOR_LEAVING_INPUT = 'ats-candidate-experience-reason-for-leaving-input',
  SAVE_AND_COMPLETE_BUTTON = 'ats-candidate-experience-save-and-complete-button',
  SAVE_AND_NEXT_STEP_BUTTON = 'ats-candidate-experience-save-and-next-step-button',
  SAVE_ENTRY_BUTTON = 'ats-candidate-experience-save-entry-button',
  START_MONTH_DATETIME = 'ats-candidate-experience-start-month-datetime',
  START_YEAR_DATETIME = 'ats-candidate-experience-start-year-datetime',
  EDIT_EXPERIENCE_BUTTON = 'ats-candidate-experience-edit-experience-button',
}

export enum ATSCandidateExperienceModalDataTestIds {
  IGNORE_AND_PROCEED_BUTTON = 'ats-candidate-experience-modal-ignore-and-proceed-button',
  MODAL_CONTAINER = 'ats-candidate-experience-modal-container',
}

export enum ATSCandidateEducationDataTestIds {
  ADD_EDUCATION_BUTTON = 'ats-candidate-education-add-education-button',
  DEGREE_SELECT_DROPDOWN = 'ats-candidate-education-degree-select-dropdown',
  DEGREE_SELECT_DROPDOWN_OPTION = 'ats-candidate-education-degree-select-dropdown-option',
  SAVE_ENTRY_BUTTON = 'ats-candidate-education-save-entry-button',
}

export enum ATSCandidateHomeDataTestIds {
  PROFILE_UPDATED_TITLE = 'ats-candidate-home-profile-updated-title',
  REMINDER_HEADER_TITLE = 'ats-candidate-home-reminder-header-title',
  EDIT_APPLICATION_BUTTON = 'ats-candidate-home-edit-application-button',
  VIEW_MORE_ROLES_BUTTON = 'ats-candidate-home-view-more-roles-button',
}

export enum ATSCandidateLanguagesDataTestIds {
  ADD_LANGUAGES_BUTTON = 'ats-candidate-languages-add-languages-button',
  LANGUAGE_SELECT = 'ats-candidate-languages-language-select',
  LANGUAGE_SELECT_DROPDOWN_OPTION = 'ats-candidate-languages-language-select-dropdown-option',
  PROFICIENCY_SELECT = 'ats-candidate-languages-proficiency-select',
  PROFICIENCY_SELECT_DROPDOWN_OPTION = 'ats-candidate-languages-proficiency-select-dropdown-option',
  SAVE_ENTRY_BUTTON = 'ats-candidate-languages-save-entry-button',
  EDIT_LANGUAGES_BUTTON = 'ats-candidate-languages-edit-entry-button',
}

export enum ATSCandidatePreferencesDataTestIds {
  CATEGORIES_OF_INTEREST_MULTISELECT = 'ats-candidate-preferences-categories-of-interest-multiselect',
  COMPLETE_PROFILE_TEXT = 'ats-candidate-preferences-complete-profile-text',
  MAP_IMAGE = 'ats-candidate-preferences-map-image',
  SAVE_AND_NEXT_BUTTON = 'ats-candidate-preferences-save-and-next-button',
  STATE_PREFERENCES_MULTISELECT = 'ats-candidate-preferences-state-preferences-multiselect',
  TITLE_TEXT = 'ats-candidate-preferences-title-text',
}

export enum ATSCandidateReferencesDataTestIds {
  ADD_REFERENCES_BUTTON = 'ats-candidate-references-add-reference-button',
  FIRST_NAME_INPUT = 'ats-candidate-references-first-name-input',
  LAST_NAME_INPUT = 'ats-candidate-references-last-name-input',
  EMAIL_INPUT = 'ats-candidate-references-email-input',
  SAVE_ENTRY_BUTTON = 'ats-candidate-references-save-entry-button',
  PRINT = 'ats-candidate-references-print-button',
}

export enum ATSCustomCandidateFieldsDataTestIds {
  CONTAINER = 'ats-custom-candidate-fields-container',
  HEADING = 'ats-custom-candidate-fields-heading',
  FIELD_TITLE = 'ats-custom-candidate-fields-field-title',
  DOB_TITLE = 'ats-custom-candidate-fields-dob-title',
  DOB_SECTION = 'ats-custom-candidate-fields-dob-section',
  LIST_CONTAINER = 'ats-custom-candidate-fields-list-container',
  SSN_SECTION = 'ats-custom-candidate-fields-ssn-section',
  SSN_HEADER = 'ats-custom-candidate-fields-ssn-header',
  SSN_INPUT = 'ats-custom-candidate-fields-ssn-input',
  VIEW_SSN_BUTTON = 'ats-custom-candidate-fields-view-ssn-button',
  HIDE_SSN_BUTTON = 'ats-custom-candidate-fields-hide-ssn-button',
  CURRENCY_INPUT = 'ats-custom-candidate-fields-currency-input',
  TEXT_INPUT = 'ats-custom-candidate-fields-text-input',
  LINK_INPUT = 'ats-custom-candidate-fields-link-input',
}

export enum ATSCandidateListTestIds {
  CANDIDATE_ROW = 'ats-candidate-row',
  CANDIDATE_ROW_FULL_NAME = 'ats-candidate-row-full-name',
  CANDIDATE_ROW_SCORECARD_ICON = 'ats-candidate-row-scorecard-icon',
  CANDIDATE_ROW_NOTE_ICON = 'ats-candidate-row-note-icon',
  CANDIDATE_ROW_DOCUMENT_ICON = 'ats-candidate-row-document-icon',
  CANDIDATE_ROW_JOB_TITLE = 'ats-candidate-row-job-title',
  CANDIDATE_APPLICATION = 'ats-candidate-row-application',
  DAYS_AGO_COUNT = 'ats-candidate-days-ago-count',
  CANDIDATE_APPLICATION_STATUS = 'ats-candidate-application-status',
  STATUS_SELECT = 'ats-candidate-status-select',
}

export enum ATSCandidateListBulkEmail {
  BULK_EMAIL_BUTTON = 'ats-candidate-list-bulk-email-buttonm',
  BULK_STATUS_CHANGE_BUTTON = 'ats-candidate-list-bulk-status-change-button',
  BULK_EMAIL_COUNT = 'ats-candidate-list-bulk-email-count',
  SELECT_ALL_CANDIDATES_BUTTON = 'ats-candidate-list-select-all-button',
  STATUS_CHANGE_OPTIONS = 'ats-candidate-list-status-change-options',
  INDIVIDUAL_STATUS_CHANGE_OPTION = 'ats-candidate-list-status-change-individual-option',
  BULK_EMAIL_SEND_NOW_BUTTON = 'ats-candidate-list-bulk-email-send-now-button',
  SELECT_TEMPLATE_SELECT = 'ats-candidate-list-bulk-email-select-template-select',
  BULK_EMAIL_SUBJECT = 'ats-candidate-list-bulk-email-subject',
}

export enum ATSCandidateTaskListTestIds {
  DELETE_BUTTON = 'ats-task-list-delete-button',
  FULL_PROFILE_TASK_TITLE = 'ats-full-profile-task-list-task-title',
  FULL_PROFILE_TASK_DUE_DATE = 'ats-full-profile-task-list-task-due-date',
  FULL_PROFILE_COMPLETE_ICON = 'ats-full-profile-task-list-complete-icon',
  FULL_PROFILE_HEADER = 'ats-full-profile-task-list-header',
  FULL_PROFILE_TASK_ROW = 'ats-full-profile-task-list-row',
  QUICKVIEW_HEADER = 'ats-quickview-task-list-header',
  QUICKVIEW_TASK_TITLE = 'ats-quickview-task-list-task-title',
  QUICKVIEW_TASK_DUE_DATE = 'ats-quickview-task-list-task-due-date',
  QUICKVIEW_COMPLETE_ICON = 'ats-quickview-task-list-complete-icon',
  QUICKVIEW_TASK_ROW = 'ats-quickview-task-list-row',
}

export enum ATSCandidatesListFilterTestIds {
  CANDIDATE_COUNT = 'ats-candidates-list-candidate-count',
  CANDIDATES_LIST_LANGUAGE_CLEAR_ALL = 'ats-candidates-list-language-clear-all',
  CANDIDATES_LIST_LANGUAGE_FILTER = 'ats-candidates-list-candidate-language-filter',
  CANDIDATES_LIST_LANGUAGE_OPTION = 'ats-candidates-list-language-option',
  CANDIDATE_PREFERENCES_EARLY_CHILDHOOD_GRADE_OPTION = 'ats-candidates-list-preferences-grades-early-childhood-option',
  CANDIDATE_PREFERENCES_GRADES_CLEAR_ALL_BUTTON = 'ats-candidates-list-preferences-grades-clear-all-button',
  CANDIDATE_PREFERENCES_GRADES_FILTER = 'ats-candidates-list-candidate-preferences-grades',
  CANDIDATE_PREFERENCES_SCHOOL_TYPES_FILTER = 'ats-candidates-list-candidate-preferences-school-types',
  CANDIDATE_PREFERENCES_SECTION = 'ats-candidates-list-candidate-preferences-section',
  FILTER = 'ats-candidates-list-filter-header',
  FILTER_OPTION = 'ats-candidates-list-filter-option',
  SEARCH_BAR = 'ats-candidates-list-search-bar',
  SEARCH_BUTTON = 'ats-candidates-list-search-button',
  SORT_BY_NAME_BUTTON = 'ats-candidates-list-sort-by-name-button',
  STATUS_CLEAR_ALL_BUTTON = 'ats-candidates-list-status-clear-all-button',
}

export enum ATSConnectImportCandidateFormTestIds {
  JOB_COMBOBOX = 'ats-connect-import-candidate-job-combobox',
}

export enum ATSDataFFTDataTestIds {
  APPLICATION_FIELD_SELECT = 'ats-data-fft-application-field-select',
  CANDIDATE_PROFILE_SELECT = 'ats-data-fft-candidate-profile-select',
  COLUMN_NAME_INPUT = 'ats-data-fft-column-name-input',
  COLUMN_ROW = 'ats-data-fft-column-row',
  DATA_FORMAT_SELECT = 'ats-data-fft-data-format-select',
  DATA_SOURCE_SELECT = 'ats-data-fft-data-source-select',
  DELETE_ROW_BUTTON = 'ats-data-fft-delete-row-button',
  DOWNLOAD_TEST_BUTTON = 'ats-data-fft-download-test-button',
  FIELD_SELECT = 'ats-data-fft-field-select',
  NEW_COLUMN_BUTTON = 'ats-data-fft-new-column-button',
  NEW_DATA_TRANSFER_BUTTON = 'ats-data-fft-new-data-transfer-button',
  SAVE_BUTTON = 'ats-data-fft-save-button',
  DATA_MAPPING_SAVE = 'ats-data-fft-data-mapping-save-button',
}

export enum ATSDistrictHomeDataTestIds {
  GREETING_TEXT = 'ats-home-greeting-text',
  HIGH_RETENTION = 'ats-home-high-retention',
  HIGHLY_SKILLS_TEXT = 'ats-home-highly-skills-text',
  TOP_CANDIDATE_CONTAINER = 'ats-home-top-candidate-container',
  TOP_CANDIDATE_JOB_TEXT = 'ats-home-top-candidate-job-text',
  TOP_CANDIDATE_NAME_TEXT = 'ats-home-top-candidate-name-text',
}

export enum ATSDistrictJobListAlertBannerDataTestId {
  ALERT_BANNER = 'ats-district-job-list-alert-banner',
}

export enum ATSDistrictJobListDataTestIds {
  JOB_ROW = 'ats-district-job-board-job-row',
  JOB_TITLE = 'ats-district-job-board-job-row-job-title',
  COLUMN_HEADER = 'ats-district-job-board-job-list-column-header',
  COLUMN_NAME = 'ats-district-job-board-job-list-column-header-column-name-text',
  INTRO_TEXT = 'ats-district-job-board-job-list-intro-text',
  REQUISITION_ROW = 'ats-district-job-board-job-list-requisition-row',
  REQUISITION_JOB_TITLE = 'ats-district-job-board-job-list-requisition-title',
}

export enum ATSDistrictJobsListFiltersDataTestIds {
  CATEGORY_FILTER = 'ats-district-job-list-category-filter-dropdown',
  EMPLOYMENT_TYPE_FILTER = 'ats-district-job-list-employment-type-filter-dropdown',
  GRADE_LEVEL_FILTER = 'ats-district-job-list-grade-level-filter-dropdown',
  SCHOOL_FILTER = 'ats-district-job-list-school-filter-dropdown',
  SEARCH_INPUT = 'ats-district-job-list-search-input',
  SEARCH_SUBMIT_BUTTON = 'ats-district-job-list-filter-search-submit-button',
}

export enum ATSDistrictJobsListCategoryFilterDataTestIds {
  ALL_CATEGORIES_OPTION = 'ats-district-job-list-category-filter-all-categories-option',
  CATEGORY_OPTION = 'ats-district-job-list-category-filter-categories-option',
  SUBCATEGORY_OPTION = 'ats-district-job-list-category-filter-subcategory-option',
}

export enum ATSDistrictJobsListGradeLevelFilterDataTestIds {
  ALL_GRADES_OPTION = 'ats-district-job-list-grade-level-filter-all-grades-option',
  GRADE_OPTION = 'ats-district-job-list-category-filter-grade-option',
}

export enum ATSJobClosureNotificationTestIds {
  ADD_NOTIFICATION_BUTTON = 'ats-job-closure-add-notification-button',
  ALERT_BANNER = 'ats-job-closure-alert-banner',
  APPLICATION_STATUS_ID_ERROR_TEXT = 'ats-job-closure-modal-application-status-id-error',
  DELETE_NOTIFICATION_BUTTON = 'ats-job-closure-delete-notifications-button',
  EDIT_NOTIFICATION_BUTTON = 'ats-job-closure-edit-notifications-button',
  HEADING = 'ats-job-closure-heading',
  JOB_CLOSURE_SECTION = 'ats-job-closure-notifications-section',
  MODAL = 'ats-job-closure-modal',
  MODAL_PRIMARY_BUTTON = 'ats-job-closure-modal-primary-button',
  MODAL_TITLE = 'ats-job-closure-modal-title',
  THRESHOLD_ERROR_TEXT = 'ats-job-closure-modal-threshold-error',
  SUBSCRIBERS_ERROR_TEXT = 'ats-job-closure-modal-subscribers-error',
}

export enum ATSJobViewDataTestIds {
  APPLY_BUTTON = 'ats-job-view-apply-button',
  DETAILS_CONTAINER = 'ats-job-view-details-container',
  TITLE_TEXT = 'ats-job-view-title-text',
  POST_BUTTON = 'ats-job-view-post-button',
  SHARE_BUTTON = 'ats-job-view-share-button',
  EDIT_BUTTON = 'ats-job-view-edit-button',
}
export enum ATSJobEditTestIds {
  SAVE_PREVIEW_BUTTON = 'ats-job-edit-save-preview-button',
  LOCATIONS_MODAL_OPEN = 'ats-job-edit-locations-modal-open-button',
  LOCATIONS_MODAL_SAVE = 'ats-job-edit-locations-modal-save-button',
  SCHOOL_VACANCIES_RADIO = 'ats-job-edit-school-vacancies-radio',
  DISTRICT_VACANCIES_RADIO = 'ats-job-edit-district-vacancies-radio',
  INCREMENT_VACANCY_BUTTON = 'ats-job-edit-increment-vacancy-button',
  DECREMENT_VACANCY_BUTTON = 'ats-job-edit-decrement-vacancy-button',
  AUTO_TAGS_SAVE_BUTTON = 'ats-job-edit-auto-tags-modal-save-button',
  QUESTION_SAVE_BUTTON = 'ats-job-edit-question-modal-save-button',
  QUESTION_SET_SAVE_BUTTON = 'ats-job-edit-question-set-modal-save-button',
  SUBMIT_BUTTON = 'ats-job-edit-submit-button',
  TITLE_INPUT = 'ats-job-edit-title-input',
}

export enum ATSJobsListDataTestIds {
  COUNT_TEXT = 'ats-jobs-list-count-text',
  FILTER_CONTAINER = 'ats-jobs-list-filter-container',
  ROW_CONTAINER = 'ats-jobs-list-row-container',
  ROW_DATE_TEXT = 'ats-jobs-list-row-date-text',
  ROW_TITLE_TEXT = 'ats-jobs-list-row-title-text',
  SEARCH_INPUT = 'ats-jobs-list-search-input',
  CATEGORY_DROPDOWN = 'ats-jobs-list-categories-dropdown',
  CATEGORY_CLEARALL = 'ats-jobs-list-categories-clearall',
  CATEGORY_OPTION = 'ats-jobs-list-categories-option',
  GRADES_DROPDOWN = 'ats-jobs-list-grades-dropdown',
  GRADES_CLEARALL = 'ats-jobs-list-grades-clearall',
  GRADES_ALL_ELEMENTARY = 'ats-jobs-list-grades-all-elementary',
  NEW_JOB_BUTTON = 'ats-jobs-list-new-job-button',
  NEW_TEMPLATE_BUTTON = 'ats-jobs-list-new-template-button',
  SCHOOL_NAME = 'ats-jobs-list-school-name',
  STYLED_LIST_DROPDOWN = 'ats-jobs-list-styled-list-dropdown',
  STYLED_LIST_CLEARALL = 'ats-jobs-list-styled-list-clearall',
  STYLED_LIST_OPTION = 'ats-jobs-list-styled-list-option',
  STYLED_LIST_HIRING_SEASON = 'hiring-season',
  STYLED_LIST_INTERNAL_EXTERNAL = 'internal-external',
  SCHOOLS_LIST = 'ats-jobs-list-schools-list-cell',
}

export enum ATSJobsListQuickActionTestIds {
  ARCHIVE_JOB = 'ats-jobs-list-quick-action-archive-job',
  CLOSE_JOB = 'ats-jobs-list-quick-action-close-job',
  DUPLICATE_JOB = 'ats-jobs-list-quick-action-duplicate-job',
  EDIT_JOB = 'ats-jobs-list-quick-action-edit-job',
  RETURN_JOB = 'ats-jobs-list-quick-action-return-job',
  MERGE_JOB = 'ats-jobs-list-quick-action-merge-job',
  SHARE_JOB = 'ats-jobs-list-quick-action-share-job',
  VIEW_JOB = 'ats-jobs-list-quick-action-view-job',
  DELETE_TEMPLATE = 'ats-jobs-list-quick-action-delete-template',
  EDIT_TEMPLATE = 'ats-jobs-list-quick-action-edit-template',
  JOB_FROM_TEMPLATE = 'ats-jobs-list-quick-action-job-from-template',
  TEMPLATE_FROM_JOB = 'ats-jobs-list-quick-action-template-from-job',
}

export enum ATSNewJobTemplateModalTestIds {
  INPUT = 'ats-new-job-template-modal-input',
  COMBO_BOX = 'ats-new-job-template-modal-combo-box',
  CANCEL_BUTTON = 'ats-new-job-template-modal-cancel-button',
  CREATE_JOB_BUTTON = 'ats-new-job-template-modal-create-job-button',
  CONVERT_JOB_BUTTON = 'ats-new-job-template-modal-convert-job-button',
  CREATE_TEMPLATE_BUTTON = 'ats-new-job-template-modal-create-template-button',
}
export enum ATSNewMergeJobModalTestIds {
  COMBO_BOX = 'ats-merge-job-modal-combo-box',
}

export enum ATSNavbarDataTestIds {
  CANDIDATES_BUTTON = 'ats-navbar-candidates-button',
  CANDIDATES_BUTTON_BY_POSTING = 'ats-navbar-candidates-button-by-posting',
  CANDIDATES_BUTTON_VIEW_ALL = 'ats-navbar-candidates-button-view-all',
  DATA_BUTTON = 'ats-navbar-data-button',
  JOBS_DROPDOWN = 'ats-navbar-jobs-dropdown',
  JOBS_LINK = 'ats-navbar-jobs-link',
  PROSPECTS_BUTTON = 'ats-navbar-prospects-button',
  TEMPLATES_LINK = 'ats-navbar-templates-link',
  PIPELINE_BUTTON = 'ats-navbar-pipeline-button',
}

export enum ATSQuickProfileApplicationSection {
  EXAMS_EXPANDER = 'ats-quick-profile-exams-expander',
}

export enum ATSReviewApplicationTestIds {
  RESUME_FILE_TITLE = 'ats-experience-resume-file-title',
  SUBMIT_APPLICATION_BUTTON = 'ats-experience-submit-application-button',
}

export enum ATSSignInDataTestIds {
  EMAIL_INPUT = 'ats-sign-in-email-input',
  PASSWORD_INPUT = 'ats-sign-in-password-input',
  SUBMIT_BUTTON = 'ats-sign-in-submit-button',
}

export enum ATSSignUpDataTestIds {
  ACTIVATE_BUTTON = 'ats-sign-up-activate-button',
  EMAIL_CONFIRM_INPUT = 'ats-sign-up-email-confirm-input',
  EMAIL_INPUT = 'ats-sign-up-email-input',
  ERROR_TEXT = 'ats-sign-up-error-text',
  FIRST_NAME_INPUT = 'ats-sign-up-first-name-input',
  GOOGLE_SIGN_UP_BUTTON = 'ats-sign-up-google-sign-up-button',
  LAST_NAME_INPUT = 'ats-sign-up-last-name-input',
  PASSWORD_CONFIRM_INPUT = 'ats-sign-up-password-confirm-input',
  PASSWORD_INPUT = 'ats-sign-up-password-input',
  SIGN_UP_CONTAINER = 'ats-sign-up-container',
  SUBMIT_BUTTON = 'ats-sign-up-submit-button',
}

export enum ATSStateJobBoardAlertsModalDataTestIds {
  CATEGORY_DROPDOWN = 'ats-state-job-board-alerts-modal-category-dropdown',
  ORGANIZATION_DROPDOWN = 'ats-state-job-board-alerts-modal-organization-dropdown',
}

export enum ATSStateJobBoardFiltersDataTestIds {
  CATEGORY_FILTER = 'ats-state-job-board-category-filter-dropdown',
  EMPLOYMENT_TYPE_FILTER = 'ats-state-job-board-employment-type-filter-dropdown',
  GRADE_LEVEL_FILTER = 'ats-state-job-board-grade-level-filter-dropdown',
  ORGANIZATION_FILTER = 'ats-state-job-board-organization-filter-dropdown',
  SCHOOL_FILTER = 'ats-state-job-board-school-filter-dropdown',
  SEARCH_INPUT = 'ats-state-job-board-search-input',
  START_DATE_FILTER = 'ats-state-job-board-start-date-filter-dropdown',
  LOCATION_FILTER = 'ats-state-job-board-location-filter-input',
  DISTANCE_FILTER = 'ats-state-job-board-location-filter-input',
  LOCATION_DISTANCE_KEYWORD_SUBMIT_BUTTON = 'ats-state-job-board-location-distance-keyword-submit-button',
  SEARCH_SUBMIT_BUTTON = 'ats-state-job-board-header-search-submit-button',
}

export enum ATSStateJobBoardHeaderDataTestIds {
  JOB_COUNT = 'ats-state-job-board-header-job-count-text',
  SEARCH_INPUT = 'ats-state-job-board-header-search-input',
  SEARCH_SUBMIT_BUTTON = 'ats-state-job-board-header-search-submit-button',
}

export enum ATSStateJobBoardJobListDataTestIds {
  JOB_ROW = 'ats-state-job-board-job-row',
  JOB_TITLE = 'ats-state-job-board-job-row-job-title',
}

export enum ATSStateJobBoardTalentModalDataTestIds {
  NO_THANKS_BUTTON = 'ats-state-job-board-talent-modal-no-thanks-button',
}

export enum ATSTemplatesListDataTestIds {
  SEARCH_INPUT = 'ats-templates-list-search-input',
  ROW_TITLE_TEXT = 'ats-templates-list-row-title-text',
}

export enum ATSVacancyDashboardDataTestIds {
  CATEGORIES_MULTISELECT = 'ats-vacancy-dashboard-categories-multiselect',
  CLEAR_ALL_BUTTON = 'ats-vacancy-dashboard-clear-all-button',
  DOWNLOAD_RAW_DATA_BUTTON = 'ats-vacancy-dashboard-download-raw-data-button',
  DROPDOWN_CLEAR_BUTTON = 'ats-vacancy-dashboard-dropdown-clear-button',
  DROPDOWN_SELECT_ALL_BUTTON = 'ats-vacancy-dashboard-dropdown-select-all-button',
  HIRING_SEASON_MULTISELECT = 'ats-vacancy-dashboard-hiring-season-multiselect',
  JOB_POSTINGS_MULTISELECT = 'ats-vacancy-dashboard-job-postings-multiselect',
  SCHOOLS_MULTISELECT = 'ats-vacancy-dashboard-schools-multiselect',
  VACANCY_ROW = 'ats-vacancy-dashboard-vacancy-row',
  VACANCY_ROW_CANDIDATE_TITLE = 'ats-vacancy-dashboard-vacancy-candidate-title',
  VACANCY_ROW_JOB_TITLE = 'ats-vacancy-dashboard-vacancy-row-title',
}

export enum ATSPipelineMetricsDashboardDataTestIds {
  APPLICANT_VOLUME_GRAPH = 'ats-pipeline-metrics-dashboard-applicant-volume-graph',
}

export enum ATSCandidateQuickviewTestIds {
  DISTRICT_APPLICATION_QUICKVIEW = 'ats-candidate-list-district-application-quickview',
  DISTRICT_APPLICATION_QUICKVIEW_CANDIDATE_NAME = 'ats-candidate-list-district-application-quickview-candidate-name',
  SCHOOL_TOOLTIP_TEXT_ROW = 'ats-candidate-list-school-tooltip-text-row',
  SCHOOL_VISIBILITY_COUNT = 'ats-candidate-list-school-visibility-count',
}

export enum ATSInternalCandidateNoteDataTestIds {
  DELETE_BUTTON = 'ats-internal-candidate-note-delete-button',
  MENTIONS_INPUT = 'ats-internal-candidate-note-mentions-input',
  MENTION = 'ats-internal-candidate-note-mention',
  ADD_NOTE_BUTTON = 'ats-internal-candidate-note-add-note-button',
  EXISTING_NOTE_ROW = 'ats-internal-candidate-note-existing-note-row',
  EXISTING_NOTE_BULLET_POINT = 'ats-internal-candidate-note-existing-note-bullet-point',
  EXISTING_NOTE_TEXT = 'ats-internal-candidate-note-existing-note-text',
  EXISTING_NOTE_SIGNATURE = 'ats-internal-candidate-note-existing-note-signature',
  MENTION_USER_SUGGESTION = 'ats-internal-candidate-note-mention-user-suggestion',
  FULL_PROFILE_HEADER = 'ats-internal-candidate-note-district-application-header',
  DISTRICT_APPLICATION_QUICKVIEW_HEADER = 'ats-internal-candidate-note-district-application-quickview-header',
}

export enum ATSBottleneckNotifications {
  BOTTLENECK_HEADER = 'ats-bottleneck-notifications-header',
  BOTTLENECK_LIST = 'ats-bottleneck-notifications-list',
  BOTTLENECK_ROW = 'ats-bottleneck-notifications-row',
}

export enum ATSCandidateScorecardDataTestIds {
  FULL_PROFILE_ADD_VIEW_BUTTON = 'ats-candidate-full-profile-add-scorecard-button',
  QUICKVIEW_ADD_VIEW_BUTTON = 'ats-candidate-quickview-add-scorecard-button',
  MODAL_HEADER = 'ats-candidate-scorecard-modal-header',
  LIST = 'ats-candidate-scorecard-list',
  LIST_FOOTER = 'ats-candidate-scorecard-list-footer',
  LIST_CREATE_BUTTON = 'ats-candidate-scorecard-list-create-button',
  LIST_REQUEST_BUTTON = 'ats-candidate-scorecard-list-request-button',
  LIST_SECTION_HEADER = 'ats-scorecard-list-section-header',
  EDITOR = 'ats-candidate-scorecard-editor',
  EDITOR_CREATE_HEADER_TEXT = 'ats-candidate-scorecard-editor-create-header-text',
  EDITOR_DROPDOWN = 'ats-candidate-scorecard-editor-dropdown',
  SUBMIT_BUTTON = 'ats-candidate-scorecard-submit-button',
  SUBMIT_ERROR_MESSAGE = 'ats-candidate-scorecard-submit-error-message',
  TITLE_TEXT = 'ats-scorecard-editor-title-text',
  TEXT_ANSWER_MENTIONS_INPUT = 'ats-scorecard-create-text-answer-mentions-input',
  RATING_ANSWER_INPUT = 'ats-scorecard-create-rating-answer-input',
  RATING_ANSWER_SCALE = 'ats-scorecard-create-rating-answer-scale',
  MULTIPLE_CHOICE_ANSWER_OPTION = 'ats-scorecard-multiple-choice-answer-option',
  FINAL_RECOMMENDATION_ANSWER_OPTION = 'ats-scorecard-final-recommendation-answer-option',
  FINAL_RECOMMENDATION_ANSWER_SUBTEXT = 'ats-scorecard-final-recommendation-answer-subtext',
  SCORECARD_EDITOR_QUESTION_TEXT = 'ats-scorecard-editor-question-text',
  CUMULATIVE_SCORE = 'ats-scorecard-editor-cumulative-score-display',
  EXISTING_CARD_SUBMITTED_BY_TEXT = 'ats-scorecard-existing-card-submitted-by-text',
  EXISTING_CARD = 'ats-scorecard-existing-card',
  EXISTING_CARD_TITLE = 'ats-scorecard-existing-card-title',
  REQUEST_MODAL = 'ats-scorecard-request-modal',
  REQUEST_MODAL_SELECT_CARD_HEADER_TEXT = 'ats-scorecard-request-modal-select-card-header-text',
  REQUEST_MODAL_SCORECARD_DROPDOWN = 'ats-scorecard-request-modal-scorecard-dropdown',
  REQUEST_MODAL_RECIPIENTS_DROPDOWN = 'ats-scorecard-request-modal-recipients-dropdown',
  REQUEST_MODAL_ADD_RECIPIENT_BUTTON = 'ats-scorecard-request-modal-add-recipient-button',
  REQUEST_MODAL_DELETE_RECIPIENT_BUTTON = 'ats-scorecard-request-modal-delete-recipient-button',
  REQUEST_MODAL_RECIPIENT_HEADER = 'ats-scorecard-request-modal-select-recipient-header-text',
  REQUEST_MODAL_RECIPIENT_SUBTEXT = 'ats-scorecard-request-modal-select-recipient-subtext',
  REQUEST_MODAL_ADD_NOTE_HEADER = 'ats-scorecard-request-modal-add-note-header-text',
  REQUEST_MODAL_ADD_NOTE_TEXTAREA = 'ats-scorecard-request-modal-add-note-textarea',
  REQUEST_MODAL_ADD_NOTE_SUBTEXT = 'ats-scorecard-request-modal-add-note-subtext',
  REQUEST_MODAL_ERROR_TEXT = 'ats-scorecard-request-modal-error-text',
  REQUEST_MODAL_SAVE_BUTTON = 'ats-scorecard-request-modal-save-button',
  REQUEST_MODAL_CANCEL_BUTTON = 'ats-scorecard-request-modal-cancel-button',
  REQUEST_MODAL_FOOTER_CONTAINER = 'ats-scorecard-request-modal-footer-container',
  REQUEST_MODAL_BACK_BUTTON = 'ats-scorecard-request-modal-back-button',
  PENDING_REQUESTS_HEADER = 'ats-scorecard-modal-pending-requests-header',
  PENDING_REQUEST_ROW = 'ats-scorecard-modal-pending-request-row',
}

export enum ATSCandidateTagDataTestIds {
  HEADER = 'ats-candidate-tag-header',
  LIST_CONTAINER = 'ats-candidate-tag-container',
  ITEM = 'ats-candidate-tag-item',
  LABEL = 'ats-candidate-tag-label',
  REMOVE_ICON = 'ats-candidate-tag-remove-icon',
  DROPDOWN = 'ats-candidate-tag-dropdown',
  DROPDOWN_INPUT = 'ats-candidate-tag-dropdown-input',
  DROPDOWN_LIST_ITEM = 'ats-candidate-tag-dropdown-list-item',
  DROPDOWN_LIST_ITEM_TAG = 'ats-candidate-tag-dropdown-list-item-tag',
}
