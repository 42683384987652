import { Component, ReactNode } from 'react';
import styled from 'styled-components';
import { MUIAttachmentIcon } from '../styles';

import { FileViewerProps, FileViewerState } from './types';
import { Box } from '@mui/material';
import { nimbleTheme } from 'theme';
import auth from 'utils/auth';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { DefaultContainer, PrintContainer } from 'features/PrintView/styles';
import { fileNameShortener } from 'utils/util';

class FileViewer extends Component<FileViewerProps, FileViewerState> {
  constructor(props: FileViewerProps) {
    super(props);
    this.state = {
      showModal: false,
      showDeleteModal: false,
      showDeleteButton: false,
    };
  }

  close = (): void => {
    this.setState({ showModal: false });
  };

  open = (): void => {
    this.setState({ showModal: true });
  };

  handleMouseEnter = (): void => {
    if (
      this.props.showDelete &&
      this.props.user &&
      (this.props.createdById == this.props.user.id || auth.isSuperAdminUser()) &&
      auth.hasApplicationAttachmentDelete(this.props.user)
    ) {
      this.setState({ showDeleteButton: true });
    }
  };

  handleMouseLeave = (): void => {
    if (this.state.showDeleteButton) {
      this.setState({ showDeleteButton: false });
    }
  };

  handleOpenDeleteModal = (): void => {
    this.setState({ showDeleteModal: true });
  };

  render(): ReactNode {
    let file = this.props.file;
    if (!file) {
      return (
        <div className="file-line-item">
          <span>No File Uploaded</span>
        </div>
      );
    }

    const fileSuffix = file.split('.').pop();
    const imageBool =
      fileSuffix.slice(0, 3) === 'jpg' ||
      fileSuffix.slice(0, 3) === 'png' ||
      fileSuffix.slice(0, 4) === 'jpeg';

    const fileUrl = imageBool ? file : encodeURIComponent(file);

    // doc and docx have to be viewed with office software
    if (fileSuffix.slice(0, 3) === 'doc') {
      file = `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}&wdAccPdf=0`;
    }

    return (
      <Container onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <a href={file} rel="noopener noreferrer" target="_blank">
          <Box className="file" sx={{ flexGrow: 1, display: 'flex' }}>
            <Box className="icon-and-file-name">
              {this.props.icon || (
                <MUIAttachmentIcon
                  sx={{
                    width: 'inherit !important',
                    height: '18px',
                    marginRight: `${nimbleTheme.spacing(0.5)} !important`,
                  }}
                />
              )}
            </Box>
            <DefaultContainer>
              {this.props.title ? `View ${this.props.title}` : 'View File Upload'}
            </DefaultContainer>

            <PrintContainer>
              {this.props.icon ? (
                <div style={{ paddingLeft: nimbleTheme.spacing(0.5) }}>{this.props.file}</div>
              ) : (
                fileNameShortener(file)
              )}
            </PrintContainer>
          </Box>
        </a>
        {this.state.showDeleteButton && (
          <DeleteButton
            dataTestId="ats-profile-fileviewer-delete-button"
            onClick={() => this.props.onDeleteClick(this.props.id)}
            sx={{
              fill: nimbleTheme.palette.gray.mediumDark,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          />
        )}
      </Container>
    );
  }
}

export default FileViewer;

const Container = styled.div`
  min-height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 1rem;
  margin-bottom: 6px;
  background-color: #eeeff2;
  color: #777777;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  .icon-and-file-name {
    display: flex;
    align-items: center;
  }

  div {
    color: #777777;
  }

  img,
  svg,
  span {
    margin-right: 8px;
    width: 18px;
    @media print {
      margin-right: 0;
      padding-right: 0 !important;
    }
  }

  @media print {
    padding: 0 !important;
    margin: 0;
    font-size: ${nimbleTheme.typography.print.fontSize};
    min-height: 0;
  }
`;
