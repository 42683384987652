export const GRADE_MAPPING = {
  early_childhood: [0],
  elementary: [13, 1, 2, 3, 4, 5],
  middle_school: [6, 7, 8],
  high_school: [9, 10, 11, 12],
};

export enum EMPLOYMENT_TYPE {
  partTime = 0.5,
  fullAndPartTime = 0.75,
  fullTime = 1,
}

export enum SALARY_TYPE {
  hourly = 1,
  weekly = 2,
  monthly = 3,
  yearly = 4,
  daily = 5,
}
