import { StringField, BooleanField, NumberField } from './types/types';
import { Job } from 'types/types';

export const updateStringField = (name: StringField, value: string, jobCopy: Job): Job => {
  jobCopy[name] = value;
  return jobCopy;
};

export const updateBooleanField = (name: BooleanField, value: boolean, jobCopy: Job): Job => {
  jobCopy[name] = value;
  return jobCopy;
};

export const updateNumberField = (name: NumberField, value: number, jobCopy: Job): Job => {
  jobCopy[name] = value;
  return jobCopy;
};
