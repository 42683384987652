import { Component } from 'react';

import PropTypes from 'prop-types';

export default class CardBox extends Component {
  static propTypes = {
    elementClassName: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
  };

  render() {
    return (
      <div className={`${this.props.elementClassName || ''} card-box`}>
        <div className="display-name">{this.props.displayName}</div>
        <div className="exam-content">{this.props.content}</div>
      </div>
    );
  }
}
