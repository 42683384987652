import { AxiosResponse } from 'axios';
import { School } from 'types/connectTypes.js';
import { BaseNimbleAPI } from '../../../api/baseAPI.js';

interface GetSchoolsByNcesIdRequest {
  stateId: string;
  ncesId: string;
}

class MarketplaceSchoolsAPI extends BaseNimbleAPI {
  get config(): { baseURL: string } {
    return { baseURL: '/api/marketplace/' };
  }

  async getSchoolByNcesId({ stateId, ncesId }: GetSchoolsByNcesIdRequest): Promise<School | null> {
    if (!stateId || !ncesId) throw new Error('State ID and NCES ID are required');

    const queryParams: {
      state_abbreviation: string;
      school_nces_id: string;
    } = {
      state_abbreviation: stateId,
      school_nces_id: ncesId,
    };

    const { data, status }: AxiosResponse = await this.get('schools', {
      params: queryParams,
    });
    if (status !== 200) throw new Error('Failed to get schools by NCES ID');

    const school = data?.items.length > 0 ? data.items[0] : null;
    return school;
  }
}

export default new MarketplaceSchoolsAPI();
