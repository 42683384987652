import { BaseNimbleAPI } from './baseAPI';
import axios from 'axios';

const CancelToken = axios.CancelToken;

class SearchAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/search' };
  }

  async searchCandidates(params) {
    if (this.cancelSearchCandidates !== undefined) {
      this.cancelSearchCandidates();
    }

    const url = '/candidates/';
    const response = await this.get(url, {
      cancelToken: new CancelToken((cancel) => {
        this.cancelSearchCandidates = cancel;
      }),
      params,
    });
    return response.data;
  }

  async searchDistrictUsers(params) {
    if (this.cancelSearchDistrictUsers !== undefined) {
      this.cancelSearchDistrictUsers();
    }

    const url = '/districtusers/';
    const response = await this.get(url, {
      cancelToken: new CancelToken((cancel) => {
        this.cancelSearchDistrictUsers = cancel;
      }),
      params,
    });
    return response.data;
  }
}

export default new SearchAPI();
