import { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { Box, Stack, Grid, InputAdornment, Theme, useTheme } from '@mui/material';
import { Input } from 'sharedComponents/Input';
import { styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocationFilter } from 'sharedComponents/Filters/LocationFilter';
import StateJobBoardFilterDropDowns from './StateJobBoardFilterDropDowns';
import { StateJobBoardFilterDropDownsProps } from './types';
import { StateJobBoardLocationFilterDataTestIds } from './constants';
import { ATSStateJobBoardFiltersDataTestIds } from 'data-testids/ATS';
import defaultMap from 'assets/idoe-default-map.png';
import FilterValueTags from './Filters/FilterValueTags';
import { MobileDrawerControlButtonGroup } from 'sharedComponents/Drawer/MobileDrawerControlButtonGroup';
import { MobileDrawer } from 'sharedComponents/Drawer/MobileDrawer';
import { MobileKeywordSearchFilterBody } from 'sharedComponents/Filters/MobileDrawerKeywordFilterBody';
import { getSearchPlaceholderText } from './utils';
import {
  LocationFilterDisplayNames,
  MobileDrawerButtonText,
  MobileDrawerTitles,
  MobileDrawerTypes,
  searchLabelText,
} from 'sharedComponents/Filters/constants';

export const StateJobBoardFilterContainer: React.FC<StateJobBoardFilterDropDownsProps> = ({
  filterValues,
  actions,
  filterOptions,
  activeFilters,
  setIsLoading,
  isLoading,
  setUseDebounce,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showMap, setShowMap] = useState<boolean>(false);
  const [mapUrl, setMapUrl] = useState<string>();
  const [drawerType, setDrawerType] = useState<string>('');
  const [isMobileLocationSubmitClicked, setIsMobileLocationSubmitClicked] =
    useState<boolean>(false);
  const [locationInput, setLocationInput] = useState<string>('');
  const [selectedDistance, setSelectedDistance] = useState<string>('');
  const [previousKeyword, setPreviousKeyword] = useState<string>('');
  const [previousLocationInput, setPreviousLocationInput] = useState<string>('');
  const [previousSelectedDistance, setPreviousSelectedDistance] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const ignoredKeys = ['distanceRadiusMiles', 'locationLongitude', 'locationLatitude'];
  const drawerFilters = Object.entries(activeFilters).filter(([key]) => !ignoredKeys.includes(key));
  const hasActiveFilters = drawerFilters.some(([, value]) => value === true);
  const hasSearchTerm = !!searchTerm;
  const activeDrawers = [hasSearchTerm, showMap, hasActiveFilters];

  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleKeywordSubmit();
    }
  };

  const handleKeywordSubmit = () => {
    if (previousKeyword === searchTerm) {
      closeDrawer();
      return;
    }

    setPreviousKeyword(searchTerm);
    actions.setQuery(searchTerm);
    closeDrawer();
  };

  const handleLocationSubmit = () => {
    const hasLocationChanged = locationInput !== previousLocationInput;
    const hasDistanceChanged = selectedDistance !== previousSelectedDistance;
    if (!hasLocationChanged && !hasDistanceChanged) {
      closeDrawer();
      return;
    }
    setIsMobileLocationSubmitClicked(true);
  };

  useEffect(() => {
    setIsMobileLocationSubmitClicked(false);

    if (locationInput && selectedDistance) {
      setPreviousLocationInput(locationInput);
      setPreviousSelectedDistance(selectedDistance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapUrl]);

  const drawerTitles = [
    MobileDrawerTitles.SEARCH,
    MobileDrawerTitles.LOCATION,
    MobileDrawerTitles.FILTERS,
  ];

  const drawerOnClickHandlers = [
    () => setDrawerType(MobileDrawerTypes.SEARCH),
    () => setDrawerType(MobileDrawerTypes.LOCATION),
    () => setDrawerType(MobileDrawerTypes.FILTERS),
  ];

  const closeDrawer = () => {
    setDrawerType('');
  };

  const getDrawerProps = () => {
    switch (drawerType) {
      case MobileDrawerTypes.SEARCH:
        return {
          title: MobileDrawerTitles.SEARCH,
          buttonText: MobileDrawerButtonText.SEARCH,
          onSubmitHandler: handleKeywordSubmit,
          children: (
            <MobileKeywordSearchFilterBody
              searchTerm={searchTerm}
              handleKeywordChange={handleKeywordChange}
              dataTestId=""
            />
          ),
        };
      case MobileDrawerTypes.LOCATION:
        return {
          title: MobileDrawerTitles.LOCATION,
          buttonText: MobileDrawerButtonText.LOCATION,
          onSubmitHandler: handleLocationSubmit,
          children: (
            <LocationFilter
              filterValues={filterValues}
              filterOptions={filterOptions.distanceOptions}
              distanceDisplayName={LocationFilterDisplayNames.DISTANCE_DISPLAY_NAME}
              locationDisplayName={LocationFilterDisplayNames.LOCATION_DISPLAY_NAME}
              locationChangeHandler={actions.setSelectedLocation}
              actions={actions}
              setShowMap={setShowMap}
              setMapUrl={setMapUrl}
              mapUrl={mapUrl}
              searchTerm={searchTerm}
              dataTestIds={StateJobBoardLocationFilterDataTestIds}
              hasKeywordSearch={false}
              isExternalSubmit={isMobileLocationSubmitClicked}
              setIsExternalSubmit={setIsMobileLocationSubmitClicked}
              closeDrawer={closeDrawer}
              locationInput={locationInput}
              setLocationInput={setLocationInput}
              selectedDistance={selectedDistance}
              setSelectedDistance={setSelectedDistance}
              setIsLoading={setIsLoading}
              previousLocationInput={previousLocationInput}
              previousSelectedDistance={previousSelectedDistance}
            />
          ),
        };
      case MobileDrawerTypes.FILTERS:
        return {
          title: MobileDrawerTitles.FILTERS,
          buttonText: MobileDrawerButtonText.FILTERS,
          onSubmitHandler: () => {
            closeDrawer();
          },
          isDisabled: false,
          children: (
            <StateJobBoardFilterDropDowns
              filterValues={filterValues}
              actions={actions}
              filterOptions={filterOptions}
              activeFilters={activeFilters}
              setUseDebounce={setUseDebounce}
              error={error}
            />
          ),
        };
      default:
        return {
          title: '',
          buttonText: '',
          onSubmitHandler: () => {},
          children: <></>,
          isDisabled: true,
        };
    }
  };

  const drawerProps = getDrawerProps();

  return (
    <Container direction={'column'} spacing={isMobile ? 1 : 2}>
      {!isMobile && (
        <GridContainer container>
          <Grid item xs={12} sm={5} lg={6}>
            <Input
              label={searchLabelText}
              variant="outlined"
              size="small"
              placeholder={getSearchPlaceholderText(isMobile)}
              value={searchTerm}
              onChange={handleKeywordChange}
              onKeyDown={onKeyPress}
              dataTestId={ATSStateJobBoardFiltersDataTestIds.SEARCH_INPUT}
              sx={InputStyles(theme)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledSearchIcon onClick={handleKeywordSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5} lg={5.5}>
            <LocationFilter
              filterValues={filterValues}
              filterOptions={filterOptions.distanceOptions}
              distanceDisplayName={LocationFilterDisplayNames.DISTANCE_DISPLAY_NAME}
              locationDisplayName={LocationFilterDisplayNames.LOCATION_DISPLAY_NAME}
              locationChangeHandler={actions.setSelectedLocation}
              actions={actions}
              setShowMap={setShowMap}
              setMapUrl={setMapUrl}
              mapUrl={mapUrl}
              searchTerm={searchTerm}
              dataTestIds={StateJobBoardLocationFilterDataTestIds}
              hasKeywordSearch={true}
              isExternalSubmit={false}
              locationInput={locationInput}
              setLocationInput={setLocationInput}
              selectedDistance={selectedDistance}
              setSelectedDistance={setSelectedDistance}
              setIsExternalSubmit={setIsMobileLocationSubmitClicked}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Grid>
        </GridContainer>
      )}
      <MapContainer>
        {showMap ? (
          <Map src={mapUrl} alt="locations" />
        ) : (
          <Map src={defaultMap} alt="default-location" />
        )}
      </MapContainer>
      <StyledFilterValueTags item xs={12}>
        <FilterValueTags
          actions={actions}
          filterValues={filterValues}
          activeFilters={activeFilters}
          error={error}
        />
      </StyledFilterValueTags>
      {isMobile ? (
        <Stack spacing={1.5}>
          <MobileDrawerControlButtonGroup
            buttonTitles={drawerTitles}
            onClickHandlers={drawerOnClickHandlers}
            activeDrawers={activeDrawers}
          />
          <MobileDrawer
            isOpen={!!drawerType}
            closeHandler={closeDrawer}
            openHandler={() => {}}
            showCloseButton={true}
            dataTestId=""
            {...drawerProps}
            isLoading={isLoading}
            isDisabled={drawerProps.isDisabled}
          >
            {drawerProps.children}
          </MobileDrawer>
        </Stack>
      ) : (
        <StateJobBoardFilterDropDowns
          filterValues={filterValues}
          actions={actions}
          filterOptions={filterOptions}
          activeFilters={activeFilters}
          setUseDebounce={setUseDebounce}
          error={error}
        />
      )}
    </Container>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: `${theme.palette.white.main}`,
  paddingLeft: `${theme.spacing(2.5)}`,
  paddingRight: `${theme.spacing(2.5)}`,
  [theme.breakpoints.down('md')]: {
    paddingLeft: `${theme.spacing(2.5)}`,
    paddingRight: `${theme.spacing(2.5)}`,
  },
}));

const GridContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
}));

const MapContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  border: `solid 1px ${theme.palette.gray.darker}`,
  borderRadius: theme.shape.borderRadius * 3,
  [theme.breakpoints.down('md')]: {
    height: theme.spacing(18.5),
  },
}));

const Map = styled('img')(({ theme }) => ({
  background: 'url(${props => props.src}) lightgray 50% / cover no-repeat',
  alignSelf: 'center',
  backgroundPosition: 'center',
  width: '100%',
  height: theme.spacing(28),
  borderRadius: theme.shape.borderRadius * 3,
  border: `solid 1px ${theme.palette.gray.darker}`,
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export const InputStyles = (theme: Theme) => ({
  width: '100%',
  fontSize: theme.typography.subtitle.fontSize,
  borderRadius: theme.shape.borderRadius * 2,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },

  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
      height: theme.spacing(2.5),
    },
  },
});

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledFilterValueTags = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
}));
