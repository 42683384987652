import {
  Answer,
  AttachmentBox,
  BoldText,
  FlexColumnBox,
  Question,
  RatingsBox,
  StyledListItem,
} from './styles';
import { Box, List, useTheme } from '@mui/material';
import { fileNameShortener } from 'utils/util';
import { PrintDivider } from './ScorecardPrintView';
import { QuestionText } from './styles';
import { RubricPrintView } from './RubricPrintView';
import { scorecardQType } from 'utils/enums';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MultipleChoiceViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/MultipleChoiceViewAnswer';
import TextViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/TextViewAnswer';

export interface ReviewerQuestionsAndAnswersProps {
  data: any;
}

export const renderQuestion = (item) => {
  const question = item.question_text;

  return (
    <>
      <QuestionText>{question}</QuestionText>
      {item.is_required && <sup>*</sup>}
    </>
  );
};

export const renderAnswer = (answer) => {
  switch (answer.question_type) {
    case scorecardQType.nimble:
    case scorecardQType.text:
      return <TextViewAnswer answer={answer} />;
    case scorecardQType.multiple_choice:
      return <MultipleChoiceViewAnswer answer={answer} />;
    default:
      return <div />;
  }
};

export const ReviewerQuestionsAndAnswers: React.FC<ReviewerQuestionsAndAnswersProps> = ({
  data,
}) => {
  const theme = useTheme();

  const answersToRender = data.answers.filter(
    (item) =>
      item.question_type !== scorecardQType.attachment &&
      item.question_type !== scorecardQType.cumulative_score &&
      item.question_type !== scorecardQType.direction_text &&
      item.question_type !== scorecardQType.final_recommendation &&
      item.question_type !== scorecardQType.rating &&
      item.question_type !== scorecardQType.rubric
  );

  const attachments = data?.answers.filter(
    (item) => item.question_type === scorecardQType.attachment
  );
  const ratings = data?.answers.filter((item) => item.question_type === scorecardQType.rating);
  const rubrics = data?.answers.filter((item) => item.question_type === scorecardQType.rubric);

  return (
    <>
      {answersToRender.length > 0 && (
        <>
          <List sx={{ padding: 0 }}>
            {answersToRender.map((item) => {
              return (
                <>
                  <StyledListItem key={item.id}>
                    <Question>{renderQuestion(item)}</Question>
                    <Answer>{renderAnswer(item)}</Answer>
                  </StyledListItem>
                  <PrintDivider />
                </>
              );
            })}
          </List>
        </>
      )}

      {rubrics.length > 0 && (
        <>
          {rubrics.map((rubric) => (
            <RubricPrintView rubric={rubric} key={rubric.id} />
          ))}
          <PrintDivider />
        </>
      )}

      {ratings.length > 0 && (
        <FlexColumnBox>
          {ratings.map((rating) => (
            <RatingsBox key={rating.id}>
              <BoldText>{rating.question_text}.</BoldText>
              {rating.answer_rating} / {rating.scale}
            </RatingsBox>
          ))}
          <PrintDivider />
        </FlexColumnBox>
      )}

      {attachments.length > 0 && (
        <FlexColumnBox>
          {attachments.map((attachment) => {
            if (attachment?.attachment) {
              const attachmentName = attachment?.attachment
                ? fileNameShortener(attachment?.attachment).substring(14)
                : '';

              return (
                <AttachmentBox key={attachment.id}>
                  <BoldText>{attachment.question_text}.</BoldText>
                  <Box sx={{ display: 'flex' }}>
                    <AttachFileIcon
                      fontSize="small"
                      sx={{ transform: 'rotate(45deg)', fill: theme.palette.text.tertiary }}
                    />
                    {attachmentName}
                  </Box>
                </AttachmentBox>
              );
            } else {
              return (
                <Box key={attachment.id}>
                  <BoldText>{attachment.question_text}.</BoldText>
                  <i>No attachment</i>
                </Box>
              );
            }
          })}
        </FlexColumnBox>
      )}
    </>
  );
};
