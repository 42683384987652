import { useState } from 'react';
import styled from 'styled-components';
import { DataDashboardContainer } from '../DataDashboardContainer';
import { DashboardBaseChart } from '../components/DashboardBaseChart';
import {
  searchBarPlaceholders,
  stateDashboardSegmentTypes,
  stateDashboardChartTypes,
} from 'utils/enums';

/** Entrypoint into the Summary dashboard.**/
export default function SummaryDashboard() {
  const stateCode = 'IN';
  const [districtsSelected, setDistrictsSelected] = useState([]);
  const [hiringSeasonsSelected, setHiringSeasonsSelected] = useState([]);
  const [jobSubcategoriesSelected, setJobSubcategoriesSelected] = useState([]);

  return (
    <>
      <DataDashboardContainer
        title={'Summary metrics'}
        stateCode={stateCode}
        setDistrictsSelected={setDistrictsSelected}
        setHiringSeasonsSelected={setHiringSeasonsSelected}
        setJobSubcategoriesSelected={setJobSubcategoriesSelected}
      />
      <DashboardContainer>
        <DashboardBaseChart
          title="Applicant Pool Characteristics"
          chartType={stateDashboardChartTypes.pie}
          XAxisLabel={''}
          groupByOptions={[
            stateDashboardSegmentTypes.credential,
            stateDashboardSegmentTypes.gender,
            stateDashboardSegmentTypes.raceEthnicity,
          ]}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          stateCode={stateCode}
          graphId={0}
        />
        <DashboardBaseChart
          title="Vacancy Characteristics"
          useVacancyAsTotal={true}
          chartType={stateDashboardChartTypes.pie}
          XAxisLabel={''}
          groupByOptions={[
            stateDashboardSegmentTypes.employmentType,
            stateDashboardSegmentTypes.subcategory,
          ]}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          stateCode={stateCode}
          graphId={1}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          searchBarPlaceholders={searchBarPlaceholders}
        />
      </DashboardContainer>
      <DashboardContainer>
        <DashboardBaseChart
          title="Hire Rate by Applicant Group"
          chartType={stateDashboardChartTypes.bar}
          XAxisLabel={'% Hire Rate'}
          showFilter={true}
          alwaysShowStatus={true}
          groupByOptions={[
            stateDashboardSegmentTypes.credential,
            stateDashboardSegmentTypes.gender,
            stateDashboardSegmentTypes.raceEthnicity,
          ]}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          stateCode={stateCode}
          excludePostingOnly={true}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          graphId={3}
          searchBarPlaceholders={searchBarPlaceholders}
        />

        <DashboardBaseChart
          title="Vacancy Fill Rate by Posting Type"
          chartType={stateDashboardChartTypes.bar}
          XAxisLabel={'% Vacancy Fill Rate'}
          vacancyFillRate={true}
          showFilter={true}
          groupByOptions={[
            stateDashboardSegmentTypes.subcategory,
            stateDashboardSegmentTypes.employmentType,
          ]}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          stateCode={stateCode}
          excludePostingOnly={true}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          graphId={4}
          searchBarPlaceholders={searchBarPlaceholders}
        />
      </DashboardContainer>
    </>
  );
}
const DashboardContainer = styled.div`
  display: flex;
  gap: 15px;
  max-width: 1160px;
  margin: 10px auto 10px;
  padding: 0 30px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;
