import { Component } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import DistrictAccountNewUserModal from './districtaccountnewusermodal';
import DeactivateModal from './deactivatemodal';
import auth from '../../utils/auth';
import { user_type } from '../../utils/enums';
import moment from 'moment';

import ManageUsersIcon from '../../assets/icons/AccountManagementIcons/manage_users.svg';
import EditIcon from '../../assets/icons/AccountManagementIcons/edit-icon.svg';
import IconCaretDown from '../../assets/icon-down-caret-grey.svg';
import IconCaretUp from '../../assets/icon-up-caret-green.svg';

const USERTYPE = user_type().reduce((obj, item) => {
  obj[item.key] = item.label;
  return obj;
}, {});

export default class DistrictAccountUsers extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    deactivateUser: PropTypes.func.isRequired,
    reactivateUser: PropTypes.func.isRequired,
    district: PropTypes.object.isRequired,
    schools: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
  };
  static defaultProps = { district: {} };
  state = {
    displayModal: false,
    selectedUser: {},
    showDeactivatedUsers: false,
    showDeactivateModal: false,
  };

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  showModal = (e) => {
    e.stopPropagation();
    this.setState({
      displayModal: true,
    });
  };

  showEditModal = (user) => this.setState({ selectedUser: user });

  hideModal = () => {
    this.setState({
      displayModal: false,
      selectedUser: {},
    });
  };

  toggleActivatedUsers = () => {
    this.setState((prevState) => {
      return { showDeactivatedUsers: !prevState.showDeactivatedUsers };
    });
  };

  toggleDeactivateModal = () => {
    this.setState((prevState) => {
      return { showDeactivateModal: !prevState.showDeactivateModal };
    });
  };

  render() {
    return (
      <div className="card no-padding">
        <div>
          <div className="header-div">
            <div className="flex">
              <img className="mr1" src={ManageUsersIcon} alt="" />
              <h3>Manage Users</h3>
            </div>
            <button className="edit-button" onClick={this.showModal}>
              + Add user
            </button>
          </div>
          <div className="districtaccount-row header users">
            <div className="name">Full Name</div>
            <div className="user-type">User Type</div>
            <div className="last-login">Last Log-in</div>
          </div>
          {this.props.users
            .filter((u) => u.is_active)
            .map((user) => (
              <div key={user.id} className="districtaccount-row users">
                <div className="name">
                  {user.name}
                  {auth.isSuperAdminUser() &&
                    !auth.inPostingOnlyDistrict() &&
                    !user.linked_users.length && (
                      <ExclamationIcon
                        data-for="manage-users-info"
                        data-tip="Edit this user to link their candidate profile & hide screening details"
                        data-effect="solid"
                      />
                    )}
                </div>
                <div className="user-type">
                  {`${USERTYPE[user.profile.user_type]} ${
                    auth.isSchoolUser(user) && user.profile.school
                      ? `at ${user.profile.school.name}`
                      : ''
                  }`}
                </div>
                <div className="last-login">
                  {user.last_login ? moment(user.last_login).fromNow() : 'N/A'}
                </div>

                {auth.getUser().profile.user_type >= user.profile.user_type && (
                  <img
                    className="edit"
                    src={EditIcon}
                    alt="edit"
                    onClick={() => this.showEditModal(user)}
                  />
                )}

                {this.state.selectedUser.id === user.id && (
                  <DistrictAccountNewUserModal
                    onSave={this.props.updateUser}
                    displayModal={
                      this.state.selectedUser.id === user.id && !this.state.showDeactivateModal
                    }
                    district={this.props.district}
                    hideModal={this.hideModal}
                    user={this.state.selectedUser}
                    schools={this.props.schools}
                    toggleDeactivateModal={this.toggleDeactivateModal}
                  />
                )}
                {this.state.selectedUser.id === user.id && (
                  <DeactivateModal
                    show={this.state.showDeactivateModal}
                    onHide={() => {
                      this.toggleDeactivateModal();
                      this.hideModal();
                    }}
                    // same behavior as onHide for the cancel button in this case
                    onCancel={() => {
                      this.toggleDeactivateModal();
                      this.hideModal();
                    }}
                    onSave={() => {
                      this.toggleDeactivateModal();
                      this.hideModal();
                      this.props.deactivateUser(user);
                    }}
                    header={'Deactivate User'}
                    mainText={`Are you sure you want to deactivate ${user.name}?`}
                    subText={'This can be undone by clicking "Show Deactivated Users" any time.'}
                    saveButtonText={'Deactivate This User'}
                    backButtonText={'Cancel'}
                    icon={ManageUsersIcon}
                  />
                )}
              </div>
            ))}
          {auth.isSuperAdminUser() && this.props.users.filter((u) => !u.is_active).length > 0 && (
            <div>
              <div
                className={`toggle-div ${this.state.showDeactivatedUsers ? 'expanded' : ''}`}
                onClick={this.toggleActivatedUsers}
              >
                {this.state.showDeactivatedUsers ? 'Hide' : 'Show'}{' '}
                {this.props.users.filter((u) => !u.is_active).length} deactivated users
                <img
                  src={this.state.showDeactivatedUsers ? IconCaretUp : IconCaretDown}
                  alt="show deactivated users"
                />
              </div>
              <div
                className={`card no-padding additional-section ${
                  this.state.showDeactivatedUsers ? 'expanded' : ''
                }`}
              >
                {this.props.users
                  .filter((u) => !u.is_active)
                  .map((user) => (
                    <div key={user.id} className="districtaccount-row users">
                      <p className="name">{user.name}</p>
                      <div className="user-type">
                        {`${USERTYPE[user.profile.user_type]} ${
                          auth.isSchoolUser(user) && user.profile.school
                            ? `at ${user.profile.school.name}`
                            : ''
                        }`}
                      </div>
                      <div className="last-login">
                        {user.last_login ? moment(user.last_login).fromNow() : 'N/A'}
                      </div>
                      <span className="reactivate" onClick={() => this.props.reactivateUser(user)}>
                        Reactivate user
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <DistrictAccountNewUserModal
          onSave={this.props.createUser}
          displayModal={this.state.displayModal}
          district={this.props.district}
          hideModal={this.hideModal}
          schools={this.props.schools}
        />
        <ReactTooltip id="manage-users-info" />
      </div>
    );
  }
}

const ExclamationIcon = styled.div`
  position: absolute;

  right: 40px;
  top: 3px;

  height: 18px;
  width: 18px;

  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.uiColors.lightgray};

  &::after {
    display: block;
    height: 18px;
    width: 18px;

    position: absolute;
    left: 6px;
    bottom: 1px;

    content: '!';
    color: ${(props) => props.theme.uiColors.lightgray};
  }
`;
