import { getJobViewV2Url, shouldSeePublicLink } from '../utils';
import { JobViewV2MetadataProps } from '../types';
import { MetadataContainer, RightBottom, RightTop } from '../styles';
import { JobViewV2PublicShareLink } from './JobViewV2PublicShareLink';
import auth from 'utils/auth';
import { JobViewV2Details } from './JobViewV2Details';

export const JobViewV2Metadata: React.FC<JobViewV2MetadataProps> = ({ job }) => {
  const districtRoleId = auth.isDistrictUser() ? job.id : job.district_role?.id;
  const jobLink = getJobViewV2Url(districtRoleId);

  return (
    <MetadataContainer>
      {shouldSeePublicLink(job) && (
        <RightTop>
          <JobViewV2PublicShareLink link={jobLink} />
        </RightTop>
      )}
      <RightBottom>
        <JobViewV2Details job={job} />
      </RightBottom>
    </MetadataContainer>
  );
};
