import { useState } from 'react';
import styled from 'styled-components';
import { DataDashboardContainerV2 } from '../components/V2Components/DataDashboardContainerV2';
import { DashboardBaseChartV2 } from '../components/V2Components/DashboardBaseChartV2';
import {
  searchBarPlaceholders,
  stateDashboardSegmentTypes,
  stateDashboardChartTypes,
} from 'utils/enums';

/** Entrypoint into the ApplicantCaracteristicsDashboard dashboard.**/
export default function ApplicantCharacteristics() {
  const stateCode = 'IN';
  const [districtsSelected, setDistrictsSelected] = useState([]);
  const [hiringSeasonsSelected, setHiringSeasonsSelected] = useState([]);
  const [jobSubcategoriesSelected, setJobSubcategoriesSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  return (
    <>
      <DataDashboardContainerV2
        title={'Applicant Characteristics'}
        stateCode={stateCode}
        setDistrictsSelected={setDistrictsSelected}
        setHiringSeasonsSelected={setHiringSeasonsSelected}
        setJobSubcategoriesSelected={setJobSubcategoriesSelected}
        showJobSubcategoriesDropdown={true}
        applicantCount={totalCount}
      />
      <DashboardContainer>
        <DashboardBaseChartV2
          title="Certification Type"
          chartType={stateDashboardChartTypes.bar}
          titleTooltip={true}
          titleTooltipText={`This chart is displaying the number of <br/>
          applicants who have certifications <br/>
          and which types they have.`}
          XAxisLabel={''}
          showFilter={false}
          showDropdown={false}
          defaultGroupBySelected={stateDashboardSegmentTypes.credential_status.value}
          groupByOptions={[stateDashboardSegmentTypes.credential_status]}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          stateCode={stateCode}
          graphId={1}
          legendOnLeft={false}
          setTotalCount={setTotalCount}
          certificationTypeGraph={true}
          filteredCategoriesFromGraph={['None', 'Other']}
        />
      </DashboardContainer>
      <DashboardContainer>
        <DashboardBaseChartV2
          title="Diversity"
          chartType={stateDashboardChartTypes.pie}
          XAxisLabel={''}
          groupByOptions={[
            stateDashboardSegmentTypes.gender,
            stateDashboardSegmentTypes.raceEthnicity,
          ]}
          defaultGroupBySelected={stateDashboardSegmentTypes.raceEthnicity.value}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          stateCode={stateCode}
          graphId={2}
          searchBarPlaceholders={searchBarPlaceholders}
          legendOnLeft={true}
          isSingleColumned={true}
          diversityGraph={true}
          titleTooltip={true}
          titleTooltipText={`This chart is displaying the <br/>
          breakdown of applicants by gender or race/ethnicity.`}
        />
      </DashboardContainer>
    </>
  );
}

export const DashboardContainer = styled.div`
  display: flex;
  gap: 15px;
  max-width: 1160px;
  margin: 10px auto 10px;
  padding: 0 30px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;
