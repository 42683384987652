import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import auth from '../../utils/auth';
import NotificationModal from './NotificationModals/NotificationModal';

import {
  notificationTypeLabel,
  notificationTypeValue,
  notificationFrequencyLabel,
  notificationJobsLabel,
  notificationSchools,
} from '../../utils/NotificationEnums';
import { ViewButton } from 'sharedComponents/Buttons';
import { SettingsRow } from 'components/SettingsRow';

const NotificationRow = ({
  notification,
  newApplicationStatuses,
  handlePutNotification,
  handleNotificationDelete,
  hasReferenceCompletedNotification,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  function putNotificationAndCloseModal(notification) {
    handlePutNotification(notification).then(closeModal);
  }

  function closeModal() {
    setEditModalOpen(false);
  }

  function openModal() {
    setEditModalOpen(true);
  }

  function renderSchools() {
    if (allSchoolsSelected) {
      return 'All schools';
    } else if (onlyCertainSchoolsSelected) {
      return getSchoolName();
    } else {
      return 'N/A';
    }
  }

  const allSchoolsSelected = notification.schools_preference === notificationSchools.all_schools;
  const onlyCertainSchoolsSelected =
    notification.schools_preference === notificationSchools.only_certain_schools;

  function getSchoolName() {
    if (notification.schools.length === 0) {
      return 'N/A';
    } else if (notification.schools.length === 1) {
      return notification.schools[0].name;
    } else {
      return 'Multiple';
    }
  }

  function renderJobsSelection() {
    if (notification.jobs_preference === null) {
      return 'N/A';
    } else {
      return notificationJobsLabel[notification.jobs_preference];
    }
  }

  return (
    <>
      <SettingsRow onClick={openModal} cta={<ViewButton />}>
        <SectionLarger>
          {notificationTypeLabel[notification.type]}
          {notification.type === notificationTypeValue.status_change && (
            <>{`: ${notification.status.label}`}</>
          )}
        </SectionLarger>
        <Section>{notificationFrequencyLabel[notification.frequency]}</Section>
        {auth.isDistrictAdmin() && <SectionLarge>{renderJobsSelection()}</SectionLarge>}
        {auth.isDistrictUser() && <SectionLarge>{renderSchools()}</SectionLarge>}
      </SettingsRow>

      {editModalOpen && (
        <NotificationModal
          notification={notification}
          show={editModalOpen}
          closeModal={closeModal}
          newApplicationStatuses={newApplicationStatuses}
          handleSubmit={putNotificationAndCloseModal}
          handleDelete={handleNotificationDelete}
          hasReferenceCompletedNotification={hasReferenceCompletedNotification}
        />
      )}
    </>
  );
};

NotificationRow.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default NotificationRow;

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;
`;

const SectionLarge = styled(Section)`
  flex: 0 0 20%;
`;

const SectionLarger = styled(Section)`
  flex: 0 0 28%;
  font-size: 15px;
`;
