import { CumulativeScoreAnswerProps } from './types';

const CumulativeScoreAnswer: React.FC<CumulativeScoreAnswerProps> = ({
  parentObject,
  dataTestId,
}) => {
  return (
    <p data-testid={dataTestId}>
      <i>
        {parentObject.cumulative_score} out of {parentObject.max_cumulative_score} possible points
      </i>
    </p>
  );
};

export default CumulativeScoreAnswer;
