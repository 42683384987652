import { Component } from 'react';

import PropTypes from 'prop-types';
import { addDocument } from '../../../utils/fileUpload.js';
import greenCheckmark from '../../../assets/icon_check_green.svg';

export default class FileUpload extends Component {
  static propTypes = {
    updateFile: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      documentExists: false,
      uploadErrorMessages: {
        document: '',
      },
      document: {},
      submitErrorMessage: '',
      postSubmission: false,
    };
    this.initialState = Object.assign({}, this.state);

    this.addDocument = addDocument.bind(this);
  }

  clickDocumentInput = () => {
    const uploadErrorMessages = this.state.uploadErrorMessages;
    uploadErrorMessages.resume = '';
    this.setState({ uploadErrorMessages });
    this.documentInput.click();
  };

  changeDocument = (event) => {
    const file = this.addDocument(event);
    // If there's an error
    if (!file) return;
    this.setState(
      {
        document: file.fileData,
        uploadErrorMessages: file.uploadErrorMessages,
        documentExists: true,
      },
      () => {
        this.props.updateFile(file.fileData);
      }
    );
  };

  document = () => {
    const uploadExists = this.state.documentExists;
    const attachment = this.state.document;
    const nameShowing = uploadExists ? attachment.name : '';

    return (
      <div className="file-upload-container">
        <div className="upload-flex-container">
          {uploadExists && <img alt="icon-check" src={greenCheckmark} />}
          <div className={`file-upload-col-2 ${uploadExists ? 'ml2' : 'ml1'}`}>
            <p className="bold mbhalf">{`${this.props.title}`}</p>
            <p className="upload-description-text mbhalf">{this.props.description}</p>
            <p className="upload-description-text">{nameShowing}</p>
          </div>
        </div>

        <input
          name="document"
          ref={(input) => (this.documentInput = input)}
          type="file"
          style={{ width: '0px', height: '0px', overflow: 'hidden', marginTop: '-0.4rem' }}
          onChange={this.changeDocument}
        />

        <button type="button" className="upload bg-green" onClick={this.clickDocumentInput}>
          {uploadExists ? 'Update File' : 'Select File'}
        </button>
      </div>
    );
  };

  render() {
    return <div>{this.document()}</div>;
  }
}
