import { Box, Link, styled, Typography } from '@mui/material';

export interface SettingsAnchorTagHeaderProps {
  hasOnboardingModule: boolean;
  hasAdminUsers: boolean;
  isSuperAdminUser: boolean;
  showSchoolGroups: boolean;
}

export const SettingsAnchorTagHeader: React.FC<SettingsAnchorTagHeaderProps> = ({
  hasAdminUsers,
  hasOnboardingModule,
  isSuperAdminUser,
  showSchoolGroups,
}) => {
  return (
    <Box sx={{ paddingTop: '48px' }}>
      <Typography id="top" variant="h4" sx={{ fontWeight: 600, fontSize: '18px' }}>
        Quick Links
      </Typography>
      <Box>
        <Row>
          <Link href="#notifications">Notifications</Link> &nbsp;|&nbsp;
          <Link href="#scorecards">Scorecards</Link>
          {isSuperAdminUser && (
            <>
              &nbsp;|&nbsp;<Link href="#referenceForms">Reference Forms</Link>
            </>
          )}
          &nbsp;|&nbsp; <Link href="#emailTemplates">Email Templates</Link>
          {showSchoolGroups && (
            <>
              &nbsp;|&nbsp;<Link href="#schoolGroups">School Groups</Link>
            </>
          )}
        </Row>
        {isSuperAdminUser && (
          <Row>
            <Link href="#jobCategories">Job Categories</Link> &nbsp;|&nbsp;
            <Link href="#applicationQuestionSets">Application Question Sets</Link> &nbsp;|&nbsp;
            <Link href="#applicantSource">Applicant Source</Link> &nbsp;|&nbsp;
            <Link href="#candidateStatuses">Candidate Statuses</Link> &nbsp;|&nbsp;
            <Link href="#candidateTags">Candidate Tags</Link>
            {hasOnboardingModule && (
              <>
                |&nbsp; <Link href="#tasks">Tasks</Link> &nbsp;|&nbsp;
                {hasAdminUsers && (
                  <>
                    <Link href="#candidateForms">Candidate Forms</Link>&nbsp;|&nbsp;
                  </>
                )}
                <Link href="#customProfileFields">Custom Profile Fields</Link>
              </>
            )}
          </Row>
        )}
      </Box>
    </Box>
  );
};

const Row = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '8px',
  width: '100%',
  flexWrap: 'wrap',
});
