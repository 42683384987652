import { useMemo } from 'react';
import {
  ResponsiveContainer,
  Cell,
  CartesianGrid,
  BarChart,
  Bar,
  Text,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import styled from 'styled-components';
import { CHART_COLOR_PALETTES_V2 } from 'features/StateDataDashboards/constants';

let colors = CHART_COLOR_PALETTES_V2;

export const BarChartV2Component = ({
  dataset,
  XAxisLabel,
  vacancyFillRate,
  alwaysShowStatus,
  useVacancyAsTotal,
  certificationTypeGraph,
  vacancyByEmploymentTypeLegend,
  total,
  filteredCategoriesFromGraph,
}) => {
  let datasetCopy = [...dataset];

  const setDataKey = () => {
    if (vacancyFillRate) {
      return 'vacancy_fill_rate_rounded';
    } else if (useVacancyAsTotal) {
      return 'vacancy_count';
    } else if (alwaysShowStatus) {
      return 'hire_rate';
    } else {
      return 'value';
    }
  };

  const filterCategories = (dataCopy) => {
    if (!filteredCategoriesFromGraph.length) {
      return dataCopy;
    }
    const filteredCategoriesDictionary = filteredCategoriesFromGraph.reduce(
      (filteredCategoriesObj, category) => {
        filteredCategoriesObj[category] = category;
        return filteredCategoriesObj;
      },
      {}
    );
    return dataCopy.filter((dataCopyObj) => {
      return !filteredCategoriesDictionary.hasOwnProperty(dataCopyObj.name);
    });
  };

  const showYAxisLabels = dataset.length < 12;

  return (
    <ResponsiveContainer width={695} height={356}>
      <BarChart data={filterCategories(datasetCopy)} layout="vertical" margin={{ right: 39 }}>
        <CartesianGrid horizontal={false} vertical={false} />
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          tickLine={false}
          tick={<TickLabel fontSize={12} showYAxisLabels={showYAxisLabels} />}
          width={showYAxisLabels ? 170 : 15}
        />

        <XAxis
          type="number"
          tick={{ fontSize: 12 }}
          tickCount={10}
          tickLine={false}
          height={50}
          tickFormatter={(value) =>
            alwaysShowStatus ||
            vacancyFillRate ||
            (useVacancyAsTotal && vacancyByEmploymentTypeLegend)
              ? `${value}%`
              : `${value}`
          }
          label={{
            value: `${XAxisLabel}`,
            fontSize: 12,
            position: 'insideBottom',
            dy: 0,
            fill: '#777777',
          }}
        />
        <Bar dataKey={setDataKey()} fill="#00B88D" name="">
          {datasetCopy.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              tooltipItemColor={colors[index % colors.length]}
            />
          ))}
        </Bar>
        <Tooltip
          contentStyle={{ minWidth: '73px', height: '58px', alignContent: 'center' }}
          separator={''}
          content={
            <CustomTooltipBarChart
              alwaysShowStatus={alwaysShowStatus}
              vacancyFillRate={vacancyFillRate}
              useVacancyAsTotal={useVacancyAsTotal}
              certificationTypeGraph={certificationTypeGraph}
              total={total}
            />
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltipBarChart = ({
  payload,
  alwaysShowStatus,
  vacancyFillRate,
  useVacancyAsTotal,
  certificationTypeGraph,
}) => {
  const setTooltipValue = (bar) => {
    if (alwaysShowStatus) {
      return `${bar.payload.percentageLabel} (${bar.payload.value})`;
    } else if (vacancyFillRate) {
      return `${bar.payload.percentageLabel} (${bar.payload.vacancy_fill_count})`;
    } else if (useVacancyAsTotal || certificationTypeGraph) {
      return `${bar.value} (${bar.payload.percentageLabel})`;
    } else {
      return bar.value;
    }
  };

  for (const bar of payload) {
    return (
      <>
        <ToolTipContainer>
          {bar.payload.name}
          <br />
          <StyledPercentage>{setTooltipValue(bar)}</StyledPercentage>
        </ToolTipContainer>
      </>
    );
  }
  return null;
};

const TickLabel = ({ payload, showYAxisLabels, ...passthroughProps }) => {
  return <Text {...passthroughProps}>{showYAxisLabels ? payload.value : ''}</Text>;
};

const ToolTipContainer = styled.div`
  background-color: #ffffff;
  min-width: 73px;
  height: 58px;
  padding: 8px 18px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
`;

const StyledPercentage = styled.div`
  color: #00b88d;
`;
