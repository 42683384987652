import { Box, Stack, styled, Typography } from '@mui/material';
import { SwipeableDrawer } from './SwipeableDrawer';
import { FilterDrawerInterface } from './types';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material';

export const MobileDrawer = ({
  children,
  closeHandler,
  openHandler,
  anchor = 'bottom',
  isOpen = false,
  showCloseButton = true,
  sx,
  onSubmitHandler,
  title,
  buttonText,
  dataTestId,
  isLoading = false,
  isDisabled = false,
}: FilterDrawerInterface): JSX.Element => {
  const theme = useTheme();
  return (
    <SwipeableDrawer
      sx={sx ? { ...sx, ...DrawerStyles(theme) } : DrawerStyles(theme)}
      showCloseButton={showCloseButton}
      openHandler={openHandler}
      closeHandler={closeHandler}
      isOpen={isOpen}
      anchor={anchor}
      dataTestId={dataTestId}
    >
      <Stack spacing={3} minWidth={'100%'}>
        <StyledTitle>{title}</StyledTitle>
        <Box minWidth={'100%'}>{children}</Box>
        <PrimaryButton
          onClick={onSubmitHandler}
          sx={ButtonStyles(theme)}
          size="extraLarge"
          dataTestId={`${dataTestId}-submit-button`}
          isLoading={isLoading}
          disabled={isDisabled}
          loadingSpinnerColor={'inherit'}
        >
          {buttonText}
        </PrimaryButton>
      </Stack>
    </SwipeableDrawer>
  );
};

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px !important',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: theme.spacing(2.5),
}));

const DrawerStyles = (theme: Theme) => ({
  paddingX: theme.spacing(2),
  paddingY: '22px',
  '& .MuiPaper-root': {
    borderTopLeftRadius: theme.shape.borderRadius * 3,
    borderTopRightRadius: theme.shape.borderRadius * 3,
    overflow: 'scroll',
  },
});

const ButtonStyles = (theme: Theme) => ({
  fontSize: '16px !important',
  background: theme.palette.black.main,
  borderRadius: theme.shape.borderRadius * 0.5,
  minWidth: '100%',
  marginTop: theme.spacing(1),
  lineHeight: theme.spacing(2.5),
});
