import checkmark from '../../../assets/icons/icon-white-checkmark.svg';
import loadingSwirl from '../../../assets/icons/Ellipse.png';

export default function ProfilePreferenceProgressBar({
  step,
}: {
  step: number;
}): React.ReactElement {
  const createClassname = (type: string, num: number) => {
    const green = num <= step ? 'backgroundgreen' : '';
    return type === 'circle' ? `check-circle ${green}` : `line-section ${green}`;
  };

  const createInnerImage = (num: number) => {
    if (num === step) {
      return <img src={loadingSwirl} alt="swirly" className="swirly" />;
    } else if (num < step) {
      return <img src={checkmark} alt="checkmark" />;
    } else {
      return <div />;
    }
  };

  return (
    <div className={`application-progress-bar-container flex mt2 skip-documentation-page`}>
      <div className="application-progress-bar flex">
        <div className={createClassname('circle', 1)}>{createInnerImage(1)}</div>
        <div className={createClassname('', 2)} />
        <div className={createClassname('circle', 2)}>{createInnerImage(2)}</div>
        <div className={createClassname('', 3)} />
        {/* Placeholder divs for progress bar formatting */}
        <div />
        <div />
        <div className={createClassname('circle', 4)}>{createInnerImage(4)}</div>
      </div>
      <div className="application-progress-bar-text flex mt1">
        <div>PREFERENCES</div>
        <div>BASIC INFO</div>
        {/* Placeholder div for progress bar formatting */}
        <div />
        <div>EXPERIENCE</div>
      </div>
    </div>
  );
}
