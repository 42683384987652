import styled from 'styled-components';
import { grid } from 'styled-system';
import { District } from 'types/types';

import { NimbleLogoDarkText } from 'ui-kit/icons';

export interface JobNotFoundProps {
  district: District;
}

export const JobNotFound: React.FC<JobNotFoundProps> = ({ district }) => {
  return (
    <Container>
      <LogoContainer>
        <NimbleLogoDarkText width="165px" height="77px" />
      </LogoContainer>
      <HeaderText>Whoops, looks like that job is no longer active.</HeaderText>
      {district.name && (
        <BodyText>
          {`Click the link below to view a current list of opportunities at ${district.name}.`}
        </BodyText>
      )}
      {district.slug && (
        <ViewJobsButton
          onClick={() => {
            window.location.href = `/jobs/${district.slug}/`;
          }}
        >
          View jobs
        </ViewJobsButton>
      )}
    </Container>
  );
};

const Container = styled.div(
  {
    display: 'grid',
    gridTemplateRows: '1fr 12rem auto auto 8rem 2fr',

    textAlign: 'center',
    justifyItems: 'center',

    height: '100vh',
    backgroundColor: 'white',
  },
  grid
);

const LogoContainer = styled.div`
  grid-column-start: 2;
  grid-row-start: 2;
  align-self: center;
`;

const BodyText = styled.p`
  color: #444444;
  font-size: 1.4rem;
  letter-spacing: -0.2px;
  line-height: 2rem;

  grid-column-start: 2;
  grid-row-start: 4;
`;

const HeaderText = styled(BodyText)`
  font-weight: bold;
  font-size: 1.5rem;

  grid-column-start: 2;
  grid-row-start: 3;

  margin-bottom: 0.5rem;
`;

const ViewJobsButton = styled.button`
  background-color: #00b88d;
  color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 28rem;
  border: none;
  font-weight: bold;
  font-size: 1.3rem;
  height: 3.5rem;

  grid-column-start: 2;
  grid-row-start: 5;
  align-self: center;
`;
