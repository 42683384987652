import { createNotification, deleteNotification, editNotification } from './queries';
import {
  CreateNotificationMutationVariables,
  DeleteNotificationMutationVariables,
  EditNotificationMutationVariables,
  JobClosureData,
} from './types';
import { UseMutation } from 'types/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCreateNotificationMutation: UseMutation<
  JobClosureData,
  unknown,
  CreateNotificationMutationVariables,
  unknown
> = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: ({ jobId, pendingNotification }) =>
      createNotification(jobId, pendingNotification).then((res) => res.data as JobClosureData),

    onSuccess: (data, variables, context) => {
      const currentQueryData: JobClosureData[] = queryClient.getQueryData([
        'jobClosureNotifications',
        variables.jobId,
      ]);

      const updatedData = currentQueryData.concat(data);
      queryClient.setQueryData(['jobClosureNotifications', variables.jobId], updatedData);

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useEditNotificationMutation: UseMutation<
  JobClosureData,
  unknown,
  EditNotificationMutationVariables,
  unknown
> = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: ({ notificationId, pendingNotification }) =>
      editNotification(notificationId, pendingNotification).then(
        (res) => res.data as JobClosureData
      ),

    onSuccess: (data, variables, context) => {
      const currentQueryData: JobClosureData[] = queryClient.getQueryData([
        'jobClosureNotifications',
        variables.jobId,
      ]);

      const updatedData = currentQueryData.map((datum) =>
        datum.role_notification_id !== data.role_notification_id ? datum : data
      );

      queryClient.setQueryData(['jobClosureNotifications', variables.jobId], updatedData);

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useDeleteNotificationMutation: UseMutation<
  JobClosureData,
  unknown,
  DeleteNotificationMutationVariables,
  unknown
> = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: ({ notificationId }) =>
      deleteNotification(notificationId).then((res) => res.data as JobClosureData),

    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(['jobClosureNotifications', variables.jobId], []);

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
