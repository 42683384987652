import { Fragment, useState } from 'react';
import styled from 'styled-components';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ReactComponent as SearchIcon } from 'assets/icon_search_glass.svg';

import auth from 'utils/auth';

/**
 * @description
 * Accepts a list of users and sorts them alphabetically by their label.
 * The current user viewing the list is moved to the top and recieves a
 * "YOU" badge beside their name.
 */
export default function CurrentUserWithBadgeListFilter({
  displayName,
  fieldName,
  options,
  values,
  isActive,
  selectAll,
  clearAll,
  handleChange,
  isMultiSelectFilter = false,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const currentUserId = auth.getUser().id;

  let filteredOptions;
  if (!options) {
    filteredOptions = [];
  } else if (!searchValue) {
    let currentUser = {};

    // Order users alphabetically and remove current user
    filteredOptions = options
      .sort((optionOne, optionTwo) => optionOne.label.localeCompare(optionTwo.label))
      .filter((option) => {
        if (option.value === auth.getUser().id) {
          currentUser = option;
          return false;
        }
        return option;
      });
    // insert current user at top of list
    filteredOptions.splice(0, 0, currentUser);
  } else {
    filteredOptions = options.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const toggleOptions = () => setShowOptions((prev) => !prev);

  const getCaret = () => {
    if (isActive) {
      if (showOptions) {
        return iconUpCaretGreen;
      } else {
        return iconDownCaretGreen;
      }
    } else {
      if (showOptions) {
        return iconUpCaret;
      } else {
        return iconDownCaret;
      }
    }
  };

  return (
    <FilterContainer isActive={isActive}>
      <div className="cl-filter-header" onClick={toggleOptions}>
        <p>{displayName}</p>
        <img src={getCaret()} alt="" />
      </div>
      <div className={`relative selection-list ${showOptions ? 'show' : ''}`}>
        {selectAll && clearAll && (
          <div className="option select-all">
            <span className="drop-select-all" onClick={() => selectAll(fieldName, options)}>
              Select All
            </span>
            <span className="drop-select-all" onClick={() => clearAll(fieldName)}>
              Clear All
            </span>
          </div>
        )}
        <InputFilterContainer>
          <InputFilter
            type="text"
            placeholder=""
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {searchValue ? (
            <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
          ) : (
            <PositionedSearchIcon />
          )}
        </InputFilterContainer>
        {filteredOptions.map((option, index) => {
          const optionIsSelected = values.includes(option.value);
          return (
            <Fragment key={index}>
              <div className="option">
                <label className="container">
                  <input
                    type="checkbox"
                    checked={optionIsSelected}
                    onChange={() =>
                      // determine what type of function is being passed
                      isMultiSelectFilter
                        ? handleChange(fieldName, option.value, optionIsSelected, options.length)
                        : handleChange(fieldName, option.value)
                    }
                  />
                  <span className="checkmark" />
                  {option.value === currentUserId ? (
                    <>
                      {/* we check for "aria-expanded" during testing to ensure ellipsis is present */}
                      <OptionText aria-expanded={true}>{option.label}</OptionText>
                      <span>{option.value === currentUserId && <UserBadge>YOU</UserBadge>}</span>
                    </>
                  ) : (
                    option.label
                  )}
                </label>
              </div>
            </Fragment>
          );
        })}
      </div>
    </FilterContainer>
  );
}

const FilterContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  border-top: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  font-size: 13px;
  border-right: ${({ isActive }) => (isActive ? '2px solid #00B88D' : '')};

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid #dfdfdf;
  }

  ${({ isActive }) => (isActive ? 'p { color: #00B88D; }' : '')}

  .cl-filter-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 0;
  }

  .selection-list {
    display: none;

    &.show {
      display: block;
    }

    .option {
      cursor: pointer;
      color: rgba(57, 60, 73, 0.8);
      font-size: 13px;

      &.indented {
        padding-left: 28px;
      }

      &:first-child {
        padding-top: 8px;
      }

      &:last-child {
        padding-bottom: 8px;
      }

      .container {
        display: inline-flex;
        position: relative;
        padding-left: 26px;
        margin-bottom: 8px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: normal;

        /* Hide the browser's default checkbox */
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 1px;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: white;
          border: 1px solid #dfdfdf;

          /* Create the checkmark/indicator (hidden when not checked) */
          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
          background-color: #dfdfdf;
        }

        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
          background-color: #00b88d;
        }

        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
          display: block;
        }

        .status-circle {
          height: 9px;
          width: 9px;
          min-width: 9px;
        }
      }

      &.select-all {
        padding: ${({ showSearchInput }) => (showSearchInput ? '0.5rem 0' : '0.5rem 0 1rem')};
      }

      .drop-select-all {
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }
`;

const OptionText = styled.div`
  max-width: 105px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 0.5rem;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 15px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;

const UserBadge = styled.span`
  background-color: #7b5196;
  color: white;
  padding: 2px 5px;
  width: 70px;
  font-weight: bold;
  border-radius: 40px;
  font-size: 10px;
  margin-left: 5px;
  vertical-align: center;
`;
