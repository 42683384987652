import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import greenCheckmark from '../../../assets/icon_check_green.svg';
import ErrorText from '../../errortext';
import { questionType } from '../../../utils/enums';
import { makeAllUrlsValid } from '../../../utils/util';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';

export default class AttachmentInputs extends Component {
  static propTypes = {
    errorMessages: PropTypes.object.isRequired,
    role: PropTypes.object.isRequired,
    uploads: PropTypes.object.isRequired,
    uploadAttachment: PropTypes.func.isRequired,
    clearUploadAttachment: PropTypes.func.isRequired,
    addVideoLinkAnswer: PropTypes.func.isRequired,
    videoLinkAnswersByQuestionId: PropTypes.object.isRequired,
    validateVideoLinkAnswer: PropTypes.func.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object),
    jobQuestionsAndAttachmentsAndSets: PropTypes.array.isRequired,
    handleQuestionChange: PropTypes.func.isRequired,
    handleCustomAnswerChange: PropTypes.func.isRequired,
    requiredQuestionsErrors: PropTypes.array.isRequired,
    custom_answers: PropTypes.object.isRequired,
    isInternalCandidate: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.upload = {};
  }

  clickUploadInput = (id) => {
    this.upload[id].click();
  };

  uploadExists = (attachment) => {
    /** loop through both props.uploads and props.attachment to see if the relevant
     * file has already been uploaded.
     */
    let uploads = this.props.uploads;
    let keys = Object.keys(uploads);
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        let upload = uploads[keys[i]];
        if (upload.required_application_attachment === attachment.id) {
          return upload.attachment.name;
        }
      }
    }

    let attachments = this.props.attachments.filter((a) => !a.additional_document);
    if (attachments?.length > 0) {
      // Loop through attachment seeing if the title matches this row attachment
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i]?.required_application_attachment?.id === attachment.id) {
          let url = attachments[i].attachment;
          // Gets date_role_id_filename
          // Note: This doesn't really work very well. Intent was to show original
          // filename but it's hard to reverse the string, so I'm just showing our internal string
          const fileName = url.split('/').slice(-1).join('').split('?')[0];
          return fileName;
        }
      }
    }

    return null;
  };

  hideItem = (item) => {
    if (this.props.role.internal_requirements_specified) {
      return (
        (!this.props.isInternalCandidate && !item.is_visible_external) ||
        (this.props.isInternalCandidate && !item.is_visible_internal)
      );
    } else {
      return !item.is_visible_external;
    }
  };

  showRequiredAsterisk = (item) => {
    if (this.props.role.internal_requirements_specified) {
      return (
        (!this.props.isInternalCandidate && item.is_required_external) ||
        (this.props.isInternalCandidate && item.is_required_internal)
      );
    } else {
      return item.is_required_external;
    }
  };

  yesNoQuestion = (q, i) => (
    <div
      className={'application-row ' + (this.hideItem(q) ? 'hidden' : '')}
      key={i}
      title="yes_or_no_question"
    >
      <label>
        {q.prompt}
        {this.showRequiredAsterisk(q) && <RequiredAsterisk />}
      </label>
      <div className="yesno">
        <label>
          <input
            type="radio"
            name={'custom_answers' + q.id}
            value={true}
            checked={this.props.custom_answers[q.id]?.value}
            onChange={(e) => this.props.handleCustomAnswerChange(q.id, questionType.yes_no, e)}
          />{' '}
          Yes
        </label>
        <label>
          <input
            type="radio"
            name={'custom_answers' + q.id}
            value={false}
            checked={!this.props.custom_answers[q.id]?.value}
            onChange={(e) => this.props.handleCustomAnswerChange(q.id, questionType.yes_no, e)}
            required
          />{' '}
          No
        </label>
      </div>
      {this.props.requiredQuestionsErrors.indexOf(q.id) !== -1 && (
        <ErrorText message={'Required question'} />
      )}
    </div>
  );

  statementCheckboxQuestion = (q, i) => (
    <>
      <div
        className={'application-row ' + (this.hideItem(q) ? 'hidden' : '')}
        key={i}
        title="statement_checkbox_question"
      >
        <StatementCheckboxContainer>
          <StyledCheckbox
            type="checkbox"
            name={'custom_answers' + q.id}
            checked={this.props.custom_answers[q.id]?.value}
            onChange={(e) =>
              this.props.handleCustomAnswerChange(q.id, questionType.statementCheckbox, e)
            }
          />
          <CheckboxStatement>
            {q.prompt}
            {this.showRequiredAsterisk(q) && <RequiredAsterisk />}
          </CheckboxStatement>
        </StatementCheckboxContainer>
      </div>
      {this.props.requiredQuestionsErrors.indexOf(q.id) !== -1 && (
        <ErrorText message={'Please check this box to continue. This field is required.'} />
      )}
    </>
  );

  multipleChoiceQuestion = (q, i) => (
    <div
      className={'application-row ' + (this.hideItem(q) ? 'hidden' : '')}
      key={i}
      title="multiple_choice_question"
    >
      <label>
        {q.prompt}
        {this.showRequiredAsterisk(q) && <RequiredAsterisk />}
        {q.multi_select && (
          <div style={{ fontSize: '12px', marginTop: '4px' }}>{`Select as many as apply.`}</div>
        )}
      </label>
      <div className="multiple-choice">
        {q.mc_options.map((option, index) => (
          <label className="mc_option" key={index}>
            <input
              type="checkbox"
              name={'multiple_choice' + q.id}
              value={option}
              checked={this.props.custom_answers[q.id].value.includes(option)}
              onChange={(e) =>
                this.props.handleCustomAnswerChange(
                  q.id,
                  questionType.multiple_choice,
                  e,
                  q.multi_select
                )
              }
            />
            &nbsp;&nbsp;{option}
          </label>
        ))}
      </div>
      {this.props.requiredQuestionsErrors.indexOf(q.id) !== -1 && (
        <ErrorText message={'Required question'} />
      )}
    </div>
  );

  nimbleOpenResponseQuestion = (q, i) => (
    <div
      className={'open-response application-row ' + (this.hideItem(q) ? 'hidden' : '')}
      key={i}
      title="nimble_question"
    >
      <label>
        {q.question}
        {this.showRequiredAsterisk(q) && <RequiredAsterisk />}
      </label>
      <textarea
        name={'q' + i}
        placeholder="Enter response"
        value={q.answer}
        onChange={(e) => this.props.handleQuestionChange(i, e)}
      />
      {this.props.requiredQuestionsErrors.indexOf(q.id) !== -1 && (
        <ErrorText message={'Required question'} />
      )}
    </div>
  );

  directionTextQuestion = (q) => {
    // Determine whether this candidate can see this question
    if (this.hideItem(q)) {
      return null;
    }

    const createMarkup = () => ({
      __html: makeAllUrlsValid(q.prompt),
    });

    return (
      <div title="direction_text_question">
        <HorizontalLine />
        <DirectionText dangerouslySetInnerHTML={createMarkup()} />
        <HorizontalLineBottom />
      </div>
    );
  };

  // different enough from nimbleOpenResponseQuestion to justify a separate function :(
  customOpenResponseQuestion = (q, i) => (
    <div
      className={'open-response application-row ' + (this.hideItem(q) ? 'hidden' : '')}
      key={i}
      title="open_response_question"
    >
      <label>
        {q.prompt}
        {this.showRequiredAsterisk(q) && <RequiredAsterisk />}
      </label>
      <textarea
        name={'open_response'}
        placeholder="Enter response"
        value={this.props.custom_answers[q.id].value}
        onChange={(e) =>
          this.props.handleCustomAnswerChange(q.id, questionType.open_response, e, false)
        }
      />
      {this.props.requiredQuestionsErrors.indexOf(q.id) !== -1 && (
        <ErrorText message={'Required question'} />
      )}
    </div>
  );

  renderVideoLinkQuestion = (question) => {
    if (this.hideItem(question)) {
      return null;
    }
    const answer = this.props.videoLinkAnswersByQuestionId[question.id] || {};
    const { value = '', error = '' } = answer;

    return (
      <div className="additional-upload-row video-link mb1" key={question.id}>
        <label htmlFor={`video-link-input-${question.id}`}>
          <div className="video-link-question-title">
            {question.title}
            {this.showRequiredAsterisk(question) && <RequiredAsterisk />}
          </div>
          {question.description && (
            <div className="upload-description-text">{question.description}</div>
          )}
          <input
            id={`video-link-input-${question.id}`}
            name={`upload[${question.id}]`}
            className="input form-field text"
            type="text"
            placeholder="Use a youtube, vimeo, or google drive video link. Please make sure your video sharing preferences are set to public."
            value={value}
            onChange={(event) => this.props.addVideoLinkAnswer(question, event.target.value)}
            onBlur={() => this.props.validateVideoLinkAnswer(question)}
            required={this.showRequiredAsterisk(question)}
          />
          {error && <div className="teaching-video-error red-txt">{error}</div>}
        </label>
      </div>
    );
  };

  fileAttachmentInput = (attachment, i) => {
    let uploadExists = this.uploadExists(attachment);
    return (
      <div key={i} className={'application-row ' + (this.hideItem(attachment) ? 'hidden' : '')}>
        <div className="file-upload-container">
          <div className="upload-flex-container">
            {uploadExists && <img alt="icon-check" src={greenCheckmark} />}
            <div className={`file-upload-col-2 ${uploadExists ? 'ml2' : 'ml1'}`}>
              <p className="bold mbhalf">
                {attachment.title}
                {this.showRequiredAsterisk(attachment) && <RequiredAsterisk />}
              </p>
              <p className="upload-description-text mbhalf">{attachment.description}</p>
              <p className="upload-description-text">{uploadExists}</p>
            </div>
          </div>

          <input
            name={`upload[${attachment.id}]`}
            ref={(input) => (this.upload[attachment.id] = input)}
            type="file"
            style={{ height: '0px', overflow: 'hidden', margin: '0' }}
            onChange={(e) => this.props.uploadAttachment(e, attachment.id)}
          />

          <button
            type="button"
            className="upload"
            onClick={() => this.clickUploadInput(attachment.id)}
          >
            {this.uploadExists(attachment) === null ? 'Select File' : 'Update File'}
          </button>
        </div>
        <div className="upload-error-text">
          <ErrorText message={this.props.errorMessages[attachment.id]} />
        </div>
        <UploadFileHelperMessage />
      </div>
    );
  };

  renderSchoolPreferencesQuestion = (question, i) => {
    if (this.hideItem(question)) {
      return null;
    }

    // (spec) If there are no choices, hide the question
    if (this.props.schoolPreferenceQuestionChoices.length === 0) {
      return null;
    }

    const answer = this.props.schoolPreferencesAnswer;

    return (
      <div key={i} className="application-row school-preferences-question">
        <div className="title">
          School Preferences
          {this.showRequiredAsterisk(question) && <RequiredAsterisk />}
        </div>
        <div className="description">Select all schools you're interested in</div>
        <label className="italic" htmlFor="interested-in-all-opportunities">
          <input
            id="interested-in-all-opportunities"
            type="checkbox"
            checked={answer.is_interested_in_all}
            onChange={this.props.handleInterestedInAllOpportunitiesToggle}
          />
          <span>
            I'm interested in <u>all</u> opportunities, regardless of location
          </span>
        </label>
        <div className="school-preferences-question__school-choices">
          {this.props.schoolPreferenceQuestionChoices.map((school) => (
            <div key={school.id}>
              <label htmlFor={school.id}>
                <input
                  id={school.id}
                  type="checkbox"
                  checked={answer.selected_schools.includes(school.id)}
                  onChange={() => this.props.handlePreferredSchoolToggle(school.id)}
                />
                <span>{school.name}</span>
              </label>
            </div>
          ))}
        </div>
        <label className="italic" htmlFor="open-to-other-locations">
          <input
            id="open-to-other-locations"
            type="checkbox"
            checked={answer.is_open_to_other_options}
            onChange={this.props.handleOpenToOtherLocationsChange}
          />
          <span>I'm open to being contacted about opportunities at other locations</span>
        </label>
        {answer.error && <ErrorText message={answer.error} />}
      </div>
    );
  };

  render() {
    return (
      <div className="other-attachments">
        {this.props.jobQuestionsAndAttachmentsAndSets.map((item, i) => {
          if (item.question_type === questionType.videoLink) {
            return this.renderVideoLinkQuestion(item, i);
          } else if (item.question_type === questionType.attachment) {
            // type is attachment (model: RequiredApplicationAttachment)
            return this.fileAttachmentInput(item, i);
          } else if (
            item.question_type === questionType.nimble ||
            item.question_type === questionType.text_question_model
          ) {
            // type is Nimble question (model: NimbleQuestion)
            return this.nimbleOpenResponseQuestion(item, i);
          } else if (item.question_type === questionType.schoolPreferences) {
            return this.renderSchoolPreferencesQuestion(item, i);
          } else {
            // type is custom question (model: CustomQuestion)
            // custom question has an open response type, which is similar to Nimble
            // question in the conditional above this one. Only difference is those
            // questions are provided by Nimble, whereas these are created by a user.
            if (item.question_type === questionType.open_response) {
              return this.customOpenResponseQuestion(item, i);
            } else if (item.question_type === questionType.multiple_choice) {
              return this.multipleChoiceQuestion(item, i);
            } else if (item.question_type === questionType.direction_text) {
              return this.directionTextQuestion(item, i);
            } else if (item.question_type === questionType.statementCheckbox) {
              return this.statementCheckboxQuestion(item, i);
            } else {
              return this.yesNoQuestion(item, i);
            }
          }
        })}
      </div>
    );
  }
}

const RequiredAsterisk = () => {
  return <span style={{ color: 'red' }}>*</span>;
};

const StatementCheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;

  margin: 4rem 0;
`;

const StyledCheckbox = styled.input`
  width: unset;
  margin-top: 3px;
`;

const CheckboxStatement = styled.i`
  font-size: 15px;
  text-align: justify;
  font-weight: 400;
`;

const DirectionText = styled.div`
  & > ul {
    margin-left: 20px;
  }
`;

const HorizontalLine = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HorizontalLineBottom = styled(HorizontalLine)`
  margin-bottom: 28px;
`;
