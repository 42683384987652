import styled from 'styled-components';

export default function Checkbox({ text, isActive, onClick }) {
  return (
    <Container onClick={onClick}>
      <CheckboxOuter active={isActive}>{isActive && <CheckboxInner />}</CheckboxOuter>
      &nbsp;&nbsp;{text}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
`;

const CheckboxOuter = styled.div`
  position: relative;
  width: 28px;
  height: 28px;

  border-radius: 50%;

  background-color: ${(props) => (props.active ? 'rgba(0, 184, 141, 0.05)' : '#FFFFFF')};
  border: 2px solid ${(props) => (props.active ? '#00B88D' : '#C4C4C4')};
`;

const CheckboxInner = styled.div`
  position: absolute;
  top: 30.5%;
  left: 30.8%;
  width: 10px;
  height: 10px;

  border-radius: 50%;

  background-color: #00b88d;
`;
