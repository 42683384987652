import { BaseNimbleAPI } from './baseAPI';

class JobActivityFeedAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/job-activities/' };
  }

  async getAllActivity(roleId) {
    const url = `/${roleId}/`;
    const response = await this.get(url);
    return response.data;
  }
}

export default new JobActivityFeedAPI();
