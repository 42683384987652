import { Container, CTA } from './styles';
import { SettingsRowProps } from './types';

export const SettingsRow: React.FC<SettingsRowProps> = ({
  children,
  groupCTA,
  onClick,
  sx,
  cta,
}) => {
  return (
    <Container sx={sx} onClick={onClick} data-testid="settings-row">
      {children}
      <CTA>{groupCTA ? groupCTA : cta}</CTA>
    </Container>
  );
};
