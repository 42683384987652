import { Unstable_Grid2 as Grid } from '@mui/material';
import { GridItemProps } from './types';

export const GridItem: React.FC<GridItemProps> = ({ children, xs, sm, md, lg, xl, sx }) => {
  return (
    <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl} sx={sx}>
      {children}
    </Grid>
  );
};
