import { useState } from 'react';
import styled from 'styled-components';
import { DataDashboardContainerV2 } from '../components/V2Components/DataDashboardContainerV2';
import { DashboardBaseChartV2 } from '../components/V2Components/DashboardBaseChartV2';
import {
  searchBarPlaceholders,
  stateDashboardSegmentTypes,
  stateDashboardChartTypes,
} from 'utils/enums';

export default function VacancyCharacteristics() {
  const stateCode = 'IN';
  const [districtsSelected, setDistrictsSelected] = useState([]);
  const [hiringSeasonsSelected, setHiringSeasonsSelected] = useState([]);
  const [jobSubcategoriesSelected, setJobSubcategoriesSelected] = useState([]);
  const [vacancyStatusesSelected, setVacancyStatusesSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  return (
    <>
      <DataDashboardContainerV2
        title={'Vacancy Characteristics'}
        stateCode={stateCode}
        setDistrictsSelected={setDistrictsSelected}
        setHiringSeasonsSelected={setHiringSeasonsSelected}
        setJobSubcategoriesSelected={setJobSubcategoriesSelected}
        setVacancyStatusesSelected={setVacancyStatusesSelected}
        showJobSubcategoriesDropdown={true}
        showVacancyStatusDropdown={true}
        vacancyCount={totalCount}
      />
      <DashboardContainer>
        <DashboardBaseChartV2
          title="Job Category"
          useVacancyAsTotal={true}
          chartType={stateDashboardChartTypes.bar}
          XAxisLabel={''}
          showFilter={false}
          showDropdown={false}
          defaultGroupBySelected={stateDashboardSegmentTypes.subcategory.value}
          groupByOptions={[stateDashboardSegmentTypes.subcategory]}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          vacancyStatusesSelected={vacancyStatusesSelected}
          stateCode={stateCode}
          graphId={0}
          legendOnLeft={false}
          searchBarPlaceholders={searchBarPlaceholders}
          showGroupedToggleInHeader={true}
          isSingleColumned={false}
          setTotalCount={setTotalCount}
          titleTooltip={true}
          titleTooltipText={`This chart is displaying the number of <br/>
          vacancies by job category.`}
        />
      </DashboardContainer>
      <DashboardContainer>
        <DashboardBaseChartV2
          title="Employment Type"
          useVacancyAsTotal={true}
          chartType={stateDashboardChartTypes.pie}
          XAxisLabel={''}
          groupByOptions={[stateDashboardSegmentTypes.employmentType]}
          defaultGroupBySelected={stateDashboardSegmentTypes.employmentType.value}
          hiringSeasonsSelected={hiringSeasonsSelected}
          districtsSelected={districtsSelected}
          jobSubcategoriesSelected={jobSubcategoriesSelected}
          vacancyStatusesSelected={vacancyStatusesSelected}
          showDropdown={false}
          stateCode={stateCode}
          graphId={1}
          searchBarPlaceholders={searchBarPlaceholders}
          legendOnLeft={true}
          isSingleColumned={false}
          vacancyByEmploymentTypeLegend={true}
          titleTooltip={true}
          titleTooltipText={`This chart is displaying the breakdown <br/>
          of vacancies by employment type.`}
        />
      </DashboardContainer>
    </>
  );
}

const DashboardContainer = styled.div`
  display: flex;
  gap: 15px;
  max-width: 1160px;
  margin: 10px auto 10px;
  padding: 0 30px;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;
