import styled from 'styled-components';
import { grid, layout } from 'styled-system';
import PropTypes from 'prop-types';

import { AltModal } from 'ui-kit';

export default function HelloSignIFrameBodyAndFooter({ onBack, onHide }) {
  return (
    <>
      <IFrameEntry id="hellosign-iframe-entry" />
      <Footer gridTemplateColumns={['1fr', '1fr', '186px 186px']}>
        <EditButton onClick={onBack} width={[1, 1, '184px']}>
          Back to Previous
        </EditButton>
        <CancelButton onClick={onHide} width={[1, 1, '184px']}>
          Cancel all form(s)
        </CancelButton>
      </Footer>
    </>
  );
}

HelloSignIFrameBodyAndFooter.propTypes = {
  onBack: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

const IFrameEntry = styled.div`
  height: 650px;
  grid-column: 1 / span 4;
  grid-row: 3 / span 1;
  min-width: 0;
  margin: 0 1px 0 0;
`;

const Footer = styled(AltModal.Actions)`
  ${grid}

  display: grid;
  align-items: center;
`;

const Button = styled.button`
  ${layout}

  height: 50px;

  font-weight: 600;
  font-size: 15px;

  border-radius: 3px;
`;

const EditButton = styled(Button)`
  color: #a6a7ad;
  border: 1px solid #a6a7ad;
  background-color: #fff;
`;

const CancelButton = styled(Button)`
  color: #a6a7ad;
  border: none;
  background-color: transparent;
`;
