import { Component } from 'react';
import axios from 'axios';

import ResetPassword from '../components/ResetPassword/';
import { withRouter } from 'react-router-dom';

class UserUpdatePasswordContainer extends Component {
  constructor(props) {
    super(props);

    document.body.className += ` auth-pages`;
  }

  componentWillUnmount() {
    let newClasses = document.body.className.replace('auth-pages', '');
    document.body.className = newClasses;
  }

  resetPassword = (password, onSuccess, onError) => {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const username = searchParams.get('username');
    const signature = searchParams.get('signature');

    const data = {
      password,
      username,
      signature,
    };
    axios
      .put('/api/reset_password/', data)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error.response);
      });
  };

  render() {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const username = searchParams.get('username');
    const signature = searchParams.get('signature');
    const isConnect = Boolean(searchParams.get('is_connect'));

    // if query params don't exist, don't display the form //
    if (!username || !signature) {
      return null;
    }
    return <ResetPassword resetPassword={this.resetPassword} isConnect={isConnect} />;
  }
}

export default withRouter(UserUpdatePasswordContainer);
