import PropTypes from 'prop-types';
import { StyledSelect } from 'ui-kit';

function ApplicableToSection(props) {
  const districtSectionDisabled = !!props.editingVacancy;
  const schoolSectionDisabled =
    props.editingVacancy && props.vacancyApplicableToEntireDistrict === true;

  return (
    <div className="vacancy-modal-section applicable-to-container">
      <h4 className="vacancy-modal-section-header">This position is applicable to:</h4>
      <label className={`applicable-to ${districtSectionDisabled ? 'disabled-hover' : 'pointer'}`}>
        <input
          type="radio"
          className="pointer mr1"
          name="vacancyApplicableToEntireDistrict"
          value={true}
          checked={props.vacancyApplicableToEntireDistrict === true}
          onChange={props.handleChange}
          disabled={districtSectionDisabled}
        />
        Entire District
      </label>
      <label className={`applicable-to ${schoolSectionDisabled ? 'disabled-hover' : 'pointer'}`}>
        <input
          type="radio"
          className="pointer mr1"
          name="vacancyApplicableToEntireDistrict"
          value={false}
          checked={props.vacancyApplicableToEntireDistrict === false}
          onChange={props.handleChange}
          disabled={schoolSectionDisabled}
        />
        Single School
      </label>
      {props.vacancyApplicableToEntireDistrict === false && (
        <StyledSelect
          boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
          options={props.schoolOptions}
          defaultValue={props.schoolOptions.filter(
            (option) => props.vacancySchoolId === option.value
          )}
          onChange={(selectedObject) => props.handleSelectChange(selectedObject, 'vacancySchoolId')}
          placeholder="Search Schools by Name"
          isClearable
        />
      )}
    </div>
  );
}

ApplicableToSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  vacancyApplicableToEntireDistrict: PropTypes.bool.isRequired,
  schoolOptions: PropTypes.array.isRequired,
  vacancySchoolId: PropTypes.number,
  handleSelectChange: PropTypes.func.isRequired,
  editingVacancy: PropTypes.bool,
};

export default ApplicableToSection;
