import styled from 'styled-components';

export const statusColors = {
  'green-2': '#008060',
  'archived-red': '#EF5675',
  'active-closed-yellow': '#FFA601',
  'draft-grey': '#cfcfcf',
  'needs-approval-blue': '#304B7D',
};

type JobRowContainerProps = {
  smallerLayout?: boolean;
};

export const JobRowContainer = styled.div<JobRowContainerProps>`
  display: grid;
  grid-template-columns: 16px 2fr 1fr;
  grid-gap: 0.5rem;
  align-items: center;

  margin: 0 auto;
  padding-left: 1rem;
  font-size: 13px;
  color: #777;
  height: 50px;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  cursor: pointer;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;

  div:last-child > .quick-actions {
    opacity: 0;
  }

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);

    div:last-child > .quick-actions {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }

  @media screen and (min-width: 990px) {
    // don't show featured icon or hover icons in smallerLayout (school admins / district users)
    grid-template-columns: ${({ smallerLayout }) =>
      smallerLayout
        ? `
      minmax(170px, 275px) 130px minmax(100px, 135px) 100px 100px minmax(276px, 1fr)
    `
        : `
      16px minmax(170px, 275px) 130px minmax(100px, 135px) 100px 100px minmax(225px, 1fr)
    `};
  }
`;

export const TextEllipsisContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

TextEllipsisContainer.displayName = 'TextEllipsisContainer';

type StatusSectionProps = {
  statusColor?: string;
};

export const StatusSection = styled.div<StatusSectionProps>`
  font-size: 15px;
  width: fit-content;
  color: ${({ statusColor }) => statusColors[statusColor]};
`;

StatusSection.displayName = 'StatusSection';

export const Subtext = styled.p`
  font-size: 11px;
  color: #bbb;
`;

export const IconContainer = styled.div`
  padding-bottom: 2px;
`;

IconContainer.displayName = 'IconContainer';

export const HideOnMobile = styled.div`
  display: none;

  @media screen and (min-width: 990px) {
    display: block;
  }
`;
