import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getQuestionCounts } from '../../utils/scorecardutils';
import { referencePermissionMap } from 'utils/enums';
import { useHistory } from 'react-router-dom';
import { ViewButton } from 'sharedComponents/Buttons';
import { SettingsRow } from 'components/SettingsRow';
import { Checkbox } from '@mui/material';

const CustomReferenceRow = ({ reference, isDefaultReference, onDefaultReferenceChange }) => {
  const history = useHistory();

  return (
    <SettingsRow
      cta={<ViewButton />}
      onClick={() => history.push(`/editreference/${reference.id}/`)}
    >
      <ReferenceTitle>{reference.title}</ReferenceTitle>
      <QuestionType>{getQuestionCounts(reference.questions)}</QuestionType>
      <LighterTextSection>{referencePermissionMap[reference.view_permissions]}</LighterTextSection>
      <MyDefaultSection onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={isDefaultReference}
          onChange={(e) => onDefaultReferenceChange(e.target.checked)}
        />
      </MyDefaultSection>
    </SettingsRow>
  );
};

export default CustomReferenceRow;

CustomReferenceRow.propTypes = {
  reference: PropTypes.object.isRequired,
  isDefaultReference: PropTypes.bool.isRequired,
  onDefaultReferenceChange: PropTypes.func.isRequired,
};

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LighterTextSection = styled(Section)`
  color: #909090;
  flex: 0 0 19%;
`;

const ReferenceTitle = styled(Section)`
  flex: 0 0 32%;
  font-size: 15px;
`;

const QuestionType = styled(LighterTextSection)`
  flex: 0 0 25%;
`;

const MyDefaultSection = styled.div`
  cursor: pointer;

  flex: 0 0 14%;
`;
