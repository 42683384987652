import styled from 'styled-components';
import PropTypes from 'prop-types';

import { scorecardQType } from '../../../utils/enums';

import TextAnswer from '../../ReferenceModal/TextAnswer';
import MultipleChoiceAnswer from '../../ReferenceModal/MultipleChoiceAnswer';
import RubricAnswer from '../../ReferenceModal/RubricAnswer';
import CumulativeScoreAnswer from '../../ReferenceModal/CumulativeScoreAnswer';
import FinalRecommendationAnswer from '../../ReferenceModal/FinalRecommendationAnswer';
import DirectionsAnswer from '../../ReferenceModal/DirectionsAnswer';
import RatingAnswer from '../../ReferenceModal/RatingAnswer';
import AttachmentAnswer from '../../ReferenceModal/AttachmentAnswer';
import { ATSCandidateScorecardDataTestIds } from 'data-testids/ATS';

export default function ScorecardEditorQuestions({
  scorecard,
  updateField,
  updateFinalRecommendation,
  updateMultipleChoiceField,
  updateRating,
  updateRubricSelection,
  adminUsers,
  uploadAttachment,
  removeAttachment,
}) {
  const renderQuestion = (answer) => {
    if (
      answer.question_type === scorecardQType.rubric ||
      answer.question_type === scorecardQType.direction_text
    ) {
      // rubric and direction answers have their own question formats
      return;
    }
    let question = answer.question_text;
    if (answer.question_type === scorecardQType.cumulative_score) {
      question = 'Cumulative score:';
    }
    if (answer.question_type === scorecardQType.final_recommendation) {
      question = 'Do you recommend this candidate?';
    }

    return (
      <QuestionText
        data-testid={ATSCandidateScorecardDataTestIds.SCORECARD_EDITOR_QUESTION_TEXT}
      >{`${question} ${answer.is_required ? '*' : ''}`}</QuestionText>
    );
  };

  const renderAnswerSection = (answer, index) => {
    switch (answer.question_type) {
      case scorecardQType.rating:
        return (
          <RatingAnswer
            answer={answer}
            index={index}
            updateRating={updateRating}
            dataTestId={ATSCandidateScorecardDataTestIds.RATING_ANSWER_INPUT}
            scaleDataTestId={ATSCandidateScorecardDataTestIds.RATING_ANSWER_SCALE}
          />
        );
      case scorecardQType.nimble:
      case scorecardQType.text:
        return (
          <TextAnswer
            adminUsers={adminUsers}
            answer={answer}
            updateField={updateField}
            index={index}
            mentionsInputDataTestId={ATSCandidateScorecardDataTestIds.TEXT_ANSWER_MENTIONS_INPUT}
          />
        );
      case scorecardQType.multiple_choice:
        return (
          <MultipleChoiceAnswer
            answer={answer}
            updateMultipleChoiceField={updateMultipleChoiceField}
            index={index}
            optionDataTestId={ATSCandidateScorecardDataTestIds.MULTIPLE_CHOICE_ANSWER_OPTION}
          />
        );
      case scorecardQType.cumulative_score:
        return (
          <CumulativeScoreAnswer
            parentObject={scorecard}
            dataTestId={ATSCandidateScorecardDataTestIds.CUMULATIVE_SCORE}
          />
        );
      case scorecardQType.direction_text:
        return <DirectionsAnswer answer={answer} />;
      case scorecardQType.final_recommendation:
        return (
          <FinalRecommendationAnswer
            answer={answer}
            parentObject={scorecard}
            updateFinalRecommendation={updateFinalRecommendation}
            optionDataTestId={ATSCandidateScorecardDataTestIds.FINAL_RECOMMENDATION_ANSWER_OPTION}
            subtextDataTestId={ATSCandidateScorecardDataTestIds.FINAL_RECOMMENDATION_ANSWER_SUBTEXT}
          />
        );
      case scorecardQType.rubric:
        return (
          <RubricAnswer
            answer={answer}
            updateRubricSelection={updateRubricSelection}
            index={index}
          />
        );
      case scorecardQType.attachment:
        return (
          <AttachmentAnswer
            answer={answer}
            uploadAttachment={uploadAttachment}
            removeAttachment={removeAttachment}
            index={index}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      {scorecard.answers.map((answer, index) => (
        <AnswerItem key={index}>
          {renderQuestion(answer)}
          {renderAnswerSection(answer, index)}
        </AnswerItem>
      ))}
    </>
  );
}

ScorecardEditorQuestions.propTypes = {
  scorecard: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  updateFinalRecommendation: PropTypes.func.isRequired,
  updateMultipleChoiceField: PropTypes.func.isRequired,
  updateRating: PropTypes.func.isRequired,
  updateRubricSelection: PropTypes.func.isRequired,
  // adminUsers not sent down in submitscorecard flow
  adminUsers: PropTypes.array,
  uploadAttachment: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

const QuestionText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
`;

const AnswerItem = styled.div`
  margin-bottom: 50px;
`;
