import { useContext } from 'react';
import {
  CurrentUserPermissionsContext,
  CurrentUserPermissionsContextValue,
} from '../containers/CurrentUserPermissionsProvider';

const useCurrentUserPermissions = (): CurrentUserPermissionsContextValue =>
  useContext(CurrentUserPermissionsContext);

export default useCurrentUserPermissions;
