import { Component } from 'react';
import PropTypes from 'prop-types';
import auth from '../../../../utils/auth';
// Sub components
import VacancyRow from '../VacancyRow';

export default class VacanciesList extends Component {
  static propTypes = {
    vacancies: PropTypes.arrayOf(PropTypes.object),
    unplacedCandidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeCandidateFromVacancy: PropTypes.func.isRequired,
    deleteVacancy: PropTypes.func.isRequired,
    pairCandidateWithVacancy: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object),
    updateVacancyInContainerState: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    roleOptions: PropTypes.array.isRequired,
    schoolOptions: PropTypes.array.isRequired,
    putVacancy: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div
        className={`vacancies-list-container ${
          auth.isSchoolUser() || auth.isDistrictUserOnly() ? 'not-active' : ''
        }`}
      >
        <div className="list-header list-item vacancies-headers">
          <div className="vacancy-job-posting pointer" onClick={() => this.props.sortBy('role')}>
            Job Posting <span className="glyphicon glyphicon-menu-down sort" />
          </div>

          <div className="vacancy-school pointer" onClick={() => this.props.sortBy('school')}>
            School <span className="glyphicon glyphicon-menu-down sort" />
          </div>

          <div className="vacancy-category">Category</div>

          <div className="vacancy-fte pointer" onClick={() => this.props.sortBy('ftes')}>
            FTE <span className="glyphicon glyphicon-menu-down sort" />
          </div>

          <div className="vacancy-candidate pointer" onClick={() => this.props.sortBy('candidate')}>
            Candidate <span className="glyphicon glyphicon-menu-down sort" />
          </div>
        </div>

        <div className="vacancies-list">
          {this.props.vacancies.map((vacancy, index) => {
            return (
              <VacancyRow
                vacancy={vacancy}
                unplacedCandidates={this.props.unplacedCandidates}
                key={index}
                removeCandidateFromVacancy={this.props.removeCandidateFromVacancy}
                pairCandidateWithVacancy={this.props.pairCandidateWithVacancy}
                adminUsers={this.props.adminUsers}
                updateVacancyInContainerState={this.props.updateVacancyInContainerState}
                roleOptions={this.props.roleOptions}
                schoolOptions={this.props.schoolOptions}
                putVacancy={this.props.putVacancy}
                deleteVacancy={this.props.deleteVacancy}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
