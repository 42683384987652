export const acceptedUserProfileFieldAttributes = [
  {
    value: 'address_apt',
    label: 'Apt #',
  },
  {
    value: 'avg_employment_gap',
    label: 'Avg Employment Gap',
  },
  {
    value: 'birthday',
    label: 'Birthday',
  },
  {
    value: 'phone_cell',
    label: 'Cell Phone',
  },
  {
    value: 'address_city',
    label: 'City',
  },
  {
    value: 'address_country',
    label: 'Country',
  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  // {
  //   value: 'credential_subjects',
  //   label: 'Credential Subjects',
  // },
  {
    value: 'education_degrees',
    label: 'Degrees',
  },
  {
    value: 'el_authorization',
    label: 'El Authorization',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'ever_teacher',
    label: 'Ever Teacher',
  },
  {
    value: 'exams_taken',
    label: 'Exams Taken',
  },
  {
    value: 'first_name',
    label: 'First Name',
  },
  {
    value: 'internal_source',
    label: 'Source',
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'has_advanced_degree',
    label: 'Has Advanced Degree',
  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  // {
  //   value: 'has_answers',
  //   label: 'Has Answers',
  // },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'has_ged',
  //    label: 'Has GED',
  //  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'has_no_certificates',
  //    label: 'Has No Certificates',
  //  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'has_no_credentials',
  //    label: 'Has No Credentials',
  //  },
  {
    value: 'phone_work',
    label: 'Home Phone',
  },
  {
    value: 'language_skills',
    label: 'Language Skills',
  },
  {
    value: 'last_name',
    label: 'Last Name',
  },
  {
    value: 'middle_name',
    label: 'Middle Name',
  },
  {
    value: 'other_certificate_explanation',
    label: 'Other Certificate Explanation',
  },
  {
    value: 'race_ethnicity',
    label: 'Race / Ethnicity',
  },
  {
    value: 'ssn',
    label: 'SSN',
  },
  {
    value: 'address_state',
    label: 'State',
  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'states_lived_in',
  //    label: 'States Lived In',
  //  },
  {
    value: 'address_street',
    label: 'Street',
  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'tenure_history',
  //    label: 'Tenure History',
  //  },
  {
    value: 'twitter_handle',
    label: 'X / Twitter',
  },

  {
    value: 'website',
    label: 'Website',
  },
  {
    value: 'years_experience',
    label: 'Years of Experience',
  },
  {
    value: 'address_zip',
    label: 'Zip Code',
  },
  {
    value: 'id',
    label: 'Nimble ID',
  },
];

export const dataSourceOptions = [
  {
    value: 'application',
    label: 'Application',
  },
  {
    value: 'application_status_date',
    label: 'Application Status Date',
  },
  {
    value: 'hellosign',
    label: 'Candidate Form',
  },
  {
    value: 'custom_profile_field',
    label: 'Custom Profile Field',
  },
  {
    value: 'date_sent',
    label: 'Date Sent',
  },
  {
    value: 'district_application_role',
    label: 'District Role',
  },
  {
    value: 'constant_value',
    label: 'Hard Coded Value',
  },
  {
    value: 'user_profile',
    label: 'User Profile Field',
  },
];

export const dataFormatOptions = [
  {
    value: 'date_mmddyyyy',
    label: 'Date (mmddyyyy)',
  },
  {
    value: 'date_yyyymmdd',
    label: 'Date (yyyymmdd)',
  },
  {
    value: 'hellosign_date_to_year',
    label: 'Date to Year',
  },
  {
    value: 'integer',
    label: 'Integer',
  },
  {
    value: 'percent',
    label: 'Percent',
  },
  {
    value: 'phone_us_no_country_code',
    label: 'Phone (no country code)',
  },
  {
    value: 'phone_us_no_country_code_no_hyphen',
    label: 'Phone (No country code, no hyphen)',
  },
  {
    value: 'remove_commas',
    label: 'Remove Commas',
  },
  {
    value: 'uppercase',
    label: 'Uppercase',
  },
  {
    value: 'year',
    label: 'Year',
  },
];

export const acceptedDistrictApplicationRoleFields = [
  {
    value: 'active_status',
    label: 'Job Active Status',
  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'categories',
  //    label: 'Job Categories',
  //  },
  {
    value: 'created',
    label: 'Job Date Created',
  },
  {
    value: 'deadline',
    label: 'Job Deadline',
  },
  {
    value: 'description',
    label: 'Job Description',
  },
  {
    value: 'hiring_season',
    label: 'Job Hiring Season',
  },
  {
    value: 'hiring_season_humanized',
    label: 'Job Hiring Season (SS YY-YY)',
  },
  {
    value: 'id',
    label: 'Job ID',
  },
  {
    value: 'updated',
    label: 'Job Last Updated Date',
  },
  {
    value: 'requisition_number',
    label: 'Job Requisition Number',
  },
  {
    value: 'salary_max',
    label: 'Job Salary Maximum',
  },
  {
    value: 'salary_min',
    label: 'Job Salary Minimum',
  },
  {
    value: 'status',
    label: 'Job Status',
  },
  {
    value: 'start_date',
    label: 'Job Start Date',
  },
  {
    value: 'title',
    label: 'Job Title',
  },
  {
    value: 'subcategory_label',
    label: 'Subcategory Labels',
  },
  {
    value: 'subcategory_id',
    label: 'Subcategory IDs',
  },
  //  Hide this field for now. See https://hirenimble.atlassian.net/browse/NIM-1997
  //  {
  //    value: 'subjects',
  //    label: 'Subjects',
  //  },
];

export const applicationFieldOptions = [
  {
    value: 'new_status',
    label: 'Application Status',
  },
  {
    value: 'type',
    label: 'Application Type',
  },
  {
    value: 'date_submitted',
    label: 'Date Submitted (District Application)',
  },
  {
    value: 'munis_send_date',
    label: 'Export Date',
  },
  {
    value: 'updated',
    label: 'Last Updated',
  },
  {
    value: 'prior_district_employee',
    label: 'Prior District Employee',
  },
  {
    value: 'school_name',
    label: 'School Name',
  },
  {
    value: 'source_label',
    label: 'Source',
  },
  {
    value: 'source_value',
    label: 'Source (Internal Name)',
  },
];
