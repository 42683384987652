import useSWR from 'swr';
import axios from 'axios';

const KEY = '/api/scores/top-candidates/';
const fetcher = () => axios.get(KEY).then((response) => response.data);

export default function useTopCandidates() {
  const { data } = useSWR(KEY, fetcher);

  const isLoading = data === undefined;

  return { data, isLoading, KEY };
}
