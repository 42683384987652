import styled from 'styled-components';

import { finalRecommendation } from 'utils/enums';

export default function FinalRecommendationViewAnswer({ answer, parentObject }) {
  const options = parentObject.include_maybe_option
    ? finalRecommendation
    : finalRecommendation.filter((item) => item.value !== 'final_recommendation/maybe');

  return (
    <>
      <Subtext>{answer.question_text}</Subtext>
      <FlexContainer>
        {options.map((item) => (
          <OptionContainer
            key={item.value}
            selected={parentObject.final_recommendation === item.value}
          >
            <LabelText>{item.label}</LabelText>
          </OptionContainer>
        ))}
      </FlexContainer>
    </>
  );
}

const FlexContainer = styled.div`
  display: flex;
`;

const OptionContainer = styled.div`
  width: 75px;
  height: 48px;

  border: 1px solid ${(props) => (props.selected ? '#00B88D' : '#D7D7D7')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0954484);
  border-radius: 3px;

  font-weight: bold;
  font-size: 14px;

  text-align: center;

  color: ${(props) => (props.selected ? '#00B88D' : '#999999')};

  mix-blend-mode: normal;
`;

const Subtext = styled.p`
  margin-bottom: 12px;
`;

const LabelText = styled.p`
  line-height: 48px;
  opacity: 0.4;
`;
