import './schoolaccount.css';

import { useEffect, useState } from 'react';

import auth from 'utils/auth';
import schoolsAPI from 'api/schoolsAPI';

const SchoolAccount: React.FC = () => {
  const [school, setSchool] = useState(null);

  useEffect(() => {
    schoolsAPI.fetchSchool(auth.getUser()?.profile.school.id).then((schoolToSet) => {
      return setSchool(schoolToSet);
    });
  }, []);

  return (
    <div
      className="flex mt2"
      style={{
        maxWidth: '1200px',
        padding: '0 40px 0 12px',
        margin: 'auto',
        marginTop: '2rem',
      }}
    >
      <div className="flex-2 ml2">
        <div className="card no-padding district-header">
          <div>
            <h3 className="mb1">{school?.name}</h3>
            <h4>{school?.location}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolAccount;
