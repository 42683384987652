import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import useSWR, { mutate } from 'swr';
import axios, { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { MentionsInput, Mention, SuggestionDataItem, OnAddHandlerFunc } from 'react-mentions';

import auth from 'utils/auth';
import mentionStylesAlternate from 'components/Profile/InternalCandidateNotes/mentionStylesAlternate.js';
import LoadingSpinner from 'components/loadingSpinner';
import { User } from 'types/types';
import { CandidateNotesProps, NewNote, Tag } from './types';
import { InternalCandidateNote } from '../MarketplaceProspectQuickView/types';
import { fetcherFn } from 'swr/dist/types';

const fetcher: fetcherFn<InternalCandidateNote[]> = (url: string) =>
  axios.get(url).then(({ data }) => data);

const CandidateNotes: React.FC<CandidateNotesProps> = ({
  candidate,
  application,
  onClose,
  adminUsers,
  index,
  incrementNoteCount,
}) => {
  const node = useRef<HTMLDivElement>();
  const [text, setText] = useState<string>('');
  const [taggedUsers, setTaggedUsers] = useState<User[]>([]);

  const handleOutsideClick = useCallback(
    (e: MouseEvent): void => {
      // If the user clicks inside this component (node.current), do nothing.
      // If they click outside, close this component.
      if (node.current.contains(e.target as Node)) {
        // inside click
        return;
      }

      // outside click
      onClose();

      // Prevent clicks from propagating beyond this component when it's open.
      e.stopPropagation();
      e.stopImmediatePropagation();
    },
    [onClose]
  );

  useEffect(() => {
    // add listener when mounted to close this component by clicking
    // outside of it
    document.addEventListener('click', handleOutsideClick, { capture: true });

    return () => {
      // remove the listener when the component closes
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [handleOutsideClick]);

  const key = `/api/user/${candidate.id}/internal_notes`;
  const { data } = useSWR<InternalCandidateNote[]>(key, fetcher);

  const addNote = (): void => {
    /** Check for removed tags then continue with the note submission. */
    const regExp = /@\[(.*?)\]/g;
    const finalUserList = text.match(regExp) || [];

    if (taggedUsers.length !== finalUserList.length) {
      // if these lengths are different, a tag was removed. Search
      // for the user(s) not present in finalUserList, and remove.
      let i = taggedUsers.length;
      while (i--) {
        const fullName = `${taggedUsers[i].first_name} ${taggedUsers[i].last_name}`;
        // substring below changes '@[First Name]' to 'First name', for comparison with fullName
        if (!finalUserList.find((item) => item.substring(2, item.length - 1) === fullName)) {
          taggedUsers.splice(i, 1);
        }
      }
    }

    const requestUser: User = auth.getUser();
    const created_by = `${requestUser.first_name} ${requestUser.last_name}`;
    const created_at = new Date(moment.now());
    const newNote: NewNote = { text, created_by, created_at, author_id: requestUser.id };

    submit(newNote, taggedUsers, text);
  };

  const renderUserSuggestion = (
    _suggestion: SuggestionDataItem,
    _search: string,
    highlightedDisplay: ReactNode
  ): JSX.Element => {
    return <div>{highlightedDisplay}</div>;
  };

  const onAdd: OnAddHandlerFunc = (id) => {
    /** callback function that runs when a user is tagged. Add tagged user to state, and email that list
     *  when the note is published (which happens in the addNote function).
     */
    const user = adminUsers.find((admin) => admin.id === id);
    // don't tag same user multiple times, because that will send multiple emails
    if (taggedUsers.map((u) => u.id).includes(id as number)) {
      return;
    }

    taggedUsers.push(user as User);
    setTaggedUsers(taggedUsers);
  };

  const submit = (newNote: NewNote, recipientList: User[], message: string): void => {
    const originalData: InternalCandidateNote[] = [...data];
    const newData: (NewNote | InternalCandidateNote)[] = [newNote, ...data];

    // update the local data immediately, but disable the revalidation
    mutate(key, newData, false);
    setText('');
    setTaggedUsers([]);

    axios
      .patch(`/api/user/${candidate.id}/`, { internalCandidateNote: newNote })
      .then(() => {
        emailTaggedUsers(recipientList, message);
        incrementNoteCount(candidate.id);
      })
      .catch((err) => {
        // on error, trigger revalidation by passing true
        mutate(key, originalData, true);
        throw new Error(err);
      });
  };

  const emailTaggedUsers = (recipientList: User[], message: string): void => {
    const tagObjects: Tag[] = [];

    recipientList.forEach((recipient) => {
      const url = getUrl(recipient);
      const tagObj = {
        user_id: recipient.id,
        location: `in a note`,
        note_url: url,
        note_copy: message,
        candidate_name: candidate.name,
      };
      tagObjects.push(tagObj);
    });

    sendTagNotifications(tagObjects);
  };

  const getUrl = (recipient: User): string => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;

    const recipientUrlPrefix = `${recipient.profile.user_type >= 30 ? 'district' : 'school'}`;

    // hacky, but the best (and maybe only?) way to tell if we're linking to a district
    // application or school application is to check the district_role property.
    if (application.role.district_role) {
      // viewing a schoolapplication, so must be school admin
      return `${location}/school/school-profile/${candidate.id}?s_pk=${application.id}`;
    } else {
      // viewing a district application, can be any user type other than candidate
      return `${location}/${recipientUrlPrefix}/profile/${candidate.id}?application=${application.id}`;
    }
  };

  const sendTagNotifications = (tagObjects: Tag[]): Promise<AxiosResponse> => {
    return axios.post('/api/send_tag_notifications/', tagObjects);
  };

  if (data === undefined) {
    return (
      <Container index={index} ref={node}>
        <LoadingSpinner margin={7} />;
      </Container>
    );
  }

  return (
    <Container onClick={(e) => e.stopPropagation()} index={index} ref={node}>
      <ExistingNotes isEmpty={data.length === 0}>
        {data.map((note) => (
          <div className="note-textarea mb1" key={note.id}>
            <div className="note-textarea bold">{note.created_by}</div>
            <div className="note-textarea sub-text">
              {moment(note['created_at']).format('MM/D/YYYY, h:mm a')}
            </div>
            <div>{note.text}</div>
          </div>
        ))}
      </ExistingNotes>

      <MentionsInput
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={"Mention people using '@'"}
        style={mentionStylesAlternate}
        markup="@[__display__]"
        displayTransform={(_id: string, display: string) => `@${display}`}
        className="note-textarea clickable"
        autoFocus={true}
      >
        <Mention
          trigger="@"
          style={{ backgroundColor: 'var(--gray)' }}
          data={adminUsers}
          renderSuggestion={renderUserSuggestion}
          onAdd={onAdd}
          className="clickable"
          appendSpaceOnAdd={true}
        />
      </MentionsInput>

      <ButtonContainer>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton onClick={addNote} isDisabled={text.length === 0} disabled={text.length === 0}>
          Submit
        </SubmitButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div<{ index: number }>`
  position: absolute;
  background-color: #fff;
  padding: 20px;
  z-index: 10;
  width: 350px;
  max-height: 420px;
  ${({ index }) => (index > 7 ? 'bottom' : 'top')}: 0px;
  right: 21px;

  border: 1px solid #cccccc;
  box-shadow: 0px 3px 3px 0px rgb(204 204 204 / 0.75);
  color: #161616;

  cursor: auto;
`;

const ExistingNotes = styled.div<{ isEmpty: boolean }>`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d6d6d6;
  max-height: 226px;
  overflow: auto;

  ${({ isEmpty }) => (isEmpty ? 'display: none;' : '')}
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

const Button = styled.button`
  width: 100$;
  height: 34px;
  background-color: #cacaca;
  padding: 5px 20px;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 3px;
  color: #ffffff;
`;

const SubmitButton = styled(Button)<{ isDisabled: boolean }>`
  background-color: #00b88d;

  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5 !important;' : '')}
`;

export default CandidateNotes;
