import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { notify } from 'react-notify-toast';

import { MarketplaceFitStatus, MarketplaceCandidate, Candidate } from 'types/types';
import maybe from './assets/maybe.svg';
import moment from 'moment';
import no from './assets/no.svg';
import yes from './assets/yes.svg';
import { myColor, myColorWarning } from 'utils/message';
import talentMarketplaceAPI from 'api/talentMarketplaceAPI';
import {
  BaseActionButton,
  CategoriesOfInterest,
  Container,
  LastLogin,
  Name,
  Row,
  ViewProfileButton,
  YOE,
} from './Marketplace.styles';
import { Component } from 'react';

interface MarketplaceCandidateRowProps {
  fullUser: MarketplaceCandidate;
  setFullUser: (QuickProfileUser) => void;
  currentTab: number;
  selected: boolean;
  toggleQuickProfile: (candidate: Candidate) => void;
}

interface cdiProps {
  fullUser: MarketplaceCandidate;
  setFullUser: (QuickProfileUser) => void;
  shouldShowStatusButtons: boolean;
}

const Actions = ({ fullUser, setFullUser, shouldShowStatusButtons }: cdiProps) => {
  const labeledStatus = ["'Not a Fit'", "'Potential Fit'", "'Converted to Prospect'"];
  const MarketplaceFitStatusLabel = Object.keys(MarketplaceFitStatus).reduce((obj, key, index) => {
    const value = MarketplaceFitStatus[key];
    obj[value] = labeledStatus[index];
    return obj;
  }, {});

  const onMarketplaceStatusUpdate = (updatedFitStatus: MarketplaceFitStatus) => {
    talentMarketplaceAPI
      .updateCandidateDistrictInformation({
        user: fullUser.user.id,
        marketplace_fit_status: updatedFitStatus,
      })
      .then(() => {
        showUpdatedToast(MarketplaceFitStatusLabel[updatedFitStatus]);
        const fullUserCopy = { ...fullUser };
        fullUserCopy.marketplace_fit_status = updatedFitStatus;
        setFullUser(fullUserCopy);
      })
      .catch((err) => {
        showErrorToast(err?.response?.data?.error);
      });
  };

  const showUpdatedToast = (updatedFitStatus) => {
    notify.show('Marked as ' + updatedFitStatus, 'custom', 1000, myColor);
  };

  const showErrorToast = (message?: string) => {
    notify.show(message || 'Error updating talent status', 'custom', 2000, myColorWarning);
  };

  const isNotAFit = () => fullUser?.marketplace_fit_status === MarketplaceFitStatus.NO_FIT;
  const isPotentialFit = () =>
    fullUser?.marketplace_fit_status === MarketplaceFitStatus.POTENTIAL_FIT;
  const isConvertedToProspect = () =>
    fullUser?.marketplace_fit_status === MarketplaceFitStatus.CONVERTED_TO_PROSPECT;

  return (
    <Container>
      {shouldShowStatusButtons ? (
        <>
          <BaseActionButton
            status="no-fit"
            isSelected={isNotAFit()}
            name="no-fit"
            onClick={() => onMarketplaceStatusUpdate(MarketplaceFitStatus.NO_FIT)}
            data-tip
            data-for="no-fit"
            data-place="top"
            data-delay-show="100"
          >
            <img src={no} alt="no-fit" style={{ pointerEvents: 'none' }} />
          </BaseActionButton>
          <BaseActionButton
            status="potential-fit"
            isSelected={isPotentialFit()}
            name="potential-fit"
            onClick={() => onMarketplaceStatusUpdate(MarketplaceFitStatus.POTENTIAL_FIT)}
            data-tip
            data-for="potential-fit"
            data-place="top"
            data-delay-show="100"
          >
            <img src={maybe} alt="potential-fit" style={{ pointerEvents: 'none' }} />
          </BaseActionButton>
          <BaseActionButton
            status="converted-to-prospect"
            isSelected={isConvertedToProspect()}
            name="converted-to-prospect"
            onClick={() => onMarketplaceStatusUpdate(MarketplaceFitStatus.CONVERTED_TO_PROSPECT)}
            data-tip
            data-for="converted-to-prospect"
            data-place="top"
            data-delay-show="100"
          >
            <img src={yes} alt="converted-to-prospect" style={{ pointerEvents: 'none' }} />
          </BaseActionButton>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

class MarketplaceCandidateRow extends Component<
  MarketplaceCandidateRowProps & RouteComponentProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  handleClick = (event, candidate) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.name === 'selected') {
      return;
    }

    this.props.toggleQuickProfile(candidate);
  };

  render() {
    const { fullUser, setFullUser, currentTab } = this.props;

    if (!fullUser) return <div />;

    const {
      user: { name, profile },
      categories_of_interest_count,
    } = fullUser;
    const shouldShowStatusButtons = currentTab !== 3;
    const lastLogin = moment(profile.updated).fromNow().replace('months', 'mos');

    const numberOfCategories =
      categories_of_interest_count === 1
        ? '1 category'
        : `${categories_of_interest_count} categories`;

    return (
      <Row>
        <Name>{name}</Name>
        <YOE>{profile.years_experience}</YOE>
        <CategoriesOfInterest>{numberOfCategories}</CategoriesOfInterest>
        <LastLogin>{lastLogin}</LastLogin>
        <Actions
          fullUser={fullUser}
          setFullUser={setFullUser}
          shouldShowStatusButtons={shouldShowStatusButtons}
        />

        <ViewProfileButton onClick={(event) => this.handleClick(event, fullUser)}>
          View Profile →
        </ViewProfileButton>
      </Row>
    );
  }
}

export default withRouter(MarketplaceCandidateRow);
