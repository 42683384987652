import styled, { css } from 'styled-components';
import { border, layout, space, typography } from 'styled-system';

interface InputTypes {
  hasError?: boolean;
  borderColor?: string;
  height?: string;
  marginTop?: number | string;
  marginBottom?: number | string;
}

export const Input = styled.input<InputTypes>`
  font-size: 14px;

  border-width: 1px;
  border-style: solid;
  border-radius: 3px;

  padding-left: 1.5em;
  padding-right: 1.5em;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }

  ${border}
  ${layout}
  ${space}
  ${typography}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid #cc0033;
      background-color: rgba(239, 86, 117, 0.1);
    `}
`;

Input.defaultProps = {
  borderColor: '#DCDCDC',
  height: '50px',
  marginTop: 0,
  marginBottom: 0,
};
