import { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as DashboardWidget from './DashboardWidgetV2';
import { Dropdown } from 'ui-kit';
import * as Toggle from '../Toggle';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import InfoIcon from 'assets/icons/icon_info.svg';
import {
  ChoiceDropdown,
  useChoiceDropdown,
} from '../../../PipelineMetricsDashboard/components/ChoiceDropdown';
import { BarChartV2Component } from './BarChartV2';
import { PieChartV2Component } from './PieChartV2';
import stateDashboardMetricsAPI from 'api/stateDashboardMetricsAPI';
import { CHART_COLOR_PALETTES_V2 } from 'features/StateDataDashboards/constants';
import {
  defaultFilters,
  groupedSegments,
  getFilterOptions,
  getArrayOfsubItems,
  getLabelText,
} from '../../utils';

import {
  stateDashboardChartTypes,
  stateDashboardSegmentMap,
  stateDashboardSegmentTypes,
  vacancyStatus,
} from 'utils/enums';

let colors = CHART_COLOR_PALETTES_V2;

export const DashboardBaseChartV2 = ({
  stateCode,
  title,
  chartType,
  groupByOptions,
  hiringSeasonsSelected,
  districtsSelected,
  jobSubcategoriesSelected,
  graphId,
  useVacancyAsTotal = false,
  vacancyFillRate = false,
  alwaysShowStatus = false,
  titleTooltip = false,
  titleTooltipText = '',
  XAxisLabel = undefined,
  showFilter = false,
  showDropdown = true,
  defaultGroupBySelected = stateDashboardSegmentTypes.none.value,
  showStatusToggle = false,
  excludePostingOnly = false,
  searchBarPlaceholders = {},
  legendOnLeft = false,
  certificationTypeGraph = false,
  diversityGraph = false,
  vacancyByEmploymentTypeLegend = false,
  showGroupedToggleInHeader = false,
  filteredCategoriesFromGraph = [],
  filterZerosFromGraph = false,
  vacancyStatusesSelected = [],
  setTotalCount = () => 0,
}) => {
  // TODO for dashboard base chart will be to remove graph-specific flags, like vacancyFillRate
  // Instead, each chart should have with a unique chart_id enum that can be passed to BackEnd (instead of graphId) to get correct data.

  const [apiError, setApiError] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [filtersSelected, setFiltersSelected] = useState(defaultFilters);
  const [groupBySelected, setGroupBySelected] = useState(defaultGroupBySelected);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [statusToggleState, setStatusToggleState] = useState({
    modeOneActive: true,
    modeTwoActive: false,
  });
  const [groupedToggleState, setGroupedToggleState] = useState({
    modeOneActive: false,
    modeTwoActive: true,
  });

  const optionDropdownState = useChoiceDropdown({
    choices: filterOptions,
    initialSelectedChoiceIds: [],
  });

  const showGroupedToggle = groupedSegments.includes(groupBySelected);
  const isGrouped = groupedToggleState.modeOneActive && showGroupedToggle;

  const handleStatusToggleChange = () => {
    setStatusToggleState({
      modeOneActive: !statusToggleState.modeOneActive,
      modeTwoActive: !statusToggleState.modeTwoActive,
    });
    setFiltersSelected((f) => ({
      ...f,
      status: !statusToggleState.modeTwoActive,
    }));
  };

  const clearDropdown = () => optionDropdownState.onClearAllChoices();

  const handleGroupedToggleChange = () => {
    setGroupedToggleState({
      modeOneActive: !groupedToggleState.modeOneActive,
      modeTwoActive: !groupedToggleState.modeTwoActive,
    });
  };

  const fetchChartData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await stateDashboardMetricsAPI.getChartData(
        groupBySelected,
        chartType,
        filtersSelected,
        isGrouped,
        stateCode,
        excludePostingOnly,
        graphId
      );

      setChartData(response);
      setIsLoading(false);
      setApiError(false);
    } catch (error) {
      if (axios.isCancel(error)) return console.log('Request cancelled');
      setIsLoading(false);
      setApiError(error);
    }
  }, [
    groupBySelected,
    chartType,
    filtersSelected,
    isGrouped,
    stateCode,
    excludePostingOnly,
    graphId,
  ]);

  const total = useMemo(() => {
    if (!chartData) {
      return;
    }

    return useVacancyAsTotal
      ? getVacancyCountTotal(chartData, vacancyStatusesSelected)
      : chartData.total;
  }, [chartData, useVacancyAsTotal, vacancyStatusesSelected]);

  const sortedDataset = useMemo(() => {
    if (!chartData?.results) {
      return [];
    }

    const sortedDataset = chartData.results.map((item) => ({ ...item }));

    if (alwaysShowStatus) {
      sortedDataset.forEach((item) => {
        item.hire_rate = getRoundedPercentage(item.value / item.segment_total);
        item.percentageLabel = getPercentageLabel(item.value / item.segment_total);
      });
      sortedDataset.sort((a, b) => b.hire_rate - a.hire_rate);
    } else if (vacancyFillRate) {
      sortedDataset.forEach((item) => {
        item.vacancy_fill_rate_rounded = getRoundedPercentage(item.vacancy_fill_rate);
        item.percentageLabel = getPercentageLabel(item.vacancy_fill_rate);
      });
      sortedDataset.sort((a, b) => b.vacancy_fill_rate - a.vacancy_fill_rate);
    } else if (certificationTypeGraph || diversityGraph) {
      sortedDataset.forEach((item) => {
        item.percentageLabel = getPercentageLabel(item.value / total);
      });
      sortedDataset.sort((a, b) => b.value - a.value);
    } else if (useVacancyAsTotal) {
      sortedDataset.forEach((item) => {
        item.vacancy_count = getVacancyCountForChartEntry(item, vacancyStatusesSelected);
        item.percentageLabel = getPercentageLabel(item.vacancy_count / total);
      });
      sortedDataset.sort((a, b) => b.vacancy_count - a.vacancy_count);
    } else {
      sortedDataset.sort((a, b) => b.value - a.value);
    }

    if (filteredCategoriesFromGraph.length) {
      sortedDataset.forEach((item, index) => (item.color = colors[(index % colors.length) - 2]));
    } else {
      sortedDataset.forEach((item, index) => (item.color = colors[index % colors.length]));
    }
    return sortedDataset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartData,
    total,
    alwaysShowStatus,
    certificationTypeGraph,
    diversityGraph,
    useVacancyAsTotal,
    vacancyFillRate,
    vacancyStatusesSelected,
  ]);

  const filteredData = useMemo(() => {
    if (!filterZerosFromGraph && !filteredCategoriesFromGraph.length) {
      return sortedDataset;
    }

    let filteredDataset = [...sortedDataset];

    if (filterZerosFromGraph) {
      filteredDataset = filteredDataset.filter(
        (dataCopyObj) => dataCopyObj.percentageLabel !== '0%'
      );
    }

    const filteredCategoriesDictionary = filteredCategoriesFromGraph.reduce(
      (filteredCategoriesObj, category) => {
        filteredCategoriesObj[category] = category;
        return filteredCategoriesObj;
      },
      {}
    );

    filteredDataset = filteredDataset.filter((dataCopyObj) => {
      return !filteredCategoriesDictionary.hasOwnProperty(dataCopyObj.name);
    });

    return filteredDataset;
  }, [filterZerosFromGraph, filteredCategoriesFromGraph, sortedDataset]);

  useEffect(() => {
    setTotalCount(total);
  }, [total, setTotalCount]);

  useEffect(() => {
    getFilterOptions(groupBySelected, stateCode).then((r) => setFilterOptions(r));

    // segment type can be grade, subcategory, EPP, etc.
    const segmentKey = stateDashboardSegmentMap[groupBySelected].key;
    const filters = isGrouped
      ? getArrayOfsubItems(optionDropdownState.selectedChoiceIds)
      : optionDropdownState.selectedChoiceIds;

    let updatedFiltersSelected = {
      ...filtersSelected,
      hiring_seasons: hiringSeasonsSelected,
      districts: districtsSelected,
      subcategory: jobSubcategoriesSelected,
      status: alwaysShowStatus ? true : filtersSelected.status,
    };

    if (vacancyFillRate) {
      updatedFiltersSelected = {
        ...updatedFiltersSelected,
        [segmentKey]: filters,
        status: filtersSelected.status,
      };
    }

    setFiltersSelected(updatedFiltersSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optionDropdownState.selectedChoiceIds,
    groupBySelected,
    isGrouped,
    stateCode,
    chartType,
    hiringSeasonsSelected,
    districtsSelected,
    jobSubcategoriesSelected,
    alwaysShowStatus,
  ]);

  useEffect(() => {
    if (groupBySelected !== stateDashboardSegmentTypes.none.value) fetchChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelected]);

  let notEnoughData = filteredData.length === 0 || total <= 1;
  let message = { isActive: false, title: '', subtitle: '' };
  if (notEnoughData) {
    message.isActive = true;
    message.title = 'Not enough data';
    message.subtitle = 'Check your filters and try again.';
  }
  if (groupBySelected === stateDashboardSegmentTypes.none.value) {
    message.isActive = true;
    message.title = 'No option selected.';
    message.subtitle = 'Please select attributes from the dropdown.';
  }
  if (apiError) {
    message.isActive = true;
    message.title = 'There was an error processing your request:';
    message.subtitle = apiError.message;
  }
  return (
    <DashboardWidget.ContainerV2>
      <DashboardWidget.HeaderContainerV2>
        <DashboardWidget.HeadingV2>
          {title}
          {titleTooltip && (
            <>
              <StyledInfoIcon
                src={InfoIcon}
                alt="title-tooltip"
                data-tip={titleTooltipText}
                data-for="dashboard-applications-info"
              />
              <ReactTooltip id="dashboard-applications-info" effect="solid" multiline />
            </>
          )}
        </DashboardWidget.HeadingV2>
        <ChoiceDropdownContainer flexDirection={showFilter ? 'row' : 'row-reverse'}>
          {showDropdown && (
            <Dropdown.Dropdown
              label={stateDashboardSegmentMap[groupBySelected].label}
              isActive={false}
              inputProps={{ height: '56px', minWidth: '228px' }}
              labelProps={{ color: '#444' }}
            >
              {groupByOptions.map((item) => {
                return (
                  <Dropdown.ListItem
                    key={item.value}
                    onClick={() => {
                      setGroupBySelected(item.value);
                      clearDropdown();
                    }}
                  >
                    <div style={{ padding: '12px 16px' }}>{item.label}</div>
                  </Dropdown.ListItem>
                );
              })}
            </Dropdown.Dropdown>
          )}
          {showFilter && groupBySelected !== stateDashboardSegmentTypes.none.value && (
            <ChoiceDropdown
              label={getLabelText(filtersSelected[stateDashboardSegmentMap[groupBySelected].key])}
              choices={filterOptions}
              {...optionDropdownState}
              height="56px"
              width="228px"
              searchBarPlaceholder={
                groupBySelected && groupBySelected in searchBarPlaceholders
                  ? searchBarPlaceholders[groupBySelected]
                  : ''
              }
            />
          )}
        </ChoiceDropdownContainer>
        {showGroupedToggle && showGroupedToggleInHeader && (
          <Toggle.Wrapper style={{ margin: '20px 30px' }}>
            <Toggle.ToggleItem
              isActive={groupedToggleState.modeOneActive}
              onClick={handleGroupedToggleChange}
            >
              Group
            </Toggle.ToggleItem>
            <Toggle.ToggleItem
              isActive={groupedToggleState.modeTwoActive}
              onClick={handleGroupedToggleChange}
            >
              Ungroup
            </Toggle.ToggleItem>
          </Toggle.Wrapper>
        )}
      </DashboardWidget.HeaderContainerV2>
      <DashboardWidget.BodyV2>
        <DashboardWidget.BodyActionsV2>
          {showStatusToggle && groupBySelected !== stateDashboardSegmentTypes.none.value ? (
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={statusToggleState.modeOneActive}
                onClick={handleStatusToggleChange}
              >
                Applied
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={statusToggleState.modeTwoActive}
                onClick={handleStatusToggleChange}
              >
                Hired
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          ) : showGroupedToggle && !showGroupedToggleInHeader ? (
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={groupedToggleState.modeOneActive}
                onClick={handleGroupedToggleChange}
              >
                Group
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={groupedToggleState.modeTwoActive}
                onClick={handleGroupedToggleChange}
              >
                Ungroup
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          ) : (
            <div style={{ height: '10px' }}></div>
          )}
        </DashboardWidget.BodyActionsV2>
        <DashboardWidget.BodyContentV2 isLoading={isLoading}>
          {message.isActive ? (
            <MessageContainer>
              <MessageTitle>{message.title}</MessageTitle>
              <MessageSubtitle>{message.subtitle}</MessageSubtitle>
            </MessageContainer>
          ) : (
            <ChartContainer>
              {legendOnLeft && (
                <LegendComponentV2
                  dataset={sortedDataset}
                  total={total}
                  vacancyFillRate={vacancyFillRate}
                  alwaysShowStatus={alwaysShowStatus}
                  useVacancyAsTotal={useVacancyAsTotal}
                  certificationTypeGraph={certificationTypeGraph}
                  diversityGraph={diversityGraph}
                  vacancyByEmploymentTypeLegend={vacancyByEmploymentTypeLegend}
                />
              )}
              {chartType === stateDashboardChartTypes.bar && (
                <BarChartV2Component
                  dataset={filteredData}
                  XAxisLabel={XAxisLabel}
                  total={total}
                  vacancyFillRate={vacancyFillRate}
                  alwaysShowStatus={alwaysShowStatus}
                  useVacancyAsTotal={useVacancyAsTotal}
                  certificationTypeGraph={certificationTypeGraph}
                  diversityGraph={diversityGraph}
                  filteredCategoriesFromGraph={filteredCategoriesFromGraph}
                />
              )}
              {chartType === stateDashboardChartTypes.pie && (
                <PieChartV2Component
                  dataset={filteredData}
                  total={total}
                  useVacancyAsTotal={useVacancyAsTotal}
                  vacancyByEmploymentTypeLegend={vacancyByEmploymentTypeLegend}
                />
              )}
              {!legendOnLeft && (
                <LegendComponentV2
                  dataset={sortedDataset}
                  total={total}
                  vacancyFillRate={vacancyFillRate}
                  alwaysShowStatus={alwaysShowStatus}
                  useVacancyAsTotal={useVacancyAsTotal}
                  certificationTypeGraph={certificationTypeGraph}
                  diversityGraph={diversityGraph}
                  vacancyByEmploymentTypeLegend={vacancyByEmploymentTypeLegend}
                />
              )}
            </ChartContainer>
          )}
        </DashboardWidget.BodyContentV2>
      </DashboardWidget.BodyV2>
    </DashboardWidget.ContainerV2>
  );
};

const getRoundedPercentage = (fraction) => {
  return Math.round(fraction * 1000) / 10;
};

const getPercentageLabel = (fraction) => {
  if (fraction === 0) return '0%';
  else if (fraction < 0.001) return '<0.1%';

  const roundedPercent = getRoundedPercentage(fraction);
  return `${roundedPercent}%`;
};

const getVacancyCountForChartEntry = (chartEntry, vacancyStatusesSelected) => {
  if (
    vacancyStatusesSelected.includes(vacancyStatus.filled) ===
    vacancyStatusesSelected.includes(vacancyStatus.unfilled)
  ) {
    return chartEntry.vacancy_count;
  }

  if (vacancyStatusesSelected.includes(vacancyStatus.filled)) {
    return chartEntry.vacancy_fill_count;
  } else {
    return chartEntry.vacancy_count - chartEntry.vacancy_fill_count;
  }
};

const getVacancyCountTotal = (chartData, vacancyStatusesSelected) => {
  if (
    vacancyStatusesSelected.includes(vacancyStatus.filled) ===
    vacancyStatusesSelected.includes(vacancyStatus.unfilled)
  ) {
    return chartData.vacancy_total;
  }

  if (vacancyStatusesSelected.includes(vacancyStatus.filled)) {
    return chartData.vacancy_fill_total;
  } else {
    return chartData.vacancy_total - chartData.vacancy_fill_total;
  }
};

const LegendComponentV2 = ({
  total,
  dataset,
  alwaysShowStatus = false,
  useVacancyAsTotal = false,
  certificationTypeGraph = false,
  vacancyByEmploymentTypeLegend = false,
}) => {
  const sortLegend = (dataset) => {
    let sortedDataset;
    if (alwaysShowStatus) {
      sortedDataset = dataset.sort((a, b) => {
        if (a.percentageLabel === '0%') {
          return 1;
        }
        if (b.percentageLabel === '0%') {
          return -1;
        }
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedDataset = dataset.sort((a, b) => a.name.localeCompare(b.name));
    }
    const noneNames = ['None', 'Other', 'Unspecified', 'Unknown'];
    sortedDataset.sort((a, b) => noneNames.includes(a.name) - noneNames.includes(b.name));
    return sortedDataset;
  };

  const getLegendValue = (entry) => {
    if (vacancyByEmploymentTypeLegend) {
      return entry.percentageLabel;
    } else if (useVacancyAsTotal) {
      return entry.vacancy_count;
    } else if (certificationTypeGraph) {
      return entry.value;
    } else {
      return entry.percentageLabel;
    }
  };

  return (
    <ScrollableWrapper>
      <Legend
        theme={{
          isSingleColumned: dataset.length <= 3 || dataset.find((i) => i.name.length > 9),
        }}
      >
        {sortLegend([...dataset]).map((entry) => (
          <LegendLineItem key={entry.name}>
            <LegendCircle color={entry.color} />
            <LegendPercent
              useVacancyAsTotal={useVacancyAsTotal}
              certificationTypeGraph={certificationTypeGraph}
              vacancyByEmploymentTypeLegend={vacancyByEmploymentTypeLegend}
            >
              {getLegendValue(entry)}
            </LegendPercent>
            <LegendName>{entry.name}</LegendName>
          </LegendLineItem>
        ))}
      </Legend>
    </ScrollableWrapper>
  );
};

const ScrollableWrapper = styled.div`
  max-height: 350px;
  max-width: 343px;
  width: max-content;
  overflow-y: scroll;
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  justify-content: space-around;
`;

const ChoiceDropdownContainer = styled.div`
  padding-right: 40px;
  padding-left: 30px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row-reverse')};
  gap: 10px;
  margin-top: 17px;
  min-width: 515px;
`;

//add v2 legend : border
const Legend = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.theme.isSingleColumned ? '100%' : '50% 50%')};
  grid-column-gap: 1rem;
  padding-top: 5px;
  margin-left: ${(props) => (props.theme.isSingleColumned ? '0%' : '0.5em')};
  max-height: 350px;
  max-width: 343px;
  min-width: 312px;
  align-items: center;
  color: #777;
  font-size: 16px;
`;

const LegendCircle = styled.div`
  background: ${(props) => props.color};
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 4px;
`;

const LegendPercent = styled.div`
  text-align: right;
  font-weight: 600;
  font-size: 15px;
  color: #444;
  margin-right: 5px;
`;

const LegendName = styled.div`
  color: #777777;
  font-weight: 400;
  font-size: 15px;
`;
const LegendLineItem = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-left: 20px;
  white-space: nowrap;
  width: max-content;
`;

const MessageContainer = styled.div`
  flex: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageTitle = styled.div`
  font-weight: 600px;
  font-size: 15px;
  color: #444444;
`;

const MessageSubtitle = styled.div`
  font-weight: 400px;
  font-size: 15px;
  color: #777777;
`;

const StyledInfoIcon = styled.img`
  width: 15px;
  margin-left: 5px;
  margin-bottom: 4px;
`;
