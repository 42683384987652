import { PrimaryButton } from 'sharedComponents/Buttons';
import { HeaderText, TitleContainer } from './styles';

export interface AnchorTagHeaderProps {
  title: string;
  onClick: () => void;
  buttonText: string;
}

export const AnchorTagHeader = ({ id, title, onClick, buttonText }) => {
  return (
    <div id={id}>
      <TitleContainer>
        <HeaderText data-testid="anchor-tag-title">{title}</HeaderText>
        <PrimaryButton onClick={onClick} sx={{ width: '206px', height: '48px' }}>
          {buttonText}
        </PrimaryButton>
      </TitleContainer>
    </div>
  );
};
