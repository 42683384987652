import { BaseNimbleAPI } from './baseAPI';

class PreferencesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/preferences/' };
  }

  async fetch(userId) {
    const response = await this.get(`/${userId}/`);
    return response.data;
  }

  async update(userId, updatedPreference) {
    const response = await this.put(`/${userId}/`, updatedPreference);
    return response.data;
  }

  async checkPreferencesStarted(userId) {
    const response = await this.get(`${userId}/preferences_started/`);
    return response.data;
  }
}

export default new PreferencesAPI();
