import { ATSCandidateScorecardDataTestIds } from 'data-testids/ATS';
import { useMemo } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-system';

import { TextInputWithDropdown } from 'ui-kit';
import { TrashIcon } from 'ui-kit/icons';

export default function ({
  addRecipient,
  updateRecipient,
  selectRecipient,
  removeRecipient,
  clearRecipient,
  setReadOnly,
  recipients,
  adminUsers,
}) {
  const adminOptions = useMemo(
    () => adminUsers.map((obj) => ({ value: obj.id, label: obj.name })),
    [adminUsers]
  );

  return (
    <>
      <HeaderText data-testid={ATSCandidateScorecardDataTestIds.REQUEST_MODAL_RECIPIENT_HEADER}>
        Select recipients
      </HeaderText>
      <Subtext data-testid={ATSCandidateScorecardDataTestIds.REQUEST_MODAL_RECIPIENT_SUBTEXT}>
        Tag Nimble users below to request feedback on this candidate
      </Subtext>
      <ContentGrid gridTemplateColumns={['1fr', 'minmax(300px, 405px) 129px']}>
        <ContentInnerGrid rowCount={recipients.length}>
          {recipients.map((recipient, index) => {
            let hasNoValue = !(recipient.name || recipient.email);
            return (
              // need to use index for the key because there's no stable id to use
              <RecipientContainer key={index}>
                <TextInputWithDropdown
                  options={adminOptions}
                  inputValue={recipient.name || recipient.email}
                  handleInputChange={(e) => updateRecipient(e.target.value, index)}
                  handleSelection={(value) => selectRecipient(value, index)}
                  placeholder="Start typing to add Nimble user or type an email"
                  boxShadow={false}
                  onClear={() => clearRecipient(index)}
                  onClose={() => {
                    // only set as readOnly if there is something in the input
                    if (hasNoValue) {
                      return;
                    }
                    setReadOnly(index);
                  }}
                  // input is always editable when there is no value in it
                  editable={recipient.editable || hasNoValue}
                  dataTestId={ATSCandidateScorecardDataTestIds.REQUEST_MODAL_RECIPIENTS_DROPDOWN}
                />
                {recipients.length > 1 && (
                  <TrashIconStyled
                    data-testid={
                      ATSCandidateScorecardDataTestIds.REQUEST_MODAL_DELETE_RECIPIENT_BUTTON
                    }
                    onClick={() => removeRecipient(index)}
                  />
                )}
              </RecipientContainer>
            );
          })}
        </ContentInnerGrid>
        <GreenTextButton
          data-testid={ATSCandidateScorecardDataTestIds.REQUEST_MODAL_ADD_RECIPIENT_BUTTON}
          onClick={addRecipient}
        >
          + Add recipient
        </GreenTextButton>
      </ContentGrid>
    </>
  );
}

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  margin-bottom: 14px;
  margin-top: 30px;
`;

const Subtext = styled.p`
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const ContentGrid = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  grid
);

const ContentInnerGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(${(props) => props.rowCount}, 50px);
`;

const GreenTextButton = styled.button`
  width: 129px;
  height: 20px;

  font-size: 14px;

  border: none;
  color: #00b88d;
  background: transparent;
`;

const TrashIconStyled = styled(TrashIcon)`
  height: 14px;
  cursor: pointer;
`;

const RecipientContainer = styled.div({
  display: 'grid',
  gridGap: '12px',
  gridTemplateColumns: '1fr 12px',
  alignItems: 'center',
});
