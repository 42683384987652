import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import {
  CTAButtonContainer,
  ModalContainer,
  HelpText,
  Key,
  Row,
  SubContainer,
  Title,
  ErrorText,
} from '../styles';
import { JobComboBox } from './JobComboBox';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import auth from 'utils/auth';
import { LandingFormProps } from '../types';

export const LandingForm: React.FC<LandingFormProps> = ({ candidateEmail, candidateName }) => {
  const [hasCandidateApplication, setHasCandidateApplication] = useState(false);
  const history = useHistory();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const toggleHasApplication = () => setHasCandidateApplication(!hasCandidateApplication);

  const hasFormErrors = Boolean(errors.role);
  const candidatesListURL = auth.isSchoolAdmin() ? '/school/candidates' : '/district/candidates';

  return (
    <ModalContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title variant="h1">Hire {candidateName}</Title>
        <SecondaryButton onClick={toggleHasApplication}>
          {hasCandidateApplication
            ? 'Toggle to does not have application'
            : 'Toggle to does have application'}
        </SecondaryButton>
      </Box>
      <SubContainer>
        <Row>
          <Key>Candidate:</Key> &nbsp;{candidateName}
        </Row>
        <Row>
          <Key>Candidate email:</Key> &nbsp; {candidateEmail}
        </Row>
      </SubContainer>
      <SubContainer>
        <Key>What role do you want this hire this candidate for?</Key>
        <Controller
          name="role"
          control={control}
          rules={{ required: 'Please select a role' }}
          render={({ field: { onChange } }) => (
            <JobComboBox onChange={onChange} hasError={Boolean(errors.role)} />
          )}
        />
        {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
        <HelpText>
          Don&apos;t see the role you want? Contact your administrator for more information or
          submit a job request.
        </HelpText>
      </SubContainer>
      {!hasCandidateApplication && (
        <>
          <SubContainer>
            <Key>Tell us a little bit about your interaction with the candidate so far:</Key>
            <Controller
              name="internalNote"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={6}
                  placeholder={`E.g. I have spoken on the phone twice with ${candidateName}. I was impressed by their qualifications, especially their experience in Title I schools.`}
                />
              )}
            />
          </SubContainer>
          <CTAButtonContainer>
            <PrimaryButton type="submit" disabled={hasFormErrors}>
              Import Candidate
            </PrimaryButton>
          </CTAButtonContainer>
        </>
      )}
      {hasCandidateApplication && (
        <>
          <SubContainer>
            <Key>
              It looks like this candidate already has an active application for this role.
              Here&apos;s what you can do next:
            </Key>
            <li>
              Look through your candidate list for their application by clicking the button below.
            </li>
            <li>
              If you can&apos;t find this candidate&apos;s application, contact your administrator
              for more information on where the candidate is in the hiring process.
            </li>
          </SubContainer>
          <CTAButtonContainer>
            <PrimaryButton onClick={() => history.push(candidatesListURL)}>
              View Candidates
            </PrimaryButton>
          </CTAButtonContainer>
        </>
      )}
    </ModalContainer>
  );
};
