import styled from 'styled-components';
import auth from 'utils/auth';
import ReactTooltip from 'react-tooltip';

import FinalRecommendationPreview from './FinalRecommendationPreview';
import CumulativeScorePreview from './CumulativeScorePreview';
import warningTriangle from 'assets/icons/warning_triangle.svg';
import { RightCaretIcon } from 'ui-kit/icons';
import { ReferenceCheck, ReferenceListCardProps } from '../types';

const ReferenceListCard: React.FC<ReferenceListCardProps> = ({
  referenceCheck,
  onView,
  onComplete,
}) => {
  const user = auth.getUser();

  const renderActionButton = (referenceCheck: ReferenceCheck) => {
    let buttonLabel: string;
    let func;

    if (referenceCheck.is_complete) {
      buttonLabel = 'View';
      func = onView;
    } else if (user.email === referenceCheck.submitted_by) {
      buttonLabel = 'Finish reference';
      func = onComplete;
    } else {
      // shouldn't get here because other users' drafts are not shown
      return null;
    }
    return (
      <ActionButton onClick={() => func(referenceCheck)}>
        {buttonLabel}
        &nbsp;&nbsp;
        <RightCaretIcon height="8px" />
      </ActionButton>
    );
  };

  const submitted_label = referenceCheck.submitted_by_title
    ? referenceCheck.submitted_by_name + ', ' + referenceCheck.submitted_by_title
    : referenceCheck.submitted_by_name;

  return (
    <Card>
      {renderActionButton(referenceCheck)}
      <ReferenceTitle>{referenceCheck.template_name ?? 'Reference'}</ReferenceTitle>
      <GreyText data-tip data-for={`submitted_by_${referenceCheck.id}`}>
        Submitted by {referenceCheck.submitted_by} for {submitted_label}
      </GreyText>
      <ReactTooltip id={`submitted_by_${referenceCheck.id}`} effect="solid">
        <span>{submitted_label}</span>
      </ReactTooltip>
      <GreyText>{new Date(referenceCheck.created).toLocaleString()}</GreyText>
      <HorizontalLine />
      {referenceCheck.include_final_recommendation && referenceCheck.show_answer_on_preview && (
        <FinalRecommendationPreview parentObject={referenceCheck} />
      )}
      {referenceCheck.include_cumulative_score && referenceCheck.show_total_on_preview && (
        <CumulativeScorePreview parentObject={referenceCheck} />
      )}
      {!referenceCheck.is_complete && (
        <DraftWarningContainer>
          <img src={warningTriangle} alt="warning-triangle" />
          <DraftLabel>Draft</DraftLabel>
          <DraftWarning>(only visible to you)</DraftWarning>
        </DraftWarningContainer>
      )}
    </Card>
  );
};

export default ReferenceListCard;

const Card = styled.div({
  height: '203px',
  width: '100%',
  position: 'relative',
  padding: '25px 20px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
  borderRadius: '3px',
  backgroundColor: '#FFFFFF',
  overflow: 'hidden',
});

const ActionButton = styled.button({
  height: '19px',
  padding: '0 4px',
  position: 'absolute',
  top: '7px',
  right: '7px',
  color: '#979797',
  border: '1px solid #979797',
  borderRadius: '3px',
  fontSize: '14px',
  backgroundColor: '#FFFFFF',
  opacity: '0.8',
  display: 'flex',
  alignItems: 'center',
});

const Text = styled.p({
  margin: '4px 0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const ReferenceTitle = styled(Text)({
  fontWeight: '600',
  fontSize: '15px',
  lineHeight: '20px',
  color: '#444444',
});

const GreyText = styled(Text)({
  fontSize: '14px',
  lineHeight: '19px',
  color: '#999999',
});

const HorizontalLine = styled.hr({
  margin: '12px 0',
});

const DraftWarningContainer = styled.div({
  position: 'absolute',
  bottom: '7px',
  left: '10px',
  display: 'flex',
  alignItems: 'center',
});

const DraftWarning = styled.p({
  color: '#EF5675',
  fontSize: '12px',
  marginLeft: '4px',
});

const DraftLabel = styled(DraftWarning)({
  fontWeight: 600,
});
