import { Component } from 'react';

import PropTypes from 'prop-types';
import { eventTypeMapping } from '../../../utils/enums';

import FilterDrop from '../../CandidatesList/CandidatesFilters/FilterDrop';
import ProfileTimeline from '../ProfileTimeline/ProfileTimeline';

export default class ActivitiesFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsTypeSelected: [],
    };
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  componentDidMount() {
    // Initially show all event types
    this.setState({ eventsTypeSelected: eventTypeMapping().map((e) => e.value) });
  }

  updateFilterDrop = (fieldName, fieldValue, active) => {
    /** this.state.addExperience[fieldName] should always be an array. If active is true,
     * the filter option that was clicked is active and should be turned off, which means we
     * have to remove fieldValue from the array instead of adding it.
     * @param {string} fieldName: either experience_type or org_description
     * @param {number} fieldValue: number corresponding to enum
     * @param {boolean} active: whether or not the value is selected
     */
    let updateObject = this.state.eventsTypeSelected;
    if (active) {
      // value is selected and the user wants to deselect. Find the element and remove.
      updateObject = updateObject.filter((e) => e !== fieldValue);
    } else {
      // value is not selected and the user wants to select. Add it to the array.
      updateObject.push(fieldValue);
    }
    this.setState({ eventsTypeSelected: updateObject, errorMessage: '' });
  };

  eventsShowing = () => {
    // This got too l33t too fast sorry billy ;(
    const allEventSubtypesSelected = eventTypeMapping()
      .filter((e) => this.state.eventsTypeSelected.indexOf(e.value) !== -1)
      .reduce((sum, current) => {
        return sum.concat(current.eventTypes);
      }, []);

    return this.props.events.filter((e) => allEventSubtypesSelected.indexOf(e.type) !== -1);
  };

  selectAll = () => {
    this.setState({ eventsTypeSelected: eventTypeMapping().map((e) => e.value) });
  };

  clearAll = () => {
    this.setState({ eventsTypeSelected: [] });
  };

  render() {
    return (
      <div className="activity-feed">
        <div className="flex title-container">
          <h5>All Activity for {this.props.user.name}</h5>
          <FilterDrop
            updateMultiSelectFilter={this.updateFilterDrop}
            displayName={'All Activity Types'}
            fieldName={'all_activity_types'}
            values={this.state.eventsTypeSelected}
            options={eventTypeMapping().sort((a, b) => (a.label > b.label ? 1 : -1))}
            selectAll={this.selectAll}
            clearAll={this.clearAll}
          />
        </div>
        <ProfileTimeline events={this.eventsShowing()} user={this.props.user} />
      </div>
    );
  }
}
