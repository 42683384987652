import axios from 'axios';
import { uploadFile } from './fileUpload.js';

/*
 ** Opens the upload modal (shared QP and full profile)
 */
export function openUploadDocumentModal(applicationID) {
  this.setState({ uploadDocumentModalOpen: applicationID });
}
/*
 ** Upload addition document (shared QP and full profile)
 */
export function submitAdditionalDocument(attachmentName, file) {
  // Upload the document
  return uploadFile(file, Number(this.state.uploadDocumentModalOpen), attachmentName);
}

/*
 ** Retrieves a user and swaps her into high level container state (shared QP and full profile)
 */
export function retrieveUser(userID) {
  let url = `/api/user/${userID}/`;

  axios.get(url).then((r) => {
    let updatedCandidate = r.data;
    // Switch the user into state
    let candidates = this.state.candidates;
    let index = candidates.findIndex((c) => c.id === updatedCandidate.id);
    candidates[index] = updatedCandidate;
    this.setState({
      candidates,
    });
  });
}
