import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  flex-direction: column;

  min-width: 0;
  margin: 0;

  font-weight: normal;
`;
