import styled from 'styled-components';
import { makeAllUrlsValid } from '../../utils/util';
import { DirectionsAnswerProps } from './types';

const DirectionsAnswer: React.FC<DirectionsAnswerProps> = ({ answer }) => {
  const createMarkup = () => ({
    __html: makeAllUrlsValid(answer.directions),
  });

  return (
    <>
      <HorizontalLine />
      <DirectionText dangerouslySetInnerHTML={createMarkup()} />
      <HorizontalLineBottom />
    </>
  );
};

export default DirectionsAnswer;

const DirectionText = styled.div`
  & > ul {
    margin-left: 20px;
  }
`;

const HorizontalLine = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HorizontalLineBottom = styled(HorizontalLine)`
  margin-bottom: 28px;
`;
