import { useState } from 'react';

import iconUpCaretGreen from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';

const SchoolPreferenceFilter = (props) => {
  const [showOptions, setShowoptions] = useState(false);

  const toggleShowOptions = () => {
    setShowoptions((prevState) => !prevState);
  };

  return (
    <div className={`new-cl-filter ${props.isActive ? 'cl-active' : ''} sub-filter`}>
      <div className="cl-filter-header" onClick={toggleShowOptions}>
        <p className={props.isActive ? 'cl-active' : ''}>Schools</p>
        <img
          src={
            showOptions && props.isActive
              ? iconUpCaretGreen
              : !showOptions && props.isActive
                ? iconDownCaretGreen
                : showOptions
                  ? iconUpCaret
                  : iconDownCaret
          }
          alt=""
        />
      </div>
      <div className={`selection-list ${showOptions ? 'show' : ''}`}>
        <div className="option select-all">
          <span className="drop-select-all" onClick={props.selectAll}>
            Select All
          </span>
          <span className="drop-select-all" onClick={props.clearAll}>
            Clear All
          </span>
        </div>
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={props.isOpenToOtherOptions}
              onChange={() => props.updateIsOpenToOtherOptions()}
            />
            <span className="checkmark"></span>
            Open to other options
          </label>
        </div>
        {props.activeDistrictSchools.map((school, i) => (
          <div key={i} className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={props.selectedSchoolIds.includes(school.id)}
                onChange={() => props.updateSelectedSchoolIds(school.id)}
              />
              <span className="checkmark" />
              {school.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchoolPreferenceFilter;
