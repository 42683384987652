import styled from 'styled-components';

import { Fragment } from 'react';
import { Dropdown } from 'ui-kit';

export const CategoryDropdown = ({
  categories,
  selectedSubcategories,
  setSelectedSubcategories,
  ...rest
}) => {
  const isSubcategorySelected = (subcategory) => {
    return selectedSubcategories.includes(subcategory.id);
  };

  const isCategorySelected = (category) => {
    return (
      category.subcategories.length > 0 &&
      category.subcategories.every((subcategory) => isSubcategorySelected(subcategory))
    );
  };

  const areAllCategoriesSelected = () => {
    return categories.every((category) => isCategorySelected(category));
  };

  const onToggleSubcategory = (subcategory) => {
    if (isSubcategorySelected(subcategory)) {
      // If subcategory is already selected, remove it
      setSelectedSubcategories((selectedSubcategories) =>
        selectedSubcategories.filter((id) => id !== subcategory.id)
      );
    } else {
      // Add subcategory
      setSelectedSubcategories((selectedSubcategories) => [
        ...selectedSubcategories,
        subcategory.id,
      ]);
    }
  };

  const onToggleCategory = (category) => {
    // If all subcategories are selected, remove all subcategories
    if (isCategorySelected(category)) {
      const subcategoryIds = category.subcategories.map(({ id }) => id);
      setSelectedSubcategories((selectedSubcategories) =>
        selectedSubcategories.filter((id) => !subcategoryIds.includes(id))
      );
    } else {
      // Add all subcategories (as needed)
      setSelectedSubcategories((selectedSubcategories) => {
        const missingSubcategoryIds = category.subcategories
          .filter((id) => !selectedSubcategories.includes(id))
          .map((subcategory) => subcategory.id);
        return [...selectedSubcategories, ...missingSubcategoryIds];
      });
    }
  };

  const onToggleAllCategories = () => {
    if (areAllCategoriesSelected()) {
      setSelectedSubcategories(() => []);
    } else {
      // Select all categories
      setSelectedSubcategories((selectedSubcategories) =>
        categories
          .flatMap((category) => category.subcategories)
          .map((subcategory) => subcategory.id)
      );
    }
  };

  let dropdownLabel = 'Select all that apply';

  const selectedSubcategoryLabels = categories
    .flatMap((category) => category.subcategories)
    .filter((subcategory) => selectedSubcategories.includes(subcategory.id))
    .map((subcategory) => subcategory.label);

  if (selectedSubcategoryLabels.length > 0) {
    dropdownLabel = selectedSubcategoryLabels.join(', ');
  }

  return (
    <Dropdown.Dropdown
      label={dropdownLabel}
      inputProps={{ mt: '1em', paddingLeft: '1.5em', paddingRight: '1.5em' }}
      {...rest}
    >
      <CategoryListItem isSelected={areAllCategoriesSelected()} onClick={onToggleAllCategories}>
        All jobs
      </CategoryListItem>
      {categories.map((category) => {
        return (
          <Fragment key={category.id}>
            <CategoryListItem
              isSelected={isCategorySelected(category)}
              onClick={() => onToggleCategory(category)}
            >
              {category.label}
            </CategoryListItem>
            {category.subcategories.map((subcategory) => (
              <SubcategoryListItem
                key={subcategory.id}
                isSelected={isSubcategorySelected(subcategory)}
                onClick={() => onToggleSubcategory(subcategory)}
              >
                {subcategory.label}
              </SubcategoryListItem>
            ))}
          </Fragment>
        );
      })}
    </Dropdown.Dropdown>
  );
};

const DropdownListItem = ({ isSelected, children, ...rest }) => {
  return (
    <Dropdown.ListItem {...rest}>
      <span>{children}</span>
      <input type="checkbox" checked={isSelected} readOnly />
    </Dropdown.ListItem>
  );
};

const CategoryListItem = styled(DropdownListItem)`
  padding: 0.5em 1.5em;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SubcategoryListItem = styled(CategoryListItem)`
  padding-left: 3.5em;
  font-weight: normal;
`;
