import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function GoButton({ url, caption }) {
  return (
    <Container>
      <Link to={url} rel="noopener noreferrer" target="_blank">
        <Caption>{caption}</Caption>
      </Link>
    </Container>
  );
}

const Caption = styled.div`
  color: white;
  padding: 1px 5px;
  border-radius: 10px;
  background-color: #304b7d;
`;

const Container = styled.div`
  justify-self: end;
  width: fit-content;
`;
