import './bulkemail.css';

import { Component } from 'react';
import PropTypes from 'prop-types';

import BulkEmailSendRow from './BulkEmailSendRow';
import axios from 'axios';

export default class BulkEmailSend extends Component {
  static propTypes = {
    candidates: PropTypes.array.isRequired,
    cc: PropTypes.array,
    bcc: PropTypes.array,
    emailTemplate: PropTypes.object.isRequired,
    uploads: PropTypes.array,
    userEmail: PropTypes.string.isRequired,
    startSpinner: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
    onProspectList: PropTypes.bool.isRequired,
  };

  state = {
    testSendSuccessful: false,
  };

  onSend = async (id) => {
    /** 3 possible ids:
     * 0 is test email, 2 is actual bulk email to selected candidates.
     * 1 is for sending a bulk email at a later time (not currently implemented).
     */
    const [TEST, SEND] = [0, 2];

    // Construct the payload
    let payload = new FormData();

    const { subject, content } = this.props.emailTemplate;
    const { candidates, cc, bcc, uploads } = this.props;

    if (id === TEST) {
      // test email only sends with the information from the first recipient.
      payload.append('candidates', JSON.stringify([candidates[0]]));
      payload.append('test', true);
    } else {
      // Real send
      payload.append('candidates', JSON.stringify(candidates));
    }

    payload.append('cc', JSON.stringify(cc.map((s) => s.value) || []));
    payload.append('bcc', JSON.stringify(bcc.map((s) => s.value) || []));
    payload.append('subject', subject);
    payload.append('content', content);

    if (uploads?.length > 0) {
      uploads.forEach((upload, i) => {
        payload.append(`attachment_${i}`, upload, upload.name);
      });
      payload.append('count', uploads.length);
    }

    // for non-test email, start spinner
    if (id === SEND) {
      this.props.startSpinner();
    }

    await axios.post('/api/sendbulkemail/', payload);

    if (id === TEST) {
      this.setState({ testSendSuccessful: true });
      return;
    } else {
      // send was the actual emails and not a test, so reset state and invoke onSend
      this.setState({ testSendSuccessful: false });
      // this turns off the spinner and deselects all the candidates.
      this.props.onSend();
    }
  };

  render() {
    return (
      <div>
        <div className="bulk-email-send-padding">
          <div className="bulk-email-send-header">
            <div className="send-row-action-label">
              {`Send Email to ${this.props.candidates.length} ${
                this.props.onProspectList ? 'Prospect' : 'Candidate'
              }${this.props.candidates.length === 1 ? '' : 's'}`}
            </div>
            <div className="send-row-sub-label font13px">{this.props.emailTemplate.subject}</div>
          </div>
          <BulkEmailSendRow
            id={0}
            actionLabel="Send a Test Email"
            subLabel={`We'll send a preview email to ${this.props.userEmail}.`}
            icon={this.state.testSendSuccessful ? 'check' : 'person'}
            onSend={this.onSend}
          />
          {/* add below in when we can send bulk emails at later time */}
          {/* <BulkEmailSendRow
            id={1}
            actionLabel="Send Later"
            subLabel="Choose a date & time to send this email."
            icon="clock"
            onSend={this.onSend}
          /> */}
          <BulkEmailSendRow
            id={2}
            actionLabel="Send Now"
            subLabel={`Send this email to ${this.props.candidates.length} ${
              this.props.onProspectList ? 'prospect' : 'candidate'
            }${this.props.candidates.length === 1 ? '' : 's'} immediately.`}
            icon="airplane"
            onSend={this.onSend}
          />
        </div>
      </div>
    );
  }
}
