import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import GoButton from 'components/Home/goButton';
import StatusIcon from 'components/Home/statusIcon';
import {
  ContentRow,
  HeaderLabel,
  HeaderRow,
  HomepageContentContainer,
} from 'components/Home/common';
import auth from 'utils/auth';
import { taskStatus } from 'utils/enums';

export default function AdminTasks({ tasks, updateCandidateTask }) {
  const urlPrefix = auth.isDistrictUser() ? 'district' : 'school';

  return (
    <HomepageContentContainer>
      <HeaderLabel>Outstanding tasks</HeaderLabel>
      <Container>
        <HeaderRow>
          <span>Task name</span>
          <span>Candidate name</span>
          <span>Owner</span>
          <span>Due</span>
        </HeaderRow>
        {tasks.map((task) => (
          <ContentRow
            key={task.id}
            isOverdue={
              task.status !== taskStatus.complete && moment(Date.now()).isAfter(task.due_date)
            }
          >
            <div>
              <StatusIcon
                isComplete={task.status === taskStatus.complete}
                isOverdue={moment(Date.now()).isAfter(task.due_date)}
                onClick={() => updateCandidateTask(task, 'put', true)}
              />
              <span>{task.title}</span>
            </div>

            <span>{task.candidate.name}</span>

            <span>{task.candidate_assigned ? 'Candidate' : 'You'}</span>

            <span className="due-date">{moment(task.due_date).format('M/D/YYYY')}</span>

            <GoButton
              url={`/${urlPrefix}/profile/${task.candidate.id}?application=${task.application_id}`}
              caption={'Go >'}
            />
          </ContentRow>
        ))}
      </Container>
    </HomepageContentContainer>
  );
}

const Container = styled.div`
  padding: 20px 0;

  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
`;

AdminTasks.propTypes = {
  tasks: PropTypes.array.isRequired,
  updateCandidateTask: PropTypes.func.isRequired,
};
