import { BaseNimbleAPI } from './baseAPI';

class ApplicationsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api' };
  }

  async getApplication(applicationId) {
    const url = `/applications/${applicationId}/`;
    const response = await this.get(url);
    return response.data;
  }

  async getApplications(params = {}) {
    const { isHome, ...extraParams } = params;
    if (isHome !== undefined) {
      extraParams.is_home = isHome;
    }

    const response = await this.get('/applications/', {
      params: { ...extraParams },
    });
    return response.data;
  }

  async getSchoolApplication(applicationId) {
    const url = `/schoolapplications/${applicationId}/`;
    const response = await this.get(url);
    return response.data;
  }

  async bulkUpdateApplicationStatusesForRole(payload) {
    const url = '/applications/bulk_update_statuses_for_role/';
    const response = await this.put(url, payload);
    return response.data;
  }

  async bulkUpdateApplicationStatuses(payload) {
    const url = '/applications/bulk_update_statuses/';
    const response = await this.put(url, payload);
    return response.data;
  }

  async candidateDeleteApplication(applicationId) {
    const response = await this.delete(
      `/applications/${applicationId}/candidate_delete_application/`
    );
    return response.data;
  }

  async candidateDeleteAppAttachment(applicationID, attachmentID) {
    const response = await this.delete(
      `/applications/${applicationID}/candidate_delete_application_attachment/`,
      {
        data: {
          attachmentID: attachmentID,
        },
      }
    );
    return response.data;
  }

  async getCandidateApplicationByRoleId(roleId) {
    const response = await this.get('/applications/get_application_by_role_id/', {
      params: { role_id: roleId },
    });
    return response.data;
  }

  async getCandidateSchoolApplications(params = {}) {
    const response = await this.get('/schoolapplications/get_candidate_school_applications/', {
      params: { ...params },
    });
    return response.data;
  }
}

export default new ApplicationsAPI();
