/* Takes a string and an object we want to search. Searches the keys for the
 * string and returns the keys which contain the string
 */
export function searchStringInObjectKeys(needle, haystack) {
  needle = needle.toLowerCase();
  let results = {};
  for (let key in haystack) {
    if (haystack.hasOwnProperty(key)) {
      if (key.toLowerCase().indexOf(needle) !== -1) {
        results[key] = haystack[key];
      }
    }
  }
  return results;
}
