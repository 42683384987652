import { useState, useEffect, ReactElement } from 'react';
import styled from 'styled-components';

import { FlatFileTransferInterface, FlatFileTransferPayloadPOST } from 'types/types';
import FlatFileTransferAPI from 'api/flatFileTransferAPI';

import WhitePlusIcon from 'ui-kit/icons/svg/white-plus-icon.svg';
import PencilIcon from 'ui-kit/icons/svg/pencil.svg';
import auth from 'utils/auth';
import { useHistory } from 'react-router-dom';
import { ATSDataFFTDataTestIds } from '../../data-testids/ATS';

export default function FlatFileTransfer(): ReactElement {
  const [flatFileTransfers, setFlatFileTransfers] = useState<FlatFileTransferInterface[]>([]);
  const history = useHistory();

  const handleCreateFlatFileTransfer = async () => {
    const initialFFTData: FlatFileTransferPayloadPOST = {
      name: '',
      district: auth.getUser().profile.district.id,
      report_columns: JSON.stringify({ columns: [] }),
      application_statuses: [],
    };

    try {
      const newFFTData = await FlatFileTransferAPI.createFlatFile(initialFFTData);
      setFlatFileTransfers([...flatFileTransfers, newFFTData]);
      if (!newFFTData.id) throw new Error('No id found for new flat file transfer');

      history.push(`/data/datatransfer/fields/${newFFTData.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchFFTs() {
      try {
        const response = await FlatFileTransferAPI.getAll();
        setFlatFileTransfers(response);
      } catch (error) {
        console.log(error);
      }
    }

    fetchFFTs();
  }, []);

  return (
    <DashboardContainer>
      <PageTitleContainer>
        <PageTitle>
          {!!flatFileTransfers?.length && flatFileTransfers.length} Data Transfers
        </PageTitle>
        <CreateFFTButton onClick={handleCreateFlatFileTransfer}>
          <Icon src={WhitePlusIcon} />
          <CreateFFTButtonText data-testid={ATSDataFFTDataTestIds.NEW_DATA_TRANSFER_BUTTON}>
            New Data Transfer
          </CreateFFTButtonText>
        </CreateFFTButton>
      </PageTitleContainer>
      <ScrollContainer>
        <FFTList>
          {flatFileTransfers?.length > 0 &&
            flatFileTransfers.map((fft) => (
              <FFTListItem key={fft.id}>
                <FFTListItemName>{fft.name}</FFTListItemName>
                <EditIcon
                  src={PencilIcon}
                  alt="edit button"
                  onClick={() => history.push(`/data/datatransfer/fields/${fft.id}`)}
                />
              </FFTListItem>
            ))}
        </FFTList>
      </ScrollContainer>
    </DashboardContainer>
  );
}

export const DashboardContainer = styled.div`
  display: flex;
  gap: 25px;
  max-width: 1160px;
  margin: 25px auto 10px;
  padding: 0 30px;
  justify-content: space-around;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;

const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ScrollContainer = styled.div`
  max-height: 66vh;
  overflow: scroll;
`;

const PageTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #333333;
  padding-left: 0;
`;

const FFTList = styled.ul`
  list-style: none;
  margin: 0;
  width: 100%;
`;

const FFTListItem = styled.li`
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 20px 0;

  &:first-of-type {
    margin: 0px;
  }
`;

const FFTListItemName = styled.h3`
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

const CreateFFTButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;

  width: 204px;
  height: 48px;

  background-color: #00b88d;
  border-radius: 2px;
  border: none;
`;

const CreateFFTButtonText = styled.span`
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Open Sans';
  font-style: normal;
`;

const Icon = styled.img`
  width: 10px;
  height: 10px;
`;

const EditIcon = styled.img`
  width: 22px;
  height: 22px;

  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
