import { SyntheticEvent, useEffect, useState } from 'react';

import { captureException } from '@sentry/browser';

import { IconButton, Stack, useTheme } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { GridItem } from 'sharedComponents/Grid';
import { SearchableSelect } from 'sharedComponents/Select/SearchableSelect';
import { Option } from 'sharedComponents/Select/types';

import { CertificationsFormInputProps } from './types';
import expressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { QuestionLabel } from './ResumeAndCertificationsForm';
import { STATE_DROPDOWN_OPTIONS } from 'features/Connect/utils/connectConstants';
import { CERTIFICATION_FORM_INPUT_TYPE } from './constants';

export const CertificationsFormInput: React.FC<CertificationsFormInputProps> = ({
  certification,
  userFormData,
  setUserFormData,
  isFormSubmitting,
  getConnectCertifications,
  stateDropdownOptions,
}) => {
  const theme = useTheme();

  const { certifications } = userFormData;

  const [credentialSubjects, setCredentialSubjects] = useState<Option[]>([]);

  const getStateLabel = (): string => {
    return STATE_DROPDOWN_OPTIONS.find(
      (selectedOption) => selectedOption.value == certification.state
    )?.label;
  };

  const handleCertificationInputChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: Option,
    inputName: string,
    clientID: string | number = null
  ) => {
    let selectedOption;
    const index = certifications.findIndex((certification) => certification.client_id === clientID);
    if (index === -1) {
      captureException(`Certification with Client ID ${clientID} not found.`);
      return;
    }
    if (inputName === CERTIFICATION_FORM_INPUT_TYPE.STATE) {
      selectedOption = newValue.value;
      certifications[index][CERTIFICATION_FORM_INPUT_TYPE.SUBJECT] = null;
      certifications[index].errors.hasStateError = false;
    }
    if (inputName === CERTIFICATION_FORM_INPUT_TYPE.SUBJECT) {
      selectedOption = { id: Number(newValue.value), label: newValue.label };
      certifications[index].errors.hasSubjectError = false;
    }
    certifications[index][inputName] = selectedOption;

    setUserFormData({ ...userFormData, certifications: [...certifications] });
  };

  const fetchCredentialOptions = async () => {
    try {
      const { credentialOptions } = await expressInterestAPI.fetchCredentialOptions(
        certification.state
      );
      setCredentialSubjects(credentialOptions);
    } catch (error) {
      captureException(error);
    }
  };

  const dropdownStyles = {
    maxWidth: '230px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.border.main,
      },
    },
    // do not autozoom in on dropdowns on mobile screens
    '& .MuiInputBase-input': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.subtitle.fontSize,
      },
    },
  };

  useEffect(() => {
    if (certification.state) {
      fetchCredentialOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certification.state]);

  return (
    <>
      <Stack
        direction={{ xs: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
        justifyContent={'space-between'}
        sx={{ width: '100%', marginBottom: '14px' }}
      >
        <GridItem xs={6}>
          <QuestionLabel required>State</QuestionLabel>
          <SearchableSelect
            name={CERTIFICATION_FORM_INPUT_TYPE.STATE}
            placeholder="Select state"
            handleChange={(event, newValue) => {
              handleCertificationInputChange(
                event,
                newValue,
                CERTIFICATION_FORM_INPUT_TYPE.STATE,
                certification.client_id
              );
            }}
            value={
              certification.state
                ? {
                    value: certification.state,
                    label: getStateLabel(),
                  }
                : null
            }
            options={stateDropdownOptions}
            error={certification.errors?.hasStateError}
            helperText="Please select a state."
            sx={{ ...dropdownStyles }}
          />
        </GridItem>
        <GridItem xs={6} sx={{ paddingRight: '0px' }}>
          <QuestionLabel required sx={{ display: !certification.state && 'none' }}>
            Certification
          </QuestionLabel>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: '10px',
            }}
          >
            <SearchableSelect
              name={CERTIFICATION_FORM_INPUT_TYPE.SUBJECT}
              placeholder="Select certification"
              handleChange={(event, newValue) =>
                handleCertificationInputChange(
                  event,
                  newValue,
                  CERTIFICATION_FORM_INPUT_TYPE.SUBJECT,
                  certification.client_id
                )
              }
              value={
                certification?.subject?.id
                  ? {
                      value: certification.subject.id,
                      label: certification.subject.label,
                    }
                  : null
              }
              options={credentialSubjects}
              error={certification.errors?.hasSubjectError}
              helperText="Please select a certification."
              disabled={!certification.state}
              sx={{
                display: !certification.state && 'none',
                ...dropdownStyles,
              }}
            />
          </div>
        </GridItem>
        {getConnectCertifications().length > 1 && (
          <GridItem
            sx={{
              display: 'flex',
              height: '100%',
              marginTop: '14px !important',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              aria-label="delete-certification"
              sx={{
                color: 'text.light',
                marginTop:
                  (certification.errors?.hasSubjectError || certification.errors?.hasStateError) &&
                  '-22px',
                padding: 0,
                '&:hover': {
                  color: '#CC3340',
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                const updatedCertifications = certifications.filter(
                  (x) => x.client_id !== certification.client_id
                );
                setUserFormData({
                  ...userFormData,
                  certifications: updatedCertifications,
                });
              }}
              disabled={isFormSubmitting}
            >
              <Delete />
            </IconButton>
          </GridItem>
        )}
      </Stack>
    </>
  );
};
