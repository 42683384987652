import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useOnClickOutside } from 'hooks';
import auth from 'utils/auth';
import HRBPView from 'components/Profile/HRBP/index';
import Nudges from 'features/TopCandidates/Nudges';
import ProfileTimelineTooltip from 'components/Profile/ProfileTimeline/ProfileTimelineTooltip';
import StatusView from 'components/common/StatusView';

import { ReactComponent as IconDownCaretWithSquare } from 'assets/icon_down_caret_with_square.svg';
import { ReactComponent as ScorecardIcon } from 'assets/icon-scorecard.svg';
import { getScorecardCount } from 'utils/scorecardutils';
import { ATSCandidateQuickviewTestIds, ATSCandidateScorecardDataTestIds } from 'data-testids/ATS';

export default function CandidateHeaderSectionQuickProfile({
  fullUser,
  // application currently being viewed, can be a schoolapplication or district application
  application,
  schoolapplications = [],
  events,
  isDraft,
  urlPrefix,
  openEmailModal,
  openCopyApplicationModal,
  openScorecardModal,
  updateUserStatus,
  updateSchoolApp,
  viewStatusModal,
  emailTemplatesList,
  adminUsers,
  newApplicationStatuses,
  districtApplicationBeingViewedBySchoolAdmin,
}) {
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const toggleApplicationCopyButton = useCallback(
    () => setShowActionDropdown(!showActionDropdown),
    [showActionDropdown]
  );
  const closeDropdown = useCallback(() => setShowActionDropdown(false), []);
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdown, showActionDropdown);
  const showCopyApplicationButton = auth.isDistrictUser() && !isDraft;

  const scorecardCount = getScorecardCount(application);

  return (
    <div>
      <HeaderContainer>
        <Link
          to={`/${urlPrefix}/profile/${fullUser.id}/?application=${application.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Header
            data-testid={ATSCandidateQuickviewTestIds.DISTRICT_APPLICATION_QUICKVIEW_CANDIDATE_NAME}
          >
            {fullUser.name}
          </Header>
        </Link>
        <div className="relative" ref={closeNodeOnClickOutside}>
          <IconDownCaretWithSquare onClick={toggleApplicationCopyButton} />
          <div className={`quickview-dropdown-div ${showActionDropdown ? 'expanded' : ''}`}>
            <div className="quickview-dropdown-btn" onClick={openEmailModal}>
              Email
            </div>
            {!isDraft && (
              <Link
                to={`/${urlPrefix}/profile/${fullUser.id}/?application=${application.id}&print=true`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="quickview-dropdown-btn">Download/Print</div>
              </Link>
            )}
            {showCopyApplicationButton && (
              <div className="quickview-dropdown-btn" onClick={openCopyApplicationModal}>
                Copy Application
              </div>
            )}
          </div>
        </div>
      </HeaderContainer>
      <LargeGreySubHeaderText>{application.role.title}</LargeGreySubHeaderText>
      <OuterGridContainer>
        <InnerGridContainerTwoRows>
          {application.updated && (
            <TextOverflowDiv>
              <span
                data-tip={
                  // data-tip doesn't work with JSX, so this converts it to plain HTML
                  ReactDOMServer.renderToStaticMarkup(
                    <ProfileTimelineTooltip events={events} user={fullUser} />
                  )
                }
                data-for="last-updated"
                style={{ borderBottom: '1px solid' }}
              >
                Last updated
              </span>
              {` ${moment(application.updated).format('l')}${
                application.updated_by ? ` by ${application.updated_by}` : ''
              }`}
            </TextOverflowDiv>
          )}
          {!isDraft && (
            <ScorecardHeaderContainer>
              <ScorecardIcon />
              <div id="scorecardCount">
                <span>{`${scorecardCount} interview scorecard${
                  scorecardCount === 1 ? '' : 's'
                }`}</span>
                <GreenTextButton
                  data-testid={ATSCandidateScorecardDataTestIds.QUICKVIEW_ADD_VIEW_BUTTON}
                  onClick={openScorecardModal}
                >
                  (add/view)
                </GreenTextButton>
              </div>
            </ScorecardHeaderContainer>
          )}
        </InnerGridContainerTwoRows>
        {/* Only show nudges for top candidates */}
        {application !== null && application.top_candidate && <Nudges candidate={application} />}
        <HRBPView
          hrbpAssignments={application.role.hrbp_assignments}
          schoolApplications={application.schoolapplications}
        />
      </OuterGridContainer>

      {!isDraft && (
        <StatusView
          user={fullUser}
          applicationBeingQuickviewed={application}
          updateUserStatus={updateUserStatus}
          updateSchoolApp={updateSchoolApp}
          viewStatusModal={viewStatusModal}
          schoolapplications={schoolapplications}
          isSchoolUser={auth.isSchoolUser()}
          isDistrictUser={auth.isDistrictUser()}
          emailTemplatesList={emailTemplatesList}
          adminUsers={adminUsers}
          newApplicationStatuses={newApplicationStatuses}
          districtApplicationBeingViewedBySchoolAdmin={districtApplicationBeingViewedBySchoolAdmin}
        />
      )}
    </div>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Header = styled.p`
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  color: #444444;

  margin-right: 1rem;
`;

const LargeGreySubHeaderText = styled.p`
  color: #777777;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  margin-top: 4px;
`;

const OuterGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr;
  grid-row-gap: 20px;

  margin: 24px 0;
  font-size: 15px;
  font-weight: 400;
`;

const InnerGridContainerTwoRows = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 21px);
  grid-row-gap: 4px;

  div,
  span {
    color: #999999 !important;
  }

  .__react_component_tooltip.type-dark div,
  .__react_component_tooltip.type-dark span {
    color: #ffffff !important;
  }
`;

const GreenTextButton = styled.button`
  background-color: transparent;
  padding: 0;
  text-decoration: underline;
  border: none;
  margin-left: 4px;

  &:hover {
    color: #00b88d;
  }
`;

const ScorecardHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-gap: 6px;
  align-items: center;

  font-weight: 400;
`;
ScorecardHeaderContainer.displayName = 'ScorecardHeaderContainer';

const TextOverflowDiv = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
