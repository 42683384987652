import { Component } from 'react';
import PropTypes from 'prop-types';
import { handleOutsideClickOnValidForm } from '../../../utils/util';
import { languageProficiency } from '../../../utils/enums';

export default class LanguageProficiencyFilterDrop extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
    languageEnum: PropTypes.number.isRequired,
    proficiencyReverseMap: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  outsideClickListener = (ev) => handleOutsideClickOnValidForm(this.node, ev, this.toggleOptions);

  toggleOptions = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    if (!this.state.showOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    this.setState({ showOptions: !this.state.showOptions });
  };

  updateFilterSelection = (e, value, active) => {
    e.stopPropagation();
    // user clicked on a proficiency in the dropdown.
    // convert that number into a letter since it will be sent out in the form
    // ['1a', '1b', ... ]
    // so English elementary would map to '1a', Spanish bilingual would be '2e', etc
    let prefix = this.props.languageEnum;
    let suffix = this.props.proficiencyReverseMap[value]; // this line changes the number to a letter
    let finalValue = prefix + suffix; // string. will look like '1a'
    this.props.updateMultiSelectFilter('language_list', finalValue, active);
  };

  render() {
    /** use a set for constant time lookup. Loop through props.values, which is a list of currently
     * active filters. Add each value to the set, and down below in the map function, check
     * if each item is in the set (optionSet.has(o.value). This is faster than looping through
     * the values array during each iteration of map.
     * Also, send optionSet.has(o.value) when the item is clicked, so that the container
     * knows if you're clicking an already active filter (in which case it will turn it off).
     */
    const optionSet = new Set();
    this.props.values.forEach((o) => {
      optionSet.add(o);
    });
    const { values } = this.props;

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    // Allow up to 2 to not be selected for archived and draft which are excluded by default;
    // we still don't want to draw attention to the box if only those statuses are not active
    let allSelected = values.length === languageProficiency().length;
    let noneSelected = values.length === 0;
    let isActive = !(allSelected || noneSelected);

    return (
      <div
        className="language-proficiency cl-filter-dropdown-div"
        onClick={this.toggleOptions}
        ref={(node) => (this.node = node)}
      >
        <p className={isActive ? 'active-sub-filter' : ''}>Proficiency levels</p>
        <div className={`cl-filter-selection-list ${this.state.showOptions ? 'show' : ''}`}>
          {languageProficiency().map((o, i) => (
            <div
              key={i}
              className={`cl-filter-option ${optionSet.has(o.value) ? 'selected' : ''}`}
              onClick={(e) => this.updateFilterSelection(e, o.value, optionSet.has(o.value))}
            >
              {o.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
