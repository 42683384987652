import React from 'react';
import { Grid } from '@mui/material';
import { MultiSelectWithSearch } from 'sharedComponents/Select';
import { FilterContainerProps } from './types';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';

export const Filters: React.FC<FilterContainerProps> = ({
  filterValues,
  filterOptions,
  isMobile,
  setSelectedGradeOptions,
  setSelectedSubjectOptions,
}) => {
  return (
    <Grid container spacing={1} rowGap={0.5}>
      <Grid item xs={12} md={6} lg={'auto'}>
        <MultiSelectWithSearch
          displayName="Grade Levels"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.GRADE_FILTER}
          handleChange={setSelectedGradeOptions}
          options={filterOptions.gradeOptions}
          values={filterValues.gradeOptions}
          tagLimit={1}
          size="small"
          sx={{
            backgroundColor: ' #ffffff',
            width: isMobile ? '100%' : 325,
            borderRadius: '8px',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={'auto'}>
        <MultiSelectWithSearch
          displayName="Subjects"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SUBJECTS_FILTER}
          handleChange={setSelectedSubjectOptions}
          options={filterOptions.subjectOptions}
          values={filterValues.subjectOptions}
          tagLimit={1}
          size="small"
          sx={{
            backgroundColor: '#ffffff',
            width: isMobile ? '100%' : 325,
            borderRadius: '8px',
          }}
        />
      </Grid>
    </Grid>
  );
};
