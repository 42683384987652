import React from 'react';
import TooltipIcon from 'assets/tooltip.svg';
import ReactTooltip from 'react-tooltip';

const ChartHeaderTooltip = ({ id, children }) => {
  return (
    <>
      <img className="mbhalf" src={TooltipIcon} alt="tooltip" data-tip data-for={id} />
      <ReactTooltip id={id} effect="solid">
        <div style={{ textAlign: 'center' }}>{children}</div>
      </ReactTooltip>
    </>
  );
};

export const ChartHeaderMailtoLink = () => {
  return (
    <a href="mailto:support@example.com" style={{ textDecoration: 'underline' }}>
      support@hirenimble.com
    </a>
  );
};

export default ChartHeaderTooltip;
