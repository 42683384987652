import { BaseNimbleAPI } from './baseAPI';

/**
 * Corresponds to DistrictsViewSet
 */
class DistrictsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/districts' };
  }

  /**
   * Fetch all districts in a given state
   */
  async fetchDistrictsByState(state, parent_organizations_to_exclude = false) {
    const url = '/by_state/';
    const params = { state };
    if (parent_organizations_to_exclude) {
      params['parent_organizations_to_exclude'] = parent_organizations_to_exclude;
    }
    const response = await this.get(url, { params });
    return response.data;
  }
}

export default new DistrictsAPI();
