import useSWR from 'swr';

import referenceTemplateListAPI from 'api/referenceTemplateListAPI';

const KEY = referenceTemplateListAPI.config.baseURL;

/**
 * Data management wrapper for fetching a district's ReferenceTemplates.
 *
 * Usage: const { data, isLoading, error } = useReferenceTemplates();
 */
export const useReferenceTemplates = () => {
  const { data, error } = useSWR([KEY], () => referenceTemplateListAPI.fetchReferences());

  const isLoading = data === undefined;

  return { data: data ?? [], isLoading, error };
};
