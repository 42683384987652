import VariableRow from './VariableRow';
import styled from 'styled-components';
import { EmailTemplateVariablesProps } from './types';

export const EmailTemplateVariables: React.FC<EmailTemplateVariablesProps> = (props) => {
  const { handleVariableClick } = props;

  return (
    <Container id="emailTemplateVariables">
      <Heading className="mb1">Click to add variables</Heading>
      <div className="mb1">
        <SubHeading>{props.onProspectList ? 'Prospect' : 'Candidate'}</SubHeading>
        {props.candidateVariables.map((name, i) => (
          <VariableRow name={name} key={i} handleVariableClick={handleVariableClick} />
        ))}
      </div>
      <div className="mb1">
        <SubHeading>Administrators</SubHeading>
        {props.senderVariables.map((name, i) => (
          <VariableRow name={name} key={i} handleVariableClick={handleVariableClick} />
        ))}
      </div>
      {props.eventVariables && props.eventVariables.length > 0 && (
        <div>
          <SubHeading>Event</SubHeading>
          {props.eventVariables.map((name, i) => (
            <VariableRow name={name} key={i} handleVariableClick={handleVariableClick} />
          ))}
        </div>
      )}
      {props.referenceVariables && props.referenceVariables.length > 0 && (
        <div>
          <SubHeading data-testid="references-section">References</SubHeading>
          {props.referenceVariables.map((name, i) => (
            <VariableRow name={name} key={i} handleVariableClick={handleVariableClick} />
          ))}
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-left: 30px;
  flex-grow: 1;
  max-width: 235px;
`;

const Heading = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #444;
`;

const SubHeading = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 30px;
  color: #444;
`;
