import { BaseNimbleAPI } from './baseAPI';

class CategoriesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/categories' };
  }

  /**
   * Returns a list of all of the catgories for the specified district.
   */
  async fetchCategories({ districtId }) {
    const params = {
      district_id: districtId,
    };

    const url = '/';
    const response = await this.get(url, { params });
    return response.data;
  }

  async fetchCategoriesByState(state) {
    const url = '/by_state/';
    const params = { state };
    const response = await this.get(url, { params });
    return response.data;
  }
}

export default new CategoriesAPI();
