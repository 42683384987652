import { BaseNimbleAPI } from './baseAPI';
import {
  FlatFileTransferInterface,
  FlatFileTransferPayloadPOST,
  FlatFileTransferResponseInterface,
} from '../types/types';

class FlatFileTransferAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/flat-file-transfers' };
  }

  async getAll(): Promise<FlatFileTransferInterface[]> {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  async getFlatFile(id: string): Promise<FlatFileTransferResponseInterface> {
    const url = `/${id}/`;
    const response = await this.get(url);
    return response.data;
  }

  async updateFlatFile(id: string, updatedFlatFile: FlatFileTransferInterface['report_columns']) {
    const url = `/${id}/`;
    const response = await this.put(url, updatedFlatFile);
    return response.data;
  }

  async createFlatFile(newFlatFile: FlatFileTransferPayloadPOST) {
    const url = '/';

    const response = await this.post(url, newFlatFile);
    return response.data;
  }

  async downloadTestCSV(id: string) {
    const url = `/${id}/download_test_file/`;
    const response = await this.get(url, {
      responseType: 'blob',
    });
    return response;
  }
}

export default new FlatFileTransferAPI();
