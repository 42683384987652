import styled from 'styled-components';

export const DashboardHeaderContainerV2 = ({ title }) => {
  return (
    <DashboardTitleContainer>
      <TitleText>{title}</TitleText>
    </DashboardTitleContainer>
  );
};

const DashboardTitleContainer = styled.div``;

const TitleText = styled.div`
  font-size: 24px;
`;
