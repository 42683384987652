export enum LocationFilterDisplayNames {
  DISTANCE_DISPLAY_NAME = 'Distance',
  LOCATION_DISPLAY_NAME = 'Enter address or zip code',
}

export enum MobileDrawerButtonText {
  FILTERS = 'Apply Filters',
  LOCATION = 'Update',
  SEARCH = 'Search',
}

export enum MobileDrawerTitles {
  FILTERS = 'Filter',
  LOCATION = 'Location',
  SEARCH = 'Search',
}

export enum MobileDrawerTypes {
  FILTERS = 'Filters',
  LOCATION = 'Location',
  SEARCH = 'Search',
}

export const mobileSearchPlaceholderText = 'Search title, keywords, or organization';
export const searchLabelText = 'Search';
export const searchPlaceholderText = 'Search by job title, keywords, or organization';
