import { Component } from 'react';
import PropTypes from 'prop-types';

import HiredCandidateRow from './hiredCandidateRow';

/**
 * Check git history on this component if adding back the ability to match a candidate
 * to a vacancy from the hired list.
 */
export default class HiredCandidateList extends Component {
  static propTypes = {
    unplacedCandidates: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);

    this.state = {
      candidateViewerOpen: false,
    };
  }

  toggleCandidatesPanel = () => {
    this.setState((prevState) => {
      return { candidateViewerOpen: !prevState.candidateViewerOpen };
    });
  };

  render() {
    const { unplacedCandidates } = this.props;
    const classToAdd = this.state.candidateViewerOpen ? 'open' : '';

    return (
      <div className={`hired-candidates-list-component ${classToAdd}`}>
        <div className="open-button" onClick={this.toggleCandidatesPanel}>
          <div>Unplaced candidates</div>
        </div>
        <div className="slidey-dealie-inner">
          <div>Unplaced candidates</div>
          <div>
            The following candidates were moved into the hired status but have not yet been paired
            to an open position.
          </div>

          {unplacedCandidates.map((candidate) => (
            <HiredCandidateRow candidate={candidate} key={candidate.id} />
          ))}
        </div>
      </div>
    );
  }
}
