import styled from 'styled-components';

export const ActionContainer = styled.div`
  display: flex;
`;

export const ActionText = styled.div`
  align-items: center;
  color: #777777;
  line-height: 15px;
  margin: 5px;
`;

export const BulletPoint = styled.li`
  color: #777777;
  margin-right: 10px;
  padding: 0px 5px;
  width: 6px;
`;

export const ConfirmDeleteContainer = styled.div`
  background-color: #f6e9e9;
  border-radius: 4px;
  color: #444444;
  font-size: 14px;
  font-weight: bolder;
  margin: 5px;
  padding: 20px;
`;

export const InfoAndActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrimaryDeleteButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #de3255;
  border-radius: 2px;
  color: #de3255;
  font-size: 14px;
  height: 35px;
  margin-top: 5px;
  padding: 0.45em 1.75em;
  text-decoration: bold;
`;

export const SecondaryDeleteButton = styled(PrimaryDeleteButton)`
  background-color: transparent;
  margin-left: 10px;
`;

export const SelectionContainer = styled.div`
  border-bottom: 2px solid #e2e8ec;
  border-top: 2px solid #e2e8ec;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
`;

export const StyledEditIcon = styled.img`
  height: 15px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const StyledTrashIcon = styled.img`
  filter: invert(38%) sepia(48%) saturate(1602%) hue-rotate(311deg) brightness(89%) contrast(99%);
  height: 15px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const SubscriptionCategoriesContainer = styled.div`
  display: flex;
  font-size: 12px;
`;
