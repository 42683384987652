import useSWR from 'swr';

import schoolsAPI from 'api/schoolsAPI';

const KEY = '/api/school';

/**
 * Data management wrapper for fetching and managing ActiveSchools.
 *
 * Usage: const { data, isLoading, error } = useActiveSchools();
 */
export const useActiveSchools = (districtId = undefined) => {
  const { data, error } = useSWR([KEY, districtId], (key, districtId) =>
    schoolsAPI.fetchActiveSchools(districtId)
  );

  const isLoading = data === undefined;

  return { data: data ?? [], isLoading, error };
};
