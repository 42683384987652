export const getIconColor = (new_value: string): string => {
  switch (new_value) {
    case 'Archived': {
      return '#CC3340';
    }
    case 'Active, Open': {
      return '#037F56';
    }
    case 'Active, Closed': {
      return '#EC7729';
    }
    case 'Draft': {
      return '#5c5e69';
    }
    case 'Created': {
      return '#2175B9';
    }
    case undefined: {
      // this is the case when the verb is 'saved' and there is no action object
      return '#2175B9';
    }
    default: {
      return '#5c5e69';
    }
  }
};

export const activityOptions = ['Created', 'Saved', 'Updated'];
