import { BaseNimbleAPI } from './baseAPI';

class HellosignAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/hellosign' };
  }

  // remind a signer that they have a signature request awaiting
  async remind(payload) {
    const response = await this.post('/remind/', payload);
    return response.data;
  }

  // cancel a signature request
  async cancelRequest(signature_request_id) {
    const url = '/cancel_signature_request/';
    const response = await this.post(url, { signature_request_id });
    return response.data;
  }

  async getPendingForms(application_id) {
    const url = `/pending_signature_request_tasks/?application_id=${application_id}`;
    const response = await this.get(url);
    return response.data;
  }

  async getAllAssignedSignatures() {
    const url = '/all_signatures_assigned_to_me/';
    const response = await this.get(url);
    return response.data;
  }
}

export default new HellosignAPI();
