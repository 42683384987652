import PropTypes from 'prop-types';
import styled from 'styled-components';

import auth from 'utils/auth';
import usersAPI from 'api/usersAPI';
import { SingleClickDropdown } from 'ui-kit';
import { nimbleTheme } from 'theme';
import { DefaultContainer, PrintContainer } from 'features/PrintView/styles';

const options = [
  { value: 'Internal candidate', label: 'Internal candidate' },
  { value: 'External candidate', label: 'External candidate' },
];

export default function ExternalInternalToggle({
  user,
  district,
  replaceCandidateInState,
  refreshCandidateInList,
  fetchEvents,
}) {
  const handleChange = (value) => {
    const candidate = { ...user };
    if (value === 'Internal candidate') {
      candidate.profile.district_id = district.id;
    } else {
      candidate.profile.district_id = null;
    }
    replaceCandidateInState(candidate);
    usersAPI.setInternalExternal(candidate).then(() => {
      if (refreshCandidateInList) {
        // refreshCandidateInList is passed down from Quick Profile and not Full Profile
        refreshCandidateInList(candidate.id);
      }
      fetchEvents();
    });
  };

  // Internal candidates have same district_id as the admin; otherwise they're external
  let type = 'External candidate';

  if (user.profile.district_id === district.id) {
    type = 'Internal candidate';
  }

  if (!auth.isDistrictUser()) {
    return <p>{type}</p>;
  }

  return (
    <>
      <DefaultContainer>
        <SingleClickDropdown.Dropdown
          label={type}
          border={false}
          boxShadow={false}
          width="fit-content"
          paddingLeft="0"
          paddingRight="0"
          height="21px"
          dropdownListWidth="190px"
        >
          {options.map((option) => (
            <StyledListItem key={option.value} onClick={() => handleChange(option.value)}>
              {option.label}
            </StyledListItem>
          ))}
        </SingleClickDropdown.Dropdown>
      </DefaultContainer>
      <PrintToggle>{type}</PrintToggle>
    </>
  );
}

ExternalInternalToggle.propTypes = {
  user: PropTypes.object.isRequired,
  district: PropTypes.object.isRequired,
  replaceCandidateInState: PropTypes.func.isRequired,
  // Not passed down on Full Profile
  refreshCandidateInList: PropTypes.func,
  fetchEvents: PropTypes.func,
};

const StyledListItem = styled(SingleClickDropdown.ListItem)`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 6px;
  }

  &:last-child {
    padding-bottom: 6px;
  }
`;

const PrintToggle = styled(PrintContainer)({
  '@media print': {
    fontWeight: nimbleTheme.typography.fontWeightBold,
  },
});
