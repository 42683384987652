import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { ComboBoxProps } from './types';

export const ComboBox: React.FC<ComboBoxProps> = ({
  getOptionLabel,
  handleSetSearchInputValue,
  hasError,
  inputValue,
  loading,
  onChange,
  options,
  placeholder,
  renderOption,
  defaultValue,
  dataTestId,
  sx,
}) => {
  const handleSelection = (value) => {
    onChange(value);
  };

  return (
    <MuiAutocomplete
      freeSolo={inputValue?.length ? false : true}
      onChange={(_event, newValue: string | null) => {
        handleSelection(newValue);
      }}
      onInputChange={(_e, value) => {
        handleSetSearchInputValue(value);
      }}
      defaultValue={!defaultValue ? null : { title: defaultValue }}
      disablePortal
      id="combo-box"
      data-testid={dataTestId}
      options={inputValue?.length > 0 ? options : []}
      getOptionLabel={getOptionLabel}
      loading={inputValue?.length > 0 ? loading : false}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          error={hasError}
          {...params}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
      renderOption={renderOption}
      sx={sx}
    />
  );
};
