import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { ConfirmationDialogProps } from './types';

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  backLabel,
  confirmLabel,
  isOpen,
  resultHandler,
  text,
  title,
}) => {
  const onClose = () => {
    resultHandler(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title ? title : 'Delete'}</DialogTitle>
      <DialogContent>{text ? text : 'Are you sure you want to delete?'}</DialogContent>
      <DialogActions>
        <SecondaryButton onClick={() => resultHandler(false)}>
          {backLabel ? backLabel : 'Back'}
        </SecondaryButton>
        <PrimaryButton onClick={() => resultHandler(true)}>
          {confirmLabel ? confirmLabel : 'Delete'}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
