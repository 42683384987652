import './timeline.css';

import { parseEvent } from 'utils/util';
import { ProfileTimelineTooltipProps } from './types';

/**
 * Similar to the full ProfileTimeline component but specifically for use in
 * the Last Updated tooltip on Quick Profile.
 */
const ProfileTimelineTooltip: React.FC<ProfileTimelineTooltipProps> = ({ user, events }) => {
  return (
    <div className="abridged no-scroll">
      {events.slice(0, 5).map((event, i) => {
        const { label, subtext } = parseEvent(event, user, events);
        return (
          <div className="timeline-item for-tooltip" key={i}>
            <span className="timeline-label">
              {label}
              {subtext && <div className="subtext">{subtext}</div>}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ProfileTimelineTooltip;
