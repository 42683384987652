import { BaseNimbleAPI } from './baseAPI';

class CustomScorecardDetailAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/customscorecard' };
  }

  async updateScorecard(scorecard) {
    const response = await this.put(`/${scorecard.id}/`, scorecard);
    return response.data;
  }

  async deleteScorecard(scorecardId) {
    const response = await this.delete(`/${scorecardId}/`);
    return response.data;
  }
}

export default new CustomScorecardDetailAPI();
