import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

export const UploadFileHelperMessage: React.FC<AlertProps> = ({
  variant = 'filled',
  severity = 'info',
}) => {
  return (
    <>
      <UploadFileHelperMessageAlert variant={variant} severity={severity}>
        Note: If you&apos;re experiencing issues with this upload tool, you may need to update and
        restart your browser.
      </UploadFileHelperMessageAlert>
    </>
  );
};

const UploadFileHelperMessageAlert = styled(Alert)(() => ({
  backgroundColor: '#EDF2FF',
  borderRadius: '8px',
  color: '#1F3D99',
  fontFamily: 'Open Sans',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  marginTop: '8px',
}));
