import ReactQuill from 'react-quill';

import { candidateVariables, referenceVariables, senderVariables } from './utils';
import { TextEditorAndVariablesProps } from './types';
import { EmailTemplateVariables } from './EmailTemplateVariables';
import {
  ContentAndVariablesGrid,
  ErrorText,
  FormControlContainer,
  VariablesContainer,
} from './styles';

export const TextEditorAndVariables: React.FC<TextEditorAndVariablesProps> = ({
  content,
  emailTemplate,
  handleQuillChange,
  handleVariableClick,
  hasError,
  quillRef,
}) => {
  const isReferenceEmailTemplate = emailTemplate.type === 'Reference';
  const isGeneralTemplate = emailTemplate.type === 'General';
  const isApplicationConfirmationTemplate = emailTemplate.type === 'Application confirmation';

  return (
    <>
      <ContentAndVariablesGrid>
        <FormControlContainer>
          <ReactQuill
            ref={quillRef}
            // @ts-expect-error TS doesn't like that we're passing 'name' to react-quill, but for now it's necessary
            name="content"
            placeholder={`Enter your content here`}
            value={content}
            onChange={(value) => handleQuillChange(value)}
            style={{
              border: hasError ? '1px solid #D32F2F' : '',
            }}
            data-testid="content"
            id="content"
          />
          {hasError && <ErrorText data-testid="error-text">Content is a required field</ErrorText>}
        </FormControlContainer>

        <VariablesContainer>
          <EmailTemplateVariables
            handleVariableClick={handleVariableClick}
            candidateVariables={candidateVariables}
            senderVariables={
              isApplicationConfirmationTemplate ? ['District name'] : senderVariables
            }
            referenceVariables={isReferenceEmailTemplate ? referenceVariables : []}
            eventVariables={isGeneralTemplate ? ['Interview date & time'] : []}
          />
        </VariablesContainer>
      </ContentAndVariablesGrid>
    </>
  );
};
