import styled from 'styled-components';
import { grid } from 'styled-system';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { AltModal } from 'ui-kit';
import { scorecardQType } from '../../utils/enums';
import auth from 'utils/auth';

import ScorecardActivity from './ScorecardActivity';
import TextViewAnswer from './ViewerAnswerTypes/TextViewAnswer';
import MultipleChoiceViewAnswer from './ViewerAnswerTypes/MultipleChoiceViewAnswer';
import RubricViewAnswer from './ViewerAnswerTypes/RubricViewAnswer';
import CumulativeScoreViewAnswer from './ViewerAnswerTypes/CumulativeScoreViewAnswer';
import FinalRecommendationViewAnswer from './ViewerAnswerTypes/FinalRecommendationViewAnswer';
import DirectionsViewAnswer from './ViewerAnswerTypes/DirectionsViewAnswer';
import RatingViewAnswer from './ViewerAnswerTypes/RatingViewAnswer';
import AttachmentViewAnswer from './ViewerAnswerTypes/AttachmentViewAnswer';

import { ReactComponent as GrayArrow } from 'assets/icon_right_caret_grey.svg';
import { DownloadIconGrey } from 'ui-kit/icons';

export default function ScorecardViewer({ onBack, onEdit, scorecard }) {
  const renderQuestion = (item) => {
    if (
      item.question_type === scorecardQType.rubric ||
      item.question_type === scorecardQType.direction_text
    ) {
      return null;
    }

    let question = '';
    if (item.question_type === scorecardQType.cumulative_score) {
      question = 'Cumulative score:';
    } else if (item.question_type === scorecardQType.final_recommendation) {
      question = 'Do you recommend this candidate?';
    } else {
      question = item.question_text;
    }

    return (
      <>
        <QuestionText>{question}</QuestionText>
        {item.is_required && <sup>*</sup>}
      </>
    );
  };

  const renderAnswer = (answer) => {
    switch (answer.question_type) {
      case scorecardQType.rating:
        return <RatingViewAnswer answer={answer} />;
      case scorecardQType.nimble:
      case scorecardQType.text:
        return <TextViewAnswer answer={answer} />;
      case scorecardQType.multiple_choice:
        return <MultipleChoiceViewAnswer answer={answer} />;
      case scorecardQType.cumulative_score:
        return <CumulativeScoreViewAnswer parentObject={scorecard} />;
      case scorecardQType.direction_text:
        return <DirectionsViewAnswer answer={answer} />;
      case scorecardQType.final_recommendation:
        return <FinalRecommendationViewAnswer answer={answer} parentObject={scorecard} />;
      case scorecardQType.rubric:
        return <RubricViewAnswer answer={answer} />;
      case scorecardQType.attachment:
        return <AttachmentViewAnswer answer={answer} urlBase="scorecard_answer" />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <ModalBody>
        <BackButtonContainer onClick={onBack}>
          <BackButtonIcon />
          Return to scorecard summary
        </BackButtonContainer>

        <HeaderContainer>
          <HeaderText>{scorecard.template_name}</HeaderText>
          <Link to={`/view-scorecard/${scorecard.id}`} rel="noopener noreferrer" target="_blank">
            <DownloadIconGrey
              data-tip
              data-for="download-scorecard"
              data-place="bottom"
              data-delay-show="100"
            />
          </Link>
          <ReactTooltip id="download-scorecard" effect="solid">
            <span style={{ color: 'white' }}>Download scorecard</span>
          </ReactTooltip>
        </HeaderContainer>

        <ScorecardActivity events={[]} />
        {scorecard.answers.map((item) => (
          <AnswerItem key={item.id}>
            {renderQuestion(item)}
            {renderAnswer(item)}
          </AnswerItem>
        ))}
      </ModalBody>
      <FooterContainer gridTemplateColumns={['1fr', '150px 186px']}>
        {(auth.isSuperAdminUser() || auth.getUser().email === scorecard.submitted_by) && (
          <EditButton onClick={() => onEdit(scorecard)}>Edit Scorecard</EditButton>
        )}
        <SubmitButton onClick={onBack}>Done</SubmitButton>
      </FooterContainer>
    </>
  );
}

ScorecardViewer.propTypes = {
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  scorecard: PropTypes.object.isRequired,
};

const ModalBody = styled(AltModal.Body)`
  position: relative;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;

  margin-bottom: 24px;
  margin-top: 30px;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #444444;
`;

const QuestionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
`;

const BackButtonContainer = styled.div`
  color: var(--darkgray) !important;
  font-size: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;

  width: fit-content;
  position: absolute;
  top: 20px;
`;

const BackButtonIcon = styled(GrayArrow)`
  transform: rotate(180deg);
  margin-right: 7px;
`;

const AnswerItem = styled.div`
  margin-bottom: 50px;
`;

const FooterContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  borderRadius: '3px',

  width: '100%',
});

const EditButton = styled(FooterButton)({
  backgroundColor: '#FFFFFF',
  border: '1px solid #CACACA',
  color: '#CACACA',
});

const SubmitButton = styled(FooterButton)`
  background: #00b88d;
  border: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
`;
