import { Grid, styled, SxProps, Theme } from '@mui/material';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import NearMeIcon from '@mui/icons-material/NearMe';
import { MobileDrawerButtonGroupProps } from './types';

export const MobileDrawerControlButtonGroup: React.FC<MobileDrawerButtonGroupProps> = ({
  buttonTitles = ['Submit'],
  onClickHandlers,
  activeDrawers,
}) => {
  const theme = useTheme();

  const icons: Record<string, React.ReactNode> = {
    Search: <SearchIcon />,
    Location: <NearMeIcon />,
    Filter: <FilterListIcon />,
  };

  return (
    <ButtonGroupContainer container justifyContent="center" wrap="nowrap">
      {buttonTitles.map((title, index) => (
        <Grid key={index} item xs={12 / buttonTitles.length}>
          <SecondaryButton
            size="medium"
            startIcon={icons[title] || null}
            sx={buttonStyles(theme, activeDrawers[index])}
            onClick={onClickHandlers[index]}
            iconColor={activeDrawers[index] ? theme.palette.primary.main : 'inherit'}
          >
            {title}
          </SecondaryButton>
        </Grid>
      ))}
    </ButtonGroupContainer>
  );
};

const ButtonGroupContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  columnGap: theme.spacing(1),
  flexWrap: 'nowrap',
}));

export const buttonStyles = (theme: Theme, isActive: boolean): SxProps<Theme> => ({
  display: 'flex',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.gray.mediumDark,
  border: `1px solid ${isActive ? theme.palette.primary.main : theme.palette.gray.darker}`,
  borderRadius: theme.spacing(1),
  fontSize: '14px',
  width: '100%',
});
