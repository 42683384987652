import { Component } from 'react';
import PropTypes from 'prop-types';
import CandidatesListFilterDrop from 'ui-kit/ListFilterDropdown';
import ListFilterDropdownWithInput from 'ui-kit/ListFilterDropdownWithInput';
import ExperienceFilter from './ExperienceFilter';
import FilterGroup from './FilterGroup';
import CurrentUserWithBadgeListFilter from 'ui-kit/CurrentUserWithBadgeListFilter';
import CandidateAvailabilityFilter from './CandidateAvailabilityFilter';
import CandidateLanguageFilter from './CandidateLanguageFilter';
import GradesFilter from './GradesFilter';
import SchoolTypeFilter from './SchoolTypeFilter';
import SchoolPreferenceFilter from './SchoolPreferenceFilter';
import TaskFilter from './TaskFilter';
import SetAsDefaultButton from './SetAsDefault';

import {
  internalList,
  roleStatusesForSearchFilters,
  preferenceCategories,
  grades,
  preferenceOptions,
} from 'utils/enums';
import auth from 'utils/auth';
import { validateTagUserType } from '../../CustomTag/util';
import { Feature } from '../../../hoc/Feature';
import { featureTags } from '../../../context/featureContext';

export default class CandidatesFilters extends Component {
  constructor(props) {
    super(props);
    const district = auth.getUser().profile.district;
    this.district_indiana_district = district.indiana_district;
    this.district_id = district.id;
    this.district_state = district.state;
  }

  static propTypes = {
    statusOptions: PropTypes.arrayOf(PropTypes.object),
    experienceStart: PropTypes.number,
    experienceEnd: PropTypes.number,
    /**
     * Supplied by the user who wants to filter a candidate list based on the kind of application the candidates
     * are applying for. Right now they must enter a string which is partially matched against the DB, but this
     * ought to be an autocomplete experience.
     */
    updateMultiSelectFilter: PropTypes.func.isRequired,
    updateMultiSelectFilterWithMultipleValues: PropTypes.func.isRequired,
    status_list: PropTypes.arrayOf(PropTypes.number).isRequired,
    job_status_list: PropTypes.arrayOf(PropTypes.number).isRequired,
    internal_list: PropTypes.arrayOf(PropTypes.number).isRequired,
    cred_list: PropTypes.arrayOf(PropTypes.number).isRequired,
    job_subject_list: PropTypes.arrayOf(PropTypes.number).isRequired,
    task_list: PropTypes.arrayOf(PropTypes.string).isRequired,
    tag_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    hrbp_assignees: PropTypes.arrayOf(PropTypes.number).isRequired,
    hrbpAssigneeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    hellosign_templates: PropTypes.array.isRequired,
    language_list: PropTypes.arrayOf(PropTypes.string).isRequired,
    employment_type_and_hours: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    credentialOptions: PropTypes.array.isRequired,
    updateExperienceState: PropTypes.func.isRequired,
    selectAll: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
    universal_tasks: PropTypes.array.isRequired,
    visible_templates: PropTypes.array.isRequired,
    multilingual_school: PropTypes.array.isRequired,
    title_1_school: PropTypes.array.isRequired,
    turnaround_school: PropTypes.array.isRequired,
    grades: PropTypes.array.isRequired,
    preference_categories: PropTypes.array.isRequired,
    onboarding_locations: PropTypes.array.isRequired,
    activeDistrictSchools: PropTypes.array.isRequired,
    schoolPreferenceFilters: PropTypes.object.isRequired,
    selectAllSchoolPreferencesFilters: PropTypes.func.isRequired,
    clearAllSchoolPreferencesFilters: PropTypes.func.isRequired,
    updateSelectedSchoolIds: PropTypes.func.isRequired,
    updateIsOpenToOtherOptions: PropTypes.func.isRequired,
    isSchoolPreferenceFilterActive: PropTypes.func.isRequired,
    candidate_source_list: PropTypes.arrayOf(PropTypes.string).isRequired,
    candidateSourceOptions: PropTypes.array,
    all_sources: PropTypes.arrayOf(PropTypes.object),
  };

  getSubcategories = () => {
    /** this.props.categories is a list of district categories, but we want to filter based on
     * subcategories. Iterate through props.categories and return all subcategories.
     */
    const subcategories = [];
    const categories = this.props.categories;
    categories.forEach((c) => {
      c.subcategories.forEach((s) => {
        // CandidatesListFilterDrop expects it to be in this format: { value: 3, label: 'Math' }
        subcategories.push({ value: s.id, label: s.label });
      });
    });

    return subcategories;
  };

  getTaskOptions = () => {
    let options = this.props.universal_tasks.concat(this.props.visible_templates);
    options.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });

    const finalOptions = [];
    options.forEach((option) => {
      const fieldName = option.template_id === undefined ? 'task_list' : 'hellosign_templates';

      // for hellosign_templates, use the 'template_id' field in order to avoid id collisions
      // due to the fact that these come from two different db tables.
      const idField = fieldName === 'task_list' ? 'id' : 'template_id';

      // stringify the id since the template_id field is a string and it's used as id
      // in some cases.

      finalOptions.push({
        value: String(option[idField]),
        label: `${option.title} complete`,
        fieldName,
      });
      // each option has to be in the dropdown twice, once for complete and once for incomplete.
      // since the value must be unique, use a negative sign prefix for incomplete.
      finalOptions.push({
        value: `-${option[idField]}`,
        label: `${option.title} incomplete`,
        fieldName,
      });
    });

    return finalOptions;
  };

  // CandidatesListFilterDrop expects this format: { value: 3, label: 'Good candidate' }
  getCandidateTagOptions = () =>
    this.props.tags.map(({ id, label }) => ({ value: id, label: label }));

  isTaskFilterActive = () => {
    const totalLength = this.props.universal_tasks.concat(this.props.visible_templates).length;
    const currentLength = this.props.task_list.concat(this.props.hellosign_templates).length;
    // multiply total length by 2 because each task has 2 associated
    // options: complete and incomplete
    return currentLength !== 0 && currentLength !== totalLength * 2;
  };

  isSchoolTypeFilterActive = () => {
    let { multilingual_school, title_1_school, turnaround_school } = this.props;

    let schoolTypeAllSelected =
      multilingual_school.length + title_1_school.length + turnaround_school.length ===
      preferenceOptions().length * 3;
    let schoolTypeNoneSelected =
      multilingual_school.length === 0 &&
      title_1_school.length === 0 &&
      turnaround_school.length === 0;
    let schoolTypeActive = !(schoolTypeAllSelected || schoolTypeNoneSelected);

    return schoolTypeActive;
  };

  isPreferenceCategoriesActive = () => {
    const { preference_categories } = this.props;
    return ![0, preferenceCategories().length].includes(preference_categories.length);
  };

  isGradesFilterActive = () => {
    const selectedGrades = this.props.grades;
    const allSelected = selectedGrades.length === grades().length;
    const noneSelected = selectedGrades.length === 0;
    const isActive = !(allSelected || noneSelected);
    return isActive;
  };

  // verify that current user can view at least one district tag
  userCanViewTagSection = (tags) => tags.some((tag) => validateTagUserType(auth.getUser(), tag));

  isSchoolPreferenceFilterActive = () => {
    const { isOpenToOtherOptions, selectedSchoolIds } = this.props.schoolPreferenceFilters;
    const allOptionsSelected =
      selectedSchoolIds.length === this.props.activeDistrictSchools.length && isOpenToOtherOptions;
    const noOptionsSelected = selectedSchoolIds.length === 0 && !isOpenToOtherOptions;
    const isActive = !(allOptionsSelected || noOptionsSelected);
    return isActive;
  };

  render() {
    return (
      <div className="candidateslist-filters">
        <div>
          <CandidatesListFilterDrop
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            displayName={'Candidate Status'}
            fieldName={'status_list'}
            values={this.props.status_list}
            options={this.props.statusOptions}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          <Feature
            featureName={featureTags.SOURCE_FILTER}
            fallback={<div style={{ display: 'None' }} />}
          >
            <CandidatesListFilterDrop
              updateMultiSelectFilter={this.props.updateMultiSelectFilter}
              displayName={'Source'}
              fieldName={'candidate_source_list'}
              values={this.props.candidate_source_list}
              options={this.props.candidateSourceOptions}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
            />
          </Feature>

          {/* Spec: only District admins and Super admins can view filter */}
          {auth.isDistrictAdmin(auth.getUser()) && (
            <CurrentUserWithBadgeListFilter
              handleChange={this.props.updateMultiSelectFilter}
              isMultiSelectFilter={true}
              displayName={'HR/Onboarding Partner'}
              fieldName={'hrbp_assignees'}
              values={this.props.hrbp_assignees}
              options={this.props.hrbpAssigneeOptions}
              isActive={
                this.props.hrbp_assignees.length &&
                this.props.hrbp_assignees.length !== this.props.hrbpAssigneeOptions.length
              }
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
            />
          )}
          <CandidatesListFilterDrop
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            displayName={'Internal/External'}
            fieldName={'internal_list'}
            values={this.props.internal_list}
            options={internalList()}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          <CandidatesListFilterDrop
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            displayName={'Credential'}
            fieldName={'cred_list'}
            values={this.props.cred_list}
            options={this.props.credentialOptions}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          {auth.hasOnboardingModule() && (
            <TaskFilter
              taskOptions={this.props.universal_tasks}
              templateOptions={this.props.visible_templates}
              values={this.props.task_list.concat(this.props.hellosign_templates)}
              options={this.getTaskOptions()}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
              isActive={this.isTaskFilterActive()}
              handleChange={this.props.updateMultiSelectFilter}
            />
          )}
          <ExperienceFilter
            experienceStart={this.props.experienceStart}
            experienceEnd={this.props.experienceEnd}
            updateExperienceState={this.props.updateExperienceState}
          />
          <CandidatesListFilterDrop
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            displayName={'Job Status'}
            fieldName={'job_status_list'}
            values={this.props.job_status_list}
            options={roleStatusesForSearchFilters()}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          <CandidatesListFilterDrop
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            displayName={'Job Category'}
            fieldName={'job_subject_list'}
            values={this.props.job_subject_list}
            options={this.getSubcategories()}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          <FilterGroup
            title={'Candidate Preferences'}
            isActive={
              this.isSchoolTypeFilterActive() ||
              this.isGradesFilterActive() ||
              this.isPreferenceCategoriesActive() ||
              this.isSchoolPreferenceFilterActive()
            }
          >
            <SchoolTypeFilter
              updateMultiSelectFilter={this.props.updateMultiSelectFilter}
              multilingual_school={this.props.multilingual_school}
              title_1_school={this.props.title_1_school}
              turnaround_school={this.props.turnaround_school}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
            />
            <GradesFilter
              updateMultiSelectFilter={this.props.updateMultiSelectFilter}
              updateMultiSelectFilterWithMultipleValues={
                this.props.updateMultiSelectFilterWithMultipleValues
              }
              grades={this.props.grades}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
            />
            <CandidatesListFilterDrop
              updateMultiSelectFilter={this.props.updateMultiSelectFilter}
              displayName={'Job Category'}
              fieldName={'preference_categories'}
              values={this.props.preference_categories}
              options={preferenceCategories()}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
              isSubFilter={true}
            />
            <SchoolPreferenceFilter
              isActive={this.isSchoolPreferenceFilterActive()}
              selectAll={this.props.selectAllSchoolPreferencesFilters}
              clearAll={this.props.clearAllSchoolPreferencesFilters}
              activeDistrictSchools={this.props.activeDistrictSchools}
              selectedSchoolIds={this.props.schoolPreferenceFilters.selectedSchoolIds}
              isOpenToOtherOptions={this.props.schoolPreferenceFilters.isOpenToOtherOptions}
              updateSelectedSchoolIds={this.props.updateSelectedSchoolIds}
              updateIsOpenToOtherOptions={this.props.updateIsOpenToOtherOptions}
            />
          </FilterGroup>
          <CandidateLanguageFilter
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            updateMultiSelectFilterWithMultipleValues={
              this.props.updateMultiSelectFilterWithMultipleValues
            }
            language_list={this.props.language_list}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          <CandidateAvailabilityFilter
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            updateMultiSelectFilterWithMultipleValues={
              this.props.updateMultiSelectFilterWithMultipleValues
            }
            employment_type_and_hours={this.props.employment_type_and_hours}
            selectAll={this.props.selectAll}
            clearAll={this.props.clearAll}
          />
          {this.props.tags?.length > 0 && this.userCanViewTagSection(this.props.tags) && (
            <CandidatesListFilterDrop
              updateMultiSelectFilter={this.props.updateMultiSelectFilter}
              displayName={'Candidate Tags'}
              fieldName={'tag_ids'}
              values={this.props.tag_ids}
              options={this.getCandidateTagOptions()}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
            />
          )}
          {auth.hasOnboardingModule() && auth.isDistrictUser() && (
            <ListFilterDropdownWithInput
              updateMultiSelectFilter={this.props.updateMultiSelectFilter}
              displayName={'Onboarding Location'}
              fieldName={'onboarding_locations'}
              values={this.props.onboarding_locations}
              options={this.props.activeDistrictSchools.map((s) => ({
                value: s.id,
                label: s.name,
              }))}
              selectAll={this.props.selectAll}
              clearAll={this.props.clearAll}
            />
          )}
          <SetAsDefaultButton />
        </div>
      </div>
    );
  }
}
