import { Component } from 'react';

import PropTypes from 'prop-types';

import StatusSwitcher from '../../CandidatesList/QuickProfile/statusSwitcher';
import ApplicationStatusesAPI from 'api/applicationStatusesAPI';
import { appStatusType } from '../../../utils/enums';

export default class StatusView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiringPoolOrder: 0,
    };
  }
  static propTypes = {
    user: PropTypes.object.isRequired,
    updateUserStatus: PropTypes.func.isRequired,
    // Not required when viewing a schoolapplication
    updateSchoolApp: PropTypes.func,
    // Not required when viewing a schoolapplication
    viewStatusModal: PropTypes.func,
    applicationBeingQuickviewed: PropTypes.object,
    schoolapplications: PropTypes.arrayOf(PropTypes.object),
    isSchoolUser: PropTypes.bool.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    emailTemplatesList: PropTypes.array.isRequired,
    adminUsers: PropTypes.array.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    districtApplicationBeingViewedBySchoolAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const hiringPool = this.props.newApplicationStatuses.find(
      (s) => s.status_type === appStatusType.hiring_pool
    );
    if (hiringPool) {
      this.setState({ hiringPoolOrder: hiringPool._order });
    } else {
      ApplicationStatusesAPI.getAll({
        statusTypes: [appStatusType.hiring_pool],
      }).then((data) => {
        this.setState({ hiringPoolOrder: data[0]._order });
      });
    }
  }

  getStatusOptions = () => {
    // can't move status to draft
    let statusOptions = this.props.newApplicationStatuses.filter(
      (s) => s.status_type !== appStatusType.draft
    );

    if (this.props.isSchoolUser) {
      if (this.props.districtApplicationBeingViewedBySchoolAdmin) {
        // if the school admin is viewing a district application
        // they can move the application into statuses meeting the following criteria:
        // 1) order comes before hiring pool and marked as school_admin_move_access=True
        // OR 2) status_type exactly equal to hiring pool and school_admin_move_district=True
        // OR 3) status_type archived and post hiring pool and school_admin_move_district=True
        statusOptions = statusOptions.filter((status) => {
          const isPreHiringPool = status._order < this.state.hiringPoolOrder;
          const isHiringPool = status.status_type === appStatusType.hiring_pool;
          const isPostHiringPool = status._order > this.state.hiringPoolOrder;
          const isArchived = status.status_type === appStatusType.archived;

          return (
            (isPreHiringPool && status.school_admin_move_access === true) ||
            (isHiringPool && status.school_admin_move_district === true) ||
            (isPostHiringPool && isArchived && status.school_admin_move_district === true)
          );
        });
      } else {
        // If viewing a schoolapplication, can only move to post SHP statuses
        statusOptions = statusOptions.filter(
          (status) => status.school_admin_move_access && status._order >= this.state.hiringPoolOrder
        );
      }
    }
    if (
      !this.props.applicationBeingQuickviewed ||
      ![appStatusType.hired, appStatusType.onboarding].includes(
        this.props.applicationBeingQuickviewed.new_status.status_type
      )
    ) {
      statusOptions = statusOptions.filter((s) => s.status_type !== appStatusType.onboarding);
    }
    return statusOptions;
  };

  toggleScreen = (statusObj) => {
    let { applicationBeingQuickviewed } = this.props;

    if (this.props.isDistrictUser) {
      // If want to move app to equal or after SHP status AND the current status is past SHP
      if (
        statusObj.status_type >= appStatusType.hiring_pool &&
        applicationBeingQuickviewed.new_status.status_type >= appStatusType.hiring_pool
      ) {
        if (
          this.props.schoolapplications.length === 1 &&
          statusObj.status_type !== appStatusType.archived &&
          statusObj.status_type !== appStatusType.onboarding
        ) {
          /* If only one schoolapp, no need to open the school selection modal,
           * unless the the target status is archived or offer declined (b/c these also)
           * offer ability to archive the district application
           */
          this.props.updateSchoolApp({
            candidate_id: this.props.user.id,
            application_id: this.props.schoolapplications[0].id,
            target_status: statusObj.id,
            schoolappsToModify: [this.props.schoolapplications[0]],
          });
        } else if (
          this.props.schoolapplications.length > 0 &&
          // don't need to select schools for onboarding status. There is backend logic that
          // sets all apps/schoolsapps from the hired status into the relevant onboarding status.
          statusObj.status_type !== appStatusType.onboarding
        ) {
          // If there are more than 0 schoolapplications, open the modal to select schools
          this.props.viewStatusModal(statusObj);
        } else {
          // If not moving past SHP, just advance the district application
          this.props.updateUserStatus({
            candidate_id: this.props.user.id,
            application_to_update: applicationBeingQuickviewed.id,
            target_status: statusObj.id,
          });
        }
      } else {
        this.props.updateUserStatus({
          candidate_id: this.props.user.id,
          application_to_update: applicationBeingQuickviewed.id,
          target_status: statusObj.id,
        });
      }
    } else {
      // If it's a school admin...
      if (this.props.districtApplicationBeingViewedBySchoolAdmin) {
        // ...changing a district application status
        this.props.updateUserStatus({
          candidate_id: this.props.user.id,
          application_to_update: applicationBeingQuickviewed.id,
          target_status: statusObj.id,
          archiveOtherApps: true,
        });
      } else {
        // ...changing a schoolapplication status
        this.props.updateSchoolApp({
          candidate_id: this.props.user.id,
          application_id: applicationBeingQuickviewed.id,
          target_status: statusObj.id,
          schoolappsToModify: [applicationBeingQuickviewed],
        });
      }
    }
  };

  render() {
    const { user, applicationBeingQuickviewed } = this.props;

    return (
      <StatusSwitcher
        user={user}
        application={applicationBeingQuickviewed}
        toggleScreen={this.toggleScreen}
        statusOptions={this.getStatusOptions()}
        emailTemplatesList={this.props.emailTemplatesList}
        adminUsers={this.props.adminUsers}
      />
    );
  }
}
