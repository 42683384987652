import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { useTheme } from '@mui/material/styles';
import { ConnectMarketingMessageAndButtonProps } from '../types';
import { ConnectMarketingInputAndButtonDataTestIds } from 'data-testids/ConnectDataTestIds';
import auth from 'utils/auth';
import { useHistory } from 'react-router';
import { checkUserProfileCompletion } from 'features/Connect/features/ExpressInterest/utils';
import {
  connectRedirectUrls,
  connectUrlParams,
} from 'features/Connect/features/JobDiscovery/constants';
import { buildUrl } from 'utils/util';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';

export function ConnectMarketingMessageAndButton({
  isLoading,
}: ConnectMarketingMessageAndButtonProps): React.ReactElement {
  const stateCode = useStateParam();
  const theme = useTheme();
  const user = auth.getUser();
  const history = useHistory();

  const onClickHandler = () => {
    if (!user) {
      history.push(
        buildUrl(connectRedirectUrls.SIGN_UP, { [connectUrlParams.REDIRECT]: stateCode })
      );
    } else if (!user?.preference?.nimble_connect_profile?.is_completed) {
      checkUserProfileCompletion(user, history, stateCode);
    } else {
      history.push(
        buildUrl(connectRedirectUrls.PROFILE, { [connectUrlParams.REDIRECT]: stateCode })
      );
    }
  };

  return (
    <Stack spacing={2}>
      <StyledText>
        Get discovered by hundreds of principals by filling out your profile and job preferences
      </StyledText>
      <PrimaryButton
        size="small"
        isLoading={isLoading}
        dataTestId={ConnectMarketingInputAndButtonDataTestIds.COMPLETE_PROFILE_BUTTON}
        sx={primaryButtonStyles(theme)}
        onClick={onClickHandler}
      >
        Complete your profile now
      </PrimaryButton>
    </Stack>
  );
}

const primaryButtonStyles = (theme) => ({
  color: theme.palette.white.main,
  background: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  display: 'flex',
  width: '275px',
  borderRadius: '16px',
  padding: '15px 32px',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: '8px',
  fontSize: '16px',
  fontWeight: '700',
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
});

const StyledText = styled(Typography)(({ theme }) => ({
  color: '#475467',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));
