import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MentionsInput, Mention } from 'react-mentions';
import mentionStyles from '../../../Profile/InternalCandidateNotes/mentionStyles.js';

export default class VacancyRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      taggedUsers: [],
    };
  }

  static propTypes = {
    closeNoteWindow: PropTypes.func.isRequired,
    position: PropTypes.object.isRequired,
    onSubmitNote: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object),
  };

  componentWillReceiveProps(nextProps) {
    this.forceUpdate();
    if (nextProps) {
      this.setState({ loaded: true });
    }
  }

  componentWillUnmount() {
    this.ignoreSetState = true;
  }

  addNote = async () => {
    const { taggedUsers, text } = this.state;
    const regExp = /@\[(.*?)\]/g;
    const finalUserList = text.match(regExp) || [];

    if (taggedUsers.length !== finalUserList.length) {
      // if these lengths are different, a tag was removed. Search
      // for the user(s) not present in finalUserList, and remove.
      let i = taggedUsers.length;
      while (i--) {
        let fullName = `${taggedUsers[i].first_name} ${taggedUsers[i].last_name}`;
        // substring below changes '@[First Name]' to 'First name', for comparison with fullName
        if (!finalUserList.find((item) => item.substring(2, item.length - 1) === fullName)) {
          taggedUsers.splice(i, 1);
        }
      }
    }

    await this.props.onSubmitNote(text, taggedUsers);
    this.clearStaging();
  };

  clearStaging = () => {
    const text = '';
    const taggedUsers = [];
    if (!this.ignoreSetState) {
      this.setState({
        text,
        taggedUsers,
      });
    }
  };

  renderUserSuggestion = (suggestion, search, highlightedDisplay) => {
    return <div>{highlightedDisplay}</div>;
  };

  onAdd = (id) => {
    /** callback function that runs when a user is tagged. Add tagged user to state, and email that list
     *  when the note is published (which happens in the addNote function).
     */
    const taggedUsers = this.state.taggedUsers;
    const user = this.props.adminUsers.find((admin) => admin.id === id);
    // don't tag same user multiple times, because that will send multiple emails
    if (taggedUsers.includes(user)) {
      return;
    }
    taggedUsers.push(user);
    this.setState({ taggedUsers });
  };

  renderNote = (note) => {
    return (
      <div className="note-textarea mb1" key={note.id}>
        <div className="note-textarea bold">{note.created_by}</div>
        <div className="note-textarea sub-text">
          {moment(note['created_at']).format('MM/D/YYYY, h:mm a')}
        </div>
        <div>{note.text}</div>
      </div>
    );
  };

  render() {
    return (
      <div className="note-textarea position-row-note-container" ref={(node) => (this.node = node)}>
        <div
          className={`note-textarea existing-notes ${
            this.props.position.notes.length === 0 ? 'empty' : ''
          }`}
        >
          {this.props.position.notes.map((n) => this.renderNote(n))}
        </div>

        <MentionsInput
          value={this.state.text}
          onChange={(e) => this.setState({ text: e.target.value })}
          placeholder={"Mention people using '@'"}
          style={mentionStyles}
          markup="@[__display__]"
          displayTransform={(id, display) => `@${display}`}
          className="note-textarea clickable"
          autoFocus={true}
        >
          <Mention
            trigger="@"
            style={{ backgroundColor: 'var(--gray)' }}
            data={this.props.adminUsers}
            renderSuggestion={this.renderUserSuggestion}
            onAdd={this.onAdd}
            className="clickable"
            appendSpaceOnAdd={true}
          />
        </MentionsInput>

        <div className="buttons">
          <button onClick={this.props.closeNoteWindow} className="cancel-button mr1">
            Cancel
          </button>
          <button
            onClick={this.addNote}
            className={`submit ${this.state.text.length === 0 ? 'disabled-submit' : ''}`}
            disabled={this.state.text.length === 0}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}
