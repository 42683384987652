import { Box } from '@mui/material';
import { ModalCTAButtonsProps } from './types';
import { PrimaryButton, SecondaryButton, TextButton } from 'sharedComponents/Buttons';

export const ModalCTAButtons: React.FC<ModalCTAButtonsProps> = ({
  emailTemplateId,
  handleDeleteTemplate,
  hasErrors,
  onHide,
  onSave,
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
      <Box>
        <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>
        {emailTemplateId && (
          <TextButton onClick={handleDeleteTemplate} sx={{ marginLeft: '8px' }}>
            Delete Template
          </TextButton>
        )}
      </Box>
      <PrimaryButton disabled={hasErrors} onClick={onSave}>
        Save Email Template
      </PrimaryButton>
    </Box>
  );
};
