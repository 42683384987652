import { createFormFieldConfig, minLengthRule, requiredRule } from '../../../../utils/formConfig';

/**
 * Object representation of login form
 *
 * FORMAT: Input Label, Input name, Input type
 */
const signinForm = {
  email: {
    ...createFormFieldConfig('Email address', 'email', 'email'),
    validationRules: [requiredRule('email'), minLengthRule('email', 4)],
  },
  password: {
    ...createFormFieldConfig('Password', 'password', 'password'),
    validationRules: [requiredRule('password'), minLengthRule('password', 6)],
  },
};

export default signinForm;
