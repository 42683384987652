import { BaseNimbleAPI } from './baseAPI';

import { nimbleProduct } from 'types/types';

class LazyRegistrationAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/auth/lazy_register/' };
  }

  async registerUser(
    email: string,
    agreeTos: boolean,
    nimbleProduct: nimbleProduct,
    redirectTo: string,
    signupAction?: string
  ) {
    const url = '/';
    const response = await this.post(url, {
      email: email,
      agree_tos: agreeTos,
      nimble_product: nimbleProduct,
      redirect_to: encodeURIComponent(redirectTo),
      signup_action: signupAction,
    });
    return response.data;
  }
}

export default new LazyRegistrationAPI();
