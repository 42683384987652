import { Fragment, useState } from 'react';

import { AltModal, RadioButton, CheckButton, VacancyInput } from 'ui-kit';
import { Modal } from 'react-bootstrap';
import { space, layout } from 'styled-system';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import auth from 'utils/auth';

import { searchStringInObjectKeys } from '../../utils/locationsModalUtil';
import LocationsModalSearch from '../common/LocationsModalSearch';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import Tooltip from '../../assets/tooltip.svg';
import { ATSJobEditTestIds } from '../../data-testids/ATS';

// PROPS DESCRIPTION:
// schoolOptions, // Array of groups and schools.  Groups have the isGroupHeader field
// locations, // school names as keys and locations as values
// districtVacancies // Decimal number
// show // visibility flag for modal
// onHide // hides the modal
// onSave // save callback

export default function LocationsModal(props) {
  const [locations, setLocations] = useState(props.locations);
  const [locationsShowing, setLocationsShowing] = useState({ ...props.locations });
  // Same as above, but for groups
  const groups = {};
  props.schoolOptions
    .filter((so) => so.isGroupHeader)
    .forEach((g) => {
      groups[g.name] = true;
    });
  const [groupsShowing, setGroupsShowing] = useState(groups);
  const [districtVacancies, setDistrictVacancies] = useState(props.districtVacancies);

  const hasSchoolrolesChecked = () => {
    const schoolsChecked = Object.values(locations).filter((l) => l.checked);
    return schoolsChecked.length > 0;
  };

  const hasSchoolRouting = () => {
    const isAnyLocationChecked = Object.keys(locations).some((n) => {
      return locations[n].checked;
    });
    return isAnyLocationChecked;
  };

  // If there are school FTEs or Locations selected without FTEs show schools first
  const [vacanciesMode, setVacanciesMode] = useState(
    hasSchoolrolesChecked() || hasSchoolRouting() ? 'school' : 'district'
  );
  const [listMode, setListMode] = useState('schools'); // schools or groups.

  const cancel = () => {
    setLocations(props.locations);
    setLocationsShowing({ ...props.locations });
    setDistrictVacancies(props.districtVacancies);
    setVacanciesMode(hasSchoolrolesChecked() ? 'school' : 'district');
    setListMode('school');

    props.onHide();
  };

  const schoolSearchChange = (value) => {
    if (listMode === 'schools') {
      setLocationsShowing(searchStringInObjectKeys(value, locations));
    } else if (listMode === 'groups') {
      const groups = {};
      props.schoolOptions
        .filter((so) => so.isGroupHeader)
        .forEach((g) => {
          groups[g.name] = { ...g };
        });
      setGroupsShowing(searchStringInObjectKeys(value, groups));
    }
  };

  const updateSchoolVacancies = (name, value) => {
    let newVacancies = value;
    const newLocations = { ...locations };
    newLocations[name].vacancies = newVacancies >= 0 ? newVacancies : 0;
    // When there are new vacacies, check the boxes.
    if (newVacancies > 0) {
      // Check the main box for routing:
      newLocations[name].checked = true;
      // Check visible to admin
      newLocations[name].visibleToAdmins = true;
    }
    // if we are removing vacacies we shouldn't remove the checks.
    setLocations(newLocations);

    // Update district vacancies if needed:
    setDistrictVacancies(hasSchoolrolesChecked() ? 0 : props.districtVacancies);
  };

  const updateLocations = (name, checked) => {
    const newLocations = { ...locations };
    if (!checked) {
      // We need to set this schools vacancies to 0 before deselecting
      newLocations[name].vacancies = 0;
    }
    newLocations[name].checked = checked;
    // Admins visibility works just like the checked. Until the user changes it.
    newLocations[name].visibleToAdmins = checked;
    setLocations(newLocations);

    // Update district vacancies if needed:
    setDistrictVacancies(hasSchoolrolesChecked() ? 0 : props.districtVacancies);
  };

  const updateVisibleToAdmin = (name, checked) => {
    const newLocations = { ...locations };
    newLocations[name].visibleToAdmins = checked;
    if (checked) {
      // if we are checked we need to make sure the location is also checked:
      newLocations[name].checked = true;
    }
    setLocations(newLocations);

    // Update district vacancies if needed:
    setDistrictVacancies(hasSchoolrolesChecked() ? 0 : props.districtVacancies);
  };

  const toggleAllFromGroup = (group, checked) => {
    const newLocations = {};
    Object.entries(locations).forEach((entry) => {
      const [name, location] = entry;
      if (location.groups?.includes(group)) {
        location.checked = checked;
        // check visible to admins:
        location.visibleToAdmins = checked;
        if (!checked) {
          // reset vacancies:
          location.vacancies = 0;
        }
      }
      newLocations[name] = location;
    });
    setLocations(newLocations);
  };

  // The list is already ready for groups, but when we want a flat list of locations
  // the list was not sorted by default and contains dupes.
  const seenOptions = [];
  const optionsList =
    listMode === 'groups'
      ? props.schoolOptions
      : props.schoolOptions
          .filter((o) => {
            if (seenOptions.includes(o.name)) return false;
            seenOptions.push(o.name);
            return !o.isGroupHeader;
          })
          .sort((a, b) => {
            // ignore string case:
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            // names must be equal
            return 0;
          });

  // Using Bootstrap's Modal because react-tooltip does not work with AltModal.
  return (
    <Modal
      show={props.show}
      bsSize="large"
      className="bulk-email-modal modal-darker-body"
      data-testid="locations-modal"
    >
      <AltModal.Title>Select Locations</AltModal.Title>
      <AltModal.Cancel onClick={props.onHide}>×</AltModal.Cancel>
      <AltModal.UpperRule />

      <AltModal.Body>
        <StyledSection>
          <RadioButton
            checked={vacanciesMode === 'school'}
            value="school"
            label="School-based vacancies"
            id="school-based-vacancies-radio"
            dataTestId={ATSJobEditTestIds.SCHOOL_VACANCIES_RADIO}
            name="vacancy-mode"
            onClick={() => setVacanciesMode('school')}
          />
          <RadioButton
            checked={vacanciesMode === 'district'}
            value="district"
            label="District vacancies"
            id="district-vacancies-radio"
            dataTestId={ATSJobEditTestIds.DISTRICT_VACANCIES_RADIO}
            name="vacancy-mode"
            onClick={() => setVacanciesMode('district')}
          />
          {vacanciesMode === 'district' && (
            <VacancyInput
              name="district-vacancies"
              value={districtVacancies}
              onChange={setDistrictVacancies}
            />
          )}
        </StyledSection>

        {vacanciesMode === 'school' && (
          <>
            <StyledSection>
              <SchoolsTabHeader inPostingOnlyDistrict={auth.inPostingOnlyDistrict()}>
                <LocationsModalSearch
                  schoolSearchChange={schoolSearchChange}
                  placeholder={
                    listMode === 'schools'
                      ? 'Start typing a school name to narrow your list'
                      : 'Start typing a school group name to narrow your list'
                  }
                />
                {!auth.inPostingOnlyDistrict() && (
                  <SchoolGroupsToggle>
                    <span
                      className={listMode === 'schools' ? 'selected' : ''}
                      onClick={() => setListMode('schools')}
                    >
                      Schools
                    </span>
                    <span
                      className={listMode === 'groups' ? 'selected' : ''}
                      onClick={() => setListMode('groups')}
                    >
                      Groups
                    </span>
                  </SchoolGroupsToggle>
                )}
              </SchoolsTabHeader>
            </StyledSection>

            <StyledSection>
              <GridContainer inPostingOnlyDistrict={auth.inPostingOnlyDistrict()}>
                <GridTitle>Vacancies</GridTitle>
                <GridTitle>Locations</GridTitle>
                {!auth.inPostingOnlyDistrict() && (
                  <GridTitle>
                    School Admin Access{' '}
                    <img
                      className="mbhalf"
                      src={Tooltip}
                      alt="tooltip"
                      data-tip
                      data-for="category"
                      data-multiline
                    />
                    <ReactTooltip id="category" effect="solid">
                      <div style={{ maxWidth: '200px' }}>
                        Deselecting the visibility checkbox allows you to create a vacancy at the
                        school site, while hiding candidates for this role from the principal. This
                        can be used in the case that there is a school vacancy but the principal is
                        not the hiring manager.
                      </div>
                    </ReactTooltip>
                  </GridTitle>
                )}

                {optionsList.map((so, idx) => {
                  // If the mode allows, render the header:
                  if (listMode === 'groups' && so.isGroupHeader) {
                    // But don't render if not on the visible list
                    if (!groupsShowing[so.name]) return null;

                    // Get all the group locations excluding headers
                    const group = props.schoolOptions.filter(
                      (ss) => ss.group === so.group && !ss.isGroupHeader
                    );
                    const isGroupHeaderChecked =
                      group.every((ss) => locations[ss.name].checked) && group.length > 1;
                    const isGroupHeaderConfused =
                      group.some((ss) => locations[ss.name].checked) &&
                      group.length > 1 &&
                      !isGroupHeaderChecked;

                    return (
                      <Fragment key={so.name + so.id + so.groups + idx}>
                        <span></span>
                        <span>
                          <CheckButton
                            data-testid="school-based-location-name"
                            label={so.name}
                            id={so.id + so.group}
                            name={so.name}
                            initialChecked={isGroupHeaderChecked}
                            isConfused={isGroupHeaderConfused}
                            onClick={() => toggleAllFromGroup(so.group, !isGroupHeaderChecked)}
                          />
                        </span>
                        <span></span>
                      </Fragment>
                    );
                  }

                  if (listMode === 'schools') {
                    // Don't render item if it's not in showing.
                    if (!locationsShowing.hasOwnProperty(so.name)) return null;
                  } else if (listMode === 'groups') {
                    // If group is hidden, so are all the child locations
                    const group = props.schoolOptions.filter(
                      (ss) => ss.group === so.group && ss.isGroupHeader
                    )[0];
                    if (!groupsShowing[group?.name]) return null;
                  }

                  return (
                    <Fragment key={so.name + so.group + idx}>
                      <GridCell p="4px 20px 4px 0" mb="15px" height="20px">
                        <VacancyInput
                          name={'input' + so.name}
                          value={locations[so.name]?.vacancies}
                          onChange={(v) => updateSchoolVacancies(so.name, v)}
                        />
                      </GridCell>
                      <GridCell
                        p={listMode === 'schools' ? '0 20px 0 0' : '0 20px 0 20px'}
                        mb="15px"
                        height="20px"
                        data-testid={`label-for-${so.name}`}
                      >
                        <CheckButton
                          id={idx + so.name}
                          name={so.name}
                          label={so.name}
                          initialChecked={locations[so.name].checked}
                          onClick={updateLocations}
                          data-testid={`check-button-for-${so.name}`}
                        />
                      </GridCell>
                      {!auth.inPostingOnlyDistrict() && (
                        <GridCell mb="30px" height="20px">
                          <CheckButton
                            id={so.name + idx}
                            name={so.name}
                            initialChecked={locations[so.name].visibleToAdmins}
                            onClick={updateVisibleToAdmin}
                          />
                        </GridCell>
                      )}
                    </Fragment>
                  );
                })}
              </GridContainer>
            </StyledSection>
          </>
        )}
      </AltModal.Body>
      <AltModal.Actions margin="0">
        <ModalFooterNew
          cancel={cancel}
          save={() => {
            /*
             * Spec (04/07/22):
             * If a district vacancy is added when there are school vacancies present,
             * the school vacancies should be deleted and the district vacancy saved.
             * The opposite of this should also be true.
             */
            if (districtVacancies) {
              // clear school locations
              props.onSave([], districtVacancies);
            } else {
              props.onSave(
                locations,
                hasSchoolrolesChecked() ? 0 : districtVacancies === 0 ? 1 : districtVacancies
              );
            }
          }}
          saveButtonLabel="Done"
          saveButtonTestId={ATSJobEditTestIds.LOCATIONS_MODAL_SAVE}
        />
      </AltModal.Actions>
    </Modal>
  );
}

LocationsModal.propTypes = {
  schoolOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.object.isRequired,
  districtVacancies: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const SchoolsTabHeader = styled.div`
  display: grid;
  grid-template-columns: ${({ inPostingOnlyDistrict }) =>
    inPostingOnlyDistrict
      ? // Posting-only districts don't have a school-group toggle
        // so the header only needs one column
        '1fr'
      : '80% 20%'};
`;

const SchoolGroupsToggle = styled.div`
  display: inline-grid;
  grid-template-columns: 50% 50%;
  margin-left: 20px;
  overflow: hidden;

  > span {
    color: #a6a7ad;
    display: block;
    background: #fff;
    border: 1px solid #d7d7d7;
    text-align: center;
    padding-top: 14px;
    cursor: pointer;
    user-select: none;
  }

  > span:first-child {
    border-radius: 2px 0 0 2px;
    border-right-width: 0;
  }

  > span:last-child {
    border-radius: 0 2px 2px 0;
    border-left-width: 0;
  }

  > .selected {
    color: #fff;
    background: #00b88d;
    border-color: #00b88d;
  }
`;

const StyledSection = styled.section`
  margin-bottom: 40px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ inPostingOnlyDistrict }) =>
    inPostingOnlyDistrict ? '1fr 2fr' : '23% 43% 33%'};
`;

const GridTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 19px;
`;

const GridCell = styled.span`
  ${space}
  ${layout}
`;
