export const activeValueByActiveStatus = {
  0: 'Active, closed',
  1: 'Active, open',
} as const;

export const roleStatusByValue = {
  draft: 0,
  submitted: 10,
  active: 20,
  archived: 30,
} as const;

export const roleStatusesForJobViewV2 = {
  0: 'Draft',
  10: 'Submitted',
  20: 'Active',
  30: 'Archived',
} as const;

export const userTypeToId = {
  candidate: 0,
  schoolUser: 10,
  schoolAdmin: 20,
  districtUser: 30,
  districtAdmin: 40,
  superAdmin: 50,
} as const;

export type UserType = keyof typeof userTypeToId;

export type JobStatus = keyof typeof roleStatusByValue;
