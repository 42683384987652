import useSWR from 'swr';
import axios from 'axios';

export default function useHelloSignSignatureRequests() {
  const { data } = useSWR('/api/hellosign/all_signatures_assigned_to_me/', (url) =>
    axios.get(url).then((response) => response.data)
  );

  return data;
}
