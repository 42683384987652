import { BaseNimbleAPI } from './baseAPI';

class StateAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/state' };
  }

  /**
   * Returns state information, including credentials, for a given state.
   */
  async fetchState(stateId) {
    const url = `/${stateId}/`;
    const response = await this.get(url);
    return response.data;
  }
}

export default new StateAPI();
