import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function CandidateFormModalLineItem({ label, removeLineItem }) {
  return (
    <Container>
      {label}
      <RemoveLineItemButton onClick={removeLineItem}>×</RemoveLineItemButton>
    </Container>
  );
}

CandidateFormModalLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  removeLineItem: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  position: relative;
  font-size: 14px;
  padding-left: 1.5em;
  height: 50px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
`;

const RemoveLineItemButton = styled.button`
  position: absolute;
  right: 16px;
  top: 8px;

  border: none;
  background-color: transparent;
  font-size: 22px;
  color: #a6a7ad;
`;
