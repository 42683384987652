import FilterGroup from './FilterGroup';
import GradesFilter from './GradesFilter';
import SchoolTypeFilter from './SchoolTypeFilter';
import CandidatesListFilterDrop from 'ui-kit/ListFilterDropdown';
import { grades as utilsGrades, preferenceCategories, preferenceOptions } from 'utils/enums';
import { CandidatePreferencesFilterProps } from './CandidatePreferencesFilters.types';

const CandidatePreferencesFilter: React.FC<CandidatePreferencesFilterProps> = ({
  clearAll,
  filterOptions,
  filterValues,
  grades,
  multilingualSchool,
  selectAll,
  title1School,
  turnaroundSchool,
  updateMultiSelectFilter,
  updateMultiSelectFilterWithMultipleValues,
}) => {
  const isGradesFilterActive = () => {
    const selectedGrades = grades;
    const allSelected = selectedGrades.length === utilsGrades().length;
    const noneSelected = selectedGrades.length === 0;
    const isActive = !(allSelected || noneSelected);
    return isActive;
  };

  const isPreferenceCategoriesActive = () => {
    return ![0, preferenceCategories().length].includes(filterValues.length);
  };

  const isSchoolTypeFilterActive = () => {
    const schoolTypeAllSelected =
      multilingualSchool.length + title1School.length + turnaroundSchool.length ===
      preferenceOptions().length * 3;
    const schoolTypeNoneSelected =
      multilingualSchool.length === 0 && title1School.length === 0 && turnaroundSchool.length === 0;
    const schoolTypeActive = !(schoolTypeAllSelected || schoolTypeNoneSelected);

    return schoolTypeActive;
  };

  const filterGroupIsActive =
    isSchoolTypeFilterActive() || isGradesFilterActive() || isPreferenceCategoriesActive();

  return (
    <FilterGroup title={'Candidate Preferences'} isActive={filterGroupIsActive}>
      <SchoolTypeFilter
        clearAll={clearAll}
        multilingual_school={multilingualSchool}
        selectAll={selectAll}
        title_1_school={title1School}
        turnaround_school={turnaroundSchool}
        updateMultiSelectFilter={updateMultiSelectFilter}
      />
      <GradesFilter
        clearAll={clearAll}
        grades={grades}
        selectAll={selectAll}
        updateMultiSelectFilter={updateMultiSelectFilter}
        updateMultiSelectFilterWithMultipleValues={updateMultiSelectFilterWithMultipleValues}
      />
      <CandidatesListFilterDrop
        clearAll={clearAll}
        displayName={'Job Category'}
        isSubFilter={true}
        fieldName={'preference_categories'}
        options={filterOptions}
        selectAll={selectAll}
        updateMultiSelectFilter={updateMultiSelectFilter}
        values={filterValues}
      />
    </FilterGroup>
  );
};

export default CandidatePreferencesFilter;
