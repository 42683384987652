import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ATSJobEditTestIds } from '../data-testids/ATS';

export default function VacancyInput({ name, value, onChange }) {
  const inputEl = useRef(null);

  const onDecrement = () => {
    let value = parseFloat(inputEl.current.value) || 0;
    value = (value - 1 >= 0 ? value - 1 : 0).toFixed(2);
    inputEl.current.value = value;
    onChange(value);
  };

  const onInput = () => {
    let value = (parseFloat(inputEl.current.value) || 0).toFixed(2);
    inputEl.current.value = value;
    onChange(value);
  };

  const onIncrement = () => {
    let value = parseFloat(inputEl.current.value) || 0;
    value = (value + 1 >= 0 ? value + 1 : 0).toFixed(2);
    inputEl.current.value = value;
    onChange(value);
  };

  value = parseFloat(value).toFixed(2);

  // Since this is an uncontrolled component, this useEffect mimics "componentDidMount()".
  // This is used to sync the value between vacancy inputs if there are more than one.
  useEffect(() => {
    inputEl.current.value = value;
  });

  return (
    <VacancyInputContainer>
      <span
        onClick={onDecrement}
        data-testid={`${ATSJobEditTestIds.DECREMENT_VACANCY_BUTTON}-${name}`}
      >
        -
      </span>
      <input
        data-testid={name}
        type="text"
        name={name}
        defaultValue={value}
        onBlur={onInput}
        ref={inputEl}
      />
      <span
        onClick={onIncrement}
        data-testid={`${ATSJobEditTestIds.INCREMENT_VACANCY_BUTTON}-${name}`}
      >
        +
      </span>
    </VacancyInputContainer>
  );
}

const VacancyInputContainer = styled.div`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #ddd;
  cursor: pointer;
  display: inline;
  margin: 0;
  height: 20px;
  user-select: none;

  > span {
    display: inline-block;
    padding: 0 5px;
    color: #ddd;
  }

  input {
    appearance: none;
    border: none;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    width: 5.2em;
    text-align: center;
    background-color: transparent;
    padding: 0;
    padding-left: 0.7em;
    margin: 0;
  }
`;
