import { ActivityFeedRowProps } from './types';
import { Date, Change, Author, Left, Right, AuthorDate, Container } from './styles';
import { getIconColor } from './utils';
import { ReactComponent as ActivityIcon } from './assets/activity_icon.svg';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';

export const ActivityFeedRow: React.FC<ActivityFeedRowProps> = ({ activity }) => {
  const wasCreated = activity.verb === 'created';
  const wasSaved = activity.verb === 'saved';
  const date = moment(activity.published).format('MMM DD, h:mm a');
  const statusUpdated = !wasCreated && !wasSaved;

  const getActivityText = () => {
    if (wasCreated) {
      return 'Job created';
    } else if (wasSaved) {
      return 'Job saved';
    } else {
      return `Job status ${activity.verb} from ${activity.actionObject.oldValue} to ${activity.actionObject.newValue}`;
    }
  };

  return (
    <Container key={activity.id} data-testid="activity-feed-row">
      <Left>
        {statusUpdated && (
          <ActivityIcon
            data-testid="activity-icon"
            color={getIconColor(activity?.actionObject?.newValue)}
          />
        )}
        {wasSaved && (
          <SaveIcon
            sx={{
              color: getIconColor(activity?.actionObject?.newValue),
            }}
          />
        )}
        {wasCreated && (
          <AddBoxOutlinedIcon
            data-testid="create-icon"
            sx={{
              color: getIconColor(activity?.actionObject?.newValue),
            }}
          />
        )}
      </Left>
      <Right>
        <AuthorDate>
          <Author data-testid="author-text">
            {`${activity.actor.firstName} ${activity.actor.lastName} (${activity.actor.userType})`}
          </Author>
          <Date data-testid="activity-item-date">{date}</Date>
        </AuthorDate>
        <Change data-testid="activity-text">{getActivityText()}</Change>
      </Right>
    </Container>
  );
};
