import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../../../assets/icon-grey-info.svg';

export interface AutoTagsModalOpenButtonProps {
  hasTagsAvailable: boolean;
  handleTagModalOpen: () => void;
  hasSetTags: boolean;
  questionHasHiddenTags: boolean;
}

const AutoTagsModalOpenButton: React.FC<AutoTagsModalOpenButtonProps> = ({
  handleTagModalOpen,
  hasSetTags,
  hasTagsAvailable,
  questionHasHiddenTags,
}) => {
  const buttonTextToRender = hasSetTags ? 'Automatic Tags Enabled' : 'Enable Automatic Tags';
  if (questionHasHiddenTags) {
    return (
      <>
        <TagsLinkContainer>
          <AutomaticTagsButton disabled={true} type="button" style={{ color: '#c2c2c2' }}>
            {buttonTextToRender}
            <InfoIcon data-tip data-for={'no-tags-because-of-permissions'}>
              <img src={infoIcon} />
            </InfoIcon>
          </AutomaticTagsButton>
        </TagsLinkContainer>
        <ReactTooltip id="no-tags-because-of-permissions" effect="solid" multiline={true}>
          <span>
            Auto-tags were previously configured by your administrator. Please contact them to make
            changes.
          </span>
        </ReactTooltip>
      </>
    );
  } else if (!hasTagsAvailable) {
    return (
      <>
        <TagsLinkContainer>
          <AutomaticTagsButton disabled={true} type="button" style={{ color: '#c2c2c2' }}>
            {buttonTextToRender}
            <InfoIcon data-tip data-for={'no-tags-because-of-permissions'}>
              <img src={infoIcon} />
            </InfoIcon>
          </AutomaticTagsButton>
        </TagsLinkContainer>
        <ReactTooltip id="no-tags-because-of-permissions" effect="solid" multiline={true}>
          <span>
            Create tags in the settings page (or contact your administrator) to enable automatic
            tagging for this question.
          </span>
        </ReactTooltip>
      </>
    );
  } else {
    return (
      <>
        <TagsLinkContainer>
          <AutomaticTagsButton type="button" onClick={handleTagModalOpen}>
            {buttonTextToRender}
            <InfoIcon data-tip data-for={'no-tags'}>
              <img src={infoIcon} />
            </InfoIcon>
          </AutomaticTagsButton>
        </TagsLinkContainer>
        <ReactTooltip id="no-tags" effect="solid" multiline={true}>
          <span>Automatically tag candidates based on their response to this question</span>
        </ReactTooltip>
      </>
    );
  }
};

export default AutoTagsModalOpenButton;

const AutomaticTagsButton = styled.button`
  display: flex;
  font-size: 14px;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;

  &:hover {
    color: #008060;
    text-decoration: underline;
  }
`;

const InfoIcon = styled.div`
  height: 16px;
  margin-left: 4px;
  width: 16px;
`;

const TagsLinkContainer = styled.div`
  display: inline-block;
`;
