import SourceDropdown, { getSourceLabel } from './SourceDropdown';

import UsersAPI from 'api/usersAPI';

export default function SourceSection({ fullUser, onProspectList, updateFullUser, sources }) {
  function handleSourceChange(value) {
    UsersAPI.updateUserDistrictSource(fullUser.id, value).then(() => {
      updateFullUser('district_source', value);
    });
  }

  return (
    <div className="QuickProfile__applicant-details-candidate-source">
      {fullUser.district_source?.label === 'Talent Marketplace' ? (
        <div id="source-label">{getSourceLabel(fullUser.district_source, onProspectList)}</div>
      ) : (
        <SourceDropdown
          district_source={fullUser.district_source}
          handleSourceChange={handleSourceChange}
          onProspectList={onProspectList}
          sources={sources}
        />
      )}
    </div>
  );
}
