import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import auth from '../../utils/auth';

const AuthenticatedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() => {
        if (!auth.loggedIn()) {
          const returnTo = location.pathname;
          const searchParams = new URLSearchParams(location.search);
          searchParams.set('return_to', returnTo);

          const search = searchParams.toString();

          return (
            <Redirect
              to={{
                pathname: '/login',
                search,
              }}
            />
          );
        }

        return <>{children}</>;
      }}
    />
  );
};

export default AuthenticatedRoute;
