import { Component } from 'react';

import { Link } from 'react-router-dom';
import _ from 'lodash';
import auth from '../utils/auth';
import axios from 'axios';
import styled from 'styled-components';

import AdminTasks from 'components/Home/adminTasks';
import BottleneckNotifications from 'components/Home/bottleneckNotifications';
import HelloSignSignatureRequests from 'components/Home/helloSignSignatureRequests';
import HiringPipeline from 'features/HiringPipeline';
import TopCandidates from 'features/TopCandidates';
import UpcomingInterviews from 'components/Home/upcominginterviews';

import { updateCandidateTask } from '../utils/statusviewutils.js';
import LoadingSpinner from '../components/loadingSpinner';
import { ThreeColumnGrid } from 'components/Home/common';
import featureContext from '../context/featureContext';
import { ATSDistrictHomeDataTestIds } from '../data-testids/ATS';

export default class BaseHome extends Component {
  static contextType = featureContext;

  constructor(props) {
    super(props);
    this.hiring_pipeline_show = false;
    this.state = {
      vacancies: 0,
      filled_vacancies: 0,
      showMore: false,
      notifications: [],
      ordered_status_list: [],
      tasks: [],
    };
    this.user = auth.getUser();
    this.isDistrictUser = auth.isDistrictUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.urlPrefix = this.isDistrictUser ? '/district/' : '/school/';
    this.updateCandidateTask = updateCandidateTask.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('district-home');
    this.fetchHome();
    this.fetchAdminTasks();
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('district-home');
  }

  fetchHome = () => {
    axios.get('/api/home/').then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({
          vacancies: r.data.vacancies.denominator,
          filled_vacancies: r.data.vacancies.numerator,
          notifications: r.data.notifications,
          ordered_status_list: r.data.ordered_status_list,
        });
      }
    });
  };

  fetchAdminTasks = () => {
    axios.get('/api/admin_tasks/').then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ tasks: r.data });
      }
    });
  };

  render() {
    let { ordered_status_list, vacancies, filled_vacancies } = this.state;

    const statusBarPercentage =
      vacancies > 0 ? Math.min((filled_vacancies / vacancies) * 100, 100) : 0;

    return (
      <div>
        <ContainerPaddedSection style={{ backgroundColor: 'white' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '150px',
              width: '1120px',
              margin: '0 auto 3rem',
              backgroundColor: 'white',
            }}
          >
            <div style={{ display: 'flex', width: 'auto', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: '30px',
                  alignItems: 'center',
                }}
              >
                <h3
                  className="greeting"
                  data-testid={ATSDistrictHomeDataTestIds.GREETING_TEXT}
                >{`Welcome, ${_.capitalize(this.user.first_name)}!`}</h3>
                {this.isDistrictAdmin && (
                  <Link
                    to="/dashboards/vacancy"
                    style={{ cursor: 'pointer' }}
                    className="status-bar"
                  >
                    {filled_vacancies} positions filled, {vacancies} open positions
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                      <div className="status-bar-section" style={{ width: '100%' }}>
                        <div
                          className="status-bar-section completed"
                          style={{ width: `${statusBarPercentage}%` }}
                        />
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
            <div style={{ paddingBottom: '25px', marginTop: '35px' }}>
              <span
                style={{
                  marginBottom: '30px',
                  borderBottom: '5px solid #00B88D',
                  paddingBottom: '20px',
                  width: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                {this.urlPrefix === '/school/'
                  ? _.startCase(this.user.profile.school?.name)
                  : _.startCase(this.user.profile.district?.name)}
              </span>
            </div>
          </div>
        </ContainerPaddedSection>
        <ContainerPaddedSection>
          <div className="district-home-content-container">
            {ordered_status_list.length > 0 ? (
              <Section>
                {
                  <HiringPipeline
                    ordered_status_list={ordered_status_list}
                    urlPrefix={this.urlPrefix}
                  />
                }
              </Section>
            ) : (
              <LoadingSpinner margin={2} fontSize={2} additionalClassname="bottom" />
            )}

            <Section>
              <TopCandidates />
            </Section>

            {ordered_status_list.length > 0 && (
              <Section>
                <ThreeColumnGrid>
                  <BottleneckSection>
                    <BottleneckNotifications
                      notifications={this.state.notifications}
                      updateCandidateTask={this.updateCandidateTask}
                    />
                  </BottleneckSection>
                  <InterviewSection>
                    <UpcomingInterviews />
                  </InterviewSection>
                </ThreeColumnGrid>
              </Section>
            )}
            <HelloSignSignatureRequests Wrapper={Section} />
            {this.state.tasks.length > 0 && (
              <Section>
                <AdminTasks
                  tasks={this.state.tasks}
                  updateCandidateTask={this.updateCandidateTask}
                />
              </Section>
            )}
          </div>
        </ContainerPaddedSection>
      </div>
    );
  }
}

const ContainerPaddedSection = styled.div`
  padding: 0 49px;
`;

const Section = styled.div`
  margin-top: 35px;
`;

const BottleneckSection = styled.div`
  grid-column: 1 / span 2;
`;

const InterviewSection = styled(UpcomingInterviews)`
  grid-column: 3 / span 1;
`;
