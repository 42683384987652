import { BaseNimbleAPI } from '../../../api/baseAPI';

class GeolocationAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/' };
  }

  async getMapUrl(data) {
    try {
      const response = await this.post('geocode-location-and-get-map/', data);
      return [response.status, response.data];
    } catch (error) {
      return [error.response.status, error.response.data];
    }
  }
}

export default new GeolocationAPI();
