import { Component } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

export default class Credentials extends Component {
  static propTypes = {
    credentials: PropTypes.arrayOf(Object).isRequired,
    hasNoCredentials: PropTypes.bool.isRequired,
  };

  getItem(list, value) {
    value = parseInt(value, 10);
    const option = list.find((o) => o.value === value);
    return option && option.label;
  }

  render() {
    return (
      <div>
        {this.props.hasNoCredentials && (
          <div className="added-row">
            <span className="block flex-1 bold">
              {/* No Credential Format: Header - "None" */}
              <h5>None</h5>
            </span>
          </div>
        )}
        {this.props.credentials.map((row, i) => (
          <div className="added-row" key={i}>
            {row?.state ? (
              <span className="block flex-1 bold">
                {/* State Credential Format: Header - Grade, Subject; Sub-Header - Status  */}
                <h5>
                  {`
                ${row?.grade?.value ? row.grade.value : ''}
                ${row?.grade?.value && row?.subject?.value ? `,` : ''}
                  ${row?.subject?.value ? row.subject.value : ''}
                  `}
                </h5>
                <p>{row?.status?.value && row.status.value}</p>
              </span>
            ) : (
              <span className="block flex-1 bold">
                {/* Other Credential Format: Header - Other Credential; Sub-Header - Explanation  */}
                <h5>Other Credential</h5>
                <p>{row?.credential_explanation}</p>
              </span>
            )}
            <span className="flex-1">
              {`${
                moment(row['issue_date']).isValid()
                  ? moment(row['issue_date']).format('MM/D/YYYY')
                  : ''
              } ${
                moment(row['expiration_date']).isValid()
                  ? `- ${moment(row['expiration_date']).format('MM/D/YYYY')}`
                  : ''
              }`}
            </span>
          </div>
        ))}
      </div>
    );
  }
}
