import { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from 'hooks';
import { ReactComponent as DownCaret } from 'assets/icon-down-caret.svg';
import { ComponentsTextInputWithDropdownTestIds } from 'data-testids/Components';

export const TextInputWithDropdown = ({
  placeholder = 'Start typing...',
  options = [],
  inputValue,
  handleInputChange,
  handleSelection,
  onClose,
  onClear,
  boxShadow = false,
  editable,
  dataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdownAndRunCallback = useCallback(() => {
    setIsOpen(false);
    if (editable) {
      onClose();
    }
  }, [onClose, editable]);
  const openDropdown = useCallback(() => setIsOpen(true), []);
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdownAndRunCallback, isOpen);
  const statusRef = useRef(null);

  function getFilteredOptions() {
    if (options === undefined) {
      return [];
    } else if (!inputValue) {
      return options;
    } else {
      return options.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      );
    }
  }

  return (
    <div data-testid={dataTestId} ref={closeNodeOnClickOutside}>
      <DropdownWrapper>
        <DropdownInput
          type="text"
          onChange={handleInputChange}
          onFocus={openDropdown}
          placeholder={placeholder}
          value={inputValue}
          boxShadow={boxShadow}
          readOnly={!editable}
          data-testid={ComponentsTextInputWithDropdownTestIds.INPUT}
        />
        {inputValue ? (
          <PositionedClearIcon onClick={onClear}>×</PositionedClearIcon>
        ) : (
          <PositionedCaret height="0.45em" />
        )}
        {isOpen && editable && (
          <DropdownList ref={statusRef}>
            {getFilteredOptions().map((option) => (
              <StyledListItem
                key={option.value}
                onClick={() => {
                  handleSelection(option.value);
                  setIsOpen(false);
                }}
                data-testid={ComponentsTextInputWithDropdownTestIds.LIST_ITEM}
              >
                {option.label}
              </StyledListItem>
            ))}
          </DropdownList>
        )}
      </DropdownWrapper>
    </div>
  );
};

const DropdownWrapper = styled.div`
  position: relative;
  user-select: none;
`;

const DropdownInput = styled.input`
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
  font-size: 14px;

  border: 0.5px solid #d7d7d7;
  box-shadow: ${(props) => (props.boxShadow ? '0px 2px 2px rgba(0, 0, 0, 0.0954484)' : 'none')};
  border-radius: 3px;

  padding-left: 1.5em;
  padding-right: 2.5em;

  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  width: 100%;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }
`;

const PositionedCaret = styled(DownCaret)`
  position: absolute;
  right: 24px;
  top: 22px;
`;

const PositionedClearIcon = styled.span`
  position: absolute;
  right: 24px;
  top: 12px;
  font-size: 20px;
  cursor: pointer;
  color: var(--lightgray);
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;

  max-height: 315px;
  @media screen and (max-width: 768px) {
    max-height: 222px;
  }

  font-size: 14px;

  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 1px 2px rgba(204, 204, 204, 0.5);

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  list-style-type: none;
  z-index: 10;
`;

const StyledListItem = styled.li`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;
