import './interviews.css';

import { Component } from 'react';

import InterviewCard from '../components/interviewcard';
import auth from '../utils/auth';
import axios from 'axios';

export default class InterviewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyMine: true,
      interviews: [],
    };
    this.getUser = auth.getUser();
    this.isSchoolUser = auth.isSchoolUser();
  }

  componentDidMount() {
    this.fetchInterviews();
    document.body.classList.add('interviews-body-container');
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('interviews-body-container');
  }

  fetchInterviews = () => {
    axios.get('/api/interviews/').then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ interviews: r.data });
      }
    });
  };

  render() {
    const currentUser = this.getUser;
    const upcoming = [];
    const completed = [];
    const now = new Date();
    for (let interview of this.state.interviews) {
      if (this.state.showOnlyMine && interview.created_by.id !== currentUser.id) {
        continue;
      }

      if (new Date(interview.when) > now) {
        upcoming.push(interview);
      } else {
        completed.push(interview);
      }
    }

    return (
      <div className="interviews-container">
        <div className="interviews-container-half">
          <h3 className="interviews-container-title">
            Upcoming{' '}
            <small
              className="interviews-container-toggle"
              onClick={() => this.setState({ showOnlyMine: !this.state.showOnlyMine })}
            >
              ({this.state.showOnlyMine ? 'all' : 'mine'})
            </small>
          </h3>
          {upcoming.map((interview, index) => {
            return (
              <InterviewCard interview={interview} key={index} onChange={this.fetchInterviews} />
            );
          })}
        </div>
        <div className="interviews-container-half">
          <h3 className="interviews-container-title">Completed</h3>
          {/* adding .reverse() to show most recent first */}
          {completed.reverse().map((interview, index) => {
            return (
              <InterviewCard interview={interview} key={index} onChange={this.fetchInterviews} />
            );
          })}
        </div>
      </div>
    );
  }
}
