import { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ScorecardMultipleChoiceSection from './ScorecardMultipleChoiceSection';
import DirectionText from './DirectionText';
import NimbleQuestion from './NimbleQuestion';
import RatingQuestion from './RatingQuestion';
import CumulativeScore from './CumulativeScore';
import Attachment from './Attachment';
import FinalRecommendation from './FinalRecommendation';
import TextQuestion from './TextQuestion';
import RubricQuestion from './RubricQuestion';
import ModalFooter from 'components/common/Modal/ModalFooter';

import { scorecardQType, scorecardQuestionOptions } from '../../utils/enums';

export default function EditScorecardQuestionModal({
  show,
  onHide,
  saveQuestion,
  question,
  questionBank,
}) {
  const [questionText, setQuestionText] = useState(question.question_text);
  const [isRequired, setIsRequired] = useState(question.is_required);
  const [errorMessage, setErrorMessage] = useState('');

  const [scale, setScale] = useState(question.scale);
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(question.mc_options);
  const [multiSelect, setMultiSelect] = useState(question.multi_select);
  const [includeRatingQuestions, setIncludeRatingQuestions] = useState(
    question.include_rating_questions
  );
  const [includeRubricQuestions, setIncludeRubricQuestions] = useState(
    question.include_rubric_questions
  );
  const [showTotalOnPreview, setShowTotalOnPreview] = useState(question.show_total_on_preview);
  const [attachmentDirections, setAttachmentDirections] = useState(question.attachment_directions);
  const [includeMaybeOption, setIncludeMaybeOption] = useState(question.include_maybe_option);
  const [showAnswerOnPreview, setShowAnswerOnPreview] = useState(question.show_answer_on_preview);
  const [directions, setDirections] = useState(question.directions);
  const [rubricColumns, setRubricColumns] = useState(question.rubric_columns);

  const onSave = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    saveQuestion({
      ...question,
      question_text: questionText,
      is_required: isRequired,
      scale: scale,
      mc_options: multipleChoiceOptions,
      multi_select: multiSelect,
      include_rating_questions: includeRatingQuestions,
      include_rubric_questions: includeRubricQuestions,
      show_total_on_preview: showTotalOnPreview,
      attachment_directions: attachmentDirections,
      include_maybe_option: includeMaybeOption,
      show_answer_on_preview: showAnswerOnPreview,
      directions: directions,
      rubric_columns: rubricColumns,
    });
  };

  const checkForErrors = () => {
    if (question.question_type === scorecardQType.multiple_choice) {
      // iterate over multiple choice options and make sure none are blank.
      for (let i = 0; i < multipleChoiceOptions.length; i++) {
        if (multipleChoiceOptions[i] === '') {
          throw new Error('Each answer must have a value');
        }
      }
    }
    if (questionText.trim() === '') {
      if (question.question_type === scorecardQType.nimble) {
        throw new Error('Please select an option from the dropdown');
      } else if (
        // direction_text and cumulative_score question types don't have question text
        question.question_type !== scorecardQType.direction_text &&
        question.question_type !== scorecardQType.cumulative_score
      ) {
        throw new Error('Question prompt cannot be blank');
      }
    }
  };

  const handleMultipleChoiceTextChange = (e, index) => {
    const newOptions = [...multipleChoiceOptions];
    newOptions[index] = e.target.value;
    setMultipleChoiceOptions(newOptions);
  };

  const addAnswer = () => {
    // push in an empty answer
    const newOptions = [...multipleChoiceOptions];
    newOptions.push('');
    setMultipleChoiceOptions(newOptions);
  };

  const removeOption = (index) => {
    const newOptions = [...multipleChoiceOptions];
    newOptions.splice(index, 1);
    setMultipleChoiceOptions(newOptions);
  };

  const questionTypeMapping = scorecardQuestionOptions().find(
    (option) => option.value === question.question_type
  );
  const QuestionTypeIcon = questionTypeMapping.icon;

  const renderQuestionTypeSection = () => {
    switch (question.question_type) {
      case scorecardQType.direction_text:
        return <DirectionText directions={directions} setDirections={setDirections} />;
      case scorecardQType.text:
        return <TextQuestion questionText={questionText} setQuestionText={setQuestionText} />;
      case scorecardQType.multiple_choice:
        return (
          <ScorecardMultipleChoiceSection
            questionText={questionText}
            setQuestionText={setQuestionText}
            addAnswer={addAnswer}
            mc_options={multipleChoiceOptions}
            multi_select={multiSelect}
            setMultiSelect={setMultiSelect}
            handleMultipleChoiceTextChange={handleMultipleChoiceTextChange}
            removeOption={removeOption}
          />
        );
      case scorecardQType.nimble:
        return (
          <NimbleQuestion
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionBank={questionBank}
          />
        );
      case scorecardQType.rubric:
        return (
          <RubricQuestion
            questionText={questionText}
            setQuestionText={setQuestionText}
            rubricColumns={rubricColumns}
            setRubricColumns={setRubricColumns}
          />
        );
      case scorecardQType.rating:
        return (
          <RatingQuestion
            questionText={questionText}
            setQuestionText={setQuestionText}
            scale={scale}
            setScale={setScale}
          />
        );
      case scorecardQType.attachment:
        return (
          <Attachment
            questionText={questionText}
            setQuestionText={setQuestionText}
            attachmentDirections={attachmentDirections}
            setAttachmentDirections={setAttachmentDirections}
          />
        );
      case scorecardQType.final_recommendation:
        return (
          <FinalRecommendation
            questionText={questionText}
            setQuestionText={setQuestionText}
            includeMaybeOption={includeMaybeOption}
            setIncludeMaybeOption={setIncludeMaybeOption}
            showAnswerOnPreview={showAnswerOnPreview}
            setShowAnswerOnPreview={setShowAnswerOnPreview}
          />
        );
      case scorecardQType.cumulative_score:
        return (
          <CumulativeScore
            includeRatingQuestions={includeRatingQuestions}
            setIncludeRatingQuestions={setIncludeRatingQuestions}
            includeRubricQuestions={includeRubricQuestions}
            setIncludeRubricQuestions={setIncludeRubricQuestions}
            showTotalOnPreview={showTotalOnPreview}
            setShowTotalOnPreview={setShowTotalOnPreview}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={onHide} bsSize="large" dialogClassName="scorecard-q-modal">
      <Modal.Header />
      <Modal.Body>
        <div className="scorecard-q-modal-title">
          <h3>Edit Scorecard Item</h3>
        </div>
        <span className="scorecard-q-modal-close" onClick={onHide}>
          (X) CLOSE
        </span>
        <div className="scorecard-q-modal-section">
          <div className="flex mt1 mb3 space-between">
            <>
              <div className="flex">
                <QuestionTypeIcon className="mr2" />
                <div>
                  <Header>{questionTypeMapping.label}</Header>
                  <div className="grey">
                    {multiSelect ? questionTypeMapping.info2 : questionTypeMapping.info}
                  </div>
                </div>
              </div>
              {question.question_type !== scorecardQType.direction_text &&
                question.question_type !== scorecardQType.cumulative_score && (
                  <label className="grey">
                    <input
                      type="checkbox"
                      style={{ margin: '0 0.5em 0 0' }}
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                    />
                    Required
                  </label>
                )}
            </>
          </div>
          {renderQuestionTypeSection()}
        </div>
      </Modal.Body>
      <ModalFooter
        cancel={onHide}
        save={onSave}
        errorMessage={errorMessage}
        deleteButtonLabel="Delete item"
        cancelButtonLabel="Cancel"
        saveButtonLabel="Save item"
      />
    </Modal>
  );
}

EditScorecardQuestionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  questionBank: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 22px;
`;
