import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import auth from '../../utils/auth';
import axios from 'axios';
import { VariableSizeList as List } from 'react-window';
import { withRouter } from 'react-router-dom';
import InfiniteLoader from 'react-window-infinite-loader';

import LoadingSpinner from '../loadingSpinner';
import applicationsAPI from 'api/applicationsAPI';
import districtAPI from 'api/districtAPI';
import MarketplaceCandidateRow from './MarketplaceCandidateRow';
import { TalentMarketplaceHeader } from './Header';
import { fetchAdmins, fetchEmailTemplates } from '../../utils/statusviewutils';
import { handleOutsideClickOnValidForm } from '../../utils/util';
import { RowContainer } from './Marketplace.styles';
import TalentQuickView from 'components/CandidatesList/TalentQuickView';
import styled from 'styled-components';

class MarketplaceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUser: [],
      applicationsBulkUpdate: [],
      bulkEmailModalOpen: false,
      candidates: this.props.candidates,
      district: {},
      emailModalOpen: false,
      emailTemplateSelection: null,
      emailTemplatesList: [],
      exportModalOpen: false,
      loadingSpinnerFooter: false,
      oneApplicationQuickView: false,
      selectedApplications: new Set(),
      selections: new Set(),
      showQuickProfile: false,
      showSelectionOptions: false,
      sources: [],
      targetStatus: null,
    };

    this.fetchAdmins = fetchAdmins.bind(this);
    this.fetchEmailTemplates = fetchEmailTemplates.bind(this);
    this.getUser = auth.getUser();
    this.isSchoolUser = auth.isSchoolUser();
  }

  static propTypes = {
    application_type: PropTypes.oneOf(['application', 'schoolapplication']).isRequired,
    availableApplicationStatuses: PropTypes.array,
    candidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    districtRoleId: PropTypes.string,
    fetchTalentMarketplaceCandidates: PropTypes.func.isRequired,
    fetchTalentMarketplaceCandidatesDebounced: PropTypes.func.isRequired,
    fetchCandidatesRunning: PropTypes.bool.isRequired,
    fetchProspects: PropTypes.func,
    hasMore: PropTypes.bool.isRequired,
    incrementNoteCount: PropTypes.func,
    incrementScorecardCount: PropTypes.func,
    isRequisition: PropTypes.bool,
    isSchoolUser: PropTypes.bool,
    jobTitle: PropTypes.string.isRequired,
    job_status_list: PropTypes.array.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    onProspectList: PropTypes.bool.isRequired,
    onViewByPosting: PropTypes.bool.isRequired,
    partialApplication: PropTypes.object.isRequired,
    partialUser: PropTypes.object,
    refreshCandidateInList: PropTypes.func.isRequired,
    role: PropTypes.object,
    search: PropTypes.func.isRequired,
    setApplicationForQuickView: PropTypes.func.isRequired,
    setSortFieldAndDirection: PropTypes.func,
    showSpinner: PropTypes.bool.isRequired,
    totalCandidateCount: PropTypes.number.isRequired,
    updateCandidatesInContainerState: PropTypes.func,
    updateProspectStatus: PropTypes.func,
    viewByPostingRoleId: PropTypes.number,
  };

  componentDidMount() {
    if (this.isSchoolUser) {
      this.preparePrincipalApplications(this.props.candidates);
    }

    this.fetchAdmins();
    this.fetchEmailTemplates();

    districtAPI
      .getDistrict(this.getUser.profile.district.id)
      .then((district) => this.setState({ district }));
    axios.get('/api/candidate-source/').then((r) => this.setState({ sources: r.data }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.candidates) {
      if (this.isSchoolUser) {
        this.preparePrincipalApplications(nextProps.candidates);
      } else {
        this.setState({ candidates: nextProps.candidates });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.partialUser && prevProps.candidates !== this.props.candidates) {
      let candidateIndex = this.state.candidates.findIndex(
        (candidate) => candidate.id === this.props.partialUser.id
      );

      this.listref && this.listRef.resetAfterIndex(candidateIndex, true);
    }
  }

  preparePrincipalApplications = (propsCandidates) => {
    let revisedCandidates = [];
    const candidates = [...propsCandidates];

    candidates.forEach((candidate, index) => {
      const districtRoleIdsForSchoolApps = new Set(
        candidate.schoolapplications.map((s) => s.role.district_role)
      );

      const applications = candidate.applications.filter((a) => {
        return !districtRoleIdsForSchoolApps.has(a.role.id);
      });

      candidate['allRelevantApplicationsForSchoolAdmin'] =
        candidate.schoolapplications.concat(applications);
      revisedCandidates[index] = candidate;
    });

    this.setState({
      candidates: revisedCandidates,
    });
  };

  sortBackend = async (sortField) => {
    let orderDir = this.props.orderDir;
    let currentSortField = this.props.orderBy;

    // If the new sort field is different from the current one,
    // set the order direction to 'asc' for 'last_name' and 'desc' for 'years_experience'
    if (currentSortField !== sortField) {
      orderDir = sortField === 'last_name' ? 'asc' : 'desc';
    } else {
      orderDir = this.flipSortDirection();
    }

    this.props.setSortFieldAndDirection(sortField, orderDir, () => {
      this.props.search(true, true);
    });
  };

  flipSortDirection = () => (this.props.orderDir === 'desc' ? 'asc' : 'desc');

  toggleQuickProfile = (candidate) => {
    if (candidate) {
      this.props.setApplicationForQuickView(candidate);
    }
    this.setState({ showQuickProfile: !this.state.showQuickProfile });
  };

  outsideClickListener = (ev) =>
    handleOutsideClickOnValidForm(this.node, ev, this.toggleSelectionOptions);

  postUpdate = (statusObj, applicationsBulkUpdate) => {
    if (!statusObj) {
      statusObj = this.state.targetStatus;
    }

    if (!applicationsBulkUpdate) {
      applicationsBulkUpdate = this.state.applicationsBulkUpdate;
    }

    this.setState({ loadingSpinnerFooter: true });

    if (!this.props.role) {
      applicationsAPI
        .bulkUpdateApplicationStatuses({
          applications_to_update: this.state.selectedApplications,
          status_id: statusObj.id,
          job_status_list: this.props.job_status_list,
        })
        .then(this.props.updateCandidatesInContainerState)
        .then(() => this.setState({ loadingSpinnerFooter: false }));
      this.onHide();
      return;
    }

    const payload = {
      applications_to_update: applicationsBulkUpdate,
      target_status_id: statusObj.id,
      job_status_list: this.props.job_status_list,
      role_id: this.props.role.id,
    };

    applicationsAPI
      .bulkUpdateApplicationStatusesForRole(payload)
      .then(this.props.updateCandidatesInContainerState)
      .then(() => this.setState({ loadingSpinnerFooter: false }));
    this.onHide();
    return;
  };

  bulkEmail = () => {
    this.setState({ bulkEmailModalOpen: true });
  };

  onHide = () => {
    this.setState({
      emailModalOpen: false,
      bulkEmailModalOpen: false,
      emailTemplateSelection: null,
      targetStatus: null,
      applicationsBulkUpdate: [],
    });
  };

  bulkExport = () => {
    this.setState({ exportModalOpen: true });
  };

  onHideExport = () => {
    this.setState({ exportModalOpen: false });
  };

  continueStatusUpdate = () => {
    this.setState({ bulkEmailModalOpen: false });
    this.postUpdate(this.state.targetStatus);
  };

  bulkStatusUpdate = (statusObj, applicationsBulkUpdate) => {
    if (statusObj.email_bool) {
      this.handleStatusChangeEmail(statusObj, applicationsBulkUpdate);
    } else {
      this.postUpdate(statusObj, applicationsBulkUpdate);
    }
  };

  handleStatusChangeEmail = (statusObj, applicationsBulkUpdate) => {
    let emailTemplateSelection = statusObj.emailtemplate ? statusObj.emailtemplate.id : null;

    this.setState({
      bulkEmailModalOpen: true,
      targetStatus: statusObj,
      emailTemplateSelection,
      applicationsBulkUpdate,
    });
  };

  skipEmailButton = () => (
    <button className="email-modal-button" onClick={() => this.postUpdate(this.state.targetStatus)}>
      Skip Email
    </button>
  );

  getItemSize = (index) => {
    let candidate = this.state.candidates[index];
    let applications = candidate.applications;
    if (this.props.isSchoolUser) {
      applications = candidate.allRelevantApplicationsForSchoolAdmin || [];
    }

    return candidate.expanded ? 48 * (applications.length + 1) : 48;
  };

  _isItemLoaded = (index) => !this.props.hasMore || index < this.state.candidates.length;

  Row = ({ currentTab, data, index, style }) => {
    if (!this._isItemLoaded(index)) {
      return <div style={style}>{'Loading...'}</div>;
    }

    const candidate = this.state.candidates[index];
    const isSelected = this.state.selections.has(candidate.id);

    return (
      <div style={style}>
        <MarketplaceCandidateRow
          setFullUser={(candidate) => {
            let candidatesCopy = this.state.candidates;
            candidatesCopy[index] = candidate;
            this.setState({ candidates: candidatesCopy });
          }}
          fullUser={candidate}
          selected={isSelected}
          toggleQuickProfile={this.toggleQuickProfile}
          currentTab={this.props.currentTab}
          shouldShowStatusButtons={currentTab === 3 ? false : true}
        />
      </div>
    );
  };

  render() {
    if (this.props.showSpinner) {
      return <LoadingSpinner />;
    }

    if (this.state.candidates.length === 0) {
      return (
        <NoCandidatesFoundMessage>
          No profiles found. Make sure your addresses are up to date or try removing some filters.
        </NoCandidatesFoundMessage>
      );
    }
    let activeCandidatesOnly =
      _.isEqual(this.props.job_status_list, [1, 2]) ||
      _.isEqual(this.props.job_status_list, [2, 1]);
    let itemData = { activeCandidatesOnly };

    const itemCount = this.props.hasMore
      ? this.state.candidates.length + 1
      : this.state.candidates.length;

    const loadMoreItems = this.props.fetchCandidatesRunning
      ? () => {}
      : this.props.fetchTalentMarketplaceCandidatesDebounced;

    return (
      <div
        className="list-view list-view-container"
        style={{ backgroundColor: '#f8f8f8', padding: '0 48px', width: '100%' }}
      >
        <TalentMarketplaceHeader
          sortBackend={this.sortBackend}
          currentTab={this.props.currentTab}
        />
        <div style={{ height: `calc(100vh - ${this.props.isRequisition ? '274' : '292'}px` }}>
          <InfiniteLoader
            isItemLoaded={this._isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={15}
            threshold={6}
          >
            {({ onItemsRendered }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    onItemsRendered={onItemsRendered}
                    height={height}
                    itemCount={this.state.candidates.length}
                    itemSize={this.getItemSize}
                    itemData={itemData}
                    width={width}
                    overscanCount={30}
                    ref={(ref) => (this.listRef = ref)}
                    style={{ paddingBottom: '15px' }}
                  >
                    {this.Row}
                  </List>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        {this.state.showQuickProfile && (
          <TalentQuickView
            toggleVisibility={this.toggleQuickProfile}
            show={this.state.showQuickProfile}
            user={this.props.partialUser}
          />
        )}
        <ReactTooltip id="not-a-fit" effect="solid">
          <span>Not a Fit</span>
        </ReactTooltip>
        <ReactTooltip id="maybe-fit" effect="solid">
          <span>Potential Fit</span>
        </ReactTooltip>
        <ReactTooltip id="convert-to-prospect" effect="solid">
          <span>Convert to Prospect</span>
        </ReactTooltip>
      </div>
    );
  }
}

const NoCandidatesFoundMessage = styled('div')({
  paddingLeft: '60px',
  paddingTop: '16px',
});

export default withRouter(MarketplaceList);
