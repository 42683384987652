import styled from 'styled-components';
import { CumulativeScorePreviewProps } from '../types';

const CumulativeScorePreview: React.FC<CumulativeScorePreviewProps> = ({ parentObject }) => {
  return (
    <Container>
      <GreyText>Total score:</GreyText>
      <ScoreText>
        {parentObject.cumulative_score} out of {parentObject.max_cumulative_score} possible
      </ScoreText>
    </Container>
  );
};

export default CumulativeScorePreview;

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '125px 160px',
  gridGap: '12px',
  alignItems: 'center',
});

const GreyText = styled.p({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  color: '#999999',
});

const ScoreText = styled(GreyText)({
  fontStyle: 'italic',
  fontWeight: 'normal',
});
