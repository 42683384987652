import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

import { LocalJobClosureNotification } from './types';

export const useQueryGetJobClosureNotifications = (
  jobId: number,
  isValidUserTypeForJobClosureNotifications: boolean
): UseQueryResult<unknown, unknown> => {
  return useQuery({
    queryKey: ['jobClosureNotifications', jobId],
    queryFn: () => fetchNotifications(jobId),
    enabled: isValidUserTypeForJobClosureNotifications,
  });
};

export const fetchNotifications = async (jobId: number): Promise<unknown> => {
  return axios.get(`/api/role/${jobId}/role-notifications/`).then((res) => res.data);
};

export const createNotification = (
  jobId: number,
  newNotification: LocalJobClosureNotification
): Promise<AxiosResponse<unknown, unknown>> => {
  return axios.post(`/api/role/${jobId}/role-notifications/`, {
    type: 'job_closure',
    alerts: [
      {
        type: 'candidate_application_status',

        context: {
          application_status_id: newNotification.applicationStatusId,
          threshold: newNotification.threshold,
        },
      },
    ],
    subscribers: newNotification.subscribers,
  });
};

export const editNotification = (
  notificationId: number,
  newNotification: LocalJobClosureNotification
): Promise<AxiosResponse<unknown, unknown>> => {
  return axios.patch(`/api/role-notifications/${notificationId}/`, {
    alerts: [
      {
        type: 'candidate_application_status',
        context: {
          application_status_id: newNotification.applicationStatusId,
          threshold: newNotification.threshold,
        },
      },
    ],
    subscribers: newNotification.subscribers,
  });
};

export const deleteNotification = (
  notificationId: number
): Promise<AxiosResponse<unknown, unknown>> => {
  return axios.delete(`/api/role-notifications/${notificationId}/`, {
    data: { role_notification_id: notificationId },
  });
};
