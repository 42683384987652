import { Component } from 'react';

import PropTypes from 'prop-types';
import { fetchApplicationStatuses } from '../../utils/statusviewutils';
import ApplicationStatusRow from './ApplicationStatusRow';
import ApplicationStatusModal from './ApplicationStatusModal';
import DestroySchoolapplicationsModal from './DestroySchoolapplicationsModal';
import { appStatusType } from '../../utils/enums';
import { showWarning } from '../../utils/message';
import auth from '../../utils/auth';
import axios from 'axios';
import _ from 'lodash';

import applicationStatusAPI from 'api/applicationStatusAPI';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';

export default class ApplicationStatusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newApplicationStatuses: [],
      showApplicationStatusModal: false,
      showDestroySchoolapplicationsModal: false,
    };
    this.district = auth.getUser().profile.district;
    this.fetchApplicationStatuses = fetchApplicationStatuses.bind(this);
  }

  static propTypes = {
    emailTemplateList: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.fetchApplicationStatuses();
  }

  freshStatus = () => {
    return {
      id: '',
      label: '',
      school_admin_view_access: false,
      school_admin_move_access: false,
      school_admin_move_district: false,
      email_bool: false,
      emailtemplate: null,
      status_type: 1, // pre_hiring_pool
      color: 2, // green
    };
  };

  onSave = (statusObj) => {
    /** The status placement is determined by looking at the school_admin_view_access
     * property. If true, we know the status is a post hiring pool status. If false, it's
     * pre hiring pool.
     */
    /** CHANGE 1/20/2019: New statuses now default to the top of the list
     * CHANGE 3/21/19: adding onboarding statuses, which go between hired and archived by default.
     */
    let newApplicationStatuses = this.state.newApplicationStatuses;
    let method;
    let url;
    let updateOrder = false;
    if (statusObj.id) {
      // Updating an existing status.
      let index = newApplicationStatuses.findIndex((s) => s.id === statusObj.id);
      if (index) {
        // special case: if a status exists and the user is switching on or off the
        // onboarding type, the status object will move from it's
        // spot between hired and archived to the default spot at the beginning of the
        // status list. We have to update the order on the backend in this case.
        if (
          newApplicationStatuses[index].status_type === appStatusType.onboarding &&
          statusObj.status_type !== appStatusType.onboarding
        ) {
          // remove old onboarding status
          newApplicationStatuses.splice(index, 1);
          // then add updated status (no longer onboarding)
          newApplicationStatuses.splice(1, 0, statusObj);
          updateOrder = true;
        } else if (
          newApplicationStatuses[index].status_type !== appStatusType.onboarding &&
          statusObj.status_type === appStatusType.onboarding
        ) {
          // remove old non-onboarding status
          newApplicationStatuses.splice(index, 1);
          // then add updated status (now onboarding)
          newApplicationStatuses.splice(this.getOnboardingIndex(), 0, statusObj);
          updateOrder = true;
        } else {
          newApplicationStatuses[index] = statusObj;
        }
      }
      method = 'put';
      url = `/api/application_status/${statusObj.id}/`;
    } else {
      // Saving a new status.
      let newIndex = 1;
      if (statusObj.status_type === appStatusType.onboarding) {
        newIndex = this.getOnboardingIndex();
      }
      newApplicationStatuses.splice(newIndex, 0, statusObj);
      // Get new order and add to object to be saved.
      // New object will have id === '', eg. [11, 21, 20, '', 31].
      // Backend will replace this empty string with newly created id.
      let order = [];
      newApplicationStatuses.forEach((s) => order.push(s.id));
      statusObj.order = order;
      method = 'post';
      url = '/api/application_status/';
    }

    if (statusObj.emailtemplate) {
      // if saving an email template, backend expects it to be an id.
      statusObj.emailtemplate_id = statusObj.emailtemplate.id;
    }

    axios[method](url, statusObj)
      .then(() => {
        if (updateOrder === false) {
          // only fetch statuses immediately if not updating the order. if we are updating
          // the order, the statuses will be retrieved via the sendOrderUpdate function.
          this.fetchApplicationStatuses();
        }
      })
      .catch((err) => console.log(err)); // TODO add error handling. Get desired behavior.

    this.setState({ newApplicationStatuses, showApplicationStatusModal: false }, () => {
      if (updateOrder === true) {
        this.sendOrderUpdate(true);
      }
    });
  };

  getOnboardingIndex = () => {
    // onboarding status is placed after last hired status. I don't think there can be
    // more than one hired status, but I'll look for the last one just in case we allow
    // it in the future.
    let { newApplicationStatuses } = this.state;
    // start at end to find the last hired status
    for (let i = newApplicationStatuses.length - 1; i >= 0; i--) {
      if (newApplicationStatuses[i].status_type === appStatusType.hired) {
        // when you find the last hired status, set index to the spot right after it.
        return i + 1;
      }
    }
    // fallback
    return 1;
  };

  onDelete = (id) => {
    // this may take a bit of time, since it's moving all applications on backend.
    // Consider adding loading spinner.
    axios
      .delete(`/api/application_status/${id}/`)
      .catch(() =>
        showWarning(
          'There was an error deleting this status. Please contact support@hirenimble.com for assistance.'
        )
      );

    // for now, make update on front end instantly.
    // TODO: see how much time backend app deletion will take.
    let newApplicationStatuses = this.state.newApplicationStatuses;
    let index = newApplicationStatuses.findIndex((s) => s.id === id);
    if (index) {
      newApplicationStatuses.splice(index, 1);
    }
    this.setState({ newApplicationStatuses });
  };

  onMove = (index, vector, e, bypassWarning) => {
    /** Change the order of the status array, allowing the user to choose the order
     * in which they appear.
     * @param {number} index: current index of the clicked status.
     * @param {number} vector: either +1 or -1. Add this to index to get new index.
     */
    e.stopPropagation();
    let newApplicationStatuses = this.state.newApplicationStatuses;
    let new_index = index + vector;
    if (new_index < 0 || new_index >= newApplicationStatuses.length) {
      // can't move item out of range
      return;
    }

    if (
      newApplicationStatuses[new_index].reserved &&
      newApplicationStatuses[new_index].status_type !== appStatusType.hiring_pool
    ) {
      // Can't swap with terminal reserved statuses (hired, archived)
      return;
    }

    const movingAcrossHiringPool =
      newApplicationStatuses[new_index].status_type === appStatusType.hiring_pool;

    // If moving from after SHP to pre SHP, warn the user that schoolapplications will be destroyed
    if (movingAcrossHiringPool && vector === -1 && !bypassWarning) {
      this.setState({
        showDestroySchoolapplicationsModal: true,
        DestroySchoolapplicationsModalValues: { index: index, vector: vector },
      });
      // The modal will call onSave again based on user input
      return;
    }
    newApplicationStatuses.splice(new_index, 0, newApplicationStatuses.splice(index, 1)[0]);
    newApplicationStatuses.forEach((s, i) => (s._order = i));
    this.setState({ newApplicationStatuses }, () => {
      this.sendOrderUpdate();
      if (movingAcrossHiringPool) {
        const movingPostHiringPool = vector === 1;
        this.updateStatus(new_index, movingPostHiringPool);
      }
    });
  };

  sendOrderUpdate = _.debounce((fetchStatuses = false) => {
    /**
     * @param {boolean} fetchStatuses: whether or not to pull in statuses after the order update.
     */
    let order = this.state.newApplicationStatuses.map((s) => s.id);
    axios.patch(`/api/district/${this.district.id}/update_status_order/`, { order }).then(() => {
      if (fetchStatuses === true) {
        this.fetchApplicationStatuses();
      }
    });
  }, 1000);

  updateStatus = (index, movingPostHiringPool) => {
    /**
     * This procedure runs when a status is moved across the hiring pool status.
     * If the status is not archived, update the status type to match the new
     * location pre or post hiring pool. If moving post hiring pool, ensure
     * school admin view access is true, as school admins can always view applications
     * in post hiring pool statuses.
     */
    const newApplicationStatuses = [...this.state.newApplicationStatuses];
    let status = newApplicationStatuses[index];
    const isNotArchived = status.status_type !== appStatusType.archived;

    // Prepare data to be persisted in the db.
    // It will be sent using a patch request so we only need to
    // add the data that is changing.
    const payload = {};

    if (isNotArchived) {
      if (movingPostHiringPool) {
        payload.status_type = appStatusType.post_hiring_pool;
        payload.school_admin_view_access = true;
      } else {
        payload.status_type = appStatusType.pre_hiring_pool;
      }
    } else {
      // status is archived in this block
      if (!movingPostHiringPool) {
        // If moving an archived status from post hiring pool to pre hiring pool:
        // if move_district was checked, both view & move checkboxes should be checked
        // if move_district was unchecked, view & move should be unchecked
        if (status.school_admin_move_district) {
          payload.school_admin_view_access = true;
          payload.school_admin_move_access = true;
        } else {
          payload.school_admin_view_access = false;
          payload.school_admin_move_access = false;
        }

        // school_admin_move_district is always false pre hiring pool
        payload.school_admin_move_district = false;
      } else {
        // if moving an archived status from pre to post hiring pool...
        // view_access is always true for post-hiring-pool archived statuses
        payload.school_admin_view_access = true;
      }
    }

    // Save a copy in case the update fails and we need to revert...
    const initial_status = { ...status };

    // Then set the properties in local state. We're going to patch
    // the update and we'll revert if it fails.
    for (let [key, value] of Object.entries(payload)) {
      status[key] = value;
    }

    this.setState({ newApplicationStatuses });

    // Optimistically update the status
    applicationStatusAPI.partial_update(status.id, payload).catch((err) => {
      status = initial_status;
      this.setState({ newApplicationStatuses });
      throw new Error(err);
    });
  };

  render() {
    // Archived statuses can come before or after the hiring pool. We have
    // to compare the order of the archived status with the order of the
    // hiring pool status to know whether it's post hiring pool.
    const hiringPoolLocation = this.state.newApplicationStatuses.findIndex(
      (s) => s.status_type === appStatusType.hiring_pool
    );

    return (
      <div className="application-status-container mb2">
        <div className="mt2">
          <AnchorTagHeader
            id="candidateStatuses"
            title="Candidate Statuses"
            onClick={() => this.setState({ showApplicationStatusModal: true })}
            buttonText="+ New Status"
          />
          {this.state.newApplicationStatuses.map(
            (status, i) =>
              status.status_type !== appStatusType.draft && (
                <ApplicationStatusRow
                  index={i}
                  key={i}
                  status={status}
                  emailTemplateList={this.props.emailTemplateList}
                  onSave={this.onSave}
                  onDelete={this.onDelete}
                  onMove={this.onMove}
                  newApplicationStatuses={this.state.newApplicationStatuses}
                  isPreHiringPool={i < hiringPoolLocation}
                />
              )
          )}
        </div>
        {this.state.showApplicationStatusModal && (
          <ApplicationStatusModal
            status={this.freshStatus()}
            show={this.state.showApplicationStatusModal}
            onHide={() => this.setState({ showApplicationStatusModal: false })}
            emailTemplateList={this.props.emailTemplateList}
            onSave={this.onSave}
            newApplicationStatuses={this.state.newApplicationStatuses}
            // new statuses start at the top of the list which is pre-hiring-pool
            isPreHiringPool={true}
          />
        )}
        {this.state.showDestroySchoolapplicationsModal && (
          <DestroySchoolapplicationsModal
            show={this.state.showDestroySchoolapplicationsModal}
            onHide={() => this.setState({ showDestroySchoolapplicationsModal: false })}
            move={this.onMove}
            moveValues={this.state.DestroySchoolapplicationsModalValues}
          />
        )}
      </div>
    );
  }
}
