import { Component } from 'react';
import PropTypes from 'prop-types';
import { handleOutsideClickOnValidForm } from '../../../../utils/util';
import { vacancyTypes } from '../../../../utils/enums';

const vacancyTypeColorByKey = vacancyTypes().reduce((obj, item) => {
  obj[item.key] = item.color;
  return obj;
}, {});

const vacancyTypeLabelByKey = vacancyTypes().reduce((obj, item) => {
  obj[item.key] = item.label;
  return obj;
}, {});

export default class VacancyTypeDropdown extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    vacancyType: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  outsideClickListener = (ev) => handleOutsideClickOnValidForm(this.node, ev, this.toggleOptions);

  toggleOptions = () => {
    if (!this.state.showOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    this.setState((prevState) => {
      return { showOptions: !prevState.showOptions };
    });
  };

  renderVacancyTypeOptions = () => {
    return vacancyTypes().map((vacancyType, i) => (
      <div key={i} className="select-list-item" onClick={() => this.changeVacancyType(vacancyType)}>
        <div className={`status-circle ${vacancyType.color}`} />
        <p>{vacancyType.label}</p>
      </div>
    ));
  };

  changeVacancyType = (vacancyType) => {
    this.props.handleChange({
      target: {
        name: 'vacancyType',
        value: vacancyType.key,
      },
    });
  };

  render() {
    let vacancyTypeColor = vacancyTypeColorByKey[this.props.vacancyType];
    let vacancyTypeLabel = vacancyTypeLabelByKey[this.props.vacancyType];
    return (
      <div
        className="select-container"
        onClick={this.toggleOptions}
        ref={(node) => (this.node = node)}
      >
        <div className="flex align-center">
          <div className={`status-circle ${vacancyTypeColor}`} />
          <p>{vacancyTypeLabel}</p>
        </div>
        <div className={`cl-filter-selection-list ${this.state.showOptions ? 'show' : ''}`}>
          {this.renderVacancyTypeOptions()}
        </div>
      </div>
    );
  }
}
