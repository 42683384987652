import { useEffect } from 'react';

export const useDynamicStructuredData = (structuredData: string): void => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = structuredData;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [structuredData]);
};
