import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { MoreInfoSliderProps } from '../types';
import { SchoolInfoHighlightRow } from '../HighlightRows';
import { Inter } from 'features/Connect/features/JobDiscovery/utils';

export const HeaderSection: React.FC<MoreInfoSliderProps> = ({ school }) => {
  const hasPrincipal = !!school?.principal;

  return (
    <MoreInfoHeaderContainer>
      {school && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SchoolName>{school.name}</SchoolName>
          </Grid>
          {hasPrincipal && (
            <Grid item xs={12}>
              <PrincipalName>
                Principal {school.principal.first_name} {school.principal.last_name}
              </PrincipalName>
            </Grid>
          )}
          <Grid item xs={12}>
            <InfoRow>
              {school?.school_metadata?.school_type}
              <span> &#183; </span>
              {school?.district?.name}
            </InfoRow>
          </Grid>
          <Grid item xs={12}>
            <SchoolInfoHighlightRow
              school={school}
              isInterestModal={false}
              isMoreInfoSlider={true}
            />
          </Grid>
        </Grid>
      )}
    </MoreInfoHeaderContainer>
  );
};

const MoreInfoHeaderContainer = styled(Box)(() => ({
  width: '100%',
}));

const SchoolName = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 600,
  fontSize: '18px',
  color: '#101828',
}));

const PrincipalName = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 600,
  fontSize: '16px',
  color: '#101828',
  marginTop: '24px',
}));

const InfoRow = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 600,
  fontSize: '14px',
  color: '#666',
  marginTop: '4px',
}));
