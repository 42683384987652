import React from 'react';
import { Chip as MUIChip } from '@mui/material';
import { SxProps } from '@mui/material';
import merge from '@mui/system/merge';
import { ChipProps } from './types';

export const Chip: React.FC<ChipProps> = ({
  deletable = false,
  clickable = false,
  onClickFunction,
  onDeleteFunction,
  size = 'small',
  sx,
  variant = 'outlined',
  label,
  icon,
  dataTestId,
}) => {
  const customStyles: SxProps = {
    display: 'flex',
    alignContent: 'center',
    color: 'var(--Gray-700, #344054)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: variant === 'filled' ? 600 : 500,
    lineHeight: variant === 'filled' ? '19px' : '18px',
  };

  const mergedStyles = sx ? merge(customStyles, sx) : customStyles;

  return (
    <MUIChip
      label={label}
      icon={icon}
      variant={variant}
      onClick={clickable ? onClickFunction : undefined}
      onDelete={deletable ? onDeleteFunction : undefined}
      size={size}
      sx={mergedStyles}
      data-testid={dataTestId || 'mui-chip'}
    />
  );
};
