import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Dropdown, SimpleCheckInput } from 'ui-kit';
import { useArrayDependency } from 'hooks';

import { ReactComponent as SearchIcon } from 'assets/icon_search_glass.svg';

export const ChoiceDropdown = ({
  label,
  choices,
  selectedChoiceIds,
  onToggleChoice,
  onSelectAllChoices,
  onClearAllChoices,
  height = '40px',
  width = '100%',
  searchBarPlaceholder = '',
}) => {
  const isActive = selectedChoiceIds.length > 0 && selectedChoiceIds.length < choices.length;
  const [searchValue, setSearchValue] = useState('');

  let filteredChoices;
  if (choices === undefined) {
    filteredChoices = [];
  } else if (!searchValue) {
    filteredChoices = choices;
  } else {
    filteredChoices = choices.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  return (
    <Dropdown.Dropdown
      label={label}
      isActive={isActive}
      inputProps={{ minWidth: '175px', height, width, fontSize: '15px' }}
      labelProps={{ color: '#444' }}
    >
      <TextButtonWrapper>
        <TextButton onClick={onSelectAllChoices}>SELECT ALL</TextButton>
        <TextButton onClick={onClearAllChoices}>CLEAR</TextButton>
      </TextButtonWrapper>
      <InputFilterContainer>
        <InputFilter
          type="text"
          placeholder={searchBarPlaceholder}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {searchValue ? (
          <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
        ) : (
          <PositionedSearchIcon />
        )}
      </InputFilterContainer>
      {filteredChoices.map((choice, i) => {
        let isSelected = false;
        let isGroupHeaderConfused = false;
        let key = 'id';

        // Some data sets use value, others (like schools, use id)
        if (!choice.id) {
          key = 'value';
        }

        if (choice.isGroupHeader) {
          const group = filteredChoices.filter((f) => f.group === choice.group);
          isSelected = group.every((e) => selectedChoiceIds.includes(e[key]));
          isGroupHeaderConfused =
            group.some((ss) => selectedChoiceIds.includes(ss[key])) &&
            group.length > 1 &&
            !isSelected;
        } else {
          isSelected = selectedChoiceIds.includes(choice[key]);
        }

        return (
          <DropdownChoice
            key={i}
            isSelected={isSelected}
            onClick={() => onToggleChoice(choice)}
            isGroupHeader={choice.isGroupHeader}
            isConfused={isGroupHeaderConfused}
          >
            {choice.label}
          </DropdownChoice>
        );
      })}
    </Dropdown.Dropdown>
  );
};

const TextButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const TextButton = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.uiColors.greens.full};
  font-size: 14px;
  font-weight: bold;

  :hover {
    text-decoration: underline;
  }
`;

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  padding-left: 1rem;
  padding-right: 0.8rem;
  margin-bottom: 6px;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 20px;
  margin: 0;
  width: 100%;
`;

const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 20px;
  top: 9px;
  width: 16px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 24px;
  top: 4px;

  font-size: 21px;
  cursor: pointer;
`;

const DropdownListItem = ({ isSelected, children, isConfused, ...rest }) => {
  return (
    <Dropdown.ListItem {...rest}>
      <span>{children}</span>
      <SimpleCheckInput isSelected={isSelected} isConfused={isConfused} />
    </Dropdown.ListItem>
  );
};

const DropdownChoice = styled(({ isGroupHeader, ...rest }) => <DropdownListItem {...rest} />)`
  padding: 0.5rem 1rem;
  padding-left: ${(props) => (props.isGroupHeader ? '1rem' : '1.5rem')};
  font-size: ${(props) => (props.isGroupHeader ? '14px' : '13px')};
  font-weight: ${(props) => (props.isGroupHeader ? 'bold' : 'normal')};

  display: grid;
  grid-template-columns: auto 20px;
  overflow: hidden;

  color: #444;

  align-items: center;

  span {
    margin-right: 0.5rem;

    &:last-child {
      margin-top: 9px;
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const useChoiceDropdown = ({ choices, initialSelectedChoiceIds }) => {
  const [selectedChoiceIds, setSelectedChoiceIds] = useState(initialSelectedChoiceIds ?? []);
  const [isTouched, setIsTouched] = useState(false);
  const initialSelectedChoiceDependency = useArrayDependency(initialSelectedChoiceIds);

  // Keep the dropdown in-sync if the initial choices change (eg/ schools finally load in)
  // Ensures that if the user has made choices, but a new school is added or something, their
  // changes are persisted
  useEffect(() => {
    if (!isTouched) {
      setSelectedChoiceIds(initialSelectedChoiceDependency);
    }
  }, [initialSelectedChoiceDependency, isTouched]);

  const onToggleChoice = (choiceToToggle) => {
    let updatedIds = [];

    setSelectedChoiceIds((prevState) => {
      const choiceIsSelected = selectedChoiceIds.includes(choiceToToggle.id);
      updatedIds = [...prevState];

      if (choiceIsSelected) {
        let idsToRemove = [];
        // Deselect choice header & all children
        if (choiceToToggle.isGroupHeader === true) {
          idsToRemove = choices
            .filter((choice) => choice.group === choiceToToggle.group)
            .map((choice) => choice.id);
        } else {
          // Remove invidividual item
          idsToRemove = [choiceToToggle.id];

          // Deactivate group header (if it exists)
          // @TODO(sean): Deactivate all headers that have this option
          const groupHeader = choices.find(
            (choice) => choice.group === choiceToToggle.group && choice.isGroupHeader
          );
          if (groupHeader) {
            idsToRemove = [...idsToRemove, groupHeader.id];
          }
        }
        updatedIds = updatedIds.filter((id) => !idsToRemove.includes(id));
      } else {
        let idsToAdd = [];
        if (choiceToToggle.isGroupHeader === true) {
          // Select choice header & all children
          idsToAdd = choices
            .filter((choice) => choice.group === choiceToToggle.group)
            .map((choice) => choice.id)
            .filter((id) => !updatedIds.includes(id));
        } else if (!updatedIds.includes(choiceToToggle.id)) {
          // Select invidividual item
          idsToAdd = [choiceToToggle.id];

          // Activate group header, if necessary
          // @TODO(sean): Activate all headers that have this option
          const groupHeader = choices.find(
            (choice) => choice.group === choiceToToggle.group && choice.isGroupHeader
          );
          if (groupHeader) {
            const groupChoices = choices
              .filter((choice) => choice.group === choiceToToggle.group)
              .filter((choice) => !choice.isGroupHeader)
              .filter((choice) => choice.id !== choiceToToggle.id);
            if (groupChoices.every((choice) => updatedIds.includes(choice.id))) {
              idsToAdd = [...idsToAdd, groupHeader.id];
            }
          }
        }
        updatedIds = [...updatedIds, ...idsToAdd];
      }
      return updatedIds;
    });

    setIsTouched(true);
  };

  const onSelectAllChoices = () => {
    const allChoiceIds = choices.map((choice) => choice.id);
    setSelectedChoiceIds(allChoiceIds);
    setIsTouched(true);
  };

  const onClearAllChoices = () => {
    setSelectedChoiceIds([]);
    setIsTouched(true);
  };

  return {
    selectedChoiceIds,
    onToggleChoice,
    onSelectAllChoices,
    onClearAllChoices,
  };
};
