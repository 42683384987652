import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import auth from '../../../utils/auth';
// Sub components
// import VacancyCounts from './vacancyCounts';
import HiredCandidateList from './HiredCandidateList';
import VacanciesList from './VacanciesList';
import VacanciesFilters from './VacanciesFilters';
import AddVacancyModal from './AddVacancyModal';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingSpinner from '../../loadingSpinner';
import { Button } from 'ui-kit';
import { ATSVacancyDashboardDataTestIds } from '../../../data-testids/ATS';

export default class Vacancies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVacancyModalOpen: false,
    };
  }

  static propTypes = {
    adminUsers: PropTypes.arrayOf(PropTypes.object),
    unplacedCandidates: PropTypes.arrayOf(PropTypes.object),
    vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadingSpinner: PropTypes.bool.isRequired,
    getVacanciesDebounced: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    resetFilters: PropTypes.func.isRequired,
    schoolsFilter: PropTypes.array.isRequired,
    subcategoriesFilter: PropTypes.array.isRequired,
    rolesFilter: PropTypes.array.isRequired,
    hiringSeasonFilter: PropTypes.array.isRequired,
    schoolOptions: PropTypes.array.isRequired,
    roleOptions: PropTypes.array.isRequired,
    subcategoryOptions: PropTypes.array.isRequired,
    hiringSeasonOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateMultiSelectFilter: PropTypes.func.isRequired,
    onSelectAllChoices: PropTypes.func.isRequired,
    onClearAllChoices: PropTypes.func.isRequired,
    filteredHiringSeasons: PropTypes.array.isRequired,
    removeCandidateFromVacancy: PropTypes.func.isRequired,
    pairCandidateWithVacancy: PropTypes.func.isRequired,
    updateVacancyInContainerState: PropTypes.func.isRequired,
    postVacancy: PropTypes.func.isRequired,
    putVacancy: PropTypes.func.isRequired,
    deleteVacancy: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    totalLineItems: PropTypes.number.isRequired,
  };

  openVacancyModal = () => {
    this.setState({ isVacancyModalOpen: true });
  };

  closeVacancyModal = () => {
    this.setState({ isVacancyModalOpen: false });
  };

  renderHeaderLine = () => (
    <FilterBar>
      <Button
        variant="link"
        mt="12px"
        mb="40px"
        padding="0.85em 25px"
        onClick={this.props.resetFilters}
        data-testid={ATSVacancyDashboardDataTestIds.CLEAR_ALL_BUTTON}
      >
        Clear All Filters
      </Button>
      {auth.isDistrictAdmin() && (
        <Button variant="primary" mt="12px" mb="40px" onClick={this.openVacancyModal}>
          + New Vacancy
        </Button>
      )}
    </FilterBar>
  );

  postVacancyThenCloseModal = (newVacancy) => {
    this.props.postVacancy(newVacancy).finally(this.closeVacancyModal);
  };

  render() {
    return (
      <div className="vacancies-container">
        {this.props.unplacedCandidates.length > 0 && (
          <HiredCandidateList unplacedCandidates={this.props.unplacedCandidates} />
        )}

        {this.renderHeaderLine()}

        <VacanciesFilters
          updateMultiSelectFilter={this.props.updateMultiSelectFilter}
          schoolsFilter={this.props.schoolsFilter}
          rolesFilter={this.props.rolesFilter}
          subcategoriesFilter={this.props.subcategoriesFilter}
          onSelectAllChoices={this.props.onSelectAllChoices}
          onClearAllChoices={this.props.onClearAllChoices}
          schoolOptions={this.props.schoolOptions}
          roleOptions={this.props.roleOptions}
          categories={this.props.categories}
          subcategoryOptions={this.props.subcategoryOptions}
          hiringSeasonFilter={this.props.hiringSeasonFilter}
          hiringSeasonOptions={this.props.hiringSeasonOptions}
        />

        {this.props.loadingSpinner ? (
          <LoadingSpinner />
        ) : (
          <InfiniteScroll loadMore={this.props.getVacanciesDebounced} hasMore={this.props.hasMore}>
            <CountBar>
              {`${this.props.totalLineItems} Vacanc${
                this.props.totalLineItems !== 1 ? 'ies' : 'y'
              }`}
            </CountBar>

            <VacanciesList
              vacancies={this.props.vacancies}
              unplacedCandidates={this.props.unplacedCandidates}
              removeCandidateFromVacancy={this.props.removeCandidateFromVacancy}
              deleteVacancy={this.props.deleteVacancy}
              pairCandidateWithVacancy={this.props.pairCandidateWithVacancy}
              adminUsers={this.props.adminUsers}
              updateVacancyInContainerState={this.props.updateVacancyInContainerState}
              sortBy={this.props.sortBy}
              roleOptions={this.props.roleOptions}
              schoolOptions={this.props.schoolOptions}
              putVacancy={this.props.putVacancy}
            />
          </InfiniteScroll>
        )}

        {this.state.isVacancyModalOpen && (
          <AddVacancyModal
            show={this.state.isVacancyModalOpen}
            onHide={this.closeVacancyModal}
            roleOptions={this.props.roleOptions}
            schoolOptions={this.props.schoolOptions}
            postVacancyThenCloseModal={this.postVacancyThenCloseModal}
          />
        )}
      </div>
    );
  }
}

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CountBar = styled.div`
  margin: 40px 0;
  font-size: 19px;
  line-height: 26px;
`;
