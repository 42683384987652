import { BaseNimbleAPI } from './baseAPI';

class CustomTagAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/custom-tags/' };
  }

  /**
   * Returns a list of all of the custom tags for the specified district.
   */
  async fetchCustomTags() {
    const url = '/';
    const { data } = await this.get(url);
    return data;
  }

  /**
   * Get a single tag
   */
  async fetchCustomTag(tagId) {
    const url = `/${tagId}/`;
    const { data } = await this.get(url);
    return data;
  }

  async createCustomTag(tag) {
    const url = '/';
    const { data } = await this.post(url, tag);
    return data;
  }

  async updateCustomTag(tag) {
    const url = `/${tag.id}/`;
    const { data } = await this.patch(url, tag);
    return data;
  }

  async deleteCustomTag(tagId) {
    const url = `/${tagId}/`;
    const { data } = await this.delete(url);
    return data;
  }
}

export default new CustomTagAPI();
