import { Unstable_Grid2 as MuiGrid } from '@mui/material';
import { GridProps } from './types';

export const Grid: React.FC<GridProps> = ({
  children,
  spacing,
  justifyContent,
  alignItems,
  sx,
}) => {
  return (
    <MuiGrid
      container
      spacing={spacing}
      justifyContent={justifyContent}
      alignItems={alignItems}
      sx={sx}
    >
      {children}
    </MuiGrid>
  );
};
