import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router';

import { ColumnCategories } from './ColumnCategories';
import { Container, ListContainer } from './styles';
import { deleteQueryStringValue, setQueryStringValue } from 'utils/util';
import { DeleteTemplateModal } from './DeleteTemplateModal';
import { emptyFilterState, fetchAndSetQueryParameters, HRBP_ASSIGNEE_FIELD_NAME } from './utils';
import { ErrorSnackbar, SuccessSnackbar } from 'sharedComponents/Snackbar';
import {
  fetchAndSetCategories,
  fetchAndSetDistrictUsers,
  fetchAndSetHRBPAssignees,
  fetchAndSetJobOwners,
  fetchAndSetLocations,
} from './requests';
import { ItemsHeaderSection } from './ItemsHeaderSection';
import { ItemsListProps } from './types';
import { MergeRoleModal } from 'components/DistrictJobsList/MergeRoleModal';
import { roleStatusFilter } from 'features/InternalJobsList/enums';
import { Rows } from './Rows';
import { statuses } from 'features/InternalJobsList/utils';
import auth from '../../utils/auth';
import CreateJobOrTemplateModal from 'components/CreateJobOrTemplateModal';
import InternalJobsFilters from '../InternalJobsList/Filters';
import LoadingSpinner from '../../components/loadingSpinner';
import ReturnSubmittedRoleModal from 'components/DistrictJobsList/ReturnSubmittedRoleModal';
import rolesAPI from 'api/rolesAPI';
import roleTemplatesAPI from 'api/roleTemplatesAPI';
import ShareToSocialMedia from 'components/ShareToSocialMedia';

export default function ItemsList({ variant }: ItemsListProps) {
  const [roleBeingMerged, setRoleBeingMerged] = useState(null);
  const [roleToReturnId, setRoleToReturnId] = useState(null);
  const [roleToShare, setRoleToShare] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [shouldFetchItems, setShouldFetchItems] = useState(false);
  const [shouldIgnoreLastFetch, setShouldIgnoreLastFetch] = useState(false);
  const [shouldShowSpinner, setShouldShowSpinner] = useState<boolean>(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  const [filterOptions, setFilterOptions] = useState({
    categories: [],
    districtUsers: [],
    hrbpAssignees: [],
    jobOwners: [],
    schools: [],
  });

  const [shouldShow, setShouldShow] = useState({
    createModal: false,
    deleteModal: false,
    errorToast: false,
    mergeModal: false,
    returnModal: false,
    shareModal: false,
    createSuccessToast: false,
    deleteSuccessToast: false,
  });

  const [state, setState] = useState({
    items: [],
    totalCount: 0,
    page: 0,
    more: false,
    fetchItemsCompleted: false,
    fetchItemsRunning: false,
    ...emptyFilterState,
  });

  const authUser = auth.getUser();
  const districtId = authUser.profile.district.id;
  const history = useHistory();
  const initialMountRef = useRef<boolean>(false);
  const isItemLoaded = (index: number): boolean => !state.more || index < state.items.length;
  const itemCount = state.more ? state.items?.length + 1 : state.items?.length;
  const location = useLocation();
  const smallerLayout = auth.isSchoolUser() || auth.isDistrictUserOnly();

  useEffect(() => {
    if (initialMountRef.current) {
      axios
        .get('/api/search/districtroles/', {
          params: {
            is_template: variant === 'templates' ? true : null,
            page_size: 20,
          },
        })
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            items: response.data.results,
            totalCount: response.data.count,
          }));
        });
    } else {
      initialMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShow.createSuccessToast, shouldShow.deleteSuccessToast]);

  useEffect(() => {
    setState((previousState) => ({ ...previousState, query: '', page: 0 }));
  }, [selectedPage]);

  useEffect(() => {
    document.body.classList.add('internal-jobs-page');
    setState((previousState) => ({ ...previousState, query: '', page: 0 }));
    clearAllFilters();
    setDefaultSearchParams();
    fetchAndSetQueryParameters(variant === 'templates', setState);
    fetchAndSetCategories(districtId, setFilterOptions);
    fetchAndSetJobOwners(districtId, setFilterOptions);
    fetchAndSetHRBPAssignees(districtId, setFilterOptions);
    fetchAndSetLocations(setFilterOptions);
    fetchAndSetDistrictUsers(districtId, setFilterOptions);

    return () => {
      setShouldIgnoreLastFetch(false);
      document.body.classList.remove('internal-jobs-page');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      items: [],
      page: 0,
      more: false,
    }));

    if (!shouldIgnoreLastFetch) setShouldFetchItems(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.grades,
    state.categories,
    state.hrbp_assignees,
    state.internal_external,
    state.sortField,
    state.sortDescending,
    state.schools,
    state.job_owners,
    state.district_users,
    state.hiring_seasons,
    state.job_status_list,
    selectedPage,
  ]);

  useEffect(() => {
    if (shouldFetchItems && !shouldIgnoreLastFetch) {
      fetchItems(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchItems, state.items, state.page, state.more]);

  const lastRequestParams = useRef(null);

  const fetchItems = (concatBool: boolean) => {
    setShouldFetchItems(false);

    const {
      query,
      categories,
      grades,
      hiring_seasons,
      internal_external,
      job_owners,
      job_status_list,
      hrbp_assignees,
      district_users,
      schools,
      sortField,
      sortDescending,
      page,
    } = state;

    const requestParams = {
      categories: categories.join(','),
      district_users: district_users.join(),
      grades: grades.join(','),
      hiring_seasons: hiring_seasons.join(','),
      hrbp_assignees: hrbp_assignees.join(','),
      internal_external: internal_external.join(','),
      is_template: variant === 'templates' ? true : null,
      job_owners: job_owners.join(','),
      job_status_list: job_status_list.join(','),
      page_size: 20,
      page: state.more ? page + 1 : 1,
      query: query,
      schools: schools.join(','),
      sortDescending,
      sortField,
    };

    // If requests are equal, avoid a new request
    if (JSON.stringify(lastRequestParams.current) === JSON.stringify(requestParams)) {
      return;
    } else {
      lastRequestParams.current = requestParams;
    }

    setState((prevState) => ({
      ...prevState,
      fetchItemsRunning: true,
    }));

    if (!concatBool) setShouldShowSpinner(true);

    axios
      .get('/api/search/districtroles/', {
        params: requestParams,
      })
      .then((r) => {
        if (
          query === state.query &&
          sortField === state.sortField &&
          sortDescending === state.sortDescending &&
          page === state.page &&
          !shouldIgnoreLastFetch
        ) {
          setShouldShowSpinner(false);
          setState((prevState) => ({
            ...prevState,
            items: concatBool === true ? state.items.concat(r.data.results) : r.data.results,
            totalCount: r.data.count,
            page: r.data.page,
            more: r.data.more,
            fetchItemsCompleted: true,
            fetchItemsRunning: false,
          }));
        }
      })
      .catch(() => {
        toggleErrorToast(true);
      });
  };

  const fetchItemsDebounced = () => {
    if (state.fetchItemsRunning === true) {
      return;
    } else {
      fetchItems(true);
    }
  };

  const handleClearAll = (fieldName) => {
    if (variant === 'jobs') {
      deleteQueryStringValue(fieldName);
    }

    setState((previousState) => ({
      ...previousState,
      [fieldName]: [],
    }));
  };

  const handleDeleteTemplate = (event) => {
    event.stopPropagation();

    roleTemplatesAPI
      .deleteTemplate(templateToDelete.id)
      .then(() => {
        toggleDeleteModal(false);
        toggleDeleteSuccessToast(true);
      })
      .catch(() => toggleErrorToast(true));
  };

  const handleSelectAll = (fieldName, values) => {
    if (variant === 'jobs') {
      deleteQueryStringValue(fieldName);
    }

    setState((previousState) => ({
      ...previousState,
      [fieldName]: values.map((v) => v.value),
    }));
  };

  const handleSetCategories = (categories) => {
    if (variant === 'jobs') {
      setQueryStringValue('categories', categories.join());
    }

    setState((previousState) => ({
      ...previousState,
      categories,
    }));
  };

  const handleSetGrades = (grades) => {
    if (variant === 'jobs') {
      setQueryStringValue('grades', grades.join());
    }

    setState((previousState) => ({
      ...previousState,
      grades,
    }));
  };

  const handleSetLocations = (locations) => {
    if (variant === 'jobs') {
      setQueryStringValue('schools', locations.join());
    }

    setState((previousState) => ({
      ...previousState,
      schools: locations,
    }));
  };

  const handleSetQuery = (newQuery) => {
    return setState((prevState) => ({
      ...prevState,
      query: newQuery,
      page: 0,
    }));
  };

  const openMergeRoleModal = (roleBeingMerged) => {
    setShouldShow((previousState) => ({ ...previousState, mergeModal: true }));
    setRoleBeingMerged(roleBeingMerged);
  };

  const openShareModal = (role) => {
    setShouldShow((previousState) => ({ ...previousState, shareModal: true }));
    setRoleToShare(role);
  };

  const toggleReturnModal = (roleId, shouldShow) => {
    setRoleToReturnId(roleId);
    setShouldShow((previousState) => ({ ...previousState, returnModal: shouldShow }));
  };

  const toggleCreateModal = (selectedItem, shouldShow) => {
    setShouldShow((previousState) => ({ ...previousState, createModal: shouldShow }));

    if (shouldShow === true) {
      setSelectedItem(selectedItem);
    }
  };

  const toggleDeleteModal = (shouldShow) =>
    setShouldShow((previousState) => ({ ...previousState, deleteModal: shouldShow }));

  const toggleErrorToast = (shouldShow) =>
    setShouldShow((previousState) => ({ ...previousState, errorToast: shouldShow }));

  const toggleFeaturedRole = (role) => {
    let remoteUpdate;

    if (role.is_featured) {
      updateRole(role.id, { is_featured: false });
      remoteUpdate = rolesAPI.unfeatureRole(role.id);
    } else {
      updateRole(role.id, { is_featured: true });
      remoteUpdate = rolesAPI.featureRole(role.id);
    }
    // Update with server response
    remoteUpdate.then(({ is_featured }) => updateRole(role.id, { is_featured }));
  };

  const toggleCreateSuccessToast = (shouldShow) => {
    setShouldShow((previousState) => ({ ...previousState, createSuccessToast: shouldShow }));
  };

  const toggleDeleteSuccessToast = (shouldShow) => {
    setShouldShow((previousState) => ({ ...previousState, deleteSuccessToast: shouldShow }));
  };

  const toggleCurrentPage = (item) => {
    setSelectedPage(item);
    resetItems();
  };

  const onSearch = (concatBool) => {
    setState((previousState) => ({ ...previousState, page: 0 }));
    fetchItems(concatBool);
  };

  const clearAllFilters = () => {
    setState({
      ...emptyFilterState,
      page: 0,
      more: false,
      items: [],
      totalCount: 0,
      fetchItemsCompleted: false,
      fetchItemsRunning: false,
      job_status_list: [],
    });
  };

  const resetItems = () => {
    clearAllFilters();
    history.push({ search: null });
  };

  const returnSubmittedRole = (returnNoteText) => {
    rolesAPI.returnSubmittedRole(roleToReturnId, returnNoteText).then(() => {
      toggleReturnModal(null, false);
    });
  };

  const setDefaultSearchParams = () => {
    const { search } = location;

    if (variant === 'templates') {
      history.push({ search: null });
      return;
    }

    const defaultSearchParams = authUser.profile.internal_jobs_default_search_params;

    if (!search && defaultSearchParams) {
      const newQueryParameters = `?` + defaultSearchParams;
      history.push({ search: newQueryParameters });
    }
  };

  const sortRolesBy = (field: string) => {
    setState((prevState) => {
      if (field === prevState.sortField) {
        return { ...prevState, sortDescending: !prevState.sortDescending };
      } else {
        return {
          ...prevState,
          sortField: field,
          sortDescending: field === 'created' ? true : false,
        };
      }
    });
  };

  const updateMultiSelectFilter = (name, value) => {
    const currentValues = [...state[name]];
    let updatedValues = [];

    if (currentValues.includes(value)) {
      updatedValues = currentValues.filter((item) => item !== value);
    } else {
      updatedValues = [...currentValues];
      updatedValues.push(value);
    }

    if (variant === 'jobs') {
      setQueryStringValue(name, updatedValues.join());
    }

    setState((prevState) => ({
      ...prevState,
      [name]: updatedValues,
    }));
  };

  const updateRole = (roleId, data) => {
    setState((previousState) => {
      const index = previousState.items.findIndex((role) => role.id === roleId);
      const newItems = [...previousState.items];
      newItems[index] = { ...previousState.items[index], ...data };
      return { ...previousState, items: newItems };
    });
  };

  const archiveRole = async (role) => {
    const roleStatuses = roleStatusFilter();

    if (role.status === statuses.archived.key) {
      role.status = statuses.active.key;
    } else {
      role.status = statuses.archived.key;
    }
    const data = { status: role.status };

    updateRole(role.id, data);

    try {
      const updatedRole = await axios.put(`/api/role/${role.id}/archive_role/`, data);
      if (updatedRole.data.status === statuses.active.key) {
        await axios.post(`/api/role/${role.id}/unarchive_schoolroles/`);
      }

      const statusFilterActive =
        state.job_status_list.length !== 0 && state.job_status_list.length !== roleStatuses.length;

      if (statusFilterActive) {
        setState((previousState) => ({
          ...previousState,
          page: 0,
        }));
      }
    } catch (error) {
      setShouldShow((previousState) => ({
        ...previousState,
        errorToast: true,
      }));
    }
  };

  const closeRole = (role) => {
    role.active_status = role.active_status === 1 ? 0 : 1;
    axios
      .patch(`/api/role/${role.id}/update_active_status/`, {
        active_status: role.active_status,
      })
      .catch(() => {
        toggleErrorToast(true);
      });

    const updatedRoles = state.items;
    updatedRoles.find((r) => r.id === role.id).active_status = role.active_status;
    // Reset roles so it trickles down to the row that was changed
    setState((previousState) => ({ ...previousState, roles: updatedRoles }));
  };

  return (
    <Container>
      <InternalJobsFilters
        categories={state.categories}
        categoryOptions={filterOptions.categories}
        clearAll={handleClearAll}
        district_users={state.district_users}
        districtUserOptions={filterOptions.districtUsers}
        grades={state.grades}
        hiring_seasons={state.hiring_seasons}
        hrbp_assignees={state.hrbp_assignees}
        hrbpAssigneeOptions={filterOptions.hrbpAssignees}
        hrbpFieldName={HRBP_ASSIGNEE_FIELD_NAME}
        internal_external={state.internal_external}
        job_owners={state.job_owners}
        job_status_list={state.job_status_list}
        jobOwnerOptions={filterOptions.jobOwners}
        locationOptions={filterOptions.schools}
        locations={state.schools}
        selectAll={handleSelectAll}
        setAllCategories={handleSetCategories}
        setAllGrades={handleSetGrades}
        setAllLocations={handleSetLocations}
        setCategories={handleSetCategories}
        setGrades={handleSetGrades}
        setLocations={handleSetLocations}
        updateMultiSelectFilter={updateMultiSelectFilter}
      />
      <ListContainer>
        <ItemsHeaderSection
          handleSetQuery={handleSetQuery}
          onSearch={onSearch}
          onToggle={toggleCurrentPage}
          resetItems={resetItems}
          totalCount={state.totalCount}
          value={state.query}
          variant={variant}
        />
        {shouldShowSpinner ? (
          <LoadingSpinner />
        ) : (
          <div>
            <ColumnCategories
              smallerLayout={smallerLayout}
              sortField={state.sortField}
              sortRolesBy={sortRolesBy}
              variant={variant}
            />
            <Rows
              archiveRole={archiveRole}
              closeRole={closeRole}
              fetchItemsDebounced={fetchItemsDebounced}
              handleOpenModalClick={toggleCreateModal}
              handleSetNetworkError={() => toggleErrorToast(true)}
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              items={state.items}
              numberOfItems={state.items?.length}
              openMergeRoleModal={openMergeRoleModal}
              openReturnSubmittedRoleModal={toggleReturnModal}
              openShareModal={openShareModal}
              setShouldShowDeleteModal={() => toggleDeleteModal(true)}
              setTemplateToDelete={setTemplateToDelete}
              smallerLayout={smallerLayout}
              toggleFeaturedRole={toggleFeaturedRole}
              variant={variant}
            />
          </div>
        )}
      </ListContainer>
      <SuccessSnackbar
        message="Successfully created template"
        onClose={() => toggleCreateSuccessToast(false)}
        open={shouldShow.createSuccessToast}
      />
      <SuccessSnackbar
        message="Successfully deleted template"
        onClose={() => toggleDeleteSuccessToast(false)}
        open={shouldShow.deleteSuccessToast}
      />
      <ErrorSnackbar
        message="Something went wrong"
        onClose={() => toggleErrorToast(false)}
        open={shouldShow.errorToast}
      />
      {shouldShow.createModal && (
        <CreateJobOrTemplateModal
          handleModalClose={() => toggleCreateModal(null, false)}
          handleSetNetworkErrorAlert={() => toggleErrorToast(true)}
          handleSetWasSuccessful={() => toggleCreateSuccessToast(true)}
          selectedItemToDuplicate={selectedItem}
          shouldShowModal={shouldShow.createModal}
          variant={variant === 'templates' ? 'job' : 'template'}
        />
      )}
      {shouldShow.deleteModal && (
        <DeleteTemplateModal
          handleCloseDeleteModal={() => toggleDeleteModal(false)}
          handleDeleteTemplate={handleDeleteTemplate}
          isOpen={shouldShow.deleteModal}
          templateId={templateToDelete.id}
        />
      )}
      {shouldShow.mergeModal && (
        <MergeRoleModal
          onHide={() => setShouldShow((previousState) => ({ ...previousState, mergeModal: false }))}
          shouldShow={shouldShow.mergeModal}
          roleBeingMerged={roleBeingMerged}
        />
      )}
      {shouldShow.returnModal && (
        <ReturnSubmittedRoleModal
          isOpen={shouldShow.returnModal}
          onClose={() => toggleReturnModal(null, false)}
          returnSubmittedRole={returnSubmittedRole}
          fetchItems={fetchItems}
        />
      )}
      {shouldShow.shareModal && (
        <ShareToSocialMedia
          isOpen={shouldShow.shareModal}
          onClose={() => {
            // Because we redirect the user with a query param, after we prompt the first time,
            // remove the flag from the url so we don't nag the user several times.
            const params = new URLSearchParams(window.location.search);
            if (params.has('j')) {
              params.delete('j');
            }
            window.history.replaceState({}, document.title, '?' + params.toString());

            setShouldShow((previousState) => ({ ...previousState, shareModal: false }));
          }}
          roleToShare={roleToShare}
          startPane={'EDIT'}
        />
      )}
    </Container>
  );
}
