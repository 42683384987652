import { Component } from 'react';
import PropTypes from 'prop-types';
import { handleOutsideClickOnValidForm } from '../../utils/util';
import moment from 'moment';

export default class InterviewDateDropdown extends Component {
  static propTypes = {
    candidate: PropTypes.object.isRequired,
    updateRecipientField: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  outsideClickListener = (ev) => handleOutsideClickOnValidForm(this.node, ev, this.toggleOptions);

  toggleOptions = () => {
    if (!this.state.showOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, false);
    }
    this.setState({ showOptions: !this.state.showOptions });
  };

  updateField = (e, readableName = null) => {
    /** Created a custom dropdown below in place of an html select element. This is because
     * we want the user to be able to select from interview times, but also type in a custom time
     * if they want. There's an existing html element for this called a datalist, but it doesn't
     * have much cross browser support at this time (9/21/18).
     * Mimic event object that would come from a select's onChange. This way it works with
     * this.props.updateRecipientField, which uses e.target.name and e.target.value.
     */
    if (readableName) {
      // in this case, a value was selected from the custom dropdown, so have to manually
      // set name and value
      e.target.name = 'interviewTime';
      e.target.value = readableName;
    }
    this.props.updateRecipientField(e, this.props.candidate.id);
  };

  formatDate = (date) => {
    return moment.utc(date).format('dddd, MMMM Do YYYY, h:mm a');
  };

  render() {
    let { candidate } = this.props;
    let { applications, schoolapplications } = candidate;
    let allApplications = applications.concat(schoolapplications);

    return (
      <div className="row-item interview-datetime">
        <input
          name="interviewTime"
          className={candidate.interviewTime ? '' : 'missing-value'}
          value={candidate.interviewTime || ''}
          onChange={this.updateField}
          onClick={this.toggleOptions}
          ref={(node) => (this.node = node)}
        />
        <div className={`selection-list ${this.state.showOptions ? 'show' : ''}`}>
          {allApplications.map((application, i) => {
            if (application.interview) {
              let readableName = this.formatDate(application.interview.when);
              return (
                <div
                  key={i}
                  className="list-option"
                  onClick={(e) => this.updateField(e, readableName)}
                >
                  {readableName}
                </div>
              );
            } else {
              // eslint-disable-next-line react/jsx-key
              return <div />;
            }
          })}
        </div>
      </div>
    );
  }
}
