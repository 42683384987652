import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import paperAirplane from '../../../assets/icon-paper-airplane-green.svg';

export default class SuccessModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="success-modal">
        <Modal.Header>
          <span style={{ float: 'right', cursor: 'pointer' }} onClick={this.props.onHide}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="bulk-email-success-div">
            <img className="bulk-email-success-img" src={paperAirplane} alt="" />
            <p className="center">{this.props.message}</p>
            <button className="modal-button save" onClick={this.props.onHide}>
              {this.props.buttonLabel}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
