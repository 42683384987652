import axios from 'axios';

export async function getOrSetPreferenceFlag() {
  if (localStorage.getItem('COMBINED_PREFERENCE_PROFILE_FLAG') === null) {
    const r = await axios.get('/api/config/');
    if (r.data.COMBINED_PREFERENCE_PROFILE_FLAG) {
      window.localStorage.setItem(
        'COMBINED_PREFERENCE_PROFILE_FLAG',
        r.data.COMBINED_PREFERENCE_PROFILE_FLAG
      );
      return Boolean(parseInt(r.data.COMBINED_PREFERENCE_PROFILE_FLAG));
    }
  } else {
    return Boolean(parseInt(localStorage.getItem('COMBINED_PREFERENCE_PROFILE_FLAG')));
  }
}
