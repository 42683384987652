import PropTypes from 'prop-types';

import auth from '../../../utils/auth';
import CandidatesListFilterDrop from 'ui-kit/ListFilterDropdown';
import { prospectStatus } from 'utils/prospectenums';
import { validateTagUserType } from '../../CustomTag/util';

function ProspectFilters({
  updateMultiSelectFilter,
  prospect_status_list,
  candidate_source_list,
  tag_ids,
  tags,
  all_sources = [],
  selectAll,
  clearAll,
}) {
  // CandidatesListFilterDrop expects this format: { value: 3, label: 'Good prospect' }
  const getProspectTagOptions = () => tags.map(({ id, label }) => ({ value: id, label: label }));

  // verify that current user can view at least one district tag
  const userCanViewTagSection = (tags) =>
    tags.some((tag) => validateTagUserType(auth.getUser(), tag));
  return (
    <div className="candidateslist-filters">
      <CandidatesListFilterDrop
        updateMultiSelectFilter={updateMultiSelectFilter}
        displayName="Prospect Status"
        fieldName={'prospect_status_list'}
        values={prospect_status_list}
        options={prospectStatus()}
        selectAll={selectAll}
        clearAll={clearAll}
      />
      <CandidatesListFilterDrop
        updateMultiSelectFilter={updateMultiSelectFilter}
        displayName={'Source'}
        fieldName={'candidate_source_list'}
        values={candidate_source_list}
        options={all_sources}
        selectAll={selectAll}
        clearAll={clearAll}
      />
      {tags?.length > 0 && userCanViewTagSection(tags) && (
        <CandidatesListFilterDrop
          updateMultiSelectFilter={updateMultiSelectFilter}
          displayName={'Prospect Tags'}
          fieldName={'tag_ids'}
          values={tag_ids}
          options={getProspectTagOptions()}
          selectAll={selectAll}
          clearAll={clearAll}
        />
      )}
    </div>
  );
}

ProspectFilters.propTypes = {
  updateMultiSelectFilter: PropTypes.func.isRequired,
  prospect_status_list: PropTypes.arrayOf(PropTypes.number).isRequired,
  candidate_source_list: PropTypes.arrayOf(PropTypes.string).isRequired,
  tag_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default ProspectFilters;
