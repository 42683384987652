import { Box } from '@mui/material';
import styled from 'styled-components';

import { TemplateRowContainerProps } from './types';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  padding: 20px 0 0;

  @media screen and (min-width: 768px) {
    padding-left: 45px;
    padding-right: 45px;
    position: fixed;
    top: 70px;
    left: 250px;
    max-width: calc(100vw - 250px);
    height: calc(100vh - 70px);
  }
`;

interface TemplatesListHeaderProps {
  smallerLayout?: boolean;
}

export const TemplatesListHeader = styled.div<TemplatesListHeaderProps>`
  display: grid;
  grid-template-columns: 16px 2fr 1fr;
  grid-gap: 0.5rem;
  align-items: center;

  font-size: 13px;
  color: #777;

  padding-left: 1rem;
  padding-bottom: 8px;
  margin-top: 1.5rem;
  border-bottom: 1px solid #dfdfdf;
  @media screen and (min-width: 990px) {
    // don't show featured icon or hover icons in smallerLayout (school admins / district users)
    grid-template-columns: ${({ smallerLayout }) =>
      smallerLayout
        ? `
      minmax(170px, 275px) 130px minmax(100px, 135px) 100px
    `
        : `
      0px minmax(170px, 300px) 150px minmax(100px, 150px) 100px  minmax(100px, 1fr)
    `};
  }
`;

TemplatesListHeader.displayName = 'TemplatesListHeader';

export const RowSection = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

export const RowSectionClickable = styled(RowSection)<{ isActive?: boolean }>`
  cursor: pointer;

  ${({ isActive }) => (isActive ? 'color: rgb(0, 128, 96)' : '')}
`;

RowSectionClickable.displayName = 'internal-jobslist-header';

export const ScrollWindow = styled.div`
  height: calc(100vh - 280px);
  padding-bottom: 50px;
`;

ListContainer.displayName = 'list-container';

export const HideOnMobile = styled.div`
  display: none;

  @media screen and (min-width: 990px) {
    display: block;
  }
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: #444444;
  font-size: 15px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: flex-end;
  column-gap: 36px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ActionItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const SpacerContainer = styled.div`
  padding-bottom: 2px;
`;

SpacerContainer.displayName = 'IconContainer';

export const HoverIconSet = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;

  > div {
    display: flex;
    align-items: center;
    width: 35px;
    height: 35px;
    justify-content: center;
    margin-right: 0;
    border-radius: 50%;
    box-shadow: none;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #ededed;
    }
  }
`;

HoverIconSet.displayName = 'HoverIconSet';

export const TextEllipsisContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: fit-content;
`;

TextEllipsisContainer.displayName = 'TextEllipsisContainer';

export const TemplateRowContainer = styled.div<TemplateRowContainerProps>`
  display: grid;
  grid-template-columns: 16px 2fr 1fr;
  grid-gap: 0.5rem;
  align-items: center;

  margin: 0 auto;
  padding-left: 1rem;
  font-size: 13px;
  color: #777;
  height: 50px;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  cursor: pointer;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);

    ${HoverIconSet} {
      transition: opacity 0.5s ease;
      opacity: 1;
      height: auto;
    }

    div:last-child > .template-quick-actions {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }

  div:last-child > .template-quick-actions {
    opacity: 0;
  }

  @media screen and (min-width: 990px) {
    // don't show featured icon or hover icons in smallerLayout (school admins / district users)
    grid-template-columns: ${({ smallerLayout }) =>
      smallerLayout
        ? `
        minmax(170px, 275px) 130px minmax(100px, 135px) 100px
        `
        : `
        0px minmax(170px, 300px) 150px minmax(100px, 150px) 100px  minmax(100px, 1fr)    `};
  }
`;

interface JobsListHeaderProps {
  smallerLayout?: boolean;
}

export const JobsListHeader = styled.div<JobsListHeaderProps>`
  display: grid;
  grid-template-columns: 50px 2fr 1fr;
  grid-gap: 0.5rem;
  align-items: center;

  font-size: 13px;
  color: #777;

  padding-left: 1rem;
  padding-bottom: 8px;
  margin-top: 1.5rem;
  border-bottom: 1px solid #dfdfdf;

  @media screen and (min-width: 990px) {
    // don't show featured icon or hover icons in smallerLayout (school admins / district users)
    grid-template-columns: ${({ smallerLayout }) =>
      smallerLayout
        ? `
      minmax(170px, 275px) 130px minmax(100px, 135px) 100px 100px
    `
        : `
      16px minmax(170px, 275px) 130px minmax(100px, 135px) 100px 100px minmax(176px, 1fr)
    `};
  }
`;

JobsListHeader.displayName = 'JobsListHeader';

export const ActiveSwitch = styled.button({
  padding: '12px',
  borderTopRightRadius: '4px',
  borderWidth: '1px',
  borderBottomRightRadius: '4px',
  backgroundColor: '#00654E',
  color: '#FFFFFF',
  borderColor: '#004f3c',
  borderStyle: 'solid',
});

export const InactiveSwitch = styled.button({
  padding: '12px',
  border: 'solid',
  borderWidth: '1px',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  borderColor: '#DDDDDD',
});

export const TooltipText = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 4px',
});
