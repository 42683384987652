import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'assets/tooltip.svg';
import districtsAPI from 'api/districtsAPI';
import MultiSelectDropdown from './MultiSelectDropdown';
import JobEditGrades from '../../../components/JobEdit/jobeditgrades';
import Header from './Header';
import Footer from './Footer';
import ThreeButtonSelect from '../../../components/common/ThreeButtonSelect';
import {
  employmentTypePreferences,
  preferenceCategories,
  preferenceOptions,
  USStates,
} from '../../../utils/enums';
import LocationPreferences from '../../../components/LocationPreferences';
import locationPreferencesAPI from '../../../api/locationPreferencesAPI';
import { Preference } from 'types/types';
import { MOBILE_BREAKPOINT } from '../defaults';
import { ATSCandidatePreferencesDataTestIds } from '../../../data-testids/ATS';

const preferenceMap = preferenceOptions().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export interface EmploymentTypeMap {
  [key: string]: number;
}

const employmentTypeMap: EmploymentTypeMap = employmentTypePreferences().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

const statesFormatted = [];
USStates().map((s) => statesFormatted.push({ value: s.value, label: s.title }));

export function PreferencesV2({
  setPreference,
  preference,
  onSubmit,
  combinedPreferenceAndProfile,
  handleCheckboxChange,
  showMap,
  setShowMap,
  showMapInputError,
  setShowMapInputError,
  showMapSelectError,
  setShowMapSelectError,
  mapUrl,
  setMapUrl,
}: {
  setPreference: (arg0: Preference) => void;
  preference: Preference;
  onSubmit: () => void;
  combinedPreferenceAndProfile?: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showMap: boolean;
  setShowMap: (arg0: boolean) => void;
  showMapInputError: boolean;
  setShowMapInputError: (arg0: boolean) => void;
  showMapSelectError: boolean;
  setShowMapSelectError: (arg0: boolean) => void;
  mapUrl: string;
  setMapUrl: (arg0: string) => void;
}): React.ReactElement {
  const [districtOptions, setDistrictOptions] = useState([]);
  const [errorState, setErrorState] = useState({
    categories: false,
    states: false,
    location: false,
  });
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    fetchIndianaDistricts();
    document.body.classList.add('preferences');
  }, []);

  const fetchIndianaDistricts = async () => {
    const response = await districtsAPI.fetchDistrictsByState('IN');
    setDistrictOptions(response.map((d) => ({ value: d.id, label: d.name })));
  };

  const checkRequiredSections = () => {
    const allSectionsFilled =
      preference.states.length > 0 &&
      preference.categories?.length > 0 &&
      preference.location &&
      preference.location !== '' &&
      preference.miles_within &&
      preference.miles_within &&
      !showMapInputError &&
      !showMapSelectError;

    const errorStateCopy = { categories: false, states: false, location: false };
    if (!preference.states || preference.states.length === 0) {
      errorStateCopy.states = true;
    }
    if (!preference.categories || preference.categories.length === 0) {
      errorStateCopy.categories = true;
    }
    if (
      !preference.location ||
      preference.location === '' ||
      !preference.miles_within ||
      preference.miles_within === ''
    ) {
      errorStateCopy.location = true;
    }
    if (preference.location === '') {
      setShowMapInputError(true);
    }
    if (preference.miles_within === '') {
      setShowMapSelectError(true);
    }
    setErrorState(errorStateCopy);
    return allSectionsFilled;
  };

  const handleSubmit = () => {
    const requiredSectionsComplete = checkRequiredSections();
    if (requiredSectionsComplete) {
      onSubmit();
    }
  };
  const handleChange = (e) => {
    const preferenceCopy = { ...preference };
    preferenceCopy[e.target.name] = e.target.value;
    if (e.target.name === 'employment_type' && e.target.value === employmentTypeMap.full_time) {
      preferenceCopy.hours_per_week = 'N/A';
    }
    if (e.target.name === 'miles_within') {
      if (e.target.value !== '') {
        setShowMapSelectError(false);
      } else {
        setShowMapSelectError(true);
      }
    }
    if (e.target.name === 'location') {
      if (e.target.value !== '') {
        setShowMapInputError(false);
      } else {
        setShowMapInputError(true);
      }
    }
    setPreference(preferenceCopy);
    setIsDirty(true);
  };

  const updateMultiSelect = (values, fieldName) => {
    const preferenceCopy = { ...preference };
    preferenceCopy[fieldName] = values.map((value) => value.value);
    setPreference(preferenceCopy);
  };

  const handleAddressSearch = async (event, address, radius) => {
    event.preventDefault();
    const data = {
      address: address,
      radius: radius,
    };

    if (isDirty) {
      fetchMapUrl(data, false);
      setIsDirty(false);
    }
  };

  const handleAddressSearchOnEnter = async (event, address, radius) => {
    if (event.key === 'Enter') {
      handleAddressSearch(event, address, radius);
    }
  };

  const fetchMapUrl = async (data, initial) => {
    const response = await locationPreferencesAPI.getMapUrl(data);
    if (response[0] === 200) {
      const showMapCopy = response[1]?.map_url ? true : false;
      setShowMapInputError(false);
      setShowMapSelectError(false);
      setMapUrl(response[1].map_url);
      setShowMap(showMapCopy);
    } else {
      const showErrorInput =
        response[1].error === 'invalid address' || response[1].error === 'address is required'
          ? true
          : false;
      const showErrorSelect = data.radius === '' ? true : false;
      if (!initial) {
        setShowMapInputError(showErrorInput);
        setShowMapSelectError(showErrorSelect);
        setShowMap(false);
        setMapUrl('');
      }
    }
  };

  return (
    <div>
      <Header step={1} />
      <div className="preferences-container" style={{ paddingTop: '21px' }}>
        <SectionWithGap className="section">
          <Option>
            <label className="container">
              <input
                type="checkbox"
                checked={preference.open_to_opportunities ? true : false}
                onChange={() =>
                  setPreference({
                    ...preference,
                    open_to_opportunities: !preference.open_to_opportunities,
                  })
                }
              />
              <span className="checkmark"></span>
              <TextAndTooltip>
                <Label>Open to work?</Label>
                <img
                  className="mbhalf"
                  src={Tooltip}
                  alt="tooltip"
                  data-tip={`
                 When checked, your profile<br />
                will be shown to recruiters
                `}
                  data-for="open-to-work"
                />
                <ReactTooltip id="open-to-work" effect="solid" multiline />
              </TextAndTooltip>
            </label>
          </Option>
        </SectionWithGap>
        <LocationPreferences
          {...{
            milesWithin: preference.miles_within,
            location: preference.location,
            checked: preference.visible_outside_radius,
            handleChange: handleChange,
            handleChangeSelect: handleChange,
            showMap: showMap,
            showMapErrorInput: showMapInputError,
            showMapErrorSelect: showMapSelectError,
            mapUrl: mapUrl,
            combinedPreferenceAndProfile: combinedPreferenceAndProfile,
            hadleSearchButton: handleAddressSearch,
            handleAddressSearchOnEnter: handleAddressSearchOnEnter,
            handleCheckboxChange: handleCheckboxChange,
          }}
        />
        <SectionWithGap className="section">
          <SelectContainer>
            <LabelContainer>
              <FlexContainer>
                State Preferences
                <RequiredAsterisk>*</RequiredAsterisk>
              </FlexContainer>
            </LabelContainer>
            <StyledDropdown>
              <MultiSelectDropdown
                values={preference.states || []}
                setValues={(values) =>
                  setPreference({
                    ...preference,
                    states: values,
                    districts_excluded: values.includes(14) ? preference.districts_excluded : [],
                  })
                }
                options={statesFormatted}
                displayName={'State(s)'}
                optionNamePlural="states"
                showSearchInput
                showSelectAllButtons
                dataTestId={ATSCandidatePreferencesDataTestIds.STATE_PREFERENCES_MULTISELECT}
              />
            </StyledDropdown>
          </SelectContainer>
          {errorState.states && <ErrorMessage>Please select a state</ErrorMessage>}
          {/* IN state id = 14. The second dropdown only shows in this instance */}
          {districtOptions.length > 0 && preference.states?.includes(14) && (
            <StyledSelectContainer>
              <LabelContainer>
                {' '}
                Hide me from the following districts
                <TooltipContainer>
                  <StyledTooltip
                    className="mbhalf"
                    src={Tooltip}
                    alt="tooltip"
                    data-tip={`
                 This will allow your profile<br />
                to be hidden from your <br />
                current employer
                `}
                    data-for="districts_excluded"
                  />
                  <ReactTooltip id="districts_excluded" effect="solid" multiline />
                </TooltipContainer>
              </LabelContainer>
              <StyledDropdown>
                <MultiSelectDropdown
                  values={preference.districts_excluded || []}
                  setValues={(values) =>
                    setPreference({ ...preference, districts_excluded: values })
                  }
                  options={districtOptions}
                  displayName={'District(s)'}
                  optionNamePlural="districts"
                  showSearchInput
                  showSelectAllButtons
                />
              </StyledDropdown>
            </StyledSelectContainer>
          )}
        </SectionWithGap>
        <div className="section">
          <div className="three-button">
            <p className="flex-50">
              <b>Do you prefer to work in a multilingual school?</b>
            </p>
            <ThreeButtonSelect
              object={preference}
              fieldName={'multilingual_school'}
              enumObject={preferenceMap}
              handleChange={handleChange}
              firstButton={{
                label: 'No Preference',
                enumField: 'no_preference',
              }}
              secondButton={{
                label: 'Yes',
                enumField: 'yes',
              }}
              thirdButton={{
                label: 'No',
                enumField: 'no',
              }}
            />
          </div>
          <div className="three-button">
            <p className="flex-50">
              <b>Do you prefer to work in a title 1 school?</b>
            </p>
            <ThreeButtonSelect
              object={preference}
              fieldName={'title_1_school'}
              enumObject={preferenceMap}
              handleChange={handleChange}
              firstButton={{
                label: 'No Preference',
                enumField: 'no_preference',
              }}
              secondButton={{
                label: 'Yes',
                enumField: 'yes',
              }}
              thirdButton={{
                label: 'No',
                enumField: 'no',
              }}
            />
          </div>
          <div className="three-button">
            <p className="flex-50">
              <b>Do you prefer to work in a turnaround school?</b>
            </p>
            <ThreeButtonSelect
              object={preference}
              fieldName={'turnaround_school'}
              enumObject={preferenceMap}
              handleChange={handleChange}
              firstButton={{
                label: 'No Preference',
                enumField: 'no_preference',
              }}
              secondButton={{
                label: 'Yes',
                enumField: 'yes',
              }}
              thirdButton={{
                label: 'No',
                enumField: 'no',
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="three-button">
            <p className="flex-50">
              <b>Do you prefer full-time or part-time positions?</b>
            </p>
            <ThreeButtonSelect
              object={preference}
              fieldName={'employment_type'}
              enumObject={employmentTypeMap}
              handleChange={handleChange}
              firstButton={{
                label: 'No Preference',
                enumField: 'no_preference',
              }}
              secondButton={{
                label: 'Full Time',
                enumField: 'full_time',
              }}
              thirdButton={{
                label: 'Part Time',
                enumField: 'part_time',
              }}
            />
          </div>
          <div className="three-button hours-per-week">
            <p className="flex-50">
              <b>How many hours per week?</b>
            </p>
            <div className="select-form-field">
              <select
                className="pointer"
                name="hours_per_week"
                value={preference.hours_per_week}
                onChange={handleChange}
                // can't select hours if employment type is "full-time"
                disabled={preference.employment_type === employmentTypeMap.full_time}
              >
                <option value="">Select</option>
                {['0-10', '10-15', '15-25', '25-30', '30-35', 'N/A'].map((i, key) => (
                  <option value={i} key={key}>
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="section">
          <FlexContainer>
            <h4 className="mb2">Categories of Interest</h4>
            <RequiredAsterisk>*</RequiredAsterisk>
          </FlexContainer>
          <MultiSelectDropdown
            values={preference.categories || []}
            setValues={(values) => setPreference({ ...preference, categories: values })}
            options={preferenceCategories()}
            displayName={'Categories'}
            optionNamePlural="categories"
            showSearchInput
            showSelectAllButtons
            dataTestId={ATSCandidatePreferencesDataTestIds.CATEGORIES_OF_INTEREST_MULTISELECT}
          />
          {errorState.categories && (
            <ErrorMessage style={{ marginTop: '12px' }}>
              Please select a category of interest
            </ErrorMessage>
          )}
        </div>
        <div className="section">
          <FlexContainer>
            <h4 className="mb2">Grade Levels of Interest</h4>
          </FlexContainer>
          <JobEditGrades
            grades={preference.grades || []}
            onChange={(values) => updateMultiSelect(values, 'grades')}
          />
        </div>
        <Footer onSubmit={handleSubmit} saveText={'Save & Next Step'} />
      </div>
    </div>
  );
}

const SectionWithGap = styled.div`
  margin-bottom: 20px;
  border-radius: 0 0 3px 3px;
  border-bottom: 1px solid #e6e6e6 !important;
`;

const Option = styled.div`
  cursor: pointer;
  font-size: 15px;

  .container {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;

    &.option-disabled {
      color: $light-grey;

      &:hover input ~ .checkmark {
        background-color: $green;
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: white;
      border: 1px solid #e5e5e5;

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #e5e5e5;
    }

    /* When the checkbox is checked, add a green background */
    input:checked ~ .checkmark {
      background-color: #00b88d;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
  }
`;

const TextAndTooltip = styled.div`
  display: flex;
  align-content: flex-start;
  font-weight: normal !important;
`;

const Label = styled.div`
  margin-right: 6px;
  font-weight: 700;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const StyledSelectContainer = styled(SelectContainer)`
  margin-top: 12px;
`;
const StyledDropdown = styled.div`
  width: 400px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 12px;
    width: 100%;
  }
`;

const StyledTooltip = styled.img`
  margin-left: 6px;
`;

const LabelContainer = styled.div`
  display: flex;
  width: 300px;
  font-weight: 700;
`;

const RequiredAsterisk = styled.div`
  color: #cc0000;
  margin-left: 3px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const TooltipContainer = styled.div`
  font-weight: normal;
`;

const ErrorMessage = styled.div`
  color: #e64c66;
  font-size: 0.85em;
  height: 1.5em;
`;
