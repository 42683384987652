import { Link, useLocation } from 'react-router-dom';

import auth from '../../../utils/auth';
import { BaseJobAlertsBannerProps } from './types';
import BellIcon from 'assets/icons/icon_bell.svg';
import { JobAlertsBanner, JobAlertsBannerText, NotificationIcon } from './JobAlertsBanner.styles';

export const BaseJobAlertsBanner: React.FC<BaseJobAlertsBannerProps> = ({ onClick, text }) => {
  return (
    <JobAlertsBanner tabIndex={0} onClick={onClick} data-testid="job-banner">
      <JobAlertsBannerText>
        <NotificationIcon src={BellIcon} alt="subscription_notification_manage" />
        {text}
      </JobAlertsBannerText>
    </JobAlertsBanner>
  );
};

export const MarketplaceJobAlertsBanner: React.FC<BaseJobAlertsBannerProps> = ({
  onClick,
  text,
  hasAlerts,
}) => {
  const location = useLocation();
  const pathToReturnTo = `${location.pathname}?openModalType=add`;
  const isLoggedIn = auth.loggedIn();

  if (isLoggedIn) {
    return <BaseJobAlertsBanner onClick={onClick} text={text} />;
  } else {
    return (
      <Link
        to={{
          pathname: '/login',
          state: {
            previousLocation: pathToReturnTo,
          },
        }}
      >
        <BaseJobAlertsBanner onClick={onClick} text={text} hasAlerts={hasAlerts} />
      </Link>
    );
  }
};
