import { Component } from 'react';

import PropTypes from 'prop-types';
import SubcategoriesRow from './SubcategoriesRow';
import AddSubcategoryForm from './AddSubcategoryForm';
import auth from '../../utils/auth';

export default class SubcategoriesList extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    addCategory: PropTypes.func.isRequired,
    editCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div
        className={`subcategories-component-container-div ${this.props.expanded ? 'expanded' : ''}`}
      >
        <div className="subcategory-list-container-div">
          {this.props.category.subcategories.map((subcategory, index) => {
            // add knowledge of parent category's id for updating purposes later
            subcategory.categoryId = this.props.category.id;
            return (
              <SubcategoriesRow
                key={index}
                subcategory={subcategory}
                subcategoryCount={this.props.category.subcategories.length}
                editCategory={this.props.editCategory}
                deleteCategory={this.props.deleteCategory}
              />
            );
          })}
        </div>
        {/* Remove edit/delete if district belongs to Indiana (IN) */}
        {!auth.getUser().profile.district.indiana_district && (
          <AddSubcategoryForm addCategory={this.props.addCategory} category={this.props.category} />
        )}
      </div>
    );
  }
}
