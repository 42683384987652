import { BaseNimbleAPI } from './baseAPI';

class NotificationsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/notifications' };
  }

  async fetchNotifications() {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  async postNotification(notification) {
    const url = '/';
    const response = await this.post(url, notification);
    return response.data;
  }

  async putNotification(notification) {
    const url = `/${notification.id}/`;
    const response = await this.put(url, notification);
    return response.data;
  }

  async deleteNotification(notification) {
    const url = `/${notification.id}/`;
    const response = await this.delete(url);
    return response.data;
  }
}

export default new NotificationsAPI();
