import styled from 'styled-components';

export default function SelectedFormTag({ title }) {
  return <Container>{title}</Container>;
}

const Container = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #777777;

  border: 1px solid #777777;
  border-radius: 3px;

  height: 32px;
  margin-right: 14px;
  margin-top: 12px;
  padding: 0 1rem;
  width: fit-content;
`;
