import { Component } from 'react';

import PropTypes from 'prop-types';
import CategoriesRowMain from './CategoriesRowMain';
import SubcategoriesList from './SubcategoriesList';

export default class CategoriesRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  static propTypes = {
    category: PropTypes.object.isRequired,
    categoryCount: PropTypes.number.isRequired,
    addCategory: PropTypes.func.isRequired,
    editCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <div>
        <CategoriesRowMain
          category={this.props.category}
          categoryCount={this.props.categoryCount}
          expanded={this.state.expanded}
          toggleExpand={this.toggleExpand}
          editCategory={this.props.editCategory}
          deleteCategory={this.props.deleteCategory}
        />
        <SubcategoriesList
          category={this.props.category}
          expanded={this.state.expanded}
          addCategory={this.props.addCategory}
          editCategory={this.props.editCategory}
          deleteCategory={this.props.deleteCategory}
        />
      </div>
    );
  }
}
