import { USStates } from 'utils/enums';

export const STATE_DROPDOWN_OPTIONS = USStates().map((state) => {
  return {
    label: state.title,
    value: state.id,
  };
});

export const CONNECT_JOBBOARD_STATE_IDS = {
  MISSOURI: 25,
  TENNESSEE: 42,
};
