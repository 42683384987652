import { useState } from 'react';
import styled from 'styled-components';

import { DropdownWithInputFilter } from 'ui-kit';
import { ReactComponent as WhiteCheckmark } from 'assets/icons/icon-white-checkmark.svg';

// FIXME: Hide create questionset button until it has functionality
// import { ReactComponent as WhitePlusIcon } from 'ui-kit/icons/svg/white-plus-icon.svg';
// import auth from '../../utils/auth';

export default function AddQuestionSet({
  selectedQuestionSet,
  questionSets,
  handleQuestionSetSelection,
  addQuestionSet,
  jobQuestionsAndAttachmentsAndSets,
}) {
  const [success, setSuccess] = useState(false);

  const questionSetIsAdded = () => {
    return jobQuestionsAndAttachmentsAndSets?.find(
      (a) => a.uuid === selectedQuestionSet.uuid && !a.is_role_qs
    );
  };
  return (
    <>
      <HeaderText>Add Question Set</HeaderText>
      <AddQuestionContainer>
        <DropdownWithInputFilter
          options={questionSets.map((questionSet) => ({
            label: questionSet.title,
            value: questionSet.uuid,
          }))}
          value={selectedQuestionSet?.uuid || ''}
          handleChange={handleQuestionSetSelection}
          placeholder="Click or start typing to select a question set to add"
          boxShadow={false}
          onClear={() => handleQuestionSetSelection({})}
        />
        <AddQuestionSetButton
          onClick={() => {
            addQuestionSet();
            setSuccess(true);
            setTimeout(() => setSuccess(false), 3000);
          }}
          disabled={success || !selectedQuestionSet?.uuid || questionSetIsAdded()}
          isSuccess={success}
        >
          {success ? (
            <>
              <WhiteCheckmark width="19px" height="14px" />
              <AddQuestionButtonSuccessText>&nbsp;&nbsp;Added!</AddQuestionButtonSuccessText>
            </>
          ) : (
            'Add question set'
          )}
        </AddQuestionSetButton>
        {/* FIXME: Hide create questionset button until it has functionality */}
        {/* {auth.isSuperAdminUser() && (
          <CreateQuestionSetButton>
            <WhitePlusIcon width="12px" height="12px" /> Create question set
          </CreateQuestionSetButton>
        )} */}
      </AddQuestionContainer>
    </>
  );
}

const HeaderText = styled.p`
  margin: 0rem 0 0.8rem;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
`;

const AddQuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 174px 200px;
  grid-gap: 1rem;
  justify-content: space-between;
`;

const AddQuestionSetButton = styled.button`
  font-size: 14px;
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')};
  color: ${(props) => (props.disabled ? '#CACACA' : 'white')};

  background-color: ${(props) => (props.isSuccess && '#00B88D') || (!props.disabled && '#00B88D')};
  border: 1px solid ${(props) => (props.disabled ? '#E6E6E6' : '#828282')};
  border-radius: 2px;

  width: 174px;
  height: 48px !important;

  padding 8px 24px;

  justify-self: end;
`;

const AddQuestionButtonSuccessText = styled.span`
  color: white;
`;

/* FIXME: Hide create questionset button until it has functionality */
// const CreateQuestionSetButton = styled.button`
//   font-size: 14px;
//   color: white;

//   background-color: #00B88D;
//   border: none;
//   border-radius: 4px;

//   width: 200px;
//   height: 48px !important;

//   padding 8px 24px;

//   justify-self: end;
// `;
