import { RefObject, useEffect, useRef } from 'react';

const useClickOutside = <T extends HTMLElement>(
  callback: (element: Node) => void
): RefObject<T> => {
  const ref = useRef<T>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const { target } = event;
      const currentElement = ref.current;

      if (currentElement && target instanceof Node && !currentElement.contains(target)) {
        callbackRef.current(target);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return ref;
};

export default useClickOutside;
