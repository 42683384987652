import { Chip } from 'sharedComponents/Chip';
import { ConnectBadgeLabels } from 'features/Connect/utils/connectEnums';

export const OnPlatformBadge: React.FC = () => {
  return (
    <Chip
      label={`● ${ConnectBadgeLabels.ONPLATFORM}`}
      variant="filled"
      sx={{
        color: '#067647',
        marginLeft: '8px',
        border: '1px solid #ABEFC6',
        background: '#ECFDF3',
      }}
    />
  );
};
