import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import ApplicantCharacteristics from 'features/StateDataDashboards/ApplicantCharacteristicsDashboard';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import { dashboardsUserTypes } from '../utils/userType';
import SummaryDashboard from '../features/StateDataDashboards/SummaryDashboard';
import SupplyAndDemandDashboard from '../features/StateDataDashboards/SupplyAndDemandDashboard';
import UserAuthorized from '../components/UserAuthorized';
import VacancyCharacteristics from '../features/StateDataDashboards/VacancyCharacteristicsDashboard';

const Dashboards: React.FC = () => {
  const routeMatch = useRouteMatch('/dashboards');

  if (!routeMatch) return null;

  return (
    <UserAuthorized allow={dashboardsUserTypes}>
      <Switch>
        <AuthenticatedRoute path="/dashboards/state/summary/in">
          <SummaryDashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/dashboards/state/applicantcharacteristics/in">
          <ApplicantCharacteristics />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/dashboards/state/vacancycharacteristics/in">
          <VacancyCharacteristics />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/dashboards/state/supplyanddemand/in">
          <SupplyAndDemandDashboard />
        </AuthenticatedRoute>
        <Redirect from="/dashboards/vacancy" to="/data/vacancy" />
        <Redirect from="/dashboards/pipeline" to="/data/pipeline" />
      </Switch>
    </UserAuthorized>
  );
};

export default Dashboards;
