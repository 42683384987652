import { useEffect, useState } from 'react';

import { Box, Button, IconButton, Stack } from '@mui/material';

import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { Alert } from 'sharedComponents/Alert';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { Input } from 'sharedComponents/Input';
import { BlankModal } from 'sharedComponents/Modal';

import { BasicInfoSection } from 'features/Connect/components/SchoolCard/SchoolCardBasicInfoSection';
import expressInterestBackground from '../../../../assets/express-interest-modal-background.svg';

import { ExpressInterestModalProps } from 'types/connectTypes';
import ExpressInterestAPI from '../../api/expressInterestAPI';
import { CONNECT_ACTION_LABEL } from '../JobDiscovery/constants';

export const ExpressInterestModal: React.FC<ExpressInterestModalProps> = ({
  school,
  expressInterestModalIsOpen,
  actions,
  updateContactedSchools,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSkipping, setIsSkipping] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>(null);

  const isMobile = useMediaQuery(
    (theme: Theme) =>
      theme.breakpoints.down('md') || theme.breakpoints.down('sm') || theme.breakpoints.down('xs')
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null);

    setMessage(event.target.value);
  };

  const handleSubmit = async (skipMessage = false) => {
    let formattedMessage = message.trim();

    try {
      if (!skipMessage && formattedMessage.length > 0 && formattedMessage.length <= 3) {
        setError("We're glad you're reaching out, but please include a bit more detail.");
        return;
      } else if (skipMessage) {
        formattedMessage = '';
        setIsSkipping(true);
      } else {
        setIsSubmitting(true);
      }

      const response = await ExpressInterestAPI.sendExpressInterestConnectionRequest(
        school.nces_id,
        formattedMessage
      );

      // TODO: if there's an error we should likely show a modal and potentially let them resubmit
      if (response.status !== 201)
        throw new Error(`Failed to send ${CONNECT_ACTION_LABEL} request`);

      actions.setContactedSchoolIds(school.nces_id);
      handleCloseModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsSkipping(false);
    }
  };

  const handleCloseModal = (_?: unknown, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

    updateContactedSchools(school);
    actions.setExpressInterestModalIsOpen(false);
    window.history.pushState({}, '', location.pathname);
  };

  useEffect(() => {
    if (actions.hasSchoolBeenContacted(school?.nces_id) || school?.hasExpressedInterest)
      handleCloseModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school]);

  return (
    <BlankModal
      open={expressInterestModalIsOpen && !school?.hasExpressedInterest}
      onClose={handleCloseModal}
      sx={{
        backgroundImage: `url(${expressInterestBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        padding: isMobile ? '16px' : '24px',
        borderRadius: '8px',
      }}
      header={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#101828',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '28px',
              padding: '80px 0px 24px',
            }}
          >
            Finish connecting with this principal
          </Typography>
          <IconButton onClick={() => actions.setExpressInterestExitModalIsOpen(true)}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
      body={
        <Stack gap={'12px'}>
          <Alert>
            Principals will typically contact you by email within one week if it’s a good match.
          </Alert>
          <Box
            sx={{
              display: 'flex',
              padding: isMobile ? '16px' : '24px',
              flexDirection: 'column',
              gap: '8px',
              borderRadius: '8px',
              border: '1px solid #B1ECDB',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                Color: '#101828',
              }}
            >
              {school?.name}
            </Typography>
            <BasicInfoSection school={school} isInterestModal={true} />
          </Box>
          <Box width={'100%'} alignContent={'center'}>
            <Typography
              sx={{
                color: '#344054',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '20px',
                margin: '16px 0px 4px',
              }}
            >
              Introduce yourself to Principal {school?.principal?.last_name} (suggested: 3-5
              sentences){' '}
            </Typography>
            <Input
              multiline={true}
              onChange={handleChange}
              sx={{
                width: '100%',
                marginBottom: '32px',
                border: 'solid 1px ##D0D5DD',
                borderRadius: '8px',
                alignSelf: 'center',
                ' & input::placeholder': {
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                },
              }}
              placeholder="[optional] What do you want this principal to know about you? Your answer will go to the school principal"
              hasError={!!error}
              errorText={error}
            />
          </Box>
          <Grid container direction={'row'} justifyContent={'space-between'}>
            <Grid item width={'48%'}>
              <SecondaryButton
                size="small"
                disabled={isSkipping || isSubmitting}
                isLoading={isSkipping}
                onClick={() => handleSubmit(true)}
                sx={{
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  color: '#344054',
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                Skip & Submit
              </SecondaryButton>
            </Grid>
            <Grid item width={'48%'}>
              <PrimaryButton
                size="small"
                disabled={isSkipping || isSubmitting}
                isLoading={isSubmitting}
                onClick={() => handleSubmit()}
                sx={{
                  backgroundColor: '#00A37D',
                  border: '1px solid #00A37D',
                  borderRadius: '8px',
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                Send & Submit
              </PrimaryButton>
            </Grid>
          </Grid>
        </Stack>
      }
    />
  );
};

export const CloseButton = styled(Button)({
  justifyItems: 'end',
  backgroundColor: 'transparent',
  borderStyle: 'none',
  height: '24px',
  padding: 0,
  minWidth: '5px',
});
