import { JobEditProps } from 'components/JobEdit/types';
import { useQueryGetJobClosureNotifications } from 'features/JobClosureNotifications/queries';
import auth from 'utils/auth';

export const withJobClosureNotificationsCache = (Component: React.ElementType) => {
  const isValidUserTypeForJobClosureNotifications = () => {
    if (auth.isDistrictAdmin() || auth.isSuperAdminUser() === null) {
      return false;
    } else {
      return auth.isDistrictAdmin() || auth.isSuperAdminUser();
    }
  };

  // eslint-disable-next-line react/display-name
  return (props: JobEditProps): JSX.Element => {
    if ('id' in props.job) {
      const { data } = useQueryGetJobClosureNotifications(
        props.job.id,
        isValidUserTypeForJobClosureNotifications()
      );
      return <Component {...props} queryCache={data ? data : null} />;
    } else {
      return <Component {...props} />;
    }
  };
};

withJobClosureNotificationsCache.displayName = 'withJobClosureNotificationsCache';
