import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { questionSetItemOptions } from '../enums';
import { getItemStyle } from '../utils';

import { Input } from 'ui-kit';
import { sortQuestionsAndAttachments } from '../../../utils/roleutils';
import QuestionBox from 'components/common/QuestionBox';
import {
  HeaderText,
  GreyText,
  ActionButtonsContainer,
  SubmitButtonContainer,
  SaveButton,
  DeleteButton,
  ErrorTextStyled,
} from '../styledComponents';
import ItemRow from '../ItemRow';

export const QuestionSetTitleInput = (questionSet, handleQuestionSetUpdate) => {
  const { title } = questionSet ?? '';
  return (
    <>
      <HeaderText>Question set title</HeaderText>
      <Input
        name="title"
        type="text"
        placeholder="eg. Full-time teacher application questions"
        value={title}
        onChange={handleQuestionSetUpdate}
        width={1}
      />
    </>
  );
};

export const QuestionSetItemsInput = (
  questionSet,
  updateItem,
  deleteItemByIndex,
  onDragEnd,
  questionBank,
  setEditItemPane
) => {
  const { items } = questionSet ?? [];
  return (
    <>
      <HeaderText>Question set items</HeaderText>
      {!items || items.length === 0 ? (
        <GreyText>Select an item below to start building an application question set</GreyText>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortQuestionsAndAttachments(questionSet.items).map((item, index) => (
                  <Draggable
                    key={item.draggable_id || item.id}
                    draggableId={String(item.draggable_id || item.id)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <ItemRow
                          index={index}
                          key={item.draggable_id}
                          item={item}
                          onSave={updateItem}
                          onDelete={deleteItemByIndex}
                          questionBank={questionBank}
                          setEditItemPane={setEditItemPane}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export const QuestionSetAddItemsInput = (setQuestionType, setNewItemModalShowing) => {
  return (
    <>
      <HeaderText>Add Items</HeaderText>
      <div className="question-type-container">
        {questionSetItemOptions().map((item) => (
          <QuestionBox
            key={item.key}
            handleClick={() => {
              setQuestionType(item.value);
              setNewItemModalShowing(true);
            }}
            label={item.label}
            type={item.value}
          />
        ))}
      </div>
    </>
  );
};

export const QuestionSetActions = (saveQuestionSet, errorMessage) => {
  const DIMENSIONS = [1, 1, '160px'];
  return (
    <ActionButtonsContainer>
      <SubmitButtonContainer>
        {errorMessage && <ErrorTextStyled>{errorMessage}</ErrorTextStyled>}
        <SaveButton onClick={saveQuestionSet} width={DIMENSIONS} minWidth={DIMENSIONS}>
          Save question set
        </SaveButton>
      </SubmitButtonContainer>
    </ActionButtonsContainer>
  );
};
