import { Component } from 'react';

import { degreeField, degreeSubjects, universities } from '../../../utils/enums';
import PropTypes from 'prop-types';
import iconMinus from '../../../assets/icon-minus.svg';
import iconPlus from '../../../assets/icon-plus.svg';
import moment from 'moment';
import { handleOutsideClickOnValidForm, updateDate, isCardBlank } from '../../../utils/util';
import DateTime from 'react-datetime';
import { StyledSelect, StyledCreatableSelect } from '../../../ui-kit';
import { createFilter } from 'react-select';
import ErrorText from '../../../components/errortext';
import { ATSCandidateEducationDataTestIds } from 'data-testids/ATS';

export default class EducationInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      educations: props.educations,
      addEducation: props.freshObject(),
      isLoading: false,
      isAdding: false,
      insertPosition: undefined,
      errorMessage: '',
    };

    this.isCardBlank = isCardBlank.bind(this);
  }

  static propTypes = {
    educations: PropTypes.object.isRequired,
    freshObject: PropTypes.func.isRequired,
    autosave: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ educations: nextProps.educations });
  }

  getDegree(value) {
    value = parseInt(value, 10);
    const option = degreeField().find((o) => o.value === value);
    return option && option.label;
  }

  toggleAdd = () => {
    if (!this.state.isAdding) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      if (this.isCardBlank(this.state.addEducation)) {
        return this.setState({
          errorMessage: 'You must complete at least one field to save this entry.',
        });
      }
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
      this.handleAddRow();
    }
    this.setState({ isAdding: !this.state.isAdding, errorMessage: '' });
  };

  outsideClickListener = (ev) => {
    if (ev.target.className.indexOf === undefined) {
      // indexOf is undefined when removing an item from react select :(
      return;
    }

    // TODO: This may break again if react-select ever change their classes
    if (ev.target.className.indexOf('css-1cxqniv-option') > -1) {
      // if clicking on an option in the React Select component, don't close the form
      return;
    }

    // Remove the listener if canceling out
    if (ev.target.className.indexOf('add-btn') !== -1) {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    // Don't need to handle the click if canceling out of the card
    if (ev.target.className.indexOf('add-btn') === -1) {
      handleOutsideClickOnValidForm(this.node, ev, this.toggleAdd);
    }
  };

  /**
   * Searches the current list of universities.
   *
   * When iterating over large datasets, regular
   * for loops beat out javascript methods like
   * map, filter, etc.
   *
   * Extra info for those interested (as of 12/20/21)
   * https://leanylabs.com/blog/js-forEach-map-reduce-vs-for-for_of/
   *
   * @returns {Array}
   */
  customFilterUniversities = () => {
    const finalArray = [];
    const unis = universities();

    for (let i = 0; i < unis.length; i++) {
      if (this.state.addEducation.university === unis[i].value) {
        finalArray.push(unis[i]);
      }
    }
    return finalArray;
  };

  /* Updating the various input types */
  updateField = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    let updateObject = this.state.addEducation;
    updateObject[name] = value;
    this.setState({ addEducation: updateObject, errorMessage: '' });
  };

  updateMultiSelect = (values, fieldName) => {
    let updateObject = this.state.addEducation;
    values = values === null ? '' : values;
    updateObject[fieldName] = parseInt(values.value, 10);
    this.setState({
      addEducation: updateObject,
      errorMessage: '',
    });
  };

  /**
   * Handles the creation of a new school when user is adding their
   * education credentials.
   *
   * @param {*} value - new school being added to the value list
   */
  handleAddNewSchool = (value, fieldName) => {
    const updateObject = this.state.addEducation;
    updateObject['school'] = value;

    this.setState({
      addEducation: updateObject,
      errorMessage: '',
    });
  };

  handleSchoolChange = (e) => {
    let updateObject = this.state.addEducation;
    updateObject['school'] = e.target.value;
    this.setState({ addEducation: updateObject, errorMessage: '' });
  };

  handleAddRow = () => {
    let copyEducations = Object.assign({}, this.state.educations);

    // insert at certain position if specified, otherwise add to end
    if (this.state.insertPosition !== undefined) {
      copyEducations.list.splice(this.state.insertPosition, 0, this.state.addEducation);
    } else {
      copyEducations.list.push(this.state.addEducation);
    }

    this.setState({ educations: copyEducations, addEducation: this.props.freshObject() }, () => {
      this.props.autosave();
    });
  };

  handleEditRow = (i, e) => {
    let copyEducations = Object.assign({}, this.state.educations);
    let addNew = Object.assign({}, copyEducations.list[i]);
    copyEducations.list.splice(i, 1);
    // i represents the location of the (now editing) experience. Set it in state so we can use for insertion
    this.setState({ addEducation: addNew, educations: copyEducations, insertPosition: i }, () => {
      this.toggleAdd();
    });
  };

  handleRemoveRow = (i, e) => {
    e.stopPropagation();
    let copyEducations = Object.assign({}, this.state.educations);
    copyEducations.list.splice(i, 1);
    this.setState({ educations: copyEducations }, () => {
      this.props.autosave();
    });
  };

  handleClick = (i, e) => {
    if (this.state.isAdding) {
      this.toggleAdd();
    } else {
      this.handleEditRow(i, e);
    }
  };

  // when adding a new row, insert at end of current list
  resetInsertPosition = () => {
    const insertPosition = this.state.educations.length;
    this.setState({ insertPosition });
  };

  render() {
    return (
      <div ref={(node) => (this.node = node)}>
        {this.state.educations.list.map((row, i) => {
          let school = universities().find((u) => u.value === parseInt(row['university'], 10));
          school = school ? school.label : row['school'];
          let major = degreeSubjects().find((u) => u.value === parseInt(row['major_enum'], 10));
          major = major ? major.label : row['major'];
          return (
            <div className="added-row" key={i} onClick={(e) => this.handleClick(i, e)}>
              <div className="block flex-1">
                <h4>
                  {school || ''} {this.getDegree(row['degree']) && ', '}
                  <span>{`${this.getDegree(row['degree']) || ''}`}</span>
                </h4>
                <div>
                  <span>
                    {major || ''} {major && ' | '}
                  </span>
                  <span>
                    {row['gpa']}/{row['gpa_out_of']} {row['gpa'] && ' | '}
                  </span>
                  <span className="flex-1">
                    {`${
                      moment(row['start_date']).isValid()
                        ? moment(row['start_date']).format('MM/D/YYYY')
                        : ''
                    } ${
                      moment(row['end_date']).isValid()
                        ? `- ${moment(row['end_date']).format('MM/D/YYYY')}`
                        : ''
                    }`}
                  </span>
                </div>
              </div>
              <div className="pointer">
                <span className="mr1">Edit</span>
                <span onClick={(e) => this.handleRemoveRow(i, e)}>Delete</span>
              </div>
            </div>
          );
        })}
        {this.state.isAdding && (
          <div className="adding-row">
            <div className="basic application-row flex full-width-mobile first">
              {this.state.addEducation.school ? (
                <div>
                  <input
                    type="text"
                    className="form-field"
                    value={this.state.addEducation.school}
                    onChange={this.handleSchoolChange}
                  />
                </div>
              ) : (
                <StyledCreatableSelect
                  id="school-creatable"
                  border="0"
                  boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                  className="flex-1"
                  // adding this prop dramatically improves the filters performance
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={universities()}
                  onCreateOption={(value) => this.handleAddNewSchool(value, 'created')}
                  value={this.customFilterUniversities()}
                  placeholder="School / Institution"
                  onChange={(values) => this.updateMultiSelect(values, 'university')}
                  isClearable
                />
              )}
              <StyledSelect
                id="field-of-study-select"
                border="0"
                boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                className="flex-1"
                filterOption={createFilter({ ignoreAccents: false })}
                options={degreeSubjects()}
                value={degreeSubjects().filter(
                  (subject) => this.state.addEducation.major_enum === subject.value
                )}
                placeholder="Field of study"
                onChange={(values) => this.updateMultiSelect(values, 'major_enum')}
                isClearable
              />
              <div className="basic-select dropdown flex-1" style={{ marginTop: '2.5px' }}>
                <select
                  id="degree-select"
                  data-testid={ATSCandidateEducationDataTestIds.DEGREE_SELECT_DROPDOWN}
                  name="degree"
                  value={this.state.addEducation.degree}
                  onChange={this.updateField}
                  required
                >
                  <option value="">Degree or Certificate</option>
                  {degreeField().map((degree) => (
                    <option
                      key={degree.value}
                      value={degree.value}
                      data-testid={`${ATSCandidateEducationDataTestIds.DEGREE_SELECT_DROPDOWN_OPTION}-${degree.value}`}
                    >
                      {degree.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="basic application-row flex application-start-and-end-dates">
              <div>
                <input
                  name="gpa"
                  type="number"
                  className="form-field flex-1"
                  placeholder="GPA"
                  value={this.state.addEducation.gpa}
                  onChange={this.updateField}
                />
                <input
                  name="gpa_out_of"
                  type="number"
                  className="form-field flex-1"
                  placeholder="Out of"
                  value={this.state.addEducation.gpa_out_of}
                  onChange={this.updateField}
                />
              </div>
              <div>
                <DateTime
                  className="datetime-picker-container date-arrow jobedit-select-div"
                  inputProps={{
                    placeholder: 'Month',
                    className: 'datetime-picker jobedit ml0',
                    readOnly: 'true',
                  }}
                  timeFormat={false}
                  dateFormat="MMM"
                  closeOnSelect={true}
                  value={
                    this.state.addEducation.start_date
                      ? moment(this.state.addEducation.start_date)
                      : ''
                  }
                  onChange={(date) => updateDate(this, 'addEducation', 'start_date', date)}
                />
              </div>
              <div>
                <DateTime
                  className="datetime-picker-container date-arrow jobedit-select-div"
                  inputProps={{
                    placeholder: 'Year',
                    className: 'datetime-picker jobedit ml0',
                    readOnly: 'true',
                  }}
                  timeFormat={false}
                  dateFormat="YYYY"
                  closeOnSelect={true}
                  value={
                    this.state.addEducation.start_date
                      ? moment(this.state.addEducation.start_date)
                      : ''
                  }
                  onChange={(date) => updateDate(this, 'addEducation', 'start_date', date)}
                />
              </div>
              <div className="">
                <DateTime
                  className="datetime-picker-container helptext date-arrow jobedit-select-div"
                  inputProps={{
                    placeholder: 'Month',
                    className: 'datetime-picker jobedit ml0',
                    readOnly: 'true',
                  }}
                  timeFormat={false}
                  dateFormat="MMM"
                  closeOnSelect={true}
                  value={
                    this.state.addEducation.end_date ? moment(this.state.addEducation.end_date) : ''
                  }
                  onChange={(date) => updateDate(this, 'addEducation', 'end_date', date)}
                />
              </div>
              <div>
                <DateTime
                  className="datetime-picker-container date-arrow jobedit-select-div"
                  inputProps={{
                    placeholder: 'Year',
                    className: 'datetime-picker jobedit ml0',
                    readOnly: 'true',
                  }}
                  timeFormat={false}
                  dateFormat="YYYY"
                  closeOnSelect={true}
                  value={
                    this.state.addEducation.end_date ? moment(this.state.addEducation.end_date) : ''
                  }
                  onChange={(date) => updateDate(this, 'addEducation', 'end_date', date)}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.isAdding ? (
          <div className="flex add-card-container">
            {this.state.insertPosition === undefined && (
              <div
                className="add-btn cancel"
                onClick={() => {
                  this.setState({
                    isAdding: false,
                    addEducation: this.props.freshObject(),
                    errorMessage: '',
                  });
                }}
              >
                Cancel
              </div>
            )}
            <div
              className="add-btn save"
              id="education-save-button"
              data-testid={ATSCandidateEducationDataTestIds.SAVE_ENTRY_BUTTON}
              onClick={() => {
                this.toggleAdd();
                this.resetInsertPosition();
              }}
            >
              Save Entry
            </div>
          </div>
        ) : (
          <div
            className="add-btn"
            id="education-add-button"
            data-testid={ATSCandidateEducationDataTestIds.ADD_EDUCATION_BUTTON}
            onClick={() => {
              this.toggleAdd();
              this.resetInsertPosition();
            }}
          >
            <img src={this.state.isAdding ? iconMinus : iconPlus} alt="Icon" />
            Add Entry
          </div>
        )}
        {this.state.errorMessage && <ErrorText message={this.state.errorMessage} />}
      </div>
    );
  }
}
