import './interviewmodal.css';

import { Component } from 'react';

import DateTime from 'react-datetime';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

export default class InterviewModal extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  };

  state = {
    editing: false,
    type: 0,
    when: '',
    email: true,
    warnType: false,
    warnWhen: false,
  };

  componentDidMount() {
    axios.get(`/api/interview/${this.props.application.id}/`).then((r) => {
      if (!this.ignoreLastFetch && r.data) {
        this.setState({
          editing: true,
          type: r.data.type,
          when: new Date(r.data.when),
        });
      }
    });
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  getInterviewType(type) {
    switch (type) {
      case 0:
        return 'Phone Screen';
      case 1:
        return 'On Site';
      case 2:
        return 'Video Questions';
      default:
        return 'Other';
    }
  }

  cancel = () => {
    if (this.performingAction) {
      return;
    }

    const email = this.state.email;
    this.performingAction = true;
    axios
      .delete(`/api/interview/${this.props.application.id}/`)
      .then(() => {
        if (this.props.onCancel) {
          this.props.onCancel(email);
        } else {
          this.props.onHide(email);
        }
        this.performingAction = false;
      })
      .catch(() => {
        this.performingAction = false;
      });
  };

  save = () => {
    const { when, type, email } = this.state;
    if (this.performingAction) {
      return;
    }

    if (!(when instanceof Date)) {
      this.setState({ warnWhen: true });
      return;
    }

    if (type == null) {
      this.setState({ warnType: true });
      return;
    }

    this.performingAction = true;
    axios.post(`/api/interview/${this.props.application.id}/`, { when, type });
    this.props.onSave(email, when, type);
    this.performingAction = false;
  };

  render() {
    const interviewTypeButtons = [0, 1, 2, 3].map((t) => {
      return (
        <button
          key={t}
          style={{ width: '140px' }}
          className={classNames('interview-modal-option-button', {
            'interview-modal-option-selected': this.state.type === t,
          })}
          onClick={() => this.setState({ warnType: false, type: t })}
        >
          {this.getInterviewType(t)}
        </button>
      );
    });

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="interview-modal"
      >
        <Modal.Header>
          <span className="interview-modal-close" onClick={() => this.props.onHide()}>
            (X) CLOSE
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="interview-modal-title">
            <h3>
              {!this.state.editing && <strong>Schedule Interview</strong>}
              {this.state.editing && <strong>Cancel/Reschedule Interview</strong>}
            </h3>
          </div>
          <div className="interview-modal-section">
            <div>
              <h4 className="interview-modal-header">
                <strong>When is the interview?</strong>
              </h4>
              <DateTime
                className="interview-modal-datetime-picker"
                inputProps={{
                  className: 'interview-modal-datetime',
                  placeholder: 'Interview Date',
                }}
                utc={true}
                value={this.state.when}
                onChange={(date) => {
                  if (date.toDate) {
                    this.setState({ warnWhen: false, when: date.toDate() });
                  } else {
                    this.setState({ warnWhen: true, when: date });
                  }
                }}
              />{' '}
              {this.state.warnWhen && <span className="text-danger">Invalid date</span>}
            </div>
            <div>
              <h4 className="interview-modal-header">
                <strong>What type of interview is it?</strong>{' '}
                {this.state.warnType && <span className="text-danger">Cannot be blank</span>}
              </h4>
              {interviewTypeButtons}
            </div>

            <div>
              <h4 className="interview-modal-header">
                <strong>Send a confirmation email?</strong>
              </h4>

              <button
                className={classNames('interview-modal-option-button', {
                  'interview-modal-option-selected': this.state.email === true,
                })}
                onClick={() => this.setState({ email: true })}
              >
                Yes
              </button>
              <button
                className={classNames('interview-modal-option-button', {
                  'interview-modal-option-selected': !this.state.email,
                })}
                onClick={() => this.setState({ email: false })}
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.state.editing && (
            <button className="interview-modal-button interview-modal-cancel" onClick={this.cancel}>
              Cancel Interview
            </button>
          )}
          <button className="interview-modal-button interview-modal-save" onClick={this.save}>
            {this.state.email ? 'Save & Send' : 'Save'}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
