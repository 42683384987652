import styled from 'styled-components';
import { Fragment } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import * as DashboardWidget from './components/DashboardWidget';
import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import { CHART_COLOR_PALETTES, DIVERSITY_CHOICES } from './constants';
import { useApplicantSourceMetrics } from './useApplicantSourceMetrics';
import { appStatusType } from 'utils/enums';

const ApplicantSourceWidget = ({ hiringSeasons, schools, subcategories, applicationStatuses }) => {
  const statusChoices = applicationStatuses.map((status) => ({
    id: status.id,
    value: status.id,
    label: status.label,
  }));

  const statusDropdownState = useChoiceDropdown({
    choices: statusChoices,
    initialSelectedChoiceIds: statusChoices.map((choice) => choice.id),
  });

  const diversityDropdownState = useChoiceDropdown({
    choices: DIVERSITY_CHOICES,
    initialSelectedChoiceIds: DIVERSITY_CHOICES.map((choice) => choice.id),
  });

  // Filter by selected statuses
  let statusesToFilter = applicationStatuses
    .filter((status) => statusDropdownState.selectedChoiceIds.includes(status.id))
    .map((status) => status.id);
  // If hired is selected, filter by onboarding too.
  const hiredStatuses = applicationStatuses.filter(
    (status) => status.status_type === appStatusType.hired
  );
  if (hiredStatuses.some((status) => statusDropdownState.selectedChoiceIds.includes(status.id))) {
    statusesToFilter = [
      ...statusesToFilter,
      ...applicationStatuses
        .filter((status) => status.status_type === appStatusType.onboarding)
        .map((status) => status.id),
    ];
  }

  const { data, isLoading } = useApplicantSourceMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders: DIVERSITY_CHOICES.filter((choice) => choice.group === 'gender')
      .filter((choice) => !choice.isGroupHeader)
      .filter((choice) => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map((choice) => choice.value),
    ethnicities: DIVERSITY_CHOICES.filter((choice) => choice.group === 'enthnicity')
      .filter((choice) => !choice.isGroupHeader)
      .filter((choice) => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map((choice) => choice.value),
    statuses: statusesToFilter,
  });

  let dataset = data.by_candidate.filter((entry) => entry.value > 0);
  if (dataset.length === 0) {
    dataset = data.by_candidate;
  }
  const COLORS = CHART_COLOR_PALETTES[8];

  const totalItems = dataset.reduce((total, currentItem) => {
    return (total += currentItem.value);
  }, 0);

  const notEnoughData = totalItems <= 1;

  let message;
  if (notEnoughData) {
    message = 'Not enough data. Check your filters and try again.';
  }

  return (
    <DashboardWidget.Container>
      <DashboardWidget.Heading>Applicant source</DashboardWidget.Heading>
      <DashboardWidget.Body>
        <DashboardWidget.BodyActions>
          <ChoiceDropdown
            label="Demographics"
            choices={DIVERSITY_CHOICES}
            {...diversityDropdownState}
          />
          <ChoiceDropdown label="Status" choices={statusChoices} {...statusDropdownState} />
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message ? (
            <Placeholder>{message}</Placeholder>
          ) : (
            <>
              <ResponsiveContainer width="50%" height="100%">
                <PieChart>
                  <Pie
                    data={dataset}
                    innerRadius={'60%'}
                    outerRadius={'80%'}
                    paddingAngle={1}
                    startAngle={225}
                    endAngle={225 - 360}
                    dataKey="value"
                  >
                    {dataset.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <ScrollableWrapper>
                <Legend>
                  {dataset.map((entry, index) => (
                    <Fragment key={entry.name}>
                      <LegendCircle color={COLORS[index % COLORS.length]} />
                      <LegendPercent>
                        {(data.total > 0 ? entry.value / data.total : data.total).toLocaleString(
                          'en-US',
                          {
                            style: 'percent',
                            minimumFractionDigits: 1,
                          }
                        )}
                      </LegendPercent>
                      <LegendName>{entry.name}</LegendName>
                      <div /> {/* spacer */}
                      <div /> {/* spacer */}
                      <LegendSubtext>{entry.value}</LegendSubtext>
                    </Fragment>
                  ))}
                </Legend>
              </ScrollableWrapper>
            </>
          )}
        </DashboardWidget.BodyContent>
      </DashboardWidget.Body>
    </DashboardWidget.Container>
  );
};

export default ApplicantSourceWidget;

const ScrollableWrapper = styled.div`
  max-height: 225px;
  width: 50%;
  overflow-y: scroll;
`;

const Legend = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 1rem;
  align-items: center;

  margin-left: 0.5em;

  color: #777;
  font-size: 16px;
`;

const LegendCircle = styled.div`
  background: ${(props) => props.color};
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const LegendPercent = styled.div`
  text-align: right;

  font-weight: 600;
  color: #444;

  margin-right: 5px;
`;

const LegendName = styled.div`
  color: #000;
`;

const LegendSubtext = styled.div`
  font-size: 12px;

  margin-bottom: 10px;
`;

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
