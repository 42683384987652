import { BaseNimbleAPI } from './baseAPI';

class referenceCheckAnswerAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/reference_check_answer' };
  }

  async update(answerId, formData) {
    const response = await this.put(`/${answerId}/`, formData);
    return response.data;
  }
}

export default new referenceCheckAnswerAPI();
