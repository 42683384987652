import { BaseNimbleAPI } from './baseAPI';

class EventsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/events' };
  }

  async getEvents(userId) {
    try {
      const response = await this.get(`/${userId}/`);

      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new EventsAPI();
