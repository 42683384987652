import { useState, useEffect } from 'react';
import styled from 'styled-components';

import NotificationModal from './NotificationModals/NotificationModal';
import NotificationRow from './NotificationRow';
import LoadingSpinner from 'components/loadingSpinner';

import NotificationsAPI from 'api/notificationsAPI';
import { showWarning } from 'utils/message';

import {
  notificationTypeValue,
  notificationJobsValue,
  notificationSchools,
} from 'utils/NotificationEnums';

import auth from 'utils/auth';
import { AnchorTagHeader } from '../../sharedComponents/Header/AnchorTagHeader';

const Notifications = ({ newApplicationStatuses }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [addNotificationModalShowing, setAddNotificationModalShowing] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let ignore = false;
    const fetchInitialData = async () => {
      await fetchNotifications(ignore);
      setIsLoading(false);
    };

    fetchInitialData();

    return () => {
      ignore = true;
    };
  }, []);

  async function fetchNotifications(ignore = false) {
    const notifications = await NotificationsAPI.fetchNotifications();
    if (!ignore) setNotifications(notifications);
  }

  function handleNotificationPost(notification) {
    NotificationsAPI.postNotification(notification)
      .then(closeModalAndFetchNotifications)
      .catch(logErrorAndShowWarning);
  }

  async function handleNotificationUpdate(notification) {
    NotificationsAPI.putNotification(notification)
      .then(() => {
        fetchNotifications();
        return Promise.resolve();
      })
      .catch(logErrorAndShowWarning);
  }

  function handleNotificationDelete(notification) {
    NotificationsAPI.deleteNotification(notification)
      .then(closeModalAndFetchNotifications)
      .catch(logErrorAndShowWarning);
  }

  function closeModalAndFetchNotifications() {
    fetchNotifications();
    setAddNotificationModalShowing(false);
  }

  function logErrorAndShowWarning(err) {
    showWarning(
      `Oops, there was a problem saving your notification.
      Please contact support@hirenimble.com for assistance.`,
      7000
    );
    throw new Error(err);
  }

  function freshNotification() {
    return {
      type: notificationTypeValue.status_change,
      status_id: null,
      frequency: 0, // immediate
      jobs_preference: notificationJobsValue.all_jobs,
      job_categories: [],
      schools_preference: notificationSchools.all_schools,
      schools: [],
      notify_me_for: null,
      notify_me_when: null,
    };
  }

  // According to spec, the user may only have at most one reference completed notification.
  // If they already have one, don't show that notification type in the modal dropdown.
  const hasReferenceCompletedNotification = notifications
    .map((n) => n.type)
    .includes(notificationTypeValue.reference_completed);

  return (
    <div className="application-status-container custom-fields">
      <div className="mt2">
        <AnchorTagHeader
          buttonText="+ New Notification"
          onClick={() => setAddNotificationModalShowing(true)}
          title="Notifications"
          id="notifications"
        />
        {isLoading ? (
          <LoadingSpinner margin={8} />
        ) : (
          <>
            <HeaderContainer>
              <SectionLarger>Event</SectionLarger>
              <Section>Frequency</Section>
              {auth.isDistrictAdmin() && <SectionMedium>Jobs</SectionMedium>}
              {auth.isDistrictUser() && <SectionMedium>Schools</SectionMedium>}
            </HeaderContainer>
            {notifications.length === 0 && (
              <EmptyNotificationContainer>
                <EmptyNotificationPlaceholderText>
                  Click "+ New Notification" button above to create new notification
                </EmptyNotificationPlaceholderText>
              </EmptyNotificationContainer>
            )}
            {notifications.map((notification) => (
              <NotificationRow
                key={notification.id}
                notification={notification}
                newApplicationStatuses={newApplicationStatuses}
                handlePutNotification={handleNotificationUpdate}
                handleNotificationDelete={handleNotificationDelete}
                hasReferenceCompletedNotification={hasReferenceCompletedNotification}
              />
            ))}
          </>
        )}
      </div>
      {addNotificationModalShowing && (
        <NotificationModal
          notification={freshNotification()}
          show={addNotificationModalShowing}
          closeModal={() => setAddNotificationModalShowing(false)}
          newApplicationStatuses={newApplicationStatuses}
          handleSubmit={handleNotificationPost}
          hasReferenceCompletedNotification={hasReferenceCompletedNotification}
        />
      )}
    </div>
  );
};

const EmptyNotificationContainer = styled.div`
  height: 208px;
  border: 1px dashed #949494;
  border-top: none;
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
`;

const EmptyNotificationPlaceholderText = styled.p`
  font-family: Open Sans;
  font-size: 17px;
  color: #bdbdbd;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  color: #999999;
  background-color: #ffffff;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  padding: 7px 16px;
  border: 1px solid #eee;
`;

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;
`;

const SectionMedium = styled(Section)`
  flex: 0 0 20%;
`;

const SectionLarge = styled(Section)`
  flex: 0 0 25%;
`;

const SectionLarger = styled(Section)`
  flex: 0 0 28%;
`;

export default Notifications;
