import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import ErrorText from '../errortext';
import PropTypes from 'prop-types';
import { gender, race_ethnicity, user_type } from '../../utils/enums';
import auth from '../../utils/auth';
import SearchAPI from 'api/searchAPI';
import { StyledAsyncSelect } from 'ui-kit';
import ManageUsersIcon from '../../assets/icons/AccountManagementIcons/manage_users.svg';
import IconCaretDown from '../../assets/icon-down-caret-grey.svg';
import IconChainLink from '../../assets/icons/icon-chain-link.svg';

import { LinkedAccounts, LinkedAccount } from 'components/Profile/LinkProfilesSection';

const USERTYPE = user_type().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

export default class DistrictAccountNewUserModal extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    displayModal: PropTypes.bool.isRequired,
    district: PropTypes.object.isRequired,
    hideModal: PropTypes.func.isRequired,
    user: PropTypes.object,
    schools: PropTypes.array.isRequired,
    toggleDeactivateModal: PropTypes.func,
  };

  static defaultProps = {
    district: {},
  };

  state = {
    displaySchool: false,
    errors: { profile: { school: null } },
    user: { profile: { school: null } },
    linkedProfiles: [],
    searchTerm: null,
    searchResults: null,
  };

  componentDidMount() {
    ReactTooltip.rebuild();
    this.setState({
      user: this.props.user || this.getFreshCandidate(),
      linkedProfiles: this.props.user?.linked_users || [],
    });
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  getFreshCandidate = () => {
    return {
      username: '',
      profile: {
        gender: gender()[0].value,
        race_ethnicity: race_ethnicity()[0].value,
        // In posting-only districts, new users are created as super
        // admins by default. In non-posting-only districts, user_type
        // can be changed using a dropdown but the initial choice is
        // district admin.
        user_type: auth.inPostingOnlyDistrict() ? USERTYPE.super_admin : USERTYPE.district_admin,
        middle_name: '',
        district: this.props.district,
        school: null,
        address_street: '',
        address_apt: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_zip: '',
        phone_home: '',
        phone_work: '',
        phone_cell: '',
        fax: '',
        website: '',
        resume: null,
        extra: null,
      },
    };
  };

  handleSubmit = () => {
    let user = Object.assign({}, this.state.user);
    // On subsequent edits without refreshing the page, the user state is
    // set to the data structure returned by the previous save, which might
    // include ssn.  This will cause a validation error since the receiving
    // view's serializer doesn't allow for a null/blank ssn.  To avoid this,
    // we make sure we aren't sending irrelevant data back.
    delete user.profile.ssn;
    // school admins must have a school
    if (
      [USERTYPE.school_user, USERTYPE.school_admin].includes(Number(user.profile.user_type)) &&
      (!user.profile.school || !user.profile.school.id)
    ) {
      this.setState({
        errors: { profile: { school: 'Please select a school.' } },
      });
      return;
    }

    let linked = [];
    if (this.state.linkedProfiles.length) {
      linked = [...this.state.linkedProfiles.map((u) => u.id)];
    }
    if (linked.length) {
      user.linked_users = [...new Set(linked)];
    } else {
      user.linked_users = [];
    }

    this.props
      .onSave(user)
      .then(() => {
        if (!this.ignoreLastFetch) {
          this.props.hideModal();
          this.setState({
            errors: { profile: { school: null } },
            user: this.getFreshCandidate(),
          });
        }
        // FIXME: display some sort of success message
      })
      .catch((error) => {
        // handle errors //
        this.setState({
          errors: error.response.data,
        });
      });
  };

  setNewCandidateState = (event) => {
    let newCandidateCopy = Object.assign({}, this.state.user);
    newCandidateCopy[event.target.name] = event.target.value;
    // Also update `email` if `username` is edited
    if (event.target.name === 'username') {
      newCandidateCopy['email'] = event.target.value;
    }
    this.setState({ user: newCandidateCopy });
  };

  setNewCandidateProfileState = (event) => {
    let newCandidateCopy = Object.assign({}, this.state.user);
    if (event.target.name === 'school') {
      newCandidateCopy['profile'][event.target.name] = null;
      for (var i = 0; i < this.props.schools.length; i++) {
        if (parseInt(event.target.value, 10) === this.props.schools[i]['id']) {
          newCandidateCopy['profile'][event.target.name] = this.props.schools[i];
        }
      }
    } else if (event.target.name === 'user_type') {
      switch (parseInt(event.target.value, 10)) {
        case USERTYPE.school_user:
        case USERTYPE.school_admin:
          this.setState({ displaySchool: true });
          break;
        default:
          this.setState({ displaySchool: false });
          newCandidateCopy['profile']['school'] = null;
      }
      newCandidateCopy['profile'][event.target.name] = event.target.value;
    } else {
      newCandidateCopy['profile'][event.target.name] = event.target.value;
    }
    this.setState({
      user: newCandidateCopy,
      errors: { profile: { school: null } },
    });
  };

  searchDistrictUsers = (inputValue) => {
    if (inputValue && inputValue.length < 3) {
      return [];
    }
    return SearchAPI.searchDistrictUsers({ search: inputValue, onlyCandidates: true }).then(
      (data) => {
        // Remove already linked users:
        const linked_user_ids = this.state.linkedProfiles.map((u) => u.id);
        const results = data.results.reverse().filter((u) => !linked_user_ids.includes(u.id));

        this.setState({ searchResults: results });
        return results.map((u) => ({
          label: (
            <StyledOption>
              <span>{u.name}</span>
              <span>{u.email}</span>
            </StyledOption>
          ),
          value: u.id,
        }));
      }
    );
  };

  onLinkUser = (newValue, selectEvent) => {
    if (selectEvent.action === 'select-option') {
      // Api call to link the user and clear the input...
      let linkedProfiles = this.state.linkedProfiles || [];
      let userInfo = this.state.searchResults.find((u) => {
        return u.id === newValue.value;
      });
      linkedProfiles.push(userInfo);
      this.setState({ linkedProfiles: linkedProfiles, searchTerm: null });
    }
  };

  onUnlinkUser = (id) => {
    this.setState({
      linkedProfiles: this.state.linkedProfiles.filter((u) => id !== u.id),
    });
  };

  render() {
    const EDITMODE = !!this.props.user;
    const EDITING_SELF = EDITMODE && this.props.user.id === auth.getUser().id;

    return (
      <form>
        <Modal show={this.props.displayModal} bsSize="large" dialogClassName="account-modal">
          <Modal.Header>
            <img src={ManageUsersIcon} alt="" />
            <h3>{EDITMODE ? 'Edit User' : 'Create New User'}</h3>
          </Modal.Header>
          <Modal.Body>
            <ErrorText message={this.state.errors.non_field_errors} />

            <div className="name-container-div">
              <div className="half">
                <h4 className="section-title">First Name</h4>
                <div className="account-modal-dropshadow">
                  <input
                    className="account-modal-input"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    required
                    value={this.state.user.first_name}
                    onChange={(event) => this.setNewCandidateState(event)}
                  />
                  <ErrorText message={this.state.errors.first_name} />
                </div>
              </div>

              <div className="half">
                <h4 className="section-title">Last Name</h4>
                <div className="account-modal-dropshadow">
                  <input
                    className="account-modal-input"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    required
                    value={this.state.user.last_name}
                    onChange={(event) => this.setNewCandidateState(event)}
                  />
                  <ErrorText message={this.state.errors.last_name} />
                </div>
              </div>
            </div>

            <h4 className="section-title">Email</h4>
            <div className="account-modal-dropshadow">
              <input
                className="account-modal-input"
                name="username"
                type="text"
                placeholder="Email"
                required
                value={this.state.user.username}
                onChange={(event) => this.setNewCandidateState(event)}
              />
              <ErrorText message={this.state.errors.username || this.state.errors.email} />
            </div>

            {!auth.inPostingOnlyDistrict() && (
              <>
                <h4 className="section-title">Account Type</h4>
                <div className="account-modal-dropshadow">
                  <SelectWithDisabledCursor
                    disabled={EDITING_SELF}
                    name="user_type"
                    value={this.state.user.profile.user_type}
                    onChange={(event) => this.setNewCandidateProfileState(event)}
                  >
                    <option value="">Select User Type</option>
                    {user_type().map((level) => {
                      if (
                        level.key === USERTYPE.district_admin ||
                        level.key === USERTYPE.school_admin ||
                        (level.key === USERTYPE.super_admin && auth.isSuperAdminUser()) ||
                        level.key === USERTYPE.district_user
                      ) {
                        return (
                          <option key={level.key} value={level.key}>
                            {level.label}
                          </option>
                        );
                      }
                      return '';
                    })}
                  </SelectWithDisabledCursor>
                  <img src={IconCaretDown} alt="" />
                  <ErrorText
                    message={this.state.errors.profile && this.state.errors.profile.user_type}
                  />
                </div>
              </>
            )}

            {[USERTYPE.school_user, USERTYPE.school_admin].includes(
              Number(this.state.user.profile.user_type)
            ) && (
              <div>
                <h4 className="section-title">School Name</h4>
                <div className="account-modal-dropshadow">
                  <select
                    name="school"
                    value={
                      (this.state.user.profile.school && this.state.user.profile.school.id) || ''
                    }
                    onChange={(event) => this.setNewCandidateProfileState(event)}
                  >
                    <option value="">Select School</option>
                    {this.props.schools.map((school) => (
                      <option key={school.id} value={school.id}>
                        {school.name}
                        {school.location ? ': ' + school.location : ''}
                      </option>
                    ))}
                  </select>
                  <img src={IconCaretDown} alt="" />
                  <ErrorText
                    message={this.state.errors.profile && this.state.errors.profile.school}
                  />
                </div>
              </div>
            )}

            {auth.isSuperAdminUser() && !auth.inPostingOnlyDistrict() && (
              <>
                <h4 className="section-title">
                  Link to candidate profile(s){' '}
                  <InformationIcon
                    data-for="edit-user-info"
                    data-class="tooltip-in-modal"
                    data-tip="Prevents admin from seeing their own candidate profile details, including notes and scorecards"
                    data-effect="solid"
                  />
                </h4>
                <DropshadowContainer>
                  <StyledAsyncSelect
                    value={this.state.searchTerm}
                    defaultOptions
                    loadOptions={this.searchDistrictUsers}
                    onChange={this.onLinkUser}
                    placeholder="Start typing candidate name or email to link this user to their candidate profile(s)"
                  />
                </DropshadowContainer>
                {!!this.state.linkedProfiles.length && (
                  <LinkedAccounts>
                    <p>Linked accounts</p>

                    {this.state.linkedProfiles.map((profile) => (
                      <LinkedAccount key={profile.id}>
                        <div>
                          <p>{profile.name}</p>
                          <p>{profile.email}</p>
                        </div>

                        <img alt="chain-link-icon" src={IconChainLink} />
                        <button onClick={() => this.onUnlinkUser(profile.id)}>Unlink</button>
                      </LinkedAccount>
                    ))}
                  </LinkedAccounts>
                )}
              </>
            )}

            {!EDITING_SELF && EDITMODE && auth.isSuperAdminUser() && (
              <p className="deactivate" onClick={this.props.toggleDeactivateModal}>
                {/* use props user instead of state user here in case they edit the name */}
                Deactivate {this.props.user.name}
              </p>
            )}
            <div className="action-buttons-div">
              <button className="cancel" onClick={this.props.hideModal}>
                Cancel
              </button>
              <button className="save" onClick={this.handleSubmit}>
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <ReactTooltip id="edit-user-info" />
      </form>
    );
  }
}

const SelectWithDisabledCursor = styled.select`
  &:disabled {
    cursor: not-allowed;
  }
`;

// Custom option component for our select:
const StyledOption = styled.div`
  display: grid;
  grid-template-columns: 30% 50%;
`;

const InformationIcon = styled.div`
  position: relative;
  top: 4px;

  display: inline-block;
  height: 18px;
  width: 18px;

  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.uiColors.lightgray};

  &::after {
    display: block;
    height: 18px;
    width: 18px;

    position: absolute;
    left: 5px;
    top: 0;

    content: '?';
    color: ${(props) => props.theme.uiColors.lightgray};
  }
`;

const DropshadowContainer = styled.div`
  margin: 1rem 0 2rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
`;
