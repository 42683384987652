import { ColumnCategoriesProps } from './types';
import {
  HideOnMobile,
  JobsListHeader,
  RowSection,
  RowSectionClickable,
  TemplatesListHeader,
} from './styles';
import { Tooltip } from 'sharedComponents/Tooltip';
import auth from 'utils/auth';
import InfoIcon from '@mui/icons-material/Info';
import SwapVertIcon from '@mui/icons-material/SwapVert';

export const ColumnCategories: React.FC<ColumnCategoriesProps> = ({
  smallerLayout,
  sortRolesBy,
  sortField,
  variant,
}) => {
  const isSchoolUser = auth.isSchoolUser();
  const showFeaturedJobs = auth.isDistrictAdmin();

  return (
    <>
      {variant === 'templates' && (
        <TemplatesListHeader smallerLayout={smallerLayout}>
          {/* Spacer element for row item without a heading */}
          {<div className="districtjob-row-featured-icon" />}
          <RowSectionClickable
            onClick={() => sortRolesBy('title')}
            isActive={sortField === 'title'}
          >
            Template Name &nbsp;
            <SwapVertIcon fontSize="small" />
          </RowSectionClickable>
          <RowSectionClickable
            onClick={() => sortRolesBy('created')}
            isActive={sortField === 'created'}
          >
            Date Created &nbsp;
            <SwapVertIcon fontSize="small" />
          </RowSectionClickable>
          <HideOnMobile>
            <RowSection>Created By</RowSection>
          </HideOnMobile>
          <HideOnMobile>
            <RowSection>Schools</RowSection>
          </HideOnMobile>
        </TemplatesListHeader>
      )}
      {variant === 'jobs' && (
        <JobsListHeader smallerLayout={smallerLayout}>
          {showFeaturedJobs && <div className="districtjob-row-featured-icon" />}
          <RowSectionClickable
            onClick={() => sortRolesBy('title')}
            isActive={sortField === 'title'}
          >
            Job Title &nbsp;
            <SwapVertIcon fontSize="small" />
          </RowSectionClickable>
          <RowSectionClickable
            onClick={() => sortRolesBy('status')}
            isActive={sortField === 'status'}
          >
            Job Status &nbsp;
            <SwapVertIcon fontSize="small" />
          </RowSectionClickable>
          <HideOnMobile>
            <RowSection>{isSchoolUser ? 'School' : 'Schools'}</RowSection>
          </HideOnMobile>
          <HideOnMobile>
            <RowSectionClickable
              onClick={() => sortRolesBy('deadline')}
              isActive={sortField === 'deadline'}
            >
              Deadline &nbsp;
              <Tooltip title="All jobs close at 8PM Pacific Time on the deadline." placement="top">
                <InfoIcon fontSize="small" />
              </Tooltip>
              <SwapVertIcon fontSize="small" />
            </RowSectionClickable>
          </HideOnMobile>
          <HideOnMobile>
            <RowSectionClickable
              onClick={() => sortRolesBy('created')}
              isActive={sortField === 'created'}
            >
              Date Posted
              <SwapVertIcon fontSize="small" />
            </RowSectionClickable>
          </HideOnMobile>
        </JobsListHeader>
      )}
    </>
  );
};
