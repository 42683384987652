import styled from 'styled-components';
import { grid } from 'styled-system';

import { AltModal } from 'ui-kit';
import LoadingSpinner from '../../loadingSpinner';

import ReferenceListCard from './ReferenceListCard';
import ReferenceRequests from '../ReferenceRequests';
import { refContactType } from 'utils/enums';
import { ReferenceListProps } from '../types';

const ReferenceListSection = ({ referenceChecks, ...rest }) => {
  const headerSection = !referenceChecks.length ? (
    <strong>There are no reference forms recorded yet.</strong>
  ) : (
    <h4>{`${referenceChecks.length} Reference Forms Submitted`}</h4>
  );

  return (
    <div>
      <div>{headerSection}</div>
      {/* split into two columns for large screens (> 990px) */}
      <ListGrid>
        {referenceChecks.map((referenceCheck) => (
          <ReferenceListCard key={referenceCheck.id} referenceCheck={referenceCheck} {...rest} />
        ))}
      </ListGrid>
    </div>
  );
};

const ReferenceListFooter = ({ onCreate, onCancel, showRequestSection, updateReferenceType }) => (
  <FooterContainer gridTemplateColumns={['1fr', '1fr', '126px 388px']}>
    <CancelButton onClick={onCancel}>Cancel</CancelButton>
    <ActionButtonContainer>
      <RequestButton
        onClick={() => {
          updateReferenceType(refContactType.email);
          showRequestSection();
        }}
      >
        + Reference Request
      </RequestButton>
      <SaveButton
        onClick={() => {
          updateReferenceType(refContactType.phone);
          onCreate();
        }}
      >
        + New Reference
      </SaveButton>
    </ActionButtonContainer>
  </FooterContainer>
);

const ReferenceList: React.FC<ReferenceListProps> = ({
  referenceChecks,
  requests,
  onView,
  onCreate,
  onRequest,
  onComplete,
  onCancel,
  loading,
  showRequestSection,
  updateReferenceType,
}) => {
  return (
    <>
      <AltModal.Body>
        {loading ? (
          <LoadingSpinner margin={3} />
        ) : (
          <>
            <ReferenceListSection
              referenceChecks={referenceChecks}
              onView={onView}
              onComplete={onComplete}
            />
            {requests.length > 0 && <ReferenceRequests requests={requests} onRequest={onRequest} />}
          </>
        )}
      </AltModal.Body>
      <ReferenceListFooter
        onCreate={onCreate}
        onCancel={onCancel}
        showRequestSection={showRequestSection}
        updateReferenceType={updateReferenceType}
      />
    </>
  );
};

export default ReferenceList;

const ListGrid = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginTop: '32px',
    marginBottom: '25px',
  },
  grid
);

export interface FooterContainer {
  gridTemplateColumns?: string[];
}

const FooterContainer = styled(AltModal.Actions)<FooterContainer>(
  {
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  borderRadius: '3px',
  width: '100%',
});

const CancelButton = styled(FooterButton)({
  backgroundColor: '#FFFFFF',
  border: '1px solid #CACACA',
  color: '#CACACA',
});

const ActionButtonContainer = styled.div(
  {
    display: 'grid',
    justifyContent: 'end',
    gridGap: '16px',
    gridTemplateColumns: 'repeat(2, 186px)',
  },
  grid
);

const RequestButton = styled(FooterButton)({
  backgroundColor: '#CACACA',
  border: '1px solid #CACACA',
  color: '#FFFFFF',
});

const SaveButton = styled(FooterButton)({
  backgroundColor: '#00B88D',
  border: '1px solid #00B88D',
  color: '#FFFFFF',
});
