import { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import ProspectInformation from './ProspectInformation';
import auth from 'utils/auth';
import SuccessModal from 'components/common/Modal/SuccessModal';
import { Logo } from 'ui-kit';

import prospectListAPI from 'api/prospectListAPI';

export default class EnterProspectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
      logo: '',
    };
  }

  componentDidMount() {
    document.body.className += ` auth-pages`;
    document.body.className += ` prospect-entry`;

    const district_id = auth.getUser().profile.district.id;
    axios
      .get(`/api/district/${district_id}/default_logo/`)
      .then((r) => this.setState({ logo: r.data.logo }));
  }

  componentWillUnmount() {
    // Revert to prior classes
    document.body.classList.remove('auth-pages');
    document.body.classList.remove('prospect-entry');
  }

  toggleSuccessModal = () => {
    this.setState((prevState) => {
      return {
        successModal: !prevState.successModal,
      };
    });
  };

  submitProspect = (fname, lname, email) => {
    let data = {
      first_name: fname,
      last_name: lname,
      email: email,
    };
    return new Promise((resolve, reject) => {
      prospectListAPI
        .createProspect(data)
        .then(() => {
          this.setState({ successModal: true });
          resolve('Prospect created.');
        })
        .catch((err) => {
          if (err.response.data) {
            reject(err.response.data[Object.keys(err.response.data)[0]]);
          } else {
            reject(
              'There was an error with your submission. Please contact support@hirenimble.com for assistance.'
            );
          }
        });
    });
  };

  render() {
    return (
      !this.state.redirected && (
        <div className="App-container">
          <LogoContainer>{this.state.logo && <Logo src={this.state.logo} alt="" />}</LogoContainer>
          <div className="App-container__content">
            <ProspectInformation submitProspect={this.submitProspect} />
          </div>
          {this.state.successModal && (
            <SuccessModal
              show={this.state.successModal}
              onHide={this.toggleSuccessModal}
              message="Your information has been submitted!"
              buttonLabel="Got it!"
            />
          )}
        </div>
      )
    );
  }
}

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
