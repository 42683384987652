/*
 *   Utitily functions for email-related components
 */

export function handleVariableClick(name) {
  /** accept name of variable, and update state so it's in email content
   * @param {string} name: human readable name of the variable
   */
  let editor = this.quillRef.getEditor();
  editor.focus();
  let range = editor.getSelection();
  let position = range ? range.index : 0;
  let insertText = '{' + name + '}';
  editor.insertText(position, insertText);
}

export function isValidEmail(email) {
  /* eslint-disable */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
