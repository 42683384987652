import { useState } from 'react';
import styled from 'styled-components';

import { Dropdown } from 'ui-kit';

import { ReactComponent as SearchIcon } from 'assets/icon_search_glass.svg';
import { SimpleCheckInput } from 'ui-kit';
import {
  ATSCandidateExperienceDataTestIds,
  ATSVacancyDashboardDataTestIds,
} from '../../../../data-testids/ATS';

export const VacancyFilterDropdown = ({
  label,
  choices,
  selectedChoiceIds,
  fieldName,
  onToggleChoice,
  onSelectAllChoices,
  onClearAllChoices,
  height = '40px',
  dataTestId,
}) => {
  const isActive = selectedChoiceIds.length > 0 && selectedChoiceIds.length < choices.length;
  const [searchValue, setSearchValue] = useState('');

  let filteredChoices;
  if (choices === undefined) {
    filteredChoices = [];
  } else if (!searchValue) {
    filteredChoices = choices;
  } else {
    filteredChoices = choices.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const toggleSelected = (fieldName, listItem, isSelected) => {
    let idsToToggle;

    if (listItem.isGroupHeader) {
      // Toggle all in group
      idsToToggle = filteredChoices.filter((o) => o.group === listItem.group).map((o) => o.value);
    } else {
      // Toggle single one
      idsToToggle = [listItem.value];
    }

    onToggleChoice(fieldName, idsToToggle, isSelected);
  };

  return (
    <Dropdown.Dropdown
      label={label}
      isActive={isActive}
      inputProps={{ minWidth: '175px', height, fontSize: '15px', 'data-testid': dataTestId }}
      labelProps={{ color: '#444' }}
    >
      <TextButtonWrapper>
        <TextButton
          onClick={() => onSelectAllChoices(fieldName, choices)}
          data-testid={ATSVacancyDashboardDataTestIds.DROPDOWN_SELECT_ALL_BUTTON}
        >
          SELECT ALL
        </TextButton>
        <TextButton
          onClick={() => onClearAllChoices(fieldName)}
          data-testid={ATSVacancyDashboardDataTestIds.DROPDOWN_CLEAR_BUTTON}
        >
          CLEAR
        </TextButton>
      </TextButtonWrapper>
      <InputFilterContainer>
        <InputFilter
          type="text"
          placeholder=""
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {searchValue ? (
          <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
        ) : (
          <PositionedSearchIcon />
        )}
      </InputFilterContainer>
      {filteredChoices.map((choice, i) => {
        let isSelected = false;
        let isGroupHeaderConfused = false;
        if (choice.id === 'N/A') {
          isSelected = selectedChoiceIds.includes(choice.id);
        } else {
          if (choice.isGroupHeader) {
            const groupId = choice.group ? choice.group : choice.value;
            let group = filteredChoices.filter((f) => f.group === groupId);
            if (fieldName === 'subcategoriesFilter') group = group.filter((f) => !f.isGroupHeader);
            isSelected = group.every((e) => selectedChoiceIds.includes(e.value));
            isGroupHeaderConfused =
              group.some((ss) => selectedChoiceIds.includes(ss.value)) &&
              group.length > 1 &&
              !isSelected;
          } else {
            isSelected = selectedChoiceIds.includes(choice.value);
          }
        }

        return (
          <DropdownChoice
            key={i}
            isSelected={isSelected}
            onClick={() => toggleSelected(fieldName, choice, isSelected)}
            isGroupHeader={choice.isGroupHeader}
            isConfused={isGroupHeaderConfused}
          >
            {choice.label}
          </DropdownChoice>
        );
      })}
    </Dropdown.Dropdown>
  );
};

const TextButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const TextButton = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.uiColors.greens.full};
  font-size: 14px;
  font-weight: bold;

  :hover {
    text-decoration: underline;
  }
`;

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  padding-left: 1rem;
  padding-right: 0.8rem;
  margin-bottom: 6px;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 20px;
  margin: 0;
  width: 100%;
`;

const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 20px;
  top: 9px;
  width: 16px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 24px;
  top: 4px;

  font-size: 21px;
  cursor: pointer;
`;

const DropdownListItem = ({ isSelected, children, isConfused, ...rest }) => {
  return (
    <Dropdown.ListItem {...rest}>
      <span>{children}</span>
      <SimpleCheckInput isSelected={isSelected} isConfused={isConfused} />
    </Dropdown.ListItem>
  );
};

const DropdownChoice = styled(({ isGroupHeader, ...rest }) => <DropdownListItem {...rest} />)`
  padding: 0.5rem 1rem;
  padding-left: ${(props) => (props.isGroupHeader ? '0.5rem' : '1rem')};
  font-size: ${(props) => (props.isGroupHeader ? '14px' : '13px')};
  font-weight: ${(props) => (props.isGroupHeader ? 'bold' : 'normal')};

  display: grid;
  grid-template-columns: auto 20px;
  overflow: hidden;

  color: #444;

  align-items: center;

  span {
    margin-right: 0.5rem;

    &:last-child {
      margin-top: 9px;
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
