import { Component } from 'react';
import PropTypes from 'prop-types';

import SingleFilterDrop from './CandidatesFilters/SingleFilterDrop';
import { appStatusType } from '../../utils/enums';
import { getApplicationForViewByPosting } from 'utils/candidateUtils';
import LoadingSpinner from 'components/loadingSpinner';

import auth from 'utils/auth';
import { prospectStatus } from 'utils/prospectenums';
import { ATSCandidateListBulkEmail } from 'data-testids/ATS';

export default class CandidatesListFooter extends Component {
  static propTypes = {
    selections: PropTypes.any.isRequired,
    candidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    bulkEmail: PropTypes.func.isRequired,
    bulkExport: PropTypes.func.isRequired,
    bulkStatusUpdate: PropTypes.func,
    selectAllTotal: PropTypes.func.isRequired,
    totalPossibleSelections: PropTypes.number.isRequired,
    isRequisition: PropTypes.bool,
    newApplicationStatuses: PropTypes.array,
    // role is sent down if on View by Posting, because you can bulk update statuses
    role: PropTypes.object,
    loadingSpinnerFooter: PropTypes.bool.isRequired,
    availableApplicationStatuses: PropTypes.array,
    selectedApplications: PropTypes.object,
  };

  getStatusOptions = () => {
    /**
     * Get status options to show for bulk status update.
     * Mostly copied from StatusView/index.js getStatusOptions function. There is some
     * slightly different logic here because school admins can select both school
     * applications AND district applications to update within one request. The status
     * options should reflect statuses that would be available to ALL applications
     * being updated as if they were being moved separately.
     */
    let statusOptions = [];

    if (this.props.onProspectList) {
      return prospectStatus();
    }

    // If we are on the view by posting page:
    if (this.props.isRequisition) {
      if (this.props.newApplicationStatuses.length === 0) {
        return [];
      }

      // can't move status to draft
      statusOptions = this.props.newApplicationStatuses.filter(
        (s) => s.status_type !== appStatusType.draft
      );

      // statuses must be available for role's custom workflow
      const availableStatusIds = this.props.role?.statuses_available.map((s) => s.id) ?? [];
      statusOptions = statusOptions.filter((status) => availableStatusIds.includes(status.id));

      if (auth.isSchoolUser()) {
        // School admins can only move district applications to pre-hiring pool statuses if
        // designated as move_access, or to hiring pool and archived statuses if designated
        // as move_district.
        // School admins can only move school applications to post SHP statuses.
        const [containsSchoolApps, containsDistrictApps] = this.getApplicationTypes();
        if (containsDistrictApps) {
          statusOptions = statusOptions.filter(this.schoolAdminDistrictApplicationOptions);
        }
        if (containsSchoolApps) {
          const hiring_pool_order = this.getHiringPoolOrder();
          statusOptions = statusOptions.filter((status) => {
            return this.isMovableAndPostHiringPool(status, hiring_pool_order);
          });
        }
      } else {
        statusOptions = statusOptions.filter(this.districtAdminStatusOptions);
      }
    } else {
      // We are in the candidate view all page.  We have 'availableApplicationStatuses'
      // which is the information we need about each status.  And each candidate, has a list
      // of applications and within each, a role property with a status_available list of ids.
      let allRoleStatuses = []; // of ids
      let includesOnboardingStatuses = true;

      this.props.candidates.forEach((candidate) => {
        if (this.props.selections.has(candidate.id)) {
          candidate.applications.forEach((application) => {
            if (this.props.selectedApplications.has(application.id)) {
              let statusesAvailable = application.role.statuses_available;

              // If not hired or onboarding don't show those.
              if (
                ![appStatusType.hired, appStatusType.onboarding].includes(
                  application.new_status.status_type
                )
              ) {
                includesOnboardingStatuses = false;
              }

              // keep the overlapping ones:
              if (allRoleStatuses.length === 0) {
                allRoleStatuses = [...statusesAvailable];
              } else {
                allRoleStatuses = allRoleStatuses.filter((s) => statusesAvailable.includes(s));
              }
            }
          });
        }
      });
      allRoleStatuses = [...new Set(allRoleStatuses)];

      // Now we want the intersection of the availableApplicationStatuses, and the role ones:
      statusOptions = this.props.availableApplicationStatuses.filter((appStatus) => {
        return allRoleStatuses.includes(appStatus.id);
      });

      // We need to remove all onboarding statuses if at least one of the applications is not on
      // hired or onboarding.
      if (!includesOnboardingStatuses) {
        statusOptions = statusOptions.filter((appStatus) => {
          return appStatus.status_type !== appStatusType.onboarding;
        });
      }
    }

    return statusOptions;
  };

  getHiringPoolOrder = () => {
    const hiring_pool = this.props.newApplicationStatuses.find(
      (status) => status.status_type === appStatusType.hiring_pool
    );
    return hiring_pool._order;
  };

  getApplicationTypes = () => {
    const applications = this.getApplications();

    // return true if any selections are a school application
    let containsSchoolApps = applications.some(this.isSchoolApplication);

    // return true if any selections are a district application
    let containsDistrictApps = applications.some(this.isDistrictApplication);

    return [containsSchoolApps, containsDistrictApps];
  };

  getApplications = () => {
    const selectedCandidates = this.props.candidates.filter(this.candidateSelected);
    // getApplicationViewByPosting will return either the school application or
    // district application related to the role the user is viewing.
    const isSchoolUser = auth.isSchoolUser();
    const applications = selectedCandidates.map((candidate) => {
      return getApplicationForViewByPosting(candidate, isSchoolUser);
    });
    return applications;
  };

  getProspects = () => {
    return this.props.candidates.filter(this.candidateSelected);
  };

  candidateSelected = (candidate) => {
    return this.props.selections.has(candidate.id);
  };

  isDistrictApplication = (application) => {
    return !this.isSchoolApplication(application);
  };

  isSchoolApplication = (application) => {
    if (application.role.district_role) {
      return true;
    } else {
      return false;
    }
  };

  isMovableAndPostHiringPool = (status, hiring_pool_order) => {
    return status.school_admin_move_access && status._order >= hiring_pool_order;
  };

  schoolAdminDistrictApplicationOptions = (status) =>
    (status.status_type < appStatusType.hiring_pool && status.school_admin_move_access) ||
    ([appStatusType.hiring_pool, appStatusType.archived].includes(status.status_type) &&
      status.school_admin_move_district);

  districtAdminStatusOptions = (status) =>
    status.status_type === appStatusType.pre_hiring_pool ||
    status.status_type === appStatusType.hiring_pool ||
    status.status_type === appStatusType.archived;

  bulkStatusUpdate = (statusObj) => {
    if (this.props.onProspectList) {
      this.props.bulkStatusUpdate(statusObj, this.getProspects());
      return;
    }

    const applicationsBulkUpdate = this.getApplications().map((application) => {
      const application_type = this.isSchoolApplication(application) ? 'school' : 'district';
      // backend only needs id and application_type
      return { id: application.id, application_type };
    });

    this.props.bulkStatusUpdate(statusObj, applicationsBulkUpdate);
  };

  render() {
    const {
      bulkEmail,
      bulkExport,
      candidates,
      selections,
      selectAllTotal,
      totalPossibleSelections,
    } = this.props;

    let selectedCount = selections.size;
    if (!(selectedCount > 0)) {
      return <div className="cl-footer-container" />;
    }

    let statusOptions = this.getStatusOptions();

    const canShowBulkStatusButton =
      this.props.onProspectList ||
      this.props.isRequisition ||
      (!this.props.onProspectList && !this.props.isRequisition && auth.isDistrictUser());

    return (
      <div className={`cl-footer-container ${selectedCount > 0 ? 'expand' : ''}`}>
        <div className="cl-footer-middle-div">
          <div className="select-candidates-text-div flex">
            <div className="mr1">
              <div className="bold" data-testid={ATSCandidateListBulkEmail.BULK_EMAIL_COUNT}>
                {`${selectedCount} ${selectedCount === 1 ? 'Candidate' : 'Candidates'} Selected`}
              </div>
              {/* cl-page-size */}
              {selectedCount >= 15 && totalPossibleSelections > selectedCount && (
                <div onClick={selectAllTotal} className="clear-selections">
                  {`(Select all ${totalPossibleSelections} candidates)`}
                </div>
              )}
            </div>
            {this.props.loadingSpinnerFooter && <LoadingSpinner margin={0} fontSize={3} />}
          </div>
          <div>
            {/* only show bulk status update button if there are available statuses and
                there are not more selections than there are candidates loaded on the page
                (which you can do via the "Select all candidates" button above. If there
                are selections not yet on the page, we don't know which statuses they
                have available and if it's a district or school application.
            */}
            {selectedCount <= candidates.length && canShowBulkStatusButton && (
              <SingleFilterDrop
                bulkStatusUpdate={this.bulkStatusUpdate}
                statusOptions={statusOptions}
              />
            )}
            <button
              className="bulk-email-btn"
              onClick={bulkEmail}
              data-testid={ATSCandidateListBulkEmail.BULK_EMAIL_BUTTON}
            >
              Bulk Email
            </button>
            <button className="bulk-email-btn" onClick={bulkExport}>
              Export
            </button>
          </div>
        </div>
      </div>
    );
  }
}
