import styled from 'styled-components';
import { grid } from 'styled-system';

import { AltModal } from 'ui-kit';
import LoadingSpinner from '../../loadingSpinner';
import PropTypes from 'prop-types';

import ScorecardListCard from './ScorecardListCard';
import ScorecardRequests from '../scorecardrequests';
import { ATSCandidateScorecardDataTestIds } from 'data-testids/ATS';

const ScorecardListSection = ({ scorecards, ...rest }) => {
  const headerSection = !scorecards.length ? (
    <strong>There are no scorecards recorded yet.</strong>
  ) : (
    <h4>{`${scorecards.length} Scorecard${scorecards.length === 1 ? '' : 's'} Submitted`}</h4>
  );

  return (
    <div>
      <div data-testid={ATSCandidateScorecardDataTestIds.LIST_SECTION_HEADER}>{headerSection}</div>
      {/* split into two columns for large screens (> 990px) */}
      <ListGrid gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(2, 1fr)']}>
        {scorecards.map((scorecard) => (
          <ScorecardListCard key={scorecard.id} scorecard={scorecard} {...rest} />
        ))}
      </ListGrid>
    </div>
  );
};

const ScorecardListFooter = ({ onCreate, onCancel, showRequestSection, canCreateScorecards }) => (
  <FooterContainer
    gridTemplateColumns={['1fr', '1fr', '126px 388px']}
    data-testid={ATSCandidateScorecardDataTestIds.LIST_FOOTER}
  >
    <CancelButton onClick={onCancel}>Cancel</CancelButton>
    {canCreateScorecards && (
      <ActionButtonContainer gridTemplateColumns={['1fr', '1fr', 'repeat(2, 186px)']}>
        <RequestButton
          data-testid={ATSCandidateScorecardDataTestIds.LIST_REQUEST_BUTTON}
          onClick={showRequestSection}
        >
          + Scorecard Request
        </RequestButton>
        <SaveButton
          data-testid={ATSCandidateScorecardDataTestIds.LIST_CREATE_BUTTON}
          onClick={onCreate}
        >
          + New Scorecard
        </SaveButton>
      </ActionButtonContainer>
    )}
  </FooterContainer>
);

export default function ScorecardList({
  scorecards,
  requests,
  onView,
  onCreate,
  onRequest,
  onComplete,
  onCancel,
  loading,
  showRequestSection,
  updateScorecardsList,
  canCreateScorecards,
  dataTestId,
}) {
  return (
    <>
      <AltModal.Body data-testid={ATSCandidateScorecardDataTestIds.LIST}>
        {loading ? (
          <LoadingSpinner margin={3} />
        ) : (
          <>
            <ScorecardListSection
              scorecards={scorecards}
              onView={onView}
              onComplete={onComplete}
              updateScorecardsList={updateScorecardsList}
            />
            {requests.length > 0 && <ScorecardRequests requests={requests} onRequest={onRequest} />}
          </>
        )}
      </AltModal.Body>
      <ScorecardListFooter
        onCreate={onCreate}
        onCancel={onCancel}
        showRequestSection={showRequestSection}
        canCreateScorecards={canCreateScorecards}
      />
    </>
  );
}

ScorecardList.propTypes = {
  scorecards: PropTypes.arrayOf(PropTypes.object).isRequired,
  requests: PropTypes.arrayOf(PropTypes.object).isRequired,
  onView: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRequest: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  showRequestSection: PropTypes.func.isRequired,
};

const ListGrid = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    marginTop: '32px',
    marginBottom: '25px',
  },
  grid
);

const FooterContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  borderRadius: '3px',
  width: '100%',
});

const CancelButton = styled(FooterButton)({
  backgroundColor: '#FFFFFF',
  border: '1px solid #CACACA',
  color: '#CACACA',
});

const ActionButtonContainer = styled.div(
  {
    display: 'grid',
    justifyContent: 'end',
    gridGap: '16px',
  },
  grid
);

const RequestButton = styled(FooterButton)({
  backgroundColor: '#CACACA',
  border: '1px solid #CACACA',
  color: '#FFFFFF',
});

const SaveButton = styled(FooterButton)({
  backgroundColor: '#00B88D',
  border: '1px solid #00B88D',
  color: '#FFFFFF',
});
