import { SettingsRow } from 'components/SettingsRow';
import PropTypes from 'prop-types';
import { ViewButton } from 'sharedComponents/Buttons';

import { Box } from '@mui/material';
import { questionSetItemMapping } from './enums';
import { getQuestionCounts } from './utils';
import styled from '@emotion/styled';

const Row = ({ questionSet, setQuestionSetToEdit, openQuestionSetModal }) => (
  <SettingsRow
    onClick={() => {
      setQuestionSetToEdit(questionSet);
      openQuestionSetModal();
    }}
    cta={<ViewButton />}
  >
    <ScorecardTitle>{questionSet.title}</ScorecardTitle>
    <QuestionType>{getQuestionCounts(questionSet.items, questionSetItemMapping)}</QuestionType>
  </SettingsRow>
);

export default Row;

Row.propTypes = {
  questionSet: PropTypes.object.isRequired,
};

const Section = styled(Box)({
  flex: '0 0 20%',
  paddingRight: '16px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const ScorecardTitle = styled(Section)({
  flex: '0 0 32%',
  fontSize: '15px',
});

const QuestionType = styled(ScorecardTitle)({
  color: '#909090',
});
