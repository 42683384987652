import { Theme, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ValuePropRowProps } from '../types';

export const ValuePropRow = ({
  valuePropImage,
  iconImage,
  imageLeft,
  valuePropText,
  accentColor,
}: ValuePropRowProps): React.ReactElement => {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <ValuePropRowContainer>
      {imageLeft && valuePropImage()}
      <StyledTextIconBox
        sx={{ border: `${isLargeScreen ? '2.78px' : '1.71px'} solid ${accentColor}` }}
      >
        <IconBox>{iconImage()}</IconBox>
        <ValuePropTextTypography>{valuePropText}</ValuePropTextTypography>
      </StyledTextIconBox>
      {!imageLeft && valuePropImage()}
    </ValuePropRowContainer>
  );
};

const ValuePropRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px',
  width: '80%',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const IconBox = styled(Box)(({ theme }) => ({
  width: '50px',
  marginLeft: '5px',
  paddingRight: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    width: '17.314px',
    height: '17.314px',
  },
}));

const StyledTextIconBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FEFEFF',
  borderRadius: '11.11px',
  minHeight: '39px',
  display: 'flex',
  alignItems: 'center',
  paddingY: theme.spacing(1),
  paddingX: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    height: '70px',
    padding: theme.spacing(1),
  },
}));

const ValuePropTextTypography = styled(Typography)(({ theme }) => ({
  color: '#1D2939',
  padding: '5px',
  fontSize: '16px',

  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '11px',
  },
}));
