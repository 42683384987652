import { Component } from 'react';
import { notify } from 'react-notify-toast';

import PropTypes from 'prop-types';
import CustomProfileFieldRow from './CustomProfileFieldRow';
import CustomFieldModal from './CustomFieldModal';
import { showWarning } from '../../utils/message';
import { customFieldPermission } from '../../utils/enums';
import { myColor, myColorWarning } from 'utils/message';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import auth from '../../utils/auth';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';

const reorder = (list, startIndex, endIndex) => {
  const result = list.map(({ id }) => {
    return id;
  }); // Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // update state
  const r = Array.from(list);
  const [rem] = r.splice(startIndex, 1);
  r.splice(endIndex, 0, rem);
  return [{ orders: result }, r];
};

const fieldPermission = customFieldPermission().reduce((obj, item) => {
  obj[item.label] = item.value;
  return obj;
}, {});

export default class CustomProfileFieldsList extends Component {
  constructor(props) {
    super(props);
    this.show = notify.createShowQueue();
    this.state = {
      custom_profile_fields: [],
      showModal: false,
    };
    this.districtId = auth.getUser().profile.district.id;
  }

  static propTypes = {
    adminUsers: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.fetchCustomFields();
  }

  fetchCustomFields = () => {
    axios
      .get('/api/customfields/')
      .then((r) => {
        this.setState({ custom_profile_fields: r.data });
      })
      .catch(() =>
        showWarning(
          'There was an error retrieving the custom profile fields. Please contact support@hirenimble.com for assistance.',
          20000
        )
      );
  };

  freshCustomField = () => {
    return {
      id: '',
      district: this.districtId,
      title: '',
      visibility_qv: false,
      visibility_fp: true,
      super_admin_access: fieldPermission['None'],
      district_admin_access: fieldPermission['None'],
      district_user_access: fieldPermission['None'],
      school_admin_access: fieldPermission['None'],
      individual_users_view_only: [],
      individual_users_edit_and_view: [],
      field_type: 4,
      multichoice_options: [],
    };
  };

  onSave = (fieldObj, index = null) => {
    let method;
    let url;
    if (index !== null) {
      // Updating an existing fieldObj.
      method = 'put';
      url = `/api/customfields/${this.state.custom_profile_fields[index].id}/`;
    } else {
      // Saving a new fieldObj.
      method = 'post';
      url = '/api/customfields/';
    }

    let fieldObjCopy = Object.assign({}, fieldObj);
    fieldObjCopy.individual_users_view_only = fieldObj.individual_users_view_only.map((u) => u.id);
    fieldObjCopy.individual_users_edit_and_view = fieldObj.individual_users_edit_and_view.map(
      (u) => u.id
    );

    axios[method](url, fieldObjCopy)
      .then((r) => {
        if (r.status === 200) {
          this.fetchCustomFields();
          this.show('Custom field updated successfully', 'custom', 2000, myColor);
        } else if (r.status === 201) {
          this.fetchCustomFields();
          this.show('Custom field created successfully', 'custom', 2000, myColor);
        }
      })
      .catch((error) => {
        if (error.response.data) {
          const nonFieldErrors = error.response.data['non_field_errors'];
          if (nonFieldErrors && nonFieldErrors[0].includes('unique')) {
            this.show(
              'A custom profile field already exists with this title.',
              'custom',
              5000,
              myColorWarning
            );
          } else {
            this.show(
              'There was an error updating this field. Please contact support@hirenimble.com for assistance.',
              'custom',
              5000,
              myColorWarning
            );
          }
        }
      });
    this.setState({ showModal: false });
  };

  onDelete = (id) => {
    axios
      .delete(`/api/customfields/${id}/`)
      .then((r) => {
        if (r.status === 204) {
          this.show('Custom field deleted successfully', 'custom', 2000, myColor);
        }
      })
      .catch(() =>
        this.show(
          'There was an error deleting this item. Please contact support@hirenimble.com for assistance.',
          'custom',
          5000,
          myColorWarning
        )
      );

    // make update on front end instantly.
    let custom_profile_fields = this.state.custom_profile_fields;
    let index = custom_profile_fields.findIndex((t) => t.id === id);
    if (index !== -1) {
      custom_profile_fields.splice(index, 1);
    }
    this.setState({ custom_profile_fields });
  };

  onReorder = async (result) => {
    const callReorder = async (result) => {
      let method = 'put';
      let url = '/api/customfields/reorder/';
      try {
        const res = await axios[method](url, result);
        if (res.status === 200) {
          this.show('Re-ordered successfully', 'custom', 2000, myColor);
        }
      } catch (error) {
        this.show(
          'There was an error reordering fields. Please contact support@hirenimble.com for assistance.',
          'custom',
          5000,
          myColorWarning
        );
      }
    };
    await callReorder(result);
    this.fetchCustomFields();
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (
      result.source.index === result.destination.index &&
      result.source.droppableId === result.destination.droppableId
    ) {
      return;
    }
    const reordering = reorder(
      this.state.custom_profile_fields,
      result.source.index,
      result.destination.index
    );
    const reorderedItems = reordering[0];
    const new_cpf = reordering[1];
    this.setState({ custom_profile_fields: new_cpf });
    this.onReorder(reorderedItems);
  };

  render() {
    return (
      <div className="application-status-container custom-fields">
        <div className="mt2">
          <AnchorTagHeader
            onClick={() => this.setState({ showModal: true })}
            title="Custom Profile Fields"
            buttonText="+ New Profile Field"
            id="customProfileFields"
          />
          {this.state.custom_profile_fields.length > 0 && (
            <div className="cp-header">
              <span className="cp-title">Title</span>
              <span className="cp-shown-on">Shown On</span>
              <span className="cp-edit">Edit Access</span>
              <span className="cp-view">View Access</span>
            </div>
          )}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="cpfs">
              {(droppableProvided) => (
                <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {this.state.custom_profile_fields.map((fieldObj, i) => (
                    <Draggable key={fieldObj.id} draggableId={fieldObj.id.toString()} index={i}>
                      {(draggableProvided) => (
                        <div
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.dragHandleProps}
                        >
                          <CustomProfileFieldRow
                            key={i}
                            index={i}
                            fieldObj={fieldObj}
                            onSave={this.onSave}
                            onDelete={this.onDelete}
                            adminUsers={this.props.adminUsers}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {this.state.showModal && (
          <CustomFieldModal
            fieldObj={this.freshCustomField()}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            onSave={this.onSave}
            adminUsers={this.props.adminUsers}
          />
        )}
      </div>
    );
  }
}
