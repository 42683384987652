import { BaseNimbleAPI } from './baseAPI';
import { schoolStatusType } from 'utils/enums';

class SchoolsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/school' };
  }

  /**
   * Fetch a school by id
   */
  async fetchSchool(schoolId) {
    const url = `/${schoolId}/`;
    const response = await this.get(url);
    return response.data;
  }

  /**
   * Returns a list of all of the active schools in the specified district.
   * If no district is specified, the users district is used.
   */
  async fetchActiveSchools(districtId = undefined) {
    const params = {
      district_id: districtId,
      status: schoolStatusType.active,
    };

    const url = '/';
    const response = await this.get(url, { params });
    return response.data;
  }

  async fetchAllSchools(districtId = undefined) {
    const params = {
      district_id: districtId,
    };

    const url = '/';
    const response = await this.get(url, { params });
    return response.data;
  }

  async fetchSchoolsByState(state) {
    const params = { state };
    const url = '/by_state/';
    const response = await this.get(url, { params });
    return response.data;
  }

  async updateSchool(schoolId, data) {
    const url = `/${schoolId}/`;
    const response = await this.put(url, data);
    return response.data;
  }
}

export default new SchoolsAPI();
