import useSWR from 'swr';

import applicationStatusesAPI from 'api/applicationStatusesAPI';
import { appStatusType } from 'utils/enums';

const ENDPOINT = '/api/application_statuses';

const defaultData = [];

/**
 * Data management wrapper for fetching a districts ApplicationStatuses.
 *
 * Usage: const { data, isLoading, error } = useApplicationStatuses({ districtId, statusTypes });
 */
export const useApplicationStatuses = ({
  districtId,
  statusTypes = Object.values(appStatusType),
}) => {
  const key = [ENDPOINT, districtId, statusTypes.toString()];
  const options = { dedupingInterval: 15000, focusThrottleInterval: 15000 };

  const { data, error } = useSWR(
    key,
    (key, districtId) => applicationStatusesAPI.getAll({ districtId, statusTypes }),
    options
  );

  const isLoading = data === undefined;

  return { data: data ?? defaultData, isLoading, error };
};
