import { useState } from 'react';
import styled from 'styled-components';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ReactComponent as SearchIcon } from 'assets/icon_search_glass.svg';

export default function TaskFilter({
  taskOptions,
  templateOptions,
  options,
  values,
  selectAll,
  clearAll,
  isActive,
  handleChange,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  let filteredOptions;
  if (options === undefined) {
    filteredOptions = [];
  } else if (!searchValue) {
    filteredOptions = options;
  } else {
    filteredOptions = options.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const toggleOptions = () => setShowOptions((prev) => !prev);

  const getCaret = () => {
    if (isActive) {
      if (showOptions) {
        return iconUpCaretGreen;
      } else {
        return iconDownCaretGreen;
      }
    } else {
      if (showOptions) {
        return iconUpCaret;
      } else {
        return iconDownCaret;
      }
    }
  };

  const selectAllTasks = () => {
    const allUniversalTaskOptions = [];
    const allHellosignTaskOptions = [];
    taskOptions.forEach((option) => {
      allUniversalTaskOptions.push({ value: String(option.id) });
      allUniversalTaskOptions.push({ value: `-${option.id}` });
    });
    templateOptions.forEach((option) => {
      allHellosignTaskOptions.push({ value: option.template_id });
      allHellosignTaskOptions.push({ value: `-${option.template_id}` });
    });
    selectAll('task_list', allUniversalTaskOptions);
    selectAll('hellosign_templates', allHellosignTaskOptions);
  };

  const clearAllTasks = () => {
    clearAll('task_list');
    clearAll('hellosign_templates');
  };

  return (
    <FilterContainer isActive={isActive}>
      <div className="cl-filter-header" onClick={toggleOptions}>
        <p>Tasks</p>
        <img src={getCaret()} alt="" />
      </div>
      <div className={`relative selection-list ${showOptions ? 'show' : ''}`}>
        {selectAll && clearAll && (
          <div className="option select-all">
            <span className="drop-select-all" onClick={selectAllTasks}>
              Select All
            </span>
            <span className="drop-select-all" onClick={clearAllTasks}>
              Clear All
            </span>
          </div>
        )}
        <InputFilterContainer>
          <InputFilter
            type="text"
            placeholder=""
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {searchValue ? (
            <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
          ) : (
            <PositionedSearchIcon />
          )}
        </InputFilterContainer>
        {filteredOptions.map((option) => {
          const isActive = values.includes(option.value);
          const optionsLength =
            option.fieldName === 'task_list' ? taskOptions.length * 2 : templateOptions.length * 2;
          return (
            <div key={option.value + ' ' + option.fieldName} className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={isActive}
                  onChange={() => {
                    handleChange(option.fieldName, option.value, isActive, optionsLength);
                  }}
                />
                <span className="checkmark" />
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </FilterContainer>
  );
}

const FilterContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  border-top: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  font-size: 13px;
  border-right: ${({ isActive }) => (isActive ? '2px solid #00B88D' : '')};

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid #dfdfdf;
  }

  ${({ isActive }) => (isActive ? 'p { color: #00B88D; }' : '')}

  .cl-filter-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 0;
  }

  .selection-list {
    display: none;

    &.show {
      display: block;
    }

    .option {
      cursor: pointer;
      color: rgba(57, 60, 73, 0.8);
      font-size: 13px;

      &.indented {
        padding-left: 28px;
      }

      &:first-child {
        padding-top: 8px;
      }

      &:last-child {
        padding-bottom: 8px;
      }

      .container {
        display: block;
        position: relative;
        padding-left: 26px;
        margin-bottom: 8px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: normal;

        /* Hide the browser's default checkbox */
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 1px;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: white;
          border: 1px solid #dfdfdf;

          /* Create the checkmark/indicator (hidden when not checked) */
          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
          background-color: #dfdfdf;
        }

        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
          background-color: #00b88d;
        }

        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
          display: block;
        }

        .status-circle {
          height: 9px;
          width: 9px;
          min-width: 9px;
        }
      }

      &.select-all {
        padding: 0.5rem 0;
      }

      .drop-select-all {
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }
`;

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 0.5rem;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 15px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;
