import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'ui-kit';

export default function FilterValueTags({ actions, filterValues, activeFilters, error }) {
  const [showingError, setShowingError] = useState(false);
  const [errorField, setErrorField] = useState(false);

  useEffect(() => {
    if ([414, 400].includes(error?.response?.status)) {
      setShowingError(true);
      error?.filterName === 'schoolIds' ? setErrorField('schools') : setErrorField('organizations');
      setTimeout(() => {
        setShowingError(false);
      }, 4000);
    }
  }, [error]);

  return (
    <FilterTagsContainer>
      {activeFilters.subcategoryStateIds && (
        <FilterTag>
          <FilterTagsText>
            {filterValues.subcategoryStateIds.length === 1
              ? '1 Job category'
              : 'Multiple job categories'}
          </FilterTagsText>
          <FilterTagsRemoveButton onClick={actions.setDefaultSubcategoryStateIds}>
            x
          </FilterTagsRemoveButton>
        </FilterTag>
      )}

      {!showingError && activeFilters.districtIds && (
        <FilterTag>
          <FilterTagsText>
            {filterValues.districtIds.length === 1 ? '1 Organization' : 'Multiple organizations'}
          </FilterTagsText>
          <FilterTagsRemoveButton onClick={actions.setDefaultDistrictIds}>x</FilterTagsRemoveButton>
        </FilterTag>
      )}

      {activeFilters.grades && (
        <FilterTag>
          <FilterTagsText>
            {filterValues.grades.length === 1 ? '1 Grade' : 'Multiple grades'}
          </FilterTagsText>
          <FilterTagsRemoveButton onClick={actions.setDefaultGrades}>x</FilterTagsRemoveButton>
        </FilterTag>
      )}

      {activeFilters.fulltime && (
        <FilterTag>
          <FilterTagsText>
            {filterValues.fulltime[0] === 1 ? 'Full-time' : 'Part-time'}
          </FilterTagsText>
          <FilterTagsRemoveButton onClick={actions.setDefaultEmploymentTypes}>
            x
          </FilterTagsRemoveButton>
        </FilterTag>
      )}

      {activeFilters.startDate && (
        <>
          {filterValues.startDate.includes('immediate') && (
            <FilterTag>
              <FilterTagsText>Immediate start</FilterTagsText>
              <FilterTagsRemoveButton
                onClick={() => actions.removeStartDateFilterValue('immediate')}
              >
                x
              </FilterTagsRemoveButton>
            </FilterTag>
          )}
          {filterValues.startDate.includes('next_school_year') && (
            <FilterTag>
              <FilterTagsText>Next school year</FilterTagsText>
              <FilterTagsRemoveButton
                onClick={() => actions.removeStartDateFilterValue('next_school_year')}
              >
                x
              </FilterTagsRemoveButton>
            </FilterTag>
          )}
          {filterValues.startDate.includes('before') && (
            <FilterTag>
              <FilterTagsText>Starts before date</FilterTagsText>
              <FilterTagsRemoveButton onClick={() => actions.removeStartDateFilterValue('before')}>
                x
              </FilterTagsRemoveButton>
            </FilterTag>
          )}
          {filterValues.startDate.includes('after') && (
            <FilterTag>
              <FilterTagsText>Starts after date</FilterTagsText>
              <FilterTagsRemoveButton onClick={() => actions.removeStartDateFilterValue('after')}>
                x
              </FilterTagsRemoveButton>
            </FilterTag>
          )}
        </>
      )}

      {!showingError && activeFilters.schoolIds && (
        <FilterTag>
          <FilterTagsText>
            {filterValues.schoolIds.length === 1 ? '1 School' : 'Multiple schools'}
          </FilterTagsText>
          <FilterTagsRemoveButton onClick={actions.setDefaultSchoolIds}>x</FilterTagsRemoveButton>
        </FilterTag>
      )}

      {showingError && errorField === 'organizations' && (
        <YellowErrorText>
          Try narrowing your search to fewer than 100 organizations.
        </YellowErrorText>
      )}
      {showingError && errorField === 'schools' && (
        <YellowErrorText>
          Try narrowing your search to fewer than 100 schools, or filter by organization instead.
        </YellowErrorText>
      )}
    </FilterTagsContainer>
  );
}

const FilterTagsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  // One column for mobile
  grid-template-columns: 1fr;

  // Two columns for tablets and small laptops
  @media screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  // Switch to flex with row wrap for large screens so
  // the elements auto-wrap
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
  }
`;

const FilterTag = styled.div`
  background-color: rgba(254, 215, 32, 1);
  border-radius: 3px;
  color: rgba(21, 30, 71, 1);
  display: grid;
  grid-template-columns: 1fr 20px;
  height: 30px;
  min-width: 180px;
`;

const FilterTagsRemoveButton = styled.span`
  display: inline-block;
  cursor: pointer;
  padding: 5px;
`;

const FilterTagsText = styled.span`
  display: inline-block;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const YellowErrorText = styled.span`
  color: rgba(254, 215, 32, 1);
  display: inline-block;
  font-size: 13px;
  font-style: italic;

  padding: 5px;
  white-space: wrap;
`;
