import ProfilePreferenceProgressBar from './ProfilePreferenceProgressBar';
import { ATSCandidatePreferencesDataTestIds } from '../../../data-testids/ATS';

export default function Header({ step }: { step: number }): React.ReactElement {
  return (
    <div className="header-section">
      <h2
        className="darker-text"
        data-testid={ATSCandidatePreferencesDataTestIds.COMPLETE_PROFILE_TEXT}
      >
        Complete your profile
      </h2>
      <div className="header-section-long-text">
        {`You'll only have to complete this information once, and it will be saved for each job you apply to!`}
      </div>
      <ProfilePreferenceProgressBar step={step} />
    </div>
  );
}
