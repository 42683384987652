import useSWR from 'swr';

import dashboardMetricsAPI from 'api/dashboardMetricsAPI';

const ENDPOINT = '/api/dashboard-metrics/onboarding-conversions/';

const defaultData = {
  hired_applications: {},
};

/**
 * Data management wrapper for fetching/caching conversion metrics.
 *
 * Usage: const { data, isLoading, error } = useOnboardingConversionMetrics(filters);
 */
export const useOnboardingConversionMetrics = ({
  hiringSeasons,
  schools = [],
  subcategories = [],
  genders = [],
  ethnicities = [],
}) => {
  // Using ref/memo doesn't work for this library... need to stringify our deps.
  const key = [
    ENDPOINT,
    hiringSeasons.toString(),
    schools.toString(),
    subcategories.toString(),
    genders.toString(),
    ethnicities.toString(),
  ];
  const options = { dedupingInterval: 30000, focusThrottleInterval: 30000 };

  const { data, error } = useSWR(
    key,
    () =>
      dashboardMetricsAPI.getOnboardingConversionMetrics({
        hiringSeasons,
        schools,
        subcategories,
        genders,
        ethnicities,
      }),
    options
  );

  const isLoading = data === undefined;

  return { data: data ?? defaultData, isLoading, error };
};
