import { ChangeEvent } from 'react';
import auth from '../../../../utils/auth';
import { Input } from 'ui-kit';
import { Label, Container } from './TabsShareComponents';
import ErrorText from '../../../../components/errortext';
import LocationsSelect from './sharedComponents/LocationsSelect';
import RoleTypeSelector from '../../../../components/JobEdit/RoleTypeSelector';
import VisibilitySelector from '../../../../components/JobEdit/VisibilitySelector';
import CandidatesListFilterDrop from '../../../../ui-kit/ListFilterDropdown';
import { appStatusType } from '../../../../utils/enums';
import { Job, ApplicationStatus, Jobboard } from 'types/types';
import { StartTabProps, JobboardOptions } from '../../types/types';

import styled from 'styled-components';
import { getStatusesDisplayText } from 'utils/roleutils';

export interface IInput {
  [key: string]: any;
}

const StyledInput = styled(Input)<IInput>``;

export default function StartTab({
  job,
  jobBoardOptions,
  setJob,
  applicationStatuses,
  errors,
  updateField,
  roleType,
  setRoleType,
  schoolsSelected,
  setSchoolsSelected,
  schoolOptions,
  setErrors,
  apiResponseErrors,
}: StartTabProps): React.ReactElement {
  let nonDraftJobStatuses: ApplicationStatus[] = [];
  if (job.statuses_available) {
    nonDraftJobStatuses = job.statuses_available.filter(
      (status) => status.status_type !== appStatusType.draft
    );
  }

  const selectAllStatuses = () => {
    const jobCopy = { ...job };
    jobCopy['statuses_available'] = applicationStatuses;
    setJob(jobCopy);
  };

  const clearAllStatuses = () => {
    const jobCopy = { ...job };
    // Remove all except Hired status
    jobCopy['statuses_available'] = applicationStatuses.filter((a) => a.status_type === 4);
    setJob(jobCopy);
  };

  const updateStatusesAvailable = (statusId: number) => {
    const jobCopy: Job = { ...job };
    const existing_statuses_available = [...job.statuses_available];

    if (job.statuses_available.map((s) => s.id).includes(statusId)) {
      // if statuses_available already includes the selected status, the user is
      // removing that status, so filter it out.
      jobCopy.statuses_available = existing_statuses_available.filter((s) => s.id !== statusId);
    } else {
      // Otherwise the user is adding the status. Find it in applicationStatuses and add.
      const newStatus = applicationStatuses.find((s) => s.id === statusId);
      if (!newStatus) throw new Error('No status found');
      jobCopy.statuses_available = [...existing_statuses_available, newStatus];
    }
    setJob(jobCopy);
  };

  const updateJobBoard = (e: ChangeEvent<HTMLInputElement>) => {
    // Remove other options
    const value = Number(e.target.value);
    const addValue = !job.jobboards.find((j) => j.id === value);
    const updateJob = job;
    const updateJobBoards: Jobboard[] = [];
    // Add the option checked
    if (addValue) {
      updateJobBoards.push({ id: value });
    } else {
      // If no others are checked, the default jobboard must be checked
      const defaultJobboard = jobBoardOptions.filter(
        (j) => j.slug === auth.getUser().district.slug
      )[0];

      if (!defaultJobboard) throw new Error('No default jobboard found');

      updateJobBoards.push({ id: defaultJobboard.id });
    }
    updateJob.jobboards = updateJobBoards;
    setJob(updateJob);
  };

  const shortenTitles = (jb: JobboardOptions) => {
    const title = jb.title;
    if (title === 'American Leadership Academy') {
      return 'ALA';
    } else if (title === 'Signature Preparatory') {
      return 'Signature Prep';
    } else if (title === 'Wake Preparatory Academy') {
      return 'Wake Preparatory';
    } else {
      return jb.internal_title ? jb.internal_title : jb.title;
    }
  };

  const duplicateTitleError = apiResponseErrors?.duplicate_title.toString();

  return (
    <Container>
      <div>
        <Label>{job.is_template ? 'Template Name*' : 'Job Title*'}</Label>
        <StyledInput
          name="title"
          type="text"
          placeholder={job.is_template ? 'Name your template' : 'eg. 2nd Grade Teacher'}
          value={job.title || ''}
          onChange={updateField}
          hasError={errors.title || apiResponseErrors?.duplicate_title}
          width={1}
          required
          autoFocus
        />
        {errors.title && <ErrorText message={'Must provide a title.'} />}
        {apiResponseErrors?.duplicate_title && <ErrorText message={duplicateTitleError} />}
        {schoolsSelected && auth.isDistrictAdmin() && auth.inPostingOnlyDistrict() && (
          <LocationsSelect
            job={job}
            setJob={setJob}
            errors={errors}
            setErrors={setErrors}
            schoolOptions={schoolOptions}
            schoolsSelected={schoolsSelected}
            setSchoolsSelected={setSchoolsSelected}
          />
        )}{' '}
        {!auth.inPostingOnlyDistrict() && (
          <>
            <RoleTypeSelector
              job={job}
              updateField={updateField}
              roleType={roleType}
              setRoleType={setRoleType}
              hasError={errors?.role_type}
            />
            <VisibilitySelector
              job={job}
              updateField={updateField}
              district={auth.getUser().profile.district}
            />
          </>
        )}
        {!auth.inPostingOnlyDistrict() && (
          <div className="statuses-available-section">
            <Label className="inline-block mr1">Status Workflow</Label>
            <CandidatesListFilterDrop
              displayName={getStatusesDisplayText(nonDraftJobStatuses, applicationStatuses)}
              options={applicationStatuses}
              values={job.statuses_available}
              placeholder="Candidate Statuses"
              updateMultiSelectFilter={updateStatusesAvailable}
              fieldName="statuses_available"
              selectAll={selectAllStatuses}
              clearAll={clearAllStatuses}
              isTemplateOrRolePage={true}
            />
            <ErrorText message={errors.subjects} />
          </div>
        )}
      </div>

      {!auth.inPostingOnlyDistrict() && jobBoardOptions.length > 1 && (
        <div>
          <Label>Publish to Job Board</Label>
          <div className="publish-externally jobboards">
            <div className="flex">
              {jobBoardOptions.map((jb) => {
                return (
                  <label className="mr1" key={jb.id}>
                    <input
                      type="checkbox"
                      name="jobboard"
                      value={Number(jb.id)}
                      checked={job.jobboards.find((j) => j.id === jb.id) ? true : false}
                      onChange={updateJobBoard}
                      autoFocus
                    />
                    <span>{shortenTitles(jb)}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}
