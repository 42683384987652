import styled from 'styled-components';
import { grid } from 'styled-system';

import auth from 'utils/auth';
import RemindSection from './RemindSection';

export default function SignerRow({ signer }) {
  const getStatusLabel = () => {
    switch (signer.status_code) {
      case 'ready_to_sign':
        return 'Ready to sign';
      case 'awaiting_signature':
        return 'Pending';
      case 'signed':
        return 'Signed';
      default:
        break;
    }
  };

  const gridColumns = auth.isDistrictAdmin() ? '3' : '2';

  const signerName = signer.signer_name.replace('&#39;', "'");

  return (
    <SignerContainer
      key={signer.signature_id}
      gridTemplateColumns={[
        `0 repeat(${gridColumns}, 1fr)`,
        `0 repeat(${gridColumns}, 1fr)`,
        `0.25fr 2fr 2fr ${auth.isDistrictAdmin() ? '30px' : ''}`,
      ]}
    >
      <SignerName>{signerName}</SignerName>
      <Status>{getStatusLabel()}</Status>
      {auth.isDistrictAdmin() && <RemindSection signer={signer} />}
    </SignerContainer>
  );
}

const LightText = styled.p`
  font-weight: 400;
  font-size: 15px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SignerContainer = styled.div`
  ${grid}

  display: grid;
  align-items: center;

  &:last-child {
    margin-bottom: 12px;
  }
`;

const SignerName = styled(LightText)`
  grid-column: 2 / span 1;
`;

const Status = styled(LightText)`
  grid-column: 3 / span 1;

  font-style: italic;
`;
