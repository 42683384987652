import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default class DestroySchoolapplicationsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    move: PropTypes.func.isRequired,
    moveValues: PropTypes.object.isRequired,
  };

  render() {
    const { show, onHide } = this.props;
    const { index, vector } = this.props.moveValues;

    return (
      <Modal
        show={show}
        onHide={onHide}
        bsSize="large"
        dialogClassName="work-experience-reminder-modal"
      >
        <Modal.Header>
          <div className="modal-title">
            <h3>Are you sure?</h3>
          </div>
          <span className="copy-app-modal-close" onClick={onHide}>
            (X) CLOSE
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-section">
            <div>
              If you move this status before hiring pool, all existing school applications and
              statuses will be deleted. Are you sure you want to proceed?
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button className="mr2" onClick={() => onHide()}>
                Nevermind
              </button>
              <button
                className="mr3"
                onClick={(e) => {
                  this.props.move(index, vector, e, true);
                  onHide();
                }}
              >
                Move anyway
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
