import { BaseNimbleAPI } from './baseAPI';

class referenceCheckAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/referencechecks' };
  }

  async create(reference) {
    const response = await this.post('/', reference);
    return response.data;
  }

  async fetch(referenceId) {
    const response = await this.get(`/${referenceId}/`);
    return response.data;
  }

  async update(reference) {
    const response = await this.put(`/${reference.id}/`, reference);
    return response.data;
  }

  async destroy(referenceId) {
    const response = await this.delete(`/${referenceId}/`);
    return response.data;
  }

  async createAttachment(referenceCheckId, formData) {
    const response = await this.post(`/${referenceCheckId}/upload_attachment/`, formData);
    return response.data;
  }

  async setAnswerOrder(referenceCheck) {
    const data = {
      order: referenceCheck.answers.map((a) => a.id),
    };
    const response = await this.post(`/${referenceCheck.id}/set_answer_order/`, data);
    return response.data;
  }
}

export default new referenceCheckAPI();
