import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorText from '../../errortext';

function ModalFooter({
  cancel,
  save,
  deleteFunction,
  errorMessage,
  deleteButtonLabel,
  cancelButtonLabel,
  saveButtonLabel,
}) {
  return (
    <FooterContainer>
      <div>
        {cancel && <ModalButton onClick={cancel}>{cancelButtonLabel || 'Cancel'}</ModalButton>}
        {deleteFunction && (
          <ModalButton onClick={deleteFunction}>{deleteButtonLabel || 'Delete'}</ModalButton>
        )}
      </div>
      <TextCenterFlexDiv>
        <ErrorTextContainer>
          <ErrorText message={errorMessage} />
        </ErrorTextContainer>
        <ModalSaveButton onClick={save}>{saveButtonLabel || 'Save'}</ModalSaveButton>
      </TextCenterFlexDiv>
    </FooterContainer>
  );
}

ModalFooter.propTypes = {
  errorMessage: PropTypes.string,
  cancel: PropTypes.func,
  save: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func,
  deleteButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  saveButtonLabel: PropTypes.string,
};

export default ModalFooter;

const FooterContainer = styled.div`
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TextCenterFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalButton = styled.button`
  height: 50px;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0px 42px;
  color: #c2c2c2;
  font-size: 16px;
  margin: 1rem;
  background-color: transparent;
`;

const ModalSaveButton = styled(ModalButton)`
  background-color: #00b88d;
  color: white;
  border: none;
`;

const ErrorTextContainer = styled.div`
  margin-right: 1rem;
`;
