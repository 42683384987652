import { ResponsiveContainer, Cell, PieChart, Pie, Tooltip } from 'recharts';
import { CHART_COLOR_PALETTES } from '../../PipelineMetricsDashboard/constants';
import styled from 'styled-components';
let colors = CHART_COLOR_PALETTES[8];

export const PieChartComponent = ({ dataset, total, useVacancyAsTotal }) => {
  return (
    <ResponsiveContainer height={350} width={400}>
      <PieChart>
        <Pie
          data={dataset}
          innerRadius={'65%'}
          outerRadius={'90%'}
          paddingAngle={0}
          startAngle={225}
          endAngle={225 - 360}
          dataKey={useVacancyAsTotal ? 'vacancy_count' : 'value'}
        >
          {dataset.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip total={total} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = ({ payload, total }) => {
  for (const bar of payload) {
    return (
      <ToolTipContainer>
        {bar.name}
        <br />
        <StyledPercentage>{Math.ceil((bar.value / total) * 1000) / 10}%</StyledPercentage>
      </ToolTipContainer>
    );
  }
  return null;
};

const ToolTipContainer = styled.div`
  background-color: #ffffff;
  min-width: 73px;
  height: 58px;
  padding: 8px 18px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
`;

const StyledPercentage = styled.div`
  color: #00b88d;
`;
