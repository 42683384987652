import { Box, styled } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { nimbleTheme } from 'theme';
import { BulletPoint } from 'ui-kit/icons';
import { ExistingNotesProps, NoteRowProps } from './types';
import { DeletionModal } from 'sharedComponents/Modal/DeletionModal';
import auth from 'utils/auth';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { ATSInternalCandidateNoteDataTestIds } from 'data-testids/ATS';

export const ExistingNotes: React.FC<ExistingNotesProps> = ({
  extended,
  notes,
  userId,
  handleDelete,
  deletionModalRef,
}) => {
  const [openedModalId, setOpenedModalId] = useState<number | null>(null);

  const sortedNotes = notes.sort((a, b) =>
    a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0
  );

  const handleModalDelete = () => {
    handleDelete(openedModalId);
    setOpenedModalId(null);
  };

  const handleDeleteClick = (id: number) => setOpenedModalId(id);

  return (
    <>
      <NotesAnimatedContainer extended={extended}>
        {sortedNotes.map((note) => (
          <NoteRow
            {...note}
            key={`${note.id}_${note.created_at}`}
            handleDelete={handleDelete}
            userId={userId}
            deletionModalRef={deletionModalRef}
            onDeleteClick={handleDeleteClick}
          />
        ))}
      </NotesAnimatedContainer>
      <DeletionModal
        onClose={() => {
          setOpenedModalId(null);
        }}
        onDelete={handleModalDelete}
        open={openedModalId !== null}
        text="You will not be able to recover this note once it is deleted."
        title="Are you sure?"
        ref={deletionModalRef}
      />
    </>
  );
};

const NoteRow: React.FC<NoteRowProps> = ({
  id,
  text,
  created_at,
  created_by,
  author_id,
  userId,
  onDeleteClick,
}) => {
  const [shouldShowDeleteButton, setShouldShowDeleteButton] = useState<boolean>(false);

  const signatureContent = `${created_by} - ${moment(created_at).format('MM/DD/YYYY [at] h:mm a')}`;

  const onMouseEnter = () => {
    if ((auth.isSuperAdminUser() && !!id) || (author_id === userId && !!id)) {
      setShouldShowDeleteButton(true);
    }
  };

  return (
    <>
      <LineItem
        data-testid={ATSInternalCandidateNoteDataTestIds.EXISTING_NOTE_ROW}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setShouldShowDeleteButton(false)}
      >
        <NoteContentContainer>
          <Box width="10px" height="full">
            <BulletPoint
              data-testid={ATSInternalCandidateNoteDataTestIds.EXISTING_NOTE_BULLET_POINT}
            />
          </Box>
          <Box>
            <Box data-testid={ATSInternalCandidateNoteDataTestIds.EXISTING_NOTE_TEXT}>{text}</Box>
            <Signature data-testid={ATSInternalCandidateNoteDataTestIds.EXISTING_NOTE_SIGNATURE}>
              {signatureContent}
            </Signature>
          </Box>
        </NoteContentContainer>
        {shouldShowDeleteButton && auth.hasInternalCandidateNoteDelete() && (
          <DeleteButtonContainer>
            <DeleteButton
              dataTestId={ATSInternalCandidateNoteDataTestIds.DELETE_BUTTON}
              onClick={() => onDeleteClick(id)}
              sx={{
                fill: nimbleTheme.palette.gray.mediumDark,
              }}
            />
          </DeleteButtonContainer>
        )}
      </LineItem>
    </>
  );
};

const NotesAnimatedContainer = styled(Box)<{ extended: boolean }>(({ extended }) => ({
  maxHeight: extended ? '500px' : '140px',
  overflow: 'auto',
  marginBottom: '10px',
  WebkitTransition: 'max-height 1s ease',
}));

const LineItem = styled(Box)({
  display: 'flex',
  marginBottom: '10px',
  justifyContent: 'space-between',
});

const NoteContentContainer = styled(Box)({
  lineHeight: nimbleTheme.spacing(3),
  display: 'flex',
  gap: nimbleTheme.spacing(1.5),
  alignItems: 'baseline',
});

const Signature = styled(Box)({
  fontSize: nimbleTheme.spacing(1.5),
  color: 'var(--darkgray)',
});

const DeleteButtonContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
