import { BaseNimbleAPI } from './baseAPI';

class QuickProfileAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/quick-profile' };
  }

  /**
   * Fetch a full user object to be used in Quick Profile.
   * @param {number} userId
   * @returns {object}
   */
  async fetchFullUser(userId, applicationId) {
    const url = `/full-user/${userId}/district-application/`;
    const params = { a_pk: applicationId };
    const response = await this.get(url, { params });
    return response.data;
  }

  /**
   * Fetch a full user object to be used in Quick Profile School Application.
   * @param {number} userId
   * @returns {object}
   */
  async fetchFullUserSchoolApplication(userId, s_pk) {
    const url = `/full-user/${userId}/school-application/`;
    const params = { s_pk };
    const response = await this.get(url, { params });
    return response.data;
  }

  async fetchFullUserNoApplications(userId) {
    const url = `/full-user/${userId}/`;
    const response = await this.get(url);
    return response.data;
  }
}
export default new QuickProfileAPI();
