import styled, { css } from 'styled-components';
import { border, layout, space } from 'styled-system';

export interface TextAreaProps {
  border?: string;
  borderColor?: string;
  hasError?: boolean;
  height?: string;
  layout?: string;
  marginBottom?: number;
  marginTop?: number;
  value?: string;
  width?: number | string;
}

export const Textarea = styled.textarea<TextAreaProps>`
  font-size: 14px;

  border-width: 1px;
  border-style: solid;
  border-radius: 3px;

  padding: 1rem;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }

  ${border}
  ${layout}
  ${space}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid #cc0033;
      background-color: #fce4e4;
    `}
`;

Textarea.defaultProps = {
  borderColor: '#DCDCDC',
  height: '10em',
  marginTop: 0,
  marginBottom: 0,
};
