import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'ui-kit';

export default class LocationsModalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
  }

  static propTypes = {
    schoolSearchChange: PropTypes.func.isRequired,
  };

  updateSearchTerm = (value) => {
    this.setState({ searchTerm: value });
    this.props.schoolSearchChange(value);
  };

  render() {
    return (
      <div>
        <SearchContainer>
          <StyledSearchInput
            type="text"
            placeholder={this.props.placeholder || 'Start typing to narrow list of locations'}
            value={this.state.searchTerm}
            onChange={(e) => this.updateSearchTerm(e.target.value)}
          />
          <StyledClearIcon onClick={() => this.updateSearchTerm('')}>X</StyledClearIcon>
        </SearchContainer>
        {this.props.children}
      </div>
    );
  }
}

const SearchContainer = styled.div`
  position: relative;
`;

const StyledSearchInput = styled(Input)`
  width: 100%;
`;

const StyledClearIcon = styled.span`
  position: absolute;
  top: 15px;
  right: 10px;
  color: #d7d7d7;
  cursor: pointer;
  user-select: none;
`;
