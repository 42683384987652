import { Component } from 'react';
import PropTypes from 'prop-types';

import iconUpCaretGreen from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';

import {
  hoursPerWeekOptions,
  employmentTypeFilters,
  employmentTypeAndHours,
} from '../../../utils/enums';

const EMPLOYMENTTYPE = employmentTypeFilters().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export default class CandidateAvailabilityFilter extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    updateMultiSelectFilterWithMultipleValues: PropTypes.func.isRequired,
    employment_type_and_hours: PropTypes.array.isRequired,
    selectAll: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions = () => {
    this.setState((state, props) => ({ showOptions: !state.showOptions }));
  };

  selectAll = (e) => {
    e.stopPropagation();
    this.props.selectAll('employment_type_and_hours', employmentTypeAndHours());
  };

  clearAll = (e) => {
    e.stopPropagation();
    this.props.clearAll('employment_type_and_hours');
  };

  optionsCheckedInSection = (optionSet, options) => {
    // check if all options are checked
    for (let i = 0; i < options.length; i++) {
      if (!optionSet.has(options[i])) {
        return false;
      }
    }
    return true;
  };

  updateHoursPerWeekSection = (e, optionSet) => {
    e.stopPropagation();

    let options = hoursPerWeekOptions();
    if (!e.target.checked) {
      // user is deselecting box, so deselect all boxes indented below it
      options.forEach((o) => optionSet.delete(o));
    } else {
      // user is selecting box, so select all boxes indented below it
      options.forEach((o) => optionSet.add(o));
    }

    const fieldName = 'employment_type_and_hours';
    const values = [...optionSet];
    const optionsLength = employmentTypeAndHours().length;

    this.props.updateMultiSelectFilterWithMultipleValues(fieldName, values, optionsLength);
  };

  render() {
    /** use a set for constant time lookup. Loop through props.values, which is a list of currently
     * active filters. Add each value to the set, and down below in the map function, check
     * if each item is in the set (set.has(o.value). This is faster than looping through
     * the values array during each iteration of map.
     * Also, send set.has(o.value) when the item is clicked, so that the container
     * knows if you're clicking an already active filter (in which case it will turn it off).
     */
    let { employment_type_and_hours } = this.props;
    let optionSet = new Set();

    employment_type_and_hours.forEach((o) => {
      optionSet.add(o);
    });

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    const optionsLength = employmentTypeAndHours().length;
    let allSelected = employment_type_and_hours.length === optionsLength;
    let noneSelected = employment_type_and_hours.length === 0;

    let isActive = !(allSelected || noneSelected);

    return (
      <div className={`new-cl-filter ${isActive ? 'cl-active' : ''}`}>
        <div className="cl-filter-header" onClick={this.toggleOptions}>
          <p className={isActive ? 'cl-active' : ''}>Candidate Availability</p>
          <img
            src={
              this.state.showOptions && isActive
                ? iconUpCaretGreen
                : !this.state.showOptions && isActive
                  ? iconDownCaretGreen
                  : this.state.showOptions
                    ? iconUpCaret
                    : iconDownCaret
            }
            alt=""
          />
        </div>
        <div className={`selection-list ${this.state.showOptions ? 'show' : ''}`}>
          <div className="option select-all">
            <span className="drop-select-all" onClick={this.selectAll}>
              Select All
            </span>
            <span className="drop-select-all" onClick={this.clearAll}>
              Clear All
            </span>
          </div>
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={optionSet.has(EMPLOYMENTTYPE.no_preference)}
                onChange={() => {
                  const isActive = optionSet.has(EMPLOYMENTTYPE.no_preference);
                  return this.props.updateMultiSelectFilter(
                    'employment_type_and_hours',
                    EMPLOYMENTTYPE.no_preference,
                    isActive,
                    optionsLength
                  );
                }}
              />
              <span className="checkmark"></span>
              No Preference
            </label>
          </div>
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={optionSet.has(EMPLOYMENTTYPE.full_time)}
                onChange={() => {
                  const isActive = optionSet.has(EMPLOYMENTTYPE.full_time);
                  return this.props.updateMultiSelectFilter(
                    'employment_type_and_hours',
                    EMPLOYMENTTYPE.full_time,
                    isActive,
                    optionsLength
                  );
                }}
              />
              <span className="checkmark"></span>
              Full-time
            </label>
          </div>
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={this.optionsCheckedInSection(optionSet, hoursPerWeekOptions())}
                onChange={(e) => this.updateHoursPerWeekSection(e, optionSet)}
              />
              <span className="checkmark"></span>
              Part-time
            </label>
          </div>
          <p className="school-type-p indented">Hours per week</p>
          {hoursPerWeekOptions().map((option) => (
            <div key={option} className="option indented">
              <label className="container">
                <input
                  type="checkbox"
                  checked={optionSet.has(option)}
                  onChange={() => {
                    const isActive = optionSet.has(option);
                    return this.props.updateMultiSelectFilter(
                      'employment_type_and_hours',
                      option,
                      isActive,
                      optionsLength
                    );
                  }}
                />
                <span className="checkmark"></span>
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
