import { Route, RouteProps } from 'react-router-dom';

const PublicRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        return <>{children}</>;
      }}
    />
  );
};

export default PublicRoute;
