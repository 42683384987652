/**
 * A theme for Styled-Components based Styled-System
 * See https://styled-system.com/theme-specification/
 *
 * There is a lot of work to be done here to flesh this all out.
 * Ideally, define nice symetrical spacing and font sizes
 * A robust color palette with aliases.
 *
 * If we incrementally improve it so it is flexible, I think it could go far.
 *
 * I'm not sold on these color arrays either. Ideally, you'd want them sorted from light to dark,
 * but that would be impossible to maintain unless we filled them up with all the colors of our theme (which is inconsistent...)
 */

const greens = [
  '#00B88D', // A very light green, hard to see (not WCAG 2.0 compliant)
  '#34C88F', // A light green, hard to see (not WCAG 2.0 compliant)
  '#21805C', // A dark green, costly (WCAG 2.0 compliant)
  '#085F08', // A darker green, compliant with white text (WCAG 2.0 compliant)
];

const whites = ['#FFFFFF'];

const colors = {
  // Primary
  primary: {
    light: greens[0],
    dark: greens[2],
    alternateLight: greens[1],
  },
  pureWhite: whites[0],
  // Secondary
};

// As of the intro of NCI, we have a new set of colors:
const uiColors = {
  black: 'rgba(68, 68, 68, 1)',
  // I'm not sure which one is the right spelling :sweat_smile:
  grey: 'rgba(184, 184, 184, 1)',
  gray: 'rgba(184, 184, 184, 1)',
  lightgrey: 'rgba(184, 184, 184, .75)',
  lightgray: 'rgba(184, 184, 184, .75)',
  notquitewhite: 'rgb(220 220 220)',
  white: 'rgba(255, 255, 255, 1)',
  greens: {
    full: 'rgba(0, 184, 141, 1)',
    medium: 'rgba(97, 213, 187, 1)',
    light: 'rgba(190, 237, 227, 1)',
    xlight: 'rgba(190, 237, 227, .05)',
  },
  blues: {
    full: 'rgba(48, 75, 125, 1)',
    medium: 'rgba(130, 146, 178, 1)',
    light: 'rgba(203, 210, 224, 1)',
    xlight: 'rgba(203, 210, 224, .05)',
  },
  purples: {
    full: 'rgba(123, 81, 150, 1)',
    medium: 'rgba(176, 150, 193, 1)',
    light: 'rgba(222, 211, 230, 1)',
    xlight: 'rgba(222, 211, 230, .05)',
  },
  reds: {
    full: 'rgba(239, 86, 117, 1)',
    medium: 'rgba(247, 153, 171, 1)',
    light: 'rgba(252, 213, 221, 1)',
    xlight: 'rgba(252, 213, 221, .05)',
  },
  yellows: {
    full: 'rgba(255, 166, 1, 1)',
    medium: 'rgba(255, 203, 94, 1)',
    light: 'rgba(255, 234, 189, 1)',
    xlight: 'rgba(255, 234, 189, .05)',
  },
};

const buttons = {
  primary: {
    color: uiColors.white,
    backgroundColor: uiColors.greens.full,
    border: '1px solid transparent',
  },
  secondary: {
    color: '#c2c2c2',
    backgroundColor: colors.pureWhite,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  link: {
    backgroundColor: 'transparent',
    color: uiColors.gray,
    border: 0,
  },
  delete: {
    color: uiColors.white,
    backgroundColor: 'rgba(204, 0, 0, 1)',
    border: '1px solid transparent',
  },
};

const breakpoints = ['480px', '768px', '992px', '1200px'];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export default { colors, buttons, breakpoints, uiColors };
