import PropTypes from 'prop-types';
import styled from 'styled-components';
import { grid, layout } from 'styled-system';
import { makeAllUrlsValid } from '../../utils/util';
import { AltModal } from 'ui-kit';

export default function TaskDirectionsModal({ show, onHide, setTaskStatusComplete, task }) {
  return (
    <AltModal.AltModal isOpen={show} onClose={onHide}>
      <AltModal.Title>{`Task: ${task.universal_task.title}`}</AltModal.Title>
      <AltModal.Body>
        <Label>Directions:</Label>
        <DirectionsDiv
          dangerouslySetInnerHTML={{ __html: makeAllUrlsValid(task.universal_task.directions) }}
        />
      </AltModal.Body>
      <Footer gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
        <CancelButton onClick={onHide} width={[1, 1, '184px']}>
          Cancel
        </CancelButton>
        <CompleteButton
          onClick={() => {
            setTaskStatusComplete(task);
            onHide();
          }}
          width={[1, 1, '184px']}
        >
          Complete Task
        </CompleteButton>
      </Footer>
    </AltModal.AltModal>
  );
}

TaskDirectionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  setTaskStatusComplete: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

const Label = styled.p`
  font-size: 19px;
  margin-bottom: 1rem;
`;

const DirectionsDiv = styled.div`
  font-size: 15px;

  a {
    color: ${(props) => props.theme.colors.primary.light};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const Footer = styled(AltModal.Actions)`
  ${grid}

  display: grid;
  align-items: center;
`;

const Button = styled.button`
  ${layout}

  height: 50px;

  font-weight: 600;
  font-size: 15px;

  border-radius: 3px;
`;

const CancelButton = styled(Button)`
  color: #a6a7ad;
  border: 1px solid #a6a7ad;
  background-color: #fff;
`;

const CompleteButton = styled(Button)`
  background-color: #00b88d;
  border: none;
  color: #fff;

  justify-self: end;
`;
