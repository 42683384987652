import { BaseNimbleAPI } from './baseAPI';

class SubcategoriesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/subcategories' };
  }

  async fetch() {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }
}

export default new SubcategoriesAPI();
