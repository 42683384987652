import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';

export default function Header({ openQuestionSetModal, setQuestionSetToEdit }) {
  return (
    <AnchorTagHeader
      title="Application Question Sets"
      id="applicationQuestionSets"
      buttonText="+ New Question Set"
      onClick={() => {
        setQuestionSetToEdit(null);
        openQuestionSetModal();
      }}
    />
  );
}
