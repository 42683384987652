import IconDownCaretWithSquare from 'assets/icon_down_caret_with_square.svg';
import { ProfileHeaderProps } from './types';
import QPDrowdownButton from './QPDropdownButton';

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  handleActionDropdownClick,
  handleSetEmailModalOpen,
  name,
  showActionDropdown,
  showActionDropdownRef,
}) => {
  return (
    <div className="QuickProfile__header">
      <div className="flex flex-center">
        <div className="QuickProfile__header-name">
          <h2>{name}</h2>
        </div>
        <div className="QuickProfile__header-icons">
          <div className="relative">
            <img
              className="QuickProfile__header-dropdown-img candidate-icon-field"
              src={IconDownCaretWithSquare}
              onClick={handleActionDropdownClick}
              ref={showActionDropdownRef}
            />
            <div className={`quickview-dropdown-div ${showActionDropdown ? 'expanded' : ''}`}>
              <QPDrowdownButton label="Email" onClick={handleSetEmailModalOpen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
