import { ActivityFeedProps } from './types';
import ProfileTimeline from 'components/Profile/ProfileTimeline/ProfileTimeline';

const ActivityFeed: React.FC<ActivityFeedProps> = ({ events, fullUser }) => {
  return (
    <div className="profile-section">
      <h4 className="section-header mb1 mr1 inline-block">Activity Feed</h4>
      <ProfileTimeline events={events} user={fullUser} />
    </div>
  );
};

export default ActivityFeed;
