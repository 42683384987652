import moment from 'moment';

import { SubheaderProps } from './types';

const Subheader: React.FC<SubheaderProps> = ({ createdAt, updated, updatedBy }) => {
  return (
    <div className="QuickProfile__subheader">
      <div className="QuickProfile__subheader-item--small" style={{ marginBottom: '5px' }}>
        {`Entered as prospect ${moment(createdAt).format('l')}`}
      </div>
      {updated && (
        <div className="QuickProfile__subheader-item--small">
          <span data-tip data-for="last-updated">
            Last updated
          </span>
          {` ${moment(updated).format('l')}${updatedBy ? ` by ${updatedBy}` : ''}`}
        </div>
      )}
    </div>
  );
};

export default Subheader;
