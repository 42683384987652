import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ErrorText from '../../errortext';
import CandidateFormModalLineItem from './CandidateFormModalLineItem';
import { DropdownWithInputFilter } from 'ui-kit';

const mapping = {
  '-1': 'super_admin_access',
  '-2': 'district_admin_access',
  '-3': 'district_user_access',
  '-4': 'school_admin_access',
};

export default class EditPermissions extends Component {
  static propTypes = {
    adminUsers: PropTypes.array.isRequired,
    updateFieldObj: PropTypes.func.isRequired,
    fieldObj: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      fieldObj: Object.assign({}, props.fieldObj),
      user_or_user_type: '',
      users_view_edit: [],
    };
    this.initialState = Object.assign({}, this.state);
  }

  componentDidMount() {
    // add a permission property to the user objects, used for rendering (see note in state).
    let fieldObj = this.state.fieldObj;

    let existingUsers = fieldObj.individual_users.map((u) => {
      const name = this.props.adminUsers.find((a) => a.id === u).name;
      return { id: u, name };
    });

    this.setState({ users_view_edit: existingUsers });
  }

  getOptions = () => {
    let user_types = [
      { value: -1, label: 'Super Admins' },
      { value: -2, label: 'District Admins' },
      { value: -3, label: 'District Users' },
      { value: -4, label: 'School Admins' },
    ];

    let users = this.props.adminUsers
      .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
      .map((a) => {
        return { value: a.id, label: a.name };
      });

    return user_types.concat(users);
  };

  handleMultiSelectChange = (value) => {
    this.setState({ user_or_user_type: value, error: '' });
  };

  addItem = () => {
    let { fieldObj, user_or_user_type, users_view_edit } = this.state;
    if (user_or_user_type === '') {
      // Empty fields
      this.setState({ error: 'Please select a user type.' });
      return;
    }
    if (user_or_user_type > 0) {
      // user id in this case
      let user = this.props.adminUsers.find((a) => a.id === user_or_user_type);
      if (user) {
        // place in properly sorted location (according to last name)
        for (let i = 0; i < users_view_edit.length; i++) {
          if (users_view_edit[i].id === user_or_user_type) {
            // user already in list, so return
            this.setState({ error: 'That user has already been added' });
            return;
          }
          if (user.last_name < users_view_edit[i].last_name) {
            // insert in alphabetical order, so if user last name comes before
            // current last name, place there.
            users_view_edit.splice(i, 0, { id: user_or_user_type, name: user.name });
            this.setState({ users_view_edit });
            this.resetState();
            return;
          }
        }
        // if get to the end without placing it, 'push' it there.
        users_view_edit.push({ id: user_or_user_type, name: user.name });
        this.setState({ users_view_edit });
      }
    } else {
      let userTypeAccess = mapping[user_or_user_type];

      // make sure user type isn't already in list
      if (fieldObj[userTypeAccess]) {
        // item already in list. set error and return.
        this.setState({ error: 'That user type has already been added' });
        return;
      }
      fieldObj[userTypeAccess] = true;
      this.setState({ fieldObj });
    }
    // Updates state at the higher level (used for final save)
    fieldObj['individual_users'] = this.state.users_view_edit.map((u) => u.id);
    this.props.updateFieldObj(fieldObj);
    // clears everything in state except fieldObj and users_view_edit.
    this.resetState();
  };

  resetState = () => {
    this.setState({
      error: '',
      user_or_user_type: '',
    });
  };

  removeLineItem = (id) => {
    let { fieldObj, users_view_edit } = this.state;
    let userTypeAccess = mapping[id];
    if (id < 0) {
      // it's a user_type (-1 through -4)
      fieldObj[userTypeAccess] = false;
    } else {
      // it's a user (id > 0)
      let index = users_view_edit.findIndex((u) => u.id === id);
      if (index !== -1) {
        // remove the user
        users_view_edit.splice(index, 1);
      }
    }
    fieldObj['individual_users'] = users_view_edit.map((u) => u.id);
    this.props.updateFieldObj(fieldObj);
    this.setState({ fieldObj, users_view_edit });
  };

  render() {
    let { fieldObj } = this.state;

    return (
      <>
        <Label>Completed form visible to:</Label>
        {fieldObj.super_admin_access && (
          <CandidateFormModalLineItem
            label={'Super Admins'}
            removeLineItem={() => this.removeLineItem(-1)}
          />
        )}
        {fieldObj.district_admin_access && (
          <CandidateFormModalLineItem
            label={'District Admins'}
            removeLineItem={() => this.removeLineItem(-2)}
          />
        )}
        {fieldObj.district_user_access && (
          <CandidateFormModalLineItem
            label={'District Users'}
            removeLineItem={() => this.removeLineItem(-3)}
          />
        )}
        {fieldObj.school_admin_access && (
          <CandidateFormModalLineItem
            label={'School Admins'}
            removeLineItem={() => this.removeLineItem(-4)}
          />
        )}
        {this.state.users_view_edit.map((u) => (
          <CandidateFormModalLineItem
            key={u.id}
            label={u.name}
            removeLineItem={() => this.removeLineItem(u.id)}
          />
        ))}

        <AddNewUserTypeContainer>
          <DropdownWithInputFilter
            options={this.getOptions()}
            value={this.state.user_or_user_type || ''}
            placeholder="Search by name or user type"
            handleChange={this.handleMultiSelectChange}
            onClear={() => this.handleMultiSelectChange('')}
            boxShadow={false}
          />
          <AddUserTypeButton onClick={this.addItem}>Add</AddUserTypeButton>
        </AddNewUserTypeContainer>
        {this.state.error && <ErrorText message={this.state.error} />}
      </>
    );
  }
}

const Label = styled.p`
  font-size: 16px;
  margin-bottom: 6px;
`;

const AddNewUserTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-gap: 12px;
`;

const AddUserTypeButton = styled.button`
  height: 50px;
  background-color: #00b88d;
  color: white;
  border: none;
  border-radius: 3px;

  font-size: 14px;
`;
