import { Chip } from './Chip';
import { applicationStatusMapping, getJobStatusColorForJobViewV2 } from 'utils/enums';
import { ApplicationStatusChipProps, JobStatusChipProps } from './types';
import { getStatusText } from 'components/CreateJobOrTemplateModal/utils';
import { styled } from '@mui/material';

export const JobStatusChip: React.FC<JobStatusChipProps> = ({
  status,
  activeStatus,
  sx,
  dataTestId,
}) => {
  const chipColor = getJobStatusColorForJobViewV2(status, activeStatus);
  const text = getStatusText(status, activeStatus);

  return (
    <Chip
      label={text}
      variant="outlined"
      dataTestId={dataTestId}
      sx={{
        ...sx,
        border: `1px solid ${chipColor.borderColor}`,
        color: chipColor.textColor,
        backgroundColor: chipColor.backgroundColor,
        fontWeight: 500,
      }}
    />
  );
};

export const ApplicationStatusChip: React.FC<ApplicationStatusChipProps> = ({
  label,
  color,
  sx,
  dataTestId,
}) => {
  const chipColor = applicationStatusMapping[color];

  return (
    <ListItem>
      <Chip
        dataTestId={dataTestId}
        label={label}
        variant="outlined"
        sx={{
          ...sx,
          border: `1px solid ${chipColor}`,
          color: chipColor,
        }}
      />
    </ListItem>
  );
};

const ListItem = styled('li')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.25),
}));
