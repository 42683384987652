import { useEffect } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { useOnClickOutside, useOnKeyEvent } from 'hooks';
import { BaseModal } from './BaseModal';

/**
 * This component is used when we want
 * to use a modal with a different body and/or footer background color. The default
 * modal made it tough to do that with padding coming from the container. The body
 * and footer span the entire width of the grid in this component, so changing
 * the color is easy. It starts with a light grey background, but is easily overridden.
 *
 * See ui-kit/Modal for more information on usage.
 */
const AltModal = ({
  isOpen,
  onClose,
  children,
  closeOnClickOutside = false,
  focusOnOpenRef = null,
  large = false,
  noBottom = false,
  small = false,
}) => {
  const onClickOutsideHandler = () => {
    if (closeOnClickOutside) {
      onClose();
    }
  };
  const onClickOutsideNode = useOnClickOutside(onClickOutsideHandler, isOpen);
  useOnKeyEvent('Escape', 'keyup', onClose, isOpen);

  // Focus first input on modal open: accessibility best practice
  useEffect(() => {
    if (isOpen && focusOnOpenRef) {
      focusOnOpenRef.current.focus();
    }
  }, [isOpen, focusOnOpenRef]);

  if (!isOpen) {
    return null;
  }

  return (
    <BaseModal large={large} small={small}>
      <Layout ref={onClickOutsideNode} noBottom={noBottom} small={small}>
        <Cancel onClick={onClose}>×</Cancel>
        <UpperRule small={small} />
        {children}
      </Layout>
    </BaseModal>
  );
};

// when noBottom's true, remove the bottom grid row
const Layout = styled.div`
  display: grid;
  grid-template-columns: 30px auto 30px 30px;
  grid-template-rows: 60px 1px ${({ noBottom }) => !noBottom && 'auto'} ${({ small }) =>
      !small && 'minmax(112px, auto)'};

  height: 100%;
`;

const Title = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;

  display: flex;
  align-items: center;

  font-size: 17px;
  color: #444444;
`;

const Cancel = styled.div`
  grid-column: 3 / span 2;
  grid-row: 1 / span 1;

  color: #999999;
  font-size: 21px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const UpperRule = styled.hr`
  grid-row: 2 / span 1;
  grid-column: 1/-1;
  border-top: ${({ small }) => (small ? '1px solid #f2f2f2' : '1px solid #d8d8d8')};
`;

const Body = styled.div`
  grid-column: 1 / -1;
  grid-row: 3 / span 1;
  min-width: 0;

  padding: 2.353em;

  background-color: #f9f9f9;

  ${space}
`;

const Actions = styled.div`
  grid-column: 1 / -1;
  grid-row: 4 / span 1;

  padding: 16px 30px;
  border-radius: 0 0 3px 3px;

  background-color: #f9f9f9;

  ${space}
`;

export { AltModal, Title, Body, Actions, Cancel, UpperRule };
