import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import ErrorText from '../errortext';
import { Input, MultiSelect } from 'ui-kit';

export default class UniversalTasksModal extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    adminUsers: PropTypes.array.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      task: Object.assign({}, props.task),
      errorMessage: '',
    };
  }

  handleChange = (e) => {
    let task = this.state.task;
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'trigger_status_id') {
      value = Number(value);
    }
    task[name] = value;
    this.setState({ task, errorMessage: '' });
  };

  save = () => {
    let task = this.state.task;
    if (task.title === '') {
      this.setState({ errorMessage: 'Please add a title.' });
      return;
    }
    if (task.trigger_days === '') {
      this.setState({ errorMessage: 'Please add a number to the due date.' });
      return;
    }
    if (task.trigger_status_id === '') {
      this.setState({ errorMessage: 'Please select a trigger status.' });
      return;
    }
    this.props.onSave(task);
  };

  updateMultiSelect = (values, fieldName) => {
    let task = this.state.task;
    task.candidate_assigned = false;
    // a value of 0 means "Candidate" was selected
    values.forEach((v) => {
      if (v.value === 0) {
        task.candidate_assigned = true;
      }
    });

    task[fieldName] = values.map((v) => {
      return { id: v.value, name: v.label };
    });
    this.setState({ task });
  };

  adminOptions = () => {
    /** assigned user can now be "Candidate". It's not a specific candidate, so add in a 0
     * for id as a placeholder. The rest of the options are admins in the district.
     */
    let arr = [];
    arr.push({
      label: 'Candidate',
      value: 0,
    });
    this.props.adminUsers.forEach((u) =>
      arr.push({
        label: u.display,
        value: u.id,
      })
    );
    return arr;
  };

  handleTextareaChange = (value, fieldName) => {
    let { task } = this.state;
    task[fieldName] = value;
    this.setState({ task });
  };

  render() {
    const { show, onHide } = this.props;
    let task = this.state.task;

    return (
      <Modal
        show={show}
        onHide={onHide}
        bsSize="large"
        dialogClassName="app-status-modal task-modal"
      >
        <Modal.Header>
          <h4>{task.id ? 'Edit Task' : 'New Task'}</h4>
          <div className="modal-header-close" onClick={onHide}>
            <p>x</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="app-status-main-content">
            <h4>Name Task</h4>
            <div className="label-and-color-container mt1">
              <Input
                width={1}
                type="text"
                name="title"
                placeholder="e.g. Send onboarding packet"
                value={task.title || ''}
                onChange={this.handleChange}
                maxLength={35}
                // can't edit title if zapier_task === true
                disabled={task.zapier_task}
              />
            </div>

            <div className="date-assigned-users-container">
              <div className="due-date">
                <h4>Due Date</h4>
                <span className="subtext">x days after trigger</span>
                <div className="label-and-color-container">
                  <Input
                    width={1}
                    style={{ marginTop: '9px' }}
                    type="number"
                    name="trigger_days"
                    value={task.trigger_days || ''}
                    onChange={this.handleChange}
                    min={'0'}
                  />
                </div>
              </div>
              <div className="assigned-users">
                <h4>Set Assignee(s)</h4>
                <span className="subtext" style={{ marginBottom: '10px' }}>
                  Note that if Candidate is selected, this task will be visible to the candidate on
                  his/her dashboard
                </span>
                <MultiSelect
                  options={this.adminOptions()}
                  value={task.assigned_users.map((a) => {
                    return { label: a.name, value: a.id };
                  })}
                  onChange={(values) => this.updateMultiSelect(values ?? [], 'assigned_users')}
                  placeholder="Search by name"
                  closeMenuOnSelect={false}
                />
              </div>
            </div>

            <div className="trigger-status">
              <h4>Trigger</h4>
              {task.zapier_task ? (
                <span className="subtext">This task is triggered by a Zapier integration</span>
              ) : (
                <span className="subtext">
                  Moving a candidate to the following status will trigger creation & assignment of
                  this task
                </span>
              )}
              {!task.zapier_task && (
                <div className="select-form-field jobedit-select-div copy-app-select-role">
                  <select
                    type="text"
                    className="pointer"
                    name="trigger_status_id"
                    value={task.trigger_status_id || ''}
                    onChange={this.handleChange}
                    style={{ boxShadow: 'none' }}
                  >
                    <option value="" disabled>
                      Status
                    </option>
                    {this.props.newApplicationStatuses.map((status, i) => (
                      <option key={i} value={status.id}>
                        {status.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            {task.candidate_assigned === true && (
              <div className="task-directions">
                <h4>Directions</h4>
                <span className="subtext">
                  The following directions will be visible to the candidate
                </span>
                <div className="mt1">
                  <ReactQuill
                    value={task.directions || ''}
                    onChange={(value) => this.handleTextareaChange(value, 'directions')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="email-template-buttons-div">
            <button
              className="email-template-modal-button email-template-modal-cancel"
              onClick={onHide}
            >
              Cancel
            </button>
            <div className="flex">
              <div style={{ lineHeight: '90px', marginRight: '4px' }}>
                {this.state.errorMessage && <ErrorText message={this.state.errorMessage} />}
              </div>
              <button
                className="email-template-modal-button email-template-modal-save"
                onClick={this.save}
              >
                {task.id ? 'Save' : 'Create'} task
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
