import styled from 'styled-components';

import { StyledSelect, TwoButtonSelect } from 'ui-kit';
import { onet_codes } from './enums';

const TEACHING = 'teaching';
const NON_TEACHING = 'non-teaching';

const onet_options = onet_codes();

export default function RoleTypeSelector({ job, updateField, roleType, setRoleType, hasError }) {
  const setOnetCode = (onetObject) => {
    const value = onetObject === null ? '' : onetObject.value;
    const payload = { target: { name: 'onet_occupation_code', value } };
    updateField(payload);
  };

  const handleChange = (value) => {
    const newButtonState = value === 0 ? NON_TEACHING : TEACHING;
    setRoleType(newButtonState);

    // if user selects 'non-teaching', clear the onet code on the job
    if (value === 0) {
      setOnetCode({ value: '' });
    }
  };

  return (
    <GridContainer>
      <Label>Role Type*</Label>
      <TwoButtonSelect
        firstButtonLabel="Non-Teaching"
        firstButtonActive={roleType === NON_TEACHING}
        secondButtonLabel="Teaching"
        secondButtonActive={roleType === TEACHING}
        handleChange={handleChange}
      />
      {roleType === TEACHING && (
        <StyledSelect
          placeholder="Start typing or use the drop-down to find the closest match"
          options={onet_options}
          defaultValue={onet_options.find(({ value }) => job.onet_occupation_code === value)}
          onChange={setOnetCode}
          border={hasError ? '1px solid #CC0000' : ''}
          isClearable
        />
      )}
    </GridContainer>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 282px 1fr;
  grid-column-gap: 1rem;
  grid-template-rows: 21px 51px;
  grid-row-gap: 0.8rem;

  margin-top: 3rem;
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #444444;

  grid-column-start: 1;
  grid-column-end: 3;
`;
