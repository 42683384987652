import { Component } from 'react';

import nimbleSmallIconGreyscale from '../assets/nimble_logo_greyscale.svg';

export default class SubFooterPoweredBy extends Component {
  render() {
    return (
      <div className="jobs-page-branding">
        <div>
          <img
            src={nimbleSmallIconGreyscale}
            className="small-nimble-logo"
            alt="nimble small icon"
          />
          <span>&nbsp; Powered by Nimble</span>
        </div>
      </div>
    );
  }
}
