import { Component } from 'react';

import PropTypes from 'prop-types';
import { examMapping, examType, praxisSubjects } from '../../../utils/enums';
import CardBox from './CardBox';

const EXAM = examType().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

const SUBJECTS = praxisSubjects().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default class CardContainer extends Component {
  static propTypes = {
    exam: PropTypes.object.isRequired,
  };

  render() {
    let { exam } = this.props;
    return (
      <div className="card-container">
        <CardBox
          elementClassName={'title'}
          displayName={EXAM[exam.type]}
          content={exam.type === 2 && exam.praxis_subject ? SUBJECTS[exam.praxis_subject] : ''}
        />
        <div className="card-boxes">
          {examMapping[exam.type] &&
            examMapping[exam.type]['fields'].map(
              (row, i) =>
                exam[row.fieldName] && (
                  <CardBox key={i} displayName={row.displayName} content={exam[row.fieldName]} />
                )
            )}
        </div>
      </div>
    );
  }
}
