import useSWR from 'swr';

import customScorecardListAPI from 'api/customScorecardListAPI';

const KEY = customScorecardListAPI.config.baseURL;

/**
 * Data management wrapper for fetching a district's CustomScorecards.
 *
 * Usage: const { data, isLoading, error } = useCustomScorecards();
 */
export const useCustomScorecards = () => {
  const { data, error } = useSWR([KEY], () => customScorecardListAPI.fetchCustomScorecards());

  const isLoading = data === undefined;

  return { data: data ?? [], isLoading, error };
};
