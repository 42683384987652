import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import JobPostingAPI from 'features/Connect/api/jobPostingsAPI';
import { sortVacancies } from 'features/Connect/features/JobDiscovery/utils';
import { useEffect, useState } from 'react';
import { SwipeableDrawer } from 'sharedComponents/Drawer';
import { Vacancy } from 'types/connectTypes';
import { MoreInfoSliderProps } from '../types';
import { VacancyAccordionRow } from '../VacancySection/VacancySectionAccordionRow';
import { HeaderSection } from './MoreInfoSliderHeaderSection';
import { SchoolDetailsSection } from './MoreInfoSliderSchoolDetailsSection';

export const MoreInfoSlider: React.FC<MoreInfoSliderProps> = ({
  closeHandler,
  openHandler,
  isOpen,
  anchor,
  school,
  actions,
  vacancyId,
  isReadyToConnectModalOpen,
  dataTestId,
}) => {
  const [sliderVacancies, setSliderVacancies] = useState<Vacancy[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchVacancies = (ncesSchoolId) => {
      setIsLoading(true);
      JobPostingAPI.fetchJobPostings(ncesSchoolId)
        .then((response) => {
          setSliderVacancies(response);
        })
        .catch((error) => {
          console.error('Error fetching vacancies:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (school) {
      fetchVacancies(school.nces_id);
    }
  }, [school]);

  const sortedVacancies =
    vacancyId !== '0' ? sortVacancies(sliderVacancies, vacancyId) : sliderVacancies;

  return (
    <SwipeableDrawer
      closeHandler={closeHandler}
      openHandler={openHandler}
      isOpen={isOpen}
      anchor={anchor}
      dataTestId={`${dataTestId}-${school}`}
    >
      <MoreInfoSliderContainer>
        <HeaderSection school={school} actions={actions} />
        <SchoolDetailsSection school={school} actions={actions} />
        {isLoading ? (
          <Box sx={{ textAlign: 'center', paddingTop: 12 }}>
            <CircularProgress />
          </Box>
        ) : (
          <VacancyAccordionRow
            school={school}
            actions={actions}
            vacancies={sortedVacancies}
            defaultVacancyId={vacancyId}
            isReadyToConnectModalOpen={isReadyToConnectModalOpen}
          />
        )}
      </MoreInfoSliderContainer>
    </SwipeableDrawer>
  );
};

const MoreInfoSliderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
}));
