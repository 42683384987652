import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Component } from 'react';

import PropTypes from 'prop-types';

import { hiringSeasons, monthAbbreviationToFull } from '../utils/enums';
import { theme } from 'ui-kit';

// if we have 11 hiring seasons, this will bust...
// @TODO(sean): instead pair this with selectedSeasons, and iterate that list
// Assigning a non-matching color each iteration.
let colorOrder = [
  theme.uiColors.blues.full,
  theme.uiColors.purples.full,
  theme.uiColors.reds.full,
  theme.uiColors.yellows.full,
  theme.uiColors.blues.medium,
  theme.uiColors.purples.medium,
  theme.uiColors.reds.medium,
  theme.uiColors.yellows.medium,
  theme.uiColors.blues.light,
  theme.uiColors.purples.light,
  theme.uiColors.reds.light,
  theme.uiColors.yellows.light,
];

class CustomTooltip extends Component {
  getHiringSeason = (item) => {
    let name = item.name;
    // hiring season number is after underscore in name
    let hiringSeason = name.slice(name.lastIndexOf('_') + 1);
    let hiringSeasonHumanized = hiringSeasons(false, true).find(
      (h) => h.value === Number(hiringSeason)
    );
    return hiringSeasonHumanized;
  };

  formatLabel = (label) => {
    if (label === 'Today') {
      return label;
    } else {
      return monthAbbreviationToFull[label];
    }
  };

  renderHiringSeasonNumbers = (hiringSeasons) => {
    let colors = [...colorOrder];
    const currentSeason = this.props.currentSeason;
    return hiringSeasons
      .filter((i) => i.value !== undefined)
      .map((item, i) => {
        let hiringSeasonHumanized = this.getHiringSeason(item);
        return (
          hiringSeasonHumanized && (
            <div
              key={i}
              className="value"
              style={{
                color:
                  hiringSeasonHumanized.value === currentSeason
                    ? theme.uiColors.greens.full
                    : colors.shift(),
              }}
            >
              {`${hiringSeasonHumanized.label}: ${item.value}`}{' '}
              {item.value === 1 ? 'vacancy' : 'vacancies'}
            </div>
          )
        );
      });
  };

  render() {
    const { active, payload, label } = this.props;
    return (
      active &&
      payload && (
        <div className="custom-tooltip">
          <span>{this.formatLabel(label)}</span>
          {this.renderHiringSeasonNumbers(payload)}
        </div>
      )
    );
  }
}

export default class VacanciesGraph extends Component {
  static propTypes = {
    vacancyHistory: PropTypes.array.isRequired,
    selectedSeasons: PropTypes.array.isRequired,
    currentSeason: PropTypes.number.isRequired,
  };

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  render() {
    let { currentSeason, selectedSeasons } = this.props;
    let colors = [...colorOrder];

    return (
      <div style={{ backgroundColor: 'white', border: '1px solid #dcdcdc' }}>
        <ResponsiveContainer width="100%" height={240}>
          <LineChart
            data={this.props.vacancyHistory}
            margin={{ top: 30, right: 20, left: -20, bottom: 5 }}
          >
            <YAxis
              tick={{ fontSize: 14 }}
              axisLine={false}
              stroke="#797979"
              strokeWidth="0.5"
              tickLine={false}
            />
            <XAxis
              dataKey="monthAbbr"
              padding={{ left: 20, right: 10 }}
              tick={{ fontSize: 13 }}
              axisLine={false}
              stroke="var(--darkgray)"
              tickLine={false}
              interval={0}
            />
            <CartesianGrid vertical={false} strokeWidth="0.5" />
            <Tooltip content={<CustomTooltip currentSeason={currentSeason} />} />
            {selectedSeasons.map((s, i) => (
              <Line
                key={i}
                type="monotone"
                dataKey={`unfilled_vacancies_${s}`}
                stroke={s === currentSeason ? theme.uiColors.greens.full : colors.shift()}
                strokeWidth={2}
                dot={false}
                activeDot={{ strokeWidth: 3, r: 6 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
