import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ATSJobsListDataTestIds } from '../../../data-testids/ATS';

export default function CategoriesFilter({
  categories,
  setCategories,
  setAllCategories,
  categoryOptions,
  isActive,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const allSubcategoriesChecked = (category) => {
    const itemIsCurrentlySelected = (subcategory) => categories.includes(subcategory.id);

    return category.subcategories.every(itemIsCurrentlySelected);
  };

  const updateSection = (e, section) => {
    e.stopPropagation();

    let updatedCategories = [...categories];
    const isNotInSection = (subcategory) =>
      !section.subcategories.map((s) => s.id).includes(subcategory);

    if (!e.target.checked) {
      updatedCategories = updatedCategories.filter(isNotInSection);
    } else {
      if (section.subcategories.length > 0)
        section.subcategories.forEach((subcategory) => updatedCategories.push(subcategory.id));
      else updatedCategories.push(section.id);
      // ensure no duplicates
      updatedCategories = [...new Set(updatedCategories)];
    }

    setCategories(updatedCategories);
  };

  const selectAllCategories = () => {
    const subcategories = ['N/A'];
    categoryOptions.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        subcategories.push(subcategory.id);
      });
    });

    // more l33t version, but no IE support for flatMap
    // const subcategories = categoryOptions.flatMap(({subcategories}) => subcategories.map(({id}) => id));

    setAllCategories(subcategories);
  };

  const toggleCategory = (e, category) => {
    let updatedCategories = [...categories];

    if (!e.target.checked) {
      updatedCategories = updatedCategories.filter((item) => item !== category);
    } else {
      updatedCategories.push(category);
      // ensure no duplicates
      updatedCategories = [...new Set(updatedCategories)];
    }

    setCategories(updatedCategories);
  };

  return (
    <div className={`new-cl-filter ${isActive ? 'cl-active' : ''}`}>
      <div
        className="cl-filter-header"
        onClick={toggleOptions}
        data-testid={ATSJobsListDataTestIds.CATEGORY_DROPDOWN}
      >
        <p className={isActive ? 'cl-active' : ''}>Category</p>
        <img
          src={
            showOptions && isActive
              ? iconUpCaretGreen
              : !showOptions && isActive
                ? iconDownCaretGreen
                : showOptions
                  ? iconUpCaret
                  : iconDownCaret
          }
          alt=""
        />
      </div>
      <div className={`selection-list ${showOptions ? 'show' : ''}`}>
        <div className="option select-all">
          <span className="drop-select-all" onClick={selectAllCategories}>
            Select All
          </span>
          <span
            className="drop-select-all"
            onClick={() => setCategories([])}
            data-testid={ATSJobsListDataTestIds.CATEGORY_CLEARALL}
          >
            Clear All
          </span>
        </div>
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={categories.includes('N/A')}
              onChange={(e) => toggleCategory(e, 'N/A')}
            />
            <span className="checkmark"></span>
            N/A
          </label>
        </div>
        {categoryOptions.map((category) => (
          <Fragment key={category.id}>
            <div className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={allSubcategoriesChecked(category)}
                  onChange={(e) => updateSection(e, category)}
                />
                <span className="checkmark"></span>
                {category.label}
              </label>
            </div>
            {category.subcategories.map((subcategory) => (
              <div key={subcategory.id} className="option indented">
                <label className="container">
                  <input
                    type="checkbox"
                    checked={categories.includes(subcategory.id)}
                    onChange={(e) => toggleCategory(e, subcategory.id)}
                    data-testid={`${ATSJobsListDataTestIds.CATEGORY_OPTION}-${subcategory.label}`}
                  />
                  <span className="checkmark"></span>
                  {subcategory.label}
                </label>
              </div>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

CategoriesFilter.propTypes = {
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};
