import styled from 'styled-components';

export default function RatingViewAnswer({ answer }) {
  return (
    <Container>
      <StyledInput
        type="text"
        min="0"
        max={answer.scale}
        value={answer.answer_rating ?? ''}
        readOnly
      />
      <i>out of {answer.scale}</i>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  border: 0.5px solid #d7d7d7;
  border-radius: 3px;
  height: 48px;
  width: 118px;
  padding: 13px 10px;
  margin-right: 8px;
  margin-top: 6px;
  margin-bottom: 0;

  background-color: inherit;
`;
