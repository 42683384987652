import { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { ButtonGroup } from '@mui/material';
import { SettingsRow } from 'components/SettingsRow';

export default class CandidateFormRow extends Component {
  static propTypes = {
    template: PropTypes.object.isRequired,
    deleteTemplate: PropTypes.func,
    editTemplate: PropTypes.func.isRequired,
  };

  deleteRow = () => {
    this.props.deleteTemplate(this.props.template.id);
  };

  render() {
    const { template } = this.props;

    const lastModificationDetails =
      template.modified && template.modified_by ? (
        <div className="grey mt0">
          Last modified on {moment(template.modified).format('MM/DD/YYYY')} by{' '}
          {template.modified_by.name}
        </div>
      ) : null;

    return (
      <SettingsRow
        sx={{ cursor: 'auto' }}
        cta={
          <>
            <EditButton onClick={() => this.props.editTemplate(template)} />
            {this.props.deleteTemplate && <DeleteButton onClick={this.deleteRow} />}
          </>
        }
      >
        <div className="vertical-flex">
          <div className="status-label">{template.title}</div>
          {lastModificationDetails}
        </div>
      </SettingsRow>
    );
  }
}
