import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AlertDialog } from 'ui-kit';
import paperAirplane from '../assets/icon-paper-airplane-green.svg';
import closeIcon from '../assets/icon-close-x.svg';

import axios from 'axios';

import ApplicationsAPI from 'api/applicationsAPI';
import PreferencesAPI from 'api/preferencesAPI';
import HellosignAPI from 'api/hellosignAPI';
import UsersAPI from 'api/usersAPI';
import LoadingSpinner from 'components/loadingSpinner';
import CandidateDashboardApplicationsList from '../components/CandidateDashboardApplicationsList';
import CandidateDocumentModal from '../components/CandidateDashboardApplicationsList/candidateDashboardModal';
import CandidateDashboardTasks from '../components/UniversalTasks/CandidateDashboardTasks';
import {
  uploadFile,
  replaceRequiredFile,
  updateCandidateProfileResume,
} from '../utils/fileUpload.js';
import {
  getParameterByName,
  getQueryStringValue,
  setQueryStringWithoutPageReload,
} from '../utils/util';
import { showErrorNotification, showTotalFailure } from '../utils/message';
import { CandidateErrors } from 'sharedComponents/Error/constants';
import { taskStatus } from 'utils/enums';
import { getOrSetPreferenceFlag } from 'utils/preferenceConfig';
import auth from 'utils/auth';
import { captureException } from '@sentry/browser';
import { has } from 'lodash';
import { ATSCandidateHomeDataTestIds } from '../data-testids/ATS';

const getTodaysDate = () => {
  const now = new Date(Date.now()).toISOString();
  return now.slice(0, 10);
};

class CandidateDashboardContainer extends Component {
  constructor(props) {
    super(props);
    const editModeApplicationID =
      window.location.search.indexOf('ea') !== -1 ? getParameterByName('ea') : null;
    const roleID =
      window.location.search.indexOf('role_id') !== -1 ? getParameterByName('role_id') : null;

    this.state = {
      user: null,
      applications: [],
      schoolApplications: [],
      totalApplicationsCount: 0,
      shouldShowSubmittedHeader: false,
      shouldShowPreferencesReminder: false,
      shouldShowCanceledModal: false,
      headerText: '',
      editModeApplicationID,
      roleID,
      isUploadDocumentModalOpen: false,
      hellosignSignatureTasks: [],
      attachments: [],
      requiredAttachments: [],
      applicationID: null,
      isLoading: true,
      page: 1,
      nextApplicationPage: null,
      isFetchingNextPage: false,
      isCombinedPreferenceAndProfile: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('applicant-pages', 'candidate-dashboard');
    this.loadInitialData();
  }

  async loadInitialData() {
    try {
      const [
        isCombinedPreferenceAndProfile,
        user,
        applications,
        schoolApplications,
        hellosignSignatureTasks,
      ] = await Promise.all([
        getOrSetPreferenceFlag(),
        this.fetchUser(),
        this.fetchUserApplications({ isHome: true, page: this.state.page }),
        this.fetchUserSchoolApplications(),
        this.fetchHellosignSignatureTasks(),
      ]);

      const newState = {
        user,
        applications: applications.results,
        schoolApplications: schoolApplications,
        totalApplicationsCount: applications.count,
        isCombinedPreferenceAndProfile,
        hellosignSignatureTasks,
        isLoading: false,
        nextApplicationPage: applications.next,
      };

      this.updateStateBasedOnQueryParams(newState);
      await this.checkPreferencesStarted(newState);

      this.setState(newState);
    } catch (error) {
      showTotalFailure();
      this.setState({ isLoading: false });
    }
  }

  updateStateBasedOnQueryParams(newState) {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('fromConf')) {
      newState.shouldShowSubmittedHeader = true;
      newState.headerText =
        this.state.editModeApplicationID !== null
          ? 'Your application has been updated!'
          : 'Your application was submitted successfully!';
    } else if (searchParams.has('fromEdit')) {
      newState.shouldShowSubmittedHeader = true;
      newState.headerText = 'Your profile has been updated!';
    } else if (searchParams.has('preferences')) {
      newState.shouldShowSubmittedHeader = true;
      newState.headerText = 'Your preferences were submitted successfully!';
    }

    const signature_id = searchParams.get('s');
    if (signature_id) {
      const signature = newState.hellosignSignatureTasks.find(
        (task) => task.signature_id === signature_id
      );
      if (!signature) {
        newState.shouldShowCanceledModal = true;
      }
    }
  }

  async checkPreferencesStarted(newState) {
    const userId = this.props.match?.params.id || auth.getUser().id;
    try {
      const preferencesData = await PreferencesAPI.checkPreferencesStarted(userId);
      newState.shouldShowPreferencesReminder = !preferencesData.preferences_started;
    } catch (error) {
      captureException(error);
    }
  }

  async fetchUser() {
    try {
      const userId = auth.getUser().id;
      const userData = await UsersAPI.fetchUser(userId, { home: true });
      if (!this.ignoreLastFetch) {
        return userData;
      }
    } catch (error) {
      captureException(error);
      showTotalFailure();
    }
  }

  async fetchUserApplications(params) {
    try {
      const applications = await ApplicationsAPI.getApplications(params);
      return applications;
    } catch (error) {
      captureException(error);
      showTotalFailure();
    }
  }

  async fetchUserSchoolApplications() {
    try {
      const schoolApplications = await ApplicationsAPI.getCandidateSchoolApplications();
      return schoolApplications;
    } catch (error) {
      captureException(error);
      return [];
    }
  }

  async fetchHellosignSignatureTasks() {
    try {
      const assignedSignatures = await HellosignAPI.getAllAssignedSignatures();
      return assignedSignatures;
    } catch (error) {
      captureException(error);
      return [];
    }
  }

  loadMoreApplications = async () => {
    if (this.state.isFetchingNextPage || !this.state.nextApplicationPage) return;

    try {
      this.setState({ isFetchingNextPage: true });

      const newApplicationsResponse = await ApplicationsAPI.getApplications({
        isHome: true,
        page: this.state.page + 1,
      });

      this.setState((prevState) => ({
        applications: [...prevState.applications, ...newApplicationsResponse.results],
        page: prevState.page + 1,
        isFetchingNextPage: false,
        nextApplicationPage: newApplicationsResponse.next,
      }));
    } catch (error) {
      this.setState({ isFetchingNextPage: false });

      if (error?.response?.status !== 404) {
        captureException(error);
      }
    }
  };

  closeSubmittedHeader = () => {
    this.setState({ shouldShowSubmittedHeader: false });
  };

  openUploadDocumentModal = (applicationID) => {
    this.setState({
      isUploadDocumentModalOpen: applicationID,
      applicationID: applicationID,
    });
  };

  withdrawApplication = async (applicationID) => {
    await ApplicationsAPI.candidateDeleteApplication(applicationID);
    window.location.reload();
  };

  submitAdditionalDocument = (attachmentName, file) => {
    return uploadFile(file, Number(this.state.isUploadDocumentModalOpen), attachmentName);
  };

  handleAttachmentChanges = (updatedAttachments) => {
    const sortedAttachments = updatedAttachments.sort((attachOne, attachTwo) =>
      attachOne.id >= attachTwo.id ? 1 : -1
    );
    this.setState({ attachments: sortedAttachments });
  };

  createCandidateResumeAttachment = (filePath) => {
    return {
      id: -1,
      attachment: filePath,
      additional_document: false,
      required_application_attachment: {
        id: -1,
        title: 'Resume',
      },
      title: 'Resume',
    };
  };

  handleReplaceCandidateProfileResume = async (resume) => {
    const res = await updateCandidateProfileResume(resume);
    const user_copy = Object.assign({}, this.state.user);
    user_copy.profile = res.data;

    this.setState({ user: user_copy });

    return this.createCandidateResumeAttachment(res.data.resume);
  };

  getApplicationAttachmentsForModal = async (applicationID) => {
    this.setState({ isLoading: true });

    const application = await ApplicationsAPI.getApplication(applicationID);
    const resume = this.createCandidateResumeAttachment(this.state.user.profile.resume);

    const attachments = [resume, ...application.applicationattachment_set].map((attachment) => {
      attachment.isDeleting = false;
      attachment.isReplacing = false;
      attachment.postSubmission = false;
      return attachment;
    });

    this.setState({
      requiredAttachments: application.role.requiredapplicationattachment_set,
      attachments: attachments,
      isLoading: false,
    });
  };

  replaceRequiredAttachment = (file, attachmentName, attachmentID, requiredAttachmentID) => {
    return replaceRequiredFile(
      file,
      this.state.applicationID,
      attachmentName,
      attachmentID,
      requiredAttachmentID
    );
  };

  deleteApplicationAttachment = async (attachmentID) => {
    await ApplicationsAPI.candidateDeleteAppAttachment(this.state.applicationID, attachmentID);

    const updatedAttachments = this.state.attachments.filter(
      (attachment) => attachment.id !== attachmentID
    );

    this.setState({ attachments: updatedAttachments });
  };

  setTaskStatusComplete = (task) => {
    let newTask = Object.assign({}, task);
    newTask.status = taskStatus.complete;

    const completedByDate = getTodaysDate();
    newTask.completed_by_date = completedByDate;

    let user = Object.assign({}, this.state.user);
    let taskIndex = user.tasks.findIndex((task) => task.id === newTask.id);
    if (taskIndex !== -1) {
      user.tasks[taskIndex] = newTask;
      this.setState({ user });
    }

    axios
      .put(`/api/candidate_task/${newTask.id}/`, newTask)
      .catch(() => showErrorNotification(CandidateErrors.TASK_UPDATE_FAILURE, 20000));
  };

  jobboardUrl = () => {
    let applicationID = '';
    if (this.state.editModeApplicationID) {
      applicationID = this.state.editModeApplicationID;
    } else {
      applicationID = this.state.roleID;
    }

    const application = this.state.applications.find(
      (application) => application.role.id === Number(applicationID)
    );
    const slug =
      application.role.jobboards.length > 0
        ? application.role.jobboards[0].slug
        : application.role.district.slug;
    return `/jobs/${slug}`;
  };

  closePreferencesReminder = () => {
    this.setState({ shouldShowPreferencesReminder: false });
  };

  banner = () => {
    if (
      window.location.search.indexOf('fromConf') !== -1 ||
      window.location.search.indexOf('fromEdit') !== -1
    ) {
      if (this.state.shouldShowPreferencesReminder) {
        return (
          <div
            className={`preferences-reminder ${this.state.shouldShowSubmittedHeader ? 'mb2' : ''}`}
          >
            <img
              src={closeIcon}
              alt="close icon"
              className="close-icon pointer"
              onClick={this.closePreferencesReminder}
            />
            <h3 data-testid={ATSCandidateHomeDataTestIds.REMINDER_HEADER_TITLE}>
              {this.state.headerText}
            </h3>
            <p>Now, help Nimble match you to jobs that fit your preferences.</p>
            <div onClick={() => (window.location.href = '/preferences')}>
              <a href={'/preferences'}>
                <button className="see-more-jobs-button">Add your job preferences</button>
              </a>
            </div>
          </div>
        );
      } else if (this.state.shouldShowSubmittedHeader) {
        return (
          <div className="came-from-confirmation-page">
            <img
              src={closeIcon}
              alt="close icon"
              className="close-icon pointer"
              onClick={this.closeSubmittedHeader}
            />
            <div>
              <img src={paperAirplane} alt="paper-airplane" />
            </div>
            <div>
              <h4 data-testid={ATSCandidateHomeDataTestIds.PROFILE_UPDATED_TITLE}>
                {this.state.headerText}
              </h4>
            </div>
            <div className="pointer" onClick={this.closeSubmittedHeader}>
              {window.location.search.indexOf('fromEdit') === -1 && (
                <a href={this.jobboardUrl()} target="_blank" rel="noopener noreferrer">
                  <button className="see-more-jobs-button">See more jobs</button>
                </a>
              )}
            </div>
          </div>
        );
      }
    }

    if (
      this.state.shouldShowPreferencesReminder &&
      window.location.search.indexOf('fromConf') === -1 &&
      window.location.search.indexOf('fromEdit') === -1
    ) {
      return (
        <div
          className={`preferences-reminder ${this.state.shouldShowSubmittedHeader ? 'mb2' : ''}`}
        >
          <img
            src={closeIcon}
            alt="close icon"
            className="close-icon pointer"
            onClick={this.closePreferencesReminder}
          />
          <h3>
            Take 5 minutes to{' '}
            {this.state.isCombinedPreferenceAndProfile
              ? 'complete your profile'
              : 'add your job preferences'}
          </h3>
          <p>
            Help Nimble match you to roles that fit your{' '}
            {this.state.isCombinedPreferenceAndProfile ? 'profile.' : 'preferences.'}
          </p>
          <div onClick={() => (window.location.href = '/preferences')}>
            <a href={'/preferences'}>
              <button className="see-more-jobs-button">
                {this.state.isCombinedPreferenceAndProfile
                  ? 'Complete your profile'
                  : 'Add your job preferences'}
              </button>
            </a>
          </div>
        </div>
      );
    }
    if (
      window.location.search.indexOf('preferences') !== -1 &&
      this.state.shouldShowSubmittedHeader
    ) {
      return (
        <div className="came-from-confirmation-page">
          <img
            src={closeIcon}
            alt="close icon"
            className="close-icon pointer"
            onClick={this.closeSubmittedHeader}
          />
          <div>
            <img src={paperAirplane} alt="paper-airplane" />
          </div>
          <div>
            <h4>{this.state.headerText}</h4>
          </div>
          <div className="pointer" onClick={this.closeSubmittedHeader} />
        </div>
      );
    }
    return <div className="normal-header" />;
  };

  setHellosignTaskStatus = (signature_id, status_code) => {
    const signed_at = Math.floor(Date.now() / 1000);
    this.setState((prevState) => {
      const hellosignSignatureTasks = prevState.hellosignSignatureTasks.map((task) =>
        task.signature_id === signature_id ? { ...task, status_code, signed_at } : task
      );
      return {
        ...prevState,
        hellosignSignatureTasks,
      };
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <div className="candidatedashboard">
        <div className="candidatedashboard-logo">
          <img src="/nimble-logo-round.png" alt="nimble logo" />
        </div>
        <div className="header-section">{this.banner()}</div>
        <Container>
          <CandidateDashboardTasks
            user={this.state.user}
            applications={this.state.applications}
            schoolApplications={this.state.schoolApplications}
            hellosignSignatureTasks={this.state.hellosignSignatureTasks}
            setTaskStatusComplete={this.setTaskStatusComplete}
            setHellosignTaskStatus={this.setHellosignTaskStatus}
          />
          <CandidateDashboardApplicationsList
            user={this.state.user}
            applications={this.state.applications}
            totalApplicationsCount={this.state.totalApplicationsCount}
            page={this.state.page}
            isLoading={this.state.isLoading}
            isFetchingNextPage={this.state.isFetchingNextPage}
            setIsFetchingNextPage={(fetching) => this.setState({ isFetchingNextPage: fetching })}
            dispatch={({ type, payload }) => {
              if (type === 'SET_PAGE') {
                this.loadMoreApplications();
              }
            }}
            hasMore={this.state.nextApplicationPage !== null}
            openUploadDocumentModal={this.openUploadDocumentModal}
            withdrawApplication={this.withdrawApplication}
            getApplicationAttachmentsForModal={this.getApplicationAttachmentsForModal}
          />
        </Container>
        <CandidateDocumentModal
          show={this.state.isUploadDocumentModalOpen ? true : false}
          onHide={() => this.setState({ isUploadDocumentModalOpen: false })}
          submitAdditionalDocument={this.submitAdditionalDocument}
          deleteApplicationAttachment={this.deleteApplicationAttachment}
          replaceRequiredAttachment={this.replaceRequiredAttachment}
          attachments={this.state.attachments}
          requiredAttachments={this.state.requiredAttachments}
          handleAttachmentChanges={this.handleAttachmentChanges}
          handleReplaceCandidateProfileResume={this.handleReplaceCandidateProfileResume}
          isLoading={this.state.isLoading}
        />
        {this.state.shouldShowCanceledModal && (
          <AlertDialog
            isOpen={this.state.shouldShowCanceledModal}
            onClose={() => {
              setQueryStringWithoutPageReload('');
              this.setState({ shouldShowCanceledModal: false });
            }}
            text="This signature request has been canceled. Please reach out to the sender for more information."
          />
        )}
      </div>
    );
  }
}

export default withRouter(CandidateDashboardContainer);

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 5rem;
`;
