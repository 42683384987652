import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getQuestionCounts } from 'utils/scorecardutils';
import { scorecardPermissionMap } from 'utils/enums';
import { useHistory } from 'react-router-dom';
import { ViewButton } from 'sharedComponents/Buttons';
import { SettingsRow } from 'components/SettingsRow';
import { Checkbox } from '@mui/material';

const CustomScorecardRow = ({ scorecard, isDefaultScorecard, onDefaultScorecardChange }) => {
  const history = useHistory();

  return (
    <SettingsRow
      cta={<ViewButton />}
      onClick={() => history.push(`/editscorecard/${scorecard.id}/`)}
    >
      <ScorecardTitle>{scorecard.title}</ScorecardTitle>
      <QuestionType>{getQuestionCounts(scorecard.questions)}</QuestionType>
      <LighterTextSection>{scorecardPermissionMap[scorecard.view_permissions]}</LighterTextSection>
      <MyDefaultSection onClick={(e) => e.stopPropagation()}>
        <Checkbox
          sx={{ color: '#909090' }}
          name="scorecard_default"
          checked={isDefaultScorecard}
          onChange={(e) => onDefaultScorecardChange(e.target.checked)}
        />
      </MyDefaultSection>
    </SettingsRow>
  );
};

export default CustomScorecardRow;

CustomScorecardRow.propTypes = {
  scorecard: PropTypes.object.isRequired,
  isDefaultScorecard: PropTypes.bool.isRequired,
  onDefaultScorecardChange: PropTypes.func.isRequired,
};

const ListItem = styled.div`
  padding: 20px 16px;
  height: 70px;
  justify-content: start;
  margin-top: 0;
  position: relative;
  display: flex;
  background-color: white;
  border: 1px solid #eee;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`;

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LighterTextSection = styled(Section)`
  flex: 0 0 19%;
  color: #909090;
`;

const ScorecardTitle = styled(Section)`
  flex: 0 0 32%;
  font-size: 15px;
`;

const QuestionType = styled(LighterTextSection)`
  flex: 0 0 25%;
`;

const MyDefaultSection = styled.div`
  cursor: pointer;

  flex: 0 0 14%;
`;
