import { BasicSelectAndLabel } from 'sharedComponents/Select/BasicSelectAndLabel';
import { Box } from '@mui/material';
import { FormControlContainer } from './styles';
import { Label } from 'sharedComponents/Label';
import { Tooltip } from 'sharedComponents/Tooltip';
import { visibleTo } from 'utils/enums';
import InfoIcon from '@mui/icons-material/Info';
import { Input } from 'sharedComponents/Input';

export const FormInputs = ({
  allTypes,
  emailTemplateSubject,
  handleChange,
  handleTypeChange,
  handleVisibilityChange,
  hasApplicationConfirmationTemplate,
  hasError,
  isCreatingGeneralOrReferenceTemplate,
  isDistrictAdmin,
  shouldTypeSelectBeDisabled,
  isEditingGeneralOrReference,
  titleDefaultValue,
  typeDefaultValue,
  visibilityDefaultValue,
}) => {
  const shouldOptionBeDisabled = (option) => {
    return (
      (option.label === 'Application confirmation' && hasApplicationConfirmationTemplate) ||
      (option.label === 'Application confirmation' && isEditingGeneralOrReference)
    );
  };

  const emailTypeTooltipText = shouldTypeSelectBeDisabled
    ? 'Email type is locked as this application confirmation template is currently in use.'
    : 'Update email type to change when a template can be sent and what variables are available';

  return (
    <>
      <Box>
        <Label labelText="Template Name" />
        <Input
          placeholder={`eg. "Phone Interview Request"`}
          value={titleDefaultValue}
          onChange={handleChange}
          name="title"
          hasError={hasError.name}
          errorText="Template name is a required field"
          sx={{ '.Mui-error': { marginLeft: 0 } }}
          dataTestId="title-input"
        />
      </Box>

      <FormControlContainer style={{ display: 'flex' }}>
        <Box sx={{ width: '40%', paddingRight: '8px' }}>
          <BasicSelectAndLabel
            defaultValue={typeDefaultValue}
            label="Email Type"
            onChange={(event) => handleTypeChange(event)}
            options={allTypes}
            sx={{
              width: '100%',
              backgroundColor: shouldTypeSelectBeDisabled ? '#EEEEEE' : null,
            }}
            handleShouldItemBeDisabled={shouldOptionBeDisabled}
            shouldSelectBeDisabled={shouldTypeSelectBeDisabled}
            tooltip={
              <Tooltip title={emailTypeTooltipText} placement="top">
                <InfoIcon fontSize="small" />
              </Tooltip>
            }
          />
        </Box>
        {isDistrictAdmin && isCreatingGeneralOrReferenceTemplate && (
          <Box sx={{ width: '60%' }}>
            <BasicSelectAndLabel
              defaultValue={visibilityDefaultValue}
              label="Visibility"
              testid="visibilitySelect"
              onChange={(event) => handleVisibilityChange(event)}
              options={visibleTo()}
              sx={{ width: '100%' }}
              tooltip={
                <Tooltip
                  title="Update visibility to change who can send this email"
                  placement="top"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              }
            />
          </Box>
        )}
      </FormControlContainer>
      <FormControlContainer>
        <Label labelText="Subject Line" />
        <Input
          placeholder={`eg. "You've received a Phone Interview Request"`}
          value={emailTemplateSubject}
          onChange={handleChange}
          name="subject"
          hasError={hasError.subject}
          errorText="Subject is a required field"
          sx={{ '.Mui-error': { marginLeft: 0 } }}
          dataTestId="subject-input"
        />
      </FormControlContainer>
    </>
  );
};
