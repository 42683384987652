import PropTypes from 'prop-types';
import cx from 'classnames';

function Button(props) {
  const { className, children, onClick } = props;
  return (
    <button className={cx('button-primary', className)} onClick={onClick}>
      {children}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
