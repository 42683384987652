import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import auth from '../../utils/auth';
import { HeaderLabel, HomepageContentContainer } from 'components/Home/common';
import { ATSBottleneckNotifications } from 'data-testids/ATS';

export default function BottleneckNotifications({ notifications }) {
  if (!notifications) {
    return <div />;
  }

  return (
    <HomepageContentContainer>
      <HeaderLabel data-testid={ATSBottleneckNotifications.BOTTLENECK_HEADER}>
        Bottleneck notifications
      </HeaderLabel>
      <div
        className="content"
        style={{ paddingTop: '30px' }}
        data-testid={ATSBottleneckNotifications.BOTTLENECK_LIST}
      >
        {notifications.length > 0 ? (
          <div>
            <div className="status-message">
              These candidates have been in same status for the past{' '}
              <span style={{ fontWeight: 'bold', color: 'black' }}>5 days</span>.
            </div>
            {notifications.map((status, i) => (
              <Link
                // add f_JS=... because we want to filter for active jobs only
                to={`${auth.isSchoolUser() ? '/school/' : '/district/'}candidates?f_CS=${
                  status.id
                }&days_ago=5&f_JS=1%2C2`}
                key={i}
                className="notification-row warning"
                data-testid={ATSBottleneckNotifications.BOTTLENECK_ROW}
              >
                <div className="notification-text">
                  <div className="notification-count">{status.count}</div>
                  <div>{status.label}</div>
                  {status.top_candidates_count > 0 && (
                    <RedLabel>
                      {`(${status.top_candidates_count} top candidate${
                        status.top_candidates_count === 1 ? '' : 's'
                      })`}
                    </RedLabel>
                  )}
                </div>
                <div className="go-button">Go {'>'}</div>
              </Link>
            ))}
          </div>
        ) : (
          <div>
            <div className="status-message no-notifications">
              You currently have no notifications.
            </div>
          </div>
        )}
      </div>
    </HomepageContentContainer>
  );
}

const RedLabel = styled.i`
  color: #ef5675;
  font-size: 13px;
  margin-left: 10px;
`;

RedLabel.displayName = 'RedLabel';

BottleneckNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};
