import { BaseNimbleAPI } from './baseAPI';

class ReferenceTemplateListAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/reference_templates' };
  }

  async fetchReferences() {
    const response = await this.get('/');
    return response.data;
  }

  async createReference(reference) {
    const response = await this.post('/', reference);
    return response.data;
  }

  async fetchEditableReferences() {
    const response = await this.get('/editable/');
    return response.data;
  }
}

export default new ReferenceTemplateListAPI();
