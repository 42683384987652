import EditIcon from '@mui/icons-material/Edit';
import { IconButton, SxProps } from '@mui/material';

export interface EditButtonProps {
  onClick: () => void;
  sx?: SxProps;
}

export const EditButton: React.FC<EditButtonProps> = ({ onClick, sx }) => {
  return (
    <IconButton onClick={onClick} sx={sx}>
      <EditIcon />
    </IconButton>
  );
};
