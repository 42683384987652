import useSWR from 'swr';

import dashboardMetricsAPI from 'api/dashboardMetricsAPI';

const ENDPOINT = '/api/dashboard-metrics/applications-per-vacancy/';

const defaultData = {
  by_school: [],
  by_school_group: [],
  by_subcategory: [],
  by_category: [],
};

/**
 * Data management wrapper for fetching/caching conversion metrics.
 *
 * Usage: const { data, isLoading, error } = useApplicationsPerVacancyMetrics(filters);
 */
export const useApplicationsPerVacancyMetrics = ({
  hiringSeasons,
  schools = [],
  subcategories = [],
  userInSlowDistrict = false,
}) => {
  // Using ref/memo doesn't work for this library... need to stringify our deps.
  const key = [ENDPOINT, hiringSeasons.toString(), schools.toString(), subcategories.toString()];
  const options = { dedupingInterval: 30000, focusThrottleInterval: 30000 };

  const { data, error } = useSWR(
    key,
    () =>
      // only fire request if user is not in a district that will be slow for metrics
      !userInSlowDistrict
        ? dashboardMetricsAPI.getApplicationsPerVacancyMetrics({
            hiringSeasons,
            schools,
            subcategories,
          })
        : null,
    options
  );

  const isLoading = data === undefined;

  return { data: data ?? defaultData, isLoading, error };
};
