import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'assets/tooltip.svg';
import { BasicLocationPreferencesProps } from '../../types/types';
import { LeftAlignedDiv, StyledCheckbox } from '.';

const BasicLocationPreferences: React.FC<BasicLocationPreferencesProps> = (props) => {
  return (
    <div className="section">
      <h4>Location</h4>
      <div className="flex space-between flex-center preferences-container__location-section-content">
        <p>I prefer to work within</p>
        <div className="select-form-field">
          <select
            className="pointer"
            name="miles_within"
            value={props.milesWithin}
            onChange={props.handleChangeSelect}
          >
            <option value="">Select</option>
            {['Any', '5', '10', '25', '50', '75', '100'].map((i, key) => (
              <option value={i} key={key}>
                {i}
              </option>
            ))}
          </select>
        </div>
        <p>miles of</p>
        <div className="basic-select flex-1">
          <input
            name="location"
            className="form-field"
            placeholder="zip code or address"
            value={props.location || ''}
            onChange={props.handleChange}
          />
        </div>
      </div>
      {props.combinedPreferenceAndProfile &&
        (props.showMapErrorInput || props.showMapErrorSelect) && (
          <ErrorMessage>Please select both a distance and location</ErrorMessage>
        )}
      {props.combinedPreferenceAndProfile && (
        <LeftAlignedDiv>
          <div className="location-content" style={{ paddingTop: '12px', paddingBottom: '20px' }}>
            <StyledCheckbox
              checked={props.checked || false}
              onChange={props.handleCheckboxChange}
            />
            <p style={{ padding: '0 6px', fontWeight: '700' }}>
              Visible to recruiters outside of your selected radius
            </p>
            <img
              src={Tooltip}
              alt="tooltip"
              data-tip={`
                        This will help determine which recruiters <br/>
                        are able see your candidate profile.<br/>
                        `}
              data-for="hrbp-assignments"
            />
            <ReactTooltip id="hrbp-assignments" effect="solid" multiline />
          </div>
        </LeftAlignedDiv>
      )}
    </div>
  );
};

export default BasicLocationPreferences;

const ErrorMessage = styled.div`
  color: #e64c66;
  font-size: 0.85em;
  height: 1.5em;
`;
