import { BaseNimbleAPI } from './baseAPI';

class DistrictAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/district' };
  }

  async getDistrictAndSuperAdmins(district_id) {
    const response = await this.get(`/${district_id}/get_district_and_super_admins/`);
    return response.data;
  }

  /**
   * Fetch a district
   */
  async getDistrict(districtId) {
    const url = `/${districtId}/`;
    const response = await this.get(url);
    return response.data;
  }
}

export default new DistrictAPI();
