import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const EndOfResultsCard: React.FC = () => {
  return (
    <EndOfResultsCardContainer>
      <EndOfResultsCardText>{"You've reached the end of the results."}</EndOfResultsCardText>
    </EndOfResultsCardContainer>
  );
};

const EndOfResultsCardContainer = styled(Box)(() => ({
  borderRadius: '8px',
  border: '1px solid #EAECF0',
  background: ' #FFF',
  display: 'flex',
  height: '92px',
  padding: '32px',
  alignContent: 'center',
  justifyContent: 'center',
  gap: '20px',
}));

const EndOfResultsCardText = styled(Typography)(() => ({
  color: '#101828',
  textAlign: 'center',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px',
}));
