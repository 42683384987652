import PropTypes from 'prop-types';
import moment from 'moment';

import CompleteIcon from 'assets/icons/TaskIcons/green-circle-checkmark.svg';
import IncompleteIcon from 'assets/icons/TaskIcons/incomplete-circle.svg';
import OverdueIcon from 'assets/icons/TaskIcons/overdue-red-circle.svg';

export default function SignerTaskRow({ task }) {
  const isComplete = task.status_code === 'signed';
  return (
    <div className="task-row">
      <div className="flex">
        <img
          className="mr1"
          style={{
            height: '21px',
            width: '20px',
          }}
          src={
            isComplete
              ? CompleteIcon
              : task.due_date && moment(Date.now()).isAfter(task.due_date)
                ? OverdueIcon
                : IncompleteIcon
          }
          alt=""
        />
        <div>
          <span className={isComplete ? 'strikethrough' : ''}>{`Sign ${task.title}`}</span>
          {!isComplete && task.due_date && (
            <span>&nbsp; - due on {moment(task.due_date).format('M/D/YYYY')} by Candidate</span>
          )}
          {isComplete && task.signed_at && (
            <span>
              &nbsp;(completed by Candidate on {moment(task.signed_at * 1000).format('M/D/YYYY')})
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

SignerTaskRow.propTypes = {
  task: PropTypes.object.isRequired,
};
