import styled from 'styled-components';

/**
 * A Dashboard widget toolkit
 *
 * Usage:
 *
 * <Heading>MY GHETTO WIDGET</Heading>
 * <Body>
 *    <BodyActions>Filters, etc.</BodyActions>
 *    <BodyContent>My hacky chart or whatever</BodyContent>
 * <Body>
 */

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-flow: column nowrap;
`;

export const Heading = styled.h3`
  font-size: 1.5rem;
  line-height: 1.368;

  margin-bottom: 10px;
  color: #444;
`;

export const Body = styled.div`
  padding: 15px;

  background-color: #fff;

  border-radius: 3px;
  border: 1px solid #dcdcdc;

  flex: 1;

  display: flex;
  flex-flow: column nowrap;
`;

export const BodyActions = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  margin-bottom: 1em;
`;

export const BodyActionPulledRight = styled.div`
  margin-left: auto;
`;

export const LoadingWrapper = ({ isLoading, message, children, ...rest }) => {
  if (isLoading) {
    return (
      <LoadingSpinner>
        <i className="fa fa-circle-o-notch fast-spin fa-4x fa-fw" />
      </LoadingSpinner>
    );
  }

  if (message) {
    return <ChartMessage>{message}</ChartMessage>;
  }

  return <div {...rest}>{children}</div>;
};

const LoadingSpinner = styled.div`
  flex: 1;
  margin-bottom: 3em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
`;

export const BodyContent = styled(LoadingWrapper)`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;
