import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import merge from '@mui/system/merge';
import React from 'react';
import { NotificationBannerProps } from './types';

export const NotificationBanner: React.FC<NotificationBannerProps> = ({
  clickable,
  onClickFunction,
  label,
  sx,
  dataTestId,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const customStyles: SxProps = {
    width: '100%',
    minHeight: '50px',
    bgcolor: 'rgb(6, 8, 16)',
    color: '#ffffff',
    position: 'sticky',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      cursor: clickable ? 'pointer' : '',
    },
    top: 0,
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const mergedStyles = sx ? merge(customStyles, sx) : customStyles;

  return (
    <Box
      onClick={clickable ? onClickFunction : undefined}
      data-testid={dataTestId}
      sx={mergedStyles}
    >
      <NotificationsIconWithMargin />
      {label}
    </Box>
  );
};

const NotificationsIconWithMargin = styled(NotificationsIcon)({
  marginRight: '10px',
});
