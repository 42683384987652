import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { ErrorText } from 'components/CreateJobOrTemplateModal';
import { nimbleTheme } from 'theme';

export const Bottom = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: nimbleTheme.spacing(4),
  [nimbleTheme.breakpoints.down('md')]: {
    marginTop: nimbleTheme.spacing(2),
  },
});

export const CTAContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: nimbleTheme.spacing(6),
  [nimbleTheme.breakpoints.down('md')]: {
    marginTop: nimbleTheme.spacing(2),
  },
});

export const DescriptionText = styled(Typography)({
  fontSize: nimbleTheme.typography.htmlFontSize,
  width: '50%',
});

export const EditDeleteButtonContainer = styled(Box)({
  display: 'flex',
});

export const ElementAndErrorText = styled(Box)({
  width: '50%',
});

export const FormElements = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: nimbleTheme.spacing(1),
});

export const Middle = styled(Box)({
  backgroundColor: 'rgba(0,128,109, 0.05)',
  borderRadius: nimbleTheme.shape.borderRadius * 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: nimbleTheme.spacing(2),
  padding: `${nimbleTheme.spacing(2)} ${nimbleTheme.spacing(4)}`,
  [nimbleTheme.breakpoints.down('md')]: {
    padding: nimbleTheme.spacing(3),
  },
});

export const ModalBody = styled(Box)({
  fontSize: nimbleTheme.typography.htmlFontSize,
  letterSpacing: '0.15px',
  paddingTop: nimbleTheme.spacing(6),
  [nimbleTheme.breakpoints.down('md')]: {
    paddingTop: nimbleTheme.spacing(2),
  },
});

export const ModalTitle = styled(Box)({
  display: 'flex',
  fontWeight: nimbleTheme.typography.fontWeightMediumBold,
  justifyContent: 'center',
  lineHeight: '160%',
});

export const modalStyles = {
  borderRadius: nimbleTheme.shape.borderRadius * 2,
  maxWidth: '80%',
  paddingX: nimbleTheme.spacing(8),
  paddingY: nimbleTheme.spacing(6),
  position: 'fixed',
  width: '678px !important',
  [nimbleTheme.breakpoints.down('md')]: {
    maxWidth: '90%',
    padding: nimbleTheme.spacing(3),
  },
};

export const NewHeading = styled(Box)({
  alignSelf: 'end',
  color: nimbleTheme.palette.text.secondary,
  display: 'flex',
  fontSize: nimbleTheme.typography.htmlFontSize,
  fontWeight: nimbleTheme.typography.fontWeightMediumBold,
  justifyContent: 'space-between',
});

export const NumberInput = styled(TextField)({
  backgroundColor: 'white',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});

export const StyledErrorText = styled(ErrorText)({
  display: 'flex',
  marginLeft: 0,
  paddingTop: nimbleTheme.spacing(0.5),
});

export const StyledLinkButton = styled(Button)({
  color: nimbleTheme.palette.black.main,
  fontSize: nimbleTheme.typography.subtitle1.fontSize,
  margin: 0,
  minWidth: 0,
  opacity: '56%',
  padding: 0,
  paddingLeft: nimbleTheme.spacing(1),
  paddingRight: nimbleTheme.spacing(1),
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: '100%',
    textDecoration: 'underline',
  },
});
