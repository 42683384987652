import { useState, useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FlatFileTransferPropsInterface } from './../types/types';
import DeleteModal from '../../../components/JobEdit/DeleteModal';
import { leavePageWarning } from 'utils/message';
import { ATSDataFFTDataTestIds } from '../../../data-testids/ATS';

export const DashboardFooterContainer: React.FC<FlatFileTransferPropsInterface> = ({
  isSaveDisabled = true,
  isSaving,
  isDownloading,
  setIsSaving = () => {},
  setIsDownloading = () => {},
  saveFlatFileTransfer = () => {},
  handleTestFileDownload = () => {},
}) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);

  const agreeAndContinueMessage =
    'Nimble assumes no responsibility for information downloaded, exported or transferred from the Nimble Platform. Any data downloaded, exported or transferred from the Nimble Platform is provided “AS IS” and Nimble hereby disclaims all warranties, whether express, implied, statutory, or otherwise. Nimble specifically disclaims all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and all warranties arising from course of dealing, usage, or trade practice. Nimble makes no warranty of any kind that the Nimble Platform will operate without interruption, achieve any intended result, be compatible or work with any software, system, or other services, or be secure, accurate, complete, free of harmful code, or error free.';

  const agreeAndSave = () => {
    setIsSaving(true);
    setIsSaveModalOpen(false);
    saveFlatFileTransfer();
  };

  const downloadTest = () => {
    setIsDownloading(true);
    handleTestFileDownload();
  };

  const pauseNavigationAndAlertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const history = useHistory();

  const isDownloadDisabled = () => {
    return !isSaveDisabled;
  };

  useEffect(() => {
    if (!isSaveDisabled) {
      window.addEventListener('beforeunload', pauseNavigationAndAlertUser);
      return () => {
        window.removeEventListener('beforeunload', pauseNavigationAndAlertUser);
      };
    }
    return () => {};
  }, [isSaveDisabled]);

  return (
    <FooterContainer>
      <Prompt message={leavePageWarning} when={!isSaveDisabled} />
      <BackButton onClick={() => history.push('/data/datatransfer/')}>
        &larr; Back to Data Transfer List
      </BackButton>
      <ButtonContainerRight>
        <ProgressBarContainer></ProgressBarContainer>
        <DownloadTestButton
          data-testid={ATSDataFFTDataTestIds.DOWNLOAD_TEST_BUTTON}
          onClick={downloadTest}
          disabled={isDownloadDisabled()}
        >
          <span> Download Test </span>
          <span>
            {isDownloading ? (
              <LoadingWrapper isDownloading={isDownloading}></LoadingWrapper>
            ) : (
              <LoadingSpinnerPlaceholderDiv />
            )}
          </span>
        </DownloadTestButton>

        <SaveButton
          disabled={isSaveDisabled}
          onClick={() => setIsSaveModalOpen(true)}
          data-testid={ATSDataFFTDataTestIds.SAVE_BUTTON}
        >
          <span> Save </span>
          <span>
            {isSaving ? (
              <LoadingWrapper isSaving={isSaving}></LoadingWrapper>
            ) : (
              <LoadingSpinnerPlaceholderDiv />
            )}
          </span>
        </SaveButton>
      </ButtonContainerRight>
      {isSaveModalOpen && (
        <DeleteModal
          show={isSaveModalOpen}
          onHide={() => setIsSaveModalOpen(false)}
          onSave={agreeAndSave}
          title={'Disclaimer'}
          message={agreeAndContinueMessage}
          submitLabel={'Agree & Continue'}
        />
      )}
    </FooterContainer>
  );
};

export const LoadingWrapper: React.FC<{ isDownloading?: boolean; isSaving?: boolean }> = ({
  isDownloading,
  isSaving,
  ...rest
}) => {
  if (isSaving || isDownloading) {
    return (
      <LoadingSpinner>
        <i className="fa fa-circle-o-notch fast-spin fa-2x fa-fw" />
      </LoadingSpinner>
    );
  }

  return <div {...rest}></div>;
};

const LoadingSpinner = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  width: 100vw;
  height: 76px;
  bottom: 60px;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding: 28px 125px 0px 162px;
  justify-content: space-between;
  gap: 8px;
`;

export const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 12px 55px;
  gap: 11px;

  width: 170px;
  height: 48px;

  background: ${(props) => (props.disabled ? '#F2F2F2' : '#00B88D')};
  border: 1px solid ${(props) => (props.disabled ? '#F2F2F2' : '#00B88D')};
  border-radius: 2px;
  text-align: center;

  font-family: 'Open Sans';
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => (props.disabled ? '#BDBDBD' : '#ffffff')};

  &:hover {
    background: ${(props) => (props.disabled ? '#F2F2F2' : '#00A37D')};
  }
`;

export const DownloadTestButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;

  width: 165px;
  height: 48px;

  background: #ffffff;
  border: none;

  font-family: 'Open Sans';
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => (props.disabled ? '#BDBDBD' : '#4f4f4f')};
  text-align: center;

  &:hover {
    opacity: ${(props) => (props.disabled ? '1' : '0.6')};
  }
`;

export const ProgressBarContainer = styled.div`
  display: Flex;
  width: 256px;
`;

export const BackButton = styled.button`
  width: 300px;
  height: 48px;

  background: transparent;
  border: none;

  font-family: 'Open Sans';
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: #4f4f4f;
  text-align: left;
`;

export const ButtonContainerRight = styled.div`
  display: flex;
  flex-wrap: none;
  flex-direction: row;
`;

const LoadingSpinnerPlaceholderDiv = styled.div`
  display: flex;
  width: 40px;
`;
