import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-system';
import PropTypes from 'prop-types';

import { scorecardQType } from 'utils/enums';
import ErrorText from '../../errortext';

import { AltModal, DropdownWithInputFilter } from 'ui-kit';

import ScorecardEditorQuestions from './ScorecardEditorQuestions';

import scorecardAPI from 'api/scorecardAPI';
import scorecardAnswerAPI from 'api/scorecardAnswerAPI';

import useDebounce from 'hooks/useDebounce';
import { ATSCandidateScorecardDataTestIds } from 'data-testids/ATS';

const isAttachment = (answer) => answer.question_type === scorecardQType.attachment;

export default function ScorecardEditor({
  mode,
  scorecardToEdit,
  customScorecards,
  adminUsers,
  onBack,
  deleteScorecard,
  scorecardPostSave,
  emailTaggedUsers,
  candidate,
  incrementScorecardCount,
  defaultScorecardId,
  application,
}) {
  const [scorecard, setScorecard] = useState({
    answers: [],
  });
  const [customScorecard, setCustomScorecard] = useState({
    questions: [],
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* When autosaving, delay ten seconds before sending another POST */
  const [autosave] = useDebounce(() => {
    onSave(true);
  }, 10000);

  const getMaxCumulativeScore = useCallback((customScorecard, question) => {
    let maxScore = 0;
    // Loop through other questions in the template and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    customScorecard.questions.forEach((item) => {
      if (item.question_type === scorecardQType.rating && question.include_rating_questions) {
        maxScore += item.scale;
      }
      if (item.question_type === scorecardQType.rubric && question.include_rubric_questions) {
        // scoreToAdd will be the largest number in the rubric, which is found by looking
        // at the "number" property of the last column
        let scoreToAdd = item.rubric_columns[item.rubric_columns.length - 1].number;
        maxScore += scoreToAdd;
      }
    });

    return maxScore;
  }, []);

  const makeNewScorecardFromTemplate = useCallback(
    (customScorecard) => {
      const scorecard = {
        answers: [],
        include_cumulative_score: false,
        application: application,
        is_universal: false,
      };

      customScorecard.questions.forEach((question) => {
        // Copy over the question, but not its id. This is easier than
        // copying each field besides id explicitly.
        const newAnswer = {
          ...question,
          question_id: question.id,
        };
        delete newAnswer.id;

        switch (question.question_type) {
          case scorecardQType.multiple_choice:
            newAnswer.mc_answer = [];
            break;
          case scorecardQType.nimble:
          case scorecardQType.text:
            newAnswer.answer_text = '';
            break;
          case scorecardQType.rating:
            newAnswer.answer_rating = null;
            break;
          case scorecardQType.direction_text:
            // no additional answer info for direction text type, it's just a set of
            // directions for the editor to read.
            break;
          case scorecardQType.rubric:
            // answer_rubric will store the number of the selection.
            // If the question is not required and the editor did not select anything,
            // it will be null.
            newAnswer.answer_rubric = null;
            break;
          case scorecardQType.final_recommendation:
            // Options are yes and no, with maybe being an option if the admin
            // includes it in the template. Start off with nothing selected (null).
            // final recommendation information is stored at the scorecard level
            // because there can only be one recommendation max per scorecard.
            scorecard.include_final_recommendation = true;
            scorecard.include_maybe_option = question.include_maybe_option;
            scorecard.show_answer_on_preview = question.show_answer_on_preview;
            scorecard.final_recommendation = null;
            break;
          case scorecardQType.cumulative_score:
            // similar to final recommendation, there can only be one cumulative score per
            // scorecard, so cumulative score information is stored at the scorecard level.
            scorecard.include_cumulative_score = true;
            scorecard.include_rating_questions = question.include_rating_questions;
            scorecard.include_rubric_questions = question.include_rubric_questions;
            scorecard.show_total_on_preview = question.show_total_on_preview;
            scorecard.cumulative_score = 0;
            scorecard.max_cumulative_score = getMaxCumulativeScore(customScorecard, question);
            break;
          case scorecardQType.attachment:
            // Start with no value in the attachment
            newAnswer.attachment = null;
            break;
          default:
            break;
        }

        scorecard.answers.push(newAnswer);
      });

      return scorecard;
    },
    [getMaxCumulativeScore, application]
  );

  const getDefaultScorecardTemplate = useCallback(() => {
    const defaultTemplate = customScorecards.find((s) => s.id === defaultScorecardId);
    return defaultTemplate;
  }, [customScorecards, defaultScorecardId]);

  useEffect(() => {
    let scorecard;
    if (scorecardToEdit.id) {
      scorecard = { ...scorecardToEdit };
      setScorecard(scorecard);
    } else if (customScorecards.length > 0 && defaultScorecardId) {
      // Make a new scorecard based on the chosen customScorecard.
      // If user has a default scorecard template, use that.
      const customScorecard = getDefaultScorecardTemplate();

      if (customScorecard) {
        setCustomScorecard(customScorecard);
        scorecard = makeNewScorecardFromTemplate(customScorecard);
        setScorecard(scorecard);
      }
    }
  }, [
    scorecardToEdit,
    customScorecards,
    defaultScorecardId,
    getDefaultScorecardTemplate,
    makeNewScorecardFromTemplate,
  ]);

  const updateField = (e, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].answer_text = e.target.value;

    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const updateRating = (e, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].answer_rating = e.target.value;
    const cumulativeScore = getCumulativeScore(updatedScorecard);
    updatedScorecard.cumulative_score = cumulativeScore;

    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const updateMultipleChoiceField = (e, index, isMultiSelect) => {
    const updatedScorecard = { ...scorecard };
    const multipleChoiceAnswers = updatedScorecard.answers[index].mc_answer;

    const foundIndex = multipleChoiceAnswers.findIndex((a) => a === e.target.value);
    if (foundIndex > -1) {
      // answer is already selected, so remove it
      multipleChoiceAnswers.splice(foundIndex, 1);
    } else {
      // Answer is not already selected.
      // If it's not a multi-select, we have to deselect the other options.
      if (!isMultiSelect) {
        // setting length to 0 empties the array without reassigning it to a new one
        multipleChoiceAnswers.length = 0;
      }
      // add in new answer at end.
      multipleChoiceAnswers.push(e.target.value);
    }

    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const updateFinalRecommendation = (value) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.final_recommendation = value;
    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const updateRubricSelection = (value, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].answer_rubric = value;
    const cumulativeScore = getCumulativeScore(updatedScorecard);
    updatedScorecard.cumulative_score = cumulativeScore;
    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const uploadAttachment = (fileData, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].attachment = fileData;
    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const removeAttachment = (e, index) => {
    e.preventDefault();
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].attachment = null;
    setScorecard(updatedScorecard);
    setErrorMessage('');
    autosave();
  };

  const getCumulativeScore = (scorecard) => {
    let cumulativeScore = 0;

    // Loop through other answers in the Reference/Scorecard and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    scorecard.answers.forEach((item) => {
      if (item.question_type === scorecardQType.rating && scorecard.include_rating_questions) {
        cumulativeScore += Number(item.answer_rating) ?? 0;
      }
      if (item.question_type === scorecardQType.rubric && scorecard.include_rubric_questions) {
        cumulativeScore += item.answer_rubric ?? 0;
      }
    });

    return cumulativeScore;
  };

  const onSave = (isAutosave = false, isDraft = false) => {
    if (!customScorecard.id) {
      setErrorMessage('Please select a scorecard form');
      return;
    }

    try {
      checkForErrors(isAutosave, isDraft);
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    setEmptyRatingAnswers();

    if (isSubmitting) {
      return;
    } else {
      setIsSubmitting(true);
    }

    if (scorecard.id) {
      updateScorecard(isAutosave, isDraft);
    } else {
      createScorecard(isAutosave, isDraft);
    }
  };

  const checkForErrors = (isAutosave, isDraft) => {
    scorecard.answers.forEach((answer) => {
      if (answer.question_type === scorecardQType.rating) {
        checkRating(answer, isAutosave, isDraft);
      }

      if (
        // If it's an autosave or draft save we're just gonna take what the user gives us
        !isAutosave &&
        !isDraft &&
        answer.is_required &&
        ((answer.question_type === scorecardQType.attachment && !answer.attachment) ||
          (answer.question_type === scorecardQType.text && !answer.answer_text) ||
          (answer.question_type === scorecardQType.nimble && !answer.answer_text) ||
          (answer.question_type === scorecardQType.multiple_choice && !answer.mc_answer.length) ||
          (answer.question_type === scorecardQType.rubric && answer.answer_rubric === null) ||
          (answer.question_type === scorecardQType.final_recommendation &&
            !scorecard.final_recommendation))
      ) {
        throw new Error('Please fill out all required fields.');
      }
    });
  };

  const checkRating = (answer, isAutosave, isDraft) => {
    /**
     * Rating answers will be one of the following:
     * 1. Empty string (if number typed in and then backspaced)
     * 2. null
     * 3. A number (possibly not in the allowed range (0 - <answer.scale>))
     */
    const isFinalSubmission = !isAutosave && !isDraft;

    if (answer.answer_rating === '') {
      if (answer.is_required && isFinalSubmission) {
        throw new Error('Please fill out all required fields.');
      }
    } else if (answer.answer_rating === null) {
      if (answer.is_required && isFinalSubmission) {
        throw new Error('Please fill out all required fields.');
      }
    } else {
      // The answer is a number, so make sure it's in the allowed range,
      // even for drafts and autosaves. We don't want to allow a draft
      // save that has negative numbers or crazy high numbers.
      let rating = Number(answer.answer_rating);
      if (rating > answer.scale || rating < 0) {
        throw new Error('Rating score must be in the allowed range.');
      }
    }
  };

  const setEmptyRatingAnswers = () => {
    // The backend stores empty ratings as null, but they're sometimes empty strings
    // on the frontend due to html input values. Set all to null.
    const newAnswers = scorecard.answers.map((answer) =>
      answer.answer_rating === '' ? { ...answer, answer_rating: null } : answer
    );
    scorecard.answers = newAnswers;
  };

  const onDelete = () => {
    // If the card hasn't been saved yet, we just go back a page
    if (!scorecard.id) {
      onBack();
    } else {
      deleteScorecard(scorecard.id);
    }
  };

  const createScorecard = (isAutosave = false, isDraft = false) => {
    const finalScorecard = {
      ...scorecard,
      candidate_id: candidate.id,
      custom_scorecard_id: customScorecard.id,
      scorecard_complete: isAutosave || isDraft ? false : true,
    };

    // Answers are split into two groups: attachment answers and non-attachment answers.
    // This is because attachment answers are submitted as form data and weird results
    // were occurring when trying to submit all answers (even non attachments) as form data.
    const attachmentAnswers = finalScorecard.answers.filter(isAttachment);
    finalScorecard.answers = finalScorecard.answers.filter((answer) => !isAttachment(answer));

    scorecardAPI
      .create(finalScorecard)
      .then(async (newScorecard) => {
        const updatedScorecard = {
          // take id and other fields from newScorecard
          ...newScorecard,
          // take answers from existing scorecard in case the user made edits while
          // the scorecard was saving.
          ...scorecard,
        };

        const answerMapping = {};

        // wait for all attachments to post before moving on
        for (let answer of attachmentAnswers) {
          const formData = getFormData(answer);
          const newAttachment = await scorecardAPI.createAttachment(newScorecard.id, formData);
          // store the attachments so we can access them easily below by looking
          // up question_id.
          answerMapping[newAttachment.question_id] = newAttachment;
        }

        // At this point, new answers have been created but they don't have ids.
        // They can't be added as they are because they're not in the proper order
        // due to them being sent as two separate groups (see above). So iterate
        // over the answers in state, which are in order, and find their id that way.
        updatedScorecard.answers = updatedScorecard.answers.map((answer) => {
          if (isAttachment(answer)) {
            return answerMapping[answer.question_id];
          } else {
            // find answer using question_id and add the id to our answer in state
            const answerWithId = newScorecard.answers.find(
              (newAnswer) => newAnswer.question_id === answer.question_id
            );
            return {
              id: answerWithId.id,
              ...answer,
            };
          }
        });

        // now that all answers have an id, save the answer order
        await scorecardAPI.setAnswerOrder(updatedScorecard);

        setScorecard(updatedScorecard);

        if (!isAutosave && !isDraft) {
          tagUsers(newScorecard.id);
          incrementScorecardCount(candidate.id);
        }
        scorecardPostSave(isAutosave);
      })
      .finally(() => setIsSubmitting(false));
  };

  const getFormData = (answer) => {
    const formData = new FormData();
    if (answer.attachment instanceof File) {
      formData.append('attachment', answer.attachment, answer.attachment.name);
    } else {
      // file is empty
      formData.append('attachment', '');
    }
    formData.append('is_required', answer.is_required);
    formData.append('question_id', answer.question_id);
    formData.append('question_type', answer.question_type);
    formData.append('question_text', answer.question_text);
    formData.append('attachment_directions', answer.attachment_directions);
    return formData;
  };

  const updateScorecard = (isAutosave = false, isDraft = false) => {
    const finalScorecard = {
      ...scorecard,
      scorecard_complete: isAutosave || isDraft ? false : true,
    };

    const attachmentAnswers = finalScorecard.answers.filter(isAttachment);
    finalScorecard.answers = finalScorecard.answers.filter((answer) => !isAttachment(answer));

    attachmentAnswers.forEach(handleAttachmentAnswerUpdate);

    scorecardAPI
      .update(finalScorecard)
      .then((updatedScorecard) => {
        if (!isAutosave && !isDraft) {
          tagUsers(updatedScorecard.id);
        }
        scorecardPostSave(isAutosave);
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleAttachmentAnswerUpdate = (answer) => {
    if (typeof answer.attachment === 'string') {
      // This attachment has already been uploaded, so move on to the next iteration.
      return;
    } else {
      const formData = getFormData(answer);

      if (answer.id) {
        scorecardAnswerAPI.update(answer.id, formData);
      } else {
        scorecardAPI.createAttachment(scorecard.id, formData);
      }
    }
  };

  const tagUsers = (id) => {
    const regExp = /@\[(.*?)\]/g; // checking for names in the following format: @[First Last]
    const usersAlreadyTagged = new Set();

    // Users can only be tagged in text responses.
    scorecard.answers
      .filter(
        (answer) =>
          answer.question_type === scorecardQType.text ||
          answer.question_type === scorecardQType.nimble
      )
      .forEach((answer) => {
        const finalUserList = answer.answer_text.match(regExp) || [];
        const finalTagsForThisAnswer = [];

        // for each tag string, find the full user object in props.adminUsers
        finalUserList.forEach((tagName) => {
          // change "@[First Last]" to "First Last"
          const name = tagName.substring(2, tagName.length - 1);
          const userObject = adminUsers.find((admin) => admin.display === name);
          if (userObject && !usersAlreadyTagged.has(userObject.id)) {
            finalTagsForThisAnswer.push(userObject);
            usersAlreadyTagged.add(userObject.id);
          }
        });

        let tagType = 'scorecard';
        emailTaggedUsers(finalTagsForThisAnswer, answer.answer_text, tagType, id);
      });
  };

  const changeScorecardTemplate = (value) => {
    const customScorecard = customScorecards.find((template) => template.id === value);
    const scorecard = makeNewScorecardFromTemplate(customScorecard);
    setScorecard(scorecard);
    setCustomScorecard(customScorecard);
    setErrorMessage('');
  };

  const saveAsDraft = () => {
    onSave(false, true);
  };

  const submit = () => {
    // non autosave, non draft
    onSave(false, false);
  };

  const editFooterButtons = () => (
    <>
      <DeleteButton onClick={onDelete}>Delete Scorecard</DeleteButton>
      <CancelButton onClick={onBack}>Cancel changes</CancelButton>
    </>
  );

  const createFooterButtons = () => (
    <>
      <SaveAsDraftButton
        onClick={saveAsDraft}
        disabled={
          errorMessage !== '' &&
          // allow user to save as draft without all required fields
          errorMessage !== 'Please fill out all required fields.'
        }
      >
        Save as Draft
      </SaveAsDraftButton>
      <CancelButton onClick={onDelete}>{'Cancel & delete'}</CancelButton>
    </>
  );

  return (
    <>
      <AltModal.Body>
        {mode === 'create' && (
          <DropdownSectionContainer>
            <HeaderText data-testid={ATSCandidateScorecardDataTestIds.EDITOR_CREATE_HEADER_TEXT}>
              Select scorecard form
            </HeaderText>
            <DropdownWithInputFilter
              options={customScorecards.map((template) => ({
                value: template.id,
                label: template.title,
              }))}
              value={customScorecard.id || ''}
              handleChange={changeScorecardTemplate}
              placeholder="Type or select a template"
              boxShadow={false}
              dataTestId={ATSCandidateScorecardDataTestIds.EDITOR_DROPDOWN}
            />
          </DropdownSectionContainer>
        )}

        <TitleText data-testid={ATSCandidateScorecardDataTestIds.TITLE_TEXT}>
          {scorecard.template_name ?? customScorecard.title}
        </TitleText>

        <ScorecardEditorQuestions
          scorecard={scorecard}
          updateField={updateField}
          updateFinalRecommendation={updateFinalRecommendation}
          updateMultipleChoiceField={updateMultipleChoiceField}
          updateRating={updateRating}
          updateRubricSelection={updateRubricSelection}
          adminUsers={adminUsers}
          uploadAttachment={uploadAttachment}
          removeAttachment={removeAttachment}
        />
      </AltModal.Body>

      <FooterButtonsGridContainer gridTemplateColumns={['1fr', '1fr', '296px 371px']}>
        <GridSubContainer gridTemplateColumns={['1fr', '1fr', '150px 130px']}>
          {(mode === 'edit' || mode === 'completing') && editFooterButtons()}
          {mode === 'create' && createFooterButtons()}
        </GridSubContainer>
        <SubmitContainer gridTemplateColumns={['1fr', '1fr', '177px 186px']}>
          <ErrorText
            dataTestId={ATSCandidateScorecardDataTestIds.SUBMIT_ERROR_MESSAGE}
            message={errorMessage}
          />
          <SubmitButton
            onClick={submit}
            data-testid={ATSCandidateScorecardDataTestIds.SUBMIT_BUTTON}
          >
            {mode === 'edit' || mode === 'completing' ? 'Update Scorecard' : 'Complete & Submit'}
          </SubmitButton>
        </SubmitContainer>
      </FooterButtonsGridContainer>
    </>
  );
}

ScorecardEditor.propTypes = {
  mode: PropTypes.string.isRequired,
  scorecardToEdit: PropTypes.object.isRequired,
  customScorecards: PropTypes.arrayOf(PropTypes.object).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.object),
  onBack: PropTypes.func,
  deleteScorecard: PropTypes.func,
  scorecardPostSave: PropTypes.func.isRequired,
  emailTaggedUsers: PropTypes.func.isRequired,
};

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 11px;

  color: #444444;
`;

const TitleText = styled(HeaderText)`
  font-size: 18px;
  margin-bottom: 24px;
`;

const DropdownSectionContainer = styled.div`
  margin-bottom: 40px;
`;

const FooterButton = styled.button`
  width: 100%;
  height: 50px;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const DeleteButton = styled(FooterButton)`
  border: 1px solid #ef5675;
  color: #ef5675;
`;

const SaveAsDraftButton = styled(FooterButton)`
  border: 1px solid #cacaca;
  color: #cacaca;
`;

const CancelButton = styled(FooterButton)`
  border: none;
  background: transparent;
  color: #c2c2c2;
`;

const SubmitButton = styled(FooterButton)`
  background-color: #00b88d;
  border: none;
  font-weight: 600;
  color: #ffffff;

  grid-column: 2 / span 1;
`;

const FooterButtonsGridContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid
);

const GridSubContainer = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
  },
  grid
);

const SubmitContainer = styled.div(
  {
    display: 'grid',
    gridGap: '8px',
    alignItems: 'center',
  },
  grid
);
