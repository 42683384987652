import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import SchoolApplicationsModal from './SchoolApplicationsModal';
import Pencil from '../../../assets/icon-edit-pencil.svg';
import { nimbleTheme } from 'theme';
import { ATSCandidateQuickviewTestIds } from 'data-testids/ATS';

export default class SchoolapplicationsNote extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    role: PropTypes.object.isRequired,
    schoolapplications: PropTypes.array.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    isEditable: PropTypes.bool.isRequired,
    replaceCandidateInState: PropTypes.func.isRequired,
    fetchEvents: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      schoolsModalOpen: false,
    };
  }

  render() {
    return (
      <div className="applications-note edit-schools">
        <VisibleBox className="applications-note-text">
          This application is visible to{' '}
          <span
            className="underline"
            data-tip
            data-for="schools"
            data-testid={ATSCandidateQuickviewTestIds.SCHOOL_VISIBILITY_COUNT}
          >
            {this.props.schoolapplications.length} school
            {this.props.schoolapplications.length === 1 ? '' : 's'}
          </span>
          .
        </VisibleBox>
        {this.props.schoolapplications.length > 0 && (
          <ReactTooltip
            id="schools"
            effect="solid"
            place="bottom"
            clickable
            delayHide={500}
            delayUpdate={500}
          >
            <StyledTooltip>
              {this.props.schoolapplications.map((schoolapp) => {
                return (
                  <TooltipRow
                    key={schoolapp.id}
                    data-testid={ATSCandidateQuickviewTestIds.SCHOOL_TOOLTIP_TEXT_ROW}
                  >
                    {`${schoolapp.school.name} (${schoolapp.new_status.label})`}
                  </TooltipRow>
                );
              })}
            </StyledTooltip>
          </ReactTooltip>
        )}

        {this.props.isEditable && (
          <img
            onClick={() => this.setState({ schoolsModalOpen: true })}
            style={{ cursor: 'pointer' }}
            src={Pencil}
            alt="edit"
          />
        )}

        {this.state.schoolsModalOpen && (
          <SchoolApplicationsModal
            show={this.state.schoolsModalOpen}
            onHide={() => this.setState({ schoolsModalOpen: false })}
            replaceCandidateInState={this.props.replaceCandidateInState}
            fetchEvents={this.props.fetchEvents}
            schoolapplications={this.props.schoolapplications}
            role={this.props.role}
            user={this.props.user}
            newApplicationStatuses={this.props.newApplicationStatuses}
          />
        )}
      </div>
    );
  }
}

const TooltipRow = styled.div`
  color: #fff !important;
`;

const StyledTooltip = styled.div`
  max-height: 400px;
  overflow-y: auto;
  color: #fff !important;
`;

const VisibleBox = styled.span`
.applications-note-text {
  @media print {
      color: ${nimbleTheme.palette.info.dark}
      font-size: ${nimbleTheme.typography.print.fontSize} !important,
      padding: 0 !important,
      text-decoration: none;
    }
  }
`;
