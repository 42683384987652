import styled from 'styled-components';
import { SearchIcon } from 'ui-kit/icons';
import {
  DistrictJobBoardDropDownProps,
  GetDisplayNameOptionInterface,
  StringNumber,
} from '../types';

export const Container = styled.div<DistrictJobBoardDropDownProps>`
  width: 100%;
  border: 1px solid ${(props) => (props.isActive ? props.highlightColor : '#C4C4C4')};
  font-size: 13px;
  color: #666;
  background-color: #ffffff;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  max-width: 150px;
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  height: 100%;
`;

export const SelectionList = styled.div<DistrictJobBoardDropDownProps>`
  display: ${(props) => (props.showOptions ? 'block' : 'none')};

  position: absolute;
  top: 50px;
  left: -1px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  padding: 7px 12px;
  width: 250px;

  z-index: 5;

  max-height: 500px;
  overflow: auto;
`;

export const SelectAllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 35px;

  & > span {
    cursor: pointer;
  }
`;

export const Option = styled.div`
  cursor: pointer;
  font-size: 13px;

  &:first-child {
    padding-top: 8px;
  }

  &:last-child {
    padding-bottom: 8px;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: normal;

    &.option-disabled {
      color: $light-grey;

      &:hover input ~ .checkmark {
        background-color: $green;
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      border-radius: 2px;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: white;
      border: 1px solid #e5e5e5;

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #e5e5e5;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: #0175ff;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
  }
`;
export const IndentedOption = styled(Option)`
  padding-left: 16px;
`;

export const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 16px;
`;

export const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

export const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 15px;
`;

export const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;

export const DownCaret = ({ fillColor }) => {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29708 5.17736e-06L5.5 4.20292L9.70292 5.17736e-06L11 1.29709L5.5 6.79709L0 1.29709L1.29708 5.17736e-06Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const getDisplayNameText = (
  valuesSelected: StringNumber[],
  fieldPlural: string,
  options: GetDisplayNameOptionInterface[],
  subOptions = false
): string | number => {
  if (valuesSelected.length > 0) {
    if (valuesSelected.length === 1) {
      if (subOptions) {
        const subOption = options
          .find((o) =>
            o.subOptions?.map((subOption) => subOption.value).includes(valuesSelected[0])
          )
          ?.subOptions?.find((subOption) => subOption.value === valuesSelected[0]);

        return subOption?.label || '';
      } else {
        const option = options.find((opt) => opt.value === valuesSelected[0]);
        return option ? option.label : '';
      }
    } else {
      return `${valuesSelected.length} ${fieldPlural} selected`;
    }
  } else {
    return 'Select all that apply';
  }
};
