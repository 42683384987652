import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ReactComponent as SearchIcon } from 'assets/icon_search_glass.svg';

export default function LocationsFilter({
  locations,
  setLocations,
  setAllLocations,
  locationOptions,
  isActive,
  showSearchInput = true,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  let filteredOptions = [];
  if (locationOptions) {
    locationOptions.forEach((l) => {
      filteredOptions = filteredOptions.concat(l.sublocations);
    });

    if (searchValue) {
      filteredOptions = filteredOptions.filter(
        (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      );
    }

    filteredOptions = [...new Set(filteredOptions.map((o) => o.id))];
  }

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const allSublocationsChecked = (location) => {
    const itemIsCurrentlySelected = (sublocation) => locations.includes(sublocation.id);

    return location.sublocations.every(itemIsCurrentlySelected);
  };

  const updateSection = (e, section) => {
    e.stopPropagation();

    let updatedLocations = [...locations];
    const isNotInSection = (sublocation) =>
      !section.sublocations.map((s) => s.id).includes(sublocation);

    if (!e.target.checked) {
      updatedLocations = updatedLocations.filter(isNotInSection);
    } else {
      section.sublocations.forEach((sublocation) => updatedLocations.push(sublocation.id));
      updatedLocations = [...new Set(updatedLocations)];
    }

    setLocations(updatedLocations);
  };

  const selectAllLocations = () => {
    const sublocations = ['N/A'];
    locationOptions.forEach((location) => {
      location.sublocations.forEach((sublocation) => {
        sublocations.push(sublocation.id);
      });
    });

    setAllLocations(sublocations);
  };

  const toggleLocation = (e, location) => {
    let updatedLocations = [...locations];

    if (!e.target.checked) {
      updatedLocations = updatedLocations.filter((item) => item !== location);
    } else {
      updatedLocations.push(location);
      // ensure no duplicates
      updatedLocations = [...new Set(updatedLocations)];
    }

    setLocations(updatedLocations);
  };

  return (
    <div className={`new-cl-filter ${isActive ? 'cl-active' : ''}`}>
      <div className="cl-filter-header" onClick={toggleOptions}>
        <p className={isActive ? 'cl-active' : ''}>Location</p>
        <img
          src={
            showOptions && isActive
              ? iconUpCaretGreen
              : !showOptions && isActive
                ? iconDownCaretGreen
                : showOptions
                  ? iconUpCaret
                  : iconDownCaret
          }
          alt=""
        />
      </div>
      <div className={`selection-list ${showOptions ? 'show' : ''}`}>
        <div className="option select-all" style={{ paddingBottom: '8px' }}>
          <span className="drop-select-all" onClick={selectAllLocations}>
            Select All
          </span>
          <span className="drop-select-all" onClick={() => setLocations([])}>
            Clear All
          </span>
        </div>

        {showSearchInput && (
          <InputFilterContainer>
            <InputFilter
              type="text"
              placeholder=""
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchValue ? (
              <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
            ) : (
              <PositionedSearchIcon />
            )}
          </InputFilterContainer>
        )}

        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={locations.includes('N/A')}
              onChange={(e) => toggleLocation(e, 'N/A')}
            />
            <span className="checkmark"></span>
            N/A
          </label>
        </div>
        {locationOptions.map((location) => {
          if (location.sublocations.map((sl) => sl.id).some((sl) => filteredOptions.includes(sl))) {
            return (
              <Fragment key={location.id}>
                <div className="option">
                  <label className="container">
                    <input
                      type="checkbox"
                      checked={allSublocationsChecked(location)}
                      onChange={(e) => updateSection(e, location)}
                    />
                    <span className="checkmark"></span>
                    {location.label}
                  </label>
                </div>
                {location.sublocations.map((sublocation) => {
                  if (filteredOptions.includes(sublocation.id)) {
                    return (
                      <div key={sublocation.id} className="option indented">
                        <label className="container">
                          <input
                            type="checkbox"
                            checked={locations.includes(sublocation.id)}
                            onChange={(e) => toggleLocation(e, sublocation.id)}
                          />
                          <span className="checkmark"></span>
                          {sublocation.label}
                        </label>
                      </div>
                    );
                  }
                  return null;
                })}
              </Fragment>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

LocationsFilter.propTypes = {
  locations: PropTypes.array.isRequired,
  setLocations: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 0.5rem;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 15px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;
