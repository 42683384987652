import { Component } from 'react';

import PropTypes from 'prop-types';
import {
  grades,
  early_childhood_grades,
  childhood_grades,
  middle_grades,
  secondary_grades,
} from '../../utils/enums';

import ButtonCheckBox from '../common/ButtonCheckBox';
import _ from 'lodash';
import { ComponentsJobEditGradesDataTestIds } from '../../data-testids/Components';
import { ATSDistrictJobsListGradeLevelFilterDataTestIds } from 'data-testids/ATS';

/* Build grades subset lists */
const GRADES = grades().map((grade) => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const EARLYCHILDHOOD = early_childhood_grades().map((grade) => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const ELEMENTARY = childhood_grades().map((grade) => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const MIDDLE = middle_grades().map((grade) => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const SECONDARY = secondary_grades().map((grade) => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

export default class JobEditGrades extends Component {
  static propTypes = {
    grades: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    grades: this.props.grades,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ grades: nextProps.grades });
  }

  updateGrade = (e) => {
    // toggle one grade
    var updateGrades = this.state.grades;
    var grade = parseInt(e.target.value, 10);
    if (updateGrades.includes(grade)) {
      updateGrades.splice(updateGrades.indexOf(grade), 1);
    } else {
      updateGrades.push(grade);
    }
    this.setState({ grades: updateGrades });
    // reformat updateGrades as a list of grade integers before onChange
    updateGrades = updateGrades.map((v) => {
      var obj = {};
      obj['value'] = v;
      return obj;
    });
    this.props.onChange(updateGrades);
  };

  gradesCheckedInSection = (section = GRADES) => {
    // check if all grades in that section are checked
    return (
      _.difference(
        section.map((g) => g.value),
        this.state.grades
      ).length === 0
    );
  };
  updateGradesInSection = (section = GRADES) => {
    // toggle all grades in a section
    var updateGrades = this.state.grades;
    if (this.gradesCheckedInSection(section)) {
      _.remove(updateGrades, (grade) => section.map((g) => g.value).includes(grade));
    } else {
      updateGrades = _.union(
        updateGrades,
        section.map((g) => g.value)
      );
    }
    this.setState({ grades: updateGrades });
    // reformat updateGrades as a list of grade integers before onChange
    updateGrades = updateGrades.map((v) => {
      var obj = {};
      obj['value'] = v;
      return obj;
    });
    this.props.onChange(updateGrades);
  };

  render() {
    return (
      <div className="flex jobedit-grades">
        <div className="jobedit-grade-column">
          <div className="jobedit-grade-header">
            <ButtonCheckBox
              checked={this.gradesCheckedInSection()}
              value={this.gradesCheckedInSection()}
              onChange={() => this.updateGradesInSection()}
              label="All Grades"
              dataTestId={ATSDistrictJobsListGradeLevelFilterDataTestIds.ALL_GRADES_OPTION}
            />
          </div>
        </div>
        <div className="jobedit-grade-column">
          <div className="jobedit-grade-header">
            <ButtonCheckBox
              checked={this.state.grades.includes(EARLYCHILDHOOD[0].value)}
              value={EARLYCHILDHOOD[0].value}
              onChange={this.updateGrade}
              label="Early Childhood"
              dataTestId={ComponentsJobEditGradesDataTestIds.EARLY_CHILDHOOD_BUTTON}
            />
          </div>
        </div>
        <div className="jobedit-grade-column">
          <div className="jobedit-grade-header">
            <ButtonCheckBox
              checked={this.gradesCheckedInSection(ELEMENTARY)}
              value={this.gradesCheckedInSection(ELEMENTARY)}
              onChange={() => this.updateGradesInSection(ELEMENTARY)}
              label="All Elementary"
              dataTestId={ComponentsJobEditGradesDataTestIds.ELEMENTARY_SCHOOL_BUTTON}
            />
          </div>
          <div className="jobedit-grade-sublist">
            {ELEMENTARY.map((grade) => (
              <ButtonCheckBox
                key={grade.value}
                checked={this.state.grades.includes(grade.value)}
                value={grade.value}
                onChange={this.updateGrade}
                label={grade.label}
                dataTestId={`${ATSDistrictJobsListGradeLevelFilterDataTestIds.GRADE_OPTION}-${grade.value}`}
              />
            ))}
          </div>
        </div>
        <div className="jobedit-grade-column">
          <div className="jobedit-grade-header">
            <ButtonCheckBox
              checked={this.gradesCheckedInSection(MIDDLE)}
              value={this.gradesCheckedInSection(MIDDLE)}
              onChange={() => this.updateGradesInSection(MIDDLE)}
              label="All Middle School"
              dataTestId={ComponentsJobEditGradesDataTestIds.MIDDLE_SCHOOL_BUTTON}
            />
          </div>
          <div className="jobedit-grade-sublist">
            {MIDDLE.map((grade) => (
              <ButtonCheckBox
                key={grade.value}
                checked={this.state.grades.includes(grade.value)}
                value={grade.value}
                onChange={this.updateGrade}
                label={grade.label}
                dataTestId={`${ATSDistrictJobsListGradeLevelFilterDataTestIds.GRADE_OPTION}-${grade.value}`}
              />
            ))}
          </div>
        </div>
        <div className="jobedit-grade-column">
          <div className="jobedit-grade-header">
            <ButtonCheckBox
              checked={this.gradesCheckedInSection(SECONDARY)}
              value={this.gradesCheckedInSection(SECONDARY)}
              onChange={() => this.updateGradesInSection(SECONDARY)}
              label="All High School"
              dataTestId={ComponentsJobEditGradesDataTestIds.HIGH_SCHOOL_BUTTON}
            />
          </div>
          <div className="jobedit-grade-sublist">
            {SECONDARY.map((grade) => (
              <ButtonCheckBox
                key={grade.value}
                checked={this.state.grades.includes(grade.value)}
                value={grade.value}
                onChange={this.updateGrade}
                label={grade.label}
                dataTestId={`${ATSDistrictJobsListGradeLevelFilterDataTestIds.GRADE_OPTION}-${grade.value}`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
