import categoriesAPI from 'api/categoriesAPI';
import districtAPI from 'api/districtAPI';
import schoolGroupsAPI from 'api/schoolGroupsAPI';
import schoolsAPI from 'api/schoolsAPI';
import axios from 'axios';
import auth from 'utils/auth';

const getJobOwnerOrDistrictUserOptions = (options) => {
  const notAvailableOptions = [{ value: 'N/A', label: 'N/A' }];
  const optionsToReturn = options.map((item) => ({
    value: item.pk,
    label: `${item.first_name} ${item.last_name}`,
  }));

  return notAvailableOptions.concat(optionsToReturn);
};

const getLocationOptions = (locationOptions) => {
  const notAvailableOptions = [{ value: 'N/A', label: 'N/A' }];
  const locations = locationOptions.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // add "N/A" to front of our list of district users
  const fullOptionList = notAvailableOptions.concat(locations);
  return fullOptionList;
};

const getHRBPAssigneeOptions = (admins) => {
  return admins.map((admin) => ({
    value: admin.id,
    label: admin.name,
  }));
};

export const fetchAndSetCategories = (districtId, setOptions) => {
  categoriesAPI.fetchCategories({ districtId }).then((categoryOptions) => {
    const subcategories = ['N/A'];

    categoryOptions.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        subcategories.push(subcategory.id);
      });
    });

    setOptions((previousState) => ({ ...previousState, categories: categoryOptions }));
  });
};

export const fetchAndSetDistrictUsers = (districtId, setOptions) => {
  axios.get(`/api/district/${districtId}/tagged_district_users/`).then((response) => {
    const districtUserOptions = getJobOwnerOrDistrictUserOptions(response.data);
    setOptions((previousState) => ({ ...previousState, districtUsers: districtUserOptions }));
  });
};

export const fetchAndSetJobOwners = (districtId, setOptions) => {
  axios.get(`/api/district/${districtId}/role_owners/`).then((response) => {
    const jobOwnerOptions = getJobOwnerOrDistrictUserOptions(response.data);
    setOptions((previousState) => ({ ...previousState, jobOwners: jobOwnerOptions }));
  });
};

export const fetchAndSetLocations = (setOptions) => {
  schoolsAPI.fetchActiveSchools().then((schools) => {
    if (auth.inPostingOnlyDistrict()) {
      // no school groups so locations filter is a flat array of schools
      const schoolOptions = getLocationOptions(schools);
      setOptions((previousState) => ({ ...previousState, schoolOptions: schoolOptions }));
    } else {
      // regular districts (non-posting-only) show schools nested under a school
      // group if they belong to one or under "No Group" if they don't
      schoolGroupsAPI.getSchoolGroups().then((groups) => {
        const schoolOptions = groups.map((group) => {
          const subLocations = schools
            .filter((school) => school.school_groups.includes(group.id))
            .map((school) => {
              return {
                id: school.id,
                label: school.name,
              };
            });

          return { id: group.id, label: group.name, sublocations: subLocations };
        });

        const groupLessSchools = {
          id: 'groupless',
          label: 'No Group',
          sublocations: schools
            .filter((school) => !school.school_groups || school.school_groups.length === 0)
            .map((school) => {
              return { label: school.name, ...school };
            }),
        };

        const schoolsToReturn = [...schoolOptions, groupLessSchools];
        setOptions((previousState) => ({ ...previousState, schools: schoolsToReturn }));
      });
    }
  });
};

export const fetchAndSetHRBPAssignees = (districtId, setOptions) => {
  districtAPI.getDistrictAndSuperAdmins(districtId).then((response) => {
    const hrbpAssigneeOptions = getHRBPAssigneeOptions(response);
    setOptions((previousState) => ({ ...previousState, hrbpAssignees: hrbpAssigneeOptions }));
  });
};
