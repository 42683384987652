import styled from 'styled-components';
import { nimbleTheme } from 'theme';

export default function MultipleChoiceViewAnswer({ answer }) {
  return (
    <div>
      {answer.multi_select && <SubtitleText>Select as many as apply.</SubtitleText>}
      <GridContainer>
        {answer.mc_options.map((option, i) => (
          <Option key={i}>
            <input
              type="checkbox"
              name="multiple_choice"
              value={option}
              checked={answer.mc_answer?.includes(option) ?? false}
              readOnly
            />
            &nbsp;&nbsp;{option}
          </Option>
        ))}
      </GridContainer>
    </div>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3px;
`;

const Option = styled.label`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;

  color: #777777;
  @media print {
    font-size: ${nimbleTheme.typography.print.fontSize};
    margin-bottom: 0;
  }
`;

const SubtitleText = styled.div`
  font-size: ${nimbleTheme.typography.body3.fontSize};
  margintop: ${nimbleTheme.spacing(0.5)}};
  @media print {
    font-size: 9px;
  }
`;
