import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  min-width: 425px;
  margin-bottom: 10px;
`;

export const Heading = styled.div`
  font-size: 18px;
  color: #444444;
  font-weight: 700;
  padding: 18px 14px 18px 14px;
  margin-left: 20px;
`;

export const HeaderContainer = styled.div`
  background-color: #fbfbfb;
  border-right: 1px #e5e5e5 solid;
  border-top: 1px #e5e5e5 solid;
  border-left: 1px #e5e5e5 solid;
  border-radius: 6px 6px 0px 0px;
`;

export const Body = styled.div`
  padding: 15px;
  height: 528px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #dcdcdc;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
`;

export const BodyActions = styled.div`
  display: flex;
  align-self: center;
`;

export const LoadingWrapper = ({ isLoading, message, children, ...rest }) => {
  if (isLoading) {
    return (
      <LoadingSpinner>
        <i className="fa fa-circle-o-notch fast-spin fa-4x fa-fw" />
      </LoadingSpinner>
    );
  }

  if (message) {
    return <ChartMessage>{message}</ChartMessage>;
  }

  return <div {...rest}>{children}</div>;
};

const LoadingSpinner = styled.div`
  flex: 1;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
`;

export const BodyContent = styled(LoadingWrapper)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
