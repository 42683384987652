import { Job, RoleTemplate } from 'types/types';

export const jobErrorsDefault = {
  hrbp_assignments: false,
  title: false,
  job_description: false,
  role_type: false,
  link_to_ats: false,
  non_field_errors: false,
  subjects: false,
  grades: false,
  duplicate_title: false,
  start_date: false,
  fulltime: false,
  deadline: false,
  salary_min: false,
  salary_max: false,
};

export const questionDefault = {
  isShowingEditOptions: false,
  text: '',
  prompt: '',
  localId: null,
  is_automatic_list: false,
  school_choices: [],
  title: '',
  description: '',
  multi_select: false,
  question_type: null,
  source: null,
  mc_options: null,
  nimblequestion_id: null,
  is_legal: false,
  is_district_default: false,
  district: null,
  hint: '',
  question: '',
  is_visible_external: true,
  is_required_external: false,
  is_visible_internal: true,
  is_required_internal: false,
};

export const jobDefault: Job = {
  id: null,
  benefits: '',
  contact: '',
  jobboards: [],
  external_date: '',
  deadline: '',
  district: null,
  onet_occupation_code: '',
  question_sets_order: [],
  internal_role_notes: [],
  internal_only: false,
  hiring_season: 5,
  questions: [],
  custom_questions: [],
  requiredapplicationattachment_set: [],
  question_sets: [],
  role_question_sets: [],
  title: '',
  ftes_total: 0,
  requisition_number: '',
  owners: [],
  district_users: [],
  description: '',
  link_to_ats: '',
  internal_requirements_specified: false,
  status: 20,
  statuses_available: [],
  hrbp_assignments: [],
  start_date_type: 1,
  role_sections: [],
  grades: [],
  salary_min: 0,
  salary_max: 0,
  salary_type: 1,
  subjects: [],
  school_preferences_question: null,
  start_date: null,
  fulltime: 1.0,
  is_template: false,
};

export const templateDefault: RoleTemplate = {
  id: 15072,
  questions: [],
  custom_questions: [],
  title: 'Coordinator',
  description: '<p>Template</p>',
  benefits: null,
  subjects: null,
  grades: [],
  deadline: null,
  start_date: null,
  fulltime: null,
  salary_min: null,
  salary_max: null,
  salary_type: null,
  status: 0,
  requiredapplicationattachment_set: [],
  internal_role_notes: [],
  hiring_season: 7,
  district_users: [],
  role_sections: [],
  jobboards: [
    {
      id: 1582,
    },
  ],
  ftes_total: 1,
  internal_only: false,
  external_date: null,
  start_date_type: 1,
  internal_requirements_specified: false,
  school_preferences_question: null,
  contact: '',
  onet_occupation_code: '',
  link_to_ats: '',
  hrbp_assignments: [],
  question_sets: [],
  role_question_sets: [],
  question_sets_order: [],
  requisition_number: '1',
  is_template: true,
  district: 1,
  schoolroles: [],
  statuses_available: [],
  owners: [],
  created: '',
};

export const templateSelectDefaultProps = {
  onApply: () => {},
  modifiedIds: new Set([1, 2, 3]),
  onSelect: () => {},
  onBack: () => {},
  templates: [templateDefault],
  selectedTemplate: templateDefault,
  isLoadingTemplates: false,
};
