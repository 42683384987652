import styled from 'styled-components';

function CheckboxContainerVisibilityOnly({ itemMatrix, handleItemMatrixChange }) {
  const { isVisibleExternal, isVisibleInternal } = itemMatrix;
  return (
    <CheckboxContainerOuter>
      <GridItemHeader />
      <GridItemHeader>visible</GridItemHeader>
      <GridItem>external</GridItem>
      <DivWithAllBorders>
        <input
          type="checkbox"
          name="isVisibleExternal"
          style={{ margin: '0' }}
          value={isVisibleExternal}
          defaultChecked={isVisibleExternal}
          onChange={handleItemMatrixChange}
        />
      </DivWithAllBorders>
      <GridItem>internal</GridItem>
      <DivWithNoTopBorder>
        <input
          type="checkbox"
          name="isVisibleInternal"
          style={{ margin: '0' }}
          value={isVisibleInternal}
          defaultChecked={isVisibleInternal}
          onChange={handleItemMatrixChange}
        />
      </DivWithNoTopBorder>
    </CheckboxContainerOuter>
  );
}

export default CheckboxContainerVisibilityOnly;

const CheckboxContainerOuter = styled.div`
  display: grid;
  grid-template-columns: 70px 62px;
  grid-template-rows: 30px repeat(2, 40px);

  font-size: 14px;
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridItemHeader = styled(GridItem)`
  align-self: start;
`;

const DivWithAllBorders = styled(GridItem)`
  border: 1px solid rgba(57, 60, 73, 0.5);
`;

const DivWithNoTopBorder = styled(DivWithAllBorders)`
  border-top: 0;
`;
