import { useEffect, useState, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import { ReportColumn } from 'types/types';
import FlatFileTransferAPI from 'api/flatFileTransferAPI';
import HellosignTemplatesAPI from 'api/hellosignTemplatesAPI';
import CustomProfileFieldsAPI from 'api/customProfileFieldsAPI';
import ApplicationStatusesAPI from 'api/applicationStatusesAPI';
import { useParams } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { myColor } from '../../../utils/message';
import { FlatFileTransferDashboardHeader } from '../components/FlatFileTransferDashboardHeader';
import FlatFileTransferColumn from '../components/FlatFileTransferColumn';
import {
  ColumnState,
  ColumnAction,
  ColumnActionType,
  ColumnActionValueType,
  FlatFileTransferOptionsInterface,
} from '../types/types';
import { DashboardFooterContainer } from '../components/DashboardFooterContainer';
import FlatFileTransferDataMappingModal from '../components/FlatFileTransferDataMappingModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { formatDropdownOptionLabel, generateId } from 'utils/util';
import produce from 'immer';
import auth from '../../../utils/auth';
import * as _ from 'lodash';
import { AxiosError } from 'axios';

const isString = (value: ColumnActionValueType): value is string => typeof value === 'string';
const mapToObject = (map: Map<string, string>) => {
  return Array.from(map).reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
};

const reducer = produce((columnState: ColumnState, columnAction: ColumnAction): ColumnState => {
  const columnStateCopy = { ...columnState };

  const addSecondaryColumnFields = (obj, index): void => {
    const columnType = columnStateCopy.columns[index].type;
    if (columnType === 'constant_value' && !obj.column_value) {
      columnStateCopy.columns[index].column_value = '';
    } else if (columnType === 'constant_value') {
      columnStateCopy.columns[index].column_value = obj.column_value;
    } else if (columnType === 'user_profile') {
      columnStateCopy.columns[index].profile_field = obj.profile_field;
    } else if (columnType === 'custom_profile_field') {
      columnStateCopy.columns[index].custom_profile_field_id = obj.custom_profile_field_id;
    } else if (columnType === 'hellosign') {
      columnStateCopy.columns[index].hellosign_template_id = obj.hellosign_template_id;
      columnStateCopy.columns[index].template_id = obj.template_id;
      columnStateCopy.columns[index].hellosign_field_name = obj.hellosign_field_name;
    } else if (columnType === 'application') {
      columnStateCopy.columns[index].application_field = obj.application_field;
    } else {
      columnStateCopy.columns[index] = obj;
    }

    if (obj.formats) {
      columnStateCopy.columns[index].formats = obj.formats;
    }
    if (obj.mapping) {
      columnStateCopy.columns[index].mapping = obj.mapping;
    }
  };

  const columnIndex = columnAction.index;
  if (isString(columnAction.value)) {
    switch (columnAction.type) {
      case ColumnActionType.SET_TITLE:
        columnStateCopy.columns[columnIndex].title = columnAction.value;
        break;
      case ColumnActionType.SET_TYPE:
        columnStateCopy.columns[columnIndex] = {
          id: columnStateCopy.columns[columnIndex].id,
          title: columnStateCopy.columns[columnIndex].title,
          type: columnAction.value,
        };
        addSecondaryColumnFields(columnStateCopy.columns[columnIndex], columnIndex);
        break;
      case ColumnActionType.SET_VALUE:
        if (columnAction.fieldName === 'formats') {
          columnStateCopy.columns[columnIndex][columnAction.fieldName] = [columnAction.value];
        } else {
          columnStateCopy.columns[columnIndex][columnAction.fieldName] = columnAction.value;
        }
        break;
    }
    return columnState;
  } else if (columnAction.value instanceof Map) {
    if (columnAction.type == ColumnActionType.SET_MAPPING) {
      columnStateCopy.columns[columnIndex][columnAction.fieldName] = mapToObject(
        columnAction.value
      );
    }
    return columnState;
  } else {
    switch (columnAction.type) {
      case ColumnActionType.SET_VALUE:
        columnStateCopy.columns[columnIndex][columnAction.fieldName] = columnAction.value;
        break;
      case ColumnActionType.SET_FLAT_FILE:
        {
          const actionArray = Object.values(columnAction.value);
          if (actionArray.length > 0) {
            actionArray.forEach((obj, index) => {
              if (columnStateCopy.columns[index]) {
                columnStateCopy.columns[index].id = obj?.id || generateId();
                columnStateCopy.columns[index].title = obj.title;
                columnStateCopy.columns[index].type = obj.type;
              } else {
                columnStateCopy.columns.push({
                  id: obj?.id || generateId(),
                  title: obj.title,
                  type: obj.type,
                });
              }
              addSecondaryColumnFields(obj, index);
            });
          } else {
            return {
              ...columnStateCopy,
            };
          }
        }
        break;
      case ColumnActionType.MOVE:
        {
          columnStateCopy[columnAction.from] = columnStateCopy[columnAction.from] || [];
          columnStateCopy[columnAction.to] = columnStateCopy[columnAction.to] || [];
          const [removed] = columnStateCopy[columnAction.from].splice(columnAction.fromIndex, 1);
          columnStateCopy[columnAction.to].splice(columnAction.toIndex, 0, removed);
        }
        break;
      case ColumnActionType.ADD_COLUMN:
        return {
          columns: [
            ...columnState.columns,
            {
              id: generateId(),
              title: '',
              type: '',
            },
          ],
        };
      case ColumnActionType.DELETE_COLUMN:
        return {
          columns: [...columnState.columns.filter((column) => column.id !== columnAction.id)],
        };
      default:
        return columnStateCopy;
    }
    return columnState;
  }
});

interface FlatFileTransferFieldsParams {
  id: string;
}

export const FlatFileTransferFields: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [flatFileResponse, setFlatFileResponse] = useState<any>({});
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [areColumnsIncomplete, setAreColumnsIncomplete] = useState<boolean>(false);
  const [customProfileFieldOptions, setCustomProfileFieldOptions] = useState<
    FlatFileTransferOptionsInterface[]
  >([]);
  const [applicationStatusOptions, setApplicationStatusOptions] = useState<
    FlatFileTransferOptionsInterface[]
  >([]);
  const [helloSignTemplateOptions, setHelloSignTemplateOptions] = useState<
    FlatFileTransferOptionsInterface[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(null);
  const [modalMapping, setModalMapping] = useState<Array<any>>([]);

  const { id } = useParams<FlatFileTransferFieldsParams>();

  const [columnState, dispatch] = useReducer(reducer, {
    columns: [
      {
        id: generateId(),
        title: '',
        type: '',
      },
    ],
  });

  const saveFlatFileTransfer = (): void => {
    const updateObject: ReportColumn[] = {
      ...flatFileResponse,
      report_columns: JSON.stringify({ columns: columnState.columns }),
    };
    FlatFileTransferAPI.updateFlatFile(id, updateObject)
      .then((response) => {
        const updateResponse = response;
        const updateResponseReportColumn = JSON.parse(updateResponse.report_columns);
        updateResponse.report_columns = updateResponseReportColumn;
        setFlatFileResponse(updateResponse);
        showSavedToast();
      })
      .catch((reason: AxiosError) => {
        if (reason.response.status === 400) {
          showRequiredFieldsErrorToast();
        } else {
          showGenericErrorToast();
        }
      });
  };

  const handleTestFileDownload = async () => {
    try {
      const response = await FlatFileTransferAPI.downloadTestCSV(id);
      const filename = response.headers['content-disposition'].split('filename=')[1];

      // programmatically download csv file to local device
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsDownloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const showSavedToast = () => {
    setIsSaving(false);
    notify.show(`Data Transfer Saved!`, 'success', 5000, myColor);
  };

  const showRequiredFieldsErrorToast = () => {
    setIsSaving(false);
    notify.show('Please make sure all required fields are filled in order to save.', 'error', 5000);
  };
  const showGenericErrorToast = () => {
    setIsSaving(false);
    notify.show(
      'There was an error. Please try again or contact support@hirenimble.com.',
      'error',
      5000
    );
  };

  const openModal = (index): void => {
    setModalIndex(index);
    const mappingString = columnState.columns[index].mapping ?? {};
    const mappingList = Object.entries(mappingString);
    const labeledMapping = [];
    mappingList.forEach(([key, value]) => {
      labeledMapping.push({
        initialValue: key,
        mappedValue: value,
      });
    });
    setModalMapping(labeledMapping);
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  const handleMappingUpdate = (newMapping) => {
    setModalMapping(newMapping);
  };

  const saveMapping = () => {
    const mappingJson: Map<string, string> = new Map<string, string>();
    modalMapping.forEach((mapping) => {
      if (mapping['initialValue'] != '' || mapping['mappedValue'] != '') {
        mappingJson.set(mapping['initialValue'], mapping['mappedValue']);
      }
    });
    dispatch({
      type: ColumnActionType.SET_MAPPING,
      value: mappingJson,
      index: modalIndex,
      fieldName: 'mapping',
    });
    setIsModalOpen(false);
  };

  const checkRequiredSections = (obj: ReportColumn): boolean => {
    if (obj.title) {
      if (obj.type) {
        if (obj.type === 'constant_value') {
          return true;
        } else if (obj.type === 'user_profile' && obj.profile_field) {
          return true;
        } else if (
          obj.type === 'hellosign' &&
          obj.hellosign_template_id &&
          obj.hellosign_field_name &&
          obj.template_id
        ) {
          return true;
        } else if (obj.type === 'custom_profile_field' && obj.custom_profile_field_id) {
          return true;
        } else if (obj.type === 'date_sent') {
          return true;
        } else if (obj.type === 'concatenation') {
          return true;
        } else if (obj.type === 'left_padded') {
          return true;
        } else if (obj.type === 'application_status_date' && obj.application_status_id) {
          return true;
        } else if (obj.type === 'district_application_role' && obj.role_field) {
          return true;
        } else if (obj.type === 'application' && obj.application_field) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    async function fetchFFT() {
      setIsLoading(true);
      const getResponse = await FlatFileTransferAPI.getFlatFile(id);
      const getResponseReportColumn = JSON.parse(getResponse.report_columns);
      getResponse.report_columns = getResponseReportColumn;
      setFlatFileResponse(getResponse);

      dispatch({
        type: ColumnActionType.SET_FLAT_FILE,
        value: getResponse.report_columns['columns'],
      });
      setIsLoading(false);
    }
    async function fetchCustomProfileFields() {
      const response = await CustomProfileFieldsAPI.getCustomProfileFields();
      const customProfileFields = response;
      setCustomProfileFieldOptions(
        customProfileFields.map((customProfileField) => {
          return {
            value: customProfileField.id,
            label: formatDropdownOptionLabel(customProfileField.title),
          };
        })
      );
    }

    async function fetchHelloSignTemplates() {
      const response = await HellosignTemplatesAPI.fetchHelloSignTemplates();
      const helloSignTemplates = response;
      setHelloSignTemplateOptions(
        helloSignTemplates.map((helloSignTemplate) => {
          return {
            value: helloSignTemplate.id,
            label: formatDropdownOptionLabel(helloSignTemplate.title),
            hellosign_template_id: helloSignTemplate.template_id,
          };
        })
      );
    }

    const district = auth.getUser().profile.district.id;

    async function fetchApplicationStatusList() {
      ApplicationStatusesAPI.getAll({
        districtId: district,
        statusTypes: [],
      }).then((data) => {
        setApplicationStatusOptions(
          data.map((applicationStatus) => {
            return {
              value: applicationStatus.id,
              label: applicationStatus.label,
            };
          })
        );
      });
    }

    fetchApplicationStatusList();

    fetchFFT();
    fetchCustomProfileFields();
    fetchHelloSignTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _.isEqual(flatFileResponse['report_columns'], columnState)
      ? setIsSaveDisabled(true)
      : setIsSaveDisabled(false);

    setAreColumnsIncomplete(!columnState.columns.every(checkRequiredSections));
  }, [columnState, flatFileResponse]);

  const handleDragEnd = useCallback((result) => {
    if (result.reason === 'DROP') {
      if (!result.destination) {
        return;
      }
      dispatch({
        type: ColumnActionType.MOVE,
        from: result.source.droppableId,
        to: result.destination.droppableId,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
      });
    }
  }, []);

  return (
    <DashboardPage>
      <DashboardBodyContainer>
        <FlatFileTransferDashboardHeader
          title={'Data Configuration by Columns'}
          isLoading={isLoading}
          dispatch={dispatch}
          areColumnsIncomplete={areColumnsIncomplete}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="columns" type="COLUMN">
            {(provided) => (
              <ScrollContainer ref={provided.innerRef} {...provided.droppableProps}>
                {columnState.columns?.map((column, index) => (
                  <Draggable key={column.id} draggableId={column.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FlatFileTransferColumn
                          key={column.title}
                          columnState={column}
                          index={index}
                          dispatch={dispatch}
                          customProfileFields={customProfileFieldOptions}
                          helloSignTemplateOptions={helloSignTemplateOptions}
                          applicationStatusOptions={applicationStatusOptions}
                          openModal={openModal}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ScrollContainer>
            )}
          </Droppable>
        </DragDropContext>
        <FlatFileTransferDataMappingModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          mappingJson={modalMapping}
          onMappingUpdate={handleMappingUpdate}
          saveMapping={saveMapping}
        />
      </DashboardBodyContainer>
      <DashboardFooterContainer
        saveFlatFileTransfer={saveFlatFileTransfer}
        handleTestFileDownload={handleTestFileDownload}
        isSaveDisabled={isSaveDisabled}
        setIsSaving={setIsSaving}
        isSaving={isSaving}
        setIsDownloading={setIsDownloading}
        isDownloading={isDownloading}
      />
    </DashboardPage>
  );
};

export const DashboardPage = styled.div`
  padding: 0;
`;

export const DashboardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-bottom: 55px;
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;

  & > *:last-of-type {
    padding-bottom: 80px;
  }
`;
