import { useState } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-system';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import greyCheckmark from 'assets/icon_check_grey.svg';
import PlainFileIcon from 'assets/icon-plain-file.svg';
import HotGarbageIcon from 'assets/icon-hot-garbage.svg';
import EditFileIcon from 'assets/icon-edit-file.svg';
import ViewFileIcon from 'assets/icon-view.svg';
import { ATSCandidateHomeDataTestIds } from 'data-testids/ATS';

export default function CandidateDashboardApplicationsRow({
  application,
  openUploadDocumentModal,
  withdrawApplication,
  getApplicationAttachmentsForModal,
  user,
}) {
  const [isWithdrawApp, setIsWithdrawApp] = useState(false);

  const role = application.role;
  const district = role.district;
  // we get data this way to handle case where district has multiple jobboards
  const districtName = role.jobboards.length ? role.jobboards[0].title : role.district.name;
  const jobBoardSlug = role.jobboards.length ? role.jobboards[0].slug : role.district.slug;

  const jobTitle = role.title;
  const applicationTitle = `${districtName} - ${jobTitle}`;

  /**
   * An application status is used to determine which buttons should be
   * rendered and what type of job status should be displayed.
   */
  const getApplicationStatus = () => {
    // Show job as closed if it's archived OR (if it's closed AND they haven't submitted yet)
    if (isJobClosed()) {
      return 'Job closed';
    } else if (application.new_status && application.new_status.status_type === 0) {
      return 'Application In Progress';
    } else {
      return 'Application Submitted';
    }
  };

  // If profile_complete=true, send to page 2 of the candidate application.
  // That page says "Complete your profile", but we now allow users to customize
  // the visibility and requirements of these sections on a role to role basis,
  // so even if you "completed" that section for one job, it may not be complete
  // for another job. Until we start tracking application status on each application
  // we just have to assume this section is incomplete and send them to it (page 2).
  const url = user.profile.profile_complete
    ? `/application/${role.id}/`
    : `/applicant-profile/${role.id}/`;

  /**
   * This function will only render the "Finish Application" button
   * if role is still active and open.
   */
  const renderActionButton = (url) => {
    // Show job as closed if it's archived OR (if it's closed AND they haven't submitted yet)
    if (isJobClosed()) {
      return;
    } else if (application.new_status && application.new_status.status_type === 0) {
      // application status = In progress
      return (
        <ButtonContainer>
          <StyledLink to={url}>
            <ButtonIcon src={EditFileIcon} alt="Plain Document" />
            Finish Application
          </StyledLink>
          <StyledButton isWithdraw={true} onClick={handleWithdrawApp}>
            <ButtonIcon src={HotGarbageIcon} alt="Red garbage" />
            Withdraw Application
          </StyledButton>
        </ButtonContainer>
      );
    } else {
      // application status =  submitted
      return (
        <ButtonContainer>
          {/*
           * Spec: user should be sent to the "text preview" page of an application.
           * Which seems to be the application confirmation page which is shown before
           * they submit the application.
           */}
          <StyledLink to={`/applicationconfirmation/?job_id=${application.role.id}&ea=t`}>
            <ButtonIcon src={ViewFileIcon} alt="View file" width="18px" height="18px" />
            View
          </StyledLink>
          <StyledLink
            to={`/applicant-profile/${application.role.id}/?ea=t`}
            data-testid={ATSCandidateHomeDataTestIds.EDIT_APPLICATION_BUTTON}
          >
            <ButtonIcon src={EditFileIcon} alt="Edit file" />
            Edit
          </StyledLink>
          <ManageButton
            onClick={() => {
              getApplicationAttachmentsForModal(application.id);
              openUploadDocumentModal(application.id);
            }}
          >
            <ButtonIcon src={PlainFileIcon} alt="Plain file" width="19px" height="19px" />
            Manage Documents
          </ManageButton>
        </ButtonContainer>
      );
    }
  };

  // helper function for determining whether a job is closed
  const isJobClosed = () => role.status !== 20;

  const handleWithdrawApp = () => {
    setIsWithdrawApp(!isWithdrawApp);
  };

  return (
    <RowContainer height={['fit-content', 'fit-content', '90px']}>
      <GridItemTop>
        <OverflowEllipsis>{applicationTitle}</OverflowEllipsis>
        <FlexDiv>
          <StatusAndCheckmark>
            {getApplicationStatus() === 'Application Submitted' && (
              <Icon alt="icon-check" src={greyCheckmark} />
            )}
            <ApplicationStatus>{getApplicationStatus()}</ApplicationStatus>
          </StatusAndCheckmark>
          {/* navigate back to district associated with this application*/}
          <JobBoardLink
            href={`/jobs/${jobBoardSlug}`}
            data-testid={ATSCandidateHomeDataTestIds.VIEW_MORE_ROLES_BUTTON}
            target="_blank"
          >
            <BulletPointContainer>&bull;</BulletPointContainer>
            View More Job Openings
          </JobBoardLink>
          {/*
            If role's district has a state job board (currently just Indiana as of [04/12/22])
            we also render a link to that state job board
            */}
          {(district.indiana_district || district.state === 'IN') && (
            <JobBoardLink href={`/jobs/state/${district.state.toLowerCase()}`} target="_blank">
              <BulletPointContainer>&bull;</BulletPointContainer>
              View State Job Openings
            </JobBoardLink>
          )}
        </FlexDiv>
      </GridItemTop>
      <div>{renderActionButton(url)}</div>
      {/* candidate can opt to delete the application */}
      {isWithdrawApp && (
        <WithdrawApplicationContainer>
          <WithdrawApptext>Are you sure you want to remove this application?</WithdrawApptext>
          <WithdrawAppButton onClick={() => withdrawApplication(application.id)}>
            Yes, Withdraw Application
          </WithdrawAppButton>
          <WithdrawAppNoButton onClick={() => setIsWithdrawApp(false)}>No</WithdrawAppNoButton>
        </WithdrawApplicationContainer>
      )}
    </RowContainer>
  );
}

CandidateDashboardApplicationsRow.propTypes = {
  user: PropTypes.object.isRequired,
  application: PropTypes.object,
  editModeApplicationID: PropTypes.string,
  openUploadDocumentModal: PropTypes.func.isRequired,
  withdrawApplication: PropTypes.func.isRequired,
};

const RowContainer = styled.div`
  ${grid}

  display: grid;
  align-items: center;

  width: 100%;
  max-height: 250px;
  padding: 1.3rem;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 5px;

  font-size: 15px;
  color: #393c49;

  @media (max-width: 686px) {
    max-height: 100%;
  }
`;

const GridItemTop = styled.div`
  grid-row: 1;
  justify-self: start;

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`;

const GridItemBottom = styled.div`
  justify-self: start;
  grid-row: 2;
  grid-template-columns: 1fr;
`;

const OverflowEllipsis = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Icon = styled.img`
  width: 11px;
  height: 8px;
  margin-right: 10px;
`;

const ApplicationStatus = styled.p`
  color: #979797;
  font-size: 13px;
`;

// upgrade to react-router-dom 5 Link after PR is merged in
const JobBoardLink = styled.a`
  color: #00b88d;
  font-size: 13px;

  &:hover {
    color: #00b88d;
  }
`;

const BulletPointContainer = styled.span`
  color: #979797;
  font-size: 13px;
  margin: 0 10px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  flex-direction: row;

  @media (max-width: 686px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  @media (max-width: 504px) {
    flex-direction: column;
  }
`;

const StyledButton = styled.button`
  background: #fff;
  padding: 10px;
  margin: 0 5px;
  display: flex;
  align-items: center;

  color: ${(props) => (props.isWithdraw ? '#DE3255' : '#00b88d')};
  font-size: 13px;
  font-weight: 600px;
  line-height: 18px;
  text-align: center;
  border: none;

  @media only screen and (max-width: 800px) {
    padding: 5px;
  }

  @media only screen and (max-width: 504px) {
    padding: 10px 5px;
    margin: 0;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ManageButton = styled(StyledButton)`
  border: 1px solid ${(props) => (props.isWithdraw ? '#DE3255' : '#00b88d')};
  border-radius: 4px;
`;

const StyledLink = styled(Link)`
  border: 1px solid ${(props) => (props.isWithdraw ? '#DE3255' : '#00b88d')};
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  max-height: 40px;
  color: ${(props) => (props.isWithdraw ? '#DE3255' : '#00b88d')};
  font-size: 13px;
  font-weight: 600px;
  line-height: 18px;
  text-align: center;

  @media only screen and (max-width: 800px) {
    padding: 5px;
  }

  @media only screen and (max-width: 504px) {
    margin: 0;
    padding: 10px 5px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ButtonIcon = styled.img.attrs((props) => ({
  width: props.width || '16px',
  height: props.height || '16px',
}))`
  margin-right: 10px;
`;

const WithdrawApplicationContainer = styled.div`
  background-color: #fbf4f6;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);

  grid-row: 3;
  grid-column: span 2;
  height: 79px;
  padding: 10px 20px;

  display: flex;
  align-items: center;
`;

const WithdrawApptext = styled.span`
  color: #444444;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
`;

const WithdrawAppButton = styled.button`
  border: 1px solid #de3255;
  border-radius: 3px;
  background-color: #fff;
  padding: 3px 10px;
  margin: 0 10px;

  color: #de3255;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

const WithdrawAppNoButton = styled.button`
  border: 1px solid #de3255;
  border-radius: 3px;
  background-color: transparent;
  padding: 3px 10px;
  color: #de3255;
  text-transform: uppercase;

  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

const StatusAndCheckmark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    padding-left: 8px;
  }
`;
