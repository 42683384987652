import Slider from 'react-slick';
import './Carousel.css';

export const Carousel: React.FC<{ children }> = ({ children }) => {
  const settings = {
    infinite: children.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: true,
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};
