import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const RemindSection = ({ signer }) => {
  const [reminderSent, setReminderSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const remind = () => {
    // set isSending to true so users can't spam the button to send multiple
    // emails. It doesn't get set to false upon completion because they
    // can only remind each signer once as long as the component is mounted.
    setIsSending(true);

    const payload = { signature_id: signer.signature_id };

    axios.post('/api/hellosign/remind/', payload).then(() => setReminderSent(true));
  };

  return (
    <>
      {reminderSent ? (
        <LightTextItalicized>reminder sent</LightTextItalicized>
      ) : (
        <>
          {signer.status_code === 'ready_to_sign' && (
            <RemindButton onClick={remind} disabled={isSending}>
              remind
            </RemindButton>
          )}
        </>
      )}
    </>
  );
};

export default RemindSection;

const LightTextItalicized = styled.p`
  font-weight: 400;
  font-size: 13px;

  color: #a6a7ad;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-style: italic;

  grid-column: 4 / span 1;
  justify-self: end;
`;

const RemindButton = styled.button`
  color: #00b88d;
  text-decoration: underline;
  border: none;
  background-color: #fff;
  font-size: 13px;

  grid-column: 4 / span 1;
  justify-self: end;
`;
