import { roleSectionsArr } from 'containers/jobData';
import { currentHiringSeason, startDateType } from 'utils/enums';

export const emptyTemplate = {
  custom_questions: [],
  district_users: [],
  external_date: null,
  ftes_total: 1,
  fulltime: 1,
  grades: [],
  hiring_season: currentHiringSeason(),
  hrbp_assignments: [],
  indeed_role: true,
  internal_only: false,
  internal_requirements_specified: false,
  is_template: true,
  jobboards: [],
  link_to_ats: '',
  owners: [],
  question_sets_order: [],
  question_sets: [],
  questions: [],
  requiredapplicationattachment_set: [],
  role_question_sets: [],
  role_sections: roleSectionsArr,
  salary_max: null,
  salary_min: null,
  salary_type: 4,
  schoolroles: [],
  start_date_type: startDateType['Not specified'],
  statuses_available: [],
  title: '',
  ziprecruiter_role: true,
};
