import { Component } from 'react';
import PropTypes from 'prop-types';
import InterviewDateDropdown from './InterviewDateDropdown';
import trashIcon from '../../assets/trash-bin.png';

export default class BulkEmailPreviewRow extends Component {
  static propTypes = {
    sender: PropTypes.object.isRequired,
    candidate: PropTypes.object.isRequired,
    isSchoolUser: PropTypes.bool.isRequired,
    removeRecipient: PropTypes.func.isRequired,
    updateRecipientField: PropTypes.func.isRequired,
    VARIABLE_MAP: PropTypes.object.isRequired,
    variableSet: PropTypes.any.isRequired,
  };

  updateField = (e) => {
    this.props.updateRecipientField(e, this.props.candidate.id);
  };

  render() {
    let { sender, candidate, VARIABLE_MAP, variableSet } = this.props;

    let phone_cell;
    let phone_home;
    let phone_work;

    if (candidate.profile) {
      phone_cell = candidate.profile.phone_cell;
      phone_home = candidate.profile.phone_home;
      phone_work = candidate.profile.phone_work;
    }

    return (
      <div className="row-container">
        <div className="row-item trash">
          <img src={trashIcon} alt="" onClick={() => this.props.removeRecipient(candidate.id)} />
        </div>

        {/* Begin candidate variables */}
        <div className="row-item candidate-email">
          <input
            name="email"
            className={candidate.email ? '' : 'missing-value'}
            value={candidate.email || ''}
            onChange={this.updateField}
          />
        </div>
        <div className="row-item">
          <input
            name="first_name"
            className={candidate.first_name ? '' : 'missing-value'}
            value={candidate.first_name || ''}
            onChange={this.updateField}
          />
        </div>
        <div className="row-item">
          <input
            name="last_name"
            className={candidate.last_name ? '' : 'missing-value'}
            value={candidate.last_name || ''}
            onChange={this.updateField}
          />
        </div>

        {variableSet.has(VARIABLE_MAP['Interview date & time']) && (
          <InterviewDateDropdown
            candidate={candidate}
            updateRecipientField={this.props.updateRecipientField}
          />
        )}

        {(variableSet.has(VARIABLE_MAP['Phone number']) ||
          variableSet.has(VARIABLE_MAP['Candidate phone'])) && (
          <div className="row-item">
            <input
              name="phone"
              className={phone_cell || phone_home || phone_work ? '' : 'missing-value'}
              value={phone_cell || phone_home || phone_work || ''}
              onChange={this.updateField}
            />
          </div>
        )}
        {variableSet.has(VARIABLE_MAP['Job title']) && (
          <div className="row-item">
            <input
              name="job_title"
              className={candidate.job_title ? '' : 'missing-value'}
              value={candidate.job_title || ''}
              onChange={this.updateField}
            />
          </div>
        )}
        {/* End candidate variables */}

        {/* Sender variables. User cannot edit these */}
        {variableSet.has(VARIABLE_MAP['Sender first name']) && (
          <div className="row-item">
            <input value={sender.first_name} disabled />
          </div>
        )}
        {variableSet.has(VARIABLE_MAP['Sender last name']) && (
          <div className="row-item">
            <input value={sender.last_name} disabled />
          </div>
        )}
        {variableSet.has(VARIABLE_MAP['District name']) && (
          <div className="row-item">
            <input value={sender.profile.district.name || ''} disabled />
          </div>
        )}
        {variableSet.has(VARIABLE_MAP['School name']) && (
          <div className="row-item">
            <input
              className={sender.profile.school && sender.profile.school.name ? '' : 'missing-value'}
              value={(sender.profile.school && sender.profile.school.name) || ''}
              disabled
            />
          </div>
        )}
        {/* End sender variables */}
      </div>
    );
  }
}
