import './timeline.css';

import { Component, ReactNode } from 'react';

import CandidateEmailedModal from './CandidateEmailedModal';
import Moment from 'moment';
import { ReferenceModalActivityFeed } from './ReferenceModalActivityFeed';
import { parseEvent } from 'utils/util';
import { Event } from 'components/CandidatesList/QuickProfile/types';
import { ProfileTimelineProps, ProfileTimelineState } from './types';

export default class ProfileTimeline extends Component<ProfileTimelineProps, ProfileTimelineState> {
  constructor(props: ProfileTimelineProps) {
    super(props);
    this.state = {
      showCandidateEmailedModal: false,
      viewedEmail: {},
      selectedReference: null,
      showReferenceModal: false,
    };
  }

  openCandidateEmailedModal = (event: Event): void => {
    // Change the reference format to match email
    if (event.type === 18 || event.type === 23) {
      event.message = event.model.message;
      event.subject = event.model.subject;
    } else if (event.type === 12) {
      // Change internal note format to match
      event.message = event.model.text;
      event.subject = 'Candidate Note';
    }
    this.setState({ showCandidateEmailedModal: true, viewedEmail: event });
  };

  openReferenceModal = (event: Event): void => {
    this.setState({
      showReferenceModal: true,
      selectedReference: event.model,
    });
  };

  closeCandidateEmailedModal = (): void => {
    this.setState({ showCandidateEmailedModal: false, viewedEmail: {} });
  };

  renderLabel = (label: string, event: Event, subtext: string): JSX.Element => {
    // if event type is 6 (candidate_emailed), clicking on it should show a modal with the
    // email contents.
    if (event.type === 6) {
      return (
        <span
          className="timeline-label pointer"
          onClick={() => this.openCandidateEmailedModal(event)}
        >
          {label} <span className="underline">(view email)</span>
          {subtext && <div className="subtext">{subtext}</div>}
        </span>
      );
    } else if (event.type === 15 || event.type === 11) {
      // Candidate updated profile
      return (
        <span className="timeline-label">
          {label}
          <div className="subtext">{subtext}</div>
        </span>
      );
      // FIXME: we've hidden this option for now due to file viewer error.
      // https://hirenimble.atlassian.net/jira/software/c/projects/NIM/issues/NIM-176?filter=myopenissues
      // } else if (event.type === 15 && event.resume_update) {
      //   // Candidate added resume
      //   return (
      //     <span className="timeline-label pointer">
      //       <FileViewer file={event.resume_path} title={label} onActivityFeed={true} />
      //       <div className="subtext">{subtext}</div>
      //     </span>
      //   );
    } else if (event.type === 12) {
      return (
        <span className="timeline-label" onClick={() => this.openCandidateEmailedModal(event)}>
          {label} <span className="pointer underline">(view note)</span>
          {subtext && <div className="subtext">{subtext}</div>}
        </span>
      );
    } else if (event.type === 18 || event.type === 23) {
      return (
        <span
          className="timeline-label pointer"
          onClick={() => this.openCandidateEmailedModal(event)}
        >
          {label} <span className="underline">(view email)</span>
          {subtext && <div className="subtext">{subtext}</div>}
        </span>
      );
    } else if (event.type === 19 || event.type === 24) {
      return (
        <span className="timeline-label pointer" onClick={() => this.openReferenceModal(event)}>
          {label} <span className="underline">(view)</span>
          {subtext && <div className="subtext">{subtext}</div>}
        </span>
      );
    } else {
      return (
        <span className="timeline-label">
          {label}
          {subtext && <div className="subtext">{subtext}</div>}
        </span>
      );
    }
  };

  render(): ReactNode {
    const slicer = this.props.isAbridged ? 5 : this.props.events.length;

    const timelineItems = this.props.events.slice(0, slicer).map((event, i) => {
      const { label, icon, subtext, smallerIcon } = parseEvent(
        event,
        this.props.user,
        this.props.events
      );
      let size: string | undefined;
      let style: { width?: string } | undefined;
      if (smallerIcon) {
        size = 100 - 28 * smallerIcon + '%';
        style = smallerIcon ? { width: size } : {};
      }
      return (
        <div className="timeline-item" key={i}>
          <span>{Moment(event.created_at).format('MMM DD')}</span>
          <div className="timeline-icon">
            <img alt="timeline-icon" className="mr1" src={icon} style={style} />
          </div>
          {this.renderLabel(label, event, subtext)}
        </div>
      );
    });
    return (
      <div className={this.props.isAbridged ? 'abridged' : ''}>
        {/*There used to be collapse functionality here, but Mori took it out when we*/}
        {/*repurposed this component for the application confirmation page. You can find*/}
        {/*it in git history.*/}
        {timelineItems}
        {this.state.showCandidateEmailedModal && (
          <CandidateEmailedModal
            show={this.state.showCandidateEmailedModal}
            onHide={this.closeCandidateEmailedModal}
            viewedEmail={this.state.viewedEmail}
          />
        )}
        {this.state.showReferenceModal && (
          <ReferenceModalActivityFeed
            isOpen={this.state.showReferenceModal}
            onClose={() => this.setState({ showReferenceModal: false })}
            candidate={this.props.user}
            selectedReference={this.state.selectedReference}
          />
        )}
      </div>
    );
  }
}
