import { Component } from 'react';
import PropTypes from 'prop-types';
import { handleOutsideClickOnValidForm } from '../../../utils/util';

export default class FilterDrop extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    displayName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    selectAll: PropTypes.func,
    clearAll: PropTypes.func,
    // optional message to display if there are no options
    // e.g. "You don't have any tasks yet"
    emptyOptionsMessage: PropTypes.string,
    groupedFilter: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  outsideClickListener = (ev) => handleOutsideClickOnValidForm(this.node, ev, this.toggleOptions);

  toggleOptions = () => {
    if (!this.state.showOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    this.setState({ showOptions: !this.state.showOptions });
  };

  updateFilterSelection = (e, name, value, active) => {
    e.stopPropagation();
    this.props.updateMultiSelectFilter(name, value, active);
  };

  selectAll = (e, fieldName, options) => {
    e.stopPropagation();
    this.props.selectAll(fieldName, options);
  };

  clearAll = (e, fieldName) => {
    e.stopPropagation();
    this.props.clearAll(fieldName);
  };

  render() {
    /** use a set for constant time lookup. Loop through props.values, which is a list of currently
     * active filters. Add each value to the set, and down below in the map function, check
     * if each item is in the set (optionSet.has(o[valueName]). This is faster than looping through
     * the values array during each iteration of map.
     * Also, send optionSet.has(o[valueName]) when the item is clicked, so that the container
     * knows if you're clicking an already active filter (in which case it will turn it off).
     */
    const optionSet = new Set();
    this.props.values.forEach((o) => {
      optionSet.add(o);
    });
    const { displayName, fieldName, options, values } = this.props;

    // if there is one filter option selected (e.g. Resume Review), show that name. If there are
    // multiple selected, show 'multiple'. otherwise show the displayName (e.g. Status).
    // some of the enums have different labeling (-_-), so account for differences.
    const valueName =
      fieldName === 'status_list' ? 'id' : fieldName === 'cred_list' ? 'id' : 'value';

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    // Allow up to 2 to not be selected for archived and draft which are excluded by default;
    // we still don't want to draw attention to the box if only those statuses are not active
    let allSelected = values.length === options.length;
    let noneSelected = values.length === 0;
    return (
      <div
        className={`cl-filter-dropdown-div ${this.props.fieldName} ${
          allSelected || noneSelected ? '' : 'cl-active'
        }`}
        onClick={this.toggleOptions}
        ref={(node) => (this.node = node)}
      >
        <div className="filter-drop-display-name">{displayName}</div>
        <div
          className={`cl-filter-selection-list ${this.state.showOptions ? 'show' : ''} ${
            this.props.groupedFilter ? 'grouped-filter' : ''
          }`}
        >
          {options.length === 0 && this.props.emptyOptionsMessage ? (
            <div className="cl-filter-option select-all">{this.props.emptyOptionsMessage}</div>
          ) : this.props.selectAll && this.props.clearAll ? (
            <div className="cl-filter-option select-all">
              <span
                className="filter-drop-select-all"
                onClick={(e) => this.selectAll(e, fieldName, options)}
              >
                SELECT ALL
              </span>
              <span className="filter-drop-select-all" onClick={(e) => this.clearAll(e, fieldName)}>
                CLEAR
              </span>
            </div>
          ) : null}
          {options.map((o, i) => (
            <div
              key={i}
              className={`cl-filter-option ${optionSet.has(o[valueName]) ? 'selected' : ''} ${
                o.groupHeader ? 'group-header' : ''
              }`}
              onClick={(e) =>
                this.updateFilterSelection(e, fieldName, o[valueName], optionSet.has(o[valueName]))
              }
            >
              {o.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
