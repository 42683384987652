import { useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

export default function SocialMediaTrackingContainer(props) {
  const history = useHistory();
  const { code } = useParams();

  useEffect(() => {
    let isChanged = false;

    const trackingParams = { code, should_track: true };

    axios
      .get('/api/socialmedia/share/', { params: trackingParams })
      .then((r) => {
        // this guards against code possibly changing and triggering a new request
        // and throwing away the old request because we only care about the last
        // request that occurred.
        if (isChanged) return;

        window.localStorage.setItem('social_media_source', r.data.social_media_source.id);
        const jobId = r.data.role ? r.data.role.id : r.data.school_role.id;
        let url = `/jobview/${jobId}/`;

        if (r.data.school_role) {
          url = `${url}?is_school_role=1`;
        }

        history.push(url);
      })
      .catch(() => {
        if (isChanged) return;
        history.push('/notfound');
      });

    return () => {
      isChanged = true;
    };

    // while history is a stable reference, this guards against react-router-dom potentially making some change
    // where history becomes unstable
  }, [code, history]);

  return <></>;
}
