import { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DirectionTextIcon } from 'ui-kit/icons';
import ModalFooter from 'components/common/Modal/ModalFooter';
import ReactQuill from 'react-quill';

export default function AddJobQuestionModal({
  show,
  onHide,
  addQuestion,
  type,
  internal_requirements_specified,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [directions, setDirections] = useState('');
  const [is_visible_external, set_is_visible_external] = useState(true);
  const [is_visible_internal, set_is_visible_internal] = useState(true);

  const onSave = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }
    addQuestion(
      {
        prompt: directions,
        is_visible_external,
        is_visible_internal,
        // direction text items are never required since there's
        // no input from the candidate
        is_required_external: false,
        is_required_internal: false,
        autotags: [],
      },
      type
    );
  };

  const checkForErrors = () => {
    if (directions.length === 0) {
      throw new Error('Direction text cannot be blank');
    }
  };

  const questionTypeMapping = {
    key: 'direction_text',
    value: 5,
    label: 'Direction Text',
    icon: DirectionTextIcon,
    info: 'Gives the candidate clear directions on how to fill out this application',
  };
  const QuestionTypeIcon = questionTypeMapping.icon;

  const renderQuestionTypeSection = () => {
    return (
      <>
        <Header>Type directions here:</Header>
        <ReactQuillContainer>
          <ReactQuill
            value={directions || ''}
            onChange={(value) => {
              setDirections(value);
            }}
            placeholder="e.g. Complete and submit this form within 3 days of your interview"
          />
        </ReactQuillContainer>
      </>
    );
  };

  const handleVisibilityChange = (e) => {
    if (e.target.name === 'is_visible_internal') {
      set_is_visible_internal(e.target.checked);
    } else if (e.target.name === 'is_visible_external') {
      set_is_visible_external(e.target.checked);
    }

    setErrorMessage('');
  };

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <ModalHeader>
        <Title>New Item</Title>
        <Cancel onClick={onHide}>×</Cancel>
      </ModalHeader>
      <Body>
        <HeaderGrid>
          <QuestionTypeIcon width="40" height="40" />
          <div>
            <div className="bold">{questionTypeMapping.label}</div>
            <GreyText>{questionTypeMapping.info}</GreyText>
          </div>
          <EndItem>
            {internal_requirements_specified && (
              <CheckboxContainer>
                <GridItemHeader />
                <GridItemHeader>visible</GridItemHeader>
                <GridItem>external</GridItem>
                <DivWithAllBorders>
                  <input
                    type="checkbox"
                    name="is_visible_external"
                    style={{ margin: '0' }}
                    value={is_visible_external}
                    checked={is_visible_external}
                    onChange={handleVisibilityChange}
                    data-testid="add-job-question-is-visible-external"
                  />
                </DivWithAllBorders>
                <GridItem>internal</GridItem>
                <DivWithNoTopBorder>
                  <input
                    type="checkbox"
                    name="is_visible_internal"
                    style={{ margin: '0' }}
                    value={is_visible_internal}
                    checked={is_visible_internal}
                    onChange={handleVisibilityChange}
                    data-testid="add-job-question-is-visible-internal"
                  />
                </DivWithNoTopBorder>
              </CheckboxContainer>
            )}
          </EndItem>
        </HeaderGrid>
        {renderQuestionTypeSection()}
      </Body>
      <ModalFooter
        cancel={onHide}
        save={onSave}
        errorMessage={errorMessage}
        cancelButtonLabel="Cancel"
        saveButtonLabel="Save item"
      />
    </Modal>
  );
}

AddJobQuestionModal.propTypes = {
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  addQuestion: PropTypes.func.isRequired,
  internal_requirements_specified: PropTypes.bool.isRequired,
};

const ModalHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 17px;

  display: grid;
  grid-template-columns: 30px auto 30px 30px;
  align-items: center;
`;

const Title = styled.p`
  font-size: 17px;
  color: #444444;

  grid-column: 2 / span 1;
`;

const Cancel = styled.div`
  grid-column: 3 / span 2;
  height: 100%;

  color: #999999;

  border-left: 1px solid #d8d8d8;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const ReactQuillContainer = styled.div`
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
  }
`;

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 210px;
  align-items: center;
  grid-gap: 1.5rem;
  margin-bottom: 3rem;
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 70px 62px;
  grid-template-rows: 30px repeat(2, 40px);

  font-size: 14px;
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridItemHeader = styled(GridItem)`
  align-self: start;
`;

const EndItem = styled.div`
  justify-self: end;
`;

const DivWithAllBorders = styled(GridItem)`
  border: 1px solid rgba(57, 60, 73, 0.5);
`;

const DivWithNoTopBorder = styled(DivWithAllBorders)`
  border-top: 0;
`;

const GreyText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const Body = styled.div`
  position: relative;
  background-color: #ffffff;

  padding: 30px;

  .quill {
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    border-color: #dcdcdc;
  }
`;
