import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Modal } from 'ui-kit';

import Attachment from './components/Attachment';
import MultipleChoice from './components/MultipleChoice';
import NimbleQuestion from './components/NimbleQuestion';
import { SchoolPreferencesQuestion } from './components/SchoolPreferencesQuestion';
import StatementCheckbox from './components/StatementCheckbox';
import TextQuestion from './components/TextQuestion';
import VideoLink from './components/VideoLink';
import YesNoQuestion from './components/YesNoQuestion';
import DirectionText from './components/DirectionText';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import auth from 'utils/auth';
import customTagAPI from 'api/customTagAPI';

import { questionType as type } from '../../utils/enums';
import { isFeatureEnabled } from '../../hoc/Feature';

import schoolsAPI from 'api/schoolsAPI';

export default function EditItemModal({
  isOpen,
  onClose,
  item,
  onSave,
  onDelete,
  questionBank,
  internalRequirementsSpecified,
}) {
  const [questionText, setQuestionText] = useState(item.prompt || item.title);
  const [nimbleQuestionId, setNimbleQuestionId] = useState(item.nimblequestion_id || null);

  // handles state for required & visibility checkboxes
  const [itemMatrix, setItemMatrix] = useState({
    isRequiredExternal: item.is_required_external,
    isRequiredInternal: item.is_required_internal,
    isVisibleExternal: item.is_visible_external,
    isVisibleInternal: item.is_visible_internal,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(item.mc_options);
  const [multiSelect, setMultiSelect] = useState(item.multi_select);
  const [attachmentDirections, setAttachmentDirections] = useState(
    item.attachment_directions || ''
  );
  const [description, setDescription] = useState(item.description || '');
  const [isAutomaticList, setIsAutomaticList] = useState(item.is_automatic_list || null);
  const [selectedSchools, setSelectedSchools] = useState(item.school_choices || []);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [autoTags, setAutoTags] = useState(null);
  const [authorizedTags, setAuthorizedTags] = useState([]);
  const shouldShowNoTagsForSuperAdmin = auth.isSuperAdminUser() && Boolean(authorizedTags);

  useEffect(() => {
    schoolsAPI.fetchActiveSchools().then((schoolOptions) => setSchoolOptions(schoolOptions));
  }, []);

  const saveItem = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    onSave({
      ...item,
      draggable_id: item.draggable_id || shortid.generate(),
      prompt: questionText,
      title: questionText,
      nimblequestion_id: nimbleQuestionId,
      is_required_external: itemMatrix.isRequiredExternal,
      is_required_internal: itemMatrix.isRequiredInternal,
      is_visible_internal: itemMatrix.isVisibleInternal,
      is_visible_external: itemMatrix.isVisibleExternal,
      mc_options: multipleChoiceOptions,
      multi_select: multiSelect,
      attachment_directions: attachmentDirections,
      description: description,
      is_automatic_list: isAutomaticList,
      school_choices: selectedSchools,
      autotags: autoTags,
    });
  };

  const checkForErrors = () => {
    if (item.question_type === type.multiple_choice) {
      // iterate over multiple choice options and make sure none are blank.
      for (let i = 0; i < multipleChoiceOptions.length; i++) {
        if (multipleChoiceOptions[i].trim() === '') {
          throw new Error('Each answer must have a value');
        }
      }
    } else if (questionTextMissing) {
      throw new Error('Question prompt cannot be blank');
    } else if (item.question_type === type.videoLink && !questionText) {
      throw new Error('Video name cannot be blank');
    }
  };

  const questionTextMissing =
    item.question_type !== type.schoolPreferences &&
    item.question_type !== type.nimble &&
    item.question_type !== type.videoLink &&
    questionText.trim() === '';

  const handleMultipleChoiceTextChange = (e, index) => {
    const newOptions = [...multipleChoiceOptions];
    newOptions[index] = e.target.value;
    setMultipleChoiceOptions(newOptions);
  };

  const addAnswer = () => {
    // push in an empty answer
    const newOptions = [...multipleChoiceOptions];
    newOptions.push('');
    setMultipleChoiceOptions(newOptions);
  };

  const removeOption = (index) => {
    const newOptions = [...multipleChoiceOptions];
    newOptions.splice(index, 1);
    setMultipleChoiceOptions(newOptions);
  };

  const handleItemMatrixChange = (e) => {
    const { name, checked } = e.target;

    if (name === 'isRequiredInternal' && checked === true) {
      setItemMatrix({ ...itemMatrix, isRequiredInternal: true, isVisibleInternal: true });
    } else if (name === 'isRequiredExternal' && checked === true) {
      setItemMatrix({ ...itemMatrix, isRequiredExternal: true, isVisibleExternal: true });
    } else {
      // update specified item
      setItemMatrix({ ...itemMatrix, [name]: checked });
    }
  };

  const handleSetAutoTags = (autoTags) => setAutoTags(autoTags);

  useEffect(() => {
    customTagAPI.fetchCustomTags().then((tags) => {
      setAuthorizedTags(tags);
    });
  }, []);

  useEffect(() => {
    setAutoTags(item.autotags || []);
  }, [item]);

  const questionHasHiddenTags = useMemo(() => {
    if (auth.isSuperAdminUser()) return false;
    const questionTags = autoTags?.map((option) => option.tag_id);
    if (!questionTags) return false;

    const authorizedTagIds = authorizedTags?.map((tag) => tag.id);
    const authorizedTagIdsSet = new Set(authorizedTagIds);

    return !questionTags.every((questionTag) => authorizedTagIdsSet.has(questionTag));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, authorizedTags]);

  const hasTagFeatureFlag = isFeatureEnabled('streamlined_screening');

  const renderItemBody = () => {
    switch (item.question_type) {
      case type.open_response:
        return (
          <TextQuestion
            questionText={questionText}
            setQuestionText={setQuestionText}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.multiple_choice:
        return (
          <MultipleChoice
            questionText={questionText}
            setQuestionText={setQuestionText}
            addAnswer={addAnswer}
            mc_options={multipleChoiceOptions}
            multi_select={multiSelect}
            setMultiSelect={setMultiSelect}
            handleMultipleChoiceTextChange={handleMultipleChoiceTextChange}
            removeOption={removeOption}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.nimble:
        return (
          <NimbleQuestion
            nimbleQuestionId={nimbleQuestionId}
            setNimbleQuestionId={setNimbleQuestionId}
            questionBank={questionBank}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.attachment:
        return (
          <Attachment
            questionText={questionText}
            setQuestionText={setQuestionText}
            attachmentDirections={attachmentDirections}
            setAttachmentDirections={setAttachmentDirections}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.schoolPreferences:
        return (
          <SchoolPreferencesQuestion
            isAutomaticList={isAutomaticList}
            selectedSchools={selectedSchools}
            schoolOptions={schoolOptions}
            onAutomaticListToggle={() => {
              setIsAutomaticList((prevIsAutomaticList) => {
                const isAutomaticList = !prevIsAutomaticList;

                // We need to reset the automatically selected ids.
                if (isAutomaticList) {
                  setSelectedSchools([]);
                }

                return isAutomaticList;
              });
            }}
            onSelectedSchoolChange={(selectedSchool) => {
              setSelectedSchools((prevSelectedSchoolIds) => {
                if (prevSelectedSchoolIds.includes(selectedSchool.id)) {
                  return prevSelectedSchoolIds.filter((s) => s !== selectedSchool.id);
                } else {
                  return [...prevSelectedSchoolIds, selectedSchool.id];
                }
              });
              setIsAutomaticList(false);
            }}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.statementCheckbox:
        return (
          <StatementCheckbox
            questionText={questionText}
            setQuestionText={setQuestionText}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
            existingAutoTags={autoTags}
            authorizedTags={authorizedTags}
            handleSetAutoTags={handleSetAutoTags}
            questionHasHiddenTags={questionHasHiddenTags}
            hasTagsAvailable={Boolean(authorizedTags.length)}
            shouldShowAutoTagLink={hasTagFeatureFlag}
          />
        );
      case type.videoLink:
        return (
          <VideoLink
            videoLinkName={questionText}
            setVideoLinkName={setQuestionText}
            description={description}
            setDescription={setDescription}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.yes_no:
        return (
          <YesNoQuestion
            questionText={questionText}
            setQuestionText={setQuestionText}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
            existingAutoTags={autoTags}
            handleSetAutoTags={handleSetAutoTags}
            authorizedTags={authorizedTags}
            questionHasHiddenTags={questionHasHiddenTags}
            hasTagsAvailable={Boolean(authorizedTags.length)}
            shouldShowAutoTagLink={hasTagFeatureFlag}
          />
        );
      case type.direction_text:
        return (
          <DirectionText
            directions={questionText}
            setDirections={setQuestionText}
            handleItemMatrixChange={handleItemMatrixChange}
            itemMatrix={itemMatrix}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal.Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>New Item</Modal.Title>
      {renderItemBody()}
      <Modal.Actions>
        <ModalFooterNew
          cancel={onClose}
          cancelButtonLabel="Cancel"
          deleteFunction={onDelete}
          deleteButtonLabel="Delete item"
          errorMessage={errorMessage}
          save={saveItem}
          saveButtonLabel="Save item"
        />
      </Modal.Actions>
    </Modal.Modal>
  );
}

EditItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  questionBank: PropTypes.arrayOf(PropTypes.object).isRequired,
};
