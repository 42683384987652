import styled from 'styled-components';
import { grid } from 'styled-system';

import { theme } from 'ui-kit';

import { StateJobBoardDropDownWithSubOptions } from './Filters/StateJobBoardDropDownWithSubOptions';
import { StateJobBoardDropDown } from './Filters/StateJobBoardDropDown';
import { StartDateFilterDropDown } from './Filters/StartDateFilterDropDown';
import { ATSStateJobBoardFiltersDataTestIds } from 'data-testids/ATS';

export default function StateJobBoardFilterDropDowns({
  filterValues,
  actions,
  filterOptions,
  activeFilters,
  setUseDebounce,
  error,
}) {
  const isActiveSetter = (fieldName) => {
    // Takes a field name and returns a function. The function
    // accepts a boolean and sets the given field to that value.
    // Usage (parent component): setIsActive={isActiveSetter('categories')}
    // Usage (child component): setIsActive(true)
    return (value) =>
      actions.setActiveFilters((previousState) => ({
        ...previousState,
        [fieldName]: value,
      }));
  };

  return (
    <Container
      gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(3, 1fr)', 'repeat(6, 1fr)']}
      gridTemplateRows={[
        'repeat(6, 1fr)',
        'repeat(6, 1fr)',
        'repeat(6, 1fr)',
        'repeat(2, 1fr)',
        '1fr',
      ]}
    >
      <StateJobBoardDropDownWithSubOptions
        values={filterValues.subcategoryStateIds}
        setValues={actions.setSubcategoryStateIds}
        options={filterOptions.subcategoryStateOptions}
        displayName="Job category"
        dataTestId={ATSStateJobBoardFiltersDataTestIds.CATEGORY_FILTER}
        isActive={activeFilters.subcategoryStateIds}
        setIsActive={isActiveSetter('subcategoryStateIds')}
        mainColor="#151E47"
        highlightColor="#FED720"
        showSearchInput
        showSelectAllButtons
      />
      <StateJobBoardDropDown
        values={filterValues.districtIds}
        setValues={actions.setDistrictIds}
        options={filterOptions.districtOptions}
        displayName="Organization"
        dataTestId={ATSStateJobBoardFiltersDataTestIds.ORGANIZATION_FILTER}
        isActive={activeFilters.districtIds}
        setIsActive={isActiveSetter('districtIds')}
        mainColor="#151E47"
        highlightColor="#FED720"
        showSearchInput
        showSelectAllButtons
        error={error}
      />
      <StateJobBoardDropDownWithSubOptions
        values={filterValues.grades}
        setValues={actions.setGrades}
        options={filterOptions.gradeOptions}
        displayName="Grade level"
        dataTestId={ATSStateJobBoardFiltersDataTestIds.GRADE_LEVEL_FILTER}
        isActive={activeFilters.grades}
        setIsActive={isActiveSetter('grades')}
        mainColor="#151E47"
        highlightColor="#FED720"
        showSelectAllButtons
      />
      <StateJobBoardDropDown
        values={filterValues.fulltime}
        setValues={actions.setFulltime}
        options={filterOptions.employmentTypeOptions}
        displayName="Employment type"
        dataTestId={ATSStateJobBoardFiltersDataTestIds.EMPLOYMENT_TYPE_FILTER}
        isActive={activeFilters.fulltime}
        setIsActive={isActiveSetter('fulltime')}
        mainColor="#151E47"
        highlightColor="#FED720"
      />
      <StartDateFilterDropDown
        filterValues={filterValues}
        actions={actions}
        isActive={activeFilters.startDate}
        setIsActive={isActiveSetter('startDate')}
        dataTestId={ATSStateJobBoardFiltersDataTestIds.START_DATE_FILTER}
        highlightColor="#FED720"
      />
      <StateJobBoardDropDown
        values={filterValues.schoolIds}
        setValues={actions.setSchoolIds}
        options={filterOptions.schoolOptions}
        displayName="School"
        dataTestId={ATSStateJobBoardFiltersDataTestIds.SCHOOL_FILTER}
        isActive={activeFilters.schoolIds}
        setIsActive={isActiveSetter('schoolIds')}
        mainColor="#151E47"
        highlightColor="#FED720"
        showSearchInput
        showSelectAllButtons
        error={error}
      />
    </Container>
  );
}

const Container = styled.div`
  ${grid}

  display: grid;
  grid-gap: 20px;

  padding-top: 4px;
  padding-bottom: 20px;
  background-color: #ffffff;
`;
