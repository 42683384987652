import PropTypes from 'prop-types';

import { StyledSelect } from 'ui-kit';

const DropdownSingle = ({
  label,
  value,
  handleChange,
  placeholder,
  options,
  disabled,
  isSearchable = false,
}) => (
  <div className="reusable-select-container">
    <h4 className="mb1">{label}</h4>
    <StyledSelect
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      isDisabled={disabled}
      isSearchable={isSearchable}
    />
  </div>
);

DropdownSingle.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.object,
};

export default DropdownSingle;
