import { styled, Typography } from '@mui/material';
import { nimbleTheme } from 'theme';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const HeaderLabel = styled(Typography)({
  color: `${nimbleTheme.palette.text.tertiary}`,
  display: 'inline-block',
  fontSize: '19px',
  marginBottom: '1rem',
  '@media print': {
    fontSize: nimbleTheme.typography.body3.fontSize,
    fontWeight: nimbleTheme.typography.fontWeightBold,
    marginTop: '0 !important',
    marginBottom: nimbleTheme.spacing(1),
  },
});

export const MUIAttachmentIcon = styled(AttachFileIcon)({
  fill: nimbleTheme.palette.text.tertiary,
  paddingRight: nimbleTheme.spacing(0.5),
  transform: 'rotate(45deg)',
  width: 'inherit',
});
