import { useState } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';

import { Input } from 'sharedComponents/Input';
import { Modal } from 'sharedComponents/Modal';
import { PrimaryButton, TextButton } from 'sharedComponents/Buttons';
import { ReturnSubmittedRoleModalProps } from './types';

const ReturnSubmittedRoleModal: React.FC<ReturnSubmittedRoleModalProps> = ({
  fetchItems,
  isOpen,
  onClose,
  returnSubmittedRole,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [noteText, setNoteText] = useState('');

  function handleNoteChange(event) {
    setNoteText(event.target.value);
    setErrorMessage('');
  }

  function handleNoteSend() {
    try {
      checkForEmptyMessage();
      returnSubmittedRole({
        text: noteText,
        created_at: new Date(moment.now()),
      });
      fetchItems(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  }

  function checkForEmptyMessage() {
    if (!noteText) {
      throw new Error('Please add a note');
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={'Return request with note'}
      ctaButtons={
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <PrimaryButton onClick={handleNoteSend}>Save & Send</PrimaryButton>
        </Box>
      }
    >
      <div>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          Add Note
        </Typography>

        <Input
          multiline
          placeholder={
            'Add a note to the user who made this vacancy request, explaining why it was not approved.'
          }
          onChange={handleNoteChange}
          rows={5}
        />

        {errorMessage}
        <Typography variant="subtitle2" sx={{ margin: '8px 0' }}>
          The request will be bounced back to the sender&apos;s drafts, where (s)he can edit and
          resubmit for approval.
        </Typography>
      </div>
    </Modal>
  );
};

export default ReturnSubmittedRoleModal;
