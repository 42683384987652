import { Grid, Modal as MuiModal } from '@mui/material';
import { BlankModalProps } from './types';
import { ModalContainer } from './styles';
import React from 'react';

export const BlankModal = React.forwardRef<HTMLDivElement, BlankModalProps>(
  ({ dataTestId, header, body, open, onClose, sx }, ref) => {
    return (
      <MuiModal sx={sx} open={open} onClose={onClose} data-testid={dataTestId} ref={ref}>
        <ModalContainer sx={sx}>
          <Grid container direction={'column'}>
            <Grid item>{header}</Grid>
            <Grid item>{body}</Grid>
          </Grid>
        </ModalContainer>
      </MuiModal>
    );
  }
);

BlankModal.displayName = 'BlankModal';
