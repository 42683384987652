import { Preference } from 'types/types';

export const preferenceDefault: Preference = {
  location: '',
  multilingual_school: null,
  title_1_school: null,
  turnaround_school: null,
  employment_type: null,
  hours_per_week: '',
  grades: null,
  categories: null,
  miles_within: '',
  open_to_opportunities: null,
  visible_outside_radius: null,
  states: [],
  districts_excluded: [],
};

export const MOBILE_BREAKPOINT = 768;
