import { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import pipelineArrow from 'assets/pipeline-arrow.svg';
import pipelineArrowFirst from 'assets/pipeline-arrow-first.svg';
import showMoreIcon from 'assets/icon-show-more.svg';

import { HeaderLabel, HomepageContentContainer } from 'components/Home/common';
import { useHistory } from 'react-router-dom';

export default function HiringPipeline({ ordered_status_list, urlPrefix }) {
  const [showMore, setShowMore] = useState(false);

  const more = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowMore(true);
  };

  const less = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowMore(false);
  };

  const visibleStatuses = showMore ? ordered_status_list : ordered_status_list.slice(0, 7);
  const history = useHistory();

  return (
    <HomepageContentContainer>
      <HeaderLabel>Hiring pipeline</HeaderLabel>
      <div style={{ marginLeft: '10px' }}>
        {visibleStatuses.map((status, i) => {
          let url = i % 7 === 0 ? pipelineArrowFirst : pipelineArrow;
          let linkStyle = { backgroundImage: `url(${url})` };
          return (
            <div className="pipeline-section" style={linkStyle} key={status.id}>
              <div
                className="pipeline-section-div"
                onClick={() => history.push(`${urlPrefix}candidates?f_JS=1%2C2&f_CS=${status.id}`)}
              >
                <h3>{status.count.all || 0}</h3>
                <div className="pipeline-section-title">{status.label.toUpperCase()}</div>
              </div>
              {!showMore && i === 6 && ordered_status_list.length > 7 && (
                <div>
                  <img
                    className="show-more-statuses"
                    src={showMoreIcon}
                    alt="show more"
                    onClick={more}
                    data-tip
                    data-for="show-more"
                  />
                  <ReactTooltip id="show-more" effect="solid">
                    <span>More</span>
                  </ReactTooltip>
                </div>
              )}
              {showMore && i === ordered_status_list.length - 1 && (
                <div>
                  <img
                    className="show-more-statuses flipped"
                    src={showMoreIcon}
                    alt="show less"
                    onClick={less}
                    data-tip
                    data-for="show-less"
                  />
                  <ReactTooltip id="show-less" effect="solid">
                    <span>Less</span>
                  </ReactTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </HomepageContentContainer>
  );
}
