import { BaseNimbleAPI } from './baseAPI';

class ConnectSubscriptionsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/connect_subscriptions' };
  }

  async getSubscriptions() {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  async createSubscription() {
    const url = '/';
    const response = await this.post(url);
    return response.data;
  }
}

export default new ConnectSubscriptionsAPI();
