export enum DropdownFeatures {
  CLEAR_ALL = 'clear-all',
  CLEAR_INPUT = 'clear-input',
  INPUT_FILTER = 'input-filter',
  OPTION = 'option',
  OPTION_CHECKBOX = 'option-checkbox',
  OPTION_LABEL = 'option-label',
  SELECT_ALL = 'select-all',
  SELECTION_LIST = 'selection-list',
  SUB_OPTION = 'sub-option',
  SUB_OPTION_CHECKBOX = 'sub-option-checkbox',
  SUB_OPTION_LABEL = 'sub-option-label',
}

export enum RoleStartDateOptions {
  AFTER = 'after',
  BEFORE = 'before',
  IMMEDIATE = 'immediate',
  NEXT_SCHOOL_YEAR = 'next-school-year',
}
