import { USStates } from 'utils/enums';

export const getStateIDFromStateCode = (stateCode: string): number | string => {
  const state = USStates().find((state) => state.label === stateCode);
  if (!state) {
    return '';
  } else {
    return Number(state.id);
  }
};
