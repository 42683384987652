import { BaseNimbleAPI } from './baseAPI';

class EmailTemplateAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/emailtemplatetypes' };
  }

  async getAll() {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }
}

export default new EmailTemplateAPI();
