import styled from 'styled-components';

import CheckmarkUnchecked from './icons/checkmark-circle-unchecked.svg';
import CheckmarkChecked from './icons/checkmark-circle-checked.svg';

const AccordionCheckbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    {/* Note that these checkboxes should be in-sync */}
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      {checked ? <img src={CheckmarkChecked} alt="" /> : <img src={CheckmarkUnchecked} alt="" />}
    </StyledCheckbox>
  </CheckboxContainer>
);

export default AccordionCheckbox;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  background-image: url(${(props) => (props.checked ? CheckmarkChecked : CheckmarkUnchecked)});
  border-radius: 3px;
  transition: all 150ms;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  background-color: transparent;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
`;
