import { CircularProgress, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { PrimaryButton } from './PrimaryButton';

export interface LoadingButtonProps {
  sx?: SxProps;
  children: ReactNode;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({ sx, children }) => {
  return (
    <PrimaryButton disabled>
      <CircularProgress color="inherit" sx={sx} />
      &nbsp; {children}
    </PrimaryButton>
  );
};
