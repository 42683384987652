import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';
import { Key, ModalContainer, Row, SuccessSubContainer, Title } from '../styles';
import { SuccessPageProps } from '../types';

export const SuccessPage: React.FC<SuccessPageProps> = ({
  candidateName,
  candidateEmail,
  mockResponse,
}) => {
  const { getValues } = useFormContext();
  const formValues = getValues();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ModalContainer>
        <Title>Success!</Title>

        <SuccessSubContainer>
          <Key>
            We&apos;ve successfully imported this candidate from Nimble Connect! Here are the
            details:
          </Key>
        </SuccessSubContainer>

        <SuccessSubContainer>
          <Row>
            <Key>Candidate:</Key> &nbsp;{candidateName}
          </Row>
          <Row>
            <Key>Candidate email:</Key> &nbsp; {candidateEmail}
          </Row>
          <Row>
            <Key>Role:</Key> &nbsp;{formValues.role.title}
          </Row>
          <Row>
            <Key>Location:</Key> &nbsp;{mockResponse?.jobLocation}
          </Row>
        </SuccessSubContainer>

        {mockResponse?.internalNote && (
          <SuccessSubContainer>
            <Key>Notes:</Key>
            {mockResponse?.internalNote}
          </SuccessSubContainer>
        )}

        <SuccessSubContainer>
          <Key>Here&apos;s what&apos;s next:</Key>
          <li>We&apos;ve invited this candidate to apply to this role.</li>
          <li>
            Once they have submitted their application, it will be reviewed by your central HR team.
          </li>
          <li>
            After your central HR team&apos;s approval, the candidate&apos;s application will become
            visible to you in Nimble.
          </li>
        </SuccessSubContainer>
      </ModalContainer>
    </Box>
  );
};
