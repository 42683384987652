import { useState } from 'react';
import axios from 'axios';
import { mutate } from 'swr';

import useTopCandidates from './useTopCandidates';

import TopCandidateCard from './TopCandidateCard';

import { ThreeColumnGrid, HeaderLabel, HomepageContentContainer } from 'components/Home/common';

export default function TopCandidates() {
  const { data, isLoading, KEY } = useTopCandidates();
  const [firstCardIndex, setFirstCardIndex] = useState(0);

  if (isLoading || data.length === 0) {
    return null;
  }

  const lastCardIndex = firstCardIndex + 3;
  const moreCandidatesToShowLeft = firstCardIndex > 0;
  const moreCandidatesToShowRight = lastCardIndex < data.length - 1;

  const ignoreTopCandidate = (id) => {
    const newData = data.filter((c) => c.id !== id);

    // update the local data immediately, but disable the revalidation
    mutate(KEY, newData, false);

    axios.post('/api/scores/ignore-top-candidate/', { id }).catch((err) => handleError(err, KEY));
  };

  const handleError = (error, KEY) => {
    // trigger a revalidation (refetch) to make sure our local data is correct
    mutate(KEY);

    throw new Error(error);
  };

  return (
    <HomepageContentContainer>
      <HeaderLabel>Top candidates</HeaderLabel>
      <ThreeColumnGrid>
        {data.slice(firstCardIndex, lastCardIndex).map((candidate, index) => (
          <TopCandidateCard
            key={candidate.id}
            candidate={candidate}
            showMoveLeftIcon={index === 0 && moreCandidatesToShowLeft}
            showMoveRightIcon={index === 2 && moreCandidatesToShowRight}
            onMoveLeft={() => setFirstCardIndex((prev) => prev - 3)}
            onMoveRight={() => setFirstCardIndex((prev) => prev + 3)}
            ignoreTopCandidate={ignoreTopCandidate}
          />
        ))}
      </ThreeColumnGrid>
    </HomepageContentContainer>
  );
}
