import { Component } from 'react';
import PropTypes from 'prop-types';

import iconUpCaretGreen from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import { ATSCandidatesListFilterTestIds } from 'data-testids/ATS';

export default class FilterGroup extends Component {
  state = {
    showSubFilters: false,
  };

  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  };

  toggleSubFilters = () => {
    this.setState((prevState) => ({
      showSubFilters: !prevState.showSubFilters,
    }));
  };

  render() {
    const { isActive, title } = this.props;

    return (
      <>
        <div
          className={`${isActive ? 'cl-active' : ''} new-cl-filter group-filter`}
          data-testid={ATSCandidatesListFilterTestIds.CANDIDATE_PREFERENCES_SECTION}
        >
          <div className="cl-filter-header" onClick={this.toggleSubFilters}>
            <p className={isActive ? 'cl-active' : ''}>{title}</p>
            <img
              src={
                this.state.showSubFilters && isActive
                  ? iconUpCaretGreen
                  : !this.state.showSubFilters && isActive
                    ? iconDownCaretGreen
                    : this.state.showSubFilters
                      ? iconUpCaret
                      : iconDownCaret
              }
              alt=""
            />
          </div>
        </div>
        {this.state.showSubFilters && <div>{this.props.children}</div>}
      </>
    );
  }
}
