import axios from 'axios';
import querystring from 'querystring';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.paramsSerializer = querystring.stringify;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    console.log(error.response);
    return Promise.reject(error);
  }
);
