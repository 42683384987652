import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-system';
import PropTypes from 'prop-types';

import { scorecardQType } from 'utils/enums';
import ErrorText from '../../errortext';

import { AltModal, DropdownWithInputFilter } from 'ui-kit';

import ReferenceEditorQuestions from './ReferenceEditorQuestions';

import referenceCheckAPI from 'api/referenceCheckAPI';
import referenceCheckAnswerAPI from 'api/referenceCheckAnswerAPI';

import useDebounce from 'hooks/useDebounce';

const isAttachment = (answer) => answer.question_type === scorecardQType.attachment;

export default function ReferenceEditor({
  mode,
  referenceCheckToEdit,
  referenceTemplates,
  adminUsers,
  additional_references,
  onBack,
  deleteReferenceCheck,
  referencePostSave,
  emailTaggedUsers,
  candidate,
  defaultReferenceTemplateId,
  selectedDistrictReference,
}) {
  const [reference, setReference] = useState({});
  const [referenceCheck, setReferenceCheck] = useState({
    answers: [],
  });
  const [referenceTemplate, setReferenceTemplate] = useState({
    questions: [],
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* When autosaving, delay ten seconds before sending another POST */
  const [autosave] = useDebounce(() => {
    onSave(true);
  }, 10000);

  const getMaxCumulativeScore = useCallback((referenceTemplate, question) => {
    let maxScore = 0;
    // Loop through other questions in the template and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    referenceTemplate.questions.forEach((item) => {
      if (item.question_type === scorecardQType.rating && question.include_rating_questions) {
        maxScore += item.scale;
      }
      if (item.question_type === scorecardQType.rubric && question.include_rubric_questions) {
        // scoreToAdd will be the largest number in the rubric, which is found by looking
        // at the "number" property of the last column
        let scoreToAdd = item.rubric_columns[item.rubric_columns.length - 1].number;
        maxScore += scoreToAdd;
      }
    });

    return maxScore;
  }, []);

  const makeNewReferenceCheckFromTemplate = useCallback(
    (referenceTemplate) => {
      const referenceCheck = {
        answers: [],
        include_cumulative_score: false,
      };

      referenceTemplate.questions.forEach((question) => {
        // Copy over the question, but not its id. This is easier than
        // copying each field besides id explicitly.
        const newAnswer = {
          ...question,
          question_id: question.id,
        };
        delete newAnswer.id;

        switch (question.question_type) {
          case scorecardQType.multiple_choice:
            newAnswer.mc_answer = [];
            break;
          case scorecardQType.nimble:
          case scorecardQType.text:
            newAnswer.answer_text = '';
            break;
          case scorecardQType.rating:
            newAnswer.answer_rating = null;
            break;
          case scorecardQType.direction_text:
            // no answer for direction text type, it's just a set of
            // directions for the editor to read.
            break;
          case scorecardQType.rubric:
            // answer_rubric will store the number of the selection.
            // If the question is not required and the editor did not select anything,
            // it will be null.
            newAnswer.answer_rubric = null;
            break;
          case scorecardQType.final_recommendation:
            // Options are yes and no, with maybe being an option if the admin
            // includes it in the template. Start off with nothing selected (null).
            // final recommendation information is stored at the referenceCheck level
            // because there can only be one recommendation max per referenceCheck.
            referenceCheck.include_final_recommendation = true;
            referenceCheck.include_maybe_option = question.include_maybe_option;
            referenceCheck.show_answer_on_preview = question.show_answer_on_preview;
            referenceCheck.final_recommendation = null;
            break;
          case scorecardQType.cumulative_score:
            // similar to final recommendation, there can only be one cumulative score per
            // referenceCheck, so cumulative score information is stored at the referenceCheck level.
            referenceCheck.include_cumulative_score = true;
            referenceCheck.include_rating_questions = question.include_rating_questions;
            referenceCheck.include_rubric_questions = question.include_rubric_questions;
            referenceCheck.show_total_on_preview = question.show_total_on_preview;
            referenceCheck.cumulative_score = 0;
            referenceCheck.max_cumulative_score = getMaxCumulativeScore(
              referenceTemplate,
              question
            );
            break;
          case scorecardQType.attachment:
            // Start with no value in the attachment
            newAnswer.attachment = null;
            break;
          default:
            break;
        }
        referenceCheck.answers.push(newAnswer);
      });

      return referenceCheck;
    },
    [getMaxCumulativeScore]
  );

  const getDefaultReferenceTemplate = useCallback(() => {
    const defaultTemplate = referenceTemplates.find((s) => s.id === defaultReferenceTemplateId);
    return defaultTemplate;
  }, [referenceTemplates, defaultReferenceTemplateId]);

  useEffect(() => {
    if (selectedDistrictReference) {
      setReference(selectedDistrictReference);
    }

    let referenceCheck;
    if (referenceCheckToEdit.id) {
      referenceCheck = { ...referenceCheckToEdit };
      setReferenceCheck(referenceCheck);
    } else if (referenceTemplates.length > 0 && defaultReferenceTemplateId) {
      // Make a new referenceCheck based on the chosen referenceTemplate.
      // If user has a default reference template, use that.
      const referenceTemplate = getDefaultReferenceTemplate();

      if (referenceTemplate) {
        setReferenceTemplate(referenceTemplate);
        referenceCheck = makeNewReferenceCheckFromTemplate(referenceTemplate);
        setReferenceCheck(referenceCheck);
      }
    }
  }, [
    selectedDistrictReference,
    referenceTemplates,
    referenceCheckToEdit,
    defaultReferenceTemplateId,
    getDefaultReferenceTemplate,
    makeNewReferenceCheckFromTemplate,
  ]);

  const updateField = (e, index) => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].answer_text = e.target.value;
    const cumulativeScore = getCumulativeScore(updatedReferenceCheck);
    updatedReferenceCheck.cumulative_score = cumulativeScore;

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
    autosave();
  };

  const updateRating = (e, index) => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].answer_rating = e.target.value;

    const cumulativeScore = getCumulativeScore(updatedReferenceCheck);
    updatedReferenceCheck.cumulative_score = cumulativeScore;

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
    autosave();
  };

  const updateMultipleChoiceField = (e, index, isMultiSelect) => {
    const updatedReferenceCheck = { ...referenceCheck };
    const multipleChoiceAnswers = updatedReferenceCheck.answers[index].mc_answer;

    const foundIndex = multipleChoiceAnswers.findIndex((a) => a === e.target.value);
    if (foundIndex > -1) {
      // answer is already selected, so remove it
      multipleChoiceAnswers.splice(foundIndex, 1);
    } else {
      // Answer is not already selected.
      // If it's not a multi-select, we have to deselect the other options.
      if (!isMultiSelect) {
        // setting length to 0 empties the array without reassigning it to a new one
        multipleChoiceAnswers.length = 0;
      }
      // add in new answer at end.
      multipleChoiceAnswers.push(e.target.value);
    }

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
    autosave();
  };

  const updateFinalRecommendation = (value, index) => {
    const referenceCheckCopy = { ...referenceCheck };
    referenceCheckCopy.final_recommendation = value;
    setReferenceCheck(referenceCheckCopy);
    setErrorMessage('');
    autosave();
  };

  const updateRubricSelection = (value, index) => {
    const referenceCheckCopy = { ...referenceCheck };
    referenceCheckCopy.answers[index].answer_rubric = value;
    const cumulativeScore = getCumulativeScore(referenceCheckCopy);
    referenceCheckCopy.cumulative_score = cumulativeScore;
    setReferenceCheck(referenceCheckCopy);
    setErrorMessage('');
    autosave();
  };

  const uploadAttachment = (fileData, index) => {
    const referenceCheckCopy = { ...referenceCheck };
    referenceCheckCopy.answers[index].attachment = fileData;
    setReferenceCheck(referenceCheckCopy);
    setErrorMessage('');
    autosave();
  };

  const removeAttachment = (e, index) => {
    e.preventDefault();
    const referenceCheckCopy = { ...referenceCheck };
    referenceCheckCopy.answers[index].attachment = null;
    setReferenceCheck(referenceCheckCopy);
    setErrorMessage('');
    autosave();
  };

  const getCumulativeScore = (referenceCheck) => {
    let cumulativeScore = 0;

    // Loop through other answers in the Reference/Scorecard and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    referenceCheck.answers.forEach((item) => {
      if (item.question_type === scorecardQType.rating && referenceCheck.include_rating_questions) {
        cumulativeScore += Number(item.answer_rating) ?? 0;
      }
      if (item.question_type === scorecardQType.rubric && referenceCheck.include_rubric_questions) {
        cumulativeScore += item.answer_rubric ?? 0;
      }
    });

    return cumulativeScore;
  };

  const onSave = (isAutosave = false, isDraft = false) => {
    try {
      checkForErrors(isAutosave, isDraft);
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    setEmptyRatingAnswers();

    if (isSubmitting) {
      return;
    } else {
      setIsSubmitting(true);
    }

    if (referenceCheck.id !== undefined) {
      updateReferenceCheck(isAutosave, isDraft);
    } else {
      createReferenceCheck(isAutosave, isDraft);
    }
  };

  const checkForErrors = (isAutosave, isDraft) => {
    referenceCheck.answers.forEach((answer) => {
      if (answer.question_type === scorecardQType.rating) {
        checkRating(answer, isAutosave, isDraft);
      }
      if (
        // If it's an autosave or draft save we're just gonna take what the user gives us
        !isAutosave &&
        !isDraft &&
        answer.is_required &&
        ((answer.question_type === scorecardQType.attachment && !answer.attachment) ||
          (answer.question_type === scorecardQType.text && !answer.answer_text) ||
          (answer.question_type === scorecardQType.nimble && !answer.answer_text) ||
          (answer.question_type === scorecardQType.multiple_choice && !answer.mc_answer.length) ||
          (answer.question_type === scorecardQType.rubric && answer.answer_rubric === null) ||
          (answer.question_type === scorecardQType.final_recommendation &&
            !referenceCheck.final_recommendation))
      ) {
        throw new Error('Please fill out all required fields.');
      }
    });

    if (mode === 'create' && !reference.id) {
      throw new Error('Please select a reference.');
    }
  };

  const checkRating = (answer, isAutosave, isDraft) => {
    /**
     * Rating answers will be one of the following:
     * 1. Empty string (if number typed in and then backspaced)
     * 2. null
     * 3. A number (possibly not in the allowed range (0 - <answer.scale>))
     */
    const isFinalSubmission = !isAutosave && !isDraft;

    if (answer.answer_rating === '') {
      if (answer.is_required && isFinalSubmission) {
        throw new Error('Please fill out all required fields.');
      }
    } else if (answer.answer_rating === null) {
      if (answer.is_required && isFinalSubmission) {
        throw new Error('Please fill out all required fields.');
      }
    } else {
      // The answer is a number, so make sure it's in the allowed range,
      // even for drafts and autosaves. We don't want to allow a draft
      // save that has negative numbers or crazy high numbers.
      let rating = Number(answer.answer_rating);
      if (rating > answer.scale || rating < 0) {
        throw new Error('Rating score must be in the allowed range.');
      }
    }
  };

  const setEmptyRatingAnswers = () => {
    // The backend stores empty ratings as null, but they're sometimes empty strings
    // on the frontend due to html input values. Set all to null.
    const newAnswers = referenceCheck.answers.map((answer) =>
      answer.answer_rating === '' ? { ...answer, answer_rating: null } : answer
    );
    referenceCheck.answers = newAnswers;
  };

  const onDelete = () => {
    // If the card hasn't been saved yet, we just go back a page
    if (!referenceCheck.id) {
      onBack();
    } else {
      deleteReferenceCheck(referenceCheck.id);
    }
  };

  const createReferenceCheck = (isAutosave = false, isDraft = false) => {
    if (!referenceTemplate.id) {
      setErrorMessage('Please select a reference form');
      return;
    }

    const finalReferenceCheck = {
      ...referenceCheck,
      candidate_id: candidate.id,
      reference_id: reference.id,
      reference_template_id: referenceTemplate.id,
      submitted_by_name: reference.name,
      submitted_by_title: reference.title,
      is_complete: isAutosave || isDraft ? false : true,
    };

    // Answers are split into two groups: attachment answers and non-attachment answers.
    // This is because attachment answers are submitted as form data and weird results
    // were occurring when trying to submit all answers (even non attachments) as form data.
    const attachmentAnswers = finalReferenceCheck.answers.filter(isAttachment);
    finalReferenceCheck.answers = finalReferenceCheck.answers.filter(
      (answer) => !isAttachment(answer)
    );

    referenceCheckAPI
      .create(finalReferenceCheck)
      .then(async (newReferenceCheck) => {
        const updatedReferenceCheck = {
          // take id and other fields from newReferenceCheck
          ...newReferenceCheck,
          // take answers from existing referenceCheck in case the user made edits while
          // the reference check was saving.
          ...referenceCheck,
        };

        const answerMapping = {};

        // wait for all attachments to post before moving on
        for (let answer of attachmentAnswers) {
          const formData = getFormData(answer);
          const newAttachment = await referenceCheckAPI.createAttachment(
            newReferenceCheck.id,
            formData
          );
          // store the attachments so we can access them easily below by looking
          // up question_id.
          answerMapping[newAttachment.question_id] = newAttachment;
        }

        // At this point, new answers have been created but they don't have ids.
        // They can't be added as they are because they're not in the proper order
        // due to them being sent as two separate groups (see above). So iterate
        // over the answers in state, which are in order, and find their id that way.
        updatedReferenceCheck.answers = updatedReferenceCheck.answers.map((answer) => {
          if (isAttachment(answer)) {
            return answerMapping[answer.question_id];
          } else {
            // find answer using question_id and add the id to our answer in state
            const answerWithId = newReferenceCheck.answers.find(
              (newAnswer) => newAnswer.question_id === answer.question_id
            );
            return {
              id: answerWithId.id,
              ...answer,
            };
          }
        });

        // now that all answers have an id, save the answer order
        await referenceCheckAPI.setAnswerOrder(updatedReferenceCheck);

        setReferenceCheck(updatedReferenceCheck);

        if (!isAutosave && !isDraft) {
          tagUsers(newReferenceCheck.id);
        }
        referencePostSave(finalReferenceCheck, reference.id, isAutosave, isDraft);
      })
      .finally(() => setIsSubmitting(false));
  };

  const getFormData = (answer) => {
    const formData = new FormData();
    if (answer.attachment instanceof File) {
      formData.append('attachment', answer.attachment, answer.attachment.name);
    } else {
      // file is empty
      formData.append('attachment', '');
    }
    formData.append('is_required', answer.is_required);
    formData.append('question_id', answer.question_id);
    formData.append('question_type', answer.question_type);
    formData.append('question_text', answer.question_text);
    formData.append('attachment_directions', answer.attachment_directions);
    return formData;
  };

  const updateReferenceCheck = (isAutosave = false, isDraft = false) => {
    const finalReferenceCheck = {
      ...referenceCheck,
      is_complete: isAutosave || isDraft ? false : true,
    };

    const attachmentAnswers = finalReferenceCheck.answers.filter(isAttachment);
    finalReferenceCheck.answers = finalReferenceCheck.answers.filter(
      (answer) => !isAttachment(answer)
    );

    attachmentAnswers.forEach(handleAttachmentAnswerUpdate);

    referenceCheckAPI
      .update(finalReferenceCheck)
      .then((updatedReferenceCheck) => {
        if (!isAutosave && !isDraft) {
          tagUsers(updatedReferenceCheck.id);
        }

        referencePostSave(
          finalReferenceCheck,
          finalReferenceCheck.reference_id,
          isAutosave,
          isDraft
        );
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleAttachmentAnswerUpdate = (answer) => {
    if (typeof answer.attachment === 'string') {
      // This attachment has already been uploaded, so move on to the next iteration.
      return;
    } else {
      const formData = getFormData(answer);

      if (answer.id) {
        referenceCheckAnswerAPI.update(answer.id, formData);
      } else {
        referenceCheckAPI.createAttachment(referenceCheck.id, formData);
      }
    }
  };

  const tagUsers = (id) => {
    const regExp = /@\[(.*?)\]/g; // checking for names in the following format: @[First Last]
    const usersAlreadyTagged = new Set();

    // Users can only be tagged in text responses.
    referenceCheck.answers
      .filter(
        (answer) =>
          answer.question_type === scorecardQType.text ||
          answer.question_type === scorecardQType.nimble
      )
      .forEach((answer) => {
        const finalUserList = answer.answer_text.match(regExp) || [];
        const finalTagsForThisAnswer = [];

        // for each tag string, find the full user object in props.adminUsers
        finalUserList.forEach((tagName) => {
          // change "@[First Last]" to "First Last"
          const name = tagName.substring(2, tagName.length - 1);
          const userObject = adminUsers.find((admin) => admin.display === name);
          if (userObject && !usersAlreadyTagged.has(userObject.id)) {
            finalTagsForThisAnswer.push(userObject);
            usersAlreadyTagged.add(userObject.id);
          }
        });

        let tagType = 'reference';
        emailTaggedUsers(finalTagsForThisAnswer, answer.answer_text, tagType, id);
      });
  };

  const changeReferenceTemplate = (value) => {
    const referenceTemplate = referenceTemplates.find((template) => template.id === value);
    const referenceCheck = makeNewReferenceCheckFromTemplate(referenceTemplate);
    setReferenceCheck(referenceCheck);
    setReferenceTemplate(referenceTemplate);
    setErrorMessage('');
  };

  const saveAsDraft = () => {
    onSave(false, true);
  };

  const submit = () => {
    // non autosave, non draft
    onSave(false, false);
  };

  const editFooterButtons = () => (
    <>
      <DeleteButton onClick={onDelete}>Delete Reference</DeleteButton>
      <CancelButton onClick={onBack}>Cancel changes</CancelButton>
    </>
  );

  const createFooterButtons = () => (
    <>
      <SaveAsDraftButton
        onClick={saveAsDraft}
        disabled={
          errorMessage !== '' &&
          // allow user to save as draft without all required fields
          errorMessage !== 'Please fill out all required fields.'
        }
      >
        Save as Draft
      </SaveAsDraftButton>
      <CancelButton onClick={onDelete}>{'Cancel & delete'}</CancelButton>
    </>
  );

  return (
    <>
      <AltModal.Body>
        {mode === 'create' && (
          <DropdownSectionContainer>
            <DropdownContainer>
              <HeaderText>Select reference</HeaderText>
              <DropdownWithInputFilter
                options={additional_references.map((addlRef) => ({
                  value: addlRef.id,
                  label: `${addlRef.name}${addlRef.title && ', ' + addlRef.title}${
                    addlRef.phone && ', ' + addlRef.phone
                  }`,
                }))}
                value={reference.id || ''}
                handleChange={(value) => {
                  setReference(additional_references.find((addlRef) => addlRef.id === value));
                  setErrorMessage('');
                }}
                placeholder="Type or select a reference"
                boxShadow={false}
                onClear={() => setReference({})}
              />
            </DropdownContainer>

            <HeaderText>Select reference form</HeaderText>
            <DropdownWithInputFilter
              options={referenceTemplates.map((template) => ({
                value: template.id,
                label: template.title,
              }))}
              value={referenceTemplate.id || ''}
              handleChange={changeReferenceTemplate}
              placeholder="Type or select a template"
              boxShadow={false}
            />
          </DropdownSectionContainer>
        )}

        <TitleText>{referenceCheck.template_name ?? referenceTemplate.title}</TitleText>

        <ReferenceEditorQuestions
          referenceCheck={referenceCheck}
          updateField={updateField}
          updateFinalRecommendation={updateFinalRecommendation}
          updateMultipleChoiceField={updateMultipleChoiceField}
          updateRating={updateRating}
          updateRubricSelection={updateRubricSelection}
          adminUsers={adminUsers}
          uploadAttachment={uploadAttachment}
          removeAttachment={removeAttachment}
        />
      </AltModal.Body>

      <FooterButtonsGridContainer gridTemplateColumns={['1fr', '1fr', '296px 371px']}>
        <GridSubContainer gridTemplateColumns={['1fr', '1fr', '150px 130px']}>
          {(mode === 'edit' || mode === 'completing') && editFooterButtons()}
          {mode === 'create' && createFooterButtons()}
        </GridSubContainer>
        <SubmitContainer gridTemplateColumns={['1fr', '1fr', '177px 186px']}>
          <ErrorText message={errorMessage} />
          <SubmitButton onClick={submit}>
            {mode === 'edit' || mode === 'completing' ? 'Update Reference' : 'Complete & Submit'}
          </SubmitButton>
        </SubmitContainer>
      </FooterButtonsGridContainer>
    </>
  );
}

ReferenceEditor.propTypes = {
  mode: PropTypes.string.isRequired,
  referenceCheckToEdit: PropTypes.object.isRequired,
  referenceTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.object),
  additional_references: PropTypes.array.isRequired,
  onBack: PropTypes.func,
  deleteReferenceCheck: PropTypes.func,
  referencePostSave: PropTypes.func.isRequired,
  emailTaggedUsers: PropTypes.func.isRequired,
};

const DropdownContainer = styled.div`
  margin-bottom: 20px;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 11px;

  color: #444444;
`;

const TitleText = styled(HeaderText)`
  font-size: 18px;
  margin-bottom: 24px;
`;

const DropdownSectionContainer = styled.div`
  margin-bottom: 40px;
`;

const FooterButton = styled.button`
  width: 100%;
  height: 50px;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const DeleteButton = styled(FooterButton)`
  border: 1px solid #ef5675;
  color: #ef5675;
`;

const SaveAsDraftButton = styled(FooterButton)`
  border: 1px solid #cacaca;
  color: #cacaca;
`;

const CancelButton = styled(FooterButton)`
  border: none;
  background: transparent;
  color: #c2c2c2;
`;

const SubmitButton = styled(FooterButton)`
  background-color: #00b88d;
  border: none;
  font-weight: 600;
  color: #ffffff;

  grid-column: 2 / span 1;
`;

const FooterButtonsGridContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid
);

const GridSubContainer = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
  },
  grid
);

const SubmitContainer = styled.div(
  {
    display: 'grid',
    gridGap: '8px',
    alignItems: 'center',
  },
  grid
);
