import { Component } from 'react';

import PropTypes from 'prop-types';

import { momentOr } from '../../../utils/util';
import { workExperienceType, grades } from '../../../utils/enums';

const TYPES = workExperienceType().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

const GRADES = grades().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default class WorkExperiences extends Component {
  static propTypes = {
    experiences: PropTypes.arrayOf(Object).isRequired,
    simpleCard: PropTypes.bool,
  };

  static defaultProps = {
    simpleCard: false,
  };
  /*
   * simpleCard is rendered on applicationconfirmation and full profile
   * !simpleCard type is used on candidate application
   */

  renderLabels = (arr, mapping) => {
    /** take an array of enums and render as a string of labels according to the mapping */
    let str = '';
    arr.forEach((label) => {
      str += mapping[label] + ', ';
    });
    // remove trailing comma and space from last word
    str = str.slice(0, str.length - 2);
    return str;
  };

  render() {
    return (
      <div className="simple-card-container">
        {this.props.experiences.map((row, i) =>
          this.props.simpleCard ? (
            <div className="simple-card mt2 ml2 mb1" key={i}>
              {/* title section */}
              <div>
                {row['title'] || ''}, {row['organization']['name'] || ''}
              </div>

              {/* employment dates */}
              <div>
                <span>
                  {row['start_date']
                    ? `${momentOr(row['start_date'], 'M/D/YYYY', '')} - ${momentOr(
                        row['end_date'],
                        'M/D/YYYY',
                        'Present'
                      )}`
                    : `${momentOr(row['end_date'], 'M/D/YYYY', 'Present')}`}
                </span>
              </div>

              {/* type of position */}
              {row.experience_types.length > 0 && (
                <div>Type: {this.renderLabels(row.experience_types, TYPES)}</div>
              )}

              {/* grades taught */}
              {row.grades.length > 0 && (
                <div>Grade level: {this.renderLabels(row.grades, GRADES)}</div>
              )}

              {/* reference information */}
              {row['reference_name'] ? (
                <div>
                  <div>Supervisor: {row['reference_name']}</div>
                  <div>
                    {row['reference_phone'] || ''}{' '}
                    <span className="ml1">{row['reference_email'] || ''}</span>
                  </div>
                </div>
              ) : null}

              {/* Job description */}
              {row['description'] ? (
                <div className="">Job description: {row['description']}</div>
              ) : null}

              {/* reason for leaving */}
              {row['reason_for_leaving'] ? (
                <div className="">Reason for leaving job: {row['reason_for_leaving']}</div>
              ) : null}
              {row.do_not_contact && <div className="bold">Please do not contact</div>}
            </div>
          ) : (
            <div className="added-row flex" key={i}>
              <span className="block flex-1 bold">
                <h5>{row['organization']['name'] || ''}</h5>
                <p>{row['title'] || ''}</p>
              </span>
              <span className="flex-1">
                {row['start_date']
                  ? `${momentOr(row['start_date'], 'M/D/YYYY', '')} - ${momentOr(
                      row['end_date'],
                      'M/D/YYYY',
                      'Present'
                    )}`
                  : `${momentOr(row['end_date'], 'M/D/YYYY', 'Present')}`}

                {row['description'] ? (
                  <div className="leaving">Job description: {row['description']}</div>
                ) : null}

                {row['reason_for_leaving'] ? (
                  <div className="leaving">Reason for leaving job: {row['reason_for_leaving']}</div>
                ) : null}

                {/* type of work */}
                {row.experience_types.length > 0 && (
                  <div>Type: {this.renderLabels(row.experience_types, TYPES)}</div>
                )}

                {/* grades taught */}
                {row.grades.length > 0 && (
                  <div>Grade level: {this.renderLabels(row.grades, GRADES)}</div>
                )}
                {row.do_not_contact && <div className="bold">Please do not contact</div>}
              </span>
            </div>
          )
        )}
      </div>
    );
  }
}
