import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';

import Stack from '@mui/material/Stack';
import { updateCandidateProfileResume } from 'utils/fileUpload';
import { checkFileMeetsRequirements, formatResumeFileName } from '../utils';

import { FileUpload } from 'sharedComponents/FileUpload';
import { QuestionLabel } from './ResumeAndCertificationsForm';
import { ResumeFormProps } from './types';

export const ResumeForm: React.FC<ResumeFormProps> = ({
  user,
  userFormData,
  setUserFormData,
  userDataIsLoading,
  resumeUploadErrors,
  setResumeUploadErrors,
  resumeIsUploading,
  setResumeIsUploading,
  pageDisplayInformation,
}) => {
  const uploadResume = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setResumeIsUploading(true);
      const { fileData, errors } = checkFileMeetsRequirements(event);
      if (isEmpty(errors)) {
        const response = await updateCandidateProfileResume(fileData, user.id);
        setUserFormData({ ...userFormData, resume: formatResumeFileName(response.data.resume) });
        setResumeUploadErrors(
          resumeUploadErrors.filter((error) => error !== 'Please upload a resume.')
        );
      } else {
        setResumeUploadErrors(errors);
        console.error('Upload Error Messages', resumeUploadErrors);
      }
    } catch (error) {
      console.error('Error uploading resume', error);
    } finally {
      setResumeIsUploading(false);
    }
  };

  return (
    <Stack spacing={1}>
      <QuestionLabel required={pageDisplayInformation.requiredFields.includes('resume')}>
        Upload your resume
      </QuestionLabel>
      <FileUpload
        uploadedFileName={userFormData.resume}
        handleChange={uploadResume}
        errors={resumeUploadErrors}
        inputName="resume"
        isLoading={userDataIsLoading || resumeIsUploading}
        fileNameMaxWidth="240px"
      />
    </Stack>
  );
};
