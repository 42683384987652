import styled from 'styled-components';
import { MultiSelect } from 'ui-kit';

import { IStyledMultiSelect } from '../../types/types';

export const Container = styled.div`
  padding: 0px 48px 48px;
  max-width: 1200px;
  margin: auto;
`;

export const Label = styled.h5`
  margin: 3rem 0 0.8em;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
`;

export const StyledMultiSelect = styled(MultiSelect)<IStyledMultiSelect>`
  border: 0;
  boxshadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
`;

export const SubLabelText = styled.div`
  margin: 0 0 0.8em;
  font-size: 14px;
  color: #444;
`;
