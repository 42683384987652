import { BaseNimbleAPI } from './baseAPI';

class HellosignTemplatesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api' };
  }

  async fetchHelloSignTemplates() {
    const url = `/hellosign_templates/`;
    const response = await this.get(url);
    return response.data;
  }

  async fetchHelloSignTemplateFields(id: string) {
    const url = `/hellosign_templates/template_fields/?template_id=${id}`;
    const response = await this.get(url);
    return response.data;
  }
}

export default new HellosignTemplatesAPI();
