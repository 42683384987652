import { ReactComponent as TooltipIcon } from 'assets/tooltip.svg';
import ReactTooltip from 'react-tooltip';
import ErrorText from '../../../errortext';
import styled from 'styled-components';
import { SpnInputProps } from '../types/types';

export default function SpnInput({
  spn,
  handleSpnChange,
  hasSpnError,
}: SpnInputProps): React.ReactElement {
  return (
    <>
      <SpnHeader>Indiana State SPN Number</SpnHeader>
      <SpnInputTooltipContainer>
        <SpnInputContainer className="basic application-row">
          <div className="required-input-container">
            <input
              name="spn"
              className="form-field"
              type="text"
              placeholder="Enter your 8-digit SPN number"
              defaultValue={spn}
              maxLength={10}
              minLength={8}
              onChange={(e) => handleSpnChange(e.target.value)}
            />
          </div>
        </SpnInputContainer>
        <div>
          <ReactTooltip effect="solid" multiline={true} />
          <TooltipIcon
            data-tip={`Enter a valid SPN Number in the <br/> format P-XXXXXXXX or XXXXXXXX`}
          />
        </div>
      </SpnInputTooltipContainer>
      {hasSpnError && (
        <StyledErrorText
          message="Please enter a valid SPN Number in the format P-XXXXXXXX or XXXXXXXX"
          color="#EF5675"
        />
      )}
      <SpnLink
        href="https://license.doe.in.gov/public_data/educator_licenses/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Look up your SPN#
      </SpnLink>
    </>
  );
}

const SpnInputContainer = styled.div`
  width: 50%;
  margin-right: 7.5px;
`;

const SpnInputTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: auto;
  div {
    flex: 1;
  }
  input {
    width: 100%;
  }
  svg {
    margin-left: 0.5em;
    margin-right: 1.5em;
    fill: #999999;
  }
`;

const SpnHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding-top: 18px;
`;
const StyledErrorText = styled(ErrorText)`
  margin-bottom: 8px;
`;
const SpnLink = styled.a`
  font-size: 14px;
  color: #001bf5;
  text-decoration: underline;
  display: block;
  margin-top: 4px;

  &:visited,
  &:hover,
  &:active {
    color: #001bf5;
    text-decoration: underline;
  }
`;
