import './HellosignFormModal.scss';

import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
// AltModal breaks react-quill formatting, so keep react-bootstrap modal
// as the container until we figure out how to fix that
import { Modal } from 'react-bootstrap';

import { Input, AltModal, DropdownWithInputFilter } from 'ui-kit';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import FileUpload from '../common/FileUpload';
import EditPermissions from './EditPermissions';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';

export default class HelloSignFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      submitErrorMessage: '',
      directions: props.helloSignEditingTemplate?.directions || '',
      signer_one: null,
      signer_two: null,
      signer_three: null,
      fieldObj: null,
    };
    this.initialState = Object.assign({}, this.state);
  }

  componentDidMount() {
    this.setState({ fieldObj: this.getCustomForm() });
  }

  onCancel = () => {
    this.setState(this.initialState);
    this.props.onHideHelloSignFormModal();
  };

  onSubmit = async () => {
    if (this.checkForErrors()) {
      const editID = this.props.helloSignEditingTemplate.id || false;
      const template = { ...this.state };
      const directionsWithAbsoluteUrls = this.makeAllUrlsAbsolute(template.directions);
      template.directions = directionsWithAbsoluteUrls;
      this.props.sendTemplate(template, editID);
    }
  };

  makeAllUrlsAbsolute = (str) => {
    // users can add hyperlinks to the directions, but they may not
    // add a full link. Find all <a> tags that don't start with 'http'
    // and add 'http://' to the front of their hrefs.
    let re = /<a\s[^>]*href\s*=\s*"((?!https?:\/\/)[^"]*)"[^>]*>/g;
    str = str.replace(re, '<a href="http://$1" rel="noopener noreferrer" target="_blank">');
    return str;
  };

  onChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value, submitErrorMessage: '' });
  };

  updateDirections = (value, _, source) => {
    if (source !== 'user') {
      // prevent quill from messing with out text
      return;
    }
    this.setState({ directions: value, submitErrorMessage: '' });
  };

  onChangeSigner = (value, name) => this.setState({ [name]: value, submitErrorMessage: '' });

  updateFile = (file) => {
    this.setState({ file, submitErrorMessage: '' });
  };

  checkForErrors = () => {
    const editMode = !!this.props.helloSignEditingTemplate.id;

    if (!editMode) {
      if (!this.state.title) {
        this.setState({ submitErrorMessage: 'Please name this form.' });
        return false;
      }
      if (!this.state.file) {
        this.setState({ submitErrorMessage: 'Please select a file.' });
        return false;
      }
      if (
        this.state.signer_one === null &&
        this.state.signer_two === null &&
        this.state.signer_three === null
      ) {
        this.setState({ submitErrorMessage: 'Must select at least one signer.' });
        return false;
      }
    } else if (!this.hasAtLeastOnePermissionGroupOrIndividual()) {
      this.setState({
        submitErrorMessage: 'Completed forms must be visible to at least one group or individual.',
      });
      return false;
    }
    return true;
  };

  hasAtLeastOnePermissionGroupOrIndividual = () => {
    const {
      district_admin_access,
      district_user_access,
      school_admin_access,
      super_admin_access,
      individual_users,
    } = this.state.fieldObj;
    if (
      district_admin_access ||
      district_user_access ||
      school_admin_access ||
      super_admin_access ||
      individual_users.length > 0
    ) {
      return true;
    }
    return false;
  };

  getCustomForm = () => {
    const editMode = !!this.props.helloSignEditingTemplate?.id;

    // If editing, use the existing values for the template
    if (editMode) {
      return {
        super_admin_access: this.props.helloSignEditingTemplate.super_admin_access,
        district_admin_access: this.props.helloSignEditingTemplate.district_admin_access,
        district_user_access: this.props.helloSignEditingTemplate.district_user_access,
        school_admin_access: this.props.helloSignEditingTemplate.school_admin_access,
        individual_users: this.props.helloSignEditingTemplate.individual_users_permission,
      };
    }
    return {
      super_admin_access: false,
      district_admin_access: false,
      district_user_access: false,
      school_admin_access: false,
      individual_users: [],
    };
  };

  updateFieldObj = (fieldObj) => {
    this.setState({ fieldObj, submitErrorMessage: '' });
  };

  render() {
    const editMode = !!this.props.helloSignEditingTemplate.id;

    return (
      <Modal show={this.props.show} bsSize="large" className="deprecated-modal-container">
        <AltModal.Title>{editMode ? 'Edit Form' : 'New Form'}</AltModal.Title>
        <AltModal.Cancel onClick={this.props.onHideHelloSignFormModal}>×</AltModal.Cancel>
        <AltModal.UpperRule />
        <AltModal.Body>
          <Section>
            <Label>Name</Label>
            <Input
              width={1}
              name="title"
              value={this.props.helloSignEditingTemplate?.title ?? this.state.title}
              onChange={this.onChange}
              placeholder="e.g. Offer letter"
              disabled={editMode}
            />
          </Section>

          <Section>
            {editMode ? (
              <StyledText>Cannot edit upload.</StyledText>
            ) : (
              <FileUpload
                updateFile={this.updateFile}
                title={'Upload file'}
                description={'PDF, DOCX, DOC, JPG, PNG'}
              />
            )}
            <UploadFileHelperMessage />
          </Section>

          {!editMode && (
            <Section>
              <SignerSection>
                <DropdownWithInputFilter
                  options={this.props.signerOptions}
                  value={this.state.signer_one?.value ?? ''}
                  placeholder="Signer 1 (e.g. Candidate)"
                  handleChange={(value) => {
                    const signer = this.props.signerOptions.find(
                      (option) => option.value === value
                    );
                    this.onChangeSigner(signer, 'signer_one');
                  }}
                  onClear={() => this.onChangeSigner(null, 'signer_one')}
                  boxShadow={false}
                />
              </SignerSection>
              <SignerSection>
                <DropdownWithInputFilter
                  options={this.props.signerOptions}
                  value={this.state.signer_two?.value ?? ''}
                  placeholder="Signer 2 (e.g. HR Director)"
                  handleChange={(value) => {
                    const signer = this.props.signerOptions.find(
                      (option) => option.value === value
                    );
                    this.onChangeSigner(signer, 'signer_two');
                  }}
                  onClear={() => this.onChangeSigner(null, 'signer_two')}
                  boxShadow={false}
                />
              </SignerSection>
              <SignerSection>
                <DropdownWithInputFilter
                  options={this.props.signerOptions}
                  value={this.state.signer_three?.value ?? ''}
                  placeholder="Signer 3 (e.g. Principal)"
                  handleChange={(value) => {
                    const signer = this.props.signerOptions.find(
                      (option) => option.value === value
                    );
                    this.onChangeSigner(signer, 'signer_three');
                  }}
                  onClear={() => this.onChangeSigner(null, 'signer_three')}
                  boxShadow={false}
                />
              </SignerSection>
            </Section>
          )}

          <Section>
            <Label>Directions to candidate:</Label>
            <ReactQuillStyled
              value={this.state.directions || ''}
              onChange={this.updateDirections}
              placeholder="Candidates will see directions prior to completing the form"
            />
          </Section>

          {this.state.fieldObj && (
            <Section>
              <EditPermissions
                adminUsers={this.props.adminUsers}
                fieldObj={this.state.fieldObj}
                updateFieldObj={this.updateFieldObj}
              />
            </Section>
          )}
        </AltModal.Body>
        <AltModal.Actions>
          <ModalFooterNew
            cancel={this.onCancel}
            save={this.onSubmit}
            saveButtonLabel="Next Step"
            errorMessage={this.state.submitErrorMessage}
          />
        </AltModal.Actions>
      </Modal>
    );
  }
}

HelloSignFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHideHelloSignFormModal: PropTypes.func.isRequired,
  sendTemplate: PropTypes.func.isRequired,
  signerOptions: PropTypes.arrayOf(PropTypes.object),
  helloSignEditingTemplate: PropTypes.object,
  adminUsers: PropTypes.array.isRequired,
};

const ReactQuillStyled = styled(ReactQuill)`
  border: 0.5px solid #d7d7d7;
  border-radius: 3px;

  > div.ql-toolbar.ql-snow {
    border-radius: 3px 3px 0 0;
  }

  > div.ql-container.ql-snow {
    border-radius: 0 0 3px 3px;

    > .ql-editor.ql-blank::before {
      color: rgba(57, 60, 73, 0.3);
    }
  }
`;

const Section = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  .file-upload-container {
    box-shadow: none;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
  }
`;

const Label = styled.p`
  font-size: 16px;
  margin-bottom: 6px;
`;

const StyledText = styled.i`
  font-size: 14px;
`;

const SignerSection = styled.div`
  margin-bottom: 1rem;
`;
