import { BaseNimbleAPI } from './baseAPI';

import { RoleTemplate } from '../types/types';

class RoleTemplatesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/role_templates' };
  }

  /**
   * Fetches the specified template
   * @param {number} templateId ID of the requested template
   */
  async fetchTemplate(templateId: number) {
    const url = `/${templateId}/`;
    const response = await this.get(url);
    return response.data;
  }

  /**
   * Fetches all templates (within district)
   */
  async fetchTemplates() {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  /**
   * Creates a new template from the given data
   * @param {RoleTemplate} newTemplate template data
   */
  async createTemplate(newTemplate: RoleTemplate) {
    const url = '/';
    const response = await this.post(url, newTemplate);
    return response.data;
  }

  /**
   * Updates the specified template
   * @param {number} templateId ID of the template to update
   * @param {RoleTemplate} updatedTemplate new template data
   */
  async updateTemplate(templateId: number, updatedTemplate: RoleTemplate) {
    const url = `/${templateId}/`;
    const response = await this.put(url, updatedTemplate);
    return {
      ...response.data,
      schoolroles: response.data.schoolroles || [],
    };
  }

  /**
   * Deletes the specified template
   * @param {number} templateId ID of the requested template
   */
  async deleteTemplate(templateId: number) {
    const url = `/${templateId}/`;
    const response = await this.delete(url);
    return response.data;
  }
}

export default new RoleTemplatesAPI();
