import { useEffect, useRef } from 'react';

import { CloseButton } from 'sharedComponents/Buttons';
import { DialogProps } from './types';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialog from '@mui/material/Dialog';
import { Box } from '@mui/material';

export const Dialog: React.FC<DialogProps> = ({
  children,
  ctaButtons,
  open,
  handleClose,
  maxWidth,
  sx,
  title,
}) => {
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={sx}
      maxWidth={maxWidth}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle id="scroll-dialog-title" data-testid="dialog-title">
          {title}
        </DialogTitle>
        <CloseButton onClick={handleClose} sx={{ height: '50%', marginRight: '8px' }} />
      </Box>
      <DialogContent dividers={true}>
        <DialogContentText
          component={'span'}
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      {ctaButtons}
    </MuiDialog>
  );
};
