import { Box, styled, Typography } from '@mui/material';
import { nimbleTheme } from 'theme';

export const Author = styled(Box)({
  color: nimbleTheme.palette.text.light,
  lineHeight: nimbleTheme.spacing(2.5),
  paddingRight: nimbleTheme.spacing(1),
  [nimbleTheme.breakpoints.down('md')]: {
    fontWeight: nimbleTheme.typography.fontWeightBold,
  },
});

export const AuthorDate = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: nimbleTheme.spacing(1),
});

export const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginTop: nimbleTheme.spacing(4),

  [nimbleTheme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    marginTop: 0,
  },
});

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxWidth: '1200px',
  padding: nimbleTheme.spacing(4),
  width: '100%',

  [nimbleTheme.breakpoints.down('md')]: {
    backgroundColor: nimbleTheme.palette.white.main,
    padding: nimbleTheme.spacing(0),
  },
});

export const Date = styled(Typography)({
  lineHeight: nimbleTheme.spacing(2.25),
});

export const GreyRoundedBox = styled(Typography)({
  backgroundColor: nimbleTheme.palette.white.darker,
  borderRadius: nimbleTheme.spacing(1),
  lineHeight: nimbleTheme.spacing(2),
  padding: nimbleTheme.spacing(1.5),
  [nimbleTheme.breakpoints.down('md')]: {
    backgroundColor: nimbleTheme.palette.white.main,
  },
});

export const InternalNotesContainer = styled(Box)({
  borderRadius: nimbleTheme.spacing(1.5),
  border: `1px solid ${nimbleTheme.palette.gray.light}`,
  padding: nimbleTheme.spacing(3),
});

export const Message = styled(GreyRoundedBox)({
  lineHeight: nimbleTheme.spacing(2.5),
  padding: `${nimbleTheme.spacing(2)} !important`,
  [nimbleTheme.breakpoints.down('md')]: {
    border: `1px solid ${nimbleTheme.palette.gray.light}`,
  },
});

export const MetadataContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',

  [nimbleTheme.breakpoints.down('md')]: {
    paddingBottom: nimbleTheme.spacing(4),
    width: '100%',
  },
});

export const Row = styled(Box)({
  paddingBottom: nimbleTheme.spacing(1.5),
});

export const SpinnerContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  [nimbleTheme.breakpoints.down('md')]: {
    paddingTop: nimbleTheme.spacing(2),
  },
});

export const WhiteRoundedBox = styled(Box)({
  backgroundColor: nimbleTheme.palette.white.main,
  borderRadius: nimbleTheme.spacing(3),
  padding: nimbleTheme.spacing(3),

  [nimbleTheme.breakpoints.down('md')]: {
    backgroundColor: nimbleTheme.palette.white.darker,
    borderRadius: nimbleTheme.spacing(2),
    marginLeft: nimbleTheme.spacing(1),
    marginRight: nimbleTheme.spacing(1),
  },
});

export const RightBottom = styled(WhiteRoundedBox)({
  display: 'flex',
});

export const RightTop = styled(WhiteRoundedBox)({
  marginBottom: nimbleTheme.spacing(3),
});

export const Top = styled(Box)({
  backgroundColor: nimbleTheme.palette.white.main,
  borderRadius: nimbleTheme.shape.borderRadius * 4,
  padding: nimbleTheme.spacing(3),

  [nimbleTheme.breakpoints.down('md')]: {
    borderRadius: 0,
    marginBottom: nimbleTheme.spacing(2),
    padding: 0,
  },
});
