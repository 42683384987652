import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Checkbox, FormControl } from '@mui/material';
import { TextField } from '@mui/material';
import { MultiSelectWithSearchMUIProps } from './types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultiSelectWithSearch: React.FC<MultiSelectWithSearchMUIProps> = ({
  values = [],
  options = [],
  displayName,
  placeholder,
  handleChange,
  allowMultiselect = true,
  showSelectAll = false,
  showCheckbox = false,
  tagLimit,
  size,
  sx,
  hiddenLabel,
  dataTestId,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(values);

  const displayedOptions = showSelectAll
    ? [{ value: -1, label: `All ${displayName} Selected` }, ...options]
    : options;

  const onChangeHandler = (_event, value) => {
    const isSelectAll = value.some((option) => option.value === -1);
    setSelectAll(isSelectAll);
    setSelectedOptions(isSelectAll ? options : [...value]);
  };

  useEffect(() => {
    handleChange(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    if (values.length > 0) {
      setSelectedOptions(values);
    }
  }, [values]);

  return (
    <FormControl fullWidth variant="outlined" size={size} sx={sx}>
      <Autocomplete
        id="multi-select-with-search"
        data-testid={dataTestId || 'multi-select-with-search'}
        placeholder={displayName}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        multiple={allowMultiselect}
        limitTags={tagLimit}
        options={displayedOptions}
        size={size}
        sx={sx}
        onChange={(event, value) => {
          onChangeHandler(event, value);
        }}
        value={selectAll ? [displayedOptions[0]] : [...selectedOptions]}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            key={option.value}
            data-testid={`${dataTestId}-${option.label.toLowerCase()}-option`}
          >
            {showCheckbox && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectAll || selected}
                data-testid={`${dataTestId}-${option.label.toLowerCase()}-option-checkbox`}
              />
            )}
            {option.value === -1 ? 'Select All' : option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={displayName}
            sx={sx}
            placeholder={placeholder}
            hiddenLabel={hiddenLabel}
          />
        )}
      />
    </FormControl>
  );
};
