import { InputLabel } from '@mui/material';
import { LabelProps } from './types';

export const Label: React.FC<LabelProps> = ({ labelText, sx }) => {
  return (
    <InputLabel sx={sx} shrink={false}>
      {labelText}
    </InputLabel>
  );
};
