import { BaseNimbleAPI } from './baseAPI';

class NimbleQuestionsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/question' };
  }

  async getAll() {
    const response = await this.get(`/`);
    return response.data;
  }
}

export default new NimbleQuestionsAPI();
