import { BaseNimbleAPI } from './baseAPI';

class scorecardAnswerAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/scorecard_answer' };
  }

  async update(answerId, formData) {
    const response = await this.put(`/${answerId}/`, formData);
    return response.data;
  }
}

export default new scorecardAnswerAPI();
