import { Position, SnackbarProps } from './types';
import { Snackbar as MuiSnackbar } from '@mui/material';

export const Snackbar: React.FC<SnackbarProps> = ({
  children,
  message,
  onClose,
  open,
  position,
  duration,
  sx = { marginTop: '45px' }, // to account for the navbar height
}) => {
  const positionState: Position = { vertical: 'top', horizontal: 'center', open: open };

  const { vertical, horizontal } = position ? position : positionState;

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={duration}
      message={message}
      onClose={onClose}
      open={open}
      sx={sx}
    >
      {children}
    </MuiSnackbar>
  );
};
