import { useEffect, useState } from 'react';

import { Body } from 'ui-kit/Modal';
import { CheckmarkBlackBox } from 'ui-kit/icons';
import { Textarea } from 'ui-kit';
import { ModalBodySectionHeader } from '../styledComponents';
import { constants } from 'utils/enums';

import HeaderInformation from './HeaderInformation';
import { StatementCheckBoxProps } from '../types';
import ReactTooltip from 'react-tooltip';
import AutoTagsModalOpenButton from '../../AutoTags/Modal/AutoTagsModalOpenButton';
import { QuestionType, Tag } from 'types/types';
import AutoTagsModal from '../../AutoTags/Modal/AutoTagsModal';
import customTagAPI from 'api/customTagAPI';
import { TagState } from '../../AutoTags/Modal/types';

const StatementCheckbox: React.FC<StatementCheckBoxProps> = ({
  existingAutoTags,
  handleItemMatrixChange,
  handleSetAutoTags,
  internalRequirementsSpecified,
  itemMatrix,
  questionText,
  setQuestionText,
  shouldShowAutoTagLink,
  questionHasHiddenTags,
  hasTagsAvailable,
}) => {
  const [shouldShowTagModal, setShouldShowTagModal] = useState(false);
  const [authorizedTags, setAuthorizedTags] = useState(null);

  useEffect(() => {
    customTagAPI.fetchCustomTags().then((tags) => {
      setAuthorizedTags(tags);
    });
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleTagSave = (tagState: TagState) => {
    const autotags: Tag[] = [];

    if (tagState.Agree) {
      autotags.push({ tag_id: tagState.Agree, option: 'Agree' });
    }

    if (tagState.Disagree) {
      autotags.push({ tag_id: tagState.Disagree, option: 'Disagree' });
    }

    handleSetAutoTags(autotags);
  };

  const handleTagModalOpen = () => setShouldShowTagModal(true);
  const handleTagModalClose = () => setShouldShowTagModal(false);

  return (
    <Body>
      <HeaderInformation
        Icon={CheckmarkBlackBox}
        title="Statement checkbox"
        subtext="Asks the candidate to check the box to agree to a statement"
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <ModalBodySectionHeader>Type statement here:</ModalBodySectionHeader>
      <Textarea
        width={1}
        value={questionText}
        placeholder="e.g. By submitting this application I agree to allow the district to complete a background check"
        onChange={(e) => setQuestionText(e.target.value)}
        maxLength={constants.MEDIUM_TEXT_LENGTH}
      />
      {shouldShowAutoTagLink && (
        <AutoTagsModalOpenButton
          handleTagModalOpen={handleTagModalOpen}
          hasTagsAvailable={hasTagsAvailable}
          hasSetTags={existingAutoTags?.length > 0}
          questionHasHiddenTags={questionHasHiddenTags}
        />
      )}
      {shouldShowTagModal && (
        <AutoTagsModal
          onSave={handleTagSave}
          onClose={handleTagModalClose}
          questionType={QuestionType.statementCheckbox}
          show={shouldShowTagModal}
          existingAutoTags={existingAutoTags}
          fetchedTags={authorizedTags}
        />
      )}
    </Body>
  );
};

export default StatementCheckbox;
