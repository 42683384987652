import axios from 'axios';

export class BaseNimbleAPI {
  constructor() {
    this.client = axios;
  }

  get config() {
    return {};
  }

  get(url, config = null) {
    let requestConfig = { ...this.config, ...config };
    return this.client.get(url, requestConfig);
  }

  post(url, data, config = null) {
    let requestConfig = { ...this.config, ...config };
    return this.client.post(url, data, requestConfig);
  }

  put(url, data, config = null) {
    let requestConfig = { ...this.config, ...config };
    return this.client.put(url, data, requestConfig);
  }

  patch(url, data, config = null) {
    let requestConfig = { ...this.config, ...config };
    return this.client.patch(url, data, requestConfig);
  }

  delete(url, config = null) {
    let requestConfig = { ...this.config, ...config };
    return this.client.delete(url, requestConfig);
  }

  isCancel(error) {
    return axios.isCancel(error);
  }
}
