import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from 'hooks';
import { ReactComponent as DownCaret } from 'assets/icon-down-caret.svg';

import CustomTag from './CustomTag';
import { ATSCandidateTagDataTestIds } from 'data-testids/ATS';

export const DropdownWithInputFilter = ({
  placeholder = 'Start typing...',
  tags = [],
  value,
  handleChange,
  onClear,
  boxShadow = true,
  backgroundColor,
  dataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [hasEdited, setHasEdited] = useState(false);

  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const openDropdown = useCallback(() => setIsOpen(true), []);
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdown, isOpen);
  const statusRef = useRef(null);

  useEffect(() => {
    const currentOption = tags.find((tag) => tag.value === value);
    const label = currentOption?.label ?? '';
    setInputValue(label);
  }, [value, tags]);

  function getFilteredOptions() {
    if (tags === undefined) {
      return [];
    } else if (!inputValue || !hasEdited) {
      return tags;
    } else {
      // tags should always be sorted alphabetically
      return tags.filter((tag) => tag.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
    }
  }

  function clearInput() {
    setInputValue('');
    setHasEdited(false);
    if (onClear) {
      onClear();
    }
  }

  return (
    <div ref={closeNodeOnClickOutside} data-testid={dataTestId}>
      <DropdownWrapper>
        <DropdownInput
          type="text"
          onChange={(e) => {
            setInputValue(e.target.value);
            setHasEdited(true);
          }}
          onFocus={openDropdown}
          placeholder={placeholder}
          value={inputValue}
          boxShadow={boxShadow}
          backgroundColor={backgroundColor}
          data-testid={ATSCandidateTagDataTestIds.DROPDOWN_INPUT}
        />
        {inputValue ? (
          <PositionedClearIcon onClick={clearInput}>×</PositionedClearIcon>
        ) : (
          <PositionedCaret width="12px" height="12px" />
        )}
        {isOpen && (
          <DropdownList ref={statusRef} backgroundColor={backgroundColor}>
            {getFilteredOptions().map((tag) => (
              <StyledListItem
                key={tag.id}
                onClick={() => {
                  handleChange(tag.id);
                  closeDropdown();
                  setHasEdited(false);
                }}
                backgroundColor={backgroundColor}
                data-testid={ATSCandidateTagDataTestIds.DROPDOWN_LIST_ITEM}
              >
                <TagWrapper>
                  <CustomTag
                    tag={tag}
                    dataTestId={ATSCandidateTagDataTestIds.DROPDOWN_LIST_ITEM_TAG}
                  />
                </TagWrapper>
              </StyledListItem>
            ))}
          </DropdownList>
        )}
      </DropdownWrapper>
    </div>
  );
};

const DropdownWrapper = styled.div`
  position: relative;
  user-select: none;
`;

const DropdownInput = styled.input`
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
  font-size: 14px;

  background-color: ${(props) => props.backgroundColor};
  border: 0.5px solid #d7d7d7;
  box-shadow: ${(props) => (props.boxShadow ? '0px 2px 2px rgba(0, 0, 0, 0.0954484)' : 'none')};
  border-radius: 3px;

  padding-left: 1.5em;
  padding-right: 2.5em;

  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  width: 100%;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }
`;

const PositionedCaret = styled(DownCaret)`
  position: absolute;
  right: 23px;
  top: 20px;
`;

const PositionedClearIcon = styled.span`
  position: absolute;
  right: 24px;
  top: 12px;
  font-size: 20px;
  cursor: pointer;
  color: var(--lightgray);
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;

  max-height: 315px;
  @media screen and (max-width: 768px) {
    max-height: 222px;
  }

  font-size: 14px;

  color: rgba(0, 0, 0, 0.6);
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.uiColors.white};
  border: 1px solid #cccccc;
  box-shadow: 0px 1px 2px rgba(204, 204, 204, 0.5);

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  list-style-type: none;
  z-index: 10;
`;

const StyledListItem = styled.li`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;

const TagWrapper = styled.div`
  width: 170px;
  min-width: 170px;
`;
