import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SearchIcon } from 'ui-kit/icons';
import { theme } from 'ui-kit';
import { useOnClickOutside } from 'hooks';
import { DropdownSelectOption } from 'types/types';
import { ComponentsMultiSelectDataTestIds } from '../../../data-testids/Components';

export default function MultiSelectDropDown({
  values,
  setValues,
  options,
  displayName,
  showSearchInput = false,
  showSelectAllButtons = false,
  optionNamePlural = '',
  dataTestId,
}: {
  values: number[];
  setValues: (arg0: number[]) => void;
  options: DropdownSelectOption[];
  displayName: string;
  showSearchInput: boolean;
  showSelectAllButtons: boolean;
  optionNamePlural: string;
  dataTestId?: string;
}): React.ReactElement {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [displayNameText, setDisplayNameText] = useState(displayName);

  useEffect(() => {
    const displayNameTextCopy = getDisplayNameText(values, optionNamePlural, options);
    setDisplayNameText(displayNameTextCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, values, optionNamePlural]);

  const getDisplayNameText = (
    valuesSelected: number[],
    fieldPlural: string,
    options: DropdownSelectOption[]
  ) => {
    if (valuesSelected?.length === 0) return displayName;
    else if (valuesSelected?.length === 1)
      return options.find((o) => o.value === valuesSelected[0]).label;
    else return `${valuesSelected?.length} ${fieldPlural} selected`;
  };

  const closeNodeOnClickOutside = useOnClickOutside(() => {
    setShowOptions(false);
  }, showOptions);

  let filteredOptions = [...options];
  if (searchValue) {
    filteredOptions = filteredOptions.filter(
      (option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const toggleOptions = () => setShowOptions((prev) => !prev);
  const selectAllValues = () => setValues(options.map((o) => o.value));
  const clearAllValues = () => setValues([]);

  const toggleValue = (e, value) => {
    let updatedValues = [...values];

    if (!e.target.checked) {
      updatedValues = updatedValues.filter((item) => item !== value);
    } else {
      updatedValues.push(value);
      // ensure no duplicates
      updatedValues = [...new Set(updatedValues)];
    }

    updateValues(updatedValues);
  };

  const updateValues = (values) => setValues(values);

  return (
    <Container ref={closeNodeOnClickOutside}>
      <Dropdown onClick={toggleOptions} data-testid={dataTestId}>
        <p>{displayNameText}</p>
        <DownCaret fillColor={theme.uiColors.black} />
      </Dropdown>
      <SelectionList style={{ display: showOptions ? 'block' : 'none' }}>
        {showSelectAllButtons && (
          <SelectAllContainer>
            <span
              onClick={selectAllValues}
              data-testid={ComponentsMultiSelectDataTestIds.SELECT_ALL_BUTTON}
            >
              Select All
            </span>
            <span onClick={clearAllValues}>Clear All</span>
          </SelectAllContainer>
        )}

        {showSearchInput && (
          <InputFilterContainer>
            <InputFilter
              type="text"
              placeholder=""
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchValue ? (
              <ClearInput onClick={() => setSearchValue('')}>×</ClearInput>
            ) : (
              <PositionedSearchIcon />
            )}
          </InputFilterContainer>
        )}

        {filteredOptions.map((option) => (
          <Option key={option.value}>
            <label className="container">
              <input
                type="checkbox"
                checked={values.includes(option.value)}
                onChange={(e) => toggleValue(e, option.value)}
              />
              <span className="checkmark"></span>
              {option.label}
            </label>
          </Option>
        ))}
      </SelectionList>
    </Container>
  );
}

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 78px;
  align-items: center;
  grid-gap: 10px;

  border-bottom: 1px solid #c4c4c4;

  @media screen and (min-width: 1220) {
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 1fr 30px;
    grid-gap: 20px;
  }
`;

export const Container = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
  font-size: 15px;
  color: #828282;
  background-color: #ffffff;
  height: 50px;
  border-radius: 2px;
  display: flex;
  align-items: center;

  position: relative;
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  height: 100%;
`;

export const SelectionList = styled.div`
  position: absolute;
  top: 50px;
  left: -1px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  padding: 7px 12px;
  width: calc(100% + 2px);

  z-index: 5;

  max-height: 500px;
  overflow: auto;
`;

export const SelectAllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 35px;

  & > span {
    cursor: pointer;
  }
`;

export const Option = styled.div`
  cursor: pointer;
  font-size: 15px;
  color: #5c5e69;

  &:first-child {
    padding-top: 8px;
  }

  &:last-child {
    padding-bottom: 8px;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: normal;

    &.option-disabled {
      color: $light-grey;

      &:hover input ~ .checkmark {
        background-color: $green;
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: white;
      border: 1px solid #e5e5e5;

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #e5e5e5;
    }

    /* When the checkbox is checked, add a green background */
    input:checked ~ .checkmark {
      background-color: #00b88d;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
  }
`;
export const IndentedOption = styled(Option)`
  padding-left: 16px;
`;

export const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 16px;
`;

export const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

export const PositionedSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 15px;
`;

export const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;

export const DownCaret = ({ fillColor }: { fillColor: string }): React.ReactElement => {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29708 5.17736e-06L5.5 4.20292L9.70292 5.17736e-06L11 1.29709L5.5 6.79709L0 1.29709L1.29708 5.17736e-06Z"
        fill={fillColor}
      />
    </svg>
  );
};
